import * as React from 'react';
import { Card, Flex, Box, Menu, MenuItem, Workgroup } from 'components';
import Profile from 'components/app/profile/Profile';

import { ThemeContext } from 'styled-components/macro';

const AvatarsView = (): any => {
  const theme = React.useContext(ThemeContext);
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Avatar and menu</h2>
        <Flex flexDirection="row">
          <Box mr={5} width="240px">
            <div style={{ background: theme.primary.darkest, padding: '10px' }}>
              <Profile
                // imageUrl="https://randomuser.me/api/portraits/men/32.jpg"
                // name="George"
                variant="big"
              ></Profile>
            </div>
          </Box>
          <Box ml={50}>
            <Workgroup href="/" imageUrl="/logo-athlon.svg">
              Athlon Toronto
            </Workgroup>
          </Box>
          <Box ml={50}>
            <Menu>
              <MenuItem iconName="overview" href="/">
                Overview
              </MenuItem>
              <MenuItem iconName="templates" href="/components">
                Label Templates
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem iconName="notifications" href="/">
                Overview
              </MenuItem>
              <MenuItem iconName="apps" href="/">
                Label Templates
              </MenuItem>
            </Menu>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AvatarsView;
