import styled from 'styled-components';

export const ModalCheckboxWrapper = styled.div`
  label {
    margin: 24px 0;

    &:nth-child(1) {
      margin: 16px 0 24px;
    }

    input {
      margin: 0 16px 0 0;
    }
  }
`;

export const ModalInputWrapper = styled.div`
  padding: 24px 0;
`;
