import { useRef, useEffect } from 'react';

// TO-DO Get rid of any

const ownerDocument = (node: any) => (node && node.ownerDocument) || document;

export const getEl = (anchorEl: any) =>
  typeof anchorEl === 'function' ? anchorEl() : anchorEl;

export const setRef = (ref: any, value: any) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

export const usePortal = (anchor: any) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    rootElemRef.current = anchor
      ? ownerDocument(getEl(anchor)).body
      : document.body;
  });

  return rootElemRef.current || document.body;
};
