import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useReduxSelector, useReduxAction } from 'hooks';
import { Modal, Form, Flex, Button, Box } from 'components';
import { actions as workspaceActions } from 'ducks/workspaces/actions';
import { getThemes } from 'utils/themes';
import {
  getCurrentWorkspace,
  isUpdateSuccess,
  isUpdating,
} from 'ducks/workspaces/selectors';
import { RadioGroup, Radio } from 'v2/components/common';
import { getAssetPath } from 'utils/files';

const ThemeBar = styled.picture.attrs({
  height: '171px',
  width: '51px',
})`
  img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 171px;
  }
`;
const ThemePicture = styled.picture.attrs({
  height: '171px',
})`
  img {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height: 171px;
  }
`;
const ThemeExample = styled(Flex).attrs(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
}))`
  border-radius: 8px;
  height: 173px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  margin-bottom: 24px;
`;

//based on duplicate template modal
const ChangeThemeModal = forwardRef(({ isOpen, setIsOpen }: any, ref: any) => {
  const { t } = useTranslation(['overview', 'common']);

  const themes = getThemes();
  const currentWorkspace = useReduxSelector(getCurrentWorkspace);

  const updateWorkspace = useReduxAction(workspaceActions.UPDATE.request);
  const updateSuccess = useReduxSelector(isUpdateSuccess);
  const updateLoading = useReduxSelector(isUpdating);

  const [selectedTheme, setSelectedTheme] = useState('');

  useEffect(() => {
    if (updateSuccess) {
      ref.current.close();
    }
  }, [updateSuccess, ref]);

  useEffect(() => {
    setSelectedTheme(
      currentWorkspace?.theme !== '' ? currentWorkspace.theme : 'lucas',
    );
  }, [currentWorkspace]);

  const handleSubmit = () => {
    updateWorkspace({ ...currentWorkspace, theme: selectedTheme });
    ref.current.close();
  };

  return (
    <Modal
      data-testid="change-theme-modal"
      clickOutside={true}
      title={t('overview:theme.change')}
      ref={ref}
      footer={
        <Flex justifyContent={'flex-end'}>
          <Button
            data-testid="change-theme-cancel-button"
            onClick={() => ref.current.close()}
            variant={'text-primary'}
          >
            {t('common:cancel')}
          </Button>
          <Form onSubmit={handleSubmit}>
            <Button
              data-testid="change-theme-save-button"
              disabled={updateLoading}
              type={'submit'}
            >
              {updateLoading ? t('common:saving') : t('common:save')}
            </Button>
          </Form>
        </Flex>
      }
    >
      <Flex
        width={302}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <RadioGroup
          name={'themes'}
          value={selectedTheme}
          onChange={setSelectedTheme}
        >
          <Box>
            {themes.map(({ id, name }) => (
              <Box key={`theme${id}`}>
                <Flex width={'95%'} flexDirection={'row'} mb={'8px'}>
                  <Radio
                    data-testid="change-theme-option-radio-button"
                    value={id}
                    label={name}
                  />
                </Flex>

                <ThemeExample>
                  <ThemeBar>
                    <source
                      srcSet={getAssetPath(`/themes/${id}_theme.webp`)}
                      type="image/webp"
                    />
                    <img
                      src={getAssetPath(`/themes/${id}_theme.png`)}
                      alt={t('overview:theme.sidebar-theme', { name })}
                    />
                  </ThemeBar>
                  <ThemePicture>
                    <source
                      srcSet={getAssetPath(`/themes/${id}_header.webp`)}
                      type="image/webp"
                    />
                    <img
                      src={getAssetPath(`/themes/${id}_header.jpg`)}
                      alt={t('overview:theme.sidebar-theme', { name })}
                    />
                  </ThemePicture>
                </ThemeExample>
              </Box>
            ))}
          </Box>
        </RadioGroup>
      </Flex>
    </Modal>
  );
});

export default ChangeThemeModal;
