import React from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { SMBThemeProvider } from 'components';
import SMBRouter from 'utils/router';

import { setupStore } from './store';
import { history } from './store/history';
import { createGlobalStyle } from 'styled-components';

const queryClient = new QueryClient();

const store = setupStore(history);

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${(props) => props.theme.primary.background};
  }
`;

//fix for Safari/Firefox cached page (https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked)
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SMBThemeProvider>
          <GlobalStyle />
          <SMBRouter />
        </SMBThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}
