import {
  space,
  layout,
  typography,
  flexbox,
  position,
  color,
  border,
  HeightProps,
} from 'styled-system';
import styled from 'styled-components/macro';
import {
  SpaceProps,
  ColorProps,
  WidthProps,
  DisplayProps,
  PositionProps,
  FlexProps,
} from 'styled-system';

type BoxProps = ColorProps &
  SpaceProps &
  WidthProps &
  HeightProps &
  PositionProps &
  DisplayProps &
  FlexProps;

const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}
  ${color}
  ${border}
`;

export default Box;
