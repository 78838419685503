import {
  createReducer,
  generateFetchDataReducer,
  generatePostDataReducer,
  POST_DATA_INITIAL_STATE,
} from 'ducks/utils';
import { types } from './actions';
import { combineReducers } from 'redux';
import { FETCH_DATA_INITIAL_STATE } from 'ducks/utils';
import { TemplateType } from 'zsbpsdk/src/templates/index';

const addFile = (state: any, action: any) => {
  if (action.payload.fileType === 'Label') {
    return state;
  }
  return {
    ...state,
    data: [
      ...state.data?.filter((d) => d.id !== action?.payload.id),
      action?.payload,
    ],
  };
};

const addFilteredFile = (state: any, action: any) => {
  if (action.payload.fileType === 'Label') {
    return state;
  }

  if (state.params && state?.params !== '') {
    if (
      action.payload.name
        .toLowerCase()
        .includes(state.params.search.toLowerCase())
    ) {
      return {
        ...state,
        data: [
          ...state.data?.filter((d) => d.id !== action?.payload.id),
          action?.payload,
        ],
      };
    } else {
      return state;
    }
  } else {
    if (!state.data) {
      return state;
    }
    return {
      ...state,
      data: [
        ...state.data?.filter((d) => d.id !== action?.payload.id),
        action?.payload,
      ],
    };
  }
};

const deleteFile = (state: any, action: any) => ({
  ...state,
  data: state?.data?.filter(
    (template: TemplateType) => template.filename !== action.payload.filename,
  ),
});

const all = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ALL),
  [types.ADD.SUCCESS]: addFile,
  [types.REMOVE.SUCCESS]: deleteFile,
  [types.GOOGLE_DRIVE.SUCCESS]: addFile, //TODO What about office???
  [types.SELECTED.FAILURE]: deleteFile,
});

const add = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.ADD),
);

const remove = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.REMOVE),
});

const selected = createReducer(POST_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.SELECTED),
  [types.SELECTED.FAILURE]: (state) => ({
    ...state,
    data: undefined,
    loading: false,
  }),
});

const googleAccount = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.GOOGLE_ACCOUNT),
);

const filtered = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.FILTERED),
  [types.ADD.SUCCESS]: addFilteredFile,
  [types.SELECTED.FAILURE]: deleteFile,
});

export default combineReducers({
  all,
  add,
  remove,
  selected,
  filtered,
  googleAccount,
});
