import styled from 'styled-components';
import { Text, Box } from 'components';

export const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: 'normal',
  letterSpacing: '.4px',
  color: theme.textColors.med,
}))`
  overflow: hidden;
  max-width: 320px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// To show the input arrows initially for Chrome, we add the spinner selectors below:
export const NumberInputWrapper = styled(Box)`
  width: 80px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;
