import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import Flex from 'components/common/flex/Flex';
import { getIsInitialStatusLoading, getIsLoggedIn } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

import LoadingScreen from '../LoadingScreen';

const LoginRoute = ({ component, ...rest }: any) => {
  const isLoggedIn = useReduxSelector(getIsLoggedIn);
  const isLoading = useReduxSelector(getIsInitialStatusLoading);

  if (isLoading) {
    return (
      <Flex
        width="100%"
        height="100vh"
        alignItems="center"
        alignContent="center"
        flexDirection="row"
        justifyContent="center"
      >
        <LoadingScreen />
      </Flex>
    );
  }

  if (isLoggedIn) {
    return <Redirect to={'/home'} />;
  }
  return <Route component={component} {...rest} />;
};

export default LoginRoute;
