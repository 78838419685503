import { Loader } from 'components';
import {
  getAllPreferences,
  getAllPreferencesLoading,
} from 'ducks/preferences/selectors';
import { useReduxSelector } from 'hooks';
import React from 'react';
import DitheringComponent from './Dithering';
import { useTranslation } from 'react-i18next';

export const Common = () => {
  const { t } = useTranslation(['settings']);
  const preferences = useReduxSelector(getAllPreferences);
  const preferencesLoading = useReduxSelector(getAllPreferencesLoading);

  return preferencesLoading ? (
    <Loader visible={true} />
  ) : (
    <DitheringComponent
      title={t('settings:graphic-options')}
      dithering={+preferences.dithering}
    />
  );
};
