import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import { rootSaga } from '../sagas';
import { default as auth } from 'ducks/auth';
import { default as printers } from 'ducks/printers';
import { default as templates } from 'ducks/templates';
import { default as workspaces } from 'ducks/workspaces';
import { default as search } from 'ducks/search';
import { default as categories } from 'ducks/categories';
import { default as labelSizes } from 'ducks/labelSizes';
import { default as dataSources } from 'ducks/dataSources';
import { default as fileDialog } from 'ducks/fileDialog';
import { default as printDialog } from 'ducks/printDialog';
import { default as info } from 'ducks/info';
import { default as iframe } from 'ducks/iframe';
import { default as preferences } from 'ducks/preferences';
import { default as sideBar } from 'ducks/sideBar';
import { default as modalDialog } from 'ducks/modalDialog';
// New converted Slices below
import toast from 'state/Toast';
import { default as pageVisibility } from 'state/PageVisibility';
import { default as notifications } from 'state/Notification';
import { default as announcement } from 'state/Announcement';
import { default as globalUtils } from 'state/GlobalUtils';
import { default as dialog } from 'state/Dialog';

export function setupStore(history) {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    middleware: [sagaMiddleware, routerMiddleware(history), thunkMiddleware],
    preloadedState: {},
    reducer: {
      router: connectRouter(history),
      auth,
      workspaces,
      printers,
      templates,
      labelSizes,
      categories,
      search,
      dataSources,
      fileDialog,
      printDialog,
      info,
      iframe,
      preferences,
      sideBar,
      // New converted Slices below
      toast,
      notifications,
      pageVisibility,
      announcement,
      globalUtils,
      modalDialog,
      dialog,
    },
  });

  window['store'] = store;
  sagaMiddleware.run(rootSaga);

  return store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
