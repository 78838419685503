import styled, { DefaultTheme } from 'styled-components';
import { Box, Text, Icon } from 'components';

export type BannerVariant = 'basic' | 'error' | 'success' | 'warning' | 'info';

type BannerWrapType = { variant: BannerVariant; subtitle?: string };
export const BannerWrap = styled(Box).attrs({ pt: 8, pl: 9 })<BannerWrapType>`
  position: relative;
  border-radius: ${({ theme }) => theme.px(4)};
  width: 100%;
  background-color: ${({ theme, variant }) =>
    calcColor(variant, theme, false, true)};
  padding-bottom: ${({ subtitle }) => (subtitle ? '16px' : '21px')};
`;

export const Message = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: 500;
  font-size: ${({ theme }) => theme.px(8)};
  line-height: ${({ theme }) => theme.px(12)};
  letter-spacing: 0.4px;
`;

export const SubTitle = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: normal;
  font-size: ${({ theme }) => theme.px(7)};
  line-height: 21px;
  letter-spacing: 0.4px;
`;

export const CloseButton = styled(Box)`
  position: absolute;
  right: 21px;
  top: 21px;
  cursor: pointer;
  border: 0 none;
  outline: none;
  background: transparent;
`;

export const TextHolder = styled(Box).attrs({ ml: 7 })`
  width: 85%;
`;

type BannerVariantType = {
  variant: string;
};

export const CloseIcon = styled(Icon).attrs(
  ({ theme, variant }: { theme: DefaultTheme; variant: string }) => ({
    icon: 'close',
    color: calcColor(variant, theme, true),
    size: theme.space[7],
  }),
)<BannerVariantType>``;

export const StyledIcon = styled(Icon).attrs(
  ({ theme, variant }: { theme: DefaultTheme; variant: string }) => ({
    icon: IconMap.get(variant as BannerVariant),
    color: calcColor(variant, theme),
    size: theme.space[10],
  }),
)<BannerVariantType>`
  padding-top: ${({ theme }) => theme.px(1)};
`;

const IconMap: Map<BannerVariant, string> = new Map([
  ['basic', 'error-filled'],
  ['error', 'error-filled'],
  ['success', 'check-filled'],
  ['warning', 'warning'],
  ['info', 'info-filled'],
  ['info', 'info-filled'],
]);

const calcColor = (
  variant: BannerVariant | string,
  theme: DefaultTheme,
  close = false,
  background = false,
) => {
  if (background) return theme[variant]?.background ?? theme.neutral.background;
  if (variant === 'basic') return theme.neutral.base;
  return close ? theme[variant]?.darker : theme[variant]?.base;
};
