import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea<any>`
  max-width: 370px;
  width: calc(100% - 34px);
  resize: none;
  border-radius: 8px;
  overflow: hidden;
  line-height: 24px;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.error.base : theme.neutral.lighter};
  caret-color: ${({ theme }) => theme.primary.base};
  padding: 12px 16px;
  font-family: Roboto;
  font-size: 16px;
  &:focus-within {
    outline: none;
    border: none;
    box-shadow: 0 0 0 2px
      ${({ hasError, theme }: any) =>
        !hasError ? theme.primary.base : theme.error.base};
    transition: all 0.2s ease-in;
  }

  ${({ hasError, theme }) =>
    hasError &&
    `
    background-color: ${theme.error.lightest};
    box-shadow: 0 0 0 1px ${theme.error.base};
    background-color: ${theme.error.lightest};
  `}
`;

const ShadowTextArea = styled.textarea<any>`
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  height: 0;
  top: 0;
  left: 0;
  line-height: 24px;
`;

const TextArea = ({
  rows,
  placeholder,
  value,
  disabled,
  onChange,
  hasError,
  contentEditable,
  ...props
}) => {
  const textRef = useRef<any>();
  const shadowRef = useRef<any>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (value.length < 10) {
      if (height !== 24) setHeight(24);
    } else {
      shadowRef.current.value = textRef.current.value;
      const innerHeight = shadowRef.current.scrollHeight + 14;
      if (height !== innerHeight) {
        setHeight(innerHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <StyledTextArea
        ref={textRef}
        style={{ height: height + 'px' }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        hasError={hasError}
        contenteditable={contentEditable}
        placeholder={placeholder}
        {...props}
      />

      <ShadowTextArea ref={shadowRef} />
    </>
  );
};

export default TextArea;
