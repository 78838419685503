import React from 'react';
import EditSectionHeader from './EditSectionHeader';
import { ThemesList } from './ThemesList';
import { useReduxSelector } from 'hooks';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { useTranslation } from 'react-i18next';

const Theme = ({ match }) => {
  const workspaces = useReduxSelector(getAllWorkspaces);
  const selectedWorkspace = workspaces.find(
    (w) => w?.id?.toString() === match.params.id,
  );
  const { t } = useTranslation('workspaces');

  return (
    <>
      <EditSectionHeader
        title={t('workspaces:theme')}
        subtitle={t('workspaces:change-the-look', {
          name: selectedWorkspace?.name,
        })}
      />
      <ThemesList workspace={selectedWorkspace} />
    </>
  );
};

export default Theme;
