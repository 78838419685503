import React, { forwardRef, useEffect, useCallback } from 'react';
import { useReduxAction, useReduxSelector, useValidation } from 'hooks';
import { Modal, Form, Text, Input, Flex, Button, Box } from 'components';
import {
  getIsLoadingDuplicate,
  getIsDuplicateError,
} from 'ducks/templates/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import validatorsFor from 'utils/validators';
import { DOCUMENT_NOT_ALLOWED_PATTERN } from 'services/api';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { MY_DESIGNS_PATH } from 'utils/config';
import { TEMPLATE_ID_QUERY_PARAM } from 'pages/templates/components/TemplateDialogModal';

const ErrorBox = styled.div`
  width: calc(100% - 16px);
  color: ${({ theme }) => theme.error.base};
  background-color: ${({ theme }) => theme.error.lightest};
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px;
`;

const DuplicateTemplateModal = forwardRef(
  ({ template, isOpen, handleModalClose }: any, ref: any) => {
    const location = useLocation();
    const { t } = useTranslation(['overview', 'common', 'utils']);

    const duplicateTemplate = useReduxAction(
      templatesActions.DUPLICATE.request,
    );
    const duplicateTemplateClear = useReduxAction(
      templatesActions.DUPLICATE.clear,
    );
    const clearDialogTemplate = useReduxAction(
      templatesActions.TEMPLATE_DIALOG.clear,
    );
    const isLoading = useReduxSelector(getIsLoadingDuplicate);
    const isError = useReduxSelector(getIsDuplicateError);
    const pushAction = useReduxAction(push);

    useEffect(() => {
      if (isOpen) {
        onChange({
          target: { value: fields.templateName, name: 'templateName' },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const constraints = {
      templateName: {
        length: {
          minimum: 1,
          message: t('common:character-length', {
            value: `%{value}`,
            count: 1,
          }),
        },
        format: {
          pattern: DOCUMENT_NOT_ALLOWED_PATTERN,
          message: t('utils:characters-not-allowed'),
        },
      },
    };

    const validators = validatorsFor(
      constraints,
      t('utils:characters-not-allowed'),
    );

    const initialFields = {
      templateName: `${template.name} ${t('overview:designs.copy')}`,
    };

    const [fields, errors, hasErrors, onChange] = useValidation(
      initialFields,
      validators,
    );

    const viewDuplicateTemplate = useCallback(
      (filename: string) => {
        pushAction(`${MY_DESIGNS_PATH}?${TEMPLATE_ID_QUERY_PARAM}=${filename}`);
        clearDialogTemplate();
      },
      [clearDialogTemplate, pushAction],
    );

    const handleSubmit = useCallback(() => {
      if (!hasErrors) {
        duplicateTemplate({
          template,
          location,
          name: fields.templateName.trim(),
          onClick: (filename: string) => {
            duplicateTemplateClear();
            viewDuplicateTemplate(filename);
          },
        });
        handleClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      duplicateTemplate,
      fields.templateName,
      hasErrors,
      template,
      location,
      viewDuplicateTemplate,
      duplicateTemplateClear,
    ]);

    const handleClose = useCallback(() => {
      fields.templateName = initialFields.templateName;
      duplicateTemplateClear();
      handleModalClose();
    }, [
      handleModalClose,
      duplicateTemplateClear,
      fields,
      initialFields.templateName,
    ]);

    return (
      <Modal
        data-testid="duplicate-template-modal"
        ref={ref}
        clickOutside={!isLoading}
        onClose={handleClose}
        small
        isAnimatedBackdrop={false}
      >
        <Text mb={8} fontSize={24} fontWeight={300} lineHeight={'32px'}>
          {t('overview:designs.duplicate')}
        </Text>
        <Text fontSize={16} lineHeight={'24px'}>
          {t('overview:designs.keep-organised')}
        </Text>
        <Form onSubmit={handleSubmit}>
          <Box mt={[11]}>
            <Input
              data-testid="input-template-name"
              value={fields.templateName}
              name={'templateName'}
              onChange={onChange}
              hasError={Boolean(errors?.templateName)}
              message={errors?.templateName?.[0] ?? ''}
            />
          </Box>
          {isError && <ErrorBox>{isError?.error?.message}</ErrorBox>}

          <Flex mt={40} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              data-testid="template-cancel-button"
              mr={2}
              type={'reset'}
              onClick={handleClose}
              variant={'text-primary'}
            >
              {t('common:cancel')}
            </Button>
            <Button
              data-testid="template-save-button"
              isLoading={isLoading}
              disabled={hasErrors}
              type={'submit'}
            >
              {isLoading ? t('common:saving') : t('common:save')}
            </Button>
          </Flex>
        </Form>
      </Modal>
    );
  },
);

export default DuplicateTemplateModal;
