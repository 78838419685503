import { Box, Flex, Icon } from 'components';
import IconType from 'components/lib/icon/IconType';
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import Text from '../Text/Text';

export type ListItemProps = {
  /**
   * Text for the list item when it is text only
   */
  title?: string;
  /**
   * Show an addorment on the left
   */
  leftAdornment?: React.ReactNode;
  /**
   * Show an addorment on the right
   */
  rightAdornment?: React.ReactNode;
  /**
   * Disable the list item
   */
  disabled?: boolean;
  /**
   * Show list item in active state
   */
  active?: boolean;
  /**
   * Don't add border to the element
   */
  noBorder?: boolean;
  /**
   * icon
   */
  icon?: IconType;
  /**
   * role
   */
  role?: string;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
};

const LeftAdornment = styled(Box)`
  margin-right: ${({ theme }) => theme.space[4]}px;
`;

const RightAdornment = styled(Box)`
  margin-left: ${({ theme }) => theme.space[4]}px;
`;

export const ListItemWrap = styled(Box).attrs({
  py: 4,
  px: 8,
})<{
  disabled?: boolean;
  active?: boolean;
  $noBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  flex: 1;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.primary.lightest};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    pointer-events: none;
    color: ${theme.textColors.med};
  `}
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.primary.lightest};
  `}
`;

const ListItemContent = styled(Text)<{
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  ${({ disabled, theme }) =>
    disabled &&
    `
    color: ${theme.textColors.med};
    
    & ${StyledIcon} path {
      fill: ${theme.textColors.low} !important;
    }
  `}
`;

const StyledIcon = styled(Icon).attrs(({ theme }: { theme: DefaultTheme }) => ({
  color: theme.textColors.med,
  size: 20,
}))`
  margin-right: 10px;
`;

const ListItem = ({
  leftAdornment,
  rightAdornment,
  onClick,
  disabled = false,
  active = false,
  title,
  noBorder = false,
  icon,
  children,
  ...props
}: ListItemProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    onClick && onClick(e);
  };

  return (
    <ListItemWrap
      as={Flex}
      disabled={disabled}
      active={active}
      onClick={handleClick}
      $noBorder={noBorder}
      {...props}
    >
      {React.isValidElement(leftAdornment) && (
        <LeftAdornment as={Flex}>{leftAdornment}</LeftAdornment>
      )}
      {children ? (
        children
      ) : (
        <ListItemContent disabled={disabled}>
          {icon && <StyledIcon icon={icon} />}
          {title}
        </ListItemContent>
      )}

      {React.isValidElement(rightAdornment) && (
        <RightAdornment as={Flex}>{rightAdornment}</RightAdornment>
      )}
    </ListItemWrap>
  );
};

export default ListItem;
