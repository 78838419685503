import { DOWNLOAD_DB_MIMETYPES } from 'components/common/LDAListener';

export const getMimeTypeIcon = (mimetype: string): string => {
  if (mimetype) {
    if (DOWNLOAD_DB_MIMETYPES.includes(mimetype)) {
      return 'datasource';
    }
    if (mimetype.includes('image')) {
      return 'image';
    }
  }
  return '';
};
