import React, { forwardRef } from 'react';
import { Modal, Loader, Text, Box } from 'components';
import styled from 'styled-components/macro';
import { useReduxSelector } from 'hooks';

import {
  getThumbnail,
  getThumbnailLoading,
  getThumbnailError,
} from 'ducks/templates/selectors';
import { useTranslation } from 'react-i18next';

type TemplateSizePreview = {
  labelWidth: number;
  labelHeight: number;
};

const ModalBody = styled.div`
  width: 375px;
  padding-bottom: 8px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  height: 375px;
  width: 100%;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Error = styled(Text)`
  padding: 24px;
  color: ${({ theme }) => theme.error.base};
  text-align: left;
`;

const EmptyLabelPreview = styled(Box)<TemplateSizePreview>`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.space[4]}px;
  width: ${({ labelWidth, labelHeight }) =>
    labelWidth > labelHeight ? 75 : (labelWidth / labelHeight) * 75}%;
  height: ${({ labelWidth, labelHeight }) =>
    labelWidth < labelHeight ? 75 : (labelHeight / labelWidth) * 75}%;
`;

const MainTemplateModal = forwardRef(
  (
    { template, ButtonsList, etch, handleModalClose, hidden }: any,
    ref: any,
  ) => {
    const thumbnail = useReduxSelector(getThumbnail);
    const thumbnailLoading = useReduxSelector(getThumbnailLoading);
    const thumbnailError = useReduxSelector(getThumbnailError);

    const { t } = useTranslation('overview');

    return (
      <Modal
        data-testid="design-modal"
        ref={ref}
        title={template?.name}
        subTitle={`${etch} ${
          template?.lastPrint !== '' && template?.lastPrint !== undefined
            ? ` | ${t('overview:designs.last-print')}:  ${new Date(
                template?.lastPrint,
              ).toLocaleDateString()}`
            : ''
        }`}
        headerPadding={'24px'}
        defaultPadding={false}
        clickOutside={true}
        onClose={handleModalClose}
        hidden={hidden}
        isAnimatedBackdrop={false}
      >
        <ModalBody>
          <ImageContainer>
            {thumbnailLoading ? (
              <Loader visible={true} />
            ) : thumbnailError ? (
              <Error>{`${thumbnailError}`}</Error>
            ) : thumbnail === null ? (
              <EmptyLabelPreview
                labelWidth={template?.labelSize?.width}
                labelHeight={template?.labelSize?.height}
              ></EmptyLabelPreview>
            ) : (
              <img
                data-testid="template-modal-label-preview"
                src={`data:image/png;base64, ${thumbnail}`}
                alt={t('overview:designs.label')}
              />
            )}
          </ImageContainer>

          {ButtonsList}
        </ModalBody>
      </Modal>
    );
  },
);

export default MainTemplateModal;
