import * as React from 'react';
import { Icon, Text, Card, Flex, Box, Loader } from 'components';
import { ThemeContext } from 'styled-components/macro';

const IconsView = (): any => {
  const theme = React.useContext(ThemeContext);
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Icons</h2>
        <Text fontSize={10} color={theme.error.light}>
          Icon color and size can be set as in this example
        </Text>
        <Flex
          style={{ whiteSpace: 'nowrap' }}
          flexWrap="wrap"
          flexDirection="row"
        >
          <Flex flexDirection="column" m={20} alignContent="center">
            <h3>Navigation</h3>
            <Flex alignItems="center" alignContent="center">
              <Box mr={5}>
                <Icon color={theme.error.base} size={30} icon="overview" />
                <Text fontSize={10}>overview</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="templates" />
                <Text fontSize={10}>templates</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="data-sources" />
                <Text fontSize={10}>data-sources</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="shop" />
                <Text fontSize={10}>shop</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="apps" />
                <Text fontSize={10}>apps</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="help" />
                <Text fontSize={10}>help</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="notifications" />
                <Text fontSize={10}>notifications</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="settings" />
                <Text fontSize={10}>settings</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Directional</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="back-arrow" />
                <Text fontSize={10}>back-arrow</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="checkmark" />
                <Text fontSize={10}>checkmark</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="close" />
                <Text fontSize={10}>close</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="chevron-left" />
                <Text fontSize={10}>chevron-left</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="chevron-right" />
                <Text fontSize={10}>chevron-right</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="chevron-down" />
                <Text fontSize={10}>chevron-down</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="chevron-up" />
                <Text fontSize={10}>chevron-up</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Modifiers</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="drag-handle" />
                <Text fontSize={10}>drag-handle</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Menu</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="hamburger-menu" />
                <Text fontSize={10}>hamburger-menu</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="more" />
                <Text fontSize={10}>more</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Essentials</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="add" />
                <Text fontSize={10}>add</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="subtract" />
                <Text fontSize={10}>subtract</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="refresh" />
                <Text fontSize={10}>refresh</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="link" />
                <Text fontSize={10}>link</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="print" />
                <Text fontSize={10}>print</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="save" />
                <Text fontSize={10}>save</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="upload" />
                <Text fontSize={10}>upload</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="search" />
                <Text fontSize={10}>search</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="view" />
                <Text fontSize={10}>view</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="hide" />
                <Text fontSize={10}>hide</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="select-range" />
                <Text fontSize={10}>select-range</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="logo-zebra" />
                <Text fontSize={10}>logo-zebra</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="circle" />
                <Text fontSize={10}>circle</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="edit" />
                <Text fontSize={10}>edit</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="link-broken" />
                <Text fontSize={10}>link-broken</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="download" />
                <Text fontSize={10}>download</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="cloud-download" />
                <Text fontSize={10}>cloud-download</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="folder-solid" />
                <Text fontSize={10}>folder-solid</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Media control</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="play" />
                <Text fontSize={10}>play</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="full-screen" />
                <Text fontSize={10}>full-screen</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Categories</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="shipping" />
                <Text fontSize={10}>shipping</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>File</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="logo-xms" />
                <Text fontSize={10}>logo-xms</Text>
              </Box>
              <Box mr={5}>
                <Icon icon="logo-google-sheets" />
                <Text fontSize={10}>logo-google-sheets</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Loaders</h3>
            <Flex>
              <Box mr={5}>
                <Icon icon="loader" />
                <Text fontSize={10}>loader</Text>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Flex flexDirection="column" m={20}>
            <h3>Loader component</h3>
            <Flex>
              <Box mr={5}>
                <Loader visible={true} />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default IconsView;
