import React from 'react';
import { Card, Flex, Box, NotificationExpandable } from 'components';

const NotificationsView = (): any => {
  const notification = {
    id: '01EGG3APAAR38HNN4YA6HQM3D6',
    payload: '',
    customerid: '2fb8897f-a74d-492f-b768-50a6e69f6c1b',
    subjectId: 'ATHLON000002',
    type: 4,
    action: 5,
    subject: 3,
    new: true,
    popped: true,
    time: 1598268201775,
    name: '001',
  };

  const handleDismiss = () => {
    console.log('dismiss clicked');
  };

  const handleActionClicked = () => {
    console.log('action clicked');
  };

  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Notifications</h2>
        <Flex flexDirection="column">
          <Box mb={2}>
            <NotificationExpandable
              notification={notification}
              onDismiss={handleDismiss}
              onOpen={handleActionClicked}
            />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default NotificationsView;
