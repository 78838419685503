import React from 'react';
import styled from 'styled-components';
import { Flex } from 'components';

export type ListProps = React.PropsWithChildren<{
  className?: string;
}>;

/**
 * Lists display a set of related content. Each list item can begin with an icon.
 */
const ListContainer = styled(Flex)`
  margin: 0;
  padding: 0;
`;

const List = ({ children, ...props }: ListProps) => {
  return (
    <ListContainer flexDirection={'column'} {...props}>
      {children}
    </ListContainer>
  );
};

export default List;
