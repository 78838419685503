import { createReducer, BaseAction } from 'ducks/utils';
import { types } from './actions';

const DIALOG_INITIAL_STATE = {
  open: false,
  network: undefined,
  fileType: undefined,
};

const toast = createReducer(DIALOG_INITIAL_STATE, {
  [types.OPEN]: (state: any, action: BaseAction) => ({
    ...state,
    open: true,
    ...action.payload,
  }),
  [types.CLOSE]: (state: any, action: BaseAction) => ({
    ...state,
    ...DIALOG_INITIAL_STATE,
  }),
});

export default toast;
