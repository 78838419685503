import React, { useState } from 'react';
import { Card, Flex, Slider } from 'components';
import { useTranslation } from 'react-i18next';

const SliderView = (): any => {
  const [value, setValue] = useState(3);
  const { t } = useTranslation('common');

  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>{t('common:slider')}</h2>
        <Flex width={'310px'} flexDirection="row">
          <Slider
            min={0}
            max={6}
            value={value}
            labelStart={t('common:slider-label-light')}
            labelEnd={t('common:slider-label-dark')}
            onChange={(v) => setValue(v)}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default SliderView;
