import styled from 'styled-components';
import { Card, Flex, Text, Box } from 'components';

export const CardWrap = styled(Card).attrs({
  elevation: 'one',
  p: 'unset',
  flexDirection: 'column',
  alignItems: 'start',
  mr: 8,
  mb: 4,
  mt: 4,
})`
  padding: ${({ theme }) =>
    theme &&
    `${theme.space[8]}px ${theme.space[8]}px ${theme.space[11]}px ${theme.space[9]}px`};
  border: 1px solid ${({ theme }) => theme.neutral.lightest};
  cursor: pointer;
  width: 247px;
  transform: translateY(0);
  transition: transform 0.3s, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 16px 32px rgba(73, 80, 94, 0.16);
  }
`;

export const IconWrap = styled(Flex).attrs({ mb: 8 })`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary.lightest};
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Text).attrs(({ theme }) => ({
  fontSize: 18,
  lineHeight: '28px',
  color: theme.textColors.high,
  letterSpacing: 0.4,
  mb: 4,
}))``;

export const DescriptionWrap = styled(Box).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))`
  width: 100%;
  line-height: 1.5;
`;

export const Description = styled(Text).attrs(({ theme }) => ({
  fontSize: 14,
  letterSpacing: 0.4,
}))`
  overflow: hidden;
  text-overflow: ellipsis;
`;
