import { FileInfoType } from 'utils/dataSourcesTypes';
import tokenStorage from 'services/tokenStorage';
import {
  addTemplate,
  DB_FILE_NAME,
  getCloudDatabase,
  getLocalData as _getLocalData,
  setCloudDatabase,
} from 'services/api';
import { getUnixTime } from 'utils/dateFormatter';
import { MS_PROVIDER_LABEL } from './office365';
import {
  ALL_MIMETYPES,
  CLOUD_FILES_FIELDS,
  IMAGE_MIMETYPES,
} from '../components/common/LDAListener';
import { CustomError } from '../utils/errorFormatter';
import i18n from 'i18next';
import googleApi from './google/googleApi';
import { GOOGLE_PROVIDER_LABEL } from '../utils/file/GoogleDriveFileProvider';

const DBG = ['%c CLOUD-DB ', 'background:lightskyblue'];
export const FILE_ALREADY_EXISTS = 'fileAlreadyExists';

let cache;

export const getExternalFiles = async (
  customerId: string,
  workspaceId: string,
): Promise<FileInfoType[]> => {
  console.debug(...DBG, 'getExternalFiles');
  return await _getFiles(customerId, workspaceId);
};

export const getExternalFilesFiltered = async (
  search,
  customerId: string,
  workspaceId: string,
): Promise<FileInfoType[]> => {
  console.debug(...DBG, 'getExternalFilesFiltered');
  const files = await _getFiles(customerId, workspaceId);
  if (!search || search.length === 0) return files;
  let searched: any[] = [];
  const lowerSearch = search.toLowerCase();
  files.forEach((obj) => {
    const lowerName = obj.name.toLowerCase();
    if (lowerName.includes(decodeURIComponent(lowerSearch))) {
      searched.push(obj);
    }
  });
  return searched;
};

export const addGoogleDriveFile = async (
  fileInfo: any,
  customerId: string,
  workspaceId: string,
): Promise<any> => {
  console.debug(...DBG, 'addGoogleDriveFile');
  const fileTra: FileInfoType = {
    id: fileInfo.id,
    mimetype: fileInfo.mimeType,
    name: fileInfo.name,
    provider: GOOGLE_PROVIDER_LABEL,
    lastModified: Math.floor(Date.now() / 1000),
    dimensions: fileInfo?.dimensions,
  };
  if (IMAGE_MIMETYPES.includes(fileInfo.mimeType)) {
    const binary = await googleApi.getFileFromDrive({
      ...fileInfo,
      fileType: fileInfo.mimeType,
    });

    await new Promise((res, rej) => {
      let img = new Image();
      img.onload = async function () {
        try {
          const width = img.width;
          const height = img.height;

          fileTra.dimensions = { width, height };
          await add(fileTra, customerId, workspaceId);
          res(fileTra);
        } catch (e: any) {
          rej(e);
        }
      };
      img.onerror = rej;
      img.src = `data:${fileInfo.mimeType};base64,${binary}`;
    });
  } else {
    fileTra.dimensions = fileInfo?.dimensions;
    await add(fileTra, customerId, workspaceId);
  }
  return fileTra;
};

export const addOfficeFile = async (
  fileInfo: any,
  customerId: string,
  workspaceId: string,
  microsoftAccount: string,
): Promise<FileInfoType> => {
  console.debug(...DBG, 'addOfficeFile');
  const files: FileInfoType[] = fileInfo.value.map(
    ({ file, id, image, name }) => ({
      id,
      mimetype: file.mimeType,
      name,
      dimensions: { width: image?.width, height: image?.height },
      lastModified: Math.floor(Date.now() / 1000),
      provider: MS_PROVIDER_LABEL,
      linkedMicrosoftAccount: microsoftAccount,
    }),
  );
  await add(files[0], customerId, workspaceId);
  return files[0]; //Whats this array about?
};

export const getFileById = async (
  inputId: string,
  customerId: string,
  workspaceId: string,
): Promise<any> => {
  console.debug(...DBG, 'getFileById in', inputId);
  let localCache = cache || (await _getFiles(customerId, workspaceId));
  if (localCache) {
    let cached = cache.find(({ id }) => id === inputId);
    if (cached) return cached;
  }
  return null;
};

export const getLocalData = async (
  id: string,
  customerId: string,
  workspaceId: string,
): Promise<any> => {
  let { token } = tokenStorage.get();
  return _getLocalData(id, customerId, workspaceId, token);
};

export const _getFiles = async (
  customerId: string,
  workspaceId: string,
): Promise<FileInfoType[]> => {
  console.debug(...DBG, 'Getfiles+');
  let { token } = tokenStorage.get();

  try {
    let content: any = JSON.parse(
      await getCloudDatabase(customerId, workspaceId, token),
    );
    let fileList = Object.keys(content).map((key) => {
      let value = content[key];
      return {
        id: key + '', //TODO Check
        name: decodeURIComponent(value[CLOUD_FILES_FIELDS.name]),
        lastModified: getUnixTime(
          new Date(value[CLOUD_FILES_FIELDS.lastModified]),
        ), //TODO do we need to * 1000?
        mimetype: value[CLOUD_FILES_FIELDS.mimetype],
        provider: value[CLOUD_FILES_FIELDS.provider],
        linkedMicrosoftAccount:
          value[CLOUD_FILES_FIELDS.linkedMicrosoftAccount],
      };
    });
    console.debug(...DBG, 'Getfiles-');
    cache = fileList;
    return fileList;
  } catch (e: any) {
    console.debug(...DBG, 'Getfiles- No cloud data sources connected');
    return [];
  }
};

const filesOverlapping = ({ id, name, provider }, file) =>
  id === file.id || (name === file.name && provider === file.provider);

export const add = async (
  file: FileInfoType,
  customerId: string,
  workspaceId: string,
): Promise<FileInfoType[]> => {
  console.debug(...DBG, 'AddFile+');
  if (file.mimetype != null && !ALL_MIMETYPES.includes(file.mimetype)) {
    console.debug(...DBG, 'AddFile: Unsupported File Type: ' + file.mimetype);
    throw Error('Unsupported file type');
  }
  let fileList: FileInfoType[] = await _getFiles(customerId, workspaceId);
  if (!fileList.find((srcFile) => filesOverlapping(srcFile, file))) {
    fileList.push(file);
    await _saveDB(fileList, customerId, workspaceId);
  } else {
    throw new CustomError(FILE_ALREADY_EXISTS, FILE_ALREADY_EXISTS, file);
  }
  console.debug(...DBG, 'AddFile-');

  return fileList;
};

export const overwriteFile = async (
  file: FileInfoType,
  customerId: string,
  workspaceId: string,
): Promise<FileInfoType[]> => {
  console.debug(...DBG, 'OverwriteFile+');
  if (
    file.mimetype != null &&
    !ALL_MIMETYPES.concat('image').includes(file.mimetype)
  ) {
    console.debug(...DBG, 'AddFile: Unsupported File Type: ' + file.mimetype);
    throw Error('Unsupported file type');
  }
  let fileList: FileInfoType[] = await _getFiles(customerId, workspaceId);
  const fileToReplaceIndex = fileList.findIndex((srcFile) =>
    filesOverlapping(srcFile, file),
  );
  fileList.splice(
    fileToReplaceIndex !== -1 ? fileToReplaceIndex : fileList.length - 1,
    1,
    file,
  );
  await _saveDB(fileList, customerId, workspaceId);
  console.debug(...DBG, 'OverwriteFile-');
  return fileList;
};

export const _saveDB = async (
  fileList: FileInfoType[],
  customerId: string,
  workspaceId: string,
) => {
  console.debug(...DBG, 'SaveDB+');
  let { token } = tokenStorage.get();
  let fileMap = {};
  fileList.forEach(
    ({
      id,
      name,
      lastModified,
      mimetype,
      provider,
      dimensions = { width: 0, height: 0 },
      linkedMicrosoftAccount,
    }: FileInfoType) => {
      let structure = {
        id,
        name: encodeURIComponent(name),
        dateAdded: new Date(lastModified * 1000).toISOString(),
        mimeType: mimetype,
        dataSource: provider,
        linkedMicrosoftAccount,
      };
      if (dimensions.width !== 0) structure['dimensions'] = dimensions;
      fileMap[id] = structure;
    },
  );
  try {
    await setCloudDatabase(
      JSON.stringify(fileMap),
      customerId,
      workspaceId,
      token,
    );
  } catch (e: any) {
    try {
      await addTemplate(
        btoa(JSON.stringify(fileMap)),
        DB_FILE_NAME,
        customerId,
        workspaceId,
        token,
        true,
      );
    } catch (err: any) {
      console.debug(...DBG, 'Error in saving Database', err);
    }
  }
  cache = fileList;
  console.debug(...DBG, 'SaveDB-');
};

export const deleteFile = async (
  fileId: string,
  customerId: string,
  workspaceId: string,
): Promise<boolean> => {
  console.debug(...DBG, 'Remove+');
  let fileList: FileInfoType[] = await _getFiles(customerId, workspaceId);
  let filteredList: FileInfoType[] = fileList.filter(({ id }) => id !== fileId);
  if (filteredList.length === fileList.length) {
    throw Error(i18n.t('utils:file-deleted'));
  } else {
    await _saveDB(filteredList, customerId, workspaceId);
  }
  console.debug(...DBG, 'Remove-');
  return true;
};
