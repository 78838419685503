import React, { useMemo, useRef } from 'react';

import { Flex, Box } from 'components';
import { useRefDimensions } from 'hooks';

import CarouselProvider, { useCarousel } from './CarouselContext';
import {
  GAP,
  Slides,
  HiddenMask,
  ArrowWrapper,
  RightLeftType,
  LeftArrow,
  RightArrow,
  Dot,
  DotContainer,
} from './Carousel.styles';

type CarouselProps = {
  stepSize: 1 | 2;
  totalSlides: number;
  slideMinWidth?: number;
  showArrows?: boolean;
  showDots?: boolean;
};

export default function Carousel({
  children,
  stepSize,
  totalSlides,
  slideMinWidth,
  showArrows = true,
  showDots = true,
}: React.PropsWithChildren<CarouselProps>) {
  return (
    <CarouselProvider
      total={totalSlides}
      stepSize={stepSize}
      slideMinWidth={slideMinWidth}
      showArrows={showArrows}
      showDots={showDots}
    >
      <CarouselContent>{children}</CarouselContent>
    </CarouselProvider>
  );
}

function CarouselContent({ children }) {
  const [{ current, stepSize, total, slideMinWidth, showArrows }] = useCarousel();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useRefDimensions(containerRef, [stepSize]);

  const slideWidth = Math.max(
    (containerWidth - stepSize * GAP) / stepSize,
    slideMinWidth || 0,
  );
  const totalWidth = total * (slideWidth + GAP);

  const offsetWidth = useMemo(() => {
    let offset = (containerWidth - slideWidth) / 2;
    if (current + 1 === Math.ceil(total / stepSize) // Is last page of carousel
      && total % 2 === 1 // Odd number of slides, meaning last slide is by itself
    ) {
      offset -= GAP / 2; // Offset by container's left margin to correct centering
    }
    return offset;
  }, [containerWidth, slideWidth, stepSize, current, total]);

  return (
    <Box>
      <Flex pb={8}>
        <Flex alignItems={'center'} mr={4}>
          <Arrow direction="left" showArrows={showArrows} />
        </Flex>
        <HiddenMask ref={containerRef}>
          <Slides
            width={totalWidth}
            $offsetWidth={offsetWidth}
            $translate={current * stepSize * (slideWidth + GAP)}
          >
            {children}
          </Slides>
        </HiddenMask>
        <Flex alignItems={'center'} ml={4}>
          <Arrow direction="right" showArrows={showArrows} />
        </Flex>
      </Flex>
      <Dots />
    </Box>
  );
}

type ArrowProps = {
  direction: RightLeftType;
  showArrows?: boolean;
};

function Arrow({ direction = 'right', showArrows = true }: ArrowProps) {
  const [{ current, total, stepSize }, { next, prev }] = useCarousel();

  const disabled = React.useMemo<boolean>(() => {
    return (
      !total ||
      (direction === 'left' && current === 0) ||
      (direction === 'right' && current >= Math.ceil(total / stepSize) - 1)
    );
  }, [current, direction, total, stepSize]);

  const onClick = () => {
    if (disabled) {
      return;
    }

    direction === 'right' ? next() : prev();
  };

  return (
    <ArrowWrapper
      $disabled={disabled}
      isVisible={showArrows}
      onClick={onClick}
      data-testid={`carouselArrow-${direction}`}
    >
      {direction === 'right' ? <RightArrow /> : <LeftArrow />}
    </ArrowWrapper>
  );
}

function Dots() {
  const [{ current, total, stepSize, showDots }, { setCurrent }] = useCarousel();
  const slidesCount = Math.ceil(total / stepSize);

  return (
    <DotContainer isVisible={showDots}>
      {[...Array(slidesCount)].map((_, i) => (
        <Dot
          data-testid={`dot-${i}`}
          key={i}
          active={current === i}
          onClick={() => {
            setCurrent(i);
          }}
        />
      ))}
    </DotContainer>
  );
}
