import styled from 'styled-components';

import { Card } from 'components';

export const MenuCardWrap = styled(Card).attrs({
  elevation: 'one',
  p: 'unset',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'baseline',
})`
  padding: ${({ theme }) =>
    theme &&
    `${theme.space[8]}px ${theme.space[8]}px ${theme.space[8]}px ${theme.space[8]}px`};
  border: 1px solid ${({ theme }) => theme.neutral.lightest};
  cursor: pointer;
  width: 100%;
  height: ${({ theme }) => theme.px(80)};
  border-radius: ${({ theme }) => theme.px(2)};
  transform: translateY(0);
  transition: transform 0.3s, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.px(0, 8, 16)} rgba(73, 80, 94, 0.16);
  }
`;
