import styled from 'styled-components';

import { Box } from 'components';

import { PrinterStatus } from '../PrintersList/utils';
import OnlineIcon from './OnlineIcon';
import OfflineIcon from './OfflineIcon';
import CoverOpenIcon from './CoverOpenIcon';
import WarningIcon from './WarningIcon';
import RedExclamationIcon from './RedExclamationIcon';

type CustomPrinterStatusGraphicProps = {
  status: PrinterStatus;
};

const PrinterStatusDot = styled(Box)`
  background-color: ${({ theme }) => theme.neutral.base};
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-top: 1px;
  cursor: arrow;
`;

const PrinterStatusGraphic = ({ status }: CustomPrinterStatusGraphicProps) => {
  if (status === PrinterStatus.Online) return <OnlineIcon />;
  else if (status === PrinterStatus.Offline) return <OfflineIcon />;
  else if (status === PrinterStatus.CoverOpen) return <CoverOpenIcon />;
  else if (
    status === PrinterStatus.MediaLow ||
    status === PrinterStatus.BufferFull
  )
    return <WarningIcon />;
  else if (
    status === PrinterStatus.MediaOut ||
    status === PrinterStatus.UnableToPrint ||
    status === PrinterStatus.PaperOut
  )
    return <RedExclamationIcon />;

  return <PrinterStatusDot />;
};

export default PrinterStatusGraphic;
