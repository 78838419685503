import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';

import { useReduxAction } from 'hooks';
import { DialogHeader } from 'v2/components/app';

import Card from './Card';
import { DialogContent } from './Dialog.styles';
import UploadTemplateCard from './UploadTemplateCard';

function PrintPage() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);
  const { url } = useRouteMatch();

  return (
    <>
      <DialogHeader title={t('dialogs:print-label.title')} />
      <DialogContent>
        <UploadTemplateCard />
        <Card
          data-testid={'choose-templates'}
          title={t('dialogs:print-label.choose-template')}
          caption={t('dialogs:print-label.print-from-our-collection')}
          icon={'choose-templates'}
          mb={8}
          onClick={() => pushAction(`${url}/common-designs`)}
        />
        <Card
          data-testid={'my-labels'}
          title={t('dialogs:print-label.my-label-designs')}
          caption={t('dialogs:print-label.print-from-your-collection')}
          icon={'my-labels'}
          mb={8}
          onClick={() => pushAction(`${url}/my-designs`)}
        />
        <Card
          data-testid={'recently-printed'}
          title={t('dialogs:print-label.recently-printed')}
          caption={t('dialogs:print-label.print-recently-labels')}
          icon={'recently-printed'}
          mb={8}
          onClick={() => pushAction(`${url}/recent`)}
        />
        <Card
          data-testid={'print-test-label'}
          title={t('dialogs:print-label.print-test-label')}
          caption={t('dialogs:print-label.print-test-label-caption')}
          icon={'contrast'}
          onClick={() => pushAction(`${url}/test`)}
        />
      </DialogContent>
    </>
  );
}

export default PrintPage;
