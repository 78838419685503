import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from './CategoryCard.styles';

type LabelCartridgeAttributes = {
  naPartNumber: string;
  emeaPartNumber: string;
};

export const LabelCartridge = ({
  naPartNumber,
  emeaPartNumber,
}: LabelCartridgeAttributes) => {
  const { t } = useTranslation('templates');

  const computedSKU = useMemo(() => {
    let SKU: any = {};
    if (naPartNumber && naPartNumber !== '') {
      SKU.naPartNumber = naPartNumber;
    }
    if (emeaPartNumber && emeaPartNumber !== '') {
      SKU.emeaPartNumber = emeaPartNumber;
    }
    return SKU;
  }, [naPartNumber, emeaPartNumber]);
  return (
    <>
      <Description
        data-testid="category-card-sku-label"
      >
        {t('templates:label-cartridge-sku', {
          count: Object.values(computedSKU).length,
        })}
      </Description>

      <Description
        data-testid="category-card-sku"
      >
        {Object.values(computedSKU).join(', ')}
      </Description>
    </>
  );
};
