import React from 'react';
import { Text } from 'components';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  margin: 2px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const IconImage = styled.img`
  height: 60px;
  width: 60px;
`;
const IconText = styled(Text).attrs(() => ({
  fontSize: 16,
  lineHeight: '24px',
  color: '#2F80ED',
  letterSpacing: 0.4,
  textAlign: 'center',
}))`
  padding-top: 8px;
`;

const IconButton = ({ name, path, setClipArt }) => {
  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );
  };

  const handleClick = async () => {
    const base64 = await toDataURL(path);
    setClipArt({ name: name, base64: base64 });
  };

  return (
    <Wrapper onClick={handleClick}>
      <IconImage src={path} alt={name} />
      <IconText textAlign="start">{name}</IconText>
    </Wrapper>
  );
};

export default IconButton;
