import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

import { Text, Flex, Box } from 'components';

export const Container = styled(Flex)`
  align-items: center;
  overflow: visible;
  position: relative;
  padding: 0;
  padding: 25px;
  height: 160px;
  box-shadow: ${({ theme }) => theme.elevations.three};
  border-radius: ${({ theme }) => theme.px(4)};
  background-color: ${({ theme }) => theme.white};
`;

export const DefaultText = styled(Text).attrs(({ theme }) => ({
  color: theme.neutral.darkest,
}))<{ $fontSize?: number; $lineHeight?: number }>`
  font-size: ${({ $fontSize }) => $fontSize || 16}px;
  font-weight: 300;
  letter-spacing: 0.4px;
  line-height: ${({ $lineHeight }) => $lineHeight || 22}px;
`;

export const TruncatedText = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PrinterName = styled(DefaultText)`
  display: inline;
  white-space: nowrap;
  font-size: 18px;
`;

export const MinWidthFlex = styled(Flex)`
  min-width: 0;
`;

export const PrinterAvatar = styled.img.attrs((props) => ({
  src: props.src,
}))`
  @media ${device.desktop} {
    width: 135px;
  }
  @media ${device.tablet} {
    width: 105px;
  }
  @media ${device.mobile} {
    width: 95px;
  }
`;
