import styled from 'styled-components';

import { device } from 'utils/mediaQueries';
import { TemplatesGrid } from 'v2/components/app';
import { TemplatesWrap } from 'v2/components/app/TemplatesGrid/TemplatesGrid.styles';

const StyledTemplatesGrid = styled(TemplatesGrid)`
  & ${TemplatesWrap} {
    grid-template-columns: 1fr 1fr;
    @media ${device.desktopM} {
      grid-template-columns: 1fr;
    }
    @media ${device.tablet} {
      grid-template-columns: 1fr;
    }
  }
`;

export default StyledTemplatesGrid;
