import {
  generateDataTypes,
  generateDataActions,
  generateSelectedDataTypes,
  generateSelectedDataActions,
} from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_LABELSIZES_';

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  SELECTED: generateSelectedDataTypes(BASE_DUCK_PREFIX + 'SELECTED'),
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  SELECTED: generateSelectedDataActions(types.SELECTED),
};
