import { createReducer, BaseAction } from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';

const DIALOG_INITIAL_STATE = {
  open: false,
  templateFilename: undefined,
};

const dialog = createReducer(DIALOG_INITIAL_STATE, {
  [types.OPEN]: (state: any, action: BaseAction) => ({
    ...state,
    open: true,
    ...action.payload,
  }),
  [types.CLOSE]: (state: any, action: BaseAction) => ({
    ...state,
    ...DIALOG_INITIAL_STATE,
  }),
});

export default combineReducers({
  dialog,
});
