import React, { forwardRef, useState, useEffect } from 'react';
import { Modal } from 'components';
import { useReduxAction } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const ModalBody = styled.div`
  width: 335px;
  padding: 0px 40px 16px;
`;

export const LogoutModal = forwardRef(({ isOpen }: any, ref: any) => {
  const { t } = useTranslation('settings');

  const [time, setTime] = useState(5);
  const logout = useReduxAction(authActions.LOGOUT);

  useEffect(() => {
    if (isOpen) {
      let n = 5;
      const interval = setInterval(() => {
        setTime(n--);
        if (n === 0) {
          clearInterval(interval);
          logout();
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      defaultPadding={false}
      ref={ref}
      title={t('settings:account-updated')}
      hasCloseButton={false}
      headerPadding="32px 40px 16px"
      clickOutside={false}
    >
      <ModalBody>{t('settings:logged-out-time', { count: time })}</ModalBody>
    </Modal>
  );
});

export default LogoutModal;
