import React, { Suspense, useLayoutEffect, useState, useMemo } from 'react';
import { useReduxSelector, useWindowDimensions } from 'hooks';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Box, Loader } from 'components';
import {
  selectAllPrinters,
  selectIsLoadingPrinters,
} from 'ducks/printers/selectors';
import type { PrinterType } from 'zsbpsdk/src/printer';

import Text from 'v2/components/common/Text/Text';
import PrinterCard from './PrinterCard';
import Carousel from './Carousel';
import { Slide } from './Carousel.styles';
import { Container } from './PrinterCard.styles';

const slideMinWidth = 400;

const NoPrinters = styled(Box)`
  width: 100%;
  text-align: center;
`;

enum PrinterListState {
  Loading,
  NoPrinters,
  PrintersFound,
}

export default function PrintersList() {
  const { t } = useTranslation('components');
  const printers: PrinterType[] = useReduxSelector(selectAllPrinters);
  const { width } = useWindowDimensions();
  const [stepSize, setStepSize] = useState<1 | 2>(2);
  const printersLoading = useReduxSelector(selectIsLoadingPrinters);

  useLayoutEffect(() => {
    // if screen width is less than desktop - we display one printer
    // desktop width is defined in mediaQueries.ts
    const newStep = width < 976 ? 1 : 2;

    if (stepSize !== newStep) {
      setStepSize(newStep);
    }
  }, [width, stepSize]);

  const printerListState: PrinterListState = useMemo(() => {
    if (printers.length > 0 && !printersLoading) {
      return PrinterListState.PrintersFound;
    }
    if (printersLoading) {
      return PrinterListState.Loading;
    } else if (!printers.length) {
      return PrinterListState.NoPrinters;
    } else {
      return PrinterListState.PrintersFound;
    }
  }, [printersLoading, printers]);

  const slides = useMemo(() => {
    switch (printerListState) {
      case PrinterListState.Loading:
      default:
        return (
          <Slide $minWidth={slideMinWidth}>
            <Container>
              <Loader visible={true} />
            </Container>
          </Slide>
        );
      case PrinterListState.NoPrinters:
        return (
          <Slide $minWidth={slideMinWidth}>
            <Container>
              <NoPrinters>
                <Text variant={'h2'}>
                  {t('components:printer.messages.no-printers-available')}
                </Text>
              </NoPrinters>
            </Container>
          </Slide>
        );
      case PrinterListState.PrintersFound:
        return printers.map(function (printer) {
          return (
            <Slide $minWidth={slideMinWidth} key={printer.uniqueId}>
              <PrinterCard printer={printer} />
            </Slide>
          );
        });
    }
  }, [printers, printerListState, t]);

  return (
    <Suspense fallback={'load'}>
      <Box>
        <Carousel
          stepSize={stepSize}
          totalSlides={
            printerListState === PrinterListState.Loading
              ? 1
              : printers.length || 1
          }
          slideMinWidth={slideMinWidth}
          showArrows={printerListState === PrinterListState.PrintersFound}
          showDots={printerListState === PrinterListState.PrintersFound}
        >
          {slides}
        </Carousel>
      </Box>
    </Suspense>
  );
}
