import React, { MouseEventHandler } from 'react';

import styled from 'styled-components';

import Flex from 'components/common/flex/Flex';
import Icon from 'components/lib/icon/Icon';
import IconType from 'components/lib/icon/IconType';

import { MenuCardWrap } from './MenuCard.styles';
import Text from '../Text/Text';

type CustomMenuCardProps = {
  title: string;
  description: string;
  icon?: IconType;
  onClick: MouseEventHandler;
};

const CardIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.px(2)};
  color: ${({ theme }) => theme.neutral.darkest};
`;

const MenuCard = ({
  title,
  description,
  icon,
  onClick,
}: CustomMenuCardProps) => {

  const setButtonTestID = (title: string) => {
    let titleNoSpace = title.replace(/\s+/g, '-').toLowerCase();
    return (
      titleNoSpace
    );
  };

  return (
    <MenuCardWrap data-testid="menu-card" onClick={onClick}>
      <Flex flexDirection={'column'}>
        <Flex
          alignItems={'center'}
          data-testid={setButtonTestID(title)}
        >
          {icon && <CardIcon icon={icon} />}

          <Text maxWidth={408} variant={'h3'}>
            {title}
          </Text>
        </Flex>

        <Text maxWidth={408}>{description}</Text>
      </Flex>
    </MenuCardWrap>
  );
};

export default MenuCard;
