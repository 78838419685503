import { HelpChatField } from './HelpChatField.enum';
import { HelpChatCustomField } from './HelpChatCustomField.enum';
import { HelpChatLabel } from './HelpChatLabel.enum';
import { HelpChatEntity } from './HelpChatEntity.enum';
import { isBackendStageOrProduction } from '../../../utils/config';

declare global {
  interface Window {
    embedded_svc: any;
    liveagent: any;
    liveAgentDeployment: any;
    _laq: any;
  }
}

let firstName = '';
let email = '';
let lastName = '';
let id = '';
let country = '';
let printersList = '';

const MoneyBadgerChatOrigin = 'MoneyBadger_Chat_MyPortal_MobileApp_English';
const buttonId = '5730H0000002gOs';
const deploymentId = '5720H0000002cjj';
const eswLiveAgentDevName =
  'EmbeddedServiceLiveAgent_Parent04I0H000000fxtyUAA_176e5f64816';
const organisationId = '00Di0000000KxK7';

export const CONTENT_URL_STAGE_PRODUCTION =
  'https://c.la1-c2-iad.salesforceliveagent.com/content';
export const CONTENT_URL_DEVELOPMENT =
  'https://c.la3-c1cs-ia5.salesforceliveagent.com/content';

export const CHAT_URL_STAGE_PRODUCTION =
  'https://d.la4-c1-ia4.salesforceliveagent.com/chat';
export const CHAT_URL_DEVELOPMENT =
  'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat';

export const getLiveAgentContentUrl = () =>
  isBackendStageOrProduction()
    ? CONTENT_URL_STAGE_PRODUCTION
    : CONTENT_URL_DEVELOPMENT;
export const getLiveAgentChatUrl = () =>
  isBackendStageOrProduction()
    ? CHAT_URL_STAGE_PRODUCTION
    : CHAT_URL_DEVELOPMENT;

const getUserInfo = (
  userFirstName,
  userEmail,
  userId,
  userLastName,
  userCountry,
  userPrintersList,
) => {
  firstName = userFirstName;
  email = userEmail;
  id = userId;
  lastName = userLastName;
  country = userCountry;
  printersList = userPrintersList;
};

const setup = (onlineButton, offlineButton, loadingButton, callback) => {
  const contentUrl = getLiveAgentContentUrl();

  //chatUnreadMessage event
  //https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_minimized_events.html
  Promise.all([
    loadScript(`${contentUrl}/g/js/47.0/deployment.js`),
    loadScript(`${contentUrl}/g/js/47.0/prechat.js`),
    loadScript(
      'https://zebra.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    ),
  ])
    .then(() => {
      if (!window._laq) {
        window._laq = [];
      }
      window._laq.push(() => {
        window.liveagent.showWhenOnline(buttonId, onlineButton);
        window.liveagent.showWhenOffline(buttonId, offlineButton);
        if (loadingButton) {
          loadingButton.style.display = 'none';
        }
      });

      // Chat deployment code that initializes chat for the deployment whose id is 572R00000004Gj2 and org is 00DD01234567890
      window.liveagent.init(
        getLiveAgentChatUrl(),
        deploymentId,
        organisationId,
      );
      // Enable Chat advanced logging to be available through the Browser's Developer Console
      window.liveagent.enableLogging();

      callback();
    })
    .catch((e) => {
      console.error('Chatbox library loading failed', e);
    });
};

const teardown = () => {
  delete window.liveagent;
  delete window.liveAgentDeployment;
};

const loadScript = (url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.addEventListener('load', () => {
      resolve(url);
    });
    script.addEventListener('error', () => {
      console.error('Script rejected', url);
      reject(url);
    });
    script.src = url;
    document.body.appendChild(script);
  });
};

const activateChat = () => {
  if (!window.embedded_svc) {
    loadScript(
      'https://zebra--t03sfdc.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    )
      .then(() => {
        initializeEmbeddedService(null);
      })
      .catch(() => {
        console.error(`Activate Chat Script couldn't be loaded`);
      });
  } else {
    initializeEmbeddedService('https://service.force.com');
  }
};

const toggleMessageCountBubble = () => {
  const messageCountBubble = document.querySelector(
    '.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message',
  );
  if (!messageCountBubble || !messageCountBubble.parentNode) {
    return;
  }

  const isNumber = messageCountBubble.textContent?.match(/^[0-9]+\s.*/);
  (messageCountBubble.parentNode as HTMLElement).style.display = isNumber
    ? 'block'
    : 'none';
};

const setupMessageCountBubbleListeners = () => {
  [
    'afterMinimize',
    'onAgentRichMessage',
    'onAgentMessage',
    'onQueueUpdate',
    'onClickSubmitButton',
    'onChatConferenceInitiated',
  ].forEach((eventName) => {
    window.embedded_svc.addEventHandler(eventName, toggleMessageCountBubble);
  });
};

const createContactFieldMap = (
  fieldName: HelpChatField | HelpChatCustomField,
  label: HelpChatLabel,
) => ({
  fieldName,
  label,
  doCreate: false,
  doFind: false,
  isExactMatch: true,
});

const createCaseFieldMap = (
  fieldName: HelpChatField | HelpChatCustomField,
  label: HelpChatLabel,
) => ({
  fieldName,
  label,
  doCreate: true,
  doFind: false,
  isExactMatch: false,
});

const initializeEmbeddedService = (gslbBaseURL) => {
  window.embedded_svc.settings = {
    ...window.embedded_svc.settings,
    displayHelpButton: false,
    language: '', // For example, enter 'en' or 'en-US'
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',

    // Sets the auto-population of pre-chat form fields
    prepopulatedPrechatFields: {
      [HelpChatField.FirstName]: firstName,
      [HelpChatField.LastName]: lastName,
      [HelpChatField.Email]: email,
      [HelpChatField.Zuid]: id,
      [HelpChatField.ContactStatus]: 'Active',
      [HelpChatCustomField.Country]: country,
      [HelpChatCustomField.MoneybadgerChatCountry]: country,
      [HelpChatCustomField.MoneybadgerPrinterNames]: printersList,
    },

    // This parameter allows you to send the agent more information and add information to the chat transcript.
    extraPrechatFormDetails: [
      {
        label: HelpChatLabel.FirstName,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.FirstName],
      },
      {
        label: HelpChatLabel.LastName,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.LastName],
      },
      {
        label: HelpChatLabel.Email,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.Email],
      },
      {
        label: HelpChatLabel.ProblemCategory,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.PreChatFormSubject],
      },
      {
        label: HelpChatLabel.ProblemDescription,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.Subject],
      },
      {
        label: HelpChatLabel.Country,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.Country],
      },
      {
        label: HelpChatLabel.ProductModel,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.ModelNumber],
      },
      {
        label: HelpChatLabel.PrinterFriendlyName,
        displayToAgent: true,
        transcriptFields: [HelpChatCustomField.MbPrinterFriendlyName],
      },
      {
        label: HelpChatLabel.ContactStatus,
        value: 'Active',
        displayToAgent: false,
      },
      {
        label: HelpChatLabel.Zuid,
        value: id,
        displayToAgent: false,
        transcriptFields: [HelpChatCustomField.UserZuid],
      },
      {
        label: HelpChatLabel.ChatBelongsTo,
        value: 'Moneybadger',
        displayToAgent: false,
        transcriptFields: [HelpChatCustomField.ChatBelongsTo],
      },
    ],

    // This parameter lets you map the pre-chat form details from the extraPrechatFormDetails parameter to fields in existing or
    // new records. The information in this parameter is merged with the information already specified from your org's setup.
    extraPrechatInfo: [
      {
        entityName: HelpChatEntity.Contact,
        linkToEntityName: HelpChatEntity.Case,
        entityFieldMaps: [
          createContactFieldMap(
            HelpChatField.FirstName,
            HelpChatLabel.FirstName,
          ),
          createContactFieldMap(HelpChatField.LastName, HelpChatLabel.LastName),
          createContactFieldMap(
            HelpChatCustomField.ContactStatus,
            HelpChatLabel.ContactStatus,
          ),
          createContactFieldMap(
            HelpChatCustomField.ContactPingZuid,
            HelpChatLabel.Zuid,
          ),
          createContactFieldMap(HelpChatCustomField.Email, HelpChatLabel.Email),
        ],
      },
      {
        entityName: HelpChatEntity.Case,
        saveToTranscript: 'CaseId',
        entityFieldMaps: [
          createCaseFieldMap(
            HelpChatCustomField.MbProblemCategory,
            HelpChatLabel.ProblemCategory,
          ),
          createCaseFieldMap(
            HelpChatCustomField.ProblemDescription,
            HelpChatLabel.ProblemDescription,
          ),
          createCaseFieldMap(
            HelpChatCustomField.ProductModel,
            HelpChatLabel.ProductModel,
          ),
          createCaseFieldMap(
            HelpChatCustomField.Country,
            HelpChatLabel.Country,
          ),
        ],
      },
    ],
  };

  window.embedded_svc.addEventHandler('afterInit', () => {
    window.embedded_svc.onHelpButtonClick();
  });
  window.embedded_svc.init(
    'https://zebra.my.salesforce.com',
    'https://zsbsupport.zebra.com',
    gslbBaseURL,
    organisationId,
    MoneyBadgerChatOrigin,
    {
      baseLiveAgentContentURL: getLiveAgentContentUrl(),
      deploymentId,
      buttonId,
      baseLiveAgentURL: getLiveAgentChatUrl(),
      eswLiveAgentDevName,
      isOfflineSupportEnabled: false,
    },
  );

  //Chat button disable/enable
  window.embedded_svc.addEventHandler('onChatRequestSuccess', () => {
    fireChatEvent(true);
  });
  window.embedded_svc.addEventHandler('afterDestroy', () => {
    fireChatEvent(false);
  });
  setupMessageCountBubbleListeners();
};

const fireChatEvent = (isChatActive: boolean) => {
  window.dispatchEvent(
    new CustomEvent('chatEvent', {
      detail: { isChatActive },
    }),
  );
};

const snapin = {
  setup,
  activateChat,
  teardown,
  getUserInfo,
};

export default snapin;
