import { fork } from 'redux-saga/effects';
import { visible, hidden } from 'state/PageVisibility';

function createVisibilityChannel() {
  const change = () => {
    if (document.hidden) window['store'].dispatch(hidden());
    else window['store'].dispatch(visible());
  };
  document.addEventListener('visibilitychange', change);
  change();
}

export default function* rootSaga() {
  yield fork(createVisibilityChannel);
}
