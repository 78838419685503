import React from 'react';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import {
  Components,
  Overview,
  Login,
  NotFoundWithMenu,
  Designer,
  Help,
  Workspaces,
  Notifications,
  Settings,
  DataSources,
  Templates,
  Apps,
  Cloud,
} from 'pages';
import { history } from 'store/history';
import { Features, useFeature } from 'hooks';
import {
  COMMON_DESIGNS_PATH,
  HOME_PAGE_PATH,
  MY_DESIGNS_PATH,
  MY_DATA_SOURCES_PATH,
} from 'utils/config';
import { Eula } from 'pages/eula';
import { PublicClientApplication } from '@azure/msal-browser';
import { MSAL_CONFIG } from '../services/office365';
import { MsalProvider } from '@azure/msal-react';
import PrintDialog from '../components/app/print-dialog/PrintDialog';
import SMBToast from '../components/common/SMBToast';
import SMBFileDialog from '../components/common/SMBFileDialog';
import NotificationWidget from '../components/app/notification-widget/NotificationWidget';
import LabelRangeProvider from '../components/app/print-dialog/LabelRangeContext';
import { TemplateDialogModal } from '../pages/templates/components/TemplateDialogModal';
import { OutageDialog } from 'components/app/outage-dialog';
import { Routes as RoutesV2 } from 'v2/Routes';
import Page from 'components/common/page/Page';
import LoginRoute from 'components/app/LoginRoute/LoginRoute';
import PrivateRoute from 'components/app/PrivateRoute/PrivateRoute';
import RedirectWithHash from 'components/common/RedirectWithHash/RedirectWithHash';
import { DialogManager } from 'v2/components/app';

export function Routes() {
  const isNewHomeUI = useFeature(Features.NewHomeUI);

  if (isNewHomeUI) {
    return <RoutesV2 />;
  }

  return (
    <Route
      render={({ location }) => {
        return (
          <Switch location={location}>
            <LoginRoute path="/login" component={Login} />
            <Route path="/eula" component={Eula} />
            <Route path="/cloud" component={Cloud} />
            <Page>
              <MsalProvider instance={new PublicClientApplication(MSAL_CONFIG)}>
                <LabelRangeProvider>
                  <DialogManager />
                  <SMBFileDialog />
                  <PrintDialog />
                  <TemplateDialogModal />
                  <SMBToast />
                  <NotificationWidget />
                  <OutageDialog />
                  <Switch>
                    <PrivateRoute path={HOME_PAGE_PATH} component={Overview} />
                    <PrivateRoute
                      path={MY_DESIGNS_PATH}
                      component={Templates}
                    />
                    <PrivateRoute
                      path={COMMON_DESIGNS_PATH}
                      component={Templates}
                    />
                    <PrivateRoute path="/components" component={Components} />
                    <PrivateRoute
                      path="/workspaces/:id?"
                      component={Workspaces}
                    />
                    <PrivateRoute path="/user-settings" component={Settings} />
                    <PrivateRoute
                      path="/printer-settings"
                      component={Settings}
                    />
                    <PrivateRoute
                      path={MY_DATA_SOURCES_PATH}
                      component={DataSources}
                    />
                    <PrivateRoute path="/designer" component={Designer} />
                    <PrivateRoute
                      path="/notifications"
                      component={Notifications}
                    />
                    <PrivateRoute path="/help" component={Help} />
                    <PrivateRoute path="/apps" component={Apps} />
                    <RedirectWithHash to={HOME_PAGE_PATH} />
                  </Switch>
                </LabelRangeProvider>
              </MsalProvider>
            </Page>
            <PrivateRoute component={NotFoundWithMenu} />
          </Switch>
        );
      }}
    />
  );
}

const SMBRouter = () => {
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default SMBRouter;
