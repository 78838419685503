import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

import { CHOOSE_A_TEMPLATE, PRINT_PAGE_PATH } from 'utils/config';
import { useReduxAction, useReduxSelector, useUrlParam } from 'hooks';
import { DialogHeader, Pagination } from 'v2/components/app';
import { actions as labelSizesActions } from 'ducks/labelSizes/actions';
import { actions as categoriesActions } from 'ducks/categories/actions';
import { zebraFilteredTemplates as selectZebraFilteredTemplates } from 'ducks/templates/selectors';
import { getQuery as selectQuery } from 'ducks/router/selectors';
import { Box } from 'components';
import { PAGE_SIZE_DEFAULT } from 'utils/defaults';
import FilterCategoriesLayout from 'v2/pages/CommonDesignsPage/FilterCategoriesLayout';

import { DialogContent, DialogFooter } from '../Dialog.styles';
import {
  StyledCategoriesList,
  StyledFilterTemplatesLayout,
  StyledFlex,
  StyledSearchResult,
} from './index.styles';
import TemplatesList from './TemplatesList';

export default function ChooseTemplate() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);
  const [selectedCategory] = useUrlParam('category', '');
  const [searchString] = useUrlParam('searchLibrary', '');
  const { pageSize = PAGE_SIZE_DEFAULT } = useReduxSelector(selectQuery);

  const { data: zebraFilteredTemplates } = useReduxSelector(
    selectZebraFilteredTemplates,
  );

  const totalPagesCount = zebraFilteredTemplates
    ? Math.ceil(zebraFilteredTemplates.totalCount / parseInt(pageSize))
    : 0;

  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);
  const getAllCategories = useReduxAction(categoriesActions.FILTERED.request);

  //Initially fetch all label sizes and categories
  useEffect(() => {
    getAllLabelSizes();
    getAllCategories('');
  }, [getAllLabelSizes, getAllCategories]);

  return (
    <>
      <DialogHeader
        title={selectedCategory || t('dialogs:print-label.choose-template')}
        onBackClick={() =>
          selectedCategory
            ? pushAction(CHOOSE_A_TEMPLATE)
            : pushAction(PRINT_PAGE_PATH)
        }
      />
      <Box px={40} pb={2}>
        {selectedCategory ? (
          <StyledFilterTemplatesLayout displayInModal />
        ) : (
          <FilterCategoriesLayout />
        )}
      </Box>
      <DialogContent>
        {selectedCategory ? (
          <TemplatesList />
        ) : (
          <StyledFlex >
            {searchString ? <StyledSearchResult /> : <StyledCategoriesList />}
          </StyledFlex>
        )}
      </DialogContent>
      {selectedCategory && (
        <DialogFooter>
          {totalPagesCount > 1 && <Pagination pageCount={totalPagesCount} />}
        </DialogFooter>
      )}
    </>
  );
}
