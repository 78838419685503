import React from 'react';
import styled from 'styled-components';

import { Flex, Box, Icon } from 'components';
import { Text } from 'v2/components/common';

const BackArrow = styled(Icon).attrs(({ theme }) => ({
  color: theme.neutral.base,
  icon: 'back-arrow',
  size: 32,
}))`
  cursor: pointer;
`;

type PageTitleProps = {
  title: string;
  onBackClick: React.MouseEventHandler;
};

function PageTitle({ title, onBackClick }: PageTitleProps) {
  return (
    <Flex
      alignItems={'center'}
      mb={12}
      data-testid={'page-title'}
    >
      <Box
        onClick={onBackClick}
        aria-label={'back button'}
        data-testid="back-button"
      >
        <BackArrow />
      </Box>
      <Text pl={8} variant={'title'}>
        {title}
      </Text>
    </Flex>
  );
}

export default PageTitle;
