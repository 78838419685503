import { PrinterSettings } from 'zsbpsdk/proto/ZsbpPortalService_pb';

const FEED_ON_HEAD_CLOSE_VALUE_FEED = 'feed';
const FEED_ON_HEAD_CLOSE_VALUE_NO_MOTION = 'no motion';
const FEED_ON_HEAD_CLOSE_VALUE_UNKNOWN_ACTION = 'unknown action';

const REGEX_HEAD_CLOSE_ACTION_PAYLOAD = new RegExp(/^{ezpl\.head_close_action=([a-z ]+)}$/g);

export const convertHeadClosedActionToString = (headClosedAction?: PrinterSettings.HeadClosedAction): string => {
  switch (headClosedAction) {
    case PrinterSettings.HeadClosedAction.FEED:
      return FEED_ON_HEAD_CLOSE_VALUE_FEED;
    case PrinterSettings.HeadClosedAction.NO_MOTION:
      return FEED_ON_HEAD_CLOSE_VALUE_NO_MOTION;
    case PrinterSettings.HeadClosedAction.UNKNOWN_ACTION:
    default:
      return FEED_ON_HEAD_CLOSE_VALUE_UNKNOWN_ACTION;
  }
};

export const convertStringToHeadClosedAction = (feedOnHeadCloseValue: string): PrinterSettings.HeadClosedAction => {
  switch (feedOnHeadCloseValue) {
    case FEED_ON_HEAD_CLOSE_VALUE_FEED:
      return PrinterSettings.HeadClosedAction.FEED;
    case FEED_ON_HEAD_CLOSE_VALUE_NO_MOTION:
      return PrinterSettings.HeadClosedAction.NO_MOTION;
    case FEED_ON_HEAD_CLOSE_VALUE_UNKNOWN_ACTION:
    default:
      return PrinterSettings.HeadClosedAction.UNKNOWN_ACTION;
  }
};

export const isFeedOnHeadCloseEnabled = (headClosedAction?: PrinterSettings.HeadClosedAction): boolean => {
  switch (headClosedAction) {
    case PrinterSettings.HeadClosedAction.NO_MOTION:
      return false;
    case PrinterSettings.HeadClosedAction.FEED:
    case PrinterSettings.HeadClosedAction.UNKNOWN_ACTION:
    default:
      return true;
  }
};

export const testFeedOnHeadCloseNotificationPayload = (payload: string): boolean => {
  REGEX_HEAD_CLOSE_ACTION_PAYLOAD.lastIndex = 0;
  return REGEX_HEAD_CLOSE_ACTION_PAYLOAD.test(payload);
}

export const parseFeedOnHeadCloseNotificationPayload = (payload: string): string => {
  REGEX_HEAD_CLOSE_ACTION_PAYLOAD.lastIndex = 0;
  const matches = REGEX_HEAD_CLOSE_ACTION_PAYLOAD.exec(payload);
  if (matches?.length === 2) { // First match is entire string
    return matches[1]; // Second match is the feed on head close setting value
  }
  return convertHeadClosedActionToString(PrinterSettings.HeadClosedAction.UNKNOWN_ACTION);
}
