import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Search } from 'components';
import { useReduxAction, useReduxSelector, useUrlParam } from 'hooks';
import { actions as searchActions } from 'ducks/search/actions';
import { getZebraTemplatesSuggestions } from 'ducks/search/selectors';
import { FilterDropdown } from 'v2/components/app';
import { selectZebraFilteredTemplatesAllSizes } from 'ducks/templates/selectors';
import { actions as templateActions } from 'ducks/templates/actions';
import { device } from 'utils/mediaQueries';
import { HintText } from 'v2/components/common';

export const SearchWrap = styled(Box)`
  flex: 1;
  max-width: 512px;
  margin-right: ${({ theme }) => theme.px(4)};
  @media ${device.tablet} {
    width: calc(100% - 2px);
  }
`;

export const StyledFlexWrap = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const normalizeCategoryName = (categoryName: string): string => {
  if (categoryName && categoryName.includes('/')) {
    return categoryName.replace('/', '-');
  }

  return categoryName;
};

type FilterTemplatesLayoutAtributes = React.HTMLAttributes<HTMLDivElement> & {
  displayInModal?: boolean;
};

const FilterTemplatesLayout = ({
  displayInModal = false,
  ...props
}: FilterTemplatesLayoutAtributes) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useUrlParam('searchLibrary', '');
  const [selectedCategory] = useUrlParam('category', '');
  const zebraFilteredTemplatesSizes = useReduxSelector(
    selectZebraFilteredTemplatesAllSizes,
  );
  const searchLibraryTemplates = useReduxAction(
    searchActions.ZEBRA_TEMPLATES.request,
  );
  const allLabelSizesActionRequest = useReduxAction(
    templateActions.ZEBRA_FILTERED_ALL_SIZES.request,
  );
  const searchOptions = useReduxSelector(getZebraTemplatesSuggestions);

  useEffect(() => {
    allLabelSizesActionRequest({ selectedCategory });
  }, [selectedCategory, allLabelSizesActionRequest]);

  const onLibrarySearch = useCallback(
    (value: string): void => {
      searchLibraryTemplates({
        value: value,
        category: normalizeCategoryName(selectedCategory),
      });
    },
    [searchLibraryTemplates, selectedCategory],
  );

  const search = (
    <SearchWrap mb={'24px'}>
      <Search
        placeholder={t('templates:search-common-designs')}
        data-testid="input-search"
        onChange={onLibrarySearch}
        options={searchOptions}
        onSelect={setSearchString}
        initialSearch={searchString}
      />
    </SearchWrap>
  );

  const showInModal = (
    <>
      {search}
      <Flex justifyContent="end" mt={'24px'}>
        <FilterDropdown sizes={zebraFilteredTemplatesSizes} />
      </Flex>
    </>
  );
  
  const showOnPage = (
    <StyledFlexWrap>
      {search}
      <FilterDropdown sizes={zebraFilteredTemplatesSizes} />
    </StyledFlexWrap>
  );

  return (
    <Box {...props}>
      <Flex flexDirection={'row'} mb={'32px'}>
        <HintText
          data-testid="access-designs"
          variant="label"
          lineHeight={'32px'}
        >
          {t('templates:access-pre-made-designs')}
        </HintText>
      </Flex>
      {displayInModal ? showInModal : showOnPage}
    </Box>
  );
};

export default FilterTemplatesLayout;
