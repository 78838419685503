import * as React from 'react';
import {
  Text,
  Card,
  Flex,
  Box,
  WorkspaceCard,
  CategoryCard,
  LabelSizeCard,
} from 'components';
import { getAssetPath } from '../../../utils/files';

const CardsView = (): any => {
  return (
    <Card p={10} pb={40} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Cards</h2>
        <Flex flexDirection="row" flexWrap="wrap">
          <Card mr={10} mb={10} elevation={'one'}>
            <Box width={120} height={60}>
              <Text>Elevation one</Text>
            </Box>
          </Card>
          <Card elevation={'two'} mr={10} mb={10}>
            <Box width={120} height={60}>
              <Text>Elevation two</Text>
            </Box>
          </Card>
          <Card elevation={'three'} mr={10} mb={10}>
            <Box width={120} height={60}>
              <Text>Elevation three</Text>
            </Box>
          </Card>
          <Card elevation={'four'} mr={10} mb={10}>
            <Box width={120} height={60}>
              <Text>Elevation four</Text>
            </Box>
          </Card>
          <Card elevation={'five'} mr={10} mb={10}>
            <Box width={120} height={60}>
              <Text>Elevation five</Text>
            </Box>
          </Card>
          <Card elevation={'six'} mr={10} mb={10}>
            <Box width={120} height={60}>
              <Text>Elevation six</Text>
            </Box>
          </Card>
        </Flex>
        <h3>Workspace Card</h3>
        <Flex>
          <WorkspaceCard
            image={getAssetPath('/card-header.png')}
            name={'Athlon Toronto'}
            role="Admin"
            printersNumber="2"
            members="Members(1)"
          />
        </Flex>
        <h3>Workspace Card</h3>
        <Flex>
          <CategoryCard
            name="Shipping"
            desc="Short description of category with some use case examples"
            icon="logo-zebra"
          />
        </Flex>
        <h3>Label size Card</h3>
        <Flex>
          <Box m={[4]}>
            <LabelSizeCard
              label={{
                id: '10035147',
                name: 'XL Shipping',
                type: 'Z-Perform 2000D',
                cartridgeSize: '4',
                displayWidth: '4',
                displayHeight: '6',
                displayUnitsOfMeasure: 'in.',
                internalWidth1000sofmm: 101600,
                internalHeight1000sofmm: 152400,
                internalCornerradius1000sofmm: 3175,
                signedLabelTopposition1000sofmm: 2388,
                base64Image: '',
              }}
              selected={true}
            />
          </Box>
          <Box m={[4]}>
            <LabelSizeCard
              label={{
                id: '100347',
                name: 'Serg',
                type: 'Z-Perform 2000D',
                cartridgeSize: '4',
                displayWidth: '1',
                displayHeight: '9',
                displayUnitsOfMeasure: 'in.',
                internalWidth1000sofmm: 101600,
                internalHeight1000sofmm: 152400,
                internalCornerradius1000sofmm: 3175,
                signedLabelTopposition1000sofmm: 2388,
                base64Image: '',
              }}
            />
          </Box>
          <Box m={[4]}>
            <LabelSizeCard
              label={{
                id: '1003513',
                name: 'Zoe',
                type: 'Z-Perform 2000D',
                cartridgeSize: '4',
                displayWidth: '2',
                displayHeight: '3',
                displayUnitsOfMeasure: 'in.',
                internalWidth1000sofmm: 101600,
                internalHeight1000sofmm: 152400,
                internalCornerradius1000sofmm: 3175,
                signedLabelTopposition1000sofmm: 2388,
                base64Image: '',
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default CardsView;
