import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Form, Modal, Text } from 'components';
import { ThemeContext } from 'styled-components/macro';
import {
  getIsLoadingRemove,
  getIsRemoveSuccess,
} from 'ducks/printers/selectors';
import { useAppSelector, useReduxAction, useReduxSelector } from 'hooks';

import { actions as printersActions } from 'ducks/printers/actions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getLinks } from '../../../ducks/info/selectors';
import { actions as infoActions } from '../../../ducks/info/actions';

const StyledList = styled.ul`
  padding: 0 0 0 24px;
  list-style-type: disc;
`;

const StyledListItem = styled.li``;

const DeletePrinterModal = ({ printer, open, handleClose }: any) => {
  const ref = useRef(null);
  const theme = React.useContext(ThemeContext);
  const removePrinter = useReduxAction(printersActions.REMOVE.request);
  const requestLinks = useReduxAction(infoActions.LINKS.request);
  const isLoadingRemove = useReduxSelector(getIsLoadingRemove);
  const isRemoveSuccess = useReduxSelector(getIsRemoveSuccess);
  const links = useAppSelector(getLinks);
  const [isDeleteClicked, setDeleteClicked] = useState(false);

  const { t } = useTranslation(['overview', 'common', 'dialogs']);

  useEffect(() => {
    if (!links) {
      requestLinks();
    }
  }, [links, requestLinks]);

  const handleDeleteClicked = () => {
    setDeleteClicked(true);
  };

  const handleCloseModal = useCallback(() => {
    handleClose(false);

    setDeleteClicked(false);
  }, [handleClose]);

  useEffect(() => {
    if (isRemoveSuccess && open) {
      handleCloseModal();
    }
  }, [isRemoveSuccess, open, handleCloseModal]);

  const handleSubmit = useCallback(() => {
    removePrinter({ printer });
  }, [removePrinter, printer]);

  const deleteWarningBody = (
    <>
      <Box mb={8}>
        <Text
          fontSize={24}
          fontWeight={'300'}
          lineHeight={'32px'}
          color={theme.error.base}
        >
          {t('overview:printer.delete')}
        </Text>
      </Box>

      <Box mb={8}>
        <Text
          fontSize={16}
          fontWeight={'700'}
          lineHeight={'24px'}
          color={theme.textColors.high}
          data-testid="acknowledge-to-continue"
        >
          {t('dialogs:acknowledge-to-continue')}
        </Text>
      </Box>

      <Box mb={8}>
        <Text
          fontSize={16}
          fontWeight={'400'}
          lineHeight={'24px'}
          color={theme.textColors.high}
        >
          {t('overview:printer.delete-action-description-on-delete')}
        </Text>
      </Box>

      <StyledList>
        <Box mb={8}>
          <StyledListItem>
            <Text
              fontSize={16}
              fontWeight={'400'}
              lineHeight={'24px'}
              color={theme.textColors.high}
            >
              {t('overview:printer.delete-action-factory-reset')}
            </Text>
          </StyledListItem>
        </Box>

        <Box mb={8}>
          <StyledListItem>
            <Text
              fontSize={16}
              fontWeight={'400'}
              lineHeight={'24px'}
              color={theme.textColors.high}
            >
              {t('overview:printer.delete-action-permanently-delete')}
            </Text>
          </StyledListItem>
        </Box>
      </StyledList>

      <Box mb={8}>
        <Text
          fontSize={16}
          fontWeight={'400'}
          lineHeight={'24px'}
          color={theme.textColors.high}
        >
          {t('overview:printer.delete-action-printer-online')}
        </Text>
      </Box>

      <Box mb={8}>
        <Text
          fontSize={16}
          fontWeight={'400'}
          lineHeight={'24px'}
          color={theme.textColors.high}
        >
          {t('overview:printer.after-delete-description')}
        </Text>
      </Box>

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="cancel-button"
          onClick={handleCloseModal}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>

        <Button
          data-testid="delete-button"
          onClick={handleDeleteClicked}
          isLoading={isLoadingRemove}
          variant={'destructive'}
        >
          {isLoadingRemove ? t('common:deleting') : t('common:delete')}
        </Button>
      </Flex>
    </>
  );

  const confirmationBody = (
    <>
      <Box>
        <Box mb={8}>
          <Text
            fontSize={24}
            fontWeight={'300'}
            lineHeight={'32px'}
            color={theme.error.base}
          >
            {t('overview:printer.delete')}
          </Text>
        </Box>

        <Box>
          <Text
            fontSize={16}
            fontWeight={'400'}
            lineHeight={'24px'}
            data-testid="delete-action-confirmation"
          >
            {t('overview:printer.delete-action-confirmation')}
          </Text>
        </Box>

        <Form onSubmit={handleSubmit}>
          <Flex mt={40} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseModal}
              data-testid="printer-cancel-button"
              variant={'basic'}
              type={'button'}
            >
              {t('common:cancel')}
            </Button>

            <Button
              data-testid="printer-delete-button"
              variant={'destructive'}
              isLoading={isLoadingRemove}
              type={'submit'}
            >
              {isLoadingRemove ? t('common:deleting') : t('common:yes-delete')}
            </Button>
          </Flex>
        </Form>
      </Box>
    </>
  );

  return (
    <Modal
      onClose={handleCloseModal}
      data-testid={'delete-printer-modal'}
      open={open}
      clickOutside={!isLoadingRemove}
      small
      ref={ref}
      ariaLabel={'Delete printer'}
    >
      {isDeleteClicked ? confirmationBody : deleteWarningBody}
    </Modal>
  );
};

export default DeletePrinterModal;
