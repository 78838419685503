export const PAGE_NUMBER_DEFAULT: string = '1';
export const PAGE_SIZE_DEFAULT: string = '30';
export const SORT_DEFAULT: string = 'asc';

export const TEMPLATE_PARAMS_DEFAULT: any = {
  pageNumber: PAGE_NUMBER_DEFAULT,
  pageSize: PAGE_SIZE_DEFAULT,
  size: '-1',
  sort: SORT_DEFAULT,
  search: '',
  selectedCategory: '',
};
