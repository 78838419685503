import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Text, Dropdown, Box } from 'components';
import { actions as preferencesActions } from 'ducks/preferences/actions';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getAllPreferences } from 'ducks/preferences/selectors';
import { ThemeContext } from 'styled-components/macro';
import { getUnits } from 'utils/unitFormatter';
import { useTranslation } from 'react-i18next';

const UnitsComponent = () => {
  const { t } = useTranslation('settings');

  const theme = React.useContext(ThemeContext);
  const prefs = useReduxSelector(getAllPreferences);
  const updatePreferences = useReduxAction(preferencesActions.UPDATE.request);
  const [unitState, setUnitState] = useState(
    prefs?.units?.grpcUnit?.toString(),
  );

  const units = useMemo(() => {
    return prefs?.units;
  }, [prefs]);

  const handleSubmit = useCallback(() => {
    updatePreferences({
      data: { ...prefs, units: parseInt(unitState) },
      message: t('settings:preferences.units-of-measure-update'),
    });
  }, [updatePreferences, prefs, unitState, t]);

  useEffect(() => {
    if (units && units.grpcUnit?.toString() !== unitState) {
      setUnitState(units.grpcUnit.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units]);

  useEffect(() => {
    if (
      unitState &&
      unitState !== '' &&
      units &&
      unitState !== units?.grpcUnit?.toString()
    ) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitState]);

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'flex-start'}
      width={336}
      mr={11}
      mb={24}
    >
      <Text
        fontSize={14}
        lineHeight={'21px'}
        fontWeight={'400'}
        letterSpacing={0.4}
        color={theme.neutral.darkest}
      >
        {t('settings:units-of-measurement')}
      </Text>
      <Box width={'50%'}>
        <Dropdown
          aria-label={'unitsdropdown'}
          value={unitState}
          onChange={(e) => {
            setUnitState(e);
          }}
          options={getUnits().map((v) => {
            return { value: v.grpcUnit.toString(), label: v.name };
          })}
        />
      </Box>
    </Flex>
  );
};

export default UnitsComponent;
