import { takeLatest, put, take, select, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { actions as workspacesActions } from 'ducks/workspaces/actions';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { actions as templateActions } from 'ducks/templates/actions';

import { getIsLoggedIn } from 'ducks/auth/selectors';
import { types as authTypes } from 'ducks/auth/actions';
import {
  getAllWorkspaces,
  getAllWorkspacesLoading,
} from 'ducks/workspaces/selectors';
import {
  getFilteredFilesLoading,
  getFilteredParams,
} from 'ducks/dataSources/selectors';
import {
  getRecentTemplatesLoading,
  recentTemplatesData,
} from 'ducks/templates/selectors';
import { HOME_PAGE_PATH } from 'utils/config';

function* loader(path: string) {
  //TODO There must be a better way
  const workspaces = yield select(getAllWorkspaces);
  const workspacesLoading = yield select(getAllWorkspacesLoading);
  if (!workspaces.length && !workspacesLoading) {
    yield put(workspacesActions.ALL.request({}));
  }

  if (path === '/datasources') {
    const params = yield select(getFilteredParams);
    const isLoadingFiltered = yield select(getFilteredFilesLoading);
    if (!isLoadingFiltered) {
      yield put(dataSourcesActions.FILTERED.request(params));
    }
  }

  if (path === HOME_PAGE_PATH) {
    const isLoadingRecent = yield select(getRecentTemplatesLoading);
    const cachedRecentTemplates = yield select(recentTemplatesData);
    if (!isLoadingRecent && !cachedRecentTemplates) {
      yield put(templateActions.RECENT.request({}));
    }
  }
}

function* onRouteChange(action: any) {
  const {
    location: { pathname },
  } = action.payload;

  const isLoggedIn = yield select(getIsLoggedIn);
  if (isLoggedIn) {
    yield call(loader, pathname);
  } else {
    yield take(authTypes.LOGIN.SUCCESS);
    yield call(loader, pathname);
  }
}

export default function* watcher() {
  yield takeLatest(LOCATION_CHANGE, onRouteChange); // takeLatest ??
}
