import React, { useState } from 'react';
import { Button, Card, Flex, Box } from 'components';
import { ThemeContext } from 'styled-components/macro';

const ButtonsView = (): any => {
  const theme = React.useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadButtonText, setDownloadButtonText] =
    useState('Press to download');
  const showLoader = () => {
    setIsLoading(true);
    setDownloadButtonText('Downloading...');
    setTimeout(() => {
      setIsLoading(false);
      setDownloadButtonText('Press to download');
    }, 15000);
  };

  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Buttons</h2>
        <Flex flexDirection="row">
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'primary'}
              size={'xl'}
              iconColor={theme.white}
              iconSize={16}
              iconName="settings"
              onClick={() => {}}
            >
              Primary
            </Button>
          </Box>
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'outline'}
              size={'lg'}
              onClick={() => {}}
            >
              Outline
            </Button>
          </Box>
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'destructive'}
              size={'lg'}
              onClick={() => {}}
            >
              Destructive
            </Button>
          </Box>
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'text-primary'}
              size={'lg'}
              onClick={() => {}}
            >
              Text primary
            </Button>
          </Box>
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'secondary'}
              size={'lg'}
              disabled
              onClick={() => {}}
            >
              Secondary
            </Button>
          </Box>
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'text-destructive'}
              size={'lg'}
              onClick={() => {}}
            >
              Text destructive
            </Button>
          </Box>
          <Box mr={5}>
            <Button variant={'basic'} size={'lg'} onClick={() => {}}>
              Basic
            </Button>
          </Box>
        </Flex>
        <Flex flexDirection="row">
          <Box mt={10}>
            <Button
              icon={'right'}
              variant={'primary'}
              size={'xl'}
              iconColor={theme.white}
              iconSize={16}
              iconName="download"
              onClick={showLoader}
              isLoading={isLoading}
            >
              {downloadButtonText}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ButtonsView;
