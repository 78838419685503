import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { MY_NOTIFICATIONS_PATH } from 'utils/config';
import { useReduxAction, useReduxSelector } from 'hooks';
import { selectHasUnreadNotifications } from 'state/Notification';
import IconButton from 'v2/components/common/IconButton/IconButton';
import TooltipBottom from 'v2/components/common/ToolTip/ToolTipBottom';

const RelativeContainer = styled.div`
  position: relative;
`;

const UnreadIndicator = styled.div`
  position: absolute;
  top: 15px;
  right: 14px;
  width: 8px;
  height: 8px;
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 6px;

  background-color: #d70015;
`;

export default function NotificationButton() {
  const { t } = useTranslation(['components', 'menu']);
  const pushAction = useReduxAction(push);
  const hasUnreadNotification = useReduxSelector(selectHasUnreadNotifications);

  return (
    <TooltipBottom
      enabled={true}
      active={true}
      content={t('menu:notifications')}
      direction="bottom"
      style={{ color: 'red' }}
      marginLeft={-10}
    >
      <RelativeContainer data-testid={'notifications-menu-button'}>
        {hasUnreadNotification && (
          <UnreadIndicator data-testid={'unread-notifications-icon'} />
        )}
        <IconButton
          icon="bell-filled"
          onClick={() => pushAction(MY_NOTIFICATIONS_PATH)}
          data-testid="my-notifications-button"
        />
      </RelativeContainer>
    </TooltipBottom>
  );
}
