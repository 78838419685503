import { v4 as uuidv4 } from 'uuid';
import {
  put,
  call,
  take,
  takeLatest,
  takeLeading,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  saveSingle,
  readNotification,
  readNotificationRequest,
  setNotificationsLog,
  removeSingle,
  removeSingleRequest,
  receivedNotification,
} from 'state/Notification';
import tokenStorage from 'services/tokenStorage';
import { Notification } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { actions as printerActions } from 'ducks/printers/actions';
import { types as authTypes } from 'ducks/auth/actions';
import { getAllPrinters } from 'ducks/printers/selectors';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { PrinterType } from 'zsbpsdk/src/printer';
import { WorkspaceType } from 'zsbpsdk/src/workspace';
import selectAwaitCustomer from 'utils/selectAwaitCustomer';
import transform from 'components/app/notification-widget/transformer';
import { getPrintjobs, getCartridgelow } from 'ducks/preferences/selectors';
import { subscribeNotifications } from 'services/api';
import {
  getNotifications,
  setNotifications,
  addNotification,
  saveNotification,
  deleteNotification,
} from 'services/notifications';
import { sortNotifications } from '../utils/notifications';

const SUPPORTED_NOTIFICATIONS = [
  Notification.Subject.PRINTER,
  Notification.Subject.PRINTER_SETTINGS,
];

function* handleGetAllNotificationsWithCustomerId(customerId: string) {
  //TODO Implementation here is temporary using local storage it have to be transfered to the backend when implemented
  let notifications = getNotifications(customerId);

  //Migrate the old Notification schema to the new one
  //and set some defaults
  if (notifications.some(({ uniqueId }) => !uniqueId)) {
    notifications = notifications.map((notification: any) => {
      if (!notification.uniqueId) {
        notification.uniqueId = uuidv4();
      }

      //Mark all old notification as read
      if (!notification.hasOwnProperty('isRead')) {
        notification.isRead = true;
      }
      return notification;
    });
    notifications = sortNotifications(notifications)
    setNotifications(customerId, notifications);
  }

  yield put(setNotificationsLog(notifications));
}

function* saveNewNotification({
  payload,
}: ReturnType<typeof receivedNotification>) {
  //TODO Implementation here is temporary using local storage it have to be transfered to the backend when implemented
  const workspaces: WorkspaceType[] = yield select(getAllWorkspaces);
  const printers: PrinterType[] = yield select(getAllPrinters);
  const printjobs = yield select(getPrintjobs);
  const cartridgelow = yield select(getCartridgelow);
  const transformedNotification = transform(
    payload,
    workspaces,
    printers,
    printjobs,
    cartridgelow,
  );
  const isItSavable: boolean =
    !!transformedNotification?.title ||
    !!transformedNotification?.detailedMessage;

  //Saving only displayable notifications ( only this that are currently handled ).
  if (isItSavable) {
    const extendedNotification: any = {
      ...payload,
      time: new Date().getTime(),
      isRead: false,
      uniqueId: uuidv4(),
    };

    addNotification(extendedNotification, extendedNotification.customerid);
    yield put(saveSingle(extendedNotification));
  }
}

function* handleRemoveSingleNotification({
  payload,
}: ReturnType<typeof removeSingleRequest>) {
  //TODO Implementation here is temporary using local storage it have to be transfered to the backend when implemented
  const customer = yield selectAwaitCustomer();

  deleteNotification(payload, customer.id);
  yield put(removeSingle(payload));
}

function* handleReadNotification({
  payload,
}: ReturnType<typeof readNotificationRequest>) {
  const customer = yield selectAwaitCustomer();

  saveNotification({ ...payload, isRead: true }, customer.id);
  yield put(readNotification(payload));
}

function* subscribeForNotifications() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const channel = eventChannel((emmiter) => {
      subscribe(customer.id, token, (notification) => {
        try {
          emmiter(notification.toObject());
        } catch (e) {
          console.log(e);
        }
      });

      return () => {};
    });

    yield call(handleGetAllNotificationsWithCustomerId, customer.id);

    yield put(printerActions.ALL.request({}));

    while (true) {
      try {
        const notification = yield take(channel);

        if (!SUPPORTED_NOTIFICATIONS.includes(notification.subject)) {
          continue;
        }

        yield put(receivedNotification(notification));
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

const MAX_RETRIES = 8; // Max delay of ~4.3min before trying to re-subscribe

function subscribe(
  customerId: string,
  token: string,
  onNotification: (notification: Notification) => void,
  retries: number = 0,
) {
  if (retries < MAX_RETRIES) {
    retries += 1;
  }
  subscribeNotifications(
    customerId,
    token,
    (notification: any) => {
      retries = 0;
      onNotification(notification);
    },
    // Endlessly re-subscribe if there was an error or the request ended
    () => {
      setTimeout(() => {
        subscribe(customerId, token, onNotification, retries);
      }, Math.pow(2, retries) * 1000);
    },
    () => subscribe(customerId, token, onNotification),
  );
}

export default function* watcher() {
  yield takeLatest(removeSingleRequest.type, handleRemoveSingleNotification);
  yield takeLeading(authTypes.CUSTOMER.SUCCESS, subscribeForNotifications);
  yield takeLeading(readNotificationRequest.type, handleReadNotification);
  yield takeEvery(receivedNotification.type, saveNewNotification);
}
