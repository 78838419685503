import React, { useState, useMemo } from 'react';
import { Input, Icon } from 'components';
import { throttle } from 'throttle-debounce';
import styled from 'styled-components/macro';

const CloseIcon: any = styled(Icon).attrs(({ theme }: any) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 14,
}))``;
export const SearchIcon = styled(Icon).attrs(({ theme }) => ({
  size: 20,
  icon: 'search',
  color: theme.neutral.base,
}))``;

export default function SearchFiles({ placeholder, onChange }) {
  const [search, setSearch] = useState('');
  const throttledOnChange = useMemo(() => throttle(1000, onChange), [onChange]);

  const handleChange = (e: any) => {
    const v = e.target.value;
    setSearch(v);
    throttledOnChange(v);
  };

  const handleDismiss = () => {
    setSearch('');
    throttledOnChange('');
  };
  return (
    <Input
      placeholder={placeholder}
      iconStart={<SearchIcon />}
      onChange={handleChange}
      value={search}
      iconEnd={search ? <CloseIcon /> : ''}
      onEndIconClick={handleDismiss}
      data-testid="main-input"
    />
  );
}
