import React, { useContext } from 'react';
import { Icon } from 'components';
import { ThemeContext } from 'styled-components/macro';
import IconType from 'components/lib/icon/IconType';
import {
  CardWrap,
  Description,
  DescriptionWrap,
  IconWrap,
  Title,
} from './CategoryCard.styles';
import { LabelCartridge } from './LabelCartridge';

type CategoryCardAttributes = {
  name?: string;
  desc?: string;
  naPartNumber?: string;
  emeaPartNumber?: string;
  icon?: IconType;
  onClick?: React.MouseEventHandler;
};

const CategoryCard = ({
  name = '',
  desc = '',
  naPartNumber = '',
  emeaPartNumber = '',
  icon,
  onClick,
  ...props
}: CategoryCardAttributes) => {
  const theme = useContext(ThemeContext);

  return (
    <CardWrap
      data-testid="category-card"
      onClick={onClick}
      {...props}
    >
      <IconWrap>
        <Icon
          icon={icon || 'logo-zebra'}
          size={'24'}
          color={theme.neutral.base}
        ></Icon>
      </IconWrap>
      <Title data-testid="category-card-name">
        {name}
      </Title>
      <DescriptionWrap>
        <Description
          data-testid="category-card-descr"
        >
          {desc}
        </Description>
      </DescriptionWrap>
      <DescriptionWrap pt={40}>
        <LabelCartridge
          naPartNumber={naPartNumber}
          emeaPartNumber={emeaPartNumber}
        />
      </DescriptionWrap>
    </CardWrap>
  );
};

export default CategoryCard;
