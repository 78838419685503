import styled from 'styled-components';
import { Icon, Text } from 'components';

export const MenuRowWrap = styled.div<{
  selected?: boolean;
  logo?: string;
  value: string;
}>`
  display: flex;
  width: 100%;
  align-self: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 100;
  opacity: 0.8;
  align-items: center;
  &&& {
    padding: ${({ theme }) => theme.px(6, 9)};

  ${({ logo }) =>
    logo &&
    `
    padding-left: 0px;
  `};

  background-color: ${({ theme, selected }) =>
    selected ? theme.primary.lightest : 'transparent'};
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary.lightest};
  }
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.primary.lightest};
  `};
  font-family: Roboto;
  font-size: ${({ theme }) => theme.fontSizes.label}px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.textColors.high};
`;

export const OptionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.neutral.base,
  size: 20,
}))`
  margin-right: ${({ theme }) => theme.px(9)};
`;

export const HighlightedSubStr = styled(Text)`
  color: ${({ theme }) => theme.primary.base};
  white-space: pre;
`;

export const OptionText = styled(Text).attrs(({ theme }) => ({
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: theme.textColors.high,
}))`
  white-space: pre;
`;
