import React, { useRef } from 'react';
import { Card, Flex, Button, Modal, Box, Input, Text } from 'components';
import { useModal } from 'hooks';

const LargeModal = () => {
  const largeModalRef = useRef(null);
  const { open } = useModal(largeModalRef);
  return (
    <>
      <Button onClick={open}>Show Large Modal</Button>
      <Modal
        title={'Edit Printer'}
        footer={
          <Flex justifyContent={'flex-end'}>
            <Button>{'Done'}</Button>
          </Flex>
        }
        ref={largeModalRef}
        clickOutside={true}
      >
        <Flex
          width={1112}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Box>
            <Text>{'Printer Name'}</Text>
            <Text>{'Add a printer name so you can identify it easily'}</Text>
            <Input label={'Name'} value={'Zoe'} />
          </Box>
          <Box>
            <Text>{'Printer Name'}</Text>
            <Text>{'Add a printer name so you can identify it easily'}</Text>
            <Input label={'Name'} value={'Zoe'} />
          </Box>
        </Flex>
      </Modal>
    </>
  );
};

const SmallModal = () => {
  const smallModalRef = useRef(null);
  const { open, close } = useModal(smallModalRef);
  return (
    <>
      <Button onClick={open}>Show Small Modal</Button>
      <Modal clickOutside={true} small ref={smallModalRef}>
        <Box mb={8}>
          <Text fontSize={24} fontWeight={300}>
            {'Edit Name'}
          </Text>
        </Box>
        <Text fontSize={16} fontWeight={'normal'} lineHeight={'24px'}>
          {
            'Organize your templates by giving it a name to help you find it faster.'
          }
        </Text>
        <Input placeholder={'Shipping A'} />
        <Flex mt={10} justifyContent={'flex-end'}>
          <Button onClick={close} variant={'text-primary'}>
            Cancel
          </Button>
          <Button>Save</Button>
        </Flex>
      </Modal>
    </>
  );
};

const ModalsView = (): any => {
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Modals</h2>
        <Flex flexDirection="row">
          {
            <>
              <Box mr={[6]}>
                <SmallModal />
              </Box>
              <LargeModal />
            </>
          }
        </Flex>
      </Flex>
    </Card>
  );
};

export default ModalsView;
