import React from 'react';

type IRadioGroup = {
  onChange?: (value: string | number) => void;
  value?: string | number;
  name?: string;
};

const RadioGroupContext = React.createContext<IRadioGroup>({});

export default RadioGroupContext;

export const useRadioGroup = (): IRadioGroup =>
  React.useContext(RadioGroupContext);
