import DeleteTemplateModal from '../../overview/components/DeleteTemplateModal';
import RenameTemplateModal from '../../overview/components/ChangeTemplateModal';
import DuplicateTemplateModal from '../../overview/components/DuplicateTemplateModal';
import MainTemplateModal from '../../overview/components/MainTemplateModal';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useModal,
  useReduxAction,
  useReduxSelector,
  useUrlParam,
} from '../../../hooks';
import { getEtch } from '../../../utils/unitFormatter';
import { TemplateType } from 'zsbpsdk/src/templates/index';
import { Button, Loader } from '../../../components';
import { TemplateCardType } from '../../../components/app/template-card/TemplateCard';
import { actions as printDialogActions } from '../../../ducks/printDialog/actions';
import { actions as templateActions } from '../../../ducks/templates/actions';
import { useLocation, useParams } from 'react-router-dom';
import { getUnit } from '../../../ducks/preferences/selectors';
import styled from 'styled-components/macro';
import { MY_DESIGNS_PATH } from '../../../utils/config';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import {
  isTemplateDialogLoading,
  templateDialog,
} from '../../../ducks/templates/selectors';
import { TemplateCategory } from '../../../utils/categories';
import { ModalContainer } from 'components/common/ModalPopover';

const ButtonList = styled.div`
  overflow: hidden;
`;
const ModalContainerWithBackdrop = styled(ModalContainer)`
  background-color: ${({ theme }) => theme.withOpacity(theme.black, 0.5)};
  transition: 0.1s ease;
`;

enum TemplateDialogType {
  MAIN = 'MAIN',
  DELETE = 'DELETE',
  PRINT = 'PRINT',
  DUPLICATE = 'DUPLICATE',
  RENAME = 'RENAME',
}
export const TEMPLATE_ID_QUERY_PARAM = 'templateID';

const TemplateDialog = ({ template }: { template: TemplateType }) => {
  const { t } = useTranslation(['common', 'components']);

  const unit = useReduxSelector(getUnit);
  const [currentModalType, setCurrentModalType] = useState<TemplateDialogType>(
    TemplateDialogType.MAIN,
  );

  const [etch, setEtch] = useState(
    getEtch(template.labelSize, unit, template.orientation),
  );
  const params = useParams();

  const setTemplateId = useUrlParam(
    TEMPLATE_ID_QUERY_PARAM,
    params[TEMPLATE_ID_QUERY_PARAM],
  )[1];

  const location = useLocation();

  const renameTemplateRef = useRef(null);
  const mainModalRef = useRef(null);
  const duplicateTemplateRef = useRef(null);
  const deleteTemplateRef = useRef(null);

  const mainModalHook = useModal(mainModalRef, true);
  const renameTemplateModal = useModal(renameTemplateRef);
  const duplicateTemplateModal = useModal(duplicateTemplateRef);
  const deleteTemplateModal = useModal(deleteTemplateRef);

  const openPrintDialog = useReduxAction(printDialogActions.OPEN);
  const duplicateTemplate = useReduxAction(templateActions.DUPLICATE.request);
  const clearDuplicateTemplateState = useReduxAction(
    templateActions.DUPLICATE.clear,
  );
  const getSelectedTemplateData = useReduxAction(
    templateActions.SELECTED_DATA.request,
  );
  const clearDialogTemplate = useReduxAction(
    templateActions.TEMPLATE_DIALOG.clear,
  );
  const pushAction = useReduxAction(push);

  useEffect(() => {
    if (mainModalHook.open) {
      mainModalHook.open();
    }
    return () => {
      clearDialogTemplate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyCallback = useCallback(
    (filename: string) => {
      pushAction(`${MY_DESIGNS_PATH}?${TEMPLATE_ID_QUERY_PARAM}=${filename}`);
      clearDialogTemplate();
    },
    [clearDialogTemplate, pushAction],
  );

  const templateCardType = useMemo(
    () =>
      template.tenant === TemplateCategory.Internal
        ? TemplateCardType.LIBRARY
        : TemplateCardType.USER,
    [template.tenant],
  );

  useEffect(() => {
    setEtch(getEtch(template.labelSize, unit, template.orientation));
  }, [unit, template.labelSize, template.orientation]);

  const handleModalClose = useCallback(() => {
    setCurrentModalType(TemplateDialogType.MAIN);
    setTemplateId(null);
    clearDialogTemplate();
  }, [clearDialogTemplate, setTemplateId]);

  const openDuplicateModal = useCallback(() => {
    setCurrentModalType(TemplateDialogType.DUPLICATE);
    duplicateTemplateModal.open();
  }, [duplicateTemplateModal]);

  const openRenameModal = useCallback(() => {
    setCurrentModalType(TemplateDialogType.RENAME);
    renameTemplateModal.open();
  }, [renameTemplateModal]);

  const openDesigner = useCallback(() => {
    pushAction(
      `/designer?sid=${Date.now()}&template=${encodeURIComponent(
        template.id,
      )}&lastUrl=${encodeURIComponent(window.location.pathname)}`,
    );
  }, [pushAction, template.id]);

  const openDeleteModal = useCallback(() => {
    setCurrentModalType(TemplateDialogType.DELETE);
    deleteTemplateModal.open();
  }, [deleteTemplateModal]);

  const copyToMyDesigns = useCallback(
    (template, action) => {
      duplicateTemplate({
        template,
        location,
        name: t('components:copy', { templateName: template.name }),
        onClick: (filename: string) => {
          clearDuplicateTemplateState();
          copyCallback(filename);
        },
        action: action,
      });
      handleModalClose();
    },
    [
      clearDuplicateTemplateState,
      handleModalClose,
      copyCallback,
      duplicateTemplate,
      location,
      t,
    ],
  );

  const printMethod = useCallback(() => {
    setCurrentModalType(TemplateDialogType.PRINT);
    getSelectedTemplateData({ template });
    openPrintDialog(template.filename);
    handleModalClose();
  }, [handleModalClose, getSelectedTemplateData, openPrintDialog, template]);

  const ButtonsListObj = useCallback(() => {
    return templateCardType === TemplateCardType.USER ? (
      <ButtonList>
        <Button
          data-testid="template-modal-print-button"
          fontWeight={'400'}
          variant={'basic'}
          onClick={printMethod}
          width="100%"
          px="24px"
        >
          {t('components:printer.print')}
        </Button>
        <Button
          data-testid="template-modal-edit-button"
          width="100%"
          fontWeight={'400'}
          variant={'basic'}
          px="24px"
          onClick={openDesigner}
        >
          {t('common:edit')}
        </Button>
        <Button
          data-testid="my-designs-template-duplicate"
          width="100%"
          fontWeight={'400'}
          variant={'basic'}
          px="24px"
          onClick={openDuplicateModal}
        >
          {t('common:duplicate')}
        </Button>
        <Button
          data-testid="my-designs-template-rename"
          width="100%"
          fontWeight={'400'}
          variant={'basic'}
          px="24px"
          onClick={openRenameModal}
        >
          {t('common:rename')}
        </Button>
        <Button
          data-testid="my-designs-template-delete"
          width="100%"
          fontWeight={'400'}
          variant={'text-destructive'}
          px="24px"
          onClick={openDeleteModal}
        >
          {t('common:delete')}
        </Button>
      </ButtonList>
    ) : (
      <ButtonList>
        <Button
          data-testid="template-modal-print-button"
          variant={'basic'}
          width="100%"
          px="24px"
          fontWeight={'400'}
          onClick={printMethod}
        >
          {t('components:printer.print')}
        </Button>

        <Button
          data-testid="copy-to-my-designs"
          variant={'basic'}
          width="100%"
          px="24px"
          fontWeight={'400'}
          onClick={() => copyToMyDesigns(template, null)}
        >
          {t('components:copy-to-my-designs')}
        </Button>

        <Button
          data-testid="template-modal-edit-button"
          variant={'basic'}
          width="100%"
          px="24px"
          fontWeight={'400'}
          onClick={() => {
            copyToMyDesigns(template, 'edit');
          }}
        >
          {t('common:edit')}
        </Button>
      </ButtonList>
    );
  }, [
    copyToMyDesigns,
    openDeleteModal,
    openDesigner,
    openDuplicateModal,
    openRenameModal,
    printMethod,
    t,
    template,
    templateCardType,
  ]);

  return (
    <>
      <DeleteTemplateModal
        ref={deleteTemplateRef}
        template={template}
        isOpen={currentModalType === TemplateDialogType.DELETE}
        handleModalClose={handleModalClose}
      />
      <RenameTemplateModal
        ref={renameTemplateRef}
        template={template}
        isOpen={currentModalType === TemplateDialogType.RENAME}
        handleModalClose={handleModalClose}
      />
      <DuplicateTemplateModal
        ref={duplicateTemplateRef}
        template={template}
        isOpen={currentModalType === TemplateDialogType.DUPLICATE}
        handleModalClose={handleModalClose}
      />
      <MainTemplateModal
        template={template}
        ref={mainModalRef}
        etch={etch}
        ButtonsList={ButtonsListObj()}
        handleModalClose={handleModalClose}
        hidden={currentModalType !== TemplateDialogType.MAIN}
      />
    </>
  );
};

export const TemplateDialogModal = () => {
  const params = useParams();
  const template = useReduxSelector(templateDialog);
  const isLoadingTemplate = useReduxSelector(isTemplateDialogLoading);
  const getSelectedTemplate = useReduxAction(
    templateActions.TEMPLATE_DIALOG.request,
  );
  const queryTemplateID = useUrlParam(
    TEMPLATE_ID_QUERY_PARAM,
    params[TEMPLATE_ID_QUERY_PARAM],
  )[0];

  useEffect(() => {
    if (queryTemplateID && queryTemplateID !== template?.id?.toString()) {
      getSelectedTemplate(queryTemplateID);
    }
  }, [getSelectedTemplate, queryTemplateID, template]);

  return (
    <>
      {isLoadingTemplate && (
        <ModalContainerWithBackdrop noBackdrop={false}>
          <Loader visible={true} />
        </ModalContainerWithBackdrop>
      )}
      {template && queryTemplateID && <TemplateDialog template={template} />}
    </>
  );
};
