import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  Suspense,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {
  Flex,
  Box,
  Text,
  Carousel,
  Loader,
  OptionsMenu,
  OptionsMenuItem,
} from 'components';
import {
  useAppDispatch,
  useReduxSelector,
  useReduxAction,
  useModal,
} from 'hooks';
import {
  getAllPrinters,
  getAllPrintersLoading,
  getSelectedPrinter,
} from 'ducks/printers/selectors';
import { convertDotUnit } from 'utils/unitFormatter';
import { getUnit, getCartridgelow } from 'ducks/preferences/selectors';
import { actions as printerActions } from 'ducks/printers/actions';
import { convertPrinterStatus } from 'utils/printers';
import { useTranslation, Trans } from 'react-i18next';
import { ThemeContext } from 'styled-components/macro';
import { PrinterType } from 'zsbpsdk/src/printer';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { selectFirstName, getUserLoading } from 'ducks/auth/selectors';
import {
  PrintersListHolder,
  PrinterName,
  FirstName,
  Icon,
  StyledText,
  CarouselContainer,
  CenteredFlex,
  PrinterCardInfo,
  CartridgeInfo,
  Small,
  CartridgeModel,
  CartridgeInfoStyles,
} from './PrintersList.styles';
import {
  isBackendProduction,
  HOW_TO_ADD_PRINTER_LINK,
} from '../../../utils/config';
import { getAssetPath } from '../../../utils/files';
import { getWifiIcon } from '../../../utils/icons/getWifiIcon';

const Link = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.primary.base};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary.dark};
  }
`;

const PrintersList = ({
  DeletePrinterModal,
  PrinterDetailsModal,
  CancelPrintJobsModal,
}: any) => {
  const { t } = useTranslation('components');

  const dispatch = useAppDispatch();
  const theme = React.useContext(ThemeContext);
  const printerDetailsModal = useRef(null);
  const cancelPrintJobsModal = useRef(null);

  const { open: openDetails } = useModal(printerDetailsModal);
  const { open: openCancel } = useModal(cancelPrintJobsModal);

  const [isPrintersStartedLoading, setPrintersStartedLoading] = useState(false);
  const [isPrinterDeleteModalOpen, setIsPrinterDeleteModalOpen] =
    useState(false);

  const selectedPrinter = useReduxSelector(getSelectedPrinter);
  const printers = useReduxSelector(getAllPrinters);
  const getPrinters = useReduxAction(printerActions.ALL.request);
  const firstName = useReduxSelector(selectFirstName);
  const userIsLoading = useReduxSelector(getUserLoading);

  const unit = useReduxSelector(getUnit);
  const [visibleIndex, setVisibleIndex] = useState<number | -1>(0);
  const printersLoading = useReduxSelector(getAllPrintersLoading);
  const cartridgelow = useReduxSelector(getCartridgelow);
  const setSelectedPrinter = useReduxAction(printerActions.SELECTED.set);
  const reprint = useReduxAction(printerActions.REPRINT.request);

  const [varWlanStrength, setVarWlanStrength] = useState(0);

  const optionsMenuRef = useRef(null);

  const visibleIndexChanged = useCallback(
    (index: number) => {
      setVisibleIndex(index);
    },
    [setVisibleIndex],
  );

  const handleClose = (state: boolean) => {
    setIsPrinterDeleteModalOpen(state);
  };

  const handleOpenDeletePrinterModal = useCallback(
    () => setIsPrinterDeleteModalOpen(true),
    [],
  );

  useEffect(() => {
    if (printers && visibleIndex > printers.length - 1) {
      if (printers.length === 0) {
        visibleIndexChanged(-1);
      } else {
        visibleIndexChanged(printers.length - 1);
      }
    } else if (printers && visibleIndex === -1) {
      if (printers.length !== 0) {
        visibleIndexChanged(printers.length - 1);
      }
    }
  }, [dispatch, getPrinters, printers, visibleIndex, visibleIndexChanged]);

  useEffect(() => {
    if (!isPrintersStartedLoading) setPrintersStartedLoading(true);
  }, [printersLoading, isPrintersStartedLoading]);

  const handleDetails = useCallback(() => {
    if (openDetails) {
      const { uniqueId } = printers[visibleIndex];
      if (uniqueId) setSelectedPrinter(uniqueId);
      openDetails();
    }
  }, [openDetails, printers, setSelectedPrinter, visibleIndex]);

  const handleDelete = useCallback(() => {
    const { uniqueId } = printers[visibleIndex];

    if (uniqueId) setSelectedPrinter(uniqueId);
    handleOpenDeletePrinterModal();
  }, [
    handleOpenDeletePrinterModal,
    printers,
    setSelectedPrinter,
    visibleIndex,
  ]);

  const handleCancel = useCallback(() => {
    if (openCancel) {
      const { uniqueId } = printers[visibleIndex];
      if (uniqueId) setSelectedPrinter(uniqueId);
      openCancel();
    }
  }, [openCancel, printers, setSelectedPrinter, visibleIndex]);

  const handleReprint = useCallback(() => {
    reprint(printers[visibleIndex]);
  }, [reprint, printers, visibleIndex]);

  const reprintWithDebounce = useDebounceCallback(handleReprint, 1000);

  useEffect(() => {
    if (printers && printers[visibleIndex]?.wlan?.strength) {
      setVarWlanStrength(printers[visibleIndex]?.wlan?.strength);
    }
  }, [printers, visibleIndex]);
  // }, [printers, printers[visibleIndex]?.wlan]);

  const { status, cartridgeInfo, name } = useMemo(
    () => printers?.[visibleIndex] || {},
    [printers, visibleIndex],
  );

  const etch = useMemo(() => {
    if (!cartridgeInfo) return { width: '?', height: '?', length: '2', unit };
    let localisedWidth = convertDotUnit(cartridgeInfo?.width, unit); //TODO change to user preferences
    let localisedHeight = convertDotUnit(cartridgeInfo?.length, unit); //TODO change to user preferences
    return {
      width: localisedWidth,
      height: localisedHeight,
      length:
        localisedWidth.length +
        localisedHeight.length +
        unit.abbreviation.length * 2,
      unit,
    };
  }, [cartridgeInfo, unit]);

  const overrideRightClick = (e) => {
    e.preventDefault();
    const menuButton: any = optionsMenuRef.current;
    menuButton.firstChild.click();
  };

  const isPrinterOnline = useMemo(
    () =>
      convertPrinterStatus(
        status,
        cartridgeInfo?.remaininglabels,
        cartridgelow,
      ) !== t('components:printer.status.offline'),
    [status, cartridgelow, cartridgeInfo, t],
  );

  const isNotInErrorState = useMemo(
    () =>
      convertPrinterStatus(
        status,
        cartridgeInfo?.remaininglabels,
        cartridgelow,
      ) !== t('components:printer.status.cover-open') &&
      convertPrinterStatus(
        status,
        cartridgeInfo?.remaininglabels,
        cartridgelow,
      ) !== t('components:printer.status.paper-out'),
    [status, cartridgeInfo, cartridgelow, t],
  );

  return (
    <Suspense fallback={'load'}>
      {DeletePrinterModal && (
        <DeletePrinterModal
          handleClose={handleClose}
          printer={selectedPrinter}
          open={isPrinterDeleteModalOpen}
        />
      )}
      {PrinterDetailsModal && (
        <PrinterDetailsModal
          printer={selectedPrinter}
          ref={printerDetailsModal}
        />
      )}
      {CancelPrintJobsModal && (
        <CancelPrintJobsModal
          printer={selectedPrinter}
          ref={cancelPrintJobsModal}
        />
      )}
      <PrintersListHolder p={11} elevation={'three'}>
        {printersLoading || !isPrintersStartedLoading || userIsLoading ? (
          <Loader visible={true} />
        ) : printers?.[0] ? (
          <Flex
            onContextMenu={overrideRightClick}
            flexDirection="column"
            width="100%"
            height="100%"
          >
            <Box>
              <Flex flexDirection="row" justifyContent="space-between">
                <Box width="80%">
                  <PrinterName
                    data-testid="current-printer-name"
                    fontSize={28}
                    lineHeight={'36px'}
                    fontWeight={'300'}
                    letterSpacing={0.4}
                    color={theme.neutral.darkest}
                  >
                    {`${name}`}
                  </PrinterName>

                  <Flex flexDirection="row" justifyContent="flex-start">
                    <Text
                      data-testid="current-printer-status"
                      fontSize={16}
                      lineHeight={'24px'}
                      fontWeight={'normal'}
                      letterSpacing={0.4}
                      color={theme.textColors.med}
                      mr={4}
                    >
                      {convertPrinterStatus(
                        status,
                        cartridgeInfo?.remaininglabels,
                        cartridgelow,
                      )}
                    </Text>

                    <>
                      {varWlanStrength > 0 && isPrinterOnline && (
                        <div
                          title={t('components:printer.wireless-strength', {
                            varWlanStrength,
                          })}
                        >
                          <Icon
                            data-testid="current-wifi-status"
                            alt={t('components:printer.wireless-strength', {
                              varWlanStrength,
                            })} //Don't icomoon me as it overrides negative colours
                            src={getWifiIcon(varWlanStrength)}
                          />
                        </div>
                      )}
                    </>
                  </Flex>
                </Box>
                <Box width="20%" position={'relative'} top={8} right={8}>
                  <StyledText>
                    <OptionsMenu
                      ref={optionsMenuRef}
                      data-testid="printer-options"
                    >
                      {/* <OptionsMenuItem
                        data-testid="printer-queue"
                        onClick={() => alert('not implemented yet')}
                      >
                        Queue
                      </OptionsMenuItem> */}
                      <OptionsMenuItem
                        data-testid="reprint"
                        onClick={reprintWithDebounce}
                      >
                        {t('components:printer.options.reprint-last-label')}
                      </OptionsMenuItem>
                      <OptionsMenuItem
                        data-testid="printer-queue"
                        onClick={handleCancel}
                      >
                        {t('components:printer.options.clear-print-queue')}
                      </OptionsMenuItem>

                      <OptionsMenuItem
                        data-testid="delete-printer"
                        onClick={handleDelete}
                        danger
                      >
                        {t('components:printer.options.delete')}
                      </OptionsMenuItem>

                      {!isBackendProduction() && (
                        <OptionsMenuItem
                          data-testid="printer-details-option"
                          onClick={handleDetails}
                        >
                          {t('components:printer.options.debug-details')}
                        </OptionsMenuItem>
                      )}
                    </OptionsMenu>
                  </StyledText>
                </Box>
              </Flex>
            </Box>
            <CarouselContainer>
              <Carousel
                onUpdate={visibleIndexChanged}
                currentIndex={visibleIndex}
              >
                {printers.map((printer: PrinterType, i: number) => (
                  <Carousel.PrinterSlide key={i} self={i} printer={printer} />
                ))}
              </Carousel>
            </CarouselContainer>

            {isPrinterOnline && isNotInErrorState ? (
              <Box>
                <CenteredFlex flexDirection="row" justifyContent="space-around">
                  <PrinterCardInfo>
                    <Flex
                      data-testid="printers-list-label-dimensions"
                      flexDirection="row"
                      alignItems="baseline"
                    >
                      <CartridgeInfo
                        data-testid="printers-list-label-width"
                        fontSize={cartridgeInfo?.totallabels > 999 ? 22 : 24}
                      >{`${etch.width}${
                        etch.unit.abbreviation.length === 1
                          ? etch.unit.abbreviation
                          : ''
                      }`}</CartridgeInfo>
                      {etch.unit.abbreviation.length !== 1 && (
                        <Small>{etch.unit.abbreviation}</Small>
                      )}
                      <CartridgeInfo
                        fontSize={cartridgeInfo?.totallabels > 999 ? 22 : 24}
                      >
                        &nbsp;x&nbsp;
                      </CartridgeInfo>
                      <CartridgeInfo
                        data-testid="printers-list-label-height"
                        fontSize={cartridgeInfo?.totallabels > 999 ? 22 : 24}
                      >{`${etch.height}${
                        etch.unit.abbreviation.length === 1
                          ? etch.unit.abbreviation
                          : ''
                      }`}</CartridgeInfo>
                      {etch.unit.abbreviation.length !== 1 && (
                        <Small>{etch.unit.abbreviation}</Small>
                      )}
                    </Flex>
                    <CartridgeModel
                      data-testid="printers-list-cartridge-model"
                      fontSize={14}
                      lineHeight={'21px'}
                      fontWeight={'300'}
                      letterSpacing={0.4}
                      color={theme.neutral.darkest}
                      textAlign="center"
                    >
                      {`${cartridgeInfo?.model}`}
                    </CartridgeModel>
                  </PrinterCardInfo>
                  <PrinterCardInfo>
                    <Flex
                      data-testid="printers-list-label-count"
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="baseline"
                    >
                      <Trans i18nKey="components:printer.prints-left">
                        <span
                          data-testid="printers-list-labels-remaining"
                          style={{
                            fontSize:
                              cartridgeInfo?.totallabels > 999 ? 22 : 24,
                            color: theme.neutral.darkest,
                            ...CartridgeInfoStyles,
                          }}
                        >
                          {{ prints: String(cartridgeInfo?.remaininglabels) }}
                        </span>
                        <Small>of</Small>
                        <span
                          data-testid="printers-list-total-labels"
                          style={{
                            fontSize:
                              cartridgeInfo?.totallabels > 999 ? 22 : 24,
                            color: theme.neutral.darkest,
                            ...CartridgeInfoStyles,
                          }}
                        >
                          {{ totalPrints: cartridgeInfo?.totallabels }}
                        </span>
                        <Text
                          fontSize={14}
                          lineHeight={'21px'}
                          fontWeight={'300'}
                          letterSpacing={0.4}
                          color={theme.neutral.darkest}
                          width="100%"
                          textAlign="center"
                        >
                          prints left
                        </Text>
                      </Trans>
                    </Flex>
                  </PrinterCardInfo>
                </CenteredFlex>
              </Box>
            ) : (
              <Box>
                <CenteredFlex flexDirection="row" justifyContent="space-around">
                  <PrinterCardInfo>
                    <Flex
                      data-testid="printers-list-label-dimensions"
                      flexDirection="row"
                      alignItems="baseline"
                    >
                      <CartridgeInfo
                        data-testid="printers-list-label-width"
                        fontSize={24}
                      >
                        N/A
                      </CartridgeInfo>
                    </Flex>
                    <CartridgeModel
                      data-testid="printers-list-cartridge-model"
                      fontSize={14}
                      lineHeight={'21px'}
                      fontWeight={'300'}
                      letterSpacing={0.4}
                      color={theme.neutral.darkest}
                      textAlign="center"
                    >
                      N/A
                    </CartridgeModel>
                  </PrinterCardInfo>
                  <PrinterCardInfo>
                    <Flex
                      data-testid="printers-list-label-count"
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="baseline"
                    >
                      <span
                        data-testid="printers-list-total-labels"
                        style={{
                          fontSize: 24,
                          color: theme.neutral.darkest,
                          ...CartridgeInfoStyles,
                        }}
                      >
                        N/A
                      </span>
                      <Text
                        fontSize={14}
                        lineHeight={'21px'}
                        fontWeight={'300'}
                        letterSpacing={0.4}
                        color={theme.neutral.darkest}
                        width="100%"
                        textAlign="center"
                      >
                        prints left
                      </Text>
                    </Flex>
                  </PrinterCardInfo>
                </CenteredFlex>
              </Box>
            )}
          </Flex>
        ) : (
          <Flex flexDirection="column" style={{ textAlign: 'center' }}>
            <Box mb={8}>
              <FirstName>
                {t('common:no-printers-greeting', { name: firstName })}
              </FirstName>
            </Box>
            <Box>
              <Text
                fontSize={16}
                lineHeight={'24px'}
                fontWeight={'400'}
                color={theme.neutral.base}
              >
                {t('common:learn-how-to-add-printer')}
              </Text>
            </Box>
            <Box p={8}>  
              <img
                height="151"
                src={getAssetPath('/ZSB-DP12_large.png')}
                alt={t('settings:printer.printer-avatar')}
              />
            </Box>
            <Box>
              <Link
                href={HOW_TO_ADD_PRINTER_LINK}
                target="_blank"
                rel="noopener"
              >
                {t('common:learn-how-to-add-printer_cta')}
              </Link>
            </Box>
          </Flex>
        )}
      </PrintersListHolder>
    </Suspense>
  );
};

export default PrintersList;
