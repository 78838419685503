import { put, select, take, takeLatest } from 'redux-saga/effects';
import { getAnnouncements } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  clearBannerMessages,
  fetchBannerMessages,
  setBannerMessages,
} from '../state/Announcement';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';
import { getWhoAmI } from 'ducks/info/selectors';
import { types as infoTypes } from 'ducks/info/actions';

function* handleFetchBannerMessages() {
  const infoLoaded = yield select(getWhoAmI)
  
  // Ensure the initial GetBannerMessages call occurs only after the WhoAmI call 
  if (!infoLoaded) {
    yield take(infoTypes.WHO_AM_I.SUCCESS)
  }
  
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const bannerMessages: BannerMessage[] = yield apiCall(
      getAnnouncements,
      customer?.id,
      token,
    );

    yield put(
      setBannerMessages({
        bannerMessages,
        customer,
      }),
    );
  } catch (error) {
    yield put(clearBannerMessages());
  }
}

export default function* watcher() {
  yield takeLatest(fetchBannerMessages, handleFetchBannerMessages);
}
