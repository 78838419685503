import { generateDataTypes, generateDataActions } from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_DATASOURCES_';

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  ADD: generateDataTypes(BASE_DUCK_PREFIX + 'ADD'),
  ADD_AND_PRINT: generateDataTypes(BASE_DUCK_PREFIX + 'ADD_AND_PRINT'),
  FILTERED: generateDataTypes(BASE_DUCK_PREFIX + 'FILTERED'),
  REMOVE: generateDataTypes(BASE_DUCK_PREFIX + 'REMOVE'),
  SELECTED: generateDataTypes(BASE_DUCK_PREFIX + 'SELECTED'),
  GOOGLE_DRIVE: generateDataTypes(BASE_DUCK_PREFIX + 'GOOGLE_DRIVE'),
  OFFICE_365: generateDataTypes(BASE_DUCK_PREFIX + 'OFFICE_365'),
  GOOGLE_ACCOUNT: generateDataTypes(BASE_DUCK_PREFIX + 'GOOGLE_ACCOUNT'),
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  ADD: generateDataActions(types.ADD),
  ADD_AND_PRINT: generateDataActions(types.ADD_AND_PRINT),
  FILTERED: generateDataActions(types.FILTERED),
  REMOVE: generateDataActions(types.REMOVE),
  SELECTED: generateDataActions(types.SELECTED),
  GOOGLE_DRIVE: generateDataActions(types.GOOGLE_DRIVE),
  OFFICE_365: generateDataActions(types.OFFICE_365),
  GOOGLE_ACCOUNT: generateDataActions(types.GOOGLE_ACCOUNT),
};
