const size = {
  mobile: '735px',
  mobileWide: '736px',
  tabletM: '800px',
  tablet: '975px',
  desktop: '976px',
  desktopMiddle: '1200px',
  desktopWide: '1328px',
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  mobileWide: `(min-width: ${size.mobileWide})`,
  tablet: `(max-width: ${size.tablet})`,
  mobileToTablet: `(min-width: ${size.mobileWide}) and (max-width: ${size.tablet})`,
  tabletM: `(min-width: ${size.tabletM}) and (max-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopMiddle: `(min-width: ${size.desktopMiddle}) and (max-width: ${size.desktopWide})`,
  desktopWide: `(min-width: ${size.desktopWide})`,
  desktopM: `(min-width: ${size.desktop}) and (max-width: ${size.desktopMiddle})`,
};
