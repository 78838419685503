import React, { useEffect, useState } from 'react';
import { Flex, ToggleSwitch, Text, Loader } from 'components';

import { ThemeContext } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { actions as preferencesActions } from 'ducks/preferences/actions';
import { useReduxSelector, useReduxAction } from 'hooks';
import {
  getAllPreferences,
  getAllPreferencesLoading,
  getCartridgelow,
  getPrintjobs,
} from 'ducks/preferences/selectors';

export const Settings = () => {
  const { t } = useTranslation(['notifications', 'utils']);
  const theme = React.useContext(ThemeContext);
  const printjobs = useReduxSelector(getPrintjobs);
  const cartridgelow = useReduxSelector(getCartridgelow);
  const prefs = useReduxSelector(getAllPreferences);
  const isLoadingPrefs = useReduxSelector(getAllPreferencesLoading);
  const updatePreferences = useReduxAction(preferencesActions.UPDATE.request);
  const [printJobsLocal, setPrintJobsLocal] = useState(false);
  const [cartridgeLowLocal, setCartridgeLowLocal] = useState(false);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const [prefsLocal, setPrefsLocal] = useState<any>({});

  useEffect(() => {
    setPrefsLocal(prefs);
  }, [prefs]);

  useEffect(() => {
    setIsLoadingPreferences(isLoadingPrefs);
  }, [isLoadingPrefs]);

  useEffect(() => {
    if (!isLoadingPreferences) {
      setPrintJobsLocal(printjobs);
    }
  }, [isLoadingPreferences, printjobs]);

  useEffect(() => {
    if (!isLoadingPreferences) {
      setCartridgeLowLocal(cartridgelow);
    }
  }, [isLoadingPreferences, cartridgelow]);

  const savePrefsPrintJobs = (printjobs: boolean) => {
    updatePreferences({
      data: {
        ...prefs,
        units: prefs.units.grpcUnit,
        notificationpreference: {
          ...prefs.notificationpreference,
          printjobs,
        },
      },
      message: t('notifications:preferences-updated'),
    });
    setPrintJobsLocal(printjobs);
  };

  const savePrefsCartridgeLow = (cartridgelow: boolean) => {
    updatePreferences({
      data: {
        ...prefsLocal,
        units: prefsLocal.units.grpcUnit,
        notificationpreference: {
          ...prefsLocal.notificationpreference,
          cartridgelow,
        },
      },
      message: t('notifications:preferences-updated'),
    });
    setCartridgeLowLocal(cartridgelow);
  };

  return (
    <>
      {isLoadingPreferences ? (
        <Loader visible={true} />
      ) : (
        <Flex flexDirection={'column'}>
          <Flex flexDirection={'row'} mb={'32px'}>
            <Text
              data-testid="notification-settings-description"
              fontSize={14}
              lineHeight={'32px'}
              fontWeight={'400'}
              letterSpacing={0.4}
              color={theme.textColors.med}
            >
              {t('notifications:on-off')}
            </Text>
          </Flex>

          <Text
            data-testid="notifications-settings-when"
            mb={10}
            fontSize={18}
            lineHeight={'28px'}
            letterSpacing={0.4}
            color={theme.textColors.high}
          >
            {t('notifications:when')}
          </Text>
          <ToggleSwitch
            data-testid="documents-printed-switch"
            aria-label={'printjobs'}
            value={'printjobs'}
            label={t('notifications:documents-are-printed')}
            defaultChecked={printJobsLocal}
            onChange={(e) => {
              savePrefsPrintJobs(!printJobsLocal);
            }}
          />
          <ToggleSwitch
            data-testid="printer-low-empty-switch"
            value={'cartridgelow'}
            label={t('notifications:printer-is-low-and-empty')}
            defaultChecked={cartridgeLowLocal}
            onChange={(e) => {
              savePrefsCartridgeLow(!cartridgeLowLocal);
            }}
          />
        </Flex>
      )}
    </>
  );
};
