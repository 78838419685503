import React, { useEffect, useState } from 'react';

import {
  DEFAULT_USER_COUNTRY_CODE,
  isBackendStageOrProduction,
} from 'utils/config';
import SalesForceChat from 'pages/help/components/snapin';
import { useReduxSelector } from 'hooks';
import { getAllPrinters } from 'ducks/printers/selectors';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import { CustomerInfoType } from 'zsbpsdk/src/customer';
import { getCustomer, getUser } from 'ducks/auth/selectors';

const buttonId = '5730H0000002gOs';
const deploymentId = '5720H0000002cjj';
const organisationId = '00Di0000000KxK7';
const CONTENT_URL_STAGE_PRODUCTION =
  'https://c.la1-c2-iad.salesforceliveagent.com/content';
const CONTENT_URL_DEVELOPMENT =
  'https://c.la3-c1cs-ia5.salesforceliveagent.com/content';
const CHAT_URL_STAGE_PRODUCTION =
  'https://d.la4-c1-ia4.salesforceliveagent.com/chat';
const CHAT_URL_DEVELOPMENT =
  'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat';

type Context = {
  init: () => {};
  activateChat: () => void;
  isOffline: boolean;
  isLoading: boolean;
};
const LiveChatContext = React.createContext<Context | null>(null);

export const useLiveChat = (): Context => {
  const context = React.useContext(LiveChatContext);

  if (context === null) {
    throw new Error('useLiveChat must be within LiveChatProvider');
  }

  return context;
};

const contentUrl = getLiveAgentContentUrl();

export const LiveChatProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const printers = useReduxSelector(getAllPrinters);
  const user = useReduxSelector(getUser);
  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const [isOffline, setIsOffline] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const userFirstName = user ? user.firstName : customer?.firstName;
  const userLastName = user ? user.lastName : '';
  const userEmail = customer?.email;
  const userId = user ? user.zuid : customer?.id;
  const userCountry = user ? user.country : DEFAULT_USER_COUNTRY_CODE;

  const init = async () => {
    if (!customer) {
      return;
    }
    await loadLiveAgentScritps();

    initLiveAgent(
      {
        userFirstName,
        userEmail,
        userId,
        userLastName,
        userCountry,
        userPrintersList: getPrintersList(printers),
      },
      customer.id,
      setIsOffline,
      setIsLoading,
    );
  };

  const activateChat = () => {
    SalesForceChat.activateChat();
    saveToLocalStorage(userLocalStorageVariable(customer.id), true);
  };

  useEffect(() => {
    if (customer && getUserOpenedChat(customer.id)) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (isOffline === false && getUserOpenedChat(customer.id)) {
      SalesForceChat.activateChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffline]);

  return (
    <LiveChatContext.Provider
      value={{ init, activateChat, isOffline, isLoading }}
    >
      {children}
    </LiveChatContext.Provider>
  );
};

export default LiveChatProvider;

function getPrintersList(printers: any[]) {
  let userPrintersList = '';
  printers &&
    printers.forEach((printer, index) => {
      if (index === 0) {
        userPrintersList = printer.name;
      } else {
        userPrintersList += `, ${printer.name}`;
      }
    });

  return userPrintersList;
}

function initLiveAgent(
  userInfo,
  customerId: string,
  callback: (r: boolean) => void,
  isLoadingCallback: (r: boolean) => void,
) {
  const newDiv = document.createElement('div');
  newDiv.style.display = 'unset';

  if (!window._laq) {
    window._laq = [];
  }
  window._laq.push(() => {
    window.liveagent.showWhenOnline(buttonId, newDiv);
  });

  window.liveagent.init(getLiveAgentChatUrl(), deploymentId, organisationId);

  SalesForceChat.getUserInfo(
    userInfo.userFirstName,
    userInfo.userEmail,
    userInfo.userId,
    userInfo.userCountry,
    userInfo.userLastName,
    userInfo.userPrintersList,
  );
  window.addEventListener('chatEvent', (event: any) => {
    if (event.detail.isChatActive === false) {
      saveToLocalStorage(userLocalStorageVariable(customerId), false);
    }
  });

  const c = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        callback(mutation.target.style.display === 'none');
        isLoadingCallback(false);
      }
    }
  };

  const observer = new MutationObserver(c);
  observer.observe(newDiv, {
    attributeFilter: ['style'],
    attributeOldValue: true,
  });
}

function userLocalStorageVariable(customerId: string) {
  return 'userOpenedChatWindow_' + customerId;
}

function getUserOpenedChat(customerId: string) {
  let userOpenedChatWindow = getFromLocalStorage(
    userLocalStorageVariable(customerId),
  );
  return userOpenedChatWindow !== null && userOpenedChatWindow === 'true';
}

function loadLiveAgentScritps() {
  if (window.liveagent) {
    return Promise.resolve();
  }
  return Promise.all([
    loadScript(`${contentUrl}/g/js/47.0/deployment.js`),
    loadScript(`${contentUrl}/g/js/47.0/prechat.js`),
    loadScript(
      'https://zebra.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    ),
  ]);
}

function loadScript(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.addEventListener('load', () => {
      resolve(url);
    });
    script.addEventListener('error', () => {
      console.error('Script rejected', url);
      reject(url);
    });
    script.src = url;
    document.body.appendChild(script);
  });
}

function getLiveAgentContentUrl() {
  return isBackendStageOrProduction()
    ? CONTENT_URL_STAGE_PRODUCTION
    : CONTENT_URL_DEVELOPMENT;
}
function getLiveAgentChatUrl() {
  return isBackendStageOrProduction()
    ? CHAT_URL_STAGE_PRODUCTION
    : CHAT_URL_DEVELOPMENT;
}
