import { createReducer, BaseAction } from 'ducks/utils';
import { types } from './actions';

export const SIDEBAR_INITIAL_STATE = { isCollapsed: false };

const sidebar = createReducer(SIDEBAR_INITIAL_STATE, {
  [types.COLLAPSED]: (state: any, action: BaseAction) => ({
    ...state,
    isCollapsed: action.payload,
  }),
});

export default sidebar;
