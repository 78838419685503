import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFilteredTemplates, useReduxAction, useReduxSelector } from 'hooks';
import { recentTemplates } from 'ducks/templates/selectors';
import { actions as templateActions } from 'ducks/templates/actions';
import { TemplatesGrid } from 'v2/components/app';
import { ErrorText } from 'v2/components/common';
import { TemplatesWrap } from 'v2/components/app/TemplatesGrid/TemplatesGrid.styles';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';
import { DialogContent } from '../Dialog.styles';
import { StyledFlex } from './RecentlyPrinted.styles';
import NoRecentlyPrinted from './NoRecentlyPrinted';

const StyledTemplatesGrid = styled(TemplatesGrid)`
  & ${TemplatesWrap} {
    grid-template-columns: 1fr 1fr;
    @media ${device.desktopM} {
      grid-template-columns: 1fr;
    }
    @media ${device.tablet} {
      grid-template-columns: 1fr;
    }
  }
`;

export default function TemplatesList() {
  const { t } = useTranslation('templates');
  const recentTemplatesAction = useReduxAction(templateActions.RECENT.request);
  const recentlyPrintedTemplates = useReduxSelector(recentTemplates);

  const {
    filteredTemplatesData: recentlyPrintedTemplatesData,
    filteredTemplatesDataLoading: recentlyPrintedTemplatesDataLoading,
    filteredTemplatesError: recentlyPrintedTemplatesError,
    totalPagesCount,
  } = useFilteredTemplates(recentTemplates, recentTemplatesAction);

  if (recentlyPrintedTemplatesError) {
    return (
      <ErrorText variant="h2" mb={8}>
        {t('templates:server-error')}
      </ErrorText>
    );
  }

  return (
    <>
      <DialogContent>
        <StyledFlex mt={16}>
          {totalPagesCount === 0 &&
          !recentlyPrintedTemplatesDataLoading &&
          recentlyPrintedTemplates ? (
            <NoRecentlyPrinted />
          ) : (
            <StyledTemplatesGrid
              data-testid="recently-printed-labels"
              isLoading={recentlyPrintedTemplatesDataLoading}
              templates={recentlyPrintedTemplatesData}
            />
          )}
        </StyledFlex>
      </DialogContent>
    </>
  );
}
