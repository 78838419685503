import { Flex, Box, Card, Icon, Text } from 'components';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

export const FullScreenBox = styled(Flex)<any>`
  position: fixed;
  width: 100%;
  left: 0;
  @media ${device.desktop} {
    width: ${(props) =>
      props.collapsedState ? 'calc(100% - 54px)' : 'calc(100% - 240px)'};
    left: ${(props) => (props.collapsedState ? '54px' : '240px')};
    transition: left 0.6s ease-out, width 0.6s ease-out;
  }
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  overflow: scroll;
  justify-content: center;
  overflow: scroll;
  @media screen and (min-height: 900px) {
    overflow-y: scroll;
    overflow: visible;
  }
  z-index: 99;
`;

export const CardHolder = styled(Card)`
  @media screen and (min-height: 900px) {
    margin-top: calc((100vh - 856px) / 2);
    margin-bottom: calc((100vh - 856px) / 2);
  }

  @media screen and (max-width: 900px) {
    margin-top: 90px;
    max-width: 90%;
    min-width: 70%;
  }
  margin-bottom: 82px;
  margin-top: 70px;
  width: 70%;
  height: fit-content;
  flex: 1;
  max-width: 760px;
  position: relative;
  background-color: ${({ theme }) => theme.white};
`;

export const CloseButton = styled(Box)`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  font-size: 0;
  border: 0 none;
  background: transparent;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 14,
}))``;

export const CarouselContainer = styled(Box)`
  max-width: 100%;
  poition: relative;
  align-self: center;
  margin-bottom: -80px;
`;

export const SlideContainer = styled.div`
  width: 80%;
  border-style: solid;
  border-color: #edeff1;
  border-width: 2px;
  border-radius: 8px;
`;

export const Slide = styled(Flex)`
  height: 100%;
  width: 100%;
  padding: 48px 34px 140px;
  flex-direction: column;
  align-items: center;

  && {
    img {
      display: block;
      margin: 0 auto;
    }
  }
`;

export const CarouselTitle = styled(Text)`
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  line-height: 1.3;
  letter-spacing: 0.4;
  color: ${({ theme }) => theme.neutral.darkest};
  padding-top: 16px;
`;

export const SlideSubTitle = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.neutral.base};
  padding-top: 40px;
`;

export const SlideDescription = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.4;
  color: ${({ theme }) => theme.neutral.darkest};
`;

export const SlideContent = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})``;
