import { Box, Flex } from 'components';
import Text from 'v2/components/common/Text/Text';
import styled from 'styled-components';

export const Title = styled(Text)`
  line-height: 36px;
`;

export const Subtitle = styled(Text)`
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.4px;
`;

export const ButtonsContainer = styled(Flex).attrs(() => ({
  py: 11,
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  align-items: center;
  z-index: 1;
  background-color: ${({ theme }) => theme.primary.background};
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding-right: 80px;
`;

export const Section = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[11]}px;
`;

export const SectionTitle = styled(Text)`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.textColors.med};
`;

export const PreviewWrapper = styled.div.attrs(() => ({}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-radius: 8px;
  margin-bottom: 24px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.primary.lightest};
`;

export const SideText = styled.p`
  color: ${({ theme }) => theme.textColors.med};
  margin-top: 30px;
`;

export const UploadInput = styled.input.attrs({
  type: 'file',
  hidden: true,
})``;

export const StyledImg = styled.img`
  border-radius: 8px;
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

export const Radio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  height: 24px;
  width: 24px;
  margin: 0 12px 0 0;
  color: ${({ theme }) => theme.primary.base}; /*Doesnt work*/
`;

export const ThemeWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 10px 24px;
`;

export const ThemePicture = styled.picture.attrs({
  height: '171px',
})`
  img {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    height: 171px;
  }
`;

export const ThemeExample = styled(Flex).attrs(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
}))`
  border-radius: 8px;
  height: 173px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  margin-top: 8px;
`;
