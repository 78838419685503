import * as grpc from 'zsbpsdk/src/customer';
import { MM_IN_ONE_INCH } from 'utils/config.json';

export const convertRawUnit = (value: number, unit: Unit): string => {
  return Number(value * unit.multiplier)
    .toFixed(unit.precision)
    .replace(/(.*[1-9.])0+$/, '$1')
    .replace(/(.*)\.$/, '$1');
};

export const convertDotUnit = (value: number, unit: Unit): string => {
  return Number(value * unit.dotMultiplier)
    .toFixed(unit.precision)
    .replace(/(.*[1-9.])0+$/, '$1')
    .replace(/(.*)\.$/, '$1');
};

export const convertLabelSizeString = (string: string) => {
  let width,
    height = '';
  let rawSize = string.split('x');

  if (rawSize.length === 2) {
    width = rawSize[0];
    height = rawSize[1];
  }

  return {
    width,
    height,
  };
};

export const convertUnitToType = (num: number): Unit => {
  switch (num) {
    case grpc.Units.MILLIMETERS:
      return MM;
    case grpc.Units.CENTIMETERS:
      return CM;
    case grpc.Units.INCHES:
    default:
      return INCH;
  }
};

export const convertStringToUnit = (unit: string) => {
  if (unit === 'MILLIMETERS') {
    return MM;
  }
  if (unit === 'CENTIMETERS') {
    return CM;
  }
  return INCH;
};

export const convertStringToDithering = (dithering: string) => {
  if (dithering === 'SUPERCELL_M') {
    return grpc.Dithering.SUPERCELL_M;
  }
  if (dithering === 'PATTERN_2_X_2') {
    return grpc.Dithering.PATTERN_2_X_2;
  }
  if (dithering === 'PATTERN_8_X_8') {
    return grpc.Dithering.PATTERN_8_X_8;
  }
  if (dithering === 'PATTERN_16_X_16') {
    return grpc.Dithering.PATTERN_16_X_16;
  }
  if (dithering === 'SOLID') {
    return grpc.Dithering.SOLID;
  }
  return grpc.Dithering.FLOYD_STEINBERG;
};

type Unit = {
  name: string;
  abbreviation: string;
  multiplier: number;
  dotMultiplier: number;
  precision: number;
  grpcUnit: number;
};

export const UNKNOWN: Unit = {
  name: 'Unknown Units',
  abbreviation: '?',
  multiplier: 0,
  dotMultiplier: 0,
  precision: 0,
  grpcUnit: grpc.Units.UNKNOWN_UNITS,
};

export const INCH: Unit = {
  name: 'Inches',
  abbreviation: '"',
  multiplier: 3.93701e-5,
  dotMultiplier: 3.33333e-3,
  precision: 2,
  grpcUnit: grpc.Units.INCHES,
};

export const MM: Unit = {
  name: 'Millimeters',
  abbreviation: 'mm',
  multiplier: 1.0e-3,
  dotMultiplier: 8.46666e-2,
  precision: 0,
  grpcUnit: grpc.Units.MILLIMETERS,
};

export const CM: Unit = {
  name: 'Centimeters',
  abbreviation: 'cm',
  multiplier: 1.0e-4,
  dotMultiplier: 8.46666e-3,
  precision: 1,
  grpcUnit: grpc.Units.CENTIMETERS,
};

export const getUnits = () => {
  return [MM, CM, INCH];
};

export const getEtch = (template, unit: Unit, orientation: string = '1') => {
  let localisedWidth = convertRawUnit(template.width, unit);
  let localisedHeight = convertRawUnit(template.height, unit);

  if (orientation === '1') {
    return `${localisedWidth === 'NaN' ? '?' : localisedWidth}${
      unit.abbreviation
    } x ${localisedHeight === 'NaN' ? '?' : localisedHeight}${
      unit.abbreviation
    }`;
  }

  return `${localisedHeight === 'NaN' ? '?' : localisedHeight}${
    unit.abbreviation
  } x ${localisedWidth === 'NaN' ? '?' : localisedWidth}${unit.abbreviation}`;
};

export const convertTemplateSizeToInch = (value: number): number =>
  Number((value / 1000 / MM_IN_ONE_INCH).toFixed(2));

export const convertCartridgeInfoSizeToInch = (
  value: number,
  resolutionDotsPerInch: number,
): number => value / resolutionDotsPerInch;

export default Unit;
