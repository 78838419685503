import * as React from 'react';
import { Flex, Box, Search } from 'components';
import { NoHiddenOverflowCard } from './dropdowns';
import { useUrlParam } from 'hooks';
import { templateCategories } from 'utils/categories';
import { allTemplates } from 'utils/demo/templates';

const MixedSearch = () => {
  const [value, setValue] = useUrlParam('mixedsearch', '');
  const [mixedOptions, setMixedOptions] = React.useState<any>([]);
  const onChange = (value: string) => {
    let templatesFiltered = allTemplates.filter((t: any) =>
      t.name.startsWith(value),
    );
    let categoriesFiltered = Object.keys(templateCategories).filter(
      (cat: string) => cat.startsWith(value),
    );
    setMixedOptions([
      { label: 'Categories', type: 'section' },
      ...categoriesFiltered,
      { label: 'Templates', type: 'section' },
      ...templatesFiltered,
    ]);
  };
  return (
    <Search
      onChange={onChange}
      initialSearch={value}
      onSelect={setValue}
      options={mixedOptions}
    />
  );
};

const SearchesView = (): any => {
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = useUrlParam('search', '');

  const onChange = async (value: string) => {
    try {
      const res = await fetch(`https://restcountries.eu/rest/v2/name/${value}`);
      const cities = await res.json();
      if (cities.length) {
        let citiesFiltered = cities.filter((c: any) =>
          c.name.startsWith(value),
        );
        setOptions(citiesFiltered);
      } else {
        setOptions([]);
      }
    } catch {
      setOptions([]);
    }
  };

  return (
    <NoHiddenOverflowCard p={10} pb={40} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>{'Searches'}</h2>
        <Flex flexDirection="row" flexWrap="wrap">
          <Box width={512} mr={10}>
            <Search
              onChange={onChange}
              onSelect={setValue}
              initialSearch={value}
              options={options}
            />
          </Box>
          <Box width={512} mt={10} mr={10}>
            <MixedSearch />
          </Box>
        </Flex>
      </Flex>
    </NoHiddenOverflowCard>
  );
};

export default SearchesView;
