import styled, { DefaultTheme } from 'styled-components';
import {
  space,
  typography,
  SpaceProps,
  TypographyProps,
  color,
  ColorProps,
} from 'styled-system';

type TextVariant = 'title' | 'h1' | 'h2' | 'h3' | 'body' | 'label' | 'caption' ;
type CustomTextProps = {
  variant?: TextVariant;
  maxWidth?: number;
};

const getVariant = (theme: DefaultTheme, variant: TextVariant): string => {
  switch (variant) {
    case 'title':
      return `
      font-size: ${theme.fontSizes.title}px;
      font-weight: 300;
      line-height: 48px;
    `;

    case 'h1':
      return `
      font-size: ${theme.fontSizes.h1}px;
    `;

    case 'h2':
      return `
      font-size: ${theme.fontSizes.h2}px;
      font-weight: 300;
      `;

    case 'h3':
      return `
      font-size: ${theme.fontSizes.h3}px;
      `;
      
    case 'body':
      return `
      font-size: ${theme.fontSizes.body}px;
      `;

    case 'label':
      return `
        font-size: ${theme.fontSizes.label}px;
        font-style: normal;
      `;

    case 'caption':
      return `
        font-size: ${theme.fontSizes.caption}px;
      `;
    default:
      return '';
  }
};

const Text = styled.span.attrs(({ variant = 'label' }: CustomTextProps) => ({
  variant,
}))<CustomTextProps & SpaceProps & TypographyProps & ColorProps>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  color: ${({ theme }) => theme.neutral.darkest};
  font-weight: 400;
  letter-spacing: 0.4px;
  font-family: ${({ theme }) => theme.defaultFont};
  ${({ theme, variant }) => getVariant(theme, variant)}
  ${space}
  ${typography}
  ${color}
`;

export default Text;
