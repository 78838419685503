import React from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateType } from 'zsbpsdk/src/templates';

import styled from 'styled-components';

import { useReduxSelector } from 'hooks';
import { getUnit } from 'ducks/preferences/selectors';
import { TemplateCardType } from 'components/app/template-card/TemplateCard';
import { Box, Flex, Loader } from 'components';
import { LastPrintText } from 'components/app/template-card/TemplateCard.styles';
import { LoaderWrapper } from 'v2/components/common/Layout/Layout';

import {
  HintText,
  StyledTemplateCard,
  TemplatesWrap,
} from './TemplatesGrid.styles';

export const StyledTemplateCardWrapper = styled(StyledTemplateCard)`
  & ${LastPrintText} {
    padding-top: 16px;
  }
`;

export type TemplatesListProps = {
  templates: any;
  isLoading: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export default function TemplatesGrid({
  templates,
  isLoading,
  ...props
}: TemplatesListProps) {
  const { t } = useTranslation();

  const unit = useReduxSelector(getUnit);

  return (
    <Box {...props}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader visible />
        </LoaderWrapper>
      ) : (
        <Flex>
          <Flex
            flex={1}
            flexDirection={'column'}
            justifyContent={'start'}
            className="library-content"
          >
            {templates?.totalCount === 0 && (
              <HintText>{t('templates:templates-not-found')}</HintText>
            )}

            <TemplatesWrap>
              {templates?.fileDataList &&
                templates?.fileDataList.map((template: TemplateType) => (
                  <StyledTemplateCardWrapper
                    template={template}
                    key={template.id}
                    elevation={'one'}
                    type={TemplateCardType.LIBRARY}
                    unit={unit}
                    showLastPrint={true}
                    dateFormat={'MMM DD, YYYY'}
                  />
                ))}
            </TemplatesWrap>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
