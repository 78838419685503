import React from 'react';
import { Route, Switch } from 'react-router';
import { NavigationTab } from './NavigationTab';
import { Drivers } from './Drivers';
import { ContentOuterWrap, ContentWrap } from 'components/common/layout/Layout';
// import { Integrations } from './Integrations';

const Apps = () => {
  return (
    <ContentOuterWrap>
      <ContentWrap>
        <NavigationTab />
        <Switch>
          <Route path="/apps" component={Drivers} />
          {/* <Route path="/apps" component={Integrations} /> */}
        </Switch>
      </ContentWrap>
    </ContentOuterWrap>
  );
};

export default Apps;
