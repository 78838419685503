import styled from 'styled-components';
import { margin } from 'styled-system';

import { Flex } from 'components';
import Text from '../Text/Text';

export const RadioContainer = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  ${margin}
`;

export const StyledLabel = styled.label`
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
`;

export const LabelText = styled(Text)<{ disabled?: boolean }>`
  margin-left: ${({ theme }) => theme.px(6)};
  ${({ theme, disabled }) => disabled && `color: ${theme.neutral.lightest};`}
`;

export const RadioFilling = styled(Flex)<{ disabled?: boolean }>`
  background: ${({ theme, disabled }) =>
    !disabled ? theme.primary.base : theme.neutral.lightest};
  width: ${({ theme }) => theme.px(5)};
  height: ${({ theme }) => theme.px(5)};
  border-radius: 5px;
`;

export const StyledRadio = styled(Flex)<{
  checked?: boolean;
  disabled?: boolean;
}>`
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.px(10)};
  width: ${({ theme }) => theme.px(10)};
  border: ${({ theme }) => theme.px(1)} solid #646a78;
  border-radius: ${({ theme }) => theme.px(5)};
  cursor: pointer;

  ${({ checked, disabled, theme }) =>
    checked &&
    !disabled &&
    `
    border: ${theme.px(1)} solid ${theme.primary.base};
  `}

  ${({ disabled, checked, theme }) =>
    disabled &&
    !checked &&
    `
    background-color: ${theme.neutral.lightest};
  `}
  
  ${RadioFilling} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
