import { createSelector } from 'reselect';
import { MM_IN_ONE_INCH } from 'utils/config.json';

import { allPrinters } from '../printers/selectors';

const allLabelSizes = (state: any) => state.labelSizes.all.data;
const selectedLabel = (state: any) => state.labelSizes.selected.data;

export const allLabelSizesLoading = (state: any) =>
  state.labelSizes.all.loading;

export const getAllLabelSizes = createSelector([allLabelSizes], (labelSizes) =>
  labelSizes ? labelSizes : {},
);

export const getSelectedLabel = createSelector([selectedLabel], (label) =>
  label ? label : {},
);

export const selectAllLabelSizes = createSelector(
  [allLabelSizes, allPrinters],
  (labelSizes, printers = []) => {
    if (!labelSizes) {
      return {};
    }
    const umToDotMultiplier = 3 / 254;
    const printerMedia = labelSizes.others.reduce((acc, labelSize) => {
      printers.forEach((printer) => {
        if (
          printer?.cartridgeInfo?.width ===
            Math.round(
              labelSize.displayWidth *
                MM_IN_ONE_INCH *
                1000 *
                umToDotMultiplier,
            ) &&
          printer?.cartridgeInfo?.length ===
            Math.round(
              labelSize.displayHeight *
                MM_IN_ONE_INCH *
                1000 *
                umToDotMultiplier,
            )
        ) {
          acc.push({
            ...labelSize,
            printerName: printer.name,
            isOnline: printer.status.isOnline,
            printerId: printer.allValues?.['device.serial_number'],
          });
        }
      });
      return acc;
    }, []);
    return { printerMedia, others: labelSizes.others };
  },
);
