import React from 'react';
import {
  OptionProps,
  MenuRowWrap,
  OptionIcon,
  MenuRowContent,
} from './DropdownOption.styles';

const DropdownOption = ({
  children,
  onClick,
  selected,
  logo,
  ...props
}: React.PropsWithChildren<OptionProps>) => (
  <MenuRowWrap onClick={onClick} selected={selected} logo={logo} {...props}>
    {logo && <OptionIcon icon={logo} />}{' '}
    <MenuRowContent>{children}</MenuRowContent>
  </MenuRowWrap>
);

export default DropdownOption;
