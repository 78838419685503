import React from 'react';
import { goBack } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Loader } from 'components';
import {
  getAllPreferences,
  getAllPreferencesLoading,
} from 'ducks/preferences/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import DitheringComponent from 'pages/settings/components/Dithering';
import { PageTitle } from 'v2/components/common';
import { Title } from 'pages/settings/components/Dithering.styles';

import Page from '../Page';

const StyledDitheringComponent = styled(DitheringComponent)`
  & ${Title} {
    margin-top: 0;
  }
`;

export const Common = () => {
  const { t } = useTranslation(['other']);
  const preferences = useReduxSelector(getAllPreferences);
  const preferencesLoading = useReduxSelector(getAllPreferencesLoading);
  const goBackAction = useReduxAction(goBack);

  return (
    <Page>
      {preferencesLoading ? (
        <Loader visible={true} />
      ) : (
        <>
          <PageTitle
            onBackClick={() => goBackAction()}
            title={t('other:general')}
          />
          <StyledDitheringComponent
            title={t('settings:graphic')}
            dithering={+preferences.dithering}
          />
        </>
      )}
    </Page>
  );
};
