export const parsedLocalStorage = (key: string): any => {
  const value = localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e: any) {
      return value;
    }
  }
  return undefined;
};

export const saveToLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(error);
  }
};

export const getFromLocalStorage = (key: string) => localStorage.getItem(key);

export const clearFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const saveObjToLocalStorage = (
  obj: { hasBeenOpened: boolean; lastToken: string },
  localStorageVariableName: string,
) => {
  const aboutToExpiredWarning = {
    ...obj,
    tomorrowDate: +new Date().getTime() + 86400 * 1000,
  };

  saveToLocalStorage(
    localStorageVariableName,
    JSON.stringify(aboutToExpiredWarning),
  );
};
