import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { useUrlParam } from 'hooks';
import { Box, Flex, Search, Text } from 'components';
import { useReduxSelector, useReduxAction } from 'hooks';
import { actions as searchActions } from 'ducks/search/actions';
import { device } from 'utils/mediaQueries';
import { FilesButtons } from 'pages/dataSources/components/FilesButtons';
import { getDatasourcesSuggestions } from 'ducks/search/selectors';
import { getAllFilesData } from 'ducks/dataSources/selectors';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'v2/components/common';
import { HOME_PAGE_PATH } from 'utils/config';
import { push } from 'connected-react-router';
import { StickyHeader } from 'v2/components/app/StickyHeader/StickyHeader';

const SearchBox: any = styled(Box)`
  @media ${device.tablet} {
    width: 50%;
    max-width: 480px;
  }
  @media ${device.desktop} {
    width: 50%;
    max-width: 480px;
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: unset;
  }
`;

const StyledFlex = styled(Flex)<any>`
  flex-direction: row;
  @media ${device.tablet} {
    padding: 0px;
  }
  @media ${device.mobile} {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
`;

const ButtonContainer = styled(Flex)`
  padding-left: 12px;
  @media ${device.mobile} {
    padding: 0;
    align-items: start;
    width: 100%;
  }
`;

export const FilterLayout = () => {
  const { t } = useTranslation('dataSources');

  const suggestions = useReduxSelector(getDatasourcesSuggestions);
  const [search, setSearch] = useUrlParam('search', '');
  const theme = useContext(ThemeContext);
  const files = useReduxSelector(getAllFilesData);
  const pushAction = useReduxAction(push);

  const searchDataSources = useReduxAction(searchActions.DATA_SOURCES.request);

  const onChange = async (value: string) => {
    searchDataSources(value);
  };

  return (
    <StickyHeader>
      <Box>
        <PageTitle
          title={t('manage-my-data')}
          onBackClick={() => pushAction(HOME_PAGE_PATH)}
        />
        <Flex flexDirection={'row'} mb={'32px'}>
          <Text
            fontSize={14}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {t('dataSources:connect-your-files')}
          </Text>
        </Flex>
        {files && files.length > 0 && (
          <StyledFlex alignItems={'center'}>
            <SearchBox>
              <Search
                data-testid="search-files"
                placeholder={t('dataSources:search-files')}
                onChange={(e) => onChange(e)}
                options={suggestions}
                onSelect={setSearch}
                initialSearch={search}
              />
            </SearchBox>

            <ButtonContainer justifyContent={'flex-end'} ml={'auto'} pl={12}>
              <FilesButtons />
            </ButtonContainer>
          </StyledFlex>
        )}
      </Box>
    </StickyHeader>
  );
};
