import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerInfoType } from 'zsbpsdk/src/customer';

import { Button, Flex, Box, Loader } from 'components';
import { Avatar } from 'v2/components/common';
import { useAppDispatch, useReduxAction, useReduxSelector } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import { getCustomer, getAccountLoading, getUser } from 'ducks/auth/selectors';
import { supportedImageTypes } from 'services/api';
import { handleUploadedImage } from 'utils/imageCompress';
import { openBasicToast, openErrorToast } from 'state/Toast';

import { Subtitle, UploadInput } from './Account.styles';

function hasAvatarImage(customer?: CustomerInfoType) {
  return customer?.avatar && customer?.avatar !== 'null';
}

export const AvatarForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['settings', 'common']);

  const InputUploadRef = useRef<any>(null);

  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const accountIsLoading = useReduxSelector(getAccountLoading);
  const user = useReduxSelector(getUser);

  const updateCustomer = useReduxAction(authActions.UPDATE_CUSTOMER.request);

  const handleUploadInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileObject = e.target.files && e.target.files[0];
    const options = {
      maxWidthOrHeight: 120,
      useWebWorker: true,
    };

    if (!fileObject) return;

    try {
      const avatar = await handleUploadedImage(fileObject, options);
      updateCustomer({ ...customer, avatar });
    } catch (error: any) {
      console.error(error);
      let message = error.message;
      if (!message) {
        message = t('common:errors.corrupted-file');
      }

      dispatch(openErrorToast({ title: message, dismissTime: 0 }));
    } finally {
      InputUploadRef.current.value = null;
    }
  };

  const removeAvatar = () => {
    updateCustomer({ ...customer, avatar: 'null' });
    dispatch(openBasicToast(t('settings:avatar-remove-successful')));
  };

  return (
    <Flex pr={40} pb={40} flexDirection={'column'}>
      <Flex flexDirection={'row'} alignItems={'center'} pb={12}>
        {accountIsLoading ? (
          <Box height="80px">
            <Loader visible={true} />
          </Box>
        ) : (
          <Flex flexDirection={'column'}>
            <Subtitle>{t('common:avatar')}</Subtitle>
            <Flex mt={4} alignItems={'center'}>
              <Avatar
                variant={'big'}
                firstName={user?.firstName ?? customer?.firstName}
                lastName={user?.lastName}
                img={customer?.avatar}
              />

              {!hasAvatarImage(customer) && (
                <Subtitle>{t('common:no-image-uploaded')}</Subtitle>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex>
        <Box mr={8}>
          <UploadInput
            data-testid="upload-input"
            ref={InputUploadRef}
            onChange={handleUploadInputChange}
            accept={supportedImageTypes.toString()}
          />
          <Button
            variant={'outline'}
            onClick={() => InputUploadRef?.current?.click()}
          >
            {t('common:upload-photo')}
          </Button>
        </Box>
        {hasAvatarImage(customer) && (
          <Button
            onClick={() => removeAvatar()}
            variant={'text-primary'}
            data-testid="remove-image-button"
          >
            {t('common:remove-image')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
