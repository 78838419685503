import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Flex, Loader } from 'components';
import { Header } from './Header';
import { ContentWrapWideScreen } from 'components/common/layout/Layout';
import { device } from 'utils/mediaQueries';
import NoFiles from './NoFiles';
import { filteredDataSources, isAddingFile } from 'ducks/dataSources/selectors';
import { useFetchedData, useReduxSelector } from 'hooks';
import { actions as datasourcesActions } from 'ducks/dataSources/actions';
import FilesList from './FilesList';
import { getQuery } from 'ducks/router/selectors';

const ContentWrap = styled(Flex).attrs({
  flexDirection: 'column',
})`
  flex: 1;
  padding-top: 10px;
  max-width: 100%;
`;

const FullWidthScreen = styled(Flex).attrs(({ ...props }: any) => ({
  ...props,
}))`
  background-color: ${({ theme }) => theme.primary.background};
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  min-height: calc(100% - 200px);
  transform: translate(0, ${(props) => props.offset}px);
  transition: transform 0.4s ease-out;
  height: calc(100% - ${(props) => props.offset}px);
  @media ${device.tablet} {
    height: calc(100% - ${(props) => props.offset + 71}px);
  }
`;

const DataSources = () => {
  const { search = '' } = useReduxSelector(getQuery);
  const uploading = useReduxSelector(isAddingFile);
  const [offset, setOffset] = useState(550);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOffset(300);
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  const [filteredDatasources, filteredDatasourcesLoading] = useFetchedData(
    filteredDataSources,
    datasourcesActions.FILTERED.request,
    { search },
  );

  return (
    <FullWidthScreen offset={offset}>
      <ContentWrapWideScreen offset={offset}>
        <Flex>
          <ContentWrap>
            <Header hasData={true} />
            <Flex mt={10}>
              {filteredDatasourcesLoading || uploading ? (
                <Flex
                  justifyContent="center"
                  height="100%"
                  width="100%"
                  alignItems="center"
                >
                  <Loader visible={true} />
                </Flex>
              ) : filteredDatasources && filteredDatasources.length > 0 ? (
                <FilesList
                  files={filteredDatasources}
                  onRowSelect={() => {}}
                  hover={null}
                />
              ) : (
                <NoFiles />
              )}
            </Flex>
          </ContentWrap>
        </Flex>
      </ContentWrapWideScreen>
    </FullWidthScreen>
  );
};

export default DataSources;
