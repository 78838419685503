import { put, takeLatest, select } from 'redux-saga/effects';
import {
  types as workspacesTypes,
  actions as workspacesActions,
} from 'ducks/workspaces/actions';
import { actions as templatesActions } from 'ducks/templates/actions';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { getWorkspaces, updateWorkspace } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import {
  getAllTemplatesLoading,
  getRecentTemplatesLoading,
} from '../ducks/templates/selectors';
import { apiCall } from './utils';
import { getPrinters } from 'services/api';
import { actions as printersActions } from 'ducks/printers/actions';


function* handleWorkspacesRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const workspaces = yield apiCall(getWorkspaces, customer?.id, token);
    const defaultWorkspace = workspaces.find((wp) => wp.isDefault);

    yield put(
      workspacesActions.CURRENT.success({
        id: defaultWorkspace!.id,
      }),
    );
    yield put(workspacesActions.ALL.success(workspaces));
  } catch (err: any) {
    console.error('WORKSPACES', err);
    //TODO show error saying server is broked
    yield put(workspacesActions.ALL.failure({ error: err }));
  }
}

function* handleWorkspaceLeaveRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const { success } = yield apiCall(getWorkspaces, customer?.id, token);
    if (success) {
      yield put(workspacesActions.LEAVE.success({}));
    }
  } catch (err: any) {
    console.error('WORKSPACES', err);
    yield put(workspacesActions.LEAVE.failure({ error: err }));
  }
}

// ******
function* handleEditWorkspace({ payload }: any) {
  const workspaces = yield select(getAllWorkspaces);

  const selectedWorkspace = workspaces.find((w) => w?.id === payload?.id);
  try {
    if (selectedWorkspace) {
      const customer = yield selectAwaitCustomer();
      const { token } = tokenStorage.get();
      const workspace = yield apiCall(
        updateWorkspace,
        payload,
        customer?.id,
        token,
      );
      yield put(workspacesActions.UPDATE.success(workspace));
      // request printers to update workspace name for every printer: SMBUI-2750
      try {
        const printers = yield apiCall(
          getPrinters,
          customer?.id,
          workspace.id,
          token,
        );
        yield put(printersActions.ALL.success(printers));
      } catch (err: any) {
        yield put(printersActions.ALL.failure({ error: err }));
      }

    }
  } catch (err: any) {
    console.error('WORKSPACES', err);
    yield put(workspacesActions.UPDATE.failure({ error: err }));
  }
}

function* handleWorkspaceCurrentSuccess() {
  //when current workspace is loaded, request all dependent structures
  const recentTemplatesLoading = yield select(getRecentTemplatesLoading);
  const allTemplatesLoading = yield select(getAllTemplatesLoading);
  if (!recentTemplatesLoading) {
    yield put(templatesActions.RECENT.request({}));
  }
  if (!allTemplatesLoading) {
    yield put(templatesActions.ALL.request({}));
  }
  // yield put(printersActions.ALL.request({})); this is now done after notifications are initialised
}

export default function* watcher() {
  yield takeLatest(workspacesTypes.ALL.REQUEST, handleWorkspacesRequest);
  yield takeLatest(
    workspacesTypes.CURRENT.SUCCESS,
    handleWorkspaceCurrentSuccess,
  );
  yield takeLatest(workspacesTypes.LEAVE.REQUEST, handleWorkspaceLeaveRequest);
  yield takeLatest(workspacesTypes.UPDATE.REQUEST, handleEditWorkspace);
}
