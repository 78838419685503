import i18n from 'i18next';
import { NotificationProps } from 'components/lib/notification/Notification';
import { Notification } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { PrinterType, PrinterStatusType } from 'zsbpsdk/src/printer';
import { WorkspaceType } from 'zsbpsdk/src/workspace';
import { getAssetPath } from 'utils/files';

export type NotificationMessageType = Partial<NotificationProps> &
  Notification.AsObject;

const transform = (
  notification: Notification.AsObject,
  workspaces: any,
  printers: any,
  printjobs: boolean,
  cartridgelow: boolean,
): NotificationMessageType | null => {
  if (
    notification.subject === Notification.Subject.PRINTER ||
    notification.subject === Notification.Subject.PRINTER_SETTINGS
  ) {
    return handlePrinter(
      notification,
      printers,
      workspaces,
      printjobs,
      cartridgelow,
    );
  }
  return null;
};

const SUCCESS_SVG = getAssetPath('success.svg');
const PRINTER_ICON_SVG = getAssetPath('printer_icon.svg');
const WARNING_OUTLINE_SVG = getAssetPath('warning_outline.svg');

const printNotificationCheckAndTitles = (
  currentPrinterStatus: PrinterStatusType,
  printjobs: boolean,
  currentPrinter?: PrinterType,
  isReprintAction?: boolean,
): { title: string; detailedMessage: string; img: string } => {
  const canPrint =
    currentPrinterStatus?.isOnline &&
    currentPrinterStatus?.isReadyToPrint &&
    !currentPrinterStatus?.isHeadOpen &&
    !currentPrinterStatus?.isPaperOut &&
    !currentPrinterStatus?.isBufferFull &&
    printjobs;

  if (canPrint && isReprintAction) {
    return {
      title: i18n.t('notifications:widget.reprint-request-sent'),
      detailedMessage: i18n.t(
        'notifications:widget.reprint-request-description',
      ),
      img: SUCCESS_SVG,
    };
  } else if (canPrint && !isReprintAction) {
    return {
      title: i18n.t('notifications:widget.print-complete'),
      detailedMessage: i18n.t(
        'notifications:widget.has-completed-the-print-job',
        {
          printer: currentPrinter?.name ?? '',
        },
      ),
      img: SUCCESS_SVG,
    };
  }
  return {
    title: '',
    detailedMessage: '',
    img: PRINTER_ICON_SVG,
  };
};

const handlePrinter = (
  notification: Notification.AsObject,
  printers: PrinterType[] | null = [],
  workspace: WorkspaceType[] | null = [],
  printjobs: boolean,
  cartridgelow: boolean,
): NotificationMessageType | null => {
  let title = '';
  let detailedMessage = '';
  let img = PRINTER_ICON_SVG;

  let payload: any = {};
  try {
    payload = JSON.parse(notification.payload);
  } catch (e: any) {}
  let { method = '', cmd = '', params = {} } = payload;

  const notificationWorkspace: WorkspaceType | undefined = workspace?.find(
    (work) => work.ownerId === notification.customerid,
  );
  const currentPrinter: PrinterType | undefined = printers?.find(
    (printer) => printer.uniqueId === notification.subjectId,
  );
  const currentPrinterStatus = currentPrinter?.status;

  if (Notification.Type.PORTAL_EVENT === notification.type) {
    if (Notification.Action.ONLINE === notification.action) {
      title = i18n.t('notifications:widget.printer-online');
    }
    if (Notification.Action.OFFLINE === notification.action) {
      img = WARNING_OUTLINE_SVG;
      title = i18n.t('notifications:widget.printer-offline');
    }
    if (Notification.Action.UPDATED === notification.action) {
      img = SUCCESS_SVG;
      title = i18n.t('notifications:widget.printer-settings-updated');
    }

    if (Notification.Action.REGISTERED === notification.action) {
      title = i18n.t('notifications:widget.printer-registered');
    }
    if (Notification.Action.UNREGISTERED === notification.action) {
      title = i18n.t('notifications:widget.printer-unregistered');
    }
    if (Notification.Action.DELETED === notification.action) {
      title = i18n.t('notifications:widget.printer-removed');
    }
    detailedMessage = currentPrinter?.name ?? '';
  }
  if (Notification.Type.PRINT_STATUS === notification.type) {
    if (method === 'print_format_status') {
      if (params?.status === 'created' && currentPrinterStatus) {
        const details = printNotificationCheckAndTitles(
          currentPrinterStatus,
          printjobs,
          currentPrinter,
        );

        detailedMessage = details.detailedMessage;
        title = details.title;
        img = details.img;
      }
    }
  }

  if (Notification.Type.RESULTS === notification.type) {
    if (cmd === 'reprint_last_label') {
      if (currentPrinterStatus) {
        const details = printNotificationCheckAndTitles(
          currentPrinterStatus,
          printjobs,
          currentPrinter,
          true,
        );

        detailedMessage = details.detailedMessage;
        title = details.title;
        img = details.img;
      }
    }
  }

  if (Notification.Type.ALERT === notification.type) {
    if (Notification.Action.UNKNOWN_ACTION === notification.action) {
      if (params['name'] === 'head_latch') {
        title = i18n.t(`notifications:widget.printer-cover-${params['state']}`);

        detailedMessage = currentPrinter?.name ?? '';
        img = params.state === 'open' ? WARNING_OUTLINE_SVG : SUCCESS_SVG;
      }
      if (cartridgelow && params['name'] === 'media_state') {
        if (params['state'] === 'out') {
          title = i18n.t('notifications:widget.media-out');
          detailedMessage = i18n.t('notifications:widget.media-out-detailed', {
            printer: currentPrinter?.name,
          });
          img = WARNING_OUTLINE_SVG;
        }
        if (params.state === 'low' && cartridgelow) {
          title = i18n.t('notifications:widget.media-low');
          detailedMessage = i18n.t('notifications:widget.media-low-detailed', {
            printer: currentPrinter?.name,
          });
          img = WARNING_OUTLINE_SVG;
        }
      }
      if (params['name'] === 'head_state') {
        if (params['state'] === 'overtemp') {
          title = i18n.t('notifications:widget.warning');
          detailedMessage = i18n.t('notifications:widget.overtemp', {
            printer: currentPrinter?.name,
          });
          img = WARNING_OUTLINE_SVG;
        }
      }
    }
  }

  if (Notification.Type.FIRMWARE_UPDATE === notification.type) {
    const payloadAction: string = payload?.action || '';
    const payloadStatus: string = payload?.status || '';
    detailedMessage = currentPrinter?.name ?? '';

    if (payloadAction === 'download') {
      if (payloadStatus === 'started') {
        title = i18n.t('notifications:widget.firmware-download-started');
      } else if (payloadStatus === 'completed') {
        title = i18n.t('notifications:widget.firmware-download-completed');
      } else if (payloadStatus === 'error') {
        title = i18n.t('notifications:widget.firmware-download-error');
      }
    }

    if (payloadAction === 'apply') {
      if (payloadStatus === 'started') {
        title = i18n.t('notifications:widget.firmware-apply-started');
      } else if (payloadStatus === 'completed') {
        title = i18n.t('notifications:widget.firmware-apply-completed');
        img = SUCCESS_SVG;
      } else if (payloadStatus === 'error') {
        title = i18n.t('notifications:widget.firmware-apply-error');
      }
    }
  }

  if (Notification.Type.FILE_EVENT === notification.type) {
    const payloadMethod: string = payload?.method || '';
    detailedMessage = currentPrinter?.name ?? '';

    if (payloadMethod === 'file.download_complete') {
      // file.download_complete is exclusive to firmware files
      title = i18n.t('notifications:widget.firmware-download-completed');
      img = SUCCESS_SVG;
    }
  }

  const message = {
    title,
    detailedMessage,
    img,
    footerText: notificationWorkspace?.name ?? '',
    ...notification,
  };

  return message;
};

export default transform;
