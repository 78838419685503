import styled from 'styled-components';
import { Box, Card, Flex, Text } from 'components';

export const Wrap = styled(Card).attrs(({ theme }) => ({
  p: 'unset',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  background: theme.primary.base,
}))`
  width: 184px;
  min-height: 320px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding: ${({ theme }) => `${theme.px(6, 7)}`};
  cursor: pointer;
`;

type TemplateSizePreviewAttributes = {
  selected: boolean;
  labelCornerRadius: number;
  labelWidth: number;
  labelHeight: number;
};

export const TemplateSizePreview = styled(Box)<TemplateSizePreviewAttributes>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary.light : theme.neutral.lightest};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.white : theme.neutral.lighter)};
  border-radius: ${({ labelCornerRadius }) =>
    (labelCornerRadius * 136) / 152000}px;
  max-width: 136px;
  max-height: 136px;
  width: ${({ labelWidth }) => (labelWidth * 136) / 152000}px;
  height: ${({ labelHeight }) => (labelHeight * 136) / 152000}px;
`;

export const LabelTitle = styled(Text).attrs(({ theme, color }) => ({
  fontSize: theme.fontSizes.label,
  lineHeight: '18px',
  letterSpacing: 0.4,
  color: color ? color : theme.textColors.high,
}))`
  width: 150px;
  min-height: 36px;
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
`;

export const PrinterSerialNumber = styled(Text).attrs(({ theme, color }) => ({
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: 0.4,
  textAlign: 'center',
  color: color ? color : theme.textColors.med,
}))`
  width: 150px;
`;

export const CartridgesWrap = styled(Flex).attrs(({ color }) => ({
  color: color ? color : 'inherit',
}))`
  text-align: center;
  line-height: 1.75;
`;
