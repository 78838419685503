import React from 'react';
import IconType from '../icon/IconType';
import {
  MenuRowWrap,
  OptionIcon,
  HighlightedSubStr,
  OptionText,
} from './Suggestion.styles';

interface SuggestionType {
  onClick?: (e: string) => void;
  value: string;
  searchPhrase?: string;
  logo?: IconType;
}
const Suggestion = ({
  searchPhrase = '',
  onClick,
  value,
  logo,
  ...props
}: SuggestionType) => {
  let regex: RegExp = new RegExp('');
  if (!searchPhrase.includes('*')) {
    // Escape \, (), and [] characters
    searchPhrase = searchPhrase.replace(/([\\()[\]])/g, '\\$1');
    regex = new RegExp(`(${searchPhrase})`, 'gi');
  }
  const parts = value.split(regex);
  const handleClick = () => {
    onClick && value && onClick(value);
  };

  return !(searchPhrase?.length >= 1) ? null : (
    <MenuRowWrap {...props} onClick={handleClick} value={value}>
      <React.Fragment>
        {logo && <OptionIcon icon={logo} />}
        {parts.map((part: string, i: number) =>
          regex?.test(part) ? (
            <HighlightedSubStr
              data-testid="highlighted-string"
              as={'span'}
              key={i}
            >
              {part}
            </HighlightedSubStr>
          ) : (
            <OptionText key={i}>{part}</OptionText>
          ),
        )}
      </React.Fragment>
    </MenuRowWrap>
  );
};

export default Suggestion;
