import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ThemeContext } from 'styled-components/macro';
import { Modal, Flex, Text, Box, Button } from 'components';
import Printers from './common/Printers';
import { Line } from './common/Line';
import styled from 'styled-components';
import {
  getFromLocalStorage,
  saveObjToLocalStorage,
} from 'utils/localStorageItemConverter';
import tokenStorage from 'services/tokenStorage';

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.base};
  &:hover {
    cursor: pointer;
  }
`;

const CertificationExpiredModal = ({
  isExpired,
  expiredPrinters,
  handleModalCloseCallback,
}: any) => {
  const { t } = useTranslation(['notifications', 'common']);
  const theme = React.useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const SUPPORT_CERT_EXPIRED_URL =
    'https://zsbsupport.zebra.com/s/article/000026361';
  const SUPPORT_DELETE_PRINTER_URL =
    'https://zsbsupport.zebra.com/s/article/000026409';

  const handleModalClose = useCallback(() => {
    const expiredWarning = getFromLocalStorage('expiredWarning');

    if (expiredWarning !== null) {
      const parsedAboutToExpiredWarning = JSON.parse(expiredWarning);

      if (parsedAboutToExpiredWarning.hasBeenOpened) {
        saveObjToLocalStorage(
          { ...parsedAboutToExpiredWarning, hasBeenOpened: false },
          'expiredWarning',
        );
      }
      setIsOpen(false);
    }

    handleModalCloseCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isExpired) {
      const { token } = tokenStorage.get();
      const expiredWarning = getFromLocalStorage('expiredWarning');
      const dateNow = new Date().getTime();

      if (expiredWarning === null) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'expiredWarning',
        );
        setIsOpen(true);

        return;
      }
      const parsedExpiredWarning = JSON.parse(expiredWarning);

      if (token !== parsedExpiredWarning.lastToken) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'expiredWarning',
        );
        setIsOpen(true);

        return;
      }

      if (dateNow >= parsedExpiredWarning.tomorrowDate) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'expiredWarning',
        );
        setIsOpen(true);
      }
    }
  }, [isExpired]);

  return (
    <Modal
      data-testid="expired-certificates-modal"
      ref={ref}
      small={true}
      hasCloseButton={false}
      clickOutside={false}
      open={isOpen}
      role={'dialog'}
      aria-label="expired certificates modal"
    >
      <Flex flexDirection="column" mt={5}>
        <Box>
          <Text
            textAlign={'center'}
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={'300'}
            letterSpacing={0.4}
          >
            {t('expired-certification.update-printer')}
          </Text>
        </Box>
        <Box mt={8} mb={8}>
          <Trans
            t={t}
            i18nKey={t('expired-certification.notification-text-with-links')}
            components={{
              Link1: (
                <StyledLink
                  href={SUPPORT_DELETE_PRINTER_URL}
                  title={SUPPORT_DELETE_PRINTER_URL}
                  target="_blank"
                  rel="noopener"
                  role={'link'}
                ></StyledLink>
              ),
              Link2: (
                <StyledLink
                  href={SUPPORT_CERT_EXPIRED_URL}
                  title={SUPPORT_CERT_EXPIRED_URL}
                  target="_blank"
                  rel="noopener"
                  role={'link'}
                ></StyledLink>
              ),
            }}
          />
        </Box>
      </Flex>

      <Text
        mt={5}
        fontSize={14}
        fontWeight={'400'}
        lineHeight={'21px'}
        letterSpacing={0.4}
      >
        {t('expired-certification.registered-printers')}
      </Text>

      <Text
        mt={3}
        color={theme.textColors.med}
        fontSize={12}
        fontWeight={'400'}
        letterSpacing={2}
        lineHeight={'16px'}
      >
        {t('expired-certification.printer-name')}
      </Text>

      <Line />
      {expiredPrinters &&
        expiredPrinters.slice(0, 3).map(({ name, avatar }, index) => (
          <Box key={index} data-testid="expired-printer">
            <Printers
              key={index}
              name={name}
              src={avatar}
              alt={name}
              width={'15%'}
              showText={true}
            />
          </Box>
        ))}
      <Flex flexDirection="row" justifyContent={'right'}>
        <Button
          variant={'primary'}
          size={'md'}
          mt={30}
          onClick={handleModalClose}
          data-testid="button-modal-alert-certification-expired"
          aria-label="OK button"
        >
          {t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default CertificationExpiredModal;
