import { put, select, take, takeEvery } from 'redux-saga/effects';
import {
  actions as labelSizesActions,
  types as labelSizesTypes,
} from 'ducks/labelSizes/actions';
import { getAllLabelSizes } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { getAllPrintersLoading } from 'ducks/printers/selectors';
import { types as printersTypes } from '../ducks/printers/actions';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';

function* handleGetAllLabels() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    if (token) {
      const allPrintersLoading = yield select(getAllPrintersLoading);
      if (allPrintersLoading) {
        yield take([printersTypes.ALL.SUCCESS, printersTypes.ALL.FAILURE]);
      }

      const allLabelSizes = yield apiCall(
        getAllLabelSizes,
        customer?.id,
        token,
      );

      const labels = { others: allLabelSizes };

      yield put(labelSizesActions.ALL.success(labels));
    }
  } catch (err: any) {
    yield put(labelSizesActions.ALL.failure({ error: err }));
  }
}

export default function* watcher() {
  yield takeEvery(labelSizesTypes.ALL.REQUEST, handleGetAllLabels);
}
