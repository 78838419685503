import { createSelector } from 'reselect';
import { MS_PROVIDER_LABEL } from 'services/office365';
import {
  DOWNLOAD_DB_MIMETYPES,
  IMAGE_MIMETYPES,
} from '../../components/common/LDAListener';
import { GOOGLE_PROVIDER_LABEL } from '../../utils/file/GoogleDriveFileProvider';

export const allFiles = (state: any) => state.dataSources.all;
export const filteredDataSources = (state: any) => state.dataSources.filtered;
export const filteredDataSourcesIsLoading = (state: any) =>
  state.dataSources.loading;
export const filteredParams = (state: any) => state.dataSources.filtered.params;
export const googleAccount = (state: any) => state.dataSources.googleAccount;
export const googleAccountError = (state: any) =>
  state.dataSources.googleAccount?.error;
export const googleAccountLoading = (state: any) =>
  state.dataSources.googleAccount?.loading;

const allFilesLoading = (state: any) => state.dataSources.all.loading;
const allLocalFiles = (state: any) =>
  state.dataSources.all.data?.filter((file) =>
    file.provider.startWith('Local'),
  );
const allCloudFiles = (state: any) =>
  state.dataSources.all.data?.filter(
    (file) => !file.provider.startWith('Local'),
  );

const allGoogleFiles = (state: any) =>
  state.dataSources.all.data?.filter(
    (file) => file.provider === GOOGLE_PROVIDER_LABEL,
  );

const allOfficeFiles = (state: any) =>
  state.dataSources.all.data?.filter(
    (file) => file.provider === MS_PROVIDER_LABEL,
  );

const allDataFiles = (state: any) =>
  state.dataSources.all.data?.filter(
    (file) => DOWNLOAD_DB_MIMETYPES.indexOf(file.mimetype) > -1,
  );

const allImageFiles = (state: any) =>
  state.dataSources.all.data?.filter(
    (file) => IMAGE_MIMETYPES.indexOf(file.mimetype) > -1,
  );

const addFileLoading = (state: any) => state.dataSources.add?.loading;
const addFileError = (state: any) => state.dataSources.add?.error;
const addFileSuccess = (state: any) => state.dataSources.add?.success;

const loadingRemove = (state: any) => state.dataSources.remove.loading;
const removeSuccess = (state: any) => state.dataSources.remove.success;

const selectedFile = (state: any) => state.dataSources.selected.data;
const isSelectedFileLoading = (state: any) =>
  state.dataSources.selected.loading;

const selectedFileParam = (state: any) =>
  state.dataSources.selected?.params?.id;

export const getAllFiles = createSelector([allFiles], (files) =>
  files ? files : [],
);

export const getAllFilesData = createSelector([allFiles], (files) =>
  files && files.data ? files.data : [],
);

export const getLocalFiles = createSelector([allLocalFiles], (files) =>
  files ? files.filter((file) => file.provider.startWith('Local')) : [],
);

export const getCloudFiles = createSelector([allCloudFiles], (files) => {
  return files ? files.filter((file) => !file.provider.startWith('Local')) : [];
});

export const getGooglesFiles = createSelector([allGoogleFiles], (files) =>
  files ? files : [],
);

export const getOfficeFiles = createSelector([allOfficeFiles], (files) =>
  files ? files : [],
);

export const getDataFiles = createSelector([allDataFiles], (files) =>
  files ? files : [],
);

export const getImageFiles = createSelector([allImageFiles], (files) =>
  files ? files : [],
);

export const getFilteredFiles = createSelector([filteredDataSources], (files) =>
  files ? files : [],
);

export const getFilteredFilesLoading = createSelector(
  [filteredDataSourcesIsLoading],
  (loading) => loading,
);

export const isAllFilesLoading = createSelector(
  [allFilesLoading],
  (loading) => loading,
);

export const isAddingFile = createSelector(
  [addFileLoading],
  (loading) => loading,
);

export const isAddingFileError = createSelector(
  [addFileError],
  (error) => error,
);
export const isAddingFileSuccess = createSelector(
  [addFileSuccess],
  (success) => success,
);

export const getIsLoadingRemove = createSelector(
  [loadingRemove],
  (loading) => loading,
);
export const getIsRemoveSuccess = createSelector(
  [removeSuccess],
  (success) => success,
);

export const getSelectedFile = createSelector(
  [selectedFile],
  (fileId) => fileId,
);
export const isLoadingSelectedDataSource = createSelector(
  [isSelectedFileLoading],
  (err) => err,
);
export const getIsSelectedFileExist = createSelector(
  [selectedFileParam, getAllFilesData],
  (selectedFile, allFiles) => {
    return allFiles.some((el) => el.id === selectedFile);
  },
);
export const getGoogleAccount = createSelector(
  [googleAccount],
  (googleAccount) => googleAccount.data?.email,
);
export const getGoogleAccountError = createSelector(
  [googleAccountError],
  (googleAccountError) => googleAccountError,
);
export const getGoogleAccountLoading = createSelector(
  [googleAccountLoading],
  (googleAccountLoading) => googleAccountLoading,
);
export const selectGoogleAccount = createSelector([googleAccount], (googleAccount) => googleAccount);

export const getFilteredParams = createSelector(
  [filteredParams],
  (parmas) => parmas,
);
