import i18n from 'i18next';

export enum Severity {
  Unknown,
  Critical,
  Error,
  Warning,
  Info,
  Debug,
  Trace,
}

export class CommunicationError extends Error {
  code: any;
  constructor(message, code) {
    super(message);
    this.code = code;
  }
  toJSON() {
    return Object.assign({ message: this.message }, this);
  }
}

export class CustomError extends Error {
  type: string;
  data: any;
  constructor(message, type, data?: any) {
    super(message);
    this.type = type;
    this.data = data;
  }
  toJSON() {
    return Object.assign({ message: this.message }, this);
  }
}

export class LinkedFileMissingError extends Error {
  constructor() {
    super(i18n.t('common:errors.linked-file-missing'));
  }
}

export class PopupClosedByUserError extends Error {
  constructor() {
    super(i18n.t('common:errors.popup-closed-by-user'));
  }
}

export const formatPrintError = (
  error,
  translatedMessage: string = 'The server took too long to respond',
) => {
  if (error.error) {
    error = error.error;
  }

  switch (error?.message) {
    case 'timeout':
      return translatedMessage;
    default:
      return error?.message;
  }
};
