import React from 'react';
import useMethods, { StateAndCallbacksFor } from 'use-methods';

export enum PrintDialogStep {
  Error = -1,
  DataMapping,
  Preview,
  LabelRange,
}

// eslint-disable-next-line no-redeclare
export namespace PrintDialogStep {
  export function before(value: PrintDialogStep): PrintDialogStep {
    return value - 1;
  }
  export function after(value: PrintDialogStep): PrintDialogStep {
    return value + 1;
  }
}

type PrintDialogState = {
  currentStep: PrintDialogStep;
  currentLabel: number; // 1-based index
  copies: number;
  firstRowIsHeader: boolean;
  selectedLabels?: number[];
};

const INITIAL_STATE: PrintDialogState = {
  currentStep: PrintDialogStep.DataMapping,
  currentLabel: 1,
  copies: 1,
  firstRowIsHeader: true,
};

type Context = StateAndCallbacksFor<typeof methods>;
const PrintDialogContext = React.createContext<Context | null>(null);

const methods = (state: PrintDialogState) => ({
  reset() {
    return INITIAL_STATE;
  },
  setCurrentStep(step: PrintDialogStep) {
    state.currentStep = step;
  },
  setCopies(copies: number) {
    state.copies = copies;
    this.limitCurrentLabelToMax();
  },
  setFirstRowIsHeader(firstRowIsHeader: boolean) {
    state.firstRowIsHeader = firstRowIsHeader;
  },
  nextLabel(total: number) {
    if (state.currentLabel < total) {
      state.currentLabel++;
    }
  },
  previousLabel() {
    if (state.currentLabel > 1) {
      state.currentLabel--;
    }
  },
  setSelectedLabels(selection: number[]) {
    state.selectedLabels = selection;
    this.limitCurrentLabelToMax();
  },
  clearSelectedLabels() {
    state.selectedLabels = undefined;
  },
  limitCurrentLabelToMax() {
    const total = (state.selectedLabels?.length || 1) * state.copies;
    if (state.currentLabel > total) {
      state.currentLabel = total;
    }
  },
});

export type PrintDialogProviderProps = {};

export const PrintDialogProvider = ({
  children,
}: React.PropsWithChildren<PrintDialogProviderProps>) => {
  const [reducerState, callbacks] = useMethods(methods, INITIAL_STATE);

  return (
    <PrintDialogContext.Provider
      value={[reducerState, callbacks]}
      children={children}
    />
  );
};

export default PrintDialogProvider;

export const usePrintDialog = (): Context => {
  const context = React.useContext(PrintDialogContext);

  if (context === null) {
    throw new Error('PrintDialogContext failed to initialize');
  }

  return context;
};
