import React from 'react';
import styled from 'styled-components';
import { Toast, Box } from 'components';
import { useAppDispatch, useAppSelector, useTimeout } from 'hooks';
import { selectToastState, close } from 'state/Toast';

const Wrap = styled(Box)<any>`
  position: fixed;
  bottom: 40px;
  transition: left 0.6s ease-out;
  z-index: 1003;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
`;

const SMBToast = () => {
  const { open, title, message, variant, dismissTime, link } =
    useAppSelector(selectToastState);

  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(close());
  };

  useTimeout(() => {
    if (dismissTime) {
      handleClose();
    }
  }, dismissTime);

  return open ? (
    <Wrap>
      <Toast
        open={open}
        title={title ?? ''}
        message={message}
        variant={variant}
        onClose={handleClose}
        link={link}
      />
    </Wrap>
  ) : null;
};

export default SMBToast;
