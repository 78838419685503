import React, { useState, useCallback } from 'react';
import CertificationAboutToExpireModal from './CertificationAboutToExpireModal';
import CertificationExpiredModal from './CertificationExpiredModal';
import { PrinterType } from 'zsbpsdk/src/printer';
import { getFromLocalStorage } from 'utils/localStorageItemConverter';
import tokenStorage from 'services/tokenStorage';
interface ICertificationModalsWrapperProps {
  printersAboutToExpireCertificationOffline: PrinterType[];
  printersExpiredCertificationOffline: PrinterType[];
}

const CertificationModalsContainer = ({
  printersAboutToExpireCertificationOffline,
  printersExpiredCertificationOffline,
}: ICertificationModalsWrapperProps) => {
  const [isAboutToExpireModalClosed, setIsAboutToExpireModalClosed] =
    useState(false);
  const [isExpireModalClosed, setisExpireModalClosed] = useState(false);
  const showExpiredCertModal = printersExpiredCertificationOffline.length > 0;
  const showAboutToExpireCertModal =
    printersAboutToExpireCertificationOffline.length > 0;

  const handleAboutToExpireModalClose = useCallback(() => {
    setIsAboutToExpireModalClosed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAboutToExpireModalClosed]);

  const handleExpiredModalClose = useCallback(() => {
    setisExpireModalClosed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpireModalClosed]);

  // We make this check for a specific case logged in
  // Jira issue: SMBUI-2312
  const showAboutToExpire = () => {
    const { token } = tokenStorage.get();
    const dateNow = new Date().getTime();
    const aboutToExpireWarning = getFromLocalStorage('aboutToExpireWarning');

    if (aboutToExpireWarning === null) {
      return true;
    } else if (aboutToExpireWarning !== null) {
      const parsedAboutToExpiredWarning = JSON.parse(aboutToExpireWarning);
      if (
        token !== parsedAboutToExpiredWarning.lastToken ||
        dateNow >= parsedAboutToExpiredWarning.tomorrowDate
      ) {
        return true;
      }
    } else return false;
  };

  const showExpirationModals = () => {
    if (showAboutToExpireCertModal && showExpiredCertModal) {
      if (!isAboutToExpireModalClosed || showAboutToExpire()) {
        return (
          <CertificationAboutToExpireModal
            isAboutToExpire={showAboutToExpireCertModal}
            printersAboutToExpire={printersAboutToExpireCertificationOffline}
            handleModalCloseCallback={handleAboutToExpireModalClose}
          />
        );
      } else {
        return (
          <CertificationExpiredModal
            isExpired={true}
            expiredPrinters={printersExpiredCertificationOffline}
            handleModalCloseCallback={handleExpiredModalClose}
          />
        );
      }
    } else if (showAboutToExpireCertModal && !showExpiredCertModal) {
      return (
        <CertificationAboutToExpireModal
          isAboutToExpire={showAboutToExpireCertModal}
          printersAboutToExpire={printersAboutToExpireCertificationOffline}
          handleModalCloseCallback={handleAboutToExpireModalClose}
        />
      );
    } else if (!showAboutToExpireCertModal && showExpiredCertModal) {
      return (
        <CertificationExpiredModal
          isExpired={true}
          expiredPrinters={printersExpiredCertificationOffline}
          handleModalCloseCallback={handleExpiredModalClose}
        />
      );
    }
  };

  return <>{showExpirationModals()}</>;
};
export default CertificationModalsContainer;
