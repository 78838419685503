import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components';
import IconType from '../icon/IconType';

export type OptionProps = {
  iconName?: string;
  onClick?: React.MouseEventHandler;
  selected?: boolean;
  value?: string;
  logo?: IconType;
  title?: string;
};

export const MenuRowContent = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-height: 21px;
`;

export const MenuRowWrap = styled.div<{ selected?: boolean; logo?: string }>`
  display: flex;
  width: 100%;
  align-self: flex-start;
  box-sizing: border-box;
  flex: 1;
  z-index: 100;
  opacity: 0.8;
  align-items: center;
  padding: ${({ theme }) => theme.px(6, 9)};

  ${({ logo }) =>
    logo &&
    `
    padding-left: 0px;
  `};

  background-color: ${({ theme, selected }) =>
    selected ? theme.primary.lightest : 'transparent'};
  transition: background-color 0.3s;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary.lightest};
  }
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: ${theme.primary.lightest};
  `};
  font-family: Roboto;
  font-size: ${({ theme }) => theme.fontSizes.label}px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.textColors.high};
`;

export const OptionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.neutral.base,
  size: 20,
}))`
  margin-left: ${({ theme }) => theme.px(9)};
  margin-right: ${({ theme }) => theme.px(9)};
`;
