import { createSelector } from 'reselect';

const printDialog = (state: any) => state.printDialog.dialog;

export const getPrintDialogStatus = createSelector(
  [printDialog],
  ({ open, templateFilename }) => ({
    //TODO add range?
    open,
    templateFilename,
  }),
);
