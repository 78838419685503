const getTextAbbreviation = (text: string) => {
  return text
    .split(' ')
    .slice(0, 3)
    .map((n) => n[0])
    .join('')
    .toUpperCase();
};

export default getTextAbbreviation;
