import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Loader } from 'components';
import { TemplateCardType } from 'components/app/template-card/TemplateCard';
import { Pagination } from 'v2/components/app';
import { actions as templateActions } from 'ducks/templates/actions';
import {
  filteredTemplates as selectFilteredTemplates,
  selectFilteredTemplatesHash,
} from 'ducks/templates/selectors';
import { getUnit as selectUnit } from 'ducks/preferences/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import { TemplateType } from 'zsbpsdk/src/templates';
import { ErrorText, HintText } from 'v2/components/common';
import { LoaderWrapper } from 'v2/components/common/Layout/Layout';
import { useFilteredTemplates } from 'v2/hooks';
import { useMultiSelect } from 'providers/MultipleSelect';

import {
  StyledTemplateCard,
  TemplatesWrap,
} from '../CommonDesignsPage/CommonDesignsPage.styles';
import NoTemplatesLayout from './NoTemplatesLayout';

const TemplatesList = () => {
  const { t } = useTranslation();
  const [{ selected: selectedTemplates }, { select, unselect }] =
    useMultiSelect();

  const getFilteredTemplatesAction = useReduxAction(
    templateActions.FILTERED.request,
  );

  const unit = useReduxSelector(selectUnit);
  const shouldShowNoTemplatesLayout = useSelector(selectFilteredTemplatesHash);

  const {
    filteredTemplatesData: myDesignsFilteredTemplatesData,
    filteredTemplatesDataLoading: myDesignsFilteredTemplatesDataLoading,
    filteredTemplatesError: myDesignsFilteredTemplatesError,
    totalPagesCount,
  } = useFilteredTemplates(selectFilteredTemplates, getFilteredTemplatesAction);

  if (myDesignsFilteredTemplatesError) {
    return <ErrorText>{t('templates:server-error')}</ErrorText>;
  }

  if (shouldShowNoTemplatesLayout) {
    return <NoTemplatesLayout />;
  }

  return (
    <>
      {myDesignsFilteredTemplatesDataLoading ? (
        <LoaderWrapper>
          <Loader visible />
        </LoaderWrapper>
      ) : (
        <Flex data-testid="templates-list" mt={12} flexDirection={'column'}>
          {myDesignsFilteredTemplatesData?.totalCount === 0 && (
            <HintText data-testid="templates-not-found">
              {t('templates:templates-not-found')}
            </HintText>
          )}

          <TemplatesWrap>
            {myDesignsFilteredTemplatesData?.fileDataList.map(
              (template: TemplateType) => (
                <StyledTemplateCard
                  template={template}
                  key={template.id + template.tenant}
                  elevation={'one'}
                  unit={unit}
                  type={TemplateCardType.USER}
                  onCheckboxClick={() =>
                    selectedTemplates.includes(template)
                      ? unselect(template)
                      : select(template)
                  }
                  isBulkSelectActive={selectedTemplates.length > 0}
                  isSelectable={true}
                  isSelected={selectedTemplates.includes(template)}
                />
              ),
            )}
          </TemplatesWrap>
        </Flex>
      )}

      {totalPagesCount > 1 && <Pagination pageCount={totalPagesCount} />}
    </>
  );
};

export default TemplatesList;
