import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Flex, Modal, Text } from 'components';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled(Flex).attrs({
  flexDirection: 'row',
  height: '418px',
  width: '520px',
  px: '24px',
})`
  max-width: 100%;
`;

const StyledImage = styled.img`
  object-fit: contain;
`;

const CameraModal = forwardRef(({ resolve }: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);

  const webcamRef = React.useRef<any>();
  const [deviceId, setDeviceId] = useState('');
  const [devices, setDevices] = useState<any[]>([]);
  const [currentValues, setCurrentValues] = useState<any>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [userPermission, setUserPermission] = useState<boolean>(false);
  const [permissionDenied, setPermissionDenied] = useState<boolean>(false);
  const [img, setImg] = useState<string>();

  const handleOnUserMediaError = () => {
    setHasError(true);
  };

  const handleOnUserMedia = async () => {
    setHasError(false);
    let devs = (await navigator.mediaDevices.enumerateDevices()) ?? [];

    devs = devs.filter(({ kind }) => kind === 'videoinput');
    if (devices.length !== devs.length) {
      setDevices(devs);
    }
  };

  const videoConstraints = {
    deviceId: deviceId,
  };

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(
        mediaDevices.filter(
          ({ kind, label }) => kind === 'videoinput' && label !== '',
        ),
      ),
    [setDevices],
  );

  const capture = useCallback(() => {
    setImg(webcamRef.current.getScreenshot());
    setCurrentValues(webcamRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, ref, resolve]);

  const confirm = () => {
    resolve(img, currentValues);
    setImg(undefined);
    setCurrentValues(undefined);
    ref.current.close();
  };

  useEffect(() => {
    if (devices && devices.length > 0) {
      setDeviceId(devices[0].deviceId);
      setUserPermission(true);
      setPermissionDenied(false);
    } else {
      setUserPermission(false);
    }
  }, [devices]);

  const requestBrowserCameraAccess = useCallback(
    () =>
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then(() => {
          setHasError(false);
          setPermissionDenied(false);
        })
        .catch((err) => {
          if (
            err.name === 'NotAllowedError' &&
            err.message !== 'Permission denied by system'
          ) {
            setPermissionDenied(true);
          } else {
            setPermissionDenied(false);
          }
          setHasError(true);
        }),
    [],
  );

  const getAvailableCameraDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (ref.current.isOpen) {
      hasError ? requestBrowserCameraAccess() : getAvailableCameraDevices();
    }
  }, [
    hasError,
    handleDevices,
    getAvailableCameraDevices,
    ref,
    requestBrowserCameraAccess,
  ]);

  return (
    <Modal
      ref={ref}
      title={t('dataSources:capture-picture')}
      defaultPadding={false}
      hasCloseButton={false}
      footer={
        <Flex height={48} justifyContent={'space-between'}>
          <Flex width="50%">
            {img ? (
              <Button
                variant={'text-primary'}
                onClick={() => setImg(undefined)}
              >
                {t('dataSources:retake')}
              </Button>
            ) : (
              userPermission && (
                <Dropdown
                  key="dropdown"
                  value={deviceId}
                  options={devices?.map((dev) => {
                    return {
                      label: dev.label,
                      value: dev.deviceId,
                    };
                  })}
                  showTop={true}
                  onChange={(e) => {
                    setDeviceId(e);
                  }}
                ></Dropdown>
              )
            )}
          </Flex>

          <Flex width="50%" justifyContent="flex-end">
            <Button
              variant={'text-primary'}
              onClick={() => {
                resolve('');
                setImg(undefined);
                ref.current.close();
              }}
            >
              {t('common:cancel')}
            </Button>
            {img ? (
              <Button
                variant={'primary'}
                size={'lg'}
                onClick={confirm}
                disabled={hasError || !userPermission}
              >
                {t('common:confirm')}
              </Button>
            ) : (
              <Button
                variant={'primary'}
                size={'lg'}
                onClick={() => {
                  capture();
                }}
                disabled={hasError || !userPermission}
              >
                {t('dataSources:capture')}
              </Button>
            )}
          </Flex>
        </Flex>
      }
      clickOutside={false}
    >
      <Container>
        <Flex flex="1" height="100%">
          {img ? (
            <StyledImage src={img} alt={t('dataSources:webcam-capture')} />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={'100%'}
              height={'100%'}
              videoConstraints={videoConstraints}
              onUserMediaError={handleOnUserMediaError}
              onUserMedia={handleOnUserMedia}
            />
          )}
        </Flex>
        {(!userPermission || hasError) && (
          <Flex
            flexDirection="column"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Text textAlign="center">
              {t(
                permissionDenied
                  ? 'dataSources:need-to-allow-camera-access'
                  : 'dataSources:ensure-camera-all',
              )}
            </Text>

            <Button
              variant={'text-primary'}
              onClick={requestBrowserCameraAccess}
            >
              {t('dataSources:try-again')}
            </Button>
          </Flex>
        )}
      </Container>
    </Modal>
  );
});

export default CameraModal;
