import React, { forwardRef, useCallback, useEffect } from 'react';
import { Modal, Flex, Text, Box, Button, Form } from 'components';
import { ThemeContext } from 'styled-components/macro';
import {
  getIsLoadingCancelAll,
  getIsCancelAllSuccess,
} from 'ducks/printers/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';

import { actions as printersActions } from 'ducks/printers/actions';
import { useTranslation } from 'react-i18next';

const CancelPrintJobsModal = forwardRef(({ printer }: any, ref: any) => {
  const theme = React.useContext(ThemeContext);
  const cancelAll = useReduxAction(printersActions.CANCEL_ALL.request);
  const isLoadingCancelAll = useReduxSelector(getIsLoadingCancelAll);
  const isCancelAllSuccess = useReduxSelector(getIsCancelAllSuccess);
  const { t } = useTranslation(['overview', 'common']);

  useEffect(() => {
    if (isCancelAllSuccess && ref) {
      ref.current.close();
    }
  }, [isCancelAllSuccess, ref]);

  const handleSubmit = useCallback(() => {
    cancelAll({ printer });
  }, [cancelAll, printer]);

  return (
    <Modal clickOutside={!isLoadingCancelAll} small ref={ref}>
      <Box mb={8}>
        <Text
          data-testid="clear-print-queue-title"
          fontSize={24}
          fontWeight={300}
          color={theme.error.base}
        >
          {t('overview:printer.clear-print-queue')}
        </Text>
      </Box>
      <Text fontSize={16} fontWeight={'normal'} lineHeight={'24px'}>
        {t('overview:printer.clear-print-queue-description')}
      </Text>
      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="printer-cancel-button"
          onClick={() => ref.current.close()}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>
        <Form onSubmit={handleSubmit}>
          <Button
            data-testid="printer-delete-button"
            variant={'destructive'}
            isLoading={isLoadingCancelAll}
            type={'submit'}
          >
            {isLoadingCancelAll
              ? t('common:clearing')
              : t('common:clear-queue')}
          </Button>
        </Form>
      </Flex>
    </Modal>
  );
});

export default CancelPrintJobsModal;
