import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';

import { useReduxAction, useReduxSelector } from 'hooks';
import { Modal, Flex, Text, Box, Button } from 'components';
import { selectOutageDialog } from 'ducks/modalDialog/selectors';
import { actions as modalDialogActions } from 'ducks/modalDialog/actions';

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.base};
  &:hover {
    cursor: pointer;
  }
`;

export default function OutageDialog() {
  const { t } = useTranslation('dialogs');
  const ref = useRef(null);
  const outageDialog = useReduxSelector(selectOutageDialog);
  const closeDialog = useReduxAction(modalDialogActions.OUTAGE.close);

  return (
    <Modal
      data-testid="outage-advisory-dialog"
      ref={ref}
      title={t('dialogs:outage.title')}
      open={outageDialog.open}
      isAnimatedBackdrop={false}
      hasCloseButton={false}
      zIndex={1002}
      small
      role={'dialog'}
      aria-label={t('dialogs:outage.title')}
    >
      <Text fontSize={24} fontWeight={300} lineHeight={'28px'}>
        {t('dialogs:outage.title')}
      </Text>

      <Trans
        i18nKey={t('dialogs:outage.content')}
        components={{
          p: <Box mt={8} />,
          a: (
            <StyledLink
              href={'https://zsbsupport.zebra.com/s/contactsupport'}
              target="_blank"
              rel="noopener"
              role={'link'}
            />
          ),
        }}
      />

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="outage-advisory-dialog-ok-button"
          variant={'primary'}
          onClick={() => closeDialog()}
        >
          {t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
}
