import {
  call,
  delay,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  actions as templatesActions,
  types as templatesTypes,
  FilteredTemplatesRequest,
  ZebraFilteredTemplatesRequest,
  PrintPayload,
} from 'ducks/templates/actions';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { types as workspacesTypes } from 'ducks/workspaces/actions';
import {
  deleteDocument,
  duplicateTemplate,
  getFilteredCategoryTemplates,
  getFilteredTemplates,
  getMyDesignAllSizes,
  getPrintPreview,
  getRecentTemplates,
  getTemplate,
  getTemplateDataDetails as _getTemplateDataDetails,
  getTemplates,
  getThumbnail,
  getUniqueFileName,
  print,
  renameTemplate,
  updateTemplateMetadata,
  deleteManyDocuments,
  getSystemTemplates,
} from 'services/api';
import {
  openBasicToast,
  openErrorToast,
  openToast,
  openWarningToast,
} from 'state/Toast';
import {
  openDialog,
  closeDialog,
  cancelClicked,
  confirmClicked,
} from 'state/Dialog';
import { currentTemplate } from 'utils/demo/templates';
import { getCurrentWorkspaceId } from 'ducks/workspaces/selectors';
import {
  getAllTemplates as allTemplatesSelector,
  getAllZebraTemplates,
  getEnteredData,
  getFilteredTemplatesParams,
} from 'ducks/templates/selectors';
import tokenStorage from 'services/tokenStorage';
import { getAllPreferences } from 'ducks/preferences/selectors';
import { formatCurrentTime, formatDate } from 'utils/dateFormatter';
import { push } from 'connected-react-router';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates/index';
import * as H from 'history';
import {
  COMMON_DESIGNS_PATH,
  GRPC_CODES,
  HOME_PAGE_PATH,
} from '../utils/config';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { DataSourceType } from '../utils/dataSourcesTypes';
import {
  getTemplateCategory,
  parseInitialValue,
  UNMODIFIABLE_DATA_FIELDS,
} from '../utils/templates';
import { PayloadAction } from '../ducks/utils';
import { TEMPLATE_ID_QUERY_PARAM } from '../pages/templates/components/TemplateDialogModal';
import { TemplateCategory, LabelStock } from '../utils/categories';
import i18n from 'i18n';
import {
  getPrintSuccessMessage,
  getPrintSuccessToastVariant,
  getCartridgeInfoSize,
  isTemplateWithPrinterCartridgeSize,
} from 'utils/printers';

import { apiCall } from './utils';
import { PrinterType } from 'zsbpsdk/src/printer';
import { handlePrintersRequest } from './printers';
import { getAllPrinters } from 'ducks/printers/selectors';

const DBG = ['%c Template Saga ', 'color:white;background:black'];
const MAX_PAGE_SIZE = 100;

export function* getTenantId() {
  const x = yield select(getCurrentWorkspaceId);
  if (x) {
    return x;
  }
  yield take(workspacesTypes.ALL.SUCCESS);
  return yield select(getCurrentWorkspaceId);
}

function* handleGetFilteredZebraTemplates({
  payload,
}: PayloadAction<ZebraFilteredTemplatesRequest>) {
  const { selectedCategory, search } = payload;

  console.debug(...DBG, 'handleGetFilteredZebraTemplates');

  try {
    if (!selectedCategory && !search) {
      console.debug(...DBG, 'All templates, no filters applied');
      yield put(templatesActions.ZEBRA_FILTERED.clear());

      return;
    }

    //TODO: Doesn't it make more sense to do the serach / sort locally?
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { fileDataList, totalCount }: TemplatePage = yield apiCall(
      getFilteredCategoryTemplates,
      payload,
      customer?.id,
      workspaceId,
      token,
    );

    const filteredFileDataList: TemplateType[] = fileDataList.filter(
      (t: TemplateType) => !t.name.includes('test'),
    );
    const templatePage: TemplatePage = {
      fileDataList: filteredFileDataList,
      totalCount: totalCount,
    };

    console.debug(...DBG, 'handleGetFilteredZebraTemplates', templatePage);
    yield put(templatesActions.ZEBRA_FILTERED.success(templatePage));
  } catch (err: any) {
    console.error('handleGetFilteredZebraTemplates', 'ERROR');
    yield put(templatesActions.ZEBRA_FILTERED.failure({ error: err }));
  }
}

function* handleGetFilteredZebraTemplatesAllSizes({ payload }: any) {
  console.debug(...DBG, 'handleGetFilteredZebraTemplatesAllSizes');
  try {
    if (payload.selectedCategory) {
      const { token } = tokenStorage.get();
      const customer = yield selectAwaitCustomer();
      const workspaceId = yield getTenantId();
      const allTemplates: TemplatePage = yield apiCall(
        getFilteredCategoryTemplates,
        { selectedCategory: payload.selectedCategory, pageSize: MAX_PAGE_SIZE },
        customer?.id,
        workspaceId,
        token,
      );
      const sizes = allTemplates.fileDataList.reduce(
        (acc: any, { labelSize }: any) => {
          acc[`${labelSize.width}x${labelSize.height}`] = labelSize;
          return acc;
        },
        {},
      );
      return yield put(
        templatesActions.ZEBRA_FILTERED_ALL_SIZES.success({
          selectedCategory: allTemplates.fileDataList[0].category,
          sizes,
        }),
      );
    }
    yield put(templatesActions.ZEBRA_FILTERED_ALL_SIZES.clear());
  } catch (error) {
    console.error('handleGetFilteredZebraTemplatesAllSizes', 'ERROR');
    yield put(templatesActions.ZEBRA_FILTERED.failure({ error }));
  }
}

function* getAllTemplates() {
  console.debug(...DBG, 'getAllTemplates');
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    let allTemplates: TemplatePage = yield apiCall(
      getTemplates,
      customer?.id,
      workspaceId,
      token,
    );
    //map over to remove ZEBRA_INTERAL
    yield put(templatesActions.ALL.success(allTemplates));
  } catch (err: any) {
    yield put(templatesActions.ALL.failure({ error: err }));
  }
}

function* getTemplateDialogDetails({ payload: templateId }: any) {
  console.debug(...DBG, 'getTemplateDialogDetails for', templateId);

  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();

    const template: TemplateType = yield apiCall(
      getTemplate,
      customer?.id,
      workspaceId,
      token,
      templateId,
    );

    yield put(templatesActions.THUMBNAIL.request(template));
    console.debug(
      ...DBG,
      'getTemplateDialogDetails returns fetched template',
      template,
    );
    yield put(templatesActions.TEMPLATE_DIALOG.success(template));
  } catch (err) {
    console.debug(...DBG, 'getTemplateDialogDetails failed');
    yield put(templatesActions.TEMPLATE_DIALOG.failure({ error: err }));
  }
}

function* getTemplateDataDetails({ payload }: any) {
  //BENKEN: This is the beginning of the print dialog
  console.debug(...DBG, 'getTemplateDataDetails in', payload);
  const {
    template: { id },
  } = payload;
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    let templateDetails = yield apiCall(
      _getTemplateDataDetails,
      id,
      customer?.id,
      workspaceId,
      token,
    );

    console.debug(...DBG, 'getTemplateDataDetails extra', templateDetails);

    templateDetails.dataSources.forEach((detail, key) => {
      if (detail.dataSourceType === DataSourceType.ClientDate) {
        templateDetails.dataSources[key].initialValue =
          detail.initialValue.replace(/\./g, '');
      }
    });
    const template: TemplateType = yield apiCall(
      getTemplate,
      customer?.id,
      workspaceId,
      token,
      id,
    );

    yield put(templatesActions.UPDATE.success({ template }));
    yield put(templatesActions.SELECTED.set({ ...template }));

    let oldOrder = templateDetails.dataSources.slice(0) as any[];
    let newOrder = [] as any[];

    template.fieldOrder.forEach((e) => {
      for (let i = 0; i < oldOrder.length; i++) {
        if (oldOrder[i].name === e) {
          if (!UNMODIFIABLE_DATA_FIELDS.includes(oldOrder[i].dataSourceType)) {
            newOrder.push(oldOrder[i]);
            oldOrder.splice(i, 1);
            break;
          }
        }
      }
    });
    newOrder.push(...oldOrder);
    templateDetails.dataSources = newOrder;

    try {
      if (templateDetails.databases?.length > 0) {
        console.debug(
          ...DBG,
          'getTemplateDataDetails extra',
          'fetching dataSources',
        );
        yield put(
          dataSourcesActions.SELECTED.request({
            id: templateDetails.databases[0].externalId,
            provider: templateDetails.databases[0].provider,
            name: templateDetails.databases[0].fileName,
            fileType: templateDetails.databases[0].fileType,
          }),
        ); //TODO what if no database
        console.debug(
          ...DBG,
          'getTemplateDataDetails extra',
          'dataSources requested',
        );
      } else {
        console.debug(...DBG, 'getTemplateDataDetails extra', 'no dataSources');
        yield put(dataSourcesActions.SELECTED.clear()); //TODO what if no database
      }
    } catch (err: any) {
      console.debug(
        ...DBG,
        "getTemplateDataDetails failed, couldn't load datasource",
      );
      yield put(dataSourcesActions.SELECTED.failure({ error: err }));
    } finally {
      yield put(templatesActions.SELECTED_DATA.success(templateDetails));
    }
  } catch (err: any) {
    console.debug(...DBG, 'getTemplateDataDetails failed');
    yield put(templatesActions.SELECTED_DATA.failure({ error: err }));
  }
}

function* handleGetFilteredTemplates({
  payload,
}: PayloadAction<FilteredTemplatesRequest>) {
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const allTemplates = yield select(allTemplatesSelector);
    //NOTE: Previously, this also called all templates - why?
    const templatePage: TemplatePage = yield apiCall(
      getFilteredTemplates,
      payload,
      customer?.id,
      workspaceId,
      token,
    );
    if (templatePage) {
      yield put(templatesActions.FILTERED.success(templatePage));
      if (!allTemplates.length)
        yield put(templatesActions.ALL.success(templatePage));
    }
  } catch (err: any) {
    yield put(templatesActions.FILTERED.failure({ error: err }));
  }
}

function* handleGetMyDesignAllSizes() {
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const allSizes = yield apiCall(
      getMyDesignAllSizes,
      customer.id,
      workspaceId,
      token,
    );

    yield put(templatesActions.MY_DESIGN_ALL_SIZES.success(allSizes));
  } catch (err) {
    yield put(templatesActions.MY_DESIGN_ALL_SIZES.failure({ error: err }));
  }
}

function* getCurrentTemplate() {
  console.debug(...DBG, 'getCurrentTemplate');
  try {
    yield put(templatesActions.CURRENT.success({ currentTemplate }));
  } catch (err: any) {
    yield put(templatesActions.CURRENT.failure({ error: err }));
  }
}

function* handleGetRecentTemplates({ payload }: any) {
  console.debug(...DBG, 'handleGetRecentTemplates');
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const recentTemplates: TemplatePage = yield apiCall(
      getRecentTemplates,
      customer?.id,
      workspaceId,
      token,
    );

    yield put(templatesActions.RECENT.success(recentTemplates));
  } catch (err: any) {
    yield put(templatesActions.RECENT.failure({ error: err }));
  }
}

// Update template name and/or metadata; this method can update one without updating the other
function* handleUpdateTemplateRequest({
  payload,
}: PayloadAction<{
  template: TemplateType;
  name: string;
  metadata: any;
  location: Location;
}>) {
  const { template, name, metadata, location } = payload;

  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { token } = tokenStorage.get();

    if (name) {
      if (name.trim().length === 0) {
        throw new Error(
          i18n.t('templates:template-not-renamed', { name: template.name }),
        );
      } else if (name.length > 250) {
        throw new Error(
          i18n.t('templates:maximum-allowed-length-is-250-characters-error'),
        );
      }

      const uniqueName = yield apiCall(
        getUniqueFileName,
        `${name}.nlbl`,
        customer?.id,
        workspaceId,
        token,
      );
      const newFilename = yield apiCall(
        renameTemplate,
        template,
        uniqueName,
        customer?.id,
        workspaceId,
        token,
      );

      if (!newFilename) {
        throw new Error(
          i18n.t('templates:template-not-renamed', { name: template.name }),
        );
      }

      const displayName = newFilename.substring(
        0,
        newFilename.lastIndexOf('.'),
      );
      yield put(
        templatesActions.UPDATE.success({
          ...template,
          name: displayName,
          filename: newFilename,
          oldFilename: template.filename,
        }),
      );
      yield put(
        openBasicToast(
          i18n.t('templates:template-renamed', {
            oldName: template.name,
            newName: displayName,
          }),
        ),
      );
    }

    if (metadata) {
      const updatedTemplate = yield apiCall(
        updateTemplateMetadata,
        template,
        metadata,
        customer?.id,
        workspaceId,
        token,
      );

      if (!updatedTemplate) {
        throw new Error();
      }

      //TODO: check if lastPrint value is kept?
      yield put(
        templatesActions.UPDATE.success({
          ...updatedTemplate,
          smallThumbnail: template.smallThumbnail,
        }),
      );
    }

    yield refreshDisplayedTemplates(location?.pathname);
  } catch (err: any) {
    console.error(err);

    yield put(openErrorToast(err.message));
    yield put(templatesActions.UPDATE.failure({ error: err }));
  }
}

function* refreshDisplayedTemplates(pathname?: H.Pathname) {
  const params = yield select(getFilteredTemplatesParams);
  yield spawn(
    handleGetFilteredTemplates,
    templatesActions.FILTERED.request(params),
  );

  if (pathname && pathname === HOME_PAGE_PATH) {
    yield put(templatesActions.RECENT.request({}));
  }
}

function* handleDeleteManyTemplatesRequest({ payload }: any) {
  yield put(
    openDialog({
      type: 'Confirmation',
      props: {
        title: i18n.t('overview:designs.delete-design'),
        content: i18n.t('overview:designs.delete-design-description'),
        confirmText: i18n.t('common:delete'),
      },
    }),
  );

  const action = yield take([cancelClicked.type, confirmClicked.type]);

  yield put(closeDialog());

  if (action.type === cancelClicked.type) {
    return;
  }

  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { token } = tokenStorage.get();
    const {
      templates,
      location,
    }: { templates: TemplateType[]; location: Location } = payload;

    const {
      deletedCount,
      successfulTemplates,
    }: { deletedCount: number; successfulTemplates: TemplateType[] } =
      yield apiCall(
        deleteManyDocuments,
        customer,
        workspaceId,
        token,
        templates,
      );

    if (deletedCount === templates.length) {
      yield put(
        templatesActions.REMOVE_MULTIPLE.success([...successfulTemplates]),
      );
      yield refreshDisplayedTemplates(location.pathname);

      yield put(
        openBasicToast(
          templates.length > 1
            ? i18n.t('templates:template-deleted_plural', {
                count: deletedCount,
              })
            : i18n.t('templates:template-deleted', {
                name: successfulTemplates[0].name,
              }),
        ),
      );

      yield put(templatesActions.MY_DESIGN_ALL_SIZES.request({}));
    } else if (deletedCount !== 0) {
      yield put(
        templatesActions.REMOVE_MULTIPLE.success([...successfulTemplates]),
      );
      yield refreshDisplayedTemplates(location.pathname);

      const error = new Error(
        i18n.t('templates:error-template-not-deleted_plural', {
          deleted: deletedCount,
          total: templates.length,
        }),
      );

      yield put(openWarningToast(error.message));
      yield put(templatesActions.REMOVE_MULTIPLE.failure({ error }));
    } else {
      yield refreshDisplayedTemplates(location.pathname);

      throw new Error(
        templates.length > 1
          ? i18n.t('templates:no-labels-deleted', { count: templates.length })
          : i18n.t('templates:error-template-not-deleted', {
              name: templates[0].name,
            }),
      );
    }
  } catch (err: any) {
    yield put(openErrorToast(err.message));
    yield put(templatesActions.REMOVE_MULTIPLE.failure({ error: err }));
  }
}

function* handleDeleteTemplateRequest({ payload }: any) {
  console.debug(...DBG, 'handleDeleteTemplateRequest');
  const {
    template: { id },
    location,
  } = payload;
  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { token } = tokenStorage.get();
    const success = yield apiCall(
      deleteDocument,
      id,
      customer?.id,
      workspaceId,
      token,
    );

    if (success) {
      yield put(templatesActions.REMOVE.success({ ...payload.template }));
      yield refreshDisplayedTemplates(location.pathname);
      yield put(
        openBasicToast(
          `Design ${payload.template.name} has been successfully removed`,
        ),
      );

      yield put(templatesActions.MY_DESIGN_ALL_SIZES.request({}));
    } else {
      throw new Error();
    }
  } catch (err: any) {
    yield put(
      openErrorToast(`Design ${payload.template.name} was not deleted`),
    );
    yield put(templatesActions.REMOVE.failure({ error: err }));
  }
}

function* handleRetry(printer: PrinterType) {
  for (let i = 0; i < 3; i++) {
    try {
      yield spawn(handlePrintersRequest);
      const printers = yield select(getAllPrinters);
      const selectedPrinter: PrinterType = printers.find(
        (p) => p.id === printer.id,
      );

      const cartridgeSize = getCartridgeInfoSize(selectedPrinter);

      if (cartridgeSize.width === 0 || cartridgeSize.height === 0) {
        throw new Error();
      }

      break;
    } catch (err: any) {
      yield delay(10000);
    }
  }

  yield put(
    openErrorToast(
      i18n.t('notifications:widget.media-out-detailed', {
        printer: printer.name,
      }),
    ),
  );
}

function* handleTestPrintTemplate({ payload }: PayloadAction<PrintPayload>) {
  console.debug(...DBG, 'handleTestPrintTemplate');
  const {
    printer,
    fields,
    copies,
    labelRange,
    labelRangeMapping,
    selectedLabels,
  } = payload;

  const cartridgeSize = getCartridgeInfoSize(printer);

  if (cartridgeSize.width === 0 || cartridgeSize.height === 0) {
    yield put(
      openBasicToast({
        title: i18n.t('templates:submitting-print-job'),
        dismissTime: 0,
      }),
    );

    yield* handleRetry(printer);

    return;
  }

  const { token } = tokenStorage.get();
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();

  let template: TemplateType;
  try {
    const systemTemplates = yield apiCall(
      getSystemTemplates,
      customer?.id,
      workspaceId,
      token,
    );

    template = systemTemplates.fileDataList.find(
      (systemTemplate: TemplateType) =>
        systemTemplate.labelSize &&
        isTemplateWithPrinterCartridgeSize(
          systemTemplate,
          cartridgeSize.height,
          cartridgeSize.width,
        ),
    );
  } catch (e) {
    yield put(
      openErrorToast(i18n.t('notifications:widget.test-template-not-found')),
    );
    return;
  }

  let formattedFields = yield call(
    formatFields,
    fields,
    labelRange,
    labelRangeMapping,
    selectedLabels,
  );

  try {
    const preferences = yield select(getAllPreferences);
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const jobId = yield apiCall(
      print,
      template.filename,
      formattedFields,
      printer,
      copies,
      printer.darkness,
      printer.cartridgeInfo.defaultdarkness,
      preferences.dithering,
      customer?.id,
      token,
      getTemplateCategory(template.tenant, template.category as LabelStock),
    );
    yield put(templatesActions.TEST_PRINT.success({ jobId }));
    yield put(templatesActions.RECENT.request({}));

    yield put(
      openToast({
        variant: getPrintSuccessToastVariant(printer.status),
        title: getPrintSuccessMessage(printer.status),
      }),
    );
  } catch (err: any) {
    yield put(templatesActions.TEST_PRINT.failure({ error: err }));
  }
}

function* handlePrintTemplate({ payload }: PayloadAction<PrintPayload>) {
  console.debug(...DBG, 'handlePrintTemplate');
  const {
    printer,
    template,
    fields,
    labelRange,
    labelRangeMapping,
    selectedLabels,
    copies,
  } = payload;

  let formattedFields = yield call(
    formatFields,
    fields,
    labelRange,
    labelRangeMapping,
    selectedLabels,
  );
  try {
    const preferences = yield select(getAllPreferences);
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const jobId = yield apiCall(
      print,
      template?.filename,
      formattedFields,
      printer,
      copies,
      printer.darkness,
      printer.cartridgeInfo.defaultdarkness,
      preferences.dithering,
      customer?.id,
      token,
      getTemplateCategory(template.tenant, template.category),
    );
    const templateName = template.name;
    yield put(templatesActions.PRINT.success({ jobId, templateName }));
    yield put(templatesActions.TEMPLATE_DIALOG.request(template?.id));
    yield put(templatesActions.RECENT.request({}));
  } catch (err: any) {
    console.debug(
      ...DBG,
      `print failed on printer: ${printer?.model}, ${printer?.uniqueId}, Dk:${printer?.darkness}, CarDk:${printer?.cartridgeInfo?.defaultdarkness}`,
      err,
    );
    yield put(templatesActions.PRINT.failure({ error: err }));
  }
}

function* formatFields(
  fields: any,
  labelRange,
  labelRangeMapping,
  selectedLabels: number[] = [],
) {
  const enteredData = yield select(getEnteredData);

  if (selectedLabels.length === 0) {
    // Manual input
    return [
      fields.reduce((acc, field) => {
        acc[field.name] = formatInvisibleDataSources(field);
        return acc;
      }, {}),
    ];
  }

  return selectedLabels.map((labelIndex, index) =>
    fields
      .map((field) => {
        if (index > 0 && field.dataSourceType === DataSourceType.Counter) {
          return {}; // Let LDA handle counter value
        }

        let fieldValue = null;
        try {
          // If the data is coming from a database
          const fieldColumnIndex = labelRangeMapping?.[field.name];
          fieldValue = labelRange[labelIndex][fieldColumnIndex];
        } catch (e: any) {
          console.error(e);
        }

        if (!fieldValue) {
          const data = enteredData.data[labelIndex][field.name];
          if (data !== undefined) {
            // Data was filled at print time
            fieldValue =
              field.dataSourceType === DataSourceType.Picture
                ? data.data
                : data;
          } else {
            // No changes made by user at print time
            fieldValue =
              field.dataSourceType === DataSourceType.Counter
                ? field.initialValue
                : field.defaultValue;
          }
        }

        return {
          [field.name]: formatInvisibleDataSources(
            {
              ...field,
              initialValue: fieldValue,
            },
            index,
          ),
        };
      })
      .reduce((accum, val) => {
        Object.assign(accum, val);
        return accum;
      }, {}),
  );
}

function* handleLabelPreview({ payload }: any) {
  console.debug(...DBG, 'handleLabelPreview');
  const { template, fields, currentLabel } = payload;

  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { token } = tokenStorage.get();

    const templateDetails = yield apiCall(
      _getTemplateDataDetails,
      template.id,
      customer?.id,
      workspaceId,
      token,
    );

    for (const dataSource of templateDetails.dataSources) {
      const { name, dataSourceType, initialValue } = dataSource;
      if (dataSourceType === DataSourceType.Counter) {
        for (const field of fields) {
          if (field.name === name) {
            field.initialValue = initialValue;
            break;
          }
        }
      }
    }

    const nameValueMap = formatFieldsForPrinting(fields, currentLabel);

    const preview = yield apiCall(
      getPrintPreview,
      template.filename,
      [nameValueMap],
      customer?.id,
      workspaceId,
      token,
    );
    yield put(templatesActions.LABEL_PREVIEW.success(preview));
  } catch (_error) {
    const error: any = _error;
    let errorPayload;
    if (error instanceof SyntaxError) {
      errorPayload = { type: 'Syntax', message: error };
    } else if (error === 'printPreview exception: timeout') {
      errorPayload = { type: 'Server', message: error };
      yield put(openErrorToast('Print Preview server failed to respond'));
    } else if (error?.code === 3) {
      const message = JSON.parse(error.message);
      errorPayload = {
        ...error,
        ...message,
        type: 'gRPC',
      };
    } else if (error?.message) {
      errorPayload = { type: 'Unknown', message: error.message };
    } else {
      errorPayload = { type: 'Unknown', message: error };
    }
    console.error(error);
    yield put(
      templatesActions.LABEL_PREVIEW.failure({
        ...errorPayload,
        error: error && { ...error, code: error.code ?? GRPC_CODES.UNKNOWN },
      }),
    );
  }
}

const formatFieldsForPrinting = (fields, currentLabel) => {
  let d = {};
  fields.forEach((e) => {
    d[e.name] = formatInvisibleDataSources(e, currentLabel - 1);
  });
  return d;
};

const getLocaleForGroupSeparator = (groupSeparator) => {
  switch (groupSeparator) {
    case '.':
      return 'de-DE';
    case ' ':
      return 'fr-FR';
    case ',':
    default:
      return 'en-GB';
  }
};

export const formatInvisibleDataSources = (
  {
    initialValue,
    dataSourceType,
    currencyInputFormatInfo,
    counterStep,
    counterType,
    dateInputFormat,
    timeInputFormat,
  },
  index = 0,
) => {
  if (initialValue === null || initialValue === '') {
    return '';
  }

  switch (dataSourceType) {
    case DataSourceType.Currency:
      const locale = getLocaleForGroupSeparator(
        currencyInputFormatInfo?.groupSeparator,
      );
      let rawString = parseFloat(initialValue).toLocaleString(locale, {
        minimumFractionDigits: currencyInputFormatInfo?.decimalDigits,
        maximumFractionDigits: currencyInputFormatInfo?.decimalDigits,
      });
      return `${currencyInputFormatInfo?.currencySymbol}${rawString}`;

    case DataSourceType.Counter:
      return Number(
        parseFloat(initialValue) +
          counterStep * index * (counterType === 'Increment' ? 1 : -1),
      ).toString();

    case DataSourceType.ClientDate:
      return formatDate(dateInputFormat, new Date());

    case DataSourceType.ClientTime:
      return formatCurrentTime(timeInputFormat);

    default:
      return parseInitialValue(initialValue);
  }
};

export const TEMPLATE_FILENAME_QUERY_PARAM = 'templateFilename';

function* handleDuplicateTemplate({
  payload,
}: PayloadAction<{
  template: TemplateType;
  location: Location;
  name: string;
  action: string;
  onClick: (id: string) => void;
}>) {
  console.debug(...DBG, 'handleDuplicateTemplate');
  try {
    const { template, name, location } = payload;
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const { token } = tokenStorage.get();

    if (name.length > 250) {
      throw new Error(
        i18n.t('templates:maximum-allowed-length-is-250-characters-error'),
      );
    }

    const uniqueName = yield apiCall(
      getUniqueFileName,
      `${name}.nlbl`,
      customer?.id,
      workspaceId,
      token,
    );

    const newTemplate = yield apiCall(
      duplicateTemplate,
      template,
      uniqueName.substring(0, uniqueName.lastIndexOf('.')),
      customer?.id,
      workspaceId,
      token,
    );
    yield put(templatesActions.DUPLICATE.success(newTemplate));
    if (payload?.action === 'edit') {
      yield put(
        push(
          `/designer?sid=${Date.now()}&template=${encodeURIComponent(
            newTemplate.id,
          )}&lastUrl=${encodeURIComponent(window.location.pathname)}`,
        ),
      );
      yield put(
        openBasicToast(
          `${newTemplate.name} has been successfully duplicated to My Designs.`,
        ),
      );
    } else if (
      location.pathname === COMMON_DESIGNS_PATH ||
      location.pathname === HOME_PAGE_PATH
    ) {
      yield put(
        openBasicToast({
          title: `${newTemplate.name} has been successfully duplicated to My Designs.`,
          link: {
            name: 'View',
            onClick: () => payload?.onClick(newTemplate.id),
          },
        }),
      );
      yield refreshDisplayedTemplates(location.pathname);
    } else {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(TEMPLATE_ID_QUERY_PARAM);
      location.search = queryParams.toString();

      yield put(push(location));
      yield refreshDisplayedTemplates(location.pathname);
      yield put(openBasicToast(`Design has been successfully duplicated.`));
    }
  } catch (_error) {
    const error: any = _error;
    yield put(templatesActions.DUPLICATE.failure({ error }));
    yield put(openErrorToast(error.message));
  }
}

function* handleGetThumbnail({ payload }: any) {
  const template: TemplateType = payload;

  try {
    if (!template.largeThumbnail) {
      const customer = yield selectAwaitCustomer();
      const allTemplates: TemplateType[] = yield select(
        template.tenant === TemplateCategory.Internal
          ? getAllZebraTemplates
          : allTemplatesSelector,
      );
      const existingTemplate = allTemplates.find((t) => t.id === template.id);
      if (existingTemplate?.largeThumbnail) {
        template.largeThumbnail = existingTemplate.largeThumbnail;
      } else {
        const { token } = tokenStorage.get();
        template.largeThumbnail = yield apiCall(
          getThumbnail,
          template.filename,
          template.tenant,
          customer?.id,
          token,
          276,
        );
      }
    }
    yield put(templatesActions.THUMBNAIL.success(template));
    yield refreshDisplayedTemplates();
  } catch (_error) {
    const error: any = _error;
    yield put(templatesActions.THUMBNAIL.failure({ error: error.toJSON() }));
  }
}

export function* handleGetFilteredTemplatesHash() {
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();

    const templatePage: TemplatePage = yield apiCall(
      getFilteredTemplates,
      {
        searchLibrary: '',
        search: '',
        pageNumber: '1',
        pageSize: '30',
        sort: 'asc',
        size: '',
      },
      customer?.id,
      workspaceId,
      token,
    );

    yield put(templatesActions.FILTERED_HASH.success(templatePage));
  } catch (err: any) {
    yield put(templatesActions.FILTERED_HASH.failure({ error: err.message }));
  }
}

export default function* watcher() {
  yield takeLatest(
    [
      templatesTypes.FILTERED_HASH.REQUEST,
      templatesTypes.REMOVE.SUCCESS,
      templatesTypes.REMOVE_MULTIPLE.SUCCESS,
      templatesTypes.REMOVE_MULTIPLE.FAILURE,
    ],
    handleGetFilteredTemplatesHash,
  );
  yield takeLatest(templatesTypes.ALL.REQUEST, getAllTemplates);
  yield takeLatest(
    templatesTypes.SELECTED_DATA.REQUEST,
    getTemplateDataDetails,
  );

  yield takeLatest(templatesTypes.LABEL_PREVIEW.REQUEST, handleLabelPreview);

  yield takeLatest(templatesTypes.CURRENT.REQUEST, getCurrentTemplate);
  yield takeLatest(templatesTypes.FILTERED.REQUEST, handleGetFilteredTemplates);
  yield takeLatest(
    templatesTypes.ZEBRA_FILTERED.REQUEST,
    handleGetFilteredZebraTemplates,
  );
  yield takeLatest(
    templatesTypes.ZEBRA_FILTERED_ALL_SIZES.REQUEST,
    handleGetFilteredZebraTemplatesAllSizes,
  );
  yield takeLatest(
    templatesTypes.TEMPLATE_DIALOG.REQUEST,
    getTemplateDialogDetails,
  );
  yield takeLatest(
    templatesTypes.MY_DESIGN_ALL_SIZES.REQUEST,
    handleGetMyDesignAllSizes,
  );
  yield takeLatest(templatesTypes.RECENT.REQUEST, handleGetRecentTemplates);
  yield takeLatest(templatesTypes.UPDATE.REQUEST, handleUpdateTemplateRequest);
  yield takeLatest(templatesTypes.REMOVE.REQUEST, handleDeleteTemplateRequest);
  yield takeLatest(
    templatesTypes.REMOVE_MULTIPLE.REQUEST,
    handleDeleteManyTemplatesRequest,
  );
  yield takeLatest(templatesTypes.PRINT.REQUEST, handlePrintTemplate);
  yield takeLatest(templatesTypes.TEST_PRINT.REQUEST, handleTestPrintTemplate);
  yield takeLatest(templatesTypes.DUPLICATE.REQUEST, handleDuplicateTemplate);
  yield takeLatest(templatesTypes.THUMBNAIL.REQUEST, handleGetThumbnail);
}
