import { put, takeLatest } from 'redux-saga/effects';
import {
  types as preferencesTypes,
  actions as preferencesActions,
} from 'ducks/preferences/actions';
import { actions as infoActions } from 'ducks/info/actions';
import { openBasicToast, openErrorToast } from 'state/Toast';
import tokenStorage from 'services/tokenStorage';
import { getPrefs, setPrefs } from 'services/api';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';

function* handleGetAllPreferences() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const userPrefs = yield apiCall(getPrefs, customer?.id, token);

    yield put(preferencesActions.ALL.success(userPrefs));
    yield put(infoActions.WHO_AM_I.request({}));
  } catch (err: any) {
    yield put(preferencesActions.ALL.failure({ error: err }));
  }
}

function* handleUpdatePreferences({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const userPrefs = yield apiCall(
      setPrefs,
      customer?.id,
      token,
      payload.data,
    );
    yield put(preferencesActions.UPDATE.success(userPrefs));
    yield put(preferencesActions.ALL.success(userPrefs));
    yield put(openBasicToast(payload.message));
  } catch (err: any) {
    yield put(preferencesActions.UPDATE.failure({ error: err }));
    yield put(openErrorToast(`Preference update error`));
    console.error(err);
  }
}

export default function* watcher() {
  yield takeLatest(preferencesTypes.ALL.REQUEST, handleGetAllPreferences);
  yield takeLatest(preferencesTypes.UPDATE.REQUEST, handleUpdatePreferences);
}
