import { useMemo, useCallback, useState } from 'react';
import { getSearch } from 'ducks/router/selectors';
import { useReduxSelector, useReduxAction } from 'hooks';
import { replace } from 'connected-react-router';
import querystring from 'query-string';

const useUrlParam = (key: string, initialValue: any) => {
  const query = useReduxSelector(getSearch);
  const replaceAction = useReduxAction(replace);
  const [isInitialValue, setIsInitialValue] = useState(true);

  const value = useMemo(() => {
    const valueInQuery = querystring.parse(query)[key];
    if (!valueInQuery && isInitialValue) return initialValue;
    return valueInQuery;
  }, [initialValue, isInitialValue, key, query]);

  const setValue = useCallback(
    (v) => {
      const newValue = v?.target ? v.target.value : v;
      const parsedCurrentQuery = querystring.parse(query);
      const newQuery = querystring.stringify({
        ...parsedCurrentQuery,
        [key]: newValue,
      });
      replaceAction({ search: newQuery });
      setIsInitialValue(false);
    },
    [key, query, replaceAction],
  );
  return [value, setValue];
};

export default useUrlParam;
