import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions as dataSourcesActions,
  types as dataSourcesTypes,
} from 'ducks/dataSources/actions';
import { actions as modalDialogActions } from 'ducks/modalDialog/actions';
import { actions as templateActions } from 'ducks/templates/actions';
import {
  addGoogleDriveFile,
  addOfficeFile,
  deleteFile,
  FILE_ALREADY_EXISTS,
  getExternalFiles,
  getExternalFilesFiltered,
  getFileById,
} from 'services/internalDb';
import { FileInfoType } from 'utils/dataSourcesTypes';
import {
  MS_PROVIDER_LABEL,
  MS_PROVIDER_LABEL_OLD,
  showOneDriveFilePicker,
} from 'services/office365';
import { TEMPLATE_PARAMS_DEFAULT } from 'utils/defaults';
import { openBasicToast, openErrorToast } from 'state/Toast';
import {
  addLocalFile,
  addTemplate,
  deleteDocument,
  getLocalDataSource,
  getLocalDatasources,
  getLocalDatasourcesFiltered,
  getUniqueFileName,
  supportedDataTypes,
} from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { getAllFiles, getFilteredFiles } from 'ducks/dataSources/selectors';
import { getTenantId } from './templates';
import { toBase64 } from 'utils/toBase64';
import { actions as iframeActions } from 'ducks/iframe/actions';
import { getRows } from 'utils/excelHelper';
import { getFilteredTemplatesParams } from '../ducks/templates/selectors';
import { MAX_UPLOAD_FILE_SIZE } from 'utils/config';
import { CustomError } from 'utils/errorFormatter';
import { formattedBytes } from 'utils/metrics';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { PopupClosedByUserError } from '../utils/errorFormatter';
import i18n from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall } from './utils';
import googleApi from '../services/google/googleApi';
import { GoogleToken } from '../services/google/GoogleTokenStorage';
import { GOOGLE_CONTACTS_LABEL } from '../services/google/googleContacts';
import { GOOGLE_PROVIDER_LABEL_OLD } from '../utils/file/GoogleDriveFileProvider';
import {
  buildPermissionsNotGrantedToastOptions,
  PermissionsNotGrantedError,
} from '../services/permissions';
import { GoogleDriveScope } from '../services/google/GoogleDriveScope';

const DBG = ['%c DataSources Saga ', 'color:white;background:black'];

export function* displayPermissionsNotGrantedToast() {
  yield put(openErrorToast(buildPermissionsNotGrantedToastOptions()));
}

function* _addFile(fileState) {
  console.debug(...DBG, '_addFile');
  //TODO: This should be in a reducer
  const { data } = yield select(getAllFiles);
  try {
    const newAllFiles = [...(data ? data : []), fileState];
    yield put(dataSourcesActions.ALL.success(newAllFiles));
  } catch (e: any) {
    yield put(dataSourcesActions.ALL.failure({ error: e }));
  }

  try {
    const filteredFiles = yield select(getFilteredFiles);
    if (filteredFiles && filteredFiles.data) {
      const newFilteredFiles = [...filteredFiles.data, fileState];
      yield put(dataSourcesActions.FILTERED.success(newFilteredFiles));
    }
  } catch (e: any) {
    yield put(dataSourcesActions.FILTERED.failure({ error: e }));
  }
}

function* fetchFiles() {
  console.debug(...DBG, 'fetchFiles');
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();

  try {
    const { token } = tokenStorage.get();
    const localFiles = yield apiCall(
      getLocalDatasources,
      customer?.id,
      workspaceId,
      token,
    );

    if (localFiles) {
      //This is here to remvoe Binary from the Store
      const localFilesState = localFiles.map(
        ({ id, provider, name, mimetype, lastModified }) => ({
          id,
          provider,
          name,
          mimetype,
          lastModified,
        }),
      );

      const externalFiles = yield apiCall(
        getExternalFiles,
        customer?.id,
        workspaceId,
      );

      yield put(
        dataSourcesActions.ALL.success([...localFilesState, ...externalFiles]),
      );
    }
  } catch (e: any) {
    yield put(dataSourcesActions.ALL.failure({ error: e }));
  }
}

function* handleAddFile({
  payload,
}: PayloadAction<{
  file: File;
  source: string;
  from: string;
  fileType: string;
}>) {
  console.debug(...DBG, 'handleAddFile');
  const { file, source, from, fileType } = payload;

  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const { token } = tokenStorage.get();

  const uniqueName = yield apiCall(
    getUniqueFileName,
    file.name,
    customer?.id,
    workspaceId,
    token,
  );
  try {
    if (source.includes('Local')) {
      if (!file) {
        yield put(dataSourcesActions.ADD.failure({}));
        yield put(openErrorToast(i18n.t('dataSources:upload-file-error')));

        return;
      }

      if (file.size > +MAX_UPLOAD_FILE_SIZE.toExponential()) {
        yield put(dataSourcesActions.ADD.failure({}));
        yield put(
          openErrorToast(
            i18n.t('dataSources:upload-file-size-error', {
              maxSize: formattedBytes(MAX_UPLOAD_FILE_SIZE),
            }),
          ),
        );

        return;
      }

      const fileState = yield apiCall(
        addLocalFile,
        file,
        uniqueName,
        customer?.id,
        workspaceId,
        token,
      );

      yield put(dataSourcesActions.ADD.success(fileState));
      yield put(
        openBasicToast(
          i18n.t('dataSources:upload-file-success', {
            fileName: fileState.name,
          }),
        ),
      );

      if (from !== 'LDA') return;

      const content = yield call(toBase64, file);

      if (fileType === 'image') {
        yield put(
          iframeActions.IMAGE.success({
            ...fileState,
            data: content,
          }),
        );
      } else if (fileType === 'dataSource') {
        const jsonSheet = yield call(getRows, content);

        const columns = jsonSheet?.[0]?.map((element, index) => ({
          value: index,
          label: element,
        }));

        jsonSheet?.[1]?.forEach((element, index) => {
          columns[index].provisionalValue = element;
        });

        const rowCount = jsonSheet.length;

        yield put(
          iframeActions.DATASOURCE.success({
            ...fileState,
            data: jsonSheet,
            columns,
            rowCount,
            content: content,
          }),
        );
      }
    }
    // else if (source.includes('Image')) {
    //   // TODO: upload image make api call to addAvatar, update workspace state?
    //   // What piece of state am I updating here?
    //   alert('image');
    //   if (file) {
    //     if (file.size < 2.84e7) {
    //       const workspace = yield select(getCurrentWorkspace);
    //       let avatar = workspace.avatar;

    //       const fileState = yield call(addAvatar, file, workspaceId, token);
    //       yield _addFile(fileState);
    //       yield put(dataSourcesActions.ADD.success(fileState));
    //       yield put(
    //         toastActions.OPEN(
    //           'basic',
    //           `${fileState.name} has been successfully uploaded`,
    //         ),
    //       );
    //     } else {
    //       yield put(dataSourcesActions.ADD.failure({}));
    //       yield put(toastActions.OPEN('error', `File could not be uploaded`));
    //     }
    //   }
    // }
    else if (source.includes('Label')) {
      if (!file) {
        yield put(dataSourcesActions.ADD.failure({}));
        yield put(openErrorToast(i18n.t('dataSources:upload-file-error')));

        return;
      }

      if (file.size > +MAX_UPLOAD_FILE_SIZE.toExponential()) {
        yield put(dataSourcesActions.ADD.failure({}));
        yield put(
          openErrorToast(
            i18n.t('dataSources:upload-file-size-error', {
              maxSize: formattedBytes(MAX_UPLOAD_FILE_SIZE),
            }),
          ),
        );

        return;
      }

      const fileData = yield call(toBase64, file);

      const fileState = yield apiCall(
        addTemplate,
        fileData,
        uniqueName,
        customer?.id,
        workspaceId,
        token,
      );
      yield put(dataSourcesActions.ADD.success({ fileType: 'Label' }));

      const params = yield select(getFilteredTemplatesParams);
      yield put(templateActions.FILTERED.request(params));
      yield put(
        templateActions.FILTERED_HASH.request({
          ...TEMPLATE_PARAMS_DEFAULT,
        }),
      );

      yield put(
        openBasicToast(
          i18n.t('dataSources:upload-file-success', {
            fileName: fileState.name ?? file.name,
          }),
        ),
      );
      yield put(templateActions.MY_DESIGN_ALL_SIZES.request({}));
    }
  } catch (err: any) {
    yield put(dataSourcesActions.ADD.failure({ error: err })); //CHECK this change hasnt caused problems
    yield put(openErrorToast(`${err.message}`));

    if (from === 'LDA') {
      if (fileType === 'image') {
        yield put(iframeActions.IMAGE.failure({ error: err })); //CHECK this change hasnt caused problems
      } else {
        yield put(iframeActions.DATASOURCE.failure({ error: err })); //CHECK this change hasnt caused problems
      }
    }
  }
}

function* handleDeleteFileRequest({ payload }: any) {
  console.debug(...DBG, 'handleDeleteFileRequest', payload);
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const { token } = tokenStorage.get();
  try {
    let success;
    if (payload.provider.includes('Local')) {
      success = yield apiCall(
        deleteDocument,
        payload.name,
        customer?.id,
        workspaceId,
        token,
      );
    } else {
      success = yield deleteFile(payload.id, customer?.id, workspaceId);
    }
    if (success) {
      const allFiles = yield select(getAllFiles);
      const newAllFiles = allFiles.data.filter((obj) => obj.id !== payload.id);
      yield put(dataSourcesActions.ALL.success(newAllFiles));

      const filteredFiles = yield select(getFilteredFiles);
      if (filteredFiles && filteredFiles.data?.length > 0) {
        const newFilteredFiles = filteredFiles.data.filter(
          (obj) => obj.id !== payload.id,
        );
        yield put(dataSourcesActions.FILTERED.success(newFilteredFiles));
      }

      yield put(dataSourcesActions.REMOVE.success({ ...payload }));
      yield put(
        openBasicToast(
          i18n.t('dataSources:remove-file-success', {
            fileName: payload.name,
          }),
        ),
      );
    } else {
      yield put(dataSourcesActions.REMOVE.failure({}));
      yield put(openErrorToast(i18n.t('utils:file-deleted')));
    }
  } catch (err: any) {
    yield put(dataSourcesActions.REMOVE.failure({ error: err }));
    yield put(openErrorToast(err.message));
  }
}

function* handleSelectedFile({ payload }: any) {
  console.debug(...DBG, 'handleSelectedFile in', payload);
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  try {
    const { token } = tokenStorage.get();
    if (!payload) {
      yield put(dataSourcesActions.SELECTED.success(undefined));
      return;
    }

    //If Google or One Drive
    if (
      payload.provider.startsWith(GOOGLE_PROVIDER_LABEL_OLD) ||
      payload.provider === MS_PROVIDER_LABEL ||
      payload.provider === MS_PROVIDER_LABEL_OLD
    ) {
      if (payload.id === 'Google') {
        yield put(
          dataSourcesActions.SELECTED.success({
            fileName: GOOGLE_CONTACTS_LABEL,
            provider: GOOGLE_CONTACTS_LABEL,
            id: GOOGLE_CONTACTS_LABEL,
            fileType: 'csv',
            dimensions: '',
          }),
        );
      } else {
        const file: FileInfoType = yield apiCall(
          getFileById,
          payload.id,
          customer?.id,
          workspaceId,
        );

        let fileMetaData: any;
        if (file === null) {
          fileMetaData = {
            fileName: payload.name,
            provider: payload.provider,
            id: payload.id,
            fileType: payload.fileType,
          };
        } else {
          fileMetaData = {
            fileName: file.name,
            provider: file.provider,
            id: file.id,
            fileType: file.mimetype,
            dimensions: file?.dimensions,
          };
        }

        yield put(dataSourcesActions.SELECTED.success(fileMetaData));
      }
    } else if (payload.provider === 'Office365') {
      //contacts
      yield put(
        dataSourcesActions.SELECTED.success({
          fileName: 'Office365 Contacts',
          provider: 'Office365',
          id: 'Office365 Contacts',
          fileType: 'csv',
          dimensions: '',
        }),
      );
    } else {
      const file = yield apiCall(
        getLocalDataSource,
        payload.id,
        customer?.id,
        workspaceId,
        token,
      );
      const fileMetaData = {
        fileName: file.name,
        provider: file.provider,
        id: file.id,
        fileType: file.mimetype,
        dimensions: file?.dimensions,
      };
      yield put(dataSourcesActions.SELECTED.success(fileMetaData));
    }
  } catch (e: any) {
    console.error('SELECTED', e);
    yield put(dataSourcesActions.SELECTED.failure({ error: e }));
  }
}

function* handleGooglePicker({ payload }: any) {
  console.debug(...DBG, 'handleGooglePicker', payload);
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const requestedScopes = [GoogleDriveScope.Drive];

  try {
    const { tokenResponse }: GoogleToken = yield googleApi.configureToken({
      requestedScopes,
    });
    if (tokenResponse) {
      if (tokenResponse.error === 'access_denied') {
        throw new PermissionsNotGrantedError();
      } else if (tokenResponse.error) {
        throw new Error(tokenResponse.error);
      }

      const account = yield googleApi.getLinkedAccount();
      console.debug(...DBG, 'addGoogleAccount', account);
      yield put(dataSourcesActions.GOOGLE_ACCOUNT.success(account));
    }
    const data = yield googleApi.showPicker(tokenResponse);
    const fileExtension = data[0].name.includes('.')
      ? data[0].name.split('.').slice(-1)[0]
      : null;
    const isAllowedExtension = !!supportedDataTypes.find((type) => {
      const formattedType = type.startsWith('.') ? type.replace('.', '') : type;
      return formattedType.toLowerCase() === fileExtension.toLowerCase();
    });
    if (!isAllowedExtension || !fileExtension) {
      console.debug(...DBG, 'File extension is not allowed', data[0]);
      throw new Error('Invalid File Extension');
    }
    const fileAdded = yield apiCall(
      addGoogleDriveFile,
      data[0],
      customer?.id,
      workspaceId,
    );

    // yield _addFile(fileAdded); //TODO: are these two adds the duplicate?
    yield put(dataSourcesActions.ADD.success(fileAdded));
    yield put(
      openBasicToast(
        i18n.t('dataSources:upload-file-success', {
          fileName: fileAdded.name,
        }),
      ),
    );
    
  } catch (err: any) {
    if (
      err instanceof CustomError &&
      err.type === FILE_ALREADY_EXISTS &&
      err.data
    ) {
      return yield put(modalDialogActions.OVERWRITE_FILE.open(err.data));
    }

    if (err instanceof PermissionsNotGrantedError) {
      yield displayPermissionsNotGrantedToast();
    }

    if (err.error === 'popup_closed_by_user') {
      yield put(dataSourcesActions.ADD.clear());
    } else {
      yield put(dataSourcesActions.ADD.failure({ error: err }));
      if (err.message) {
        yield put(openErrorToast(`${err.message}`));
      }
    }
  }
}

function* handleOffice365({ payload: account }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const file = yield showOneDriveFilePicker(account);
    if (!file) {
      throw new PopupClosedByUserError();
    }
    const fileAdded = yield apiCall(
      addOfficeFile,
      file,
      customer?.id,
      workspaceId,
      account.username,
    );

    yield _addFile(fileAdded);

    yield put(dataSourcesActions.ADD.success(fileAdded));
    yield put(
      openBasicToast(
        i18n.t('dataSources:upload-file-success', {
          fileName: fileAdded.name,
        }),
      ),
    );
  } catch (err: any) {
    if (
      err instanceof CustomError &&
      err.type === FILE_ALREADY_EXISTS &&
      err.data
    ) {
      return yield put(modalDialogActions.OVERWRITE_FILE.open(err.data));
    }
    if (!(err instanceof PopupClosedByUserError)) {
      yield put(openErrorToast(`${err.message}`));
      yield put(dataSourcesActions.ADD.failure({ error: err }));
    } else {
      yield put(dataSourcesActions.ADD.clear());
    }
  }
}

function* handleGoogleAccount() {
  console.debug(...DBG, 'handleGoogleAccount');
  try {
    const account = yield googleApi.getLinkedAccount();
    console.debug(...DBG, 'handleGoogleAccount', account);
    yield put(dataSourcesActions.GOOGLE_ACCOUNT.success(account));
  } catch (err: any) {
    if (err instanceof PermissionsNotGrantedError) {
      yield displayPermissionsNotGrantedToast();
    }

    yield put(dataSourcesActions.GOOGLE_ACCOUNT.failure({ error: err }));
  }
}

function* clearGoogleAccount() {
  yield googleApi.removeLinkedAccount();
  console.debug(...DBG, 'clearGoogleAccount');
}

function* handleFiltered({ payload }: any) {
  console.debug(...DBG, 'handleFiltered');
  //TODO make concurrent with Promise.all
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();

  try {
    const { token } = tokenStorage.get();
    const localFiles = yield apiCall(
      getLocalDatasourcesFiltered,
      customer?.id,
      workspaceId,
      payload?.search,
      token,
    );
    if (localFiles) {
      const externalFiles = yield apiCall(
        getExternalFilesFiltered,
        payload?.search,
        customer?.id,
        workspaceId,
      );
      yield put(
        dataSourcesActions.FILTERED.success([...localFiles, ...externalFiles]),
      );
    }
  } catch (err: any) {
    yield put(dataSourcesActions.FILTERED.failure({ error: err }));
  }
}

export default function* watcher() {
  yield takeLatest(dataSourcesTypes.ALL.REQUEST, fetchFiles);
  yield takeLatest(dataSourcesTypes.ADD.REQUEST, handleAddFile);
  yield takeLatest(dataSourcesTypes.REMOVE.REQUEST, handleDeleteFileRequest);
  yield takeLatest(dataSourcesTypes.SELECTED.REQUEST, handleSelectedFile);
  yield takeLatest(dataSourcesTypes.GOOGLE_DRIVE.REQUEST, handleGooglePicker);
  yield takeLatest(dataSourcesTypes.OFFICE_365.REQUEST, handleOffice365);
  yield takeLatest(
    dataSourcesTypes.GOOGLE_ACCOUNT.REQUEST,
    handleGoogleAccount,
  );
  yield takeLatest(dataSourcesTypes.GOOGLE_ACCOUNT.CLEAR, clearGoogleAccount);
  yield takeLatest(dataSourcesTypes.FILTERED.REQUEST, handleFiltered);
}
