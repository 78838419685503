import { createReducer, generatePostDataReducer } from 'ducks/utils';
import { types } from './actions';
import { POST_DATA_INITIAL_STATE } from 'ducks/utils';
import { combineReducers } from 'redux';

const LOGIN_INITIAL_STATE = {
  loading: true,
  success: false,
  error: null,
  data: {
    token: null,
    user: null,
  },
};

const loginReducer = {
  ...generatePostDataReducer(types.LOGIN),
  [types.LOGOUT]: (state) => ({
    loading: false,
    success: false,
    error: null,
    data: {
      ...state?.data,
      user: null,
    },
  }),
};

const user = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.USER),
  [types.UPDATE_USER.SUCCESS]: (state, action) => ({
    ...state,
    data: { ...state?.data, ...action?.payload?.user },
  }),
  [types.UPDATE_FIRSTNAME.SUCCESS]: (state, action) => ({
    ...state,
    data: { ...state?.data, firstName: action.payload },
  }),
  [types.UPDATE_LASTNAME.SUCCESS]: (state, action) => ({
    ...state,
    data: { ...state?.data, lastName: action.payload },
  }),
  [types.UPDATE_EMAIL.SUCCESS]: (state, action) => ({
    ...state,
    data: { ...state?.data, userId: action.payload, emailId: action.payload },
  }),
});

const customer = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.CUSTOMER),
  [types.UPDATE_CUSTOMER.SUCCESS]: (state, action) => ({
    ...state,
    data: { ...state?.data, ...action?.payload },
  }),
});

const updateUser = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE_USER),
);

const updateCustomer = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE_CUSTOMER),
);
const emailToken = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.EMAIL_TOKEN),
);

const login = createReducer(LOGIN_INITIAL_STATE, loginReducer);

const updateFirstName = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE_FIRSTNAME),
);
const updateLastName = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE_LASTNAME),
);
const updateEmail = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE_EMAIL),
);

const INITIAL_AUTH_STATUS_STATE = {
  loading: false,
  data: null,
};

const initialAuthStatusReducer = {
  [types.INITIAL_STATUS.IS_LOADING]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
};

const initialStatus = createReducer(
  INITIAL_AUTH_STATUS_STATE,
  initialAuthStatusReducer,
);

const deleteAccount = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.DELETE_ACCOUNT),
});

const LOAD_FEATURES_INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  data: [],
};
const features = createReducer(
  LOAD_FEATURES_INITIAL_STATE,
  generatePostDataReducer(types.LOAD_FEATURES),
);

export default combineReducers({
  login,
  updateUser,
  updateCustomer,
  user,
  customer,
  emailToken,
  updateFirstName,
  updateLastName,
  updateEmail,
  initialStatus,
  deleteAccount,
  features,
});
