import React from 'react';
import { Flex, Text } from 'components';
import styled, { DefaultTheme } from 'styled-components';

const StyledText = styled(Text).attrs(({ theme }: { theme: DefaultTheme }) => ({
  color: theme.neutral.base,
}))``;

export const NoResultsPanel = ({ children }) => {
  return (
    <Flex
      p={100}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <StyledText fontSize={24} letterSpacing={0.4}>
        {children}
      </StyledText>
    </Flex>
  );
};
