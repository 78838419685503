import {
  generateDataTypes,
  generateDataActions,
  generateSelectedDataTypes,
  generateSelectedDataActions,
} from 'ducks/utils';
import { PrinterSettings } from 'zsbpsdk/proto/ZsbpPortalService_pb';

const BASE_DUCK_PREFIX = 'SMB_PRINTERS_';

export type FeedOnHeadCloseRequest = {
  uniqueId: string;
}

export type SetSettingsRequest = {
  printerSettings: PrinterSettings;
}

export type FeedOnHeadClosePayload = {
  [uniqueId: string]: {
    loading: boolean;
    headClosedAction?: PrinterSettings.HeadClosedAction;
  };
}

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  CURRENT: generateDataTypes(BASE_DUCK_PREFIX + 'CURRENT'),
  UPDATE: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE'),
  SELECTED: generateSelectedDataTypes(BASE_DUCK_PREFIX + 'SELECTED'),
  UPDATE_STATUS: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_STATUS'),
  REMOVE: generateDataTypes(BASE_DUCK_PREFIX + 'REMOVE'),
  CANCEL_ALL: generateDataTypes(BASE_DUCK_PREFIX + 'CANCEL_ALL'),
  REPRINT: generateDataTypes(BASE_DUCK_PREFIX + 'REPRINT'),
  GET_UNTIL_NEW: generateDataTypes(BASE_DUCK_PREFIX + 'GET_UNTIL_NEW'),
  SET_SETTINGS: generateDataTypes(BASE_DUCK_PREFIX + 'SET_SETTINGS'),
  FEED_ON_HEAD_CLOSE: generateDataTypes(BASE_DUCK_PREFIX + 'FEED_ON_HEAD_CLOSE'),
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  CURRENT: generateDataActions(types.CURRENT),
  UPDATE: generateDataActions(types.UPDATE),
  SELECTED: generateSelectedDataActions(types.SELECTED),
  UPDATE_STATUS: generateDataActions(types.UPDATE_STATUS),
  REMOVE: generateDataActions(types.REMOVE),
  CANCEL_ALL: generateDataActions(types.CANCEL_ALL),
  REPRINT: generateDataActions(types.REPRINT),
  GET_UNTIL_NEW: generateDataActions(types.GET_UNTIL_NEW),
  SET_SETTINGS: generateDataActions<SetSettingsRequest, void>(types.SET_SETTINGS),
  FEED_ON_HEAD_CLOSE: generateDataActions<FeedOnHeadCloseRequest, FeedOnHeadClosePayload>(types.FEED_ON_HEAD_CLOSE),
};
