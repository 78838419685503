import { generateDataTypes, generateDataActions } from 'ducks/utils';
const BASE_DUCK_PREFIX = 'SMB_IFRAME_';

export const types = {
  DATASOURCE: generateDataTypes(BASE_DUCK_PREFIX + 'DATASOURCE'),
  IMAGE: generateDataTypes(BASE_DUCK_PREFIX + 'IMAGE'),
};

export const actions = {
  DATASOURCE: generateDataActions(types.DATASOURCE),
  IMAGE: generateDataActions(types.IMAGE),
};
