import { getQuery as selectQuery } from 'ducks/router/selectors';
import { useFetchedData, useReduxSelector, useUrlParam } from 'hooks';
import { useEffect } from 'react';
import {
  PAGE_NUMBER_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SORT_DEFAULT,
} from 'utils/defaults';

const useFilteredTemplates = (
  selector: any,
  reduxAction: () => void,
  selectedCategory?: string,
) => {
  const {
    searchLibrary = '',
    pageSize = PAGE_SIZE_DEFAULT,
    sort = SORT_DEFAULT,
    size = '',
    search = '',
  } = useReduxSelector(selectQuery);

  const [pageNumber, setPageNumber] = useUrlParam(
    'pageNumber',
    PAGE_NUMBER_DEFAULT,
  );

  const [
    filteredTemplatesData,
    filteredTemplatesDataLoading,
    filteredTemplatesError,
  ] = useFetchedData(selector, reduxAction, {
    searchLibrary,
    search: search || searchLibrary,
    pageNumber,
    pageSize,
    sort,
    size,
    selectedCategory,
  });

  const totalPagesCount = Math.ceil(
    filteredTemplatesData?.totalCount / parseInt(pageSize),
  );

  useEffect(() => {
    const page = parseInt(pageNumber);

    if (page > totalPagesCount) {
      setPageNumber(
        totalPagesCount > 0 ? totalPagesCount : PAGE_NUMBER_DEFAULT,
      );
    }
  }, [pageNumber, setPageNumber, totalPagesCount]);

  return {
    filteredTemplatesData,
    filteredTemplatesDataLoading,
    filteredTemplatesError,
    totalPagesCount,
  };
};

export default useFilteredTemplates;
