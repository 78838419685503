import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Route, Switch } from 'react-router';
import { Flex, Box } from 'components';
import Workspace from './Workspace';
import Library from './Library';
import { Header } from './Header';
import { ContentWrapWideScreen } from 'components/common/layout/Layout';
import { device } from 'utils/mediaQueries';
import { COMMON_DESIGNS_PATH, MY_DESIGNS_PATH } from '../../../utils/config';
import { MultiSelectProvider, useMultiSelect } from 'providers/MultipleSelect';
import { FooterBar } from 'v2/pages/MyDesignsPage/MyDesignsPage';

const ContentWrap = styled(Flex).attrs({
  flexDirection: 'column',
})`
  flex: 1;
  padding-top: 10px;
  max-width: 100%;
`;

const FullWidthScreen = styled(Flex).attrs(({ ...props }: any) => ({
  ...props,
}))`
  background-color: ${({ theme }) => theme.primary.background};
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  min-height: calc(100% - 200px);
  transform: translate(0, ${(props) => props.offset}px);
  transition: transform 0.4s ease-out;
  height: calc(100% - ${(props) => props.offset}px);
  @media ${device.tablet} {
    height: calc(100% - ${(props) => props.offset + 71}px);
  }
`;

const FixedFooter = styled(Box)`
  width: 100%;
  padding: 24px 0;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: ${({ theme }) => theme.white};
`;

const HorizontalPadding = styled(Box).attrs(() => ({
  px: 80,
}))`
  @media ${device.mobile} {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const Templates = () => {
  const [offset, setOffset] = useState(550);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOffset(300);
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  return (
    <MultiSelectProvider>
      <FullWidthScreen offset={offset}>
        <ContentWrapWideScreen offset={offset}>
          <Flex>
            <ContentWrap>
              <Header />
              <Flex mt={10} justifyContent={'center'}>
                <Switch>
                  <Route path={COMMON_DESIGNS_PATH} component={Library} />
                  <Route path={MY_DESIGNS_PATH} component={Workspace} />
                </Switch>
              </Flex>
            </ContentWrap>
          </Flex>
        </ContentWrapWideScreen>
      </FullWidthScreen>
      <Footer />
    </MultiSelectProvider>
  );
};

function Footer() {
  const [{ selected }] = useMultiSelect();

  if (selected.length === 0) {
    return null;
  }

  return (
    <FixedFooter>
      <HorizontalPadding>
        <FooterBar />
      </HorizontalPadding>
    </FixedFooter>
  );
}

export default Templates;
