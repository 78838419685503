import styled from 'styled-components/macro';
import { device } from 'utils/mediaQueries';
import { Flex, TemplateCard } from 'components';

export const TemplatesWrap = styled(Flex)<any>`
  width: 100%;
  padding: 0 30px 24px;
  padding-bottom: ${(props) => (!props.loaded ? ' 128' : '0')}px;

  @media ${device.desktopWide} {
    max-width: 1440px;
    min-width: 1100px; //fixes card issue on desktopwide
  }
`;

export const StyledTemplateCard = styled(TemplateCard)`
  margin-bottom: 17px;
  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.desktopMiddle} {
    width: 48.25%;
  }

  @media ${device.mobileWide} {
    width: 47%;
  }

  @media ${device.tabletM} {
    width: 47.8%;
  }

  @media ${device.desktopWide} {
    width: 33%;
  }
`;
