import React from 'react';
import { useParams, useRouteMatch, generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';

import { Loader } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import {
  getAllPrinters,
  getAllPrintersLoading,
} from 'ducks/printers/selectors';
import { NavigationTabBar } from 'components/common/NavigationTabBar';

import { PrinterSettings } from './PrinterSettings';
import { Common } from './Common';

const generatePageNames = (printers: any[]) => {
  return ['Common', ...(printers || []).map((printer) => printer.name)];
};

export const Printers = () => {
  const printersLoading = useReduxSelector(getAllPrintersLoading);
  const printers = useReduxSelector(getAllPrinters);
  const pushAction = useReduxAction(push);
  const { printerId } = useParams<{ printerId: string }>();
  const { path } = useRouteMatch();

  const applyPage = (val: number) => {
    if (val === 0) {
      pushAction(generatePath(path));
      return;
    }

    const { uniqueId } = printers[val - 1];

    pushAction(generatePath(path, { printerId: uniqueId }));
  };

  if (!printers || printersLoading) {
    return <Loader visible={true} />;
  }

  const selectedTab =
    printers.findIndex(({ uniqueId }) => uniqueId === printerId) + 1;

  return (
    <>
      <NavigationTabBar
        data-testid="printer-settings-printer-nav-bar"
        selected={selectedTab}
        pageNames={generatePageNames(printers)}
        setPage={applyPage}
      />
      {printerId ? <PrinterSettings /> : <Common />}
    </>
  );
};
