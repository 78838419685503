import { FileDataParams } from './FileProvider';
import { CloudFileProvider } from './CloudFileProvider';
import googleApi from '../../services/google/googleApi';

export const GOOGLE_PROVIDER_LABEL = 'Google Drive';
export const GOOGLE_PROVIDER_LABEL_OLD = 'Google';

export class GoogleDriveFileProvider extends CloudFileProvider {
  async getFileData(file, params: FileDataParams) {
    file = (await super.getFileData(file, params)) ?? file;
    return googleApi.getFileFromDrive(file);
  }
}
