import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Loader } from '../../../index';
import Dropdown from '../../../lib/dropdown/Dropdown';
import { InnerTextInputFieldWrap } from '../../../lib/input/Input';
import { DropdownOptionsType } from '../../../lib/dropdown/Dropdown.styles';
import {
  useAppDispatch,
  useModal,
  useReduxAction,
  useReduxSelector,
} from '../../../../hooks';
import {
  getImageFiles,
  isAddingFileError,
  isAddingFileSuccess,
} from '../../../../ducks/dataSources/selectors';
import { useTranslation } from 'react-i18next';
import { PrintInput } from './PrintInput';
import { FileProviderFactory } from '../../../../utils/file/FileProviderFactory';
import { compressImage } from '../../../../utils/imageCompress';
import { LinkedFileMissingError } from '../../../../utils/errorFormatter';
import { openErrorToast } from '../../../../state/Toast';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CameraModal from '../../../../pages/dataSources/components/Camera/CameraModal';
import GalleryModal from '../../../../pages/dataSources/components/Gallery/GalleryModal';
import { getLocalData } from '../../../../services/internalDb';
import { supportedImageTypes } from '../../../../services/api';
import { getCurrentWorkspaceId } from '../../../../ducks/workspaces/selectors';
import { getCustomer } from '../../../../ducks/auth/selectors';
import { actions as dataSourcesActions } from '../../../../ducks/dataSources/actions';

const STATUS_AWAITING = 'awaiting';

const StyledInnerTextInputFieldWrap = styled(InnerTextInputFieldWrap)`
  height: 48px;
  width: 100%;
`;

export enum ImageDropdownType {
  Upload = '-3',
  ZebraGallery = '-2',
  Camera = '-1',
}

export type PrintPictureInputProps = {
  template: any;
  card: any;
  onLoadingChange: (loading: boolean) => void;
  onImageAdd: (imageData: string, dropdownDisplayValue: string) => void;
};

export const PrintPictureInput = ({
  template,
  card,
  onLoadingChange,
  onImageAdd,
}: PrintPictureInputProps) => {
  const { t } = useTranslation(['components', 'common']);
  const dispatch = useAppDispatch();
  const msalContext = useMsal();
  const isOneDriveAuthenticated = useIsAuthenticated();

  const userImages = useReduxSelector(getImageFiles);
  const workspaceId = useReduxSelector(getCurrentWorkspaceId);
  const datasourcesAddSuccess = useReduxSelector(isAddingFileSuccess);
  const datasourcesAddError = useReduxSelector(isAddingFileError);
  const customer = useReduxSelector(getCustomer);

  const addFileAction = useReduxAction(dataSourcesActions.ADD.request);

  const cameraModalRef = useRef(null);
  const galleryModalRef = useRef(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const cameraModalHook = useModal(cameraModalRef);
  const galleryModalHook = useModal(galleryModalRef);

  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [cameraImage, setCameraImage] = useState<string>();
  const [clipArtImage, setClipArtImage] = useState<any>(null);

  const imageOptions: DropdownOptionsType[] = [
    {
      value: ImageDropdownType.Upload,
      label: t('components:printer.upload'),
      logo: 'upload',
    },
    {
      value: ImageDropdownType.ZebraGallery,
      label: t('components:printer.zebra-gallery'),
      logo: 'logo-zebra',
    },
    {
      value: ImageDropdownType.Camera,
      label: t('components:printer.camera'),
      logo: 'camera',
    },
    ...userImages.map((image, index) => ({
      value: index.toString(),
      label: image.name,
      logo: 'image',
    })),
  ];

  const handleFileInput = useCallback(
    (e) => {
      const file = fileRef?.current?.files?.[0];
      if (file) {
        setImageLoading(true);
        addFileAction({ file, source: 'Local File' });
        e.target.value = '';
      }
    },
    [addFileAction],
  );

  const getCompressedImageData = useCallback(
    async (base64Data: string) => {
      const compressedImage = await compressImage(
        base64Data,
        template.labelSize,
      );
      return compressedImage.split(',')?.[1];
    },
    [template],
  );

  const setImageLater = useCallback(async () => {
    try {
      const userImageIndex = userImages.length - 1;
      const name = userImages[userImageIndex].name;
      const imageData = await getLocalData(name, customer?.id, workspaceId);
      const compressedImageData = await getCompressedImageData(
        `data:image/jpeg;base64,${imageData['content']}`, // TODO jpeg is a guess
      );
      onImageAdd(compressedImageData, name);
    } catch (e: any) {
      console.error(e);
      dispatch(openErrorToast(e.message));
    } finally {
      setImageLoading(false);
    }
  }, [
    customer,
    dispatch,
    getCompressedImageData,
    userImages,
    workspaceId,
    onImageAdd,
  ]);

  const handleChange = useCallback(
    async (dropdownValue: string) => {
      setImageLoading(true);

      try {
        switch (dropdownValue) {
          case ImageDropdownType.Upload:
            fileRef.current?.click();
            break;

          case ImageDropdownType.ZebraGallery:
            setClipArtImage(STATUS_AWAITING);
            galleryModalHook.open();
            break;

          case ImageDropdownType.Camera:
            setCameraImage(STATUS_AWAITING);
            cameraModalHook.open();
            break;

          default:
            // Already uploaded image
            const file = userImages[dropdownValue];
            const fileProvider = FileProviderFactory.getInstance(file);

            try {
              const fileData = await fileProvider?.getFileData(file, {
                customerId: customer?.id,
                workspaceId,
                msalContext,
                isOneDriveAuthenticated,
              });
              const compressedImageData = await getCompressedImageData(
                `data:${file.mimetype};base64,${fileData}`,
              );
              onImageAdd(compressedImageData, file.name);
            } catch (e: any) {
              if (e?.status === 404) {
                throw new LinkedFileMissingError();
              }

              dispatch(openErrorToast(t('common:errors.generic-error')));

              throw new Error();
            }
            break;
        }
      } catch (e: any) {
        if (e instanceof LinkedFileMissingError) {
          dispatch(openErrorToast(e.message));
        }
      } finally {
        setImageLoading(false);
      }
    },
    [
      cameraModalHook,
      customer,
      dispatch,
      t,
      galleryModalHook,
      workspaceId,
      userImages,
      msalContext,
      isOneDriveAuthenticated,
      getCompressedImageData,
      onImageAdd,
    ],
  );

  const handleGalleryImage = (image, current) => {
    if (image && current) {
      setClipArtImage(image);
    }
  };

  useEffect(() => {
    onLoadingChange(imageLoading);
  }, [onLoadingChange, imageLoading]);

  useEffect(() => {
    (async function () {
      if (
        cameraImage &&
        cameraImage !== '' &&
        cameraImage !== STATUS_AWAITING
      ) {
        const compressedImageData = await getCompressedImageData(cameraImage);
        onImageAdd(compressedImageData, t('components:printer.camera-image'));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraImage]);

  useEffect(() => {
    if (
      clipArtImage &&
      clipArtImage !== STATUS_AWAITING &&
      Object.keys(clipArtImage).length > 0
    ) {
      const imageData = clipArtImage.base64.split(',')[1];
      const clipArtName = t('components:printer.zebra-gallery-icon', {
        icon: clipArtImage.name,
      });
      onImageAdd(imageData, clipArtName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipArtImage]);

  useEffect(() => {
    if (datasourcesAddSuccess && imageLoading) {
      setImageLater();
    } else if (datasourcesAddError) {
      setImageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasourcesAddSuccess, datasourcesAddError]);

  return (
    <PrintInput template={template} card={card}>
      {imageLoading ? (
        <StyledInnerTextInputFieldWrap>
          <Loader visible={true} />
        </StyledInnerTextInputFieldWrap>
      ) : (
        <>
          <Dropdown
            placeholder="Choose an option"
            value={card.dropdownDisplayValue}
            options={imageOptions}
            onChange={async (selectedValue) => {
              await handleChange(selectedValue);
            }}
          />
          {/* TODO: Add hasError */}
        </>
      )}

      <CameraModal resolve={setCameraImage} ref={cameraModalRef} />
      <GalleryModal resolve={handleGalleryImage} ref={galleryModalRef} />

      <input
        name="uploadFile"
        type="file"
        hidden
        accept={supportedImageTypes.toString()}
        ref={fileRef}
        onChange={handleFileInput}
        data-testid="localFiles-uploadInput"
      />
    </PrintInput>
  );
};
