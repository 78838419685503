import { isAfter } from 'date-fns';

export const getPrinterWithExpiredCertification = (printers: any) =>
  printers.filter(function (item: any) {
    return (
      isAfter(new Date(), new Date(item.certificateNotAfter.seconds * 1000)) &&
      !item.status.isOnline
    );
  });

export const getPrinterWithAboutToExpireCertification = (printers: any) =>
  printers.filter(function (item: any) {
    const currentDate = new Date();
    const expiryDate = new Date(item.certificateNotAfter.seconds * 1000);

    // If the expiry date is in the future(bigger number), the certificate will expire soon
    // If the expiry date is less than current (before current date) - certificate is expired
    const isAboutToExpire = currentDate.getTime() <= expiryDate.getTime();

    // if the difference between two dates is less than 30 days - the certificate is About to Expire
    // 60 seconds in 1 minute x 60 minutes in 1 hour x 24 hours in 1 day = 86,400 seconds in 1 day
    const dateDiff = (
      Math.abs(currentDate.getTime() - expiryDate.getTime()) /
      1000 /
      86400
    ).toFixed(0);
    return +dateDiff <= 30 && !item.status.isOnline && isAboutToExpire;
  });
