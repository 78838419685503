import styled from 'styled-components/macro';
import { Icon, Box, Text } from 'components';
import { MenuDropdown } from '../dropdown/Dropdown.styles';

import {
  InnerTextInputFieldWrap,
  TextInputField,
} from 'components/lib/input/Input';
import IconType from '../icon/IconType';

export type SuggestionType = {
  label: string;
  type: string;
  name: string;
  logo: IconType;
};

export type SearchType = {
  placeholder?: string;
  options?: Partial<SuggestionType>[];
  onChange: (v: string) => void;
  onSelect?: (v: string) => void;
  initialSearch?: string;
  disabled?: boolean;
};

export const SearchIcon = styled(Icon).attrs(({ theme }) => ({
  size: 20,
  icon: 'search',
  color: theme.neutral.base,
}))``;

export const Category = styled(Text).attrs(({ theme }) => ({
  lineHeight: '16px',
  fontSize: '12px',
  letterSpacing: '2px',
  color: theme.textColors.med,
  px: 8,
  py: 7,
}))`
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.lightest};
  align-self: flex-start;
  width: 100%;
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 14,
}))``;

export const PhraseText = styled(Text).attrs(({ theme }) => ({
  fontSize: theme.fontSizes.body,
  lineHeight: theme.px(12),
  letterSpacing: 0.4,
  color: theme.textColors.high,
  mb: theme.space[4],
}))``;

export const TipsText = styled(PhraseText).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))``;

export const StyledBox = styled(Box)<{ disabled: boolean }>`
  ${({ disabled }) =>
    !disabled &&
    `
  ${InnerTextInputFieldWrap} {
    background:transparent;
  }
  ${TextInputField} {
    background: transparent;
  }
`}
`;

export const SuggestionInputWrap = styled(Box)`
  ${TextInputField} {
    color: ${({ theme }) => theme.textColors.med};
    pointer-events: none;
  }
  ${InnerTextInputFieldWrap} {
    z-index: 0;
    background-color: white;
  }
`;

export const ActiveInputWrap = styled(Box)`
  ${InnerTextInputFieldWrap} {
    z-index: 1;
  }
`;

export const DropdownMenu = styled(MenuDropdown)``;
