import React from 'react';
import { Flex } from 'components';
import BreakpointGroup from './BreakpointGroup';
import {
  StartLabel,
  StartLabelTop,
  EndLabel,
  EndLabelTop,
  StyledInput,
  SliderWrapper,
  Underlay,
  UnderlayTop,
} from './Slider.styles';

type SliderProps = {
  min?: number;
  max?: number;
  value?: number | string;
  onChange?: (value: any) => void;
  labelStart?: string;
  labelEnd?: string;
  labelStartTop?: string;
  labelEndTop?: string;
  steps?: boolean;
  showBorderLines?: boolean;
  disabled?: boolean;
};

const Slider = ({
  min = 0,
  max = 100,
  value = 0,
  onChange,
  labelStart,
  labelEnd,
  labelStartTop,
  labelEndTop,
  steps = true,
  showBorderLines = false,
  disabled = false,
}: SliderProps) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange && onChange(value);
    },
    [onChange],
  );

  const SliderComponent = (
    <>
      {(labelStartTop || labelEndTop) && (
        <Flex mb={'9px'}>
          {labelStartTop && (
            <StartLabelTop as={'label'}>{labelStartTop}</StartLabelTop>
          )}
          {labelEndTop && <EndLabelTop as={'label'}>{labelEndTop}</EndLabelTop>}
        </Flex>
      )}
      <Flex width={1} flexDirection={'column'}>
        <StyledInput
          data-testid="range-input"
          value={value}
          min={min}
          max={max}
          type="range"
          onChange={handleChange}
          disabled={disabled}
        />
        {(labelStart || labelEnd) && (
          <Flex mt={'11px'}>
            {labelStart && <StartLabel as={'label'}>{labelStart}</StartLabel>}
            {labelEnd && <EndLabel as={'label'}>{labelEnd}</EndLabel>}
          </Flex>
        )}
      </Flex>
    </>
  );

  const SliderStepComponent = (
    <>
      <SliderWrapper data-testid={'breakpoint-test'}>
        {labelStartTop || labelEndTop ? (
          <UnderlayTop>
            <BreakpointGroup total={max - min} showBorders={showBorderLines} />
          </UnderlayTop>
        ) : (
          <Underlay>
            <BreakpointGroup total={max - min} showBorders={showBorderLines} />
          </Underlay>
        )}
        {SliderComponent}
      </SliderWrapper>
    </>
  );

  return steps ? SliderStepComponent : SliderComponent;
};

export default Slider;
