import { differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';
import { Notification } from 'zsbpsdk/proto/ZsbpPortalService_pb';

export const KEY_PREFIX = 'Notification-log-for-';

export enum UnitOfTime {
  Minutes,
  Seconds,
  Days,
  Hours,
}

export const notificationDuration = (notificationTime) => {
  const notificationTimeUNIXTimestamp = Math.floor(+notificationTime / 1000);
  const timeNowUNIXTimestamp = Math.floor(Date.now() / 1000);

  const notificationDate = new Date(notificationTimeUNIXTimestamp * 1000);
  const timeNowDate = new Date(timeNowUNIXTimestamp * 1000);

  const minDiff = differenceInMinutes(timeNowDate, notificationDate);
  const secDiff = differenceInSeconds(timeNowDate, notificationDate);
  const hourDiff = differenceInHours(timeNowDate, notificationDate);
  const daysDiff = differenceInDays(timeNowDate, notificationDate);
  
  if (minDiff === 0) {
    return { time: secDiff, unit: UnitOfTime.Seconds };
  } else if (minDiff > 1440) {
    return { time: daysDiff, unit: UnitOfTime.Days };
  } else if (minDiff > 60) {
    return { time: hourDiff, unit: UnitOfTime.Hours };
  }
  return { time: minDiff, unit: UnitOfTime.Minutes };
};

export const removeDuplicateSavedNotifications = (customerId: string) => {
  //Removing dublicated notifications stored in local storage
  const notificationsForLog = localStorage.getItem(
    getSpecificCustomerKey(customerId),
  );
  if (notificationsForLog) {
    const filteredNotifications = removeDuplicates(notificationsForLog);

    localStorage.setItem(
      getSpecificCustomerKey(customerId),
      JSON.stringify(filteredNotifications),
    );
  }
};

export const removeDuplicates = (notificationsForLog: string) => {
  const parsed = [...JSON.parse(notificationsForLog)];

  const filteredWithID = parsed.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.id === value.id && value.id !== ''),
  );
  const filteredWithoutID = parsed.filter((noti) => noti.id === '');
  return [...filteredWithID, ...filteredWithoutID];
};

export const getSpecificCustomerKey = (customerId: string) => {
  return KEY_PREFIX + customerId;
};

export type ExtendedNotification = Notification.AsObject & {
  img: string;
  title: string;
  detailedMessage: string;
  footerText: string;
  timeoutOnClose?: number;
  isRead: boolean;
  uniqueId: string;
};

// Sort the notification by the created object.
// This will fix an edge case where we receive
// notifications with a wrong order.
// Usually the time differences between these
// notifications is a few nano seconds
export function sortNotifications(notifications: ExtendedNotification[]) {
  return [...notifications].sort((a, b) => {
    if (!(a.created && b.created)) {
      return 0;
    }

    const timeA = a.created.seconds * 1000 + a.created.nanos / 1000000;
    const timeB = b.created.seconds * 1000 + b.created.nanos / 1000000;

    return timeB - timeA;
  });
}
