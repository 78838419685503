import * as React from 'react';
import styled from 'styled-components/macro';
import { Text, Card, Page } from 'components';
import { useTranslation } from 'react-i18next';

const CardHolder = styled(Card)`
  position: relative;
  top: 300px;
  left: 50%;
  width: 70%;
  max-width: 1040px;
  min-width: 1014px;
  background-color: ${({ theme }) => theme.white};
  transform: translateX(-50%);
`;

const CardHolderInner = styled(Card)``;

export const NotFoundWithMenu = (): any => {
  const { t } = useTranslation('other');

  return (
    <Page noBackground={true}>
      <CardHolder flexDirection={'column'} p={56} elevation="one">
        <CardHolderInner mt={20}>
          <Text>{t('Sorry, this page is not available')}</Text>
        </CardHolderInner>
      </CardHolder>
    </Page>
  );
};
