import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import type { PrinterType } from 'zsbpsdk/src/printer';

import { PRINT_PAGE_PATH, HOME_PAGE_PATH } from 'utils/config';
import { useReduxSelector, useReduxAction } from 'hooks';
import { selectAllPrinters } from 'ducks/printers/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import { Flex, Box, Button } from 'components';
import { Text, Radio, RadioGroup } from 'v2/components/common';
import { PrinterStatusChip } from 'v2/components/app';
import { convertPrinterStatus } from 'v2/components/app/PrintersList/utils';

import {
  Container,
  BodyText,
  PrinterImage,
  Table,
  HeadRow,
  Row,
} from './index.styles';

export default function PrintersList() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);
  const printTestLabel = useReduxAction(templatesActions.TEST_PRINT.request);
  const [selectedPrinter, setSelectedPrinter] = useState<PrinterType | null>(
    null,
  );

  const printers: PrinterType[] = useReduxSelector(selectAllPrinters);

  useEffect(() => {
    if (printers.length === 1) {
      printTestLabel({ printer: printers[0], fields: [], copies: 1 });
      pushAction(HOME_PAGE_PATH);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <BodyText>{t('dialogs:print-test-label.select-printer-text')}</BodyText>
      <Table my={8}>
        <HeadRow border pb={5}>
          <Box flex={3} role={'columnheader'}>
            <Text variant={'body'}>{t('common:printer')}</Text>
          </Box>
          <Box flex={1} role={'columnheader'}>
            <Text variant={'body'}>{t('common:status')}</Text>
          </Box>
        </HeadRow>
        <RadioGroup
          name={'printers'}
          value={selectedPrinter}
          onChange={setSelectedPrinter}
        >
          {printers.map((printer) => (
            <Row role={'row'} key={printer.uniqueId}>
              <Box flex={3} role={'cell'}>
                <Flex alignItems={'center'}>
                  <Radio
                    checked={selectedPrinter?.uniqueId === printer.uniqueId}
                    value={printer}
                  />
                  <PrinterImage mx={8}>
                    <img src={printer.avatar} alt={printer.model} />
                  </PrinterImage>
                  <Text variant={'body'}>{printer.name}</Text>
                </Flex>
              </Box>
              <Box flex={1} role={'cell'}>
                <PrinterStatusChip
                  status={convertPrinterStatus(
                    printer.status,
                    printer.cartridgeInfo?.remaininglabels,
                  )}
                />
              </Box>
            </Row>
          ))}
        </RadioGroup>
      </Table>
      <Flex justifyContent={'flex-end'}>
        <Button
          variant={'secondary'}
          mr={9}
          onClick={() => pushAction(PRINT_PAGE_PATH)}
        >
          {t('common:cancel')}
        </Button>
        <Button
          disabled={!selectedPrinter}
          onClick={() => {
            printTestLabel({ printer: selectedPrinter, fields: [], copies: 1 });
            pushAction(HOME_PAGE_PATH);
          }}
        >
          {t('common:print')}
        </Button>
      </Flex>
    </Container>
  );
}
