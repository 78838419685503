import { createSelector } from 'reselect';

const allWorkspaces = (state: any) => state.workspaces.all.data;
const allWorkspacesLoading = (state: any) => state.workspaces.all.loading;

export const currentWorkspaceId = (state: any) =>
  state.workspaces.current.data?.id;

const updategWorkspaceLoading = (state: any) =>
  state.workspaces.update?.loading;
const updategWorkspaceSuccess = (state: any) =>
  state.workspaces.update?.success;

export const getAllWorkspaces = createSelector([allWorkspaces], (workspaces) =>
  workspaces ? workspaces : [],
);

export const getCurrentWorkspaceId = createSelector(
  [currentWorkspaceId],
  (workspaceId) => workspaceId,
);

export const isUpdating = createSelector(
  [updategWorkspaceLoading],
  (loading) => loading,
);
export const isUpdateSuccess = createSelector(
  [updategWorkspaceSuccess],
  (success) => success,
);

export const getAllWorkspacesLoading = createSelector(
  [allWorkspacesLoading],
  (loading) => loading,
);

export const getCurrentWorkspace = createSelector(
  [currentWorkspaceId, getAllWorkspaces],
  (currentWorkspaceId, allWorkspaces) => {
    const workspace = allWorkspaces.find(
      (w: any) => w.id === currentWorkspaceId,
    );
    return workspace
      ? {
          ...workspace,
          theme: workspace.theme ? workspace.theme : 'lucas',
        }
      : {};
  },
);

export const getCurrentWorkspaceName = createSelector(
  [currentWorkspaceId, getAllWorkspaces],
  (currentWorkspaceId, allWorkspaces) => {
    const workspace = allWorkspaces.find(
      (w: any) => w.id === currentWorkspaceId,
    );
    return workspace ? workspace.name : '';
  },
);
