import React from 'react';

type FormProps = {
  onSubmit: () => void;
  children: React.ReactNode;
};

const Form = ({ onSubmit, children }: FormProps) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit();
    }}
  >
    {children}
  </form>
);

export default Form;
