import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Box } from 'components';

const languages = [
  { value: 'en', label: 'English' },
  { value: 'pt', label: 'Portugese' },
  { value: 'bg', label: 'Български' },
];

const LanguageSwitch = () => {
  const { i18n } = useTranslation('common');

  // useEffect(() => {
  //   if (i18n.language && languages.map((el) => el.value).includes(i18n.language)) {
  //     i18n.changeLanguage(i18n.language);
  //   }
  //   i18n.changeLanguage('en');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Box width={'50%'}>
      <Dropdown
        value={i18n.language ?? ''}
        onChange={(lng) => i18n.changeLanguage(lng)}
        options={languages}
        disabled
      />
    </Box>
  );
};

export default LanguageSwitch;
