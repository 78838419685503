export enum HelpChatLabel {
  FirstName = 'First Name',
  LastName = 'Last Name',
  Email = 'Email',
  ProblemCategory = 'Problem Category',
  ProblemDescription = 'Problem Description',
  Country = 'Country',
  ProductModel = 'Product Model',
  PrinterFriendlyName = 'Printer Friendly Name',
  ContactStatus = 'ContactStatus',
  Zuid = 'ZUID',
  ChatBelongsTo = 'Chat belongs to',
}
