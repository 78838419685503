import React from 'react';

import { Box } from 'components';
import Menu, { MenuItem } from 'v2/components/common/Menu';
import { Avatar } from 'v2/components/common';
import { getCustomer, getUser, getAccountLoading } from 'ducks/auth/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { actions as authActions } from 'ducks/auth/actions';
import { push } from 'connected-react-router';
import IconButton from 'v2/components/common/IconButton/IconButton';
import TooltipBottom from 'v2/components/common/ToolTip/ToolTipBottom';

import { HOME_PAGE_PATH, MY_ACCOUNT_SETTINGS_PATH } from 'utils/config';

import {
  AvatarWrap,
  StyledBar,
  StyledContent,
  StyledLink,
  StyledLoader,
} from './TopNavigation.styles';
import 'utils/localStorageItemConverter';
import { useLiveChat } from 'providers/LiveChat';
import NotificationButton from './NotificationButton';

const TopNavigation = () => {
  const { t } = useTranslation(['components', 'menu']);
  const user = useReduxSelector(getUser);
  const customer = useReduxSelector(getCustomer);
  const accountIsLoading = useReduxSelector(getAccountLoading);
  const pushAction = useReduxAction(push);
  const logoutRequest = useReduxAction(authActions.LOGOUT);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorUserRef = React.useRef(null);

  const { init, activateChat, isOffline, isLoading } = useLiveChat();

  return (
    <StyledBar data-testid="app-bar">
      <StyledContent>
        <TooltipBottom
          enabled={true}
          content={t('menu:home')}
          direction="bottom"
          marginLeft={8}
        >
          <IconButton
            icon="home"
            onClick={() => pushAction(HOME_PAGE_PATH)}
            data-testid="home-button"
          />
        </TooltipBottom>
        <Box style={{ flexGrow: 2 }}></Box>
        <NotificationButton />
        <TooltipBottom
          enabled={true}
          active={true}
          content={t('menu:help')}
          direction="bottom"
          marginLeft={12}
        >
          <IconButton
            ref={anchorRef}
            icon="help-filled"
            data-testid="help-menu-button"
            onClick={() => {
              init();
              setOpenMenu((prevOpen) => !prevOpen);
            }}
          />
        </TooltipBottom>
        <Menu
          minWidth={200}
          open={openMenu}
          anchorEl={anchorRef.current}
          onClose={() => setOpenMenu(false)}
          data-testid="open-menu-button"
        >
          <StyledLink href={'https://zsbsupport.zebra.com/s/'}>
            <MenuItem
              icon="helpMenuItem"
              title={t('components:menu.support')}
              data-testid="help-menu-support"
            />
          </StyledLink>
          <StyledLink href={'https://zsbsupport.zebra.com/s/faqs'}>
            <MenuItem
              icon={'helpMenuItem'}
              title={t('components:menu.faqs')}
              data-testid="help-menu-faqs"
            />
          </StyledLink>
          <StyledLink href={'https://zsbsupport.zebra.com/s/contactsupport'}>
            <MenuItem
              icon={'helpMenuItem'}
              title={t('components:menu.contact-us')}
              data-testid="help-menu-contact-us"
            />
          </StyledLink>
          <MenuItem
            icon={'chat'}
            title={
              !isLoading && isOffline
                ? t('components:menu.live-chat-offline')
                : t('components:menu.live-chat')
            }
            data-testid="help-menu-live-chat"
            disabled={isOffline || isLoading}
            onClick={activateChat}
            rightAdornment={
              isLoading && <StyledLoader width="16px" height="16px" />
            }
          />
        </Menu>
        <TooltipBottom
          enabled={true}
          active={true}
          content={t('menu:settings')}
          direction="bottom"
        >
          <IconButton
            icon="cog-filled"
            onClick={() => pushAction('/settings')}
            data-testid="printer-settings-button"
          />
        </TooltipBottom>
        <TooltipBottom
          enabled={true}
          active={true}
          content={t('menu:account')}
          direction="bottom"
        >
          <AvatarWrap
            ref={anchorUserRef}
            onClick={() => setOpenUserMenu((prevOpen) => !prevOpen)}
            data-testid="user-menu"
          >
            <Avatar
              variant={'small'}
              firstName={
                accountIsLoading ? '' : user?.firstName ?? customer?.firstName
              }
              lastName={user?.lastName}
              img={customer?.avatar}
              data-testid="user-menu-avatar"
            />
          </AvatarWrap>
        </TooltipBottom>
        <Menu
          open={openUserMenu}
          anchorEl={anchorUserRef.current}
          onClose={() => setOpenUserMenu(false)}
          data-testid="user-menu-open"
        >
          <MenuItem
            title={t('settings:account-settings')}
            onClick={() => pushAction(MY_ACCOUNT_SETTINGS_PATH)}
            data-testid="user-menu-account-settings"
          />
          <MenuItem
            title={t('settings:log-out')}
            onClick={() => logoutRequest()}
            data-testid="user-menu-logout"
          />
        </Menu>
      </StyledContent>
    </StyledBar>
  );
};

export default TopNavigation;
