import { StyledBox } from './PrinterStatusChip.styles';

const OnlineIcon = () => {
  return (
    <StyledBox>
      <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.01443 5.00469C1.43943 5.42969 2.11443 5.47135 2.57276 5.08802C6.46443 1.88802 12.1144 1.88802 16.0144 5.07969C16.4811 5.46302 17.1644 5.42969 17.5894 5.00469C18.0811 4.51302 18.0478 3.69635 17.5061 3.25469C12.7478 -0.636979 5.86443 -0.636979 1.09776 3.25469C0.556093 3.68802 0.514426 4.50469 1.01443 5.00469ZM7.48109 11.4714L8.70609 12.6964C9.03109 13.0214 9.55609 13.0214 9.88109 12.6964L11.1061 11.4714C11.4978 11.0797 11.4144 10.4047 10.9144 10.1464C9.89776 9.62135 8.68109 9.62135 7.65609 10.1464C7.18109 10.4047 7.08943 11.0797 7.48109 11.4714ZM4.37276 8.36302C4.78109 8.77135 5.42276 8.81302 5.89776 8.47136C7.93109 7.02969 10.6644 7.02969 12.6978 8.47136C13.1728 8.80469 13.8144 8.77135 14.2228 8.36302L14.2311 8.35469C14.7311 7.85469 14.6978 7.00469 14.1228 6.59635C11.2561 4.52135 7.34776 4.52135 4.47276 6.59635C3.89776 7.01302 3.86443 7.85469 4.37276 8.36302Z"
          fill="#00864F"
        />
      </svg>
    </StyledBox>
  );
};

export default OnlineIcon;
