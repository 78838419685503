import { useState, useLayoutEffect } from 'react';

export default function useRefDimensions(
  ref: React.RefObject<any>,
  deps: any[],
) {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      if (!ref.current) {
        return;
      }

      setContainerDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    };
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);

  return containerDimensions;
}
