import React, { useEffect, useState } from 'react';
import { Flex, ToggleSwitch, Text, Loader } from 'components';

import styled, { ThemeContext } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { actions as preferencesActions } from 'ducks/preferences/actions';
import {
  useReduxSelector,
  useReduxAction,
  useLocalStorage,
  useAppSelector,
  useAppDispatch,
} from 'hooks';
import {
  getAllPreferences,
  getAllPreferencesLoading,
  getCartridgelow,
  getPrintjobs,
} from 'ducks/preferences/selectors';
import { getBannerMessageSettingKey } from 'utils/bannerMessages';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { CustomerInfoType } from 'zsbpsdk/src/customer';
import { getCustomer } from 'ducks/auth/selectors';
import { openBasicToast } from 'state/Toast';

const StyledToggleSwitch = styled(ToggleSwitch)`
  margin-bottom: 24px;
`;

const Title = styled(Text)`
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
`;

export const Notifications = () => {
  const { t } = useTranslation(['notifications', 'utils']);
  const theme = React.useContext(ThemeContext);
  const customer: CustomerInfoType = useAppSelector(getCustomer);
  const printjobs = useReduxSelector(getPrintjobs);
  const cartridgelow = useReduxSelector(getCartridgelow);
  const prefs = useReduxSelector(getAllPreferences);
  const isLoadingPrefs = useReduxSelector(getAllPreferencesLoading);
  const updatePreferences = useReduxAction(preferencesActions.UPDATE.request);
  const [printJobsLocal, setPrintJobsLocal] = useState(false);
  const [cartridgeLowLocal, setCartridgeLowLocal] = useState(false);
  const [isLoadingPreferences, setIsLoadingPreferences] = useState(true);
  const [prefsLocal, setPrefsLocal] = useState<any>({});
  const dispatch = useAppDispatch();

  const [upcomingFeaturesEnabled, setUpcomingFeaturesEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.UPCOMING_FEATURES,
      customer,
    ),
    true,
  );
  const [newFeaturesEnabled, setNewFeaturesEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.NEW_FEATURES,
      customer,
    ),
    true,
  );
  const [promotionsEnabled, setPromotionsEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.PROMOTIONAL,
      customer,
    ),
    true,
  );
  const [maintenanceEnabled, setMaintenanceEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.MAINTENANCE,
      customer,
    ),
    true,
  );
  const [remindersEnabled, setRemindersEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.REMINDERS,
      customer,
    ),
    true,
  );

  useEffect(() => {
    setPrefsLocal(prefs);
  }, [prefs]);

  useEffect(() => {
    setIsLoadingPreferences(isLoadingPrefs);
  }, [isLoadingPrefs]);

  useEffect(() => {
    if (!isLoadingPreferences) {
      setPrintJobsLocal(printjobs);
    }
  }, [isLoadingPreferences, printjobs]);

  useEffect(() => {
    if (!isLoadingPreferences) {
      setCartridgeLowLocal(cartridgelow);
    }
  }, [isLoadingPreferences, cartridgelow]);

  const savePrefsPrintJobs = (printjobs: boolean) => {
    updatePreferences({
      data: {
        ...prefs,
        units: prefs.units.grpcUnit,
        notificationpreference: {
          ...prefs.notificationpreference,
          printjobs,
        },
      },
      message: t('notifications:preferences-updated'),
    });
    setPrintJobsLocal(printjobs);
  };

  const openToast = () => {
    dispatch(openBasicToast(t('notifications:preferences-updated')));
  };

  const savePrefsCartridgeLow = (cartridgelow: boolean) => {
    updatePreferences({
      data: {
        ...prefsLocal,
        units: prefsLocal.units.grpcUnit,
        notificationpreference: {
          ...prefsLocal.notificationpreference,
          cartridgelow,
        },
      },
      message: t('notifications:preferences-updated'),
    });
    setCartridgeLowLocal(cartridgelow);
  };

  return (
    <>
      {isLoadingPreferences ? (
        <Loader visible={true} />
      ) : (
        <Flex flexDirection={'column'}>
          <Title mb={4}>{t('notifications:settings')}</Title>
          <Flex flexDirection={'row'} mb={'32px'}>
            <Text
              data-testid="notification-settings-description"
              fontSize={14}
              lineHeight={'30px'}
              fontWeight={'400'}
              letterSpacing={0.4}
              color={theme.textColors.med}
            >
              {t('notifications:on-off')}
            </Text>
          </Flex>
          <Text
            data-testid="notification-printers-title"
            fontSize={20}
            lineHeight={'30px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
          >
            {t('notifications:notify-me-when')}
          </Text>
          <StyledToggleSwitch
            data-testid="documents-printed-switch"
            aria-label={'printjobs'}
            value={'printjobs'}
            label={t('notifications:documents-are-printed')}
            defaultChecked={printJobsLocal}
            onChange={(e) => {
              savePrefsPrintJobs(!printJobsLocal);
            }}
          />
          <StyledToggleSwitch
            data-testid="printer-low-empty-switch"
            value={'cartridgelow'}
            label={t('notifications:printer-is-low-and-empty')}
            defaultChecked={cartridgeLowLocal}
            onChange={(e) => {
              savePrefsCartridgeLow(!cartridgeLowLocal);
            }}
          />
          <Text
            data-testid="notification-features-title"
            fontSize={20}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
          >
            {t('notifications:features')}
          </Text>

          <StyledToggleSwitch
            data-testid="notifications-upcoming-features"
            value={upcomingFeaturesEnabled ? 'upcomingFeaturesEnabled' : ''}
            label={t('notifications:upcoming-features')}
            defaultChecked={upcomingFeaturesEnabled}
            onChange={(e) => {
              setUpcomingFeaturesEnabled(!upcomingFeaturesEnabled);
              openToast();
            }}
          />
          <StyledToggleSwitch
            data-testid="notifications-new-features"
            value={newFeaturesEnabled ? 'newFeaturesEnabled' : ''}
            label={t('notifications:new-features')}
            defaultChecked={newFeaturesEnabled}
            onChange={(e) => {
              setNewFeaturesEnabled(!newFeaturesEnabled);
              openToast();
            }}
          />
          <StyledToggleSwitch
            data-testid="notifications-promotions"
            value={promotionsEnabled ? 'promotionsEnabled' : ''}
            label={t('notifications:promotions')}
            defaultChecked={promotionsEnabled}
            onChange={(e) => {
              setPromotionsEnabled(!promotionsEnabled);
              openToast();
            }}
          />
          <Text
            data-testid="notification-messages-description"
            fontSize={20}
            lineHeight={'30px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
          >
            {t('notifications:messages')}
          </Text>
          <StyledToggleSwitch
            data-testid="notifications-reminders-enabled"
            value={remindersEnabled ? 'remindersEnabled' : ''}
            label={t('notifications:reminders')}
            defaultChecked={remindersEnabled}
            onChange={(e) => {
              setRemindersEnabled(!remindersEnabled);
              openToast();
            }}
          />
          <StyledToggleSwitch
            data-testid="notifications-maintenance-enabled"
            value={maintenanceEnabled ? 'maintenanceEnabled' : ''}
            label={t('notifications:maintenance')}
            defaultChecked={maintenanceEnabled}
            onChange={(e) => {
              setMaintenanceEnabled(!maintenanceEnabled);
              openToast();
            }}
          />
        </Flex>
      )}
    </>
  );
};
