import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

import styled from 'styled-components/macro';

import { HOME_PAGE_PATH } from 'utils/config';
import { useAppDispatch, useReduxSelector, useReduxAction } from 'hooks';
import { actions as infoActions } from 'ducks/info/actions';
import { selectPrinterTools } from 'ducks/info/selectors';
import { Flex, Box, Loader } from 'components';
import { Text, PageTitle } from 'v2/components/common';
import { openErrorToast } from 'state/Toast';

import DriverCard from './DriverCard';
import Page from '../Page';

const LightText = styled(Text)`
  color: ${({ theme }) => theme.textColors.med};
`;

function DriversPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pushAction = useReduxAction(push);
  const getTools = useReduxAction(infoActions.PRINTER_TOOLS.request);
  const {
    data: driverInfo,
    loading: isLoading,
    error,
  } = useReduxSelector(selectPrinterTools);

  useEffect(() => {
    getTools();
    //Always scroll to top on mount
    window.scrollTo(0, 0);
  }, [getTools]);

  // @TODO Move this into the saga for fetching the printer tools data
  useEffect(() => {
    if (error) {
      dispatch(openErrorToast(t('apps:could-not-retrieve-apps')));
    }
  }, [dispatch, error, t]);

  return (
    <Page>
      <Flex flexDirection={'column'}>
        <PageTitle
          title={t('menu:install-drivers')}
          onBackClick={() => pushAction(HOME_PAGE_PATH)}
        />
        <Box mb={12}>
          <LightText data-testid="printer-driver-and-apps-info">
            {t('apps:printer-driver-and-apps')}
          </LightText>
        </Box>
        <Box mb={12}>
          <LightText data-testid="click-to-download-info">
            {t('apps:click-to-download')}
          </LightText>
        </Box>

        {isLoading ? (
          <Loader visible={isLoading} />
        ) : (
          <>
            <Box>
              <Box mb={8}>
                <LightText variant={'h2'} data-testid="windows-apps-title">
                  {t('apps:windows')}
                </LightText>
              </Box>
              <DriverCard os={'Windows'} driverInfo={driverInfo?.windows} />
            </Box>
            <Box mt={12}>
              <Box mb={8}>
                <LightText variant={'h2'} data-testid="apple-apps-title">
                  {t('apps:apple')}
                </LightText>
              </Box>
              <DriverCard os={'Mac'} driverInfo={driverInfo?.mac} />
            </Box>
          </>
        )}
      </Flex>
    </Page>
  );
}

export default DriversPage;
