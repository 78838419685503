import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  LabelRangePicker,
  Text,
} from 'components/index';
import { useReduxAction, useReduxSelector } from 'hooks';
import { selectedTemplate } from 'ducks/templates/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import styled from 'styled-components/macro';
import { getUnit } from 'ducks/preferences/selectors';
import { getEtch } from 'utils/unitFormatter';
import { useTranslation } from 'react-i18next';
import { usePrintDialog } from '../PrintDialogContext';

const Wrapper = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
`;

const Heading = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.lighter};
`;

const Footer = styled(Flex)`
  border-top: solid 1px ${({ theme }) => theme.neutral.lighter};
  justify-content: flex-end;
`;

const BackIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'chevron-left',
  color: theme.neutral.base,
  size: 14,
}))``;

const BackButton = styled(Box)`
  cursor: pointer;
  font-size: 0;
  border: 0 none;
  background: transparent;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ScrollableContent = styled(Box)`
  flex-grow: 1;
  overflow: auto;
`;

const PrintDialogLabelRangeStep = ({ onBack }) => {
  const { t } = useTranslation(['common', 'components']);
  const [{ selectedLabels }, { setSelectedLabels }] = usePrintDialog();

  const [unconfirmedSelectedLabels, setUnconfirmedSelectedLabels] = useState<
    number[]
  >(selectedLabels ?? []);

  const templateForPrinting = useReduxSelector(selectedTemplate);
  const clearJobId = useReduxAction(templatesActions.PRINT.clear_id);
  const unit = useReduxSelector(getUnit);
  const etch = getEtch(
    templateForPrinting.labelSize,
    unit,
    templateForPrinting.orientation,
  );

  const handleSelectionChange = useCallback(
    (selection) => {
      setUnconfirmedSelectedLabels(selection);
    },
    [setUnconfirmedSelectedLabels],
  );

  const confirmHandler = useCallback(() => {
    clearJobId();
    setSelectedLabels(unconfirmedSelectedLabels);
    onBack();
  }, [clearJobId, onBack, setSelectedLabels, unconfirmedSelectedLabels]);

  return (
    <Wrapper>
      <Heading height={80} px={10} py={14}>
        <BackButton
          data-testid={'close-button'}
          as={'button'}
          onClick={() => {
            clearJobId();
            onBack();
          }}
        >
          <BackIcon />
        </BackButton>
        <Text fontSize={24} fontWeight={300}>
          {templateForPrinting && templateForPrinting.name
            ? `${templateForPrinting.name} - ${`${etch}`}`
            : t('components:printer.messages.unknown-template')}
        </Text>
      </Heading>
      <ScrollableContent p={20}>
        <LabelRangePicker onSelectionChange={handleSelectionChange} />
      </ScrollableContent>
      <Footer px={19} py={9}>
        <Button
          disabled={!unconfirmedSelectedLabels.length}
          onClick={confirmHandler}
        >
          {t('common:confirm')}
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default PrintDialogLabelRangeStep;
