import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Flex, Icon } from 'components';
import IconType from 'components/lib/icon/IconType';
import { Paper, Text } from 'v2/components/common';

const Container = styled(Paper).attrs({ elevation: 'card', borderRadius: 4 })`
  display: flex;
  align-items: center;
  height: 123px;
  padding: ${({ theme }) => theme.px(8)};
  cursor: pointer;
  ${space}
`;

const ImageWrap = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;

  background-color: #f1f8ff;
`;

const CaptionText = styled(Text).attrs({ variant: 'caption' })`
  color: ${({ theme }) => theme.textColors.med};
`;

type CardProps = {
  title: string;
  caption: string;
  icon: IconType;
  onClick?: React.MouseEventHandler;
} & SpaceProps;

export default function Card({ title, caption, icon, ...props }: CardProps) {
  return (
    <Container {...props}>
      <ImageWrap>
        <Icon size={25} icon={icon} />
      </ImageWrap>
      <Flex flexDirection={'column'} ml={8}>
        <Text variant={'h3'} pb={2}>
          {title}
        </Text>
        <CaptionText>{caption}</CaptionText>
      </Flex>
    </Container>
  );
}
