import React, { useState, useCallback, useContext } from 'react';
import { Flex, Text } from 'components';
import styled from 'styled-components/macro';
import { useReduxAction } from 'hooks';
import themes from 'utils/demo/themes.json';
import { device } from 'utils/mediaQueries';
import { actions as workspaceActions } from 'ducks/workspaces/actions';
import { ThemeContext } from 'styled-components/macro';

const StyledListWrap = styled(Flex)`
  width: 100%;
`;

const StyledItemWrap = styled.a`
  margin: 0 24px 40px 0;
  width: 100%;
  max-width: 432px;
  text-decoration: none;

  &:nth-child(even) {
    margin-right: 0;
  }

  @media ${device.desktopWide} {
    width: 48.5%;
  }
`;

const StyledCard = styled(Flex)`
  overflow: hidden;
  height: 240px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  box-shadow: ${({ theme }) => theme.elevations.one};
  transition: border-color .3s;
  box-shadow: ${({ theme, selected }: any) =>
    selected
      ? `0px 0px 0px 2px ${theme.primary.base};`
      : `0px 0px 0px 1px ${theme.textColors.low};`}
  ransition: box-shadow .3s;
`;

const StyledImageWrap = styled.div`
  img {
    min-width: 360px;
    max-width: 360px;
  }
`;

const StyledCheckmark = styled.div`
  position: relative;
  margin: 0 12px 0 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: ${({ theme, selected }: any) =>
    selected
      ? `1px solid ${theme.primary.base};`
      : `1px solid ${theme.neutral.lighter};`}
  transition: border .3s;

  &::after {
    content: '';
    position: absolute;
    opacity: ${({ selected }: any) => (selected ? `1;` : `0;`)}
    top: 6px;
    left: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary.base};
    transition: opacity .3s;
  }
`;

export const ThemesList = ({ children, onClick, workspace, ...props }: any) => {
  const updateWorkspace = useReduxAction(workspaceActions.UPDATE.request);
  const [selected, setSelected] = useState(undefined);
  const appTheme = useContext(ThemeContext);

  const handleSelected = useCallback(
    (e: any, id: any) => {
      e.preventDefault();
      setSelected(id);
      updateWorkspace({ ...workspace, theme: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, workspace, updateWorkspace],
  );

  return (
    <StyledListWrap flexWrap="wrap">
      {themes.map((theme, i) => (
        <StyledItemWrap
          key={i}
          href="#"
          onClick={(e) => handleSelected(e, theme.name)}
        >
          <Flex flexDirection="column">
            <StyledCard
              flexDirection="row"
              mb={8}
              {...props}
              selected={selected === theme.id}
            >
              <img src={theme.image} alt="" />
              <StyledImageWrap>
                <img src={theme.header_image} alt="" />
              </StyledImageWrap>
            </StyledCard>
            <Flex flexDirection="row" alignItems="center">
              <StyledCheckmark {...props} selected={selected === theme.name} />
              <Text fontSize={14} color={appTheme.textColors.high}>
                {theme.name}
              </Text>
            </Flex>
          </Flex>
        </StyledItemWrap>
      ))}
    </StyledListWrap>
  );
};
