import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUrlParam, useReduxSelector } from 'hooks';
import { selectCategoriesWithLabel } from 'ducks/categories/selectors';
import { Loader } from 'components';
import { ErrorText } from 'v2/components/common';

import { CategoriesWrap, StyledCategoryCard } from './CommonDesignsPage.styles';

export default function CategoriesList({
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  const [, setSelectedCategory] = useUrlParam('category', '');
  const { data, loading, error } = useReduxSelector(selectCategoriesWithLabel);

  if (error) {
    return (
      <ErrorText variant="h2" mb={8}>
        {t('templates:server-error')}
      </ErrorText>
    );
  }

  if (loading) {
    return <Loader visible />;
  }

  if (!data) {
    return null;
  }

  return (
    <CategoriesWrap {...props}>
      {data.map(({ category, label }) => (
        <StyledCategoryCard
          onClick={() => setSelectedCategory(category.name)}
          key={category.id}
          name={category.name}
          desc={category.desc}
          naPartNumber={label?.naPartNumber}
          emeaPartNumber={label?.emeaPartNumber}
        />
      ))}
    </CategoriesWrap>
  );
}
