import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'store';

const NAMESPACE = 'SMB_DIALOG';
const getName = (name: string) => `${NAMESPACE}/${name}`;

type DialogState = {
  type: string;
  props: Record<string, any>;
};

const initialState: DialogState = {
  type: '',
  props: {},
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    openDialog(state, action: PayloadAction<DialogState>) {
      state.type = action.payload.type;
      state.props = { ...action.payload.props, open: true };
    },
    closeDialog() {
      return initialState;
    },
  },
});

export const { openDialog, closeDialog } = slice.actions;

export const cancelClicked = createAction(getName('cancelClicked'));
export const confirmClicked = createAction<any>(getName('confirmClicked'));

export default slice.reducer;

const getState = (state: RootState) => state.dialog;

export const selectDialog = createSelector([getState], (state) => state);
