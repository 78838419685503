import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { useReduxAction } from 'hooks';
import PrivateRoute from 'components/app/PrivateRoute/PrivateRoute';
import { HOME_PAGE_PATH } from 'utils/config';

import PrintPage from './PrintPage';
import ChooseTemplate from './ChooseTemplate';
import MyLabels from './MyLabels/MyLabels';
import RecentlyPrintedPage from './RecentlyPrinted/RecentlyPrinted';
import PrintTestLabel from './PrintTestLabel';

import { CloseIcon, CloseButton, StyledModal } from './Dialog.styles';

export default function Routes() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);
  let { path } = useRouteMatch();

  return (
    <StyledModal
      open
      data-testid="print-label-dialog"
      ariaLabel={t('dialogs:print-label.title')}
      isAnimatedBackdrop={false}
      zIndex={1002}
      role={'dialog'}
    >
      <CloseButton
        data-testid={'close-btn'}
        onClick={() => pushAction(HOME_PAGE_PATH)}
      >
        <CloseIcon />
      </CloseButton>
      <Switch>
        <PrivateRoute exact path={path} component={PrintPage} />
        <PrivateRoute
          path={`${path}/common-designs`}
          component={ChooseTemplate}
        />
        <PrivateRoute path={`${path}/my-designs`} component={MyLabels} />
        <PrivateRoute path={`${path}/recent`} component={RecentlyPrintedPage} />
        <PrivateRoute path={`${path}/test`} component={PrintTestLabel} />
      </Switch>
    </StyledModal>
  );
}
