import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Tooltip } from 'components/index';

import {
  StyledItem,
  Badge,
  BadgeWrapper,
  MenuItemProps,
  StyledIcon,
  StyledText,
} from './MenuItem.styles';

const MenuItem = ({
  collapsed,
  children,
  href,
  onClick,
  tooltipText,
  iconName,
  badge = 0,
  selected,
  ...props
}: React.PropsWithChildren<MenuItemProps>) => {
  const history = useHistory();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      onClick && onClick(event);
      href && history.push(href);
    },
    [history, href, onClick],
  );

  return (
    <StyledItem
      {...props}
      selected={selected}
      href={href}
      onClick={handleClick}
      collapsed={collapsed}
    >
      <Tooltip
        enabled={collapsed && tooltipText}
        content={tooltipText ?? ''}
        direction="right"
      >
        <BadgeWrapper>
          {iconName && (
            <Box>
              {badge > 0 && <Badge>{badge}</Badge>}
              <StyledIcon icon={iconName} />
            </Box>
          )}
        </BadgeWrapper>
      </Tooltip>
      {!collapsed && (
        <StyledText selected={selected}>
          {children?.toString() ?? ''}
        </StyledText>
      )}
    </StyledItem>
  );
};

export default MenuItem;
