import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'components';
import { PrinterType } from 'zsbpsdk/src/printer';
import { useCarousel } from './CarouselContext';

const Slide = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;

  && {
    img {
      display: block;
      margin: 0 auto;
      width: auto;
      height: 120px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &.fade-in {
        opacity: 1;
        transition: opacity 0.2s 0.5s ease-in-out;
      }
    }
  }
`;

const PrinterSlide = ({
  self,
  printer,
}: {
  self: number;
  printer: PrinterType;
}) => {
  const [{ current }] = useCarousel();

  return (
    <Slide>
      <Flex>
        <Box mt={20} mb={23} width={'100%'}>
          <img
            data-testid="current-printer-image"
            className={current === self ? 'fade-in' : ''}
            src={printer?.avatar}
            width="220"
            alt=""
          />
        </Box>
      </Flex>
    </Slide>
  );
};

export default PrinterSlide;
