import React from 'react';
import Arrow from './Arrow';
import Dots from './Dots';
import CarouselProvider, { useCarousel } from './CarouselContext';
import { CarouselHolder, CarouselContent } from './Carousel.styles';
import PrinterSlide from './PrinterSlide';

const DEFAULT_TRANSITION: number = 0.45;
interface CarouselProps {
  transition?: number;
  onUpdate?: (numb: number) => void;
  currentIndex?: number;
}

const CarouselRender = ({
  transition,
  children,
  ...props
}: React.PropsWithChildren<CarouselProps>) => {
  const [{ current, total, onUpdate }] = useCarousel();

  React.useEffect(() => {
    if (onUpdate && current !== undefined) onUpdate(current);
  }, [current, onUpdate]);

  if (total === undefined || total === 0) return null;
  if (current === undefined) return null;

  return (
    <CarouselHolder {...props}>
      <CarouselContent
        translate={(100 / total) * current}
        transition={transition || DEFAULT_TRANSITION}
        width={100 * total}
      >
        {children}
      </CarouselContent>
      {total && (
        <React.Fragment>
          <Arrow direction="left" />
          <Arrow direction="right" />
          <Dots />
        </React.Fragment>
      )}
    </CarouselHolder>
  );
};

const Carousel = ({
  transition,
  children,
  onUpdate,
  currentIndex,
}: React.PropsWithChildren<CarouselProps>) => {
  const total = React.Children.count(children);
  return (
    <CarouselProvider total={total} onUpdate={onUpdate} current={currentIndex}>
      {<CarouselRender transition={transition}>{children}</CarouselRender>}
    </CarouselProvider>
  );
};

Carousel.PrinterSlide = PrinterSlide;

export default Carousel;
