import React from 'react';

type VideoProps = {
  autoPlay?: boolean;
  path: string;
};

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({ path, autoPlay = true }, ref) => (
    <video muted={true} autoPlay={autoPlay} preload="true" ref={ref}>
      <source src={path} type="video/mp4" />
    </video>
  ),
);

export default Video;
