import { put, takeLatest, takeLeading, select, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  types as modalDialogTypes,
  actions as modalDialogActions,
} from 'ducks/modalDialog/actions';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { types as miscTypes } from 'ducks/misc/actions';
import {
  types as printDialogTypes,
  actions as printDialogActions,
} from 'ducks/printDialog/actions';
import { getQuery } from 'ducks/router/selectors';

import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { getTenantId } from './templates';
import { overwriteFile } from '../services/internalDb';
import { openBasicToast, openErrorToast } from '../state/Toast';
import i18n from '../i18n';
import { overwriteModalDialogData } from '../ducks/modalDialog/selectors';
import { apiCall } from './utils';

function* handleConfirmedFileOverwriting({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const file = payload ? payload : yield select(overwriteModalDialogData);

    yield apiCall(overwriteFile, file, customer.id, workspaceId);
    yield put(dataSourcesActions.ADD.success(file));

    const { search = '' } = yield select(getQuery);
    yield put(dataSourcesActions.FILTERED.request({ search }));
    yield put(openBasicToast(i18n.t(`dataSources:overwrite-file-success`)));
  } catch (err: any) {
    yield put(openErrorToast(err.message));
  } finally {
    yield put(modalDialogActions.OVERWRITE_FILE.close());
  }
}

function* handleApiTimeout() {
  yield put(modalDialogActions.OUTAGE.open(null));
  yield take(modalDialogTypes.OUTAGE.CLOSE);
}

//When the PrintDialog is showed start listening for either
//history goBack or PprintDialog close actions.
//If the action was triggered by the browser
//back button then explixitly close the print dialog.
function* handlePrintDialogOpen() {
  const action = yield take([
    (action: any) =>
      action.type === LOCATION_CHANGE && action.payload.action === 'POP',
    printDialogTypes.CLOSE,
  ]);

  if (action.type === LOCATION_CHANGE) {
    yield put(printDialogActions.CLOSE());
  }
}

export default function* watcher() {
  yield takeLatest(
    modalDialogTypes.OVERWRITE_FILE.CONFIRM,
    handleConfirmedFileOverwriting,
  );
  yield takeLeading(miscTypes.API_TIMEOUT, handleApiTimeout);
  yield takeLeading(printDialogTypes.OPEN, handlePrintDialogOpen);
}
