import { StyledBox } from './PrinterStatusChip.styles';

const CoverOpenIcon = () => {
  return (
    <StyledBox>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.9974 4.83073C9.45573 4.83073 9.83073 5.20573 9.83073 5.66406V8.9974C9.83073 9.45573 9.45573 9.83073 8.9974 9.83073C8.53906 9.83073 8.16406 9.45573 8.16406 8.9974V5.66406C8.16406 5.20573 8.53906 4.83073 8.9974 4.83073ZM8.98906 0.664062C4.38906 0.664062 0.664062 4.3974 0.664062 8.9974C0.664062 13.5974 4.38906 17.3307 8.98906 17.3307C13.5974 17.3307 17.3307 13.5974 17.3307 8.9974C17.3307 4.3974 13.5974 0.664062 8.98906 0.664062ZM8.9974 15.6641C5.31406 15.6641 2.33073 12.6807 2.33073 8.9974C2.33073 5.31406 5.31406 2.33073 8.9974 2.33073C12.6807 2.33073 15.6641 5.31406 15.6641 8.9974C15.6641 12.6807 12.6807 15.6641 8.9974 15.6641ZM9.83073 13.1641H8.16406V11.4974H9.83073V13.1641Z"
          fill="#AE6500"
        />
      </svg>
    </StyledBox>
  );
};

export default CoverOpenIcon;
