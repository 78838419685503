import { useCallback, useMemo, SetStateAction } from 'react';

import { usePrintDialog } from 'components/app/print-dialog/PrintDialogContext';
import { Checkbox } from 'components';

import { Row } from './types';

type CustomSelectAllCheckbox = {
  labelRangeRowSearchResults: Row[];
  labelRangeRowSelection: Row[];
  setLabelRangeRowSelection: (labelRangeRows: SetStateAction<Row[]>) => void;
};

const SelectAllCheckbox = ({
  labelRangeRowSearchResults: allLabelRangesRaw,
  labelRangeRowSelection: unconfirmedSelectedLabels,
  setLabelRangeRowSelection: setUnconfirmedSelectedLabels,
}: CustomSelectAllCheckbox) => {
  const [{ firstRowIsHeader }] = usePrintDialog();

  const rowMap = useMemo(
    () => new Map(allLabelRangesRaw.map((row) => [row.key, row])),
    [allLabelRangesRaw],
  );

  const selectAllState = unconfirmedSelectedLabels.every((x) => {
    const row = rowMap.get(x.key);

    if (firstRowIsHeader && x.key === 0) return true;

    return row ? x.checked : x;
  });

  const handleSelectAllClick = useCallback(() => {
    if (!selectAllState) {
      const labels = unconfirmedSelectedLabels.map((x) => {
        const row = rowMap.get(x.key);

        if (firstRowIsHeader && x.key === 0) {
          return x;
        }

        return row ? { ...x, checked: true } : x;
      });

      setUnconfirmedSelectedLabels(labels);
    } else {
      const labels = unconfirmedSelectedLabels.map((x) => {
        const row = rowMap.get(x.key);

        if (firstRowIsHeader && x.key === 0) {
          return x;
        }

        return row ? { ...x, checked: false } : x;
      });

      setUnconfirmedSelectedLabels(labels);
    }
  }, [
    selectAllState,
    firstRowIsHeader,
    unconfirmedSelectedLabels,
    setUnconfirmedSelectedLabels,
    rowMap,
  ]);

  return <Checkbox onChange={handleSelectAllClick} checked={selectAllState} />;
};

export default SelectAllCheckbox;
