import React, { useEffect } from 'react';
import useMethods, { StateAndCallbacksFor } from 'use-methods';

type CarouselState = {
  total?: number;
  current?: number;
  onUpdate?: (numb: number) => void;
};

const INITIAL_STATE: CarouselState = {};
type Context = StateAndCallbacksFor<typeof methods>;
const CarouselContext = React.createContext<Context | null>(null);

const methods = (state: CarouselState) => ({
  reset() {
    return INITIAL_STATE;
  },
  setCurrent(index: number) {
    state.current = index;
  },
  setTotal(n: number) {
    state.total = n;
  },
  next() {
    if (
      state.current !== undefined &&
      state.total !== undefined &&
      state.total - 1 > state.current
    ) {
      state.current++;
    }
  },
  prev() {
    if (state.current !== undefined && state.current !== 0) {
      state.current--;
    }
  },
});

export const CarouselProvider = ({
  children,
  total,
  current,
  onUpdate,
}: React.PropsWithChildren<CarouselState>) => {
  const initState: CarouselState = {
    total,
    current: current || 0,
    onUpdate,
  };
  const [reducerState, callbacks] = useMethods(methods, initState);
  return (
    <CarouselContext.Provider value={[reducerState, callbacks]}>
      <SyncTotalCount total={total}>{children}</SyncTotalCount>
    </CarouselContext.Provider>
  );
};

export const SyncTotalCount = ({ total, children }) => {
  const [{ total: stateTotal, current = 0 }, { setTotal, setCurrent }] =
    useCarousel();
  useEffect(() => {
    if (stateTotal && total !== stateTotal) {
      setTotal(total);
      setCurrent(total < stateTotal ? 0 : current);
    }
    if (total && current === -1) {
      setCurrent(0);
    }
  }, [current, setCurrent, setTotal, stateTotal, total]);
  return children;
};

export default CarouselProvider;

export const useCarousel = (): Context => {
  const context = React.useContext(CarouselContext);

  if (context === null) {
    throw new Error('Carousel Context Failed to initiate');
  }

  return context;
};
