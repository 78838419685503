import React from 'react';
import { MarginProps } from 'styled-system';
import {
  RadioContainer,
  RadioFilling,
  HiddenRadio,
  LabelText,
  StyledLabel,
  StyledRadio,
} from './Radio.styles';
import { useRadioGroup } from './RadioGroupContext';

export type RadioProps = MarginProps & {
  /**
   * Checked attribute that will define is the Radio is selected
   */
  checked?: boolean;
  /**
   * Disable state on the button
   */
  disabled?: boolean;
  /**
   * Label for the Radio Button
   */
  label?: string;
  /**
   * Input Name
   */
  name?: string;
  /**
   * Value
   */
  value: any;
  /**
   * Function handling the click on the button
   */
  onChange?: (value: any) => void;
};

/**
 * Radio Button component
 */
const Radio: React.FC<RadioProps> = ({
  label,
  checked: checkedProp,
  onChange,
  disabled,
  value,
  name: nameProp,
  ...props
}: RadioProps) => {
  const groupContext = useRadioGroup();
  let name = nameProp;
  let checked = checkedProp;

  const handleOnChange = () => {
    if (!disabled) {
      if (groupContext.onChange) {
        groupContext.onChange(value);
      } else if (onChange) {
        onChange(value);
      }
    }
  };

  if (groupContext) {
    if (typeof checked === 'undefined') {
      checked = groupContext.value === value;
    }
    if (typeof name === 'undefined') {
      name = groupContext.name;
    }
  }

  return (
    <RadioContainer {...props}>
      <StyledLabel>
        <HiddenRadio
          onChange={handleOnChange}
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
        />
        <StyledRadio checked={checked} disabled={disabled}>
          <RadioFilling disabled={disabled} />
        </StyledRadio>

        {label && (
          <LabelText variant={'body'} disabled={disabled}>
            {label}
          </LabelText>
        )}
      </StyledLabel>
    </RadioContainer>
  );
};

export default Radio;
