import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from 'styled-components/macro';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import {
  Banner,
  Box,
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Loader,
} from 'components/index';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as dataSourcesAction } from 'ducks/dataSources/actions';
import {
  getDataFiles,
  getIsSelectedFileExist,
  getSelectedFile,
} from 'ducks/dataSources/selectors';
import {
  getModifiableDatafields,
  selectedTemplate,
} from 'ducks/templates/selectors';
import { MS_CONTACTS_LABEL } from 'services/office365';
import { Text } from 'v2/components/common';

import { usePrintDialog } from '../PrintDialogContext';
import { useLabelRange } from '../LabelRangeContext';
import { GOOGLE_CONTACTS_LABEL } from '../../../../services/google/googleContacts';

const FileError = styled.div<{ fontSize?: number }>`
  max-width: 100%;
  color: ${({ theme }) => theme.error.base};
  white-space: pre-wrap;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
  line-height: 1.3;
  letter-spacing: 0.4px;
  overflow-wrap: break-word;
`;

const StyledDropdown = styled(Dropdown)`
  & > div[disabled] {
    background-color: ${({ theme }) => theme.neutral.lightest};
    cursor: not-allowed;

    & > div > div {
      &:hover {
        background-color: ${({ theme }) => theme.neutral.lightest};
        cursor: not-allowed;
      }

      input {
        color: ${({ theme }) => theme.textColors.low};
        cursor: not-allowed;
      }
    }
  }
`;

const PrintDialogDataMappingStep = ({
  onNext,
  onCancel,
  missingDataSource,
}) => {
  const { t } = useTranslation(['common', 'components']);
  const msalContext = useMsal();
  const isOneDriveAuthenticated = useIsAuthenticated();
  const [{ firstRowIsHeader }, { setFirstRowIsHeader, setSelectedLabels }] =
    usePrintDialog();
  const [
    {
      labelRange,
      loading: labelRangeLoading,
      success: labelRangeSuccess,
      error: labelRangeError,
    },
    { setLabelRangeMapping, setFile, reset: resetLabelRange },
  ] = useLabelRange();
  const [step, setStep] = useState(0);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [enterManual, setEnterManual] = useState(false);

  const [columnMapping, setColumnMapping] = useState<any>({});

  const getFileList = useReduxAction(dataSourcesAction.ALL.request);

  const setSelectDataSource = useReduxAction(
    dataSourcesAction.SELECTED.request,
  );
  const clearDataSource = useReduxAction(dataSourcesAction.SELECTED.clear);
  const files = useReduxSelector(getDataFiles);
  const selectedFile = useReduxSelector(getSelectedFile);
  const templateForPrinting = useReduxSelector(selectedTemplate);
  const fields = useReduxSelector(getModifiableDatafields);
  const isSelectedFileExist = useReduxSelector(getIsSelectedFileExist);

  const columnMappingData = useMemo(() => {
    if (fields && labelRange?.length) {
      const columns = labelRange[0]?.map((element, index) => ({
        value: index,
        label: element,
      }));

      let tempColumnMapping = {};
      let count = 0;

      fields.forEach((field) => {
        const column = columns.find(
          ({ label }) => field.databaseFieldName === label,
        );
        if (column) {
          tempColumnMapping[field.name] = Number(column.value);
          count++;
        }
      });

      return {
        data: { ...tempColumnMapping },
        toContinueOn:
          count ===
          fields.filter((obj) => obj.databaseFieldName != null).length,
      };
    }
  }, [fields, labelRange]);

  const documentColumns = useMemo(() => {
    return labelRange
      ? labelRange[0]?.map((element, index) => ({
          value: index,
          label: firstRowIsHeader
            ? element
            : `${t('components:printer.column')} ${index + 1}`,
        }))
      : [];
  }, [labelRange, firstRowIsHeader, t]);

  const continueOn = useCallback(
    (inputColumnMapping) => {
      setSelectedLabels(
        labelRange.map((e, i) => i).slice(firstRowIsHeader ? 1 : 0),
      );
      setLabelRangeMapping(inputColumnMapping ?? columnMapping);
      setStep(0);
      onNext();
    },
    [
      labelRange,
      columnMapping,
      firstRowIsHeader,
      onNext,
      setSelectedLabels,
      setLabelRangeMapping,
    ],
  );

  useMemo(() => {
    getFileList();
  }, [getFileList]);

  useEffect(() => {
    if (labelRangeSuccess && !enterManual) {
      if (columnMappingData) {
        setColumnMapping(columnMappingData?.data);
        setDataAvailable(true);
        setStep(1);
      } else {
        setDataAvailable(false);
      }
    }
    if (labelRangeSuccess && enterManual) {
      setLabelRangeMapping({ manual: true });
      setDataAvailable(true);
      onNext();
    }
  }, [
    labelRangeSuccess,
    enterManual,
    onNext,
    setLabelRangeMapping,
    columnMappingData,
  ]);

  useEffect(() => {
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, [selectedFile, setFile]);

  useEffect(() => {
    if (
      columnMappingData &&
      Object.entries(columnMappingData.data).length !== 0
    ) {
      if (columnMappingData.toContinueOn) {
        continueOn(columnMappingData.data);
      }
    }
  }, [columnMappingData, continueOn]);

  const handleNextStep1 = useCallback(() => {
    if (enterManual) {
      if (selectedFile) {
        clearDataSource();
      }
      onNext();
    } else if (selectedFile) {
      setFile(selectedFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setFile,
    selectedFile,
    enterManual,
    onNext,
    msalContext,
    isOneDriveAuthenticated,
  ]);

  return (
    <Box width={329} m={16}>
      {labelRangeLoading || columnMappingData?.toContinueOn ? (
        <Loader visible={true} />
      ) : (
        <>
          {step === 0 ? (
            <Flex flexDirection="column" height={'100%'}>
              <Box height={50}>
                <Text fontSize={24} fontWeight={300}>
                  {t('components:printer.options.update-data-connections')}
                </Text>
              </Box>

              {labelRangeError || !isSelectedFileExist ? (
                <Box mb={20}>
                  <Banner
                    variant={'error'}
                    message={t('components:printer.errors.could-not-load-file')}
                    subtitle={missingDataSource.toString()}
                  />
                </Box>
              ) : null}
              <Box mb={20} style={{ flexGrow: 2 }}>
                <Box mb={20}>
                  <Text>
                    <Trans
                      i18nKey="components:printer.messages.sources-missing-section"
                      values={{
                        name: `${
                          templateForPrinting && templateForPrinting.name
                            ? templateForPrinting.name
                            : t('components:printer.messages.unknown')
                        }`,
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </Text>
                </Box>
                <Box mt={10} mb={20}>
                  {!dataAvailable && (
                    <FileError data-testid="file-no-data-error">
                      {t('components:printer.errors.file-contains-no-data')}
                    </FileError>
                  )}
                  <StyledDropdown
                    placeholder={t('components:printer.messages.choose-option')}
                    value={selectedFile?.fileName}
                    hasError={!dataAvailable}
                    disabled={enterManual}
                    onChange={(e) => {
                      resetLabelRange();

                      if (e === GOOGLE_CONTACTS_LABEL) {
                        setSelectDataSource({
                          id: 'Google',
                          provider: 'Google',
                          name: GOOGLE_CONTACTS_LABEL,
                        });
                      } else if (e === MS_CONTACTS_LABEL) {
                        setSelectDataSource({
                          id: MS_CONTACTS_LABEL,
                          provider: 'Office365',
                          name: MS_CONTACTS_LABEL,
                        });
                      } else {
                        let file = files.find((file) => file.id === e);
                        setSelectDataSource({
                          id: file?.id,
                          provider: file?.provider,
                          name: file?.name,
                        });
                      }
                    }}
                    noOptionsValue={t(
                      'components:printer.messages.no-files-uploaded',
                    )}
                    options={files
                      .map((f) => ({
                        value: f.id,
                        label: `${f.name} (${f.provider.toLowerCase()})`,
                      }))
                      .concat([
                        {
                          value: GOOGLE_CONTACTS_LABEL,
                          label: GOOGLE_CONTACTS_LABEL,
                        },
                        { value: MS_CONTACTS_LABEL, label: MS_CONTACTS_LABEL },
                      ])}
                  />
                </Box>
                <Box>
                  <Flex flexDirection="row" alignItems="center">
                    <Checkbox
                      disabled={!dataAvailable}
                      onChange={(e) => {
                        setEnterManual(!enterManual);
                      }}
                      checked={enterManual}
                    ></Checkbox>
                    <Text>
                      {t(
                        'components:printer.messages.enter-data-manually-at-print-time',
                      )}
                    </Text>
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Flex flexDirection="row" justifyContent="flex-end">
                  <Button onClick={onCancel} variant="text-primary">
                    {t('common:cancel')}
                  </Button>
                  <Button
                    onClick={handleNextStep1}
                    disabled={
                      ((!selectedFile ||
                        labelRangeError ||
                        labelRangeLoading) &&
                        !enterManual) ||
                      !dataAvailable
                    }
                    isLoading={labelRangeLoading}
                  >
                    {t('common:continue')}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          ) : (
            <Flex flexDirection="column" height={'100%'}>
              <Box height={60}>
                <Text variant="h2">{t('components:printer.bind-data')}</Text>
              </Box>
              <Box style={{ flexGrow: 2 }}>
                <Box mb={20}>
                  <Text>
                    {t('components:printer.messages.uploaded-spreadsheet')}
                  </Text>
                </Box>
                <Box mb={20}>
                  <Box mb={10}>
                    <Flex flexDirection="row" alignItems="center">
                      <Checkbox
                        onChange={(e) => {
                          setFirstRowIsHeader(!firstRowIsHeader);
                        }}
                        checked={firstRowIsHeader}
                      ></Checkbox>
                      <Text>{t('components:printer.first-row-header')}</Text>
                    </Flex>
                  </Box>
                  {fields?.map((field, i) =>
                    field.databaseFieldName === null ? (
                      <Fragment key={i}></Fragment>
                    ) : (
                      <Flex mb={10} flexDirection="column" key={i}>
                        <Text>{`${i + 1}. ${field.name}`}</Text>
                        <Dropdown
                          value={columnMapping[field.name]}
                          onChange={(e) => {
                            let tempAttr = {};
                            tempAttr[field.name] = Number(e);
                            setColumnMapping((mapping) => ({
                              ...mapping,
                              ...tempAttr,
                            }));
                          }}
                          options={documentColumns}
                        />
                      </Flex>
                    ),
                  )}
                </Box>
              </Box>
              <Box height={30}>
                <Flex flexDirection="row" justifyContent="flex-end">
                  <Button onClick={onCancel} variant="text-primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      continueOn(null);
                    }} //NOTE: does this need data?
                    disabled={
                      !selectedFile ||
                      !(
                        fields?.filter(
                          (field) => field.databaseFieldName !== null,
                        ).length === Object.keys(columnMapping)?.length
                      )
                    }
                  >
                    {t('common:continue')}
                  </Button>
                </Flex>
              </Box>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default PrintDialogDataMappingStep;
