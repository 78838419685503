import styled from 'styled-components';
import { Text, PageWrap, Button } from 'components';

export const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 520px;

  & > ${PageWrap} {
    padding-left: 0;
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  // border-radius: 0px;
  width: 343px;
  display: flex;
  justify-content: center;
  margin-right: 0px;
`;

export const InfoWrapper = styled.span`
  text-align: center;
  width: 512px;
  margin-bottom: 32px;
`;

export const DeleteAccountInfo = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.4;
  text-align: center;
  display: inline;
`;

export const StyledLogoImage = styled.img`
  width: 245px;
  height: 94px;
  padding-bottom: 44px;
`;

export const StyledLoginImage = styled.img`
  width: 174px;
  height: 232px;
  padding-bottom: 32px;
`;
