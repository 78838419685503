import React from 'react';
import { Input } from '../../../index';
import { PrintInput } from './PrintInput';

export const PrintDateInput = ({
  template,
  card,
  handleChange,
  fieldError,
}) => (
  <PrintInput template={template} card={card}>
    <Input
      format={card.dateInputFormat}
      type="date"
      readonly={false}
      placeholder={card.promptText}
      disabled={card.mappedFieldId != null}
      key={card.name}
      value={card.initialValue}
      onChange={handleChange}
      hasError={fieldError?.includes(card.name) ?? false}
    />
  </PrintInput>
);
