import { call, put, fork, delay, take } from 'redux-saga/effects';

import { actions } from 'ducks/misc/actions';
import { types as modalDialogTypes } from 'ducks/modalDialog/actions';

const TIMEOUT = 30 * 1000; //30 sec

export function* apiCall<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
) {
  const task = yield fork(function* () {
    while (true) {
      yield delay(TIMEOUT);
      yield put(actions.API_TIMEOUT());
      yield take(modalDialogTypes.OUTAGE.CLOSE);
    }
  });

  const res = yield call(fn, ...args);
  task.cancel();

  return res;
}
