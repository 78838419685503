import { createSelector } from 'reselect';

const fileDialog = (state: any) => state.fileDialog;

export const getFileDialogStatus = createSelector(
  [fileDialog],
  ({ open, network, fileType }) => ({
    open,
    network,
    fileType,
  }),
);
