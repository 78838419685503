import React from 'react';

type MenuContextType = {
  onClose?: () => void;
};

const MenuContext = React.createContext<MenuContextType>({});

export default MenuContext;

export const useMenu = (): MenuContextType => React.useContext(MenuContext);
