import { DefaultTheme } from 'styled-components';
import { hexToRgba } from './index';
import { ColorPalette } from './styled';
import 'typeface-roboto';
import { space, textColors, elevations, px, spacing } from './common';

const primary: ColorPalette = {
  background: '#FAFCFF',
  lightest: '#EDF3FE',
  lighter: '#B5D0F9',
  light: '#7EACF5',
  base: '#4789F0',
  dark: '#396EC0',
  darker: '#2B5290',
  darkest: '#1C3760',
  surface: '#1C3760',
};

const neutral: ColorPalette = {
  background: '#F7F7F8',
  lightest: '#E4E6E9',
  lighter: '#C9CDD4',
  light: '#A1A8B3',
  base: '#798393',
  dark: '#616976',
  darker: '#494F58',
  darkest: '#30343B',
};

const error: ColorPalette = {
  background: '#FFF3F6',
  lightest: '#FDE7E7',
  lighter: '#F9BFBF',
  light: '#F69797',
  base: '#F04747',
  dark: '#BA3535',
  darker: '#842424',
  darkest: '#4E1313',
};

const success: ColorPalette = {
  background: '#F3FFF6',
  lightest: '#E1FFE8',
  lighter: '#BEEEC8',
  light: '#90E1A3',
  base: '#34C759',
  dark: '#299B45',
  darker: '#1D6E31',
  darkest: '#11411D',
};

const warning: ColorPalette = {
  background: '#FFF9F0',
  lightest: '#FEF2E0',
  lighter: '#F9DFB5',
  light: '#F5C67E',
  base: '#F0AE47',
  dark: '#C08B39',
  darker: '#90682B',
  darkest: '#60461C',
};

const info: ColorPalette = {
  background: '#EDF5FE',
  lightest: '#DAECFC',
  lighter: '#C8E2FB',
  light: '#A3CFF8',
  base: '#479FF0',
  dark: '#397FC0',
  darker: '#2B5F90',
  darkest: '#1C4060',
};

const lucas: DefaultTheme = {
  name: 'lucas',
  primary,
  neutral,
  error,
  success,
  warning,
  info,
  textColors,
  elevations,
  white: '#FFFFFF',
  black: '#000000',
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    caption: 12,
    label: 14,
    body: 16,
    h3: 18,
    h2: 24,
    h1: 28,
    title: 32,
  },
  space,
  spacing,
  withOpacity: (hex: string, opacity: number) => hexToRgba(hex, opacity),
  defaultFont: 'Roboto',
  px,
};

export default lucas;
