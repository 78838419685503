import { getSandboxId } from './config';

export const buildSandboxIdCookie = () => {
  const sandboxId = getSandboxId();
  let cookieString = `sandbox_id=${
    sandboxId ?? ''
  }; Domain=zebra.com; Path=/auth/code;`;

  if (sandboxId != null) {
    return `${cookieString} SameSite=None; Secure;`;
  } else {
    return `${cookieString} Expires=Thu, 01 Jan 1970 00:00:00 GMT;`; // Delete cookie
  }
};
