export const allTemplates = [
  {
    id: 1,
    name: 'Shipping A',
    etch: '1" x 1"',
    lastPrint: 'Jan 13, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 2,
    name: 'Shipping B',
    etch: '1" x 2"',
    lastPrint: 'Jan 14, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 3,
    name: 'Shipping C',
    etch: '1" x 3"',
    lastPrint: 'Jan 15, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 4,
    name: 'Shipping D',
    etch: '1" x 4"',
    lastPrint: 'Jan 16, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 5,
    name: 'Shipping E',
    etch: '2" x 2"',
    lastPrint: 'Jan 17, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 6,
    name: 'Speciality A',
    etch: '1" x 3"',
    lastPrint: 'Jan 18, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 7,
    name: 'Speciality B',
    etch: '1" x 3"',
    lastPrint: 'Jan 19, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 8,
    name: 'Speciality C',
    etch: '2" x 3"',
    lastPrint: 'Jan 20, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 9,
    name: 'Speciality D',
    etch: '2" x 3"',
    lastPrint: 'Jan 21, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 10,
    name: 'Ship A',
    etch: '1" x 4"',
    lastPrint: 'Jan 22, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 11,
    name: 'Ship B',
    etch: '1" x 2"',
    lastPrint: 'Jan 17, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 12,
    name: 'Ship C',
    etch: '2" x 2"',
    lastPrint: 'Jan 18, 2020',
    smallThumbnail: '/label-template.png',
  },
  {
    id: 13,
    name: 'Shipping A',
    etch: '1" x 4"',
    lastPrint: 'Jan 13, 2020',
    smallThumbnail: '/label-template.png',
  },
];

export const recentTemplates = [{ ids: [1, 2, 3, 4, 5, 6] }];

export const currentTemplate = [{ id: 6 }];
