import React, { ReactChild } from 'react';
import { Flex } from 'components';
import { Container, StyledText, ToggleSwitchInput, ToggleSwitchLoader } from './ToggleSwitch.styles';

type ToggleSwitchInputProps = {
  id?: string;
  defaultChecked?: boolean;
  value: string;
  label?: string;
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  greyLabel?: boolean;
  componentNextToText?: ReactChild;
};

const ToggleSwitch = ({
  id,
  defaultChecked = false,
  value,
  label,
  name,
  multiple = false,
  onChange,
  disabled = false,
  isLoading = false,
  greyLabel,
  componentNextToText,
  ...props
}: ToggleSwitchInputProps) => {
  return (
    <Container {...props}>
      <Flex alignItems={'center'}>
        <StyledText
          data-testid="toggle-switch-text"
          greyText={greyLabel}
        >
          {label ?? ''}
        </StyledText>
        {componentNextToText && componentNextToText}
      </Flex>
      {isLoading ? (
        <ToggleSwitchLoader />
      ) : (
        <ToggleSwitchInput
          data-testid="toggle-switch"
          multiple={multiple}
          disabled={disabled}
          key={id}
          checked={defaultChecked}
          value={value}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(e);
          }}
        />
      )}
    </Container>
  );
};

export default ToggleSwitch;
