import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import type { PrinterType } from 'zsbpsdk/src/printer';

import { PRINT_PAGE_PATH } from 'utils/config';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as printersActions } from 'ducks/printers/actions';
import {
  selectAllPrinters,
  getAllPrintersLoading,
} from 'ducks/printers/selectors';
import { Box, Loader } from 'components';
import { DialogHeader } from 'v2/components/app';

import NoPrinters from './NoPrinters';
import PrintersList from './PrintersList';

export default function PrintTestLabel() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);

  return (
    <>
      <DialogHeader
        title={t('dialogs:print-label.print-test-label')}
        onBackClick={() => pushAction(PRINT_PAGE_PATH)}
      />

      <Box height={'60vh'}>
        <PageContent />
      </Box>
    </>
  );
}

function PageContent() {
  const printers: PrinterType[] = useReduxSelector(selectAllPrinters);
  const printersAreLoading = useReduxSelector(getAllPrintersLoading);
  const fetchPrinters = useReduxAction(printersActions.ALL.request);

  useEffect(() => {
    fetchPrinters({});
  }, [fetchPrinters]);

  if (printersAreLoading) {
    return <Loader visible />;
  }

  return printers.length ? <PrintersList /> : <NoPrinters />;
}
