import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReduxAction, useReduxSelector } from 'hooks';
import { useFilteredTemplates } from 'v2/hooks';
import { ErrorText } from 'v2/components/common';
import { actions as templateActions } from 'ducks/templates/actions';
import {
  filteredTemplates as selectFilteredTemplates,
  selectFilteredTemplatesHash,
} from 'ducks/templates/selectors';

import NoTemplatesLayout from './NoTemplatesLayout';
import StyledTemplatesGrid from '../StyledTemplatesGrid';

export default function TemplatesList() {
  const { t } = useTranslation();

  const filteredTemplatesAction = useReduxAction(
    templateActions.FILTERED.request,
  );
  const shouldShowNoTemplatesLayout = useReduxSelector(
    selectFilteredTemplatesHash,
  );

  const {
    filteredTemplatesData,
    filteredTemplatesDataLoading,
    filteredTemplatesError,
  } = useFilteredTemplates(selectFilteredTemplates, filteredTemplatesAction);

  if (filteredTemplatesError) {
    return (
      <ErrorText variant="h2" mb={8}>
        {t('templates:server-error')}
      </ErrorText>
    );
  }

  if (shouldShowNoTemplatesLayout) {
    return <NoTemplatesLayout />;
  }

  return (
    <StyledTemplatesGrid
      isLoading={filteredTemplatesDataLoading}
      templates={filteredTemplatesData}
    />
  );
}
