import * as React from 'react';
import { Card, Flex, MembersTable, Box } from 'components';
import { members as data } from 'utils/demo/members';
import { dateFormatter } from 'utils/dateFormatter';

export const columns = [
  { key: 'avatar' },
  { key: 'name', title: 'Name' },

  { key: 'role', title: 'Role' },
  {
    key: 'templates',
    title: 'Templates',
    align: 'center',
  },
  {
    key: 'lastSeen',
    title: 'Last Seen',
    formatter: dateFormatter,
    align: 'right',
  },
  { key: 'action', align: 'right' },
];

const TableView = (): any => {
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Members Table</h2>
        <Box width={'887px'}>
          <MembersTable
            columns={columns}
            data={data}
            onRowActionClick={(id, action) => console.log(id, action)}
          />
        </Box>
      </Flex>
    </Card>
  );
};

export default TableView;
