import React from 'react';
import styled from 'styled-components/macro';

import { useFetchedData, useReduxSelector } from 'hooks';
import { Flex, Loader } from 'components';
import { filteredDataSources, isAddingFile } from 'ducks/dataSources/selectors';
import { getQuery } from 'ducks/router/selectors';
import { actions as datasourcesActions } from 'ducks/dataSources/actions';

import { FilterLayout } from './FilterLayout';
import NoFiles from './NoFiles';
import FilesList from './FilesList';
import Page from '../Page';

const ContentWrap = styled(Flex).attrs({
  flexDirection: 'column',
})`
  flex: 1;
  padding-top: 10px;
  max-width: 100%;
`;

const DataSources = () => {
  const { search = '' } = useReduxSelector(getQuery);
  const uploading = useReduxSelector(isAddingFile);

  const [filteredDatasources, filteredDatasourcesLoading] = useFetchedData(
    filteredDataSources,
    datasourcesActions.FILTERED.request,
    { search },
  );

  const loadingFiles = (
    <Flex
      justifyContent="center"
      height="100%"
      width="100%"
      alignItems="center"
    >
      <Loader visible={true} />
    </Flex>
  );

  const filesList =
    filteredDatasources && filteredDatasources.length > 0 ? (
      <FilesList
        files={filteredDatasources}
        onRowSelect={() => {}}
        hover={false}
      />
    ) : (
      <NoFiles />
    );

  return (
    <Page>
      <ContentWrap>
        <FilterLayout />
        <Flex mt={10}>
          {filteredDatasourcesLoading || uploading ? loadingFiles : filesList}
        </Flex>
      </ContentWrap>
    </Page>
  );
};

export default DataSources;
