export const space: number[] = [
  0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 26, 28, 30, 32, 36, 48, 52, 56,
];

export const textColors = {
  low: '#C9CDD4',
  med: '#798393',
  high: '#30343B',
};

export const elevations = {
  one: '0px 2px 4px rgba(73, 80, 94, .1)',
  two: '0px 4px 8px rgba(73, 80, 94, .1)',
  three: '0px 8px 16px rgba(73, 80, 94, .16)',
  four: '0px 16px 32px rgba(73, 80, 94, .16)',
  five: '0px 20px 40px rgba(73, 80, 94, .16)',
  six: '0px 32px 64px rgba(73, 80, 94, .2)',
  nav: '4px 0px 8px rgba(73, 80, 94, .12)',
  card: '0px 0.5px 2px rgba(96, 104, 112, .16), 0px 0px 0px 1px rgba(40, 51, 61, .08)',
};

export const spacing = {
  buttonGap: '16px',
};

export const px = (...args: number[]) =>
  args.map((v) => `${v * 2}px`).join(' ');
