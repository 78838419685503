import { DefaultTheme } from 'styled-components';
import lucas from 'utils/themes/lucas';
import anita from 'utils/themes/anita';
import takeshi from 'utils/themes/takeshi';
import rick from 'utils/themes/rick';
import bryce_ali from 'utils/themes/bryce_ali';
import i18n from 'i18next';

const theme = (variant?: string): DefaultTheme => {
  switch (variant) {
    case 'anita':
      return anita;
    case 'takeshi':
      return takeshi;
    case 'rick':
      return rick;
    case 'bryce_ali':
      return bryce_ali;
    default:
      return lucas;
  }
};

export const getThemes = (): { id: string; name: string }[] => {
  return [
    { id: 'lucas', name: i18n.t('overview:theme.modern') },
    { id: 'anita', name: i18n.t('overview:theme.eclectic') },
    { id: 'takeshi', name: i18n.t('overview:theme.bohemian') },
    { id: 'rick', name: i18n.t('overview:theme.professional') },
    { id: 'bryce_ali', name: i18n.t('overview:theme.maker') },
  ];
};

export const hexToRgba = (hex, opacity) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt('0x' + hex[1] + hex[1]);
    g = parseInt('0x' + hex[2] + hex[2]);
    b = parseInt('0x' + hex[3] + hex[3]);
    // 6 digits
  } else if (hex.length === 7) {
    r = parseInt('0x' + hex[1] + hex[2]);
    g = parseInt('0x' + hex[3] + hex[4]);
    b = parseInt('0x' + hex[5] + hex[6]);
  }

  return `rgba(${+r},${+g},${+b},${+opacity})`;
};

export default theme;
