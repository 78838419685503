import { PrinterStatusType, PrinterType } from 'zsbpsdk/src/printer';
import { TemplateType } from 'zsbpsdk/src/templates';
import i18n from 'i18next';
import { CARTRIDGE_MINIMUM_LABELS } from './config';
import {
  convertCartridgeInfoSizeToInch,
  convertTemplateSizeToInch,
} from './unitFormatter';

export const convertPrinterStatus = (
  status: PrinterStatusType,
  labelsRemaining: number,
  mediaLowOutAllowed?: boolean,
): string => {
  if (status) {
    if (!status.isOnline) return i18n.t('components:printer.status.offline');
    if (status.isHeadOpen)
      return i18n.t('components:printer.status.cover-open');
    if (status.isPaperOut) return i18n.t('components:printer.status.paper-out');
    if (status.isBufferFull)
      return i18n.t('components:printer.status.buffer-full');
    if (!status.isReadyToPrint)
      return i18n.t('components:printer.status.unable-to-print'); //TODO: Is there a correct text for here?
    if (status.isOnline && labelsRemaining <= CARTRIDGE_MINIMUM_LABELS)
      return i18n.t('components:printer.status.media-low');

    if (
      !status.isBufferFull &&
      !status.isHeadOpen &&
      !status.isPaperOut &&
      status.isReadyToPrint
    )
      return i18n.t('components:printer.status.online');
  }
  return i18n.t('components:printer.status.offline');
};

export const getPrintSuccessMessage = (
  status: PrinterStatusType | undefined,
) => {
  if (!status?.isOnline) {
    return i18n.t('components:printer.messages.currently-offline');
  } else if (status?.isHeadOpen) {
    return i18n.t('components:printer.messages.cover-open');
  } else if (status?.isPaperOut) {
    return i18n.t('components:printer.messages.out-of-paper');
  } else if (status?.isBufferFull) {
    return i18n.t('components:printer.messages.currently-full');
  } else {
    return i18n.t('components:printer.messages.print-job-sent');
  }
};

export const getPrintSuccessToastVariant = (
  status: PrinterStatusType | undefined,
) =>
  !status?.isOnline ||
  status?.isHeadOpen ||
  status?.isPaperOut ||
  status?.isBufferFull
    ? 'warning'
    : 'success';

export const isTemplateWithPrinterCartridgeSize = (
  template: TemplateType,
  height: number,
  width: number,
) =>
  (convertTemplateSizeToInch(template.labelSize!.width) === width &&
    convertTemplateSizeToInch(template.labelSize!.height) === height) ||
  //We check for the opposite because some of the labels could be with different orientation
  (convertTemplateSizeToInch(template.labelSize!.width) === height &&
    convertTemplateSizeToInch(template.labelSize!.height) === width);

export const getCartridgeInfoSize = (printer: PrinterType) => {
  if (!printer.cartridgeInfo || !printer.resolutionDotsPerInch) {
    return { width: 0, height: 0 };
  }

  return {
    width: convertCartridgeInfoSizeToInch(
      printer.cartridgeInfo.width,
      printer.resolutionDotsPerInch,
    ),
    height: convertCartridgeInfoSizeToInch(
      printer.cartridgeInfo.length,
      printer.resolutionDotsPerInch,
    ),
  };
};
