import { StyledBox } from './PrinterStatusChip.styles';

const WarningIcon = () => {
  return (
    <StyledBox>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.9975 1.99271L14.2725 12.8344H1.7225L7.9975 1.99271ZM0.28083 12.001C-0.360836 13.1094 0.439163 14.501 1.7225 14.501H14.2725C15.5558 14.501 16.3558 13.1094 15.7142 12.001L9.43916 1.15938C8.7975 0.0510417 7.1975 0.0510417 6.55583 1.15938L0.28083 12.001ZM7.16416 6.16771V7.83438C7.16416 8.29271 7.53916 8.66771 7.9975 8.66771C8.45583 8.66771 8.83083 8.29271 8.83083 7.83438V6.16771C8.83083 5.70938 8.45583 5.33438 7.9975 5.33438C7.53916 5.33438 7.16416 5.70938 7.16416 6.16771ZM7.16416 10.3344H8.83083V12.001H7.16416V10.3344Z"
          fill="#AE6500"
        />
      </svg>
    </StyledBox>
  );
};

export default WarningIcon;
