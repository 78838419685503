import styled from 'styled-components';
import { Flex } from '../../index';
import { device } from '../../../utils/mediaQueries';

export const announcementHeight = '2rem';

type AnnouncementWrapType = { shown: boolean };
export const AnnouncementWrap = styled(Flex)<AnnouncementWrapType>`
  transition: opacity 0.75s linear;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  color: white;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.primary.base};
  font-size: 1rem;
  height: ${announcementHeight};
  line-height: ${announcementHeight};
  min-width: 0;
  justify-content: center;
  opacity: ${({ shown }) => (shown ? '1' : '0')};

  @media ${device.mobile} {
    top: 71px;
  }

  @media ${device.mobileToTablet} {
    top: 71px;
  }
`;

export const MessageWrap = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const LinkWrap = styled.div`
  flex-shrink: 0;
`;

export const Link = styled.a`
  &,
  &:visited {
    color: white;
  }
`;
