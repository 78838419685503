import React from 'react';
import { throttle } from 'throttle-debounce';
import { Input, Form, Box, Flex } from 'components';

import Suggestion from './Suggestion';
import { useOnClickOutside } from 'hooks';

import {
  SearchType,
  SearchIcon,
  StyledBox,
  ActiveInputWrap,
  CloseIcon,
  SuggestionInputWrap,
  Category,
  PhraseText,
  TipsText,
  DropdownMenu,
} from './Search.styles';
import { useTranslation } from 'react-i18next';

type SearchChangeEvent = React.ChangeEvent<HTMLInputElement>;

const NoResults = ({ searchPhrase }: { searchPhrase: string }) => {
  const { t } = useTranslation('common');

  return (
    <Flex
      data-testid="no-results"
      px={24}
      pt={24}
      pb={40}
      flexDirection={'column'}
    >
      <PhraseText>{t('common:no-results-for', { searchPhrase })}</PhraseText>
      <TipsText>{t('common:search-tips')}</TipsText>
    </Flex>
  );
};

const Search = ({
  placeholder,
  onChange,
  options,
  initialSearch,
  onSelect,
  disabled,
  ...props
}: SearchType) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchPhrase, setSearchPhrase] = React.useState(initialSearch ?? '');
  const [hasSelected, setHasSelected] = React.useState(false);

  const wrapRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapRef, () => setIsOpen(false));

  const handleSubmit = React.useCallback(() => {
    setHasSelected(true);
    onSelect && onSelect(searchPhrase);
    setIsOpen(false);
  }, [onSelect, searchPhrase]);

  const handleDismiss = React.useCallback(() => {
    setIsOpen(false);
    setHasSelected(false);
    setSearchPhrase('');
    onSelect && onSelect('');
  }, [onSelect]);

  const throttledOnChange = React.useMemo(
    () => throttle(1000, onChange),
    [onChange],
  );
  const handleChange = React.useCallback(
    (e: SearchChangeEvent) => {
      const value = e.target.value;
      value ? setSearchPhrase(value) : setSearchPhrase('');
      !value && onSelect && onSelect('');
      setHasSelected(false);
      throttledOnChange(value);
    },
    [onSelect, throttledOnChange],
  );

  const handleSelect = React.useCallback(
    (value: string) => {
      setSearchPhrase(value);
      onSelect && onSelect(value);
      setHasSelected(true);
      setIsOpen(false);
    },
    [onSelect],
  );

  React.useEffect(() => {
    if (initialSearch) setHasSelected(true);
  }, [initialSearch]);

  React.useEffect(() => {
    if (searchPhrase?.length >= 1 && !hasSelected) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [hasSelected, searchPhrase]);

  const filteredOptions = React.useMemo(
    () => options?.filter((o) => !o.type),
    [options],
  );

  const suggestedValue = React.useMemo(() => {
    const startsWith = filteredOptions?.[0]?.name?.startsWith(searchPhrase);

    return isOpen &&
      searchPhrase?.length >= 1 &&
      startsWith &&
      filteredOptions?.length
      ? filteredOptions[0]?.name
      : '';
  }, [filteredOptions, searchPhrase, isOpen]);

  return (
    <Box ref={wrapRef} position={'relative'} {...props}>
      <Form onSubmit={handleSubmit}>
        <StyledBox position={'relative'} disabled={disabled ?? false}>
          <ActiveInputWrap>
            <Input
              placeholder={placeholder}
              iconStart={<SearchIcon />}
              onChange={handleChange}
              value={searchPhrase}
              iconEnd={isOpen || searchPhrase ? <CloseIcon /> : null}
              onEndIconClick={handleDismiss}
              data-testid="main-input"
              disabled={disabled}
            />
          </ActiveInputWrap>
          <SuggestionInputWrap top={0} width={'100%'} position={'absolute'}>
            <Input
              tabIndex={-1}
              iconStart={<SearchIcon />}
              value={suggestedValue}
              readonly={true}
              data-testid="suggestion-input"
            />
          </SuggestionInputWrap>
        </StyledBox>
        <DropdownMenu
          open={isOpen}
          data-testid="template-search-list"
          isSearchChild
        >
          {options && options?.length
            ? options.map((suggestion, i) =>
                suggestion?.type === 'section' ? (
                  <Category key={i}>{suggestion.label || ''}</Category>
                ) : (
                  <Suggestion
                    data-testid="template-search-item"
                    key={i}
                    value={suggestion.name || ''}
                    searchPhrase={searchPhrase}
                    onClick={handleSelect}
                    logo={suggestion?.logo}
                  />
                ),
              )
            : searchPhrase?.length >= 1 && (
                <NoResults searchPhrase={searchPhrase} />
              )}
        </DropdownMenu>
      </Form>
    </Box>
  );
};

export default Search;
