import { Input } from '../../../index';
import React from 'react';
import { PrintInput } from './PrintInput';

const KEYBOARD_EDITING_KEYS = [
  'backspace',
  'clear',
  'copy',
  'crsel',
  'cut',
  'delete',
  'eraseeof',
  'exsel',
  'insert',
  'paste',
  'redo',
  'undo',
  'arrowleft',
  'arrowright',
  'arrowup',
  'arrowdown',
  'end',
  'home',
  'pagedown',
  'pageup',
  'tab',
  'enter',
];
export const ALLOWED_SEPARATORS = ['.', ','];

const handleKeyDownChange = (e) => {
  if (
    (isNaN(+e.nativeEvent.key) &&
      !KEYBOARD_EDITING_KEYS.includes(e.nativeEvent.key.toLowerCase()) &&
      !ALLOWED_SEPARATORS.includes(e.nativeEvent.key) &&
      !e.ctrlKey &&
      !e.metaKey) ||
    e.nativeEvent.code === 'Space'
  ) {
    e.preventDefault();
  }
};

export const PrintCurrencyNumberInput = ({
  template,
  card,
  handleChange,
  fieldError,
}) => (
  <PrintInput template={template} card={card}>
    <Input
      hasError={fieldError?.includes(card.name) ?? false}
      type="number"
      readonly={false}
      placeholder={card.promptText}
      iconStart={card.currencyInputFormatInfo?.currencySymbol}
      value={card.initialValue}
      disabled={card.mappedFieldId != null}
      key={card.name}
      onChange={handleChange}
      onKeyDown={handleKeyDownChange}
    />
  </PrintInput>
);
