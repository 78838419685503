import React from 'react';
import { StyledDots, StyledDot } from './Dots.styles';
import { useCarousel } from './CarouselContext';

const Dots = () => {
  const [{ current, total }, { setCurrent }] = useCarousel();

  return (
    <StyledDots>
      {[...Array(total)].map((_, i) => (
        <StyledDot
          data-testid={`dot-${i}`}
          key={i}
          active={current === i}
          onClick={() => {
            setCurrent(i);
          }}
        />
      ))}
    </StyledDots>
  );
};

export default Dots;
