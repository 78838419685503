import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components/macro';
import { Modal, Flex, Text, Box, Button } from 'components';
import { Line } from './common/Line';
import Printers from './common/Printers';
import {
  getFromLocalStorage,
  saveObjToLocalStorage,
} from 'utils/localStorageItemConverter';
import tokenStorage from 'services/tokenStorage';

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.base};
  &:hover {
    cursor: pointer;
  }
`;

const CertificationAboutToExpireModal = ({
  isAboutToExpire,
  printersAboutToExpire,
  handleModalCloseCallback,
}: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t } = useTranslation(['notifications', 'common']);
  const theme = React.useContext(ThemeContext);
  const ref = useRef(null);
  const SUPPORT_CERT_ABOUT_TO_EXPIRE_URL =
    'https://zsbsupport.zebra.com/s/article/000026420';
  const SUPPORT_DELETE_PRINTER_URL =
    'https://zsbsupport.zebra.com/s/article/000026409';

  const handleModalClose = useCallback(() => {
    const aboutToExpireWarning = getFromLocalStorage('aboutToExpireWarning');

    if (aboutToExpireWarning !== null) {
      const parsedAboutToExpiredWarning = JSON.parse(aboutToExpireWarning);

      if (parsedAboutToExpiredWarning.hasBeenOpened) {
        saveObjToLocalStorage(
          { ...parsedAboutToExpiredWarning, hasBeenOpened: false },
          'aboutToExpireWarning',
        );
        setIsOpen(false);
      }
    }
    handleModalCloseCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAboutToExpire) {
      const { token } = tokenStorage.get();
      const aboutToExpireWarning = getFromLocalStorage('aboutToExpireWarning');
      const dateNow = new Date().getTime();

      if (aboutToExpireWarning === null) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'aboutToExpireWarning',
        );
        setIsOpen(true);

        return;
      }

      const parsedAboutToExpiredWarning = JSON.parse(aboutToExpireWarning);

      if (token !== parsedAboutToExpiredWarning.lastToken) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'aboutToExpireWarning',
        );
        setIsOpen(true);

        return;
      }

      if (dateNow >= parsedAboutToExpiredWarning.tomorrowDate) {
        saveObjToLocalStorage(
          { hasBeenOpened: true, lastToken: token },
          'aboutToExpireWarning',
        );
        setIsOpen(true);
      }
    }
  }, [isAboutToExpire]);

  return (
    <Modal
      data-testid={'about-to-expire-certificates-modal'}
      ref={ref}
      small={true}
      hasCloseButton={false}
      clickOutside={false}
      open={isOpen}
      role={'dialog'}
      onClose={handleModalCloseCallback}
      aria-label="about to expire certificates modal"
    >
      <Flex flexDirection="column" alignItems="center" mt={5}>
        <Box mb={8}>
          <Text
            textAlign={'center'}
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={'300'}
            letterSpacing={0.4}
          >
            {t('about-to-expire-certification.update-printer')}
          </Text>
        </Box>
        <Box mt={8} mb={8}>
          <Trans
            t={t}
            i18nKey={t(
              'about-to-expire-certification.notification-text-with-links',
            )}
            components={{
              Link1: (
                <StyledLink
                  href={SUPPORT_DELETE_PRINTER_URL}
                  title={SUPPORT_DELETE_PRINTER_URL}
                  target="_blank"
                  rel="noopener"
                  role={'link'}
                ></StyledLink>
              ),
              Link2: (
                <StyledLink
                  href={SUPPORT_CERT_ABOUT_TO_EXPIRE_URL}
                  title={SUPPORT_CERT_ABOUT_TO_EXPIRE_URL}
                  target="_blank"
                  rel="noopener"
                  role={'link'}
                ></StyledLink>
              ),
            }}
          />
        </Box>
      </Flex>
      <Text
        mt={5}
        fontSize={14}
        fontWeight={'400'}
        lineHeight={'21px'}
        letterSpacing={0.4}
      >
        {t('about-to-expire-certification.registered-printers')}
      </Text>

      <Text
        mt={3}
        color={theme.textColors.med}
        fontSize={12}
        fontWeight={'400'}
        letterSpacing={2}
        lineHeight={'16px'}
      >
        {t('about-to-expire-certification.printer-name')}
      </Text>
      <Line />
      {printersAboutToExpire &&
        printersAboutToExpire.slice(0, 3).map(({ name, avatar }, index) => (
          <Box
            key={index}
            data-testid="about-to-expire-printer"
          >
            <Printers
              key={index}
              name={name}
              src={avatar}
              alt={name}
              width={'15%'}
              showText={true}
            />
          </Box>
        ))}

      <Flex flexDirection="row" justifyContent={'right'}>
        <Button
          variant={'primary'}
          size={'md'}
          mt={30}
          onClick={handleModalClose}
          data-testid={'button-modal-about-to-expire'}
          role={'button'}
          aria-label="OK button"
        >
          {t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default CertificationAboutToExpireModal;
