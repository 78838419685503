const BASE_DUCK_PREFIX = 'SMB_FILE_DIALOG_';

export const types = {
  OPEN: BASE_DUCK_PREFIX + 'OPEN',
  CLOSE: BASE_DUCK_PREFIX + 'CLOSE',
};

export const actions = {
  OPEN: () => ({
    type: types.OPEN,
  }),
  CLOSE: () => ({
    type: types.CLOSE,
  }),
};
