import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CustomerInfoType } from 'zsbpsdk/src/customer';

import { getIsLoggedIn, getCustomer } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';
import { Login } from 'pages';
import {
  getDeleteAccountStarted,
  isInitialDeleteAccountCustomer,
} from 'utils/deleteAccountStarted';

const PrivateRoute = ({ component, ...rest }: any) => {
  // implement permissions here if needed
  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const deleteAccountStarted = getDeleteAccountStarted();
  const isInitialCustomer = isInitialDeleteAccountCustomer(customer?.id);
  const isLoggedIn = useReduxSelector(getIsLoggedIn);

  if (
    isLoggedIn &&
    deleteAccountStarted &&
    isInitialCustomer &&
    rest.location.pathname !== '/user-settings'
  ) {
    return (
      <Redirect
        to={{
          pathname: '/user-settings',
        }}
      />
    );
  }

  return <Route component={isLoggedIn ? component : Login} {...rest} />;
};

export default PrivateRoute;
