import React, { useRef, useEffect, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useModal, useReduxAction } from 'hooks';
import { actions as templateActions } from 'ducks/templates/actions';
import { actions as labelSizesActions } from 'ducks/labelSizes/actions';
import { HOME_PAGE_PATH } from 'utils/config';
import NewLabelModal from 'pages/templates/components/NewLabelModal';

const CreateNewTemplate = () => {
  const getMyDesignSizesAction = useReduxAction(
    templateActions.MY_DESIGN_ALL_SIZES.request,
  );
  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);

  useEffect(() => {
    getMyDesignSizesAction();
    getAllLabelSizes();
  }, [getMyDesignSizesAction, getAllLabelSizes]);

  const newLabelModalRef = useRef(null);

  useModal(newLabelModalRef, true);
  const pushAction = useReduxAction(push);

  const closeModal = useCallback(() => {
    pushAction(HOME_PAGE_PATH);
  }, [pushAction]);

  return (
    <NewLabelModal
      ref={newLabelModalRef}
      onModalClose={closeModal}
      pushActionPage={HOME_PAGE_PATH}
    />
  );
};
export default CreateNewTemplate;
