import React, { forwardRef } from 'react';
import { Loader, Box } from 'components';
import {
  LabelContainer,
  StyledButton,
  StyledIcon,
  ButtonProps,
} from './Button.styles';

const Button = forwardRef(
  (
    {
      children,
      isLoading,
      disabled,
      icon,
      iconColor,
      iconSize,
      iconName,
      ...props
    }: ButtonProps,
    ref: any,
  ) => (
    <StyledButton
      ref={ref}
      icon={icon}
      disabled={isLoading ? isLoading : disabled}
      {...props}
    >
      {icon && iconName && (
        <StyledIcon
          color={iconColor?.toString()}
          size={iconSize}
          icon={iconName}
        />
      )}
      <LabelContainer>{children}</LabelContainer>
      {isLoading && (
        <Box ml={9} mr={4}>
          <Loader
            visible={isLoading}
            width={'15px'}
            height={'15px'}
            borderWidth={'2px'}
          />
        </Box>
      )}
    </StyledButton>
  ),
);

export default Button;
