import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Button, Flex } from 'components';
import { getAllUserTemplates as selectAllUserTemplates } from 'ducks/templates/selectors';
import { useModal, useReduxSelector } from 'hooks';
import ImportButton from 'pages/templates/components/ImportButton';
import NewLabelModal from 'pages/templates/components/NewLabelModal';

const StyledButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))`
  margin-left: ${({ theme }) => theme.px(12)};
`;

const StyledFlex = styled(Flex)`
  ${({ noTemplates }: { noTemplates: boolean }) =>
    noTemplates &&
    `
        margin-top: 88px;
        width: 100%;
        justify-content: flex-end;
    `}
`;

const TemplatesLayoutLabelButtons = () => {
  const { t } = useTranslation();

  const newLabelModalRef = useRef(null);
  const { open: openNewLabelModal } = useModal(newLabelModalRef);

  const allUserTemplates = useReduxSelector(selectAllUserTemplates);

  return (
    <>
      <StyledFlex noTemplates={allUserTemplates.length === 0}>
        <ImportButton
          data-testid="import-label-design-button"
          buttonText={t('templates:import-a-design')}
        />

        {allUserTemplates.length !== 0 && (
          <StyledButton
            data-testid="create-label-design-button"
            icon="right"
            iconName="add"
            variant="primary"
            onClick={openNewLabelModal}
            iconSize={16}
            className="workspace-button"
          >
            {t('templates:create-new-design')}
          </StyledButton>
        )}
      </StyledFlex>

      <NewLabelModal ref={newLabelModalRef} />
    </>
  );
};

export default TemplatesLayoutLabelButtons;
