import styled from 'styled-components/macro';
import { Flex } from 'components';
import { Text } from 'v2/components/common';

export type VariantType = 'small' | 'big';

export const Container = styled(Flex)<{
  variant: VariantType;
  $noBorder: boolean;
}>`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ theme, $noBorder = false }) =>
    !$noBorder && `border: 1px solid ${theme.neutral.lighter}`}
  overflow: hidden;
  background-color: ${({ theme }) => theme.primary.lightest};

  ${({ theme, variant }) =>
    variant === 'small' &&
    `
      width: ${theme.px(16)};
      height: ${theme.px(16)};

      ${StyledText} {
        font-size: 12px;
      }

      ${StyledImage} {
        width: ${theme.px(16)};
        height: ${theme.px(16)};
      }
  `};

  ${({ theme, variant }) =>
    variant === 'big' &&
    `
      width: ${theme.px(40)};
      height: ${theme.px(40)};

      ${StyledText} {
        font-size: 36px;
      }

      ${StyledImage} {
        width: ${theme.px(40)};
        height: ${theme.px(40)};
      }
  `};
`;

export const StyledText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.primary.base};
`;

export const StyledImage = styled.img<{ variant: VariantType }>`
  object-fit: cover;
`;
