export const GOOGLE_CONTACTS_LABEL = 'Google Contacts';
export const GOOGLE_CONTACTS_HEADERS = [
  'Display Name',
  'Title',
  'Given Name',
  'Middle Name',
  'Surname',
  'Nick Name',
  'Email Address',
  'Job Title',
  'Company Name',
  'Department',
  'Main Phone',
  'Home Phone',
  'Mobile Phone',
  'Business Phone',
  'Other Phone',
  'Personal Notes',
  'Birthday',
  'Home Address - Street',
  'Home Address - City',
  'Home Address - Country/Region',
  'Home Address - Postal Code',
  'Home Address - State',
  'Home Address - Fully Formatted',
  'Business Address - Street',
  'Business Address - City',
  'Business Address - Country/Region',
  'Business Address - Postal Code',
  'Business Address - State',
  'Business Address - Fully Formatted',
  'Other Address - Street',
  'Other Address - City',
  'Other Address - Country/Region',
  'Other Address - Postal Code',
  'Other Address - State',
  'Other Address - Fully Formatted',
];

export const formatDataArray = (dataArray: any[], prop: string) => {
  const formattedDataArray: any[] = dataArray.map((o) => ({
    value: o[prop] || '',
    label: o[prop] || '',
    selected: o.metadata?.primary || false,
  }));
  if (
    formattedDataArray[0] &&
    !formattedDataArray.find((o) => o.selected === true)
  ) {
    formattedDataArray[0].selected = true;
  }
  return formattedDataArray.length > 1
    ? formattedDataArray
    : formattedDataArray[0]
    ? formattedDataArray[0].value
    : '';
};

export const formatStreetAddress = (firstLine, secondLine) => {
  if (firstLine) {
    return `${firstLine}${secondLine ? ', ' + secondLine : ''}`;
  }
  return secondLine ?? '';
};

export const formatContacts = (results: any[]) => {
  let contacts;
  try {
    contacts = [GOOGLE_CONTACTS_HEADERS].concat(
      results.map((contact) => {
        let homeAddresses = contact.addresses
          ?.filter(({ type }) => type === 'home')
          .map((address) => {
            return {
              street: formatStreetAddress(
                address?.streetAddress,
                address?.extendedAddress,
              ),
              ...address,
            };
          });
        let workAddresses = contact.addresses
          ?.filter(({ type }) => type === 'work')
          .map((address) => {
            return {
              street: formatStreetAddress(
                address?.streetAddress,
                address?.extendedAddress,
              ),
              ...address,
            };
          });
        let otherAddresses = contact.addresses
          ?.filter(({ type }) => type !== 'home' && type !== 'work')
          .map((address) => {
            return {
              street: formatStreetAddress(
                address?.streetAddress,
                address?.extendedAddress,
              ),
              ...address,
            };
          });
        return [
          contact.names?.[0]?.displayName ?? '',
          contact.names?.[0]?.honorificPrefix ?? '',
          contact.names?.[0]?.givenName ?? '',
          contact.names?.[0]?.middleName ?? '',
          contact.names?.[0]?.familyName ?? '',
          contact.nicknames?.[0]?.value ?? '',
          //contact.photos?.[0]?.url,
          contact.emailAddresses
            ? formatDataArray(contact.emailAddresses, 'value')
            : '',
          contact.organizations?.[0]?.title ?? '',
          contact.organizations?.[0]?.name ?? '',
          contact.organizations?.[0]?.department ?? '',
          contact.phoneNumbers
            ? formatDataArray(
                contact.phoneNumbers.filter(({ type }) => type === 'main'),
                'value',
              )
            : '',
          contact.phoneNumbers
            ? formatDataArray(
                contact.phoneNumbers.filter(({ type }) => type === 'home'),
                'value',
              )
            : '',
          contact.phoneNumbers
            ? formatDataArray(
                contact.phoneNumbers.filter(({ type }) => type === 'mobile'),
                'value',
              )
            : '',
          contact.phoneNumbers
            ? formatDataArray(
                contact.phoneNumbers.filter(({ type }) => type === 'work'),
                'value',
              )
            : '',
          contact.phoneNumbers
            ? formatDataArray(
                contact.phoneNumbers?.filter(
                  ({ type }) =>
                    type !== 'main' &&
                    type !== 'home' &&
                    type !== 'mobile' &&
                    type !== 'work',
                ),
                'value',
              )
            : '',
          contact.biographies?.[0]?.value ?? '',
          contact.birthdays?.[0]?.text ?? '',
          homeAddresses ? formatDataArray(homeAddresses, 'street') : '',
          homeAddresses ? formatDataArray(homeAddresses, 'city') : '',
          homeAddresses ? formatDataArray(homeAddresses, 'country') : '',
          homeAddresses ? formatDataArray(homeAddresses, 'postalCode') : '',
          homeAddresses ? formatDataArray(homeAddresses, 'region') : '',
          homeAddresses ? formatDataArray(homeAddresses, 'formattedValue') : '',
          workAddresses ? formatDataArray(workAddresses, 'street') : '',
          workAddresses ? formatDataArray(workAddresses, 'city') : '',
          workAddresses ? formatDataArray(workAddresses, 'country') : '',
          workAddresses ? formatDataArray(workAddresses, 'postalCode') : '',
          workAddresses ? formatDataArray(workAddresses, 'region') : '',
          workAddresses ? formatDataArray(workAddresses, 'formattedValue') : '',
          otherAddresses ? formatDataArray(otherAddresses, 'street') : '',
          otherAddresses ? formatDataArray(otherAddresses, 'city') : '',
          otherAddresses ? formatDataArray(otherAddresses, 'country') : '',
          otherAddresses ? formatDataArray(otherAddresses, 'postalCode') : '',
          otherAddresses ? formatDataArray(otherAddresses, 'region') : '',
          otherAddresses
            ? formatDataArray(otherAddresses, 'formattedValue')
            : '',
        ];
      }),
    );
  } catch {
    console.error('No Google contacts');
    contacts = [
      GOOGLE_CONTACTS_HEADERS,
      Array.from({ length: GOOGLE_CONTACTS_HEADERS.length }).fill(''),
    ];
  }
  return contacts;
};
