import { Box, Text } from 'components';
import styled from 'styled-components';

export const StyledBox = styled(Box).attrs({ px: 10 })<{
  index: number;
  colsLength: number;
  rowsLength: number;
  colIndex: number;
}>`
  min-width: 250px;
  max-width: 250px;
  overflow: visible;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.lighter};
  border-left: solid 1px ${({ theme }) => theme.neutral.lighter};
  border-top: solid
    ${({ index, theme }) => `${index === 0 ? 1 : 0}px ${theme.neutral.lighter}`};
  border-right: solid
    ${({ theme, colIndex, colsLength }) =>
      `${colIndex === colsLength ? 1 : 0}px ${theme.neutral.lighter}`};
  border-bottom-left-radius: ${({ index, rowsLength, colIndex }) =>
    index === rowsLength && colIndex === 0 ? 3 : 0}px;
  border-top-left-radius: ${({ index, colIndex }) =>
    index === 0 && colIndex === 0 ? 3 : 0}px;
  border-bottom-right-radius: ${({ index, rowsLength, colsLength, colIndex }) =>
    index === rowsLength && colIndex === colsLength ? 3 : 0}px;
  border-top-right-radius: ${({ index, colIndex, colsLength }) =>
    index === 0 && colIndex === colsLength ? 3 : 0}px;
`;

export const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
