export const STORAGE_KEY = 'SMB_FEATURES';

export const get = () => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
  } catch {
    clear();
    return [];
  }
};

export const clear = () => {
  localStorage.removeItem(STORAGE_KEY);
};

const featureStorage = {
  get,
  clear,
};

export default featureStorage;
