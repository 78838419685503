import React, { useEffect } from 'react';
import useMethods, { StateAndCallbacksFor } from 'use-methods';

type CarouselState = {
  total: number;
  current: number;
  stepSize: number;
  slideMinWidth?: number;
  showArrows?: boolean;
  showDots?: boolean;
};

const INITIAL_STATE: CarouselState = {
  stepSize: 1,
  current: 0,
  total: 0,
  showArrows: true,
  showDots: true,
};
type Context = StateAndCallbacksFor<typeof methods>;
const CarouselContext = React.createContext<Context | null>(null);

const methods = (state: CarouselState) => ({
  reset() {
    return INITIAL_STATE;
  },
  setCurrent(index: number) {
    state.current = index;
  },
  setTotal(n: number) {
    state.total = n;
  },
  next() {
    if (state.current < Math.ceil(state.total / state.stepSize)) {
      state.current++;
    }
  },
  prev() {
    if (state.current !== undefined && state.current !== 0) {
      state.current--;
    }
  },
  setStepSize(n: number) {
    state.stepSize = n;

    if (state.current >= Math.floor(state.total / state.stepSize)) {
      state.current = Math.floor(state.total / state.stepSize);
    }
  },
  setShowArrows(showArrows: boolean) {
    state.showArrows = showArrows;
  },
  setShowDots(showDots: boolean) {
    state.showDots = showDots;
  },
});

export const CarouselProvider = ({
  children,
  total = 0,
  stepSize = 1,
  slideMinWidth,
  showArrows = true,
  showDots = true,
}: React.PropsWithChildren<Partial<CarouselState>>) => {
  const initState: CarouselState = {
    ...INITIAL_STATE,
    total,
    stepSize,
    slideMinWidth,
    showArrows,
    showDots,
  };
  const [reducerState, callbacks] = useMethods(methods, initState);

  useEffect(() => {
    callbacks.setStepSize(stepSize);
  }, [stepSize, callbacks]);

  useEffect(() => {
    callbacks.setTotal(total);
  }, [total, callbacks]);

  useEffect(() => {
    callbacks.setShowArrows(showArrows);
  }, [showArrows, callbacks]);

  useEffect(() => {
    callbacks.setShowDots(showDots);
  }, [showDots, callbacks]);

  useEffect(() => {
   if (reducerState.current === Math.ceil(total / reducerState.stepSize)) {
    callbacks.prev();
   }
  }, [total, callbacks, reducerState]);

  return (
    <CarouselContext.Provider value={[reducerState, callbacks]}>
      {children}
    </CarouselContext.Provider>
  );
};

export default CarouselProvider;

export const useCarousel = (): Context => {
  const context = React.useContext(CarouselContext);

  if (context === null) {
    throw new Error('Carousel Context Failed to initiate');
  }

  return context;
};
