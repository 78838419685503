import { createSelector } from 'reselect';
import { getAllLabelSizes } from 'ducks/labelSizes/selectors';
import { getCategoriesSuggestions } from 'ducks/search/selectors';
import { LabelStockType } from 'zsbpsdk/src/labels';

type Category = { name: string; logo: string; id: string; desc: string };

export const filteredCategories = (state: any) => state.categories.filtered;

export const getFilteredCategories = createSelector(
  [filteredCategories],
  (categories) => (categories ? categories.data : []),
);

export const selectCategoriesWithLabel = createSelector(
  [filteredCategories, getAllLabelSizes],
  (categories, { others = [] }: { others: LabelStockType[] }) => {
    const data = addLabelToCategories(categories.data || [], others);
    return {
      ...categories,
      data,
    };
  },
);

export const selectSearchCategoriesWithLabel = createSelector(
  [getCategoriesSuggestions, getAllLabelSizes, filteredCategories],
  (
    categories: Category[],
    { others = [] }: { others: LabelStockType[] },
    filteredCategories,
  ) => {
    return {
      searchCategories: addLabelToCategories(categories, others),
      error: filteredCategories.error || null,
    };
  },
);

function addLabelToCategories(
  categories: Category[],
  labels: LabelStockType[],
) {
  return categories.map((category) => {
    const label = labels.find((labelType) => labelType.id === category.id);
    return { category, label };
  });
}
