import * as React from 'react';
import { Card, Flex, PageHeader } from 'components';

const HeadersView = (): any => {
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column" width="100%">
        <h2>Headers</h2>
        <PageHeader
          onClick={() => {
            console.log('Close Workspaces');
          }}
        />
      </Flex>
    </Card>
  );
};

export default HeadersView;
