import { generateDataTypes, generateDataActions } from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_CATEGORIES_';

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  FILTERED: generateDataTypes(BASE_DUCK_PREFIX + 'FILTERED'),
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  FILTERED: generateDataActions(types.FILTERED),
};
