import React, { useCallback } from 'react';
import { LabelRangePicker, Card, Button, Flex, Box } from 'components';
import { ThemeContext } from 'styled-components/macro';
import { selectedRows, rawData } from './data/labelranges';
import { usePrintDialog } from '../../../components/app/print-dialog/PrintDialogContext';
import { useLabelRange } from '../../../components/app/print-dialog/LabelRangeContext';

const LabelRangePickerWrap = () => {
  const theme = React.useContext(ThemeContext);
  const [, { setSelectedLabels, reset: resetPrintDialog }] = usePrintDialog();
  const [, { setLabelRange, reset: resetLabelRange }] = useLabelRange();

  const loadData = useCallback(() => {
    setLabelRange(rawData);
    setSelectedLabels(selectedRows.selected);
  }, [setLabelRange, setSelectedLabels]);

  const clearData = useCallback(() => {
    resetPrintDialog();
    resetLabelRange();
  }, [resetPrintDialog, resetLabelRange]);
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Label Range Picker</h2>
        <Flex flexDirection="row">
          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'primary'}
              size={'xl'}
              iconColor={theme.white}
              iconSize={16}
              iconName="settings"
              onClick={loadData}
            >
              Load data
            </Button>
          </Box>

          <Box mr={5}>
            <Button
              icon={'right'}
              variant={'primary'}
              size={'xl'}
              iconColor={theme.white}
              iconSize={16}
              iconName="settings"
              onClick={clearData}
            >
              Clear data
            </Button>
          </Box>
        </Flex>
        <Box mt={50}>
          <LabelRangePicker />
        </Box>
      </Flex>
    </Card>
  );
};

export default LabelRangePickerWrap;
