import React from 'react';
import ReactPaginate from 'react-paginate';

import styled from 'styled-components/macro';

import { useUrlParam } from 'hooks';
import { PAGE_NUMBER_DEFAULT } from 'utils/defaults';

import ChevronArrow from './Chevron_arrow.svg';
import ChevronArrowDisabled from './Chevron_arrow_disabled.svg';
import Break from './Break.svg';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    background: ${({ theme }) => theme.white};
    gap: ${({ theme }) => theme.px(4)};
    padding: ${({ theme }) => theme.px(4)};
    margin: 0;
    border: 1px solid ${({ theme }) => theme.neutral.lightest};
    border-radius: ${({ theme }) => theme.px(4)};
  }

  .pagination > li {
    display: inline;

    > a,
    > span {
      transition: box-shadow 0.3s;
      text-align: center;
      position: relative;
      float: left;
      padding: ${({ theme }) => theme.px(3, 2)};
      width: ${({ theme }) => theme.px(12)};
      height: ${({ theme }) => theme.px(10)};
      line-height: 20px;
      font-size: 14px;
      color: ${({ theme }) => theme.primary.base};
      text-decoration: none;
      background-color: ${({ theme }) => theme.white};
      border-radius: ${({ theme }) => theme.px(4)};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.primary.dark};
        cursor: pointer;
        background-color: ${({ theme }) => theme.primary.lightest};
      }

      &:hover {
        z-index: 2;
      }

      &:focus {
        z-index: 4;
        outline: none;
        border: 1px solid ${({ theme }) => theme.primary.base};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.primary.base};
      }
    }

    &:first-child,
    &:last-child {
      a {
        background-image: url(${ChevronArrow});
        background-repeat: no-repeat;
        background-position: 50%;
      }
    }

    &:first-child {
      a {
        transform: scaleX(-1);
      }
    }
  }

  .pagination > .active {
    > a,
    > a:hover,
    > a:focus,
    > span,
    > span:hover,
    > span:focus {
      z-index: 3;
      color: ${({ theme }) => theme.white};
      cursor: default;
      background-color: ${({ theme }) => theme.primary.dark};
      border-color: ${({ theme }) => theme.primary.dark};
    }
  }

  .pagination > .break {
    a {
      background-image: url(${Break});
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }

  .pagination > .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span,
    > span:hover,
    > span:focus {
      color: ${({ theme }) => theme.textColors.med};
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.neutral.lightest};
    }

    &:first-child,
    &:last-child {
      a {
        background-image: url(${ChevronArrowDisabled});
        background-repeat: no-repeat;
        background-position: 50%;
        transform: scaleX(1);
      }
    }

    &:last-child {
      transform: scaleX(-1);
    }
  }
`;

type CustomPaginationProps = {
  pageCount: number;
};

const Pagination = ({ pageCount }: CustomPaginationProps) => {
  const [pageNumber, setPageNumber] = useUrlParam(
    'pageNumber',
    PAGE_NUMBER_DEFAULT,
  );

  return (
    <PaginationWrapper
      aria-label={'pagination'}
      data-testid="pagination-wrapper"
    >
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel={' '}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={({ selected: pageNumber }) => {
          setPageNumber(pageNumber + 1);
        }}
        forcePage={(() => {
          const page = parseInt(pageNumber);
          return isNaN(page) ? undefined : page - 1;
        })()}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
