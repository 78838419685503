import styled from 'styled-components';
import { Flex, Box } from 'components';
import { device } from 'utils/mediaQueries';

export const HeaderWrap = styled(Flex)`
  position: relative;
`;

type HeaderType = {
  noBackground?: boolean;
  isVideo?: boolean;
  variant?: string;
  path?: string;
};

export const Header = styled(Box)<HeaderType>`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: left 0.6s ease-out, width 0.6s ease-out, opacity 0.6s ease-out;

  ${({ noBackground, isVideo, variant, theme, path }) =>
    !noBackground &&
    !isVideo &&
    `
      height: 520px;
      min-height: 100vh;
      background-repeat: no-repeat;
      background-color: ${theme.primary.background};
      ${path && `background-image: url(${path})`};
      background-size: 100%
      ${variant === 'small' ? '260' : '520'}px;

      @media ${device.tablet} {
        min-height: calc(100vh - 71px);
      }
      `}

  ${({ isVideo, variant }) =>
    isVideo &&
    `
    height: ${variant === 'small' ? '260' : '520'}px;
`}

video {
    display: none;
    width: 100%;
    object-fit: cover;
    ${({ isVideo, variant }: any) =>
      isVideo &&
      `
      display: block;
      height: ${variant === 'small' ? '260' : '520'}px;
  `}
  }

  @media ${device.mobile} {
    top: 71px;
    height: 260px;
  }

  @media ${device.mobileToTablet} {
    top: 71px;
  }
`;
