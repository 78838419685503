/** Measurement units */
export enum MeasurementUnit {
  Inch = 'Inch',
  Mm = 'Millimeter',
  Cm = 'Centimeter',
  Mils = 'Mils',
}

/**
 * Unit converter class, used to convert from internal unit values to specified
 * measurement units and vice-versa.
 */
export class UnitConverter {
  private static readonly InternalUnitValue: number = 10000;

  /**
   * Convert internal unit to value of specified unit of measurement.
   * @param valueInInternalUnit The value in internal units
   * @param measurementUnit  Unit of measurement
   */
  public static toMeasurementUnit(
    valueInInternalUnit: number,
    measurementUnit: MeasurementUnit,
  ): number {
    switch (measurementUnit) {
      case MeasurementUnit.Cm:
        const cm: number =
          valueInInternalUnit / UnitConverter.InternalUnitValue;

        return cm;
      case MeasurementUnit.Inch:
        const inch: number =
          valueInInternalUnit / UnitConverter.InternalUnitValue / 2.54;

        return inch;
      case MeasurementUnit.Mm:
        const mm: number =
          (valueInInternalUnit / UnitConverter.InternalUnitValue) * 10;

        return mm;
      case MeasurementUnit.Mils:
        const mils: number =
          (valueInInternalUnit / UnitConverter.InternalUnitValue / 2.54) * 1000;

        return mils;
      default:
        throw new Error(
          'Cannot convert to specified unit. Invalid measurement unit.',
        );
    }
  }

  /**
   * Convert value in internal units to value of specified unit of measurement.
   * @param valueInMeasurementUnit The value in internal units
   * @param measurementUnit Unit of measurement
   */
  public static toInternalUnit(
    valueInMeasurementUnit: number,
    measurementUnit: MeasurementUnit,
  ): number {
    switch (measurementUnit) {
      case MeasurementUnit.Cm:
        return UnitConverter.InternalUnitValue * valueInMeasurementUnit;
      case MeasurementUnit.Mm:
        return (UnitConverter.InternalUnitValue * valueInMeasurementUnit) / 10;
      case MeasurementUnit.Inch:
        return UnitConverter.InternalUnitValue * valueInMeasurementUnit * 2.54;
      case MeasurementUnit.Mils:
        return (
          (UnitConverter.InternalUnitValue * valueInMeasurementUnit * 2.54) /
          1000
        );
      default:
        throw new Error(
          'Cannot convert to internal unit. Invalid measurement unit.',
        );
    }
  }
}

export const formattedBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
