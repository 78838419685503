import React from 'react';
import { useReduxSelector } from 'hooks';
import { getSelectedPrinter } from 'ducks/printers/selectors';
import styled from 'styled-components';
import { Box, Flex, Text } from 'components';
import { useTranslation } from 'react-i18next';
import { getAssetPath } from 'utils/files';
import { device } from 'utils/mediaQueries';

const Container = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-bottom: 4px;
  padding-left: 1px;
  padding-right: 5px;

  @media ${device.desktop} {
    width: 528px;
  }

  @media ${device.tablet} {
    width: 55%;
  }

  @media ${device.mobile} {
    width: calc(100% - 5px);
  }
`;

const Section = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[11]}px;
`;

const ContentText = styled(Text).attrs(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.med,
  marginBottom: '24px',
}))``;

const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14x',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.high,
  marginBottom: '4px',
}))``;

const SectionDescription = styled(Text).attrs(({ theme }) => ({
  fontSize: '16x',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.med,
}))``;

const StyledLink = styled.a`
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.4px;
text-decoration: underline;
color: ${({ theme }) => theme.primary.base};
`;

export const WiFi = () => {
  const { t } = useTranslation('settings');

  const printer = useReduxSelector(getSelectedPrinter);

  const QRCodeLink = () => {
    return (
      <StyledLink href="https://zsbsupport.zebra.com/s/article/000021483" target="_blank" rel="noopener noreferrer" >
        {t('settings:manage-networks-description-link')}
      </StyledLink>
    )
  }

  return (
    <Container>
      <ContentWrapper>
        <Flex width={'100%'} flexDirection={'column'}>
          <Section>
            <ContentText data-testid="network-management-description">
              {t('settings:wi-fi-management')}
            </ContentText>
            <SectionTitle data-testid="network-status-title">
              {t('settings:network-status')}
            </SectionTitle>
            <SectionDescription 
              data-testid="network-status-value" 
              marginBottom={"24px"}
            >
              {printer?.wlan?.strength > 0
                ? t('settings:connected')
                : t('settings:not-connected')}
            </SectionDescription>
            <SectionTitle data-testid="manage-networks-title">
              {t('settings:manage-networks')}
            </SectionTitle>
            <SectionDescription 
              data-testid="manage-networks-description" 
              marginBottom={"16px"}
            >
              {t('settings:manage-networks-description')}
              {' '}<QRCodeLink />{' '}
              {t('settings:manage-networks-description-end')}
            </SectionDescription>
            <img
              data-testid="manage-networks-qr"
              src={getAssetPath('/manage-networks-qr.png')}
              title={`Manage Networks QR-Code`}
              alt={`Manage networks QR code`}
              width="96"
              height="96"
            />
          </Section>
        </Flex>
      </ContentWrapper>
    </Container>
  );
};
