import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Flex, Text, Box, Button } from 'components';
import { getDeleteAccount as selectDeleteAccount } from 'ducks/auth/selectors';

type CustomDeleteAccountModalProps = {
  handleModalClose: () => void;
  open: boolean;
};

const DeleteAccountErrorModal = ({
  handleModalClose,
  open,
}: CustomDeleteAccountModalProps) => {
  const { t } = useTranslation(['settings', 'common']);
  const ref = useRef(null);

  const { error } = useSelector(selectDeleteAccount);

  const handleDeleteAccountErrorModalClose: () => void = () => {
    handleModalClose();
  };

  return (
    <Modal
      data-testid="delete-account-error-modal"
      ref={ref}
      onClose={handleDeleteAccountErrorModalClose}
      small
      open={open}
      isAnimatedBackdrop={false}
      hasCloseButton={false}
    >
      <Box mb={16}>
        <Text fontSize={24} fontWeight={300}>
          {error?.title}
        </Text>
      </Box>

      <Text fontSize={16} fontWeight={400} lineHeight={'24px'}>
        {error?.description}
      </Text>

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="delete-account-error-ok-button"
          onClick={handleDeleteAccountErrorModalClose}
          variant={'primary'}
        >
          {t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteAccountErrorModal;
