import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'components';
import { Log } from 'pages/notifications/components/Log';

import { useReduxAction } from 'hooks';
import { PageTitle } from 'v2/components/common';
import { HOME_PAGE_PATH } from 'utils/config';
import { push } from 'connected-react-router';
import Page from '../Page';

const Notifications = () => {
  const pushAction = useReduxAction(push);
  const { t } = useTranslation('notifications');
  return (
    <Page>
      <Box data-testid="all-notifications">
        <PageTitle
          title={t('notifications:notifications-title')}
          onBackClick={() => pushAction(HOME_PAGE_PATH)}
        />
        <Log />
      </Box>
    </Page>
  );
};
export default Notifications;
