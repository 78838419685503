export type GoogleToken = {
  tokenResponse: google.accounts.oauth2.TokenResponse;
  expires: number; // UNIX timestamp expiration date in milliseconds
};

export const buildGoogleToken = (
  tokenResponse: google.accounts.oauth2.TokenResponse,
): GoogleToken => {
  const now = Date.now();

  return {
    tokenResponse: tokenResponse,
    expires: now + parseInt(tokenResponse.expires_in) * 1000,
  };
};

export const isGoogleTokenExpired = (
  googleToken: GoogleToken,
): boolean | undefined => {
  return googleToken ? Date.now() >= googleToken.expires : undefined;
};

class GoogleTokenStorage {
  private readonly key = 'GOOGLE_SERVICES';
  private _token?: GoogleToken;

  constructor() {
    this._token = this.getToken();
  }

  getToken() {
    this._token = JSON.parse(localStorage.getItem(this.key) || 'null');
    return this._token;
  }

  setToken(token: GoogleToken) {
    localStorage.setItem(this.key, JSON.stringify(token));
    this._token = token;
  }

  clearToken() {
    localStorage.removeItem(this.key);
    this._token = undefined;
  }
}

export default GoogleTokenStorage;
