import { RootState } from 'store';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates/index';
import { createSelector } from 'reselect';
import {
  getSizesFromTemplates,
  MODIFIABLE_DATA_FIELDS,
  SETTABLE_DATA_FIELDS,
} from 'utils/templates';
import { BaseInitialState } from 'ducks/utils';

import { TemplateCategory } from '../../utils/categories';

export const allTemplatesData = (state: any) => state.templates.all.data;
export const allTemplatesLoading = (state: any) => state.templates.all.loading;

const myDesignSizes = (state: any) => state.templates.myDesignSizes; //this is the new piece of data that is used to create the selector for loading, error and data
export const myDesignSize = (state: any) => state.templates.myDesignSizes.data;
export const myDesignSizeError = (state: any) =>
  state.templates.myDesignSizes.error;
export const isLoadingMyDesignSizeSelector = (state: any) =>
  state.templates.myDesignSizes.loading;

const allZebraTemplatesData = (state: any) => state.templates.zebraAll.data;
export const recentTemplatesData = (state: any) => state.templates.recent.data;
export const recentTemplates = (state: any) => state.templates.recent;
const recentTemplatesLoading = (state: any) => state.templates.recent.loading;
const recentTemplatesError = (state: any) => state.templates.recent.error;
const thumbnail = (state: any) => state.templates.thumbnail;
const thumbnailError = (state: any) => state.templates.thumbnail.error;

const filteredTemplatesHash = (state: RootState) =>
  state.templates.filteredTemplatesHash;

export const filteredTemplates = (
  state: RootState,
): BaseInitialState<TemplatePage> & Record<string, any> =>
  state.templates.filtered;

const filteredTemplatesParams = (state: any) => state.templates.filtered.params;

export const zebraFilteredTemplates = (
  state: RootState,
): BaseInitialState<TemplatePage> => state.templates.zebraFiltered;

export const zebraFilteredTemplatesAllSizes = (state: any) =>
  state.templates.zebraFilteredAllSizes.data;

export const selectZebraFilteredTemplatesAllSizes = createSelector(
  [zebraFilteredTemplatesAllSizes],
  (data: { sizes: { [key: string]: { width: number; height: number } } }) =>
    data ? Object.values(data.sizes) : [],
);

const zebraFilteredTemplatesData = (state: any) =>
  state.templates.zebraFiltered.data;

export const isLoadingFiltered = (state: any) =>
  state.templates.filtered.loading;

const isLoadingUpdate = (state: any) => state.templates.update.loading;
const isUpdateSuccess = (state: any) => state.templates.update.success;
const isUpdateFailure = (state: any) => state.templates.update.error;

export const isLoadingSelectedData = (state: any) =>
  state.templates.selectedData.loading;
export const selectedData = (state: any) => state.templates.selectedData;
const selectedDataData = (state: any) => state.templates.selectedData.data;
const selectedDataErr = (state: any) => state.templates.selectedData.error;

const enteredData = (state: any) => state.templates.enteredData;

const isLoadingDuplicate = (state: any) => state.templates.duplicate.loading;
const isDuplicateSuccess = (state: any) => state.templates.duplicate.success;
const isDuplicateError = (state: any) => state.templates.duplicate.error;

export const selectedTemplate = (state: any) => state.templates.selected.data;

const isLoadingRemove = (state: any) => state.templates.remove.loading;
const isRemoveSuccess = (state: any) => state.templates.remove.success;

const isLoadingPrint = (state: any) => state.templates.print.loading;
const isPrintSuccess = (state: any) => state.templates.print.success;
const isTestPrintLoading = (state: any) => state.templates.testPrint.loading;
const isTestPrintSuccess = (state: any) => state.templates.testPrint.success;

const isPrintError = (state: any) => state.templates.print.error;
const isPrintJobIdAvailable = (state: any) => state.templates.print.data?.jobId;
const printSuccessData = (state: any) => state.templates.print.data;

const selectedFieldOrder = (state: any) =>
  state.templates.selected.data.fieldOrder;

const isLoadingLabelPreview = (state: any) =>
  state.templates.labelPreview.loading;
const imagePreview = (state: any) => state.templates.labelPreview.data;
// here
const imagePreviewError = (state: any) => state.templates.labelPreview.error;

export const templateDialog = (state: any) =>
  state.templates.templateDialog.data;
export const isTemplateDialogLoading = (state: any) =>
  state.templates.templateDialog.loading;

export const getFilteredTemplatesParams = createSelector(
  [filteredTemplatesParams],
  (params) => params,
);

export const getAllTemplates = createSelector(
  [allTemplatesData],
  (templatePage: TemplatePage): TemplateType[] =>
    templatePage?.fileDataList ? templatePage.fileDataList : [],
);

export const getAllUserTemplates = createSelector(
  [getAllTemplates],
  (templates: TemplateType[]) =>
    templates
      ? templates.filter(
          (template: TemplateType) =>
            template.tenant !== TemplateCategory.Internal,
        )
      : [],
);

export const getAllTemplatesLoading = createSelector(
  [allTemplatesLoading],
  (loading) => loading,
);

export const getRecentTemplatesLoading = createSelector(
  [recentTemplatesLoading],
  (loading) => loading,
);

export const getRecentTemplatesError = createSelector(
  [recentTemplatesError],
  (error) => error,
);

export const getAllZebraFilteredTemplates = createSelector(
  [zebraFilteredTemplatesData],
  (templatePage: TemplatePage): TemplateType[] =>
    templatePage?.fileDataList || [],
);

export const getAllZebraTemplates = createSelector(
  [allZebraTemplatesData],
  (templatePage: TemplatePage): TemplateType[] =>
    templatePage?.fileDataList ? templatePage.fileDataList : [],
);

export const getZebraFilteredTemplatesSizes = createSelector(
  [zebraFilteredTemplatesData],
  (templatePage: TemplatePage) =>
    templatePage?.fileDataList
      ? getSizesFromTemplates(templatePage.fileDataList)
      : [],
);

export const getTemplatesSizes = createSelector(
  [getAllTemplates],
  (templates: TemplateType[]) =>
    templates ? getSizesFromTemplates(templates) : [],
);

export const getUserTemplatesSizes = createSelector(
  [getAllUserTemplates],
  (templates: TemplateType[]) =>
    templates ? getSizesFromTemplates(templates) : [],
);

// We used this selector until metadata/size endpoint is unstable and sometimes returns 404 although displayed templates on the screen
export const myDesignSizeSelector = createSelector(
  [myDesignSizeError, myDesignSize, getUserTemplatesSizes],
  (withError, myDesignSizes, calculatedSizes) => {
    return withError ? calculatedSizes : myDesignSizes;
  },
);

export const myDesignSizesSelector = createSelector(
  [myDesignSizes],
  (data) => data,
); //this is the new selector which gives access to loading, error and data at once

export const getRecentTemplates = createSelector(
  [recentTemplatesData],
  (templatePage: TemplatePage) =>
    templatePage?.fileDataList?.length ? templatePage?.fileDataList : [],
);

//hanging here
export const getIsLoadingUpdate = createSelector(
  [isLoadingUpdate],
  (loading) => loading,
);

export const getIsLoadingDuplicate = createSelector(
  [isLoadingDuplicate],
  (loading) => loading,
);

// Not working selector
export const getIsUpdateSuccess = createSelector(
  [isUpdateSuccess],
  (success) => success,
);
export const getIsUpdateFailure = createSelector(
  [isUpdateFailure],
  (error) => error,
);

export const getIsDuplicateSuccess = createSelector(
  [isDuplicateSuccess],
  (success) => success,
);
export const getIsDuplicateError = createSelector(
  [isDuplicateError],
  (error) => error,
);

export const getIsRemoveSuccess = createSelector(
  [isRemoveSuccess],
  (success) => success,
);
export const getIsLoadingRemove = createSelector(
  [isLoadingRemove],
  (success) => success,
);

export const getIsPrintSuccess = createSelector(
  [isPrintSuccess],
  (success) => success,
);
export const selectIsTestPrintLoading = createSelector(
  [isTestPrintLoading],
  (isLoading) => isLoading,
);
export const getIsTestPrintSuccess = createSelector(
  [isTestPrintSuccess],
  (success) => success,
);
export const getIsPrintError = createSelector([isPrintError], (error) => error);
export const getIsLoadingPrint = createSelector(
  [isLoadingPrint],
  (success) => success,
);
export const getPrintedJobId = createSelector(
  [isPrintJobIdAvailable],
  (jobId) => jobId,
);

export const getIsLoadingLabelPreview = createSelector(
  [isLoadingLabelPreview],
  (loading) => loading,
);
export const getLabelPreview = createSelector([imagePreview], (image) => image);

export const getIsLabelsPreviewFailed = createSelector(
  [imagePreviewError],
  (err) => err,
);

//selected data
export const getIsLoadingSelectedData = createSelector(
  [isLoadingSelectedData],
  (err) => err,
);

export const getSelectedDatasource = createSelector(
  [selectedDataData],
  (selectedData) => selectedData?.databases,
);

export const getModifiableDatafields = createSelector(
  [selectedDataData],
  (selectedData) =>
    selectedData?.dataSources.filter((dataSource) => {
      return MODIFIABLE_DATA_FIELDS.includes(dataSource.dataSourceType);
    }),
);

export const getSettableDatafields = createSelector(
  [selectedDataData],
  (selectedData) =>
    selectedData?.dataSources.filter((dataSource) => {
      return SETTABLE_DATA_FIELDS.includes(dataSource.dataSourceType); //We presume we dont need to set counter here?
    }),
);
export const getSelectedDataError = createSelector(
  [selectedDataErr],
  (err) => err,
);

export const getSelectedFieldOrder = createSelector(
  [selectedFieldOrder],
  (fieldOrder) => fieldOrder,
);

export const getEnteredData = createSelector(
  [enteredData],
  (enteredData) => enteredData,
);

//to get printed template name for notifications
export const getPrintedTemplateName = createSelector(
  [printSuccessData],
  (obj) => {
    return obj.templateName ? obj.templateName : '';
  },
);

export const getThumbnailLoading = createSelector(
  [thumbnail],
  (thumbnail) => thumbnail.loading,
);

export const getThumbnail = createSelector(
  [thumbnail],
  (thumbnail) => thumbnail?.data?.largeThumbnail,
);

export const getThumbnailError = createSelector(
  [thumbnailError],
  (thumbnailError) =>
    thumbnailError?.error?.message ?? thumbnailError?.error?.toString(),
);

export const selectMyDesignSizesLoaded = createSelector(
  [myDesignSize],
  (data) => Boolean(data),
);

export const selectFilteredTemplatesHash = createSelector(
  [filteredTemplatesHash],
  ({ data, loading }): any => {
    return !loading && data?.totalCount === 0;
  },
);
