import React, { useRef } from 'react';
import { Modal, Flex, Text, Box, Button } from 'components';

import { useTranslation } from 'react-i18next';

import { actions as authActions } from '../../ducks/auth/actions';
import { useReduxAction } from 'hooks';

type CustomDeleteAccountModalProps = {
  handleModalClose: () => void;
  open: boolean;
};

const DeleteAccountSuccessModal = ({
  handleModalClose,
  open,
}: CustomDeleteAccountModalProps) => {
  const { t } = useTranslation(['settings', 'common']);
  const ref = useRef(null);

  const logout = useReduxAction(authActions.LOGOUT);

  const handleDeleteAccountModalSuccessClose: () => void = () => {
    handleModalClose();

    logout();
  };

  return (
    <Modal
      data-testid="delete-account-success-modal"
      ref={ref}
      onClose={handleDeleteAccountModalSuccessClose}
      small
      open={open}
      isAnimatedBackdrop={false}
      hasCloseButton={false}
    >
      <Box mb={16}>
        <Text fontSize={24} fontWeight={300} data-testid="account-deleted">
          {t('settings:account-deleted')}
        </Text>
      </Box>

      <Text fontSize={16} fontWeight={400} lineHeight={'24px'} data-testid="account-delete-success">
        {t('settings:account-delete-success')}
      </Text>

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="delete-account-success-ok-button"
          onClick={handleDeleteAccountModalSuccessClose}
          variant={'primary'}
        >
          {t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteAccountSuccessModal;
