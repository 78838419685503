import { Box, Flex, Icon, Text } from '../../../index';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { TemplateCategory } from 'utils/categories';

const FieldsBox = styled(Box)`
  label {
    display: block;
  }
  background: white;
`;

export const DragHandleContainer = styled.div`
  cursor: move;
  padding-right: 18px;
  width: 26px;
`;

export const PrintInput = ({ template, card, children }) => {
  const theme = useContext(ThemeContext);

  return (
    <FieldsBox pb={'16px'}>
      <Text
        fontSize={14}
        fontWeight={400}
        color={theme.textColors.med}
        letterSpacing={0.4}
        ml={template?.tenant !== TemplateCategory.Internal ? '44px' : '0px'}
        mb={'5px'}
      >
        {card.promptText?.length > 0 ? card.promptText : card.name}
      </Text>
      <Flex flexDirection="row" alignItems="center">
        {template?.tenant !== TemplateCategory.Internal && (
          <DragHandleContainer className="dragHandle">
            <Icon icon="drag-handle" color={theme.textColors.med} />
          </DragHandleContainer>
        )}
        {children}
      </Flex>
    </FieldsBox>
  );
};
