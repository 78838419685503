import React from 'react';
import { Flex } from 'components';
import { useTimeout } from 'hooks';
import {
  NotificationWrap,
  StyledImage,
  TextHolder,
  Message,
  SubTitle,
  FooterText,
  CloseButton,
  CloseIcon,
} from './Notification.styles';

export type NotificationProps = {
  img?: string;
  title?: string;
  detailedMessage?: string;
  footerText?: string;
  timeoutOnClose?: number;
  isRead?: boolean;
  uniqueId?: string;
  onClose?: () => void;
};
const Notification = ({
  img,
  title = '',
  detailedMessage = '',
  footerText,
  onClose,
  timeoutOnClose,
  ...props
}: NotificationProps) => {
  useTimeout(() => {
    if (timeoutOnClose) {
      onClose && onClose();
    }
  }, timeoutOnClose);

  if (!(title || detailedMessage)) {
    return null;
  }

  return (
    <NotificationWrap data-testid="notification" {...props}>
      <Flex flexDirection={'row'} flexWrap="wrap" justifyContent="flex-start">
        {img && <StyledImage data-testid="notification-image" src={img} />}
        <TextHolder>
          <Flex
            flexDirection={'column'}
            flexWrap="wrap"
            justifyContent="flex-start"
            width={'100%'}
          >
            <Message data-testid="notification-title">{title}</Message>
            <SubTitle data-testid="notification-subtitle" mt={'5px'}>
              {detailedMessage}
            </SubTitle>
            {footerText && (
              <FooterText data-testid="notification-footer" mt={'5px'}>
                {footerText}
              </FooterText>
            )}
          </Flex>
        </TextHolder>
      </Flex>
      <CloseButton
        data-testid="notification-close-button"
        onClick={onClose}
        as={'button'}
      >
        <CloseIcon />
      </CloseButton>
    </NotificationWrap>
  );
};

export default Notification;
