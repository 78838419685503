export const members = [
  {
    id: 1,
    avatar: '/newton.png',
    name: 'Newton lam',
    email: 'newton@weareathlon.com',
    role: 'Admin',
    templates: '5',
    lastSeen: Date.now() // expecting UNIX epoch time eventually
      .toString()
      .slice(0, -3),
  },
  {
    id: 2,
    avatar: '/ping.png',
    name: 'Ping Gu',
    email: 'nootnoot@weareathlon.com',
    role: 'Member',
    templates: '20',
    lastSeen: '1577269734',
  },
];
