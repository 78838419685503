import React, { useCallback, useEffect, useState } from 'react';
import {
  AnnouncementWrap,
  Link,
  LinkWrap,
  MessageWrap,
} from './Announcement.styles';
import { BannerMessage, Preferences } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  getBannerMessageContentForLanguage,
  incrementExpirableBannerMessageInfo,
} from 'utils/bannerMessages';
import { getCustomer } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

type AnnouncementType = {
  bannerMessages: BannerMessage[];
  language: Preferences.Language;
  onModalOpen: (BannerMessage) => void;
};

const Announcement = ({
  bannerMessages,
  language,
  onModalOpen,
}: AnnouncementType) => {
  const [nextBannerMessageContent, setNextBannerMessageContent] = useState<
    BannerMessage.Content | undefined
  >(undefined);
  const [currentBannerMessageContent, setCurrentBannerMessageContent] =
    useState<BannerMessage.Content | undefined>(undefined);
  const [shown, setShown] = useState(true);
  const [currentBannerID, setCurrentBannerID] = useState<
    number | undefined
  >(undefined);
  const currentCustomer = useReduxSelector(getCustomer);

  const handleClick = useCallback(() => {
    if (currentBannerMessageContent != null) {
      const linkUrl = currentBannerMessageContent.getLinkurl();
      if (linkUrl != null && linkUrl.trim() !== '') {
        window.open(linkUrl);
      } else {
        onModalOpen(currentBannerMessageContent);
      }
    }
  }, [onModalOpen, currentBannerMessageContent]);

  useEffect(() => {
    let interval;
    let index = 0;

    // In case there is only one expirable banner message, 
    // we make this artificial dublication of the banner
    // to be able to initiate transition, 
    // and update the banner counter when transition ends.
    const expirableBannerMessages = bannerMessages.filter((item) => item.getType() === BannerMessage.BannerMessageType.NEW_FEATURES);
    if (expirableBannerMessages.length === 1) {
      bannerMessages.push(expirableBannerMessages[0]);
    }

    const bannerMessagesLength = bannerMessages.length;

    if (bannerMessagesLength > 0) {
      setCurrentBannerID(bannerMessages[index].getId());
      setCurrentBannerMessageContent(
        getBannerMessageContentForLanguage(bannerMessages[index], language),
      );

      if (bannerMessagesLength > 1) {
        interval = setInterval(() => {
          if (index >= bannerMessagesLength - 1) {
            index = 0;
          } else {
            index++;
          }
          setShown(false);
          setCurrentBannerID(bannerMessages[index].getId());
          setNextBannerMessageContent(
            getBannerMessageContentForLanguage(bannerMessages[index], language),
          );
        }, 10000);
      }
    } else {
      setCurrentBannerMessageContent(undefined);
      setCurrentBannerID(undefined);
    }
    return () => clearInterval(interval);
  }, [bannerMessages, language]);

  const handleTransitionEnd = useCallback(() => {
    if (!shown) {
      incrementExpirableBannerMessageInfo(
        currentCustomer,
        currentBannerID!
      );
      setCurrentBannerMessageContent(nextBannerMessageContent);
      setShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBannerMessageContent, shown]);

  return currentBannerMessageContent != null ? (
    <AnnouncementWrap shown={shown} onTransitionEnd={handleTransitionEnd}>
      <MessageWrap>{currentBannerMessageContent.getMessagetext()}</MessageWrap>
      {currentBannerMessageContent.getLinktext() && (
        <LinkWrap>
          &nbsp;&nbsp;
          <Link href="#!" onClick={handleClick}>
            {currentBannerMessageContent.getLinktext()}
          </Link>
        </LinkWrap>
      )}
    </AnnouncementWrap>
  ) : null;
};

export default Announcement;
