import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components/macro';

import {
  Flex,
  OptionsMenu,
  OptionsMenuItem,
  Tooltip,
  Loader,
} from 'components';
import { useReduxSelector } from 'hooks';
import {
  getCurrentWorkspace,
  getAllWorkspacesLoading,
} from 'ducks/workspaces/selectors';
import getTextAbbreviation from 'utils/getTextAbbreviation';
import { Text } from 'v2/components/common';
import { ImageContainer } from 'components/lib/avatar/Avatar.styles';

const UsernameContainer = styled.span`
  min-width: 115px;
  max-width: 115px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 16px;
`;

const StyledDiv = styled.div`
  padding-left: 5px;
  position: relative;
`;

const StyledFlex = styled(Flex)<any>`
  cursor: ${({ collapsed }) => (collapsed ? 'pointer' : '')};
  overflow: hidden;
`;

const StyledOptionsMenuItem = styled((props) => <OptionsMenuItem {...props} />)`
  overflow: auto;
`;

const WorkspaceFlex = styled(Flex)`
  background: ${({ theme }) => theme.neutral.base};
  padding: 16px 11px;
  max-height: 64px;
  min-height: 64px;
`;

const StyledText = styled(Text).attrs(() => ({
  variant: 'body',
}))`
  max-width: 115px;
  display: inline;
  color: ${({ theme }) => theme.white};
  overflow: hidden;
  white-space: nowrap;
  line-height: 32px;
`;

const StyledTextAbbrev = styled(Text).attrs(() => ({
  variant: 'caption',
  fontWeight: '300',
}))`
  color: ${({ theme }) => theme.primary.base};
  text-align: center;
  padding: ${({ theme }) => theme.px(2)};
`;

export const StyledImg = styled.img`
  border-radius: 8px;
  height: 32px;
  width: 32px;
  object-fit: cover;
`;

const Workgroup2 = ({
  collapsedState,
  showToolTip,
  editWorkspaceModal,
  changeThemeModal,
}: any) => {
  const { t } = useTranslation(['components', 'overview', 'common']);

  const workspace: any = useReduxSelector(getCurrentWorkspace);
  const workspaceLoading: any = useReduxSelector(getAllWorkspacesLoading);

  const optionsMenuRef = useRef(null);

  return (
    <>
      <WorkspaceFlex alignItems="center">
        <StyledFlex
          flexDirection="row"
          width="100%"
          justifyContent="start"
          collapsed={collapsedState}
          onClick={() => {
            if (collapsedState) {
              // @ts-ignore
              editWorkspaceModal.current.open();
              //TODO: Fix this
            }
          }}
        >
          {workspaceLoading ? (
            <Loader visible={true} />
          ) : (
            <>
              <Tooltip
                enabled={collapsedState && showToolTip}
                content={t('components:workspace', { count: 1 })}
                direction="right"
                mt="-18px"
              >
                <ImageContainer data-testid="sidebar-avatar">
                  {workspace?.avatar && workspace?.avatar !== 'null' ? (
                    <StyledImg
                      data-testid="edit-workspace-avatar-image"
                      alt={t('overview:workspace.avatar')}
                      src={`data:image/jpeg;base64, ${workspace?.avatar}`}
                    />
                  ) : (
                    <StyledTextAbbrev data-testid="avatar-initials">
                      {getTextAbbreviation(workspace?.name ?? '')}
                    </StyledTextAbbrev>
                  )}
                </ImageContainer>
              </Tooltip>
              {!collapsedState && (
                <>
                  <UsernameContainer>
                    <StyledText data-testid="sidebar-workspacename">
                      {workspace.name}
                    </StyledText>
                  </UsernameContainer>

                  <StyledDiv>
                    <OptionsMenu
                      ref={optionsMenuRef}
                      iconLight={true}
                      data-testid="workspace-options"
                      positionFixed={true}
                    >
                      <StyledOptionsMenuItem
                        data-testid="edit-workspace"
                        // @ts-ignore
                        onClick={() => editWorkspaceModal.current.open()}
                      >
                        {t('common:edit')}
                      </StyledOptionsMenuItem>
                      <StyledOptionsMenuItem
                        data-testid="change-theme"
                        // @ts-ignore
                        onClick={() => changeThemeModal.current.open()}
                      >
                        {t('overview:theme.change')}
                      </StyledOptionsMenuItem>
                    </OptionsMenu>
                  </StyledDiv>
                </>
              )}
            </>
          )}
        </StyledFlex>
      </WorkspaceFlex>
    </>
  );
};

export default Workgroup2;
