import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';

import {
  fetchBannerMessages,
  selectFilteredBannerMessages,
} from 'state/Announcement';
import AnnouncementModal from 'pages/overview/components/AnnouncementModal';
import { useModal, useReduxSelector } from 'hooks';

import Announcement from './Announcement';

const Announcements = () => {
  const dispatch = useDispatch();

  const [modalBannerMessageContent, setModalBannerMessageContent] = useState<
    BannerMessage.Content | undefined
  >(undefined);
  const filteredBannerMessages = useReduxSelector(selectFilteredBannerMessages);

  const announcementModalRef = useRef(null); //this can be moved into the announcement modal itself
  const announcementModal = useModal(announcementModalRef); //the modal can be refactored in order to not use this

  const handleModalOpen = useCallback(
    (bannerMessageContent) => {
      setModalBannerMessageContent(bannerMessageContent);
      announcementModal.open();
    },
    [setModalBannerMessageContent, announcementModal],
  ); //this can be refactored with the modal

  useEffect(() => {
    dispatch(fetchBannerMessages());
  }, [dispatch]);

  return (
    <>
      {filteredBannerMessages && (
        <Announcement
          bannerMessages={filteredBannerMessages}
          onModalOpen={handleModalOpen}
        />
      )}

      <AnnouncementModal
        ref={announcementModalRef}
        bannerMessageContent={modalBannerMessageContent}
      />
    </>
  );
};

export default Announcements;
