import React, { useCallback } from 'react';
import {
  CloseButton,
  CloseIcon,
  Divider,
  Divider2,
  Message,
  MultilineWrapper,
  Line,
  StyledLink,
  StyledIcon,
  Title,
  ToastWrap,
  ToastProps,
} from './Toast.styles';

const Toast = ({
  open = true,
  variant = 'basic',
  title,
  message,
  link,
  onClose,
  ...props
}: ToastProps) => {
  const handleClose = useCallback(
    (event: React.MouseEvent) => {
      onClose && onClose(event);
    },
    [onClose],
  );

  return !open ? null : (
    <ToastWrap
      data-testid="toast"
      variant={variant}
      elevation={'two'}
      {...props}
    >
      {variant !== 'basic' && <StyledIcon variant={variant} />}
      {title && message ? (
        <MultilineWrapper>
          <Line>
            <Title>{title}</Title>
          </Line>
          <Line>
            <Message>{message}</Message>
          </Line>
        </MultilineWrapper>
      ) : (
        <Title>{title}</Title>
      )}

      {link && (
        <StyledLink
          data-testid="toast-link"
          onClick={(e) => {
            link.onClick(e);
            handleClose(e);
          }}
        >
          {link.name}
        </StyledLink>
      )}
      {title && message ? <Divider2 /> : <Divider />}
      <CloseButton
        data-testid="close-toast"
        onClick={handleClose}
      >
        <CloseIcon />
      </CloseButton>
    </ToastWrap>
  );
};

export default Toast;
