import imageCompression from 'browser-image-compression';
import i18next from 'i18next';

import { supportedWorkspacedAvatarTypes } from 'services/api';

import { INCH } from './unitFormatter';
import { getFileExtension } from 'utils/files';

type Options = {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
  maxIteration?: number;
  exifOrientation?: number;
  onProgress?: (progress: number) => void;
  fileType?: string;
  initialQuality?: number;
};

const DBG = ['%c CompressImage ', 'color:mauve;background:#BADA55'];

export const compressImage = (
  base64Data: string,
  labelSize?: { width: number; height: number },
) => {
  return new Promise<string>((resolve, reject) => {
    console.debug(...DBG);
    if (!labelSize || labelSize.width === 0 || labelSize.height === 0) {
      labelSize = { width: 152400, height: 101600 };
    }

    let canvas = document.createElement('canvas'); //TODO I dont know if we need to add this to the document
    let context = canvas.getContext('2d');
    let dotWidth = Number(labelSize.width * INCH.multiplier * 300);
    let dotHeight = Number(labelSize.height * INCH.multiplier * 300);

    let img = new Image();
    img.onload = () => {
      if (
        Math.max(dotWidth, dotHeight) > Math.max(img.height, img.width) &&
        Math.min(dotWidth, dotHeight) > Math.min(img.height, img.width) &&
        base64Data.length < 3000000
      ) {
        console.debug(...DBG, 'No resize Required', base64Data.length);
        resolve(base64Data);
        return;
      }
      //aspect ratio
      let imgRatio = img.height / img.width;
      let labelRatio = dotHeight / dotWidth;
      if (imgRatio > 1) {
        //Height is bigger;
        if (labelRatio < 1) {
          //Label Width is bigger;
          labelRatio = 1 / labelRatio;

          let tempDotHeight = dotHeight;
          dotHeight = dotWidth;
          dotWidth = tempDotHeight;
        }
      } else {
        //Image Width is bigger
        if (labelRatio > 1) {
          //Label Height is bigger
          labelRatio = 1 / labelRatio;

          let tempDotHeight = dotHeight;
          dotHeight = dotWidth;
          dotWidth = tempDotHeight;
        }
      }
      if (imgRatio > labelRatio) {
        //3/6 image = 0.5, label is 4/6 = 0.67
        canvas.height = dotHeight;
        canvas.width = Math.round(imgRatio * dotHeight);
      } else {
        canvas.height = Math.round(imgRatio * dotWidth);
        canvas.width = dotWidth;
      }
      //At this point the canvas is so correct.
      context?.drawImage(img, 0, 0, canvas.width, canvas.height);
      //More optimizations here please
      let data = canvas.toDataURL('image/jpeg');
      console.debug(...DBG, 'Image Resized', base64Data.length, data.length);
      resolve(data);
    };
    img.src = base64Data;

    //Create canvas with a size of labelSizes
    //Add image to canvas (dont stretch)
    //Export image from canvas as png/jpeg? with new size
  });
};

export const handleUploadedImage = async (
  fileObject: File,
  options: Options,
) => {
  const extension = getFileExtension(fileObject.name.toUpperCase());

  if (!supportedWorkspacedAvatarTypes.includes(`.${extension}`)) {
    throw new Error(i18next.t('workspaces:unsupported-file-type-avatar'));
  }

  const compressedFile = await imageCompression(fileObject, options);
  const fileB64 = await imageCompression.getDataUrlFromFile(compressedFile);

  return fileB64.split(',')[1];
};
