import { createReducer, generateFetchDataReducer } from 'ducks/utils';
import { types } from './actions';
import { combineReducers } from 'redux';
import { FETCH_DATA_INITIAL_STATE } from 'ducks/utils';

const image = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer(types.IMAGE),
);

const dataSource = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer(types.DATASOURCE),
);

export default combineReducers({
  image,
  dataSource,
});
