import { FileDataParams, FileProvider } from './FileProvider';
import { getLocalData } from '../../services/internalDb';

export class LocalFileProvider implements FileProvider {
  async getFileData(file, params: FileDataParams) {
    const localData = await getLocalData(
      file.id,
      params?.customerId,
      params?.workspaceId,
    );
    return localData.content;
  }
}
