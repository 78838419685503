import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

export const Arrow = styled.div`
  @media${device.tablet} {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: calc(13px * -2);
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-width: 14px;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${({ theme }) => theme.white};
  }
`;
