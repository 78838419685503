import { createReducer, generateFetchDataReducer } from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';
import { FETCH_DATA_INITIAL_STATE } from 'ducks/utils';

const templates = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.TEMPLATES),
});

const zebraTemplates = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ZEBRA_TEMPLATES),
});

const categories = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.CATEGORIES),
});

const dataSources = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.DATA_SOURCES),
});

export default combineReducers({
  templates,
  categories,
  dataSources,
  zebraTemplates,
});
