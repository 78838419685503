import styled from 'styled-components';

import { Box } from 'components';
import { Link } from 'components/common/navigation/ExpandableHelpList.styles';
import { AnimatedLoader } from 'components/lib/loader/Loader.styles';

export const AvatarWrap = styled(Box)`
  padding: 16px;
  border-radius: 50%;
  width: 64px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

export const StyledContent = styled(Box)`
  display: flex;
  height: 64px;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
`;

export const StyledBar = styled(Box)`
  position: sticky;
  top: 0px;
  height: 64px;
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.primary.surface};
`;

export const StyledLink = styled(Link).attrs(() => ({
  target: '_blank',
  rel: 'noopener',
}))`
  color: ${({ theme }) => theme.textColors.high};
`;

export const StyledLoader = styled(AnimatedLoader).attrs({
  borderWidth: '3px',
})``;
