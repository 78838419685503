import styled from 'styled-components';
import { Flex } from 'components';

export const StyledDot = styled.span<{ active?: boolean }>`
  padding: ${({ theme }) => theme.px(2)};
  margin-right: ${({ theme }) => theme.px(4)};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ active, theme }) =>
    active ? theme.primary.base : theme.neutral.lighter};
  &&:last-child {
    margin-right: 0;
  }
`;

export const StyledDots = styled(Flex)`
  position: absolute;
  top: 85%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
