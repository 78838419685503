import React, { forwardRef, useState, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  Flex,
  Button,
  Form,
  Box,
  LabelSizeCard,
  Heading3Text,
  LabelText,
  Loader,
} from 'components';
import { useReduxSelector, useReduxAction } from 'hooks';
import {
  selectAllLabelSizes,
  allLabelSizesLoading,
} from 'ducks/labelSizes/selectors';
import { getPathName } from 'ducks/router/selectors';
import { getUnit } from 'ducks/preferences/selectors';
import { CREATE_NEW_DESIGN, HOME_PAGE_PATH } from 'utils/config';
import { MM_IN_ONE_INCH } from 'utils/config.json';

// TO-DO Header is with lower bottom padding, must revisit

const Footer = ({ handleSubmit, hasSelectedLabel, close }: any) => {
  const { t } = useTranslation('common');

  return (
    <Flex justifyContent={'flex-end'}>
      <Form onSubmit={handleSubmit}>
        <Flex>
          <Box mr={[9]}>
            <Button
              data-testid="footer-cancel-button"
              type={'button'}
              variant={'text-primary'}
              onClick={close}
            >
              {t('common:cancel')}
            </Button>
          </Box>
          <Button
            data-testid="footer-continue-button"
            disabled={!hasSelectedLabel}
            type={'submit'}
            variant={'primary'}
          >
            {t('common:continue')}
          </Button>
        </Flex>
      </Form>
    </Flex>
  );
};

type NewLabelModalAtributes = {
  onModalClose?: any;
  pushActionPage?: string;
  props?: any;
};

const NewLabelModal = forwardRef(
  (
    { onModalClose, pushActionPage, ...props }: NewLabelModalAtributes,
    ref: any,
  ) => {
    const { t } = useTranslation(['templates']);

    const [selectedLabel, setSelectedLabel] = useState(null);

    const pushAction = useReduxAction(push);

    const { printerMedia = [], others = [] } =
      useReduxSelector(selectAllLabelSizes);
    const loading = useReduxSelector(allLabelSizesLoading);
    const unit = useReduxSelector(getUnit);
    const pathname = useReduxSelector(getPathName);

    const lastUrl = pathname === CREATE_NEW_DESIGN ? HOME_PAGE_PATH : pathname;
    const onlinePrinterMedia = printerMedia.filter(
      (printer) => printer.isOnline,
    );

    const handleSubmit = () => {
      const label =
        printerMedia.find((label) => label.id === selectedLabel) ||
        others.find((labelOthers) => labelOthers.id === selectedLabel);

      pushAction(
        `/designer?sid=${Date.now()}&stock=${label.id}&size=${Math.round(
          label.displayWidth * MM_IN_ONE_INCH * 1000,
        )}x${Math.round(
          label.displayHeight * MM_IN_ONE_INCH * 1000,
        )}&lastUrl=${encodeURIComponent(lastUrl)}`,
      );
    };

    const closeModal = useCallback(() => {
      ref?.current?.close();
    }, [ref]);

    return (
      <Modal
        data-testid="new-label-modal"
        ref={ref}
        onClose={onModalClose}
        footer={
          <Footer
            hasSelectedLabel={Boolean(selectedLabel)}
            handleSubmit={handleSubmit}
            close={closeModal}
          />
        }
        title={t('templates:create-new-label.new-label')}
        clickOutside={true}
      >
        <Flex
          width={1112}
          alignItems={loading ? 'center' : 'unset'}
          flexDirection={'column'}
        >
          {!loading ? (
            <>
              <Heading3Text>
                {t('templates:create-new-label.select-size')}
              </Heading3Text>
              {onlinePrinterMedia.length > 0 && (
                <>
                  <LabelText mt={[8]}>
                    {t(
                      'templates:create-new-label.media-available-on-your-current-printers',
                    )}
                  </LabelText>
                  <Flex flexWrap={'wrap'}>
                    {onlinePrinterMedia.map((l: any, i: number) => (
                      <Flex
                        key={i}
                        mr={[8]}
                        my={[8]}
                        justifyContent="flex-start"
                      >
                        <LabelSizeCard
                          unit={unit}
                          onClick={() => setSelectedLabel(l.id)}
                          selected={selectedLabel === l.id}
                          label={l}
                          showSerialNumber={true}
                        />
                      </Flex>
                    ))}
                  </Flex>
                  <LabelText mt={[11]}>
                    {t('templates:create-new-label.all-other-sizes')}
                  </LabelText>
                </>
              )}
              <Flex flexWrap={'wrap'}>
                {others.map((l: any, i: number) => (
                  <Flex key={i} mr={[8]} my={[8]} justifyContent="flex-start">
                    <LabelSizeCard
                      unit={unit}
                      onClick={() => setSelectedLabel(l.id)}
                      selected={selectedLabel === l.id}
                      label={l}
                    />
                  </Flex>
                ))}
              </Flex>
            </>
          ) : (
            <Loader visible={true} />
          )}
        </Flex>
      </Modal>
    );
  },
);

export default NewLabelModal;
