import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';

import { useReduxAction } from 'hooks';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';

import Card from './Card';
import { supportedTemplatetypes } from 'services/api';
import { HOME_PAGE_PATH } from 'utils/config';

function UploadTemplateCard() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);

  const fileRef = useRef<HTMLInputElement>(null);
  const addAndPrintFileAction = useReduxAction(
    dataSourcesActions.ADD_AND_PRINT.request,
  );

  const openFileInput = useCallback(() => {
    fileRef.current?.click();
  }, [fileRef]);

  const handleFileInput = useCallback(
    (e) => {
      if (fileRef?.current?.files) {
        pushAction(HOME_PAGE_PATH);
        addAndPrintFileAction({
          file: fileRef?.current?.files?.[0],
          source: 'Label',
        });
        e.target.value = '';
      }
    },
    [addAndPrintFileAction, pushAction],
  );

  return (
    <>
      <input
        name="uploadFile"
        type="file"
        hidden
        accept={supportedTemplatetypes.toString()}
        ref={fileRef}
        onChange={handleFileInput}
        data-testid="localFiles-uploadInput"
      />
      <Card
        data-testid={'import-design'}
        title={t('dialogs:print-label.import-design')}
        caption={t('dialogs:print-label.upload-and-print')}
        icon={'import-design'}
        mb={8}
        onClick={openFileInput}
      />
    </>
  );
}

export default UploadTemplateCard;
