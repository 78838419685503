import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'v2/components/common';
import { FilterDropdown } from 'v2/components/app';
import {
  filteredTemplates as selectFilteredTemplates,
  myDesignSizesSelector as selectMyDesignSizes,
  selectFilteredTemplatesHash,
} from 'ducks/templates/selectors';
import { Checkbox, Flex } from 'components';
import { useReduxSelector } from 'hooks';
import styled from 'styled-components';
import { useMultiSelect } from 'providers/MultipleSelect';

const StyledText = styled(Text).attrs({ variant: 'h2' })`
  text-wrap: nowrap;
`;

const TemplatesLayoutFilter = () => {
  const { t } = useTranslation();
  const [{ selected: selectedTemplates }, { setSelected, clear }] =
    useMultiSelect();

  const { data: myDesignFilteredTemplates, params: myDesignFilteredParams } =
    useReduxSelector(selectFilteredTemplates);
  const { data: myDesignSizes } = useReduxSelector(selectMyDesignSizes);
  const shouldHideFilterDropdown = useReduxSelector(
    selectFilteredTemplatesHash,
  );

  //I.e applying filter or requesting another page
  //We clear the selected tempaltes if there is a change in the query params.
  useEffect(() => {
    clear();
  }, [myDesignFilteredTemplates, clear]);

  if (shouldHideFilterDropdown) return null;

  const filteredTemplates = myDesignFilteredTemplates?.fileDataList || [];

  const startRange =
    ((parseInt(myDesignFilteredParams?.pageNumber) || 1) - 1) *
    parseInt(myDesignFilteredParams?.pageSize);

  const selectedText =
    selectedTemplates.length === filteredTemplates.length
      ? t('common:selected-count-of', {
          from: startRange + 1,
          to: startRange + filteredTemplates.length,
          total: myDesignFilteredTemplates?.totalCount || 0,
        })
      : t('common:selected-count', {
          count: selectedTemplates.length,
        });

  return (
    <Flex mt={16} justifyContent="start" alignItems="center">
      {selectedTemplates.length > 0 && (
        <Flex mr={2}>
          <Checkbox
            checked={selectedTemplates.length > 0}
            indeterminate={selectedTemplates.length < filteredTemplates.length}
            isTriState={true}
            onClick={() => {
              setSelected(
                selectedTemplates.length < filteredTemplates.length
                  ? filteredTemplates
                  : [],
              );
            }}
          />
        </Flex>
      )}
      <StyledText data-testid="showing-designs">
        {selectedTemplates.length > 0
          ? selectedText
          : t('common:showing-designs', {
              length: filteredTemplates.length,
              count: myDesignFilteredTemplates?.totalCount || 0,
            })}
      </StyledText>
      <Flex flexGrow={1} />
      <FilterDropdown sizes={myDesignSizes || []} />
    </Flex>
  );
};

export default TemplatesLayoutFilter;
