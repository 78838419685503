import { appendScriptToBody } from '../../utils/scripts';
import GoogleTokenStorage, {
  buildGoogleToken,
  GoogleToken,
  isGoogleTokenExpired,
} from './GoogleTokenStorage';
import { ALL_MIMETYPES } from '../../components/common/LDAListener';
import { formatContacts } from './googleContacts';
import { removeByteOrderMark } from '../../utils/unicode';
import { CommunicationError } from '../../utils/errorFormatter';
import { GoogleDriveScope } from './GoogleDriveScope';
import { PermissionsNotGrantedError } from '../permissions';
import { joinGoogleScopes } from './scopes';

export const GOOGLE_DRIVE_CREDENTIALS = {
  SDK_URL: 'https://accounts.google.com/gsi/client',
  API: 'https://apis.google.com/js/api.js',
  CLIENT_ID:
    '233946974047-tmigrs9j8ic0ha4seevcq52jl88f7f2f.apps.googleusercontent.com',
  SCOPES_OAUTH: [
    GoogleDriveScope.Email,
    GoogleDriveScope.Profile,
    GoogleDriveScope.OpenId,
  ],
  SCOPES_REQUESTED: [GoogleDriveScope.Drive, GoogleDriveScope.Contacts],
  APP_ID: '233946974047',
  API_KEY: 'AIzaSyAliZdkddq1kyUxmCX_gINHYXScV8d65gM',
  DISCOVERY_DOCS: [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    'https://people.googleapis.com/$discovery/rest?version=v1',
  ],
};

type UserInfo = {
  email: string;
  scope?: string;
};

type RequestedScopesParams = {
  requestedScopes: GoogleDriveScope[];
};

type ConfigureTokenParams = RequestedScopesParams & {
  useNewToken?: boolean;
};

type RetryWithNewTokenParams = RequestedScopesParams & {
  functionToRetry: (googleToken: GoogleToken) => any;
};

class GoogleApi {
  private _isGapiClientInitialized: boolean = false;
  private _tokenClient?: google.accounts.oauth2.TokenClient;
  private _googleTokenStorage: GoogleTokenStorage;

  constructor() {
    this._googleTokenStorage = new GoogleTokenStorage();
  }

  private async ensureInitialized() {
    if (!this._isGapiClientInitialized || this._tokenClient == null) {
      await this.initializeGapiClient();
      await this.initializeTokenClient();
    }
  }

  private async initializeTokenClient(): Promise<google.accounts.oauth2.TokenClient> {
    return new Promise<google.accounts.oauth2.TokenClient>(async (resolve) => {
      if (!this._isGapiClientInitialized) {
        await this.initializeGapiClient();
      }

      appendScriptToBody({
        src: GOOGLE_DRIVE_CREDENTIALS.SDK_URL,
        onload: () => {
          this._tokenClient = window.google?.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_DRIVE_CREDENTIALS.CLIENT_ID,
            scope: joinGoogleScopes([
              ...GOOGLE_DRIVE_CREDENTIALS.SCOPES_REQUESTED,
              GoogleDriveScope.Email,
            ]),
            prompt: '',
            callback: (
              tokenResponse: google.accounts.oauth2.TokenResponse,
            ) => {},
            error_callback: (
              error: google.accounts.oauth2.ClientConfigError,
            ) => {},
          });

          resolve(this._tokenClient);
        },
      });
    });
  }

  private async initializeGapiClient() {
    return new Promise<void>((resolve) => {
      appendScriptToBody({
        src: GOOGLE_DRIVE_CREDENTIALS.API,
        onload: () => {
          gapi.load('client:auth2:picker', () => {
            gapi.client
              .init({
                clientId: GOOGLE_DRIVE_CREDENTIALS.CLIENT_ID,
                apiKey: GOOGLE_DRIVE_CREDENTIALS.API_KEY,
                discoveryDocs: GOOGLE_DRIVE_CREDENTIALS.DISCOVERY_DOCS,
                scope: joinGoogleScopes(
                  GOOGLE_DRIVE_CREDENTIALS.SCOPES_REQUESTED,
                ),
              })
              .then(() => {
                this._isGapiClientInitialized = true;
                resolve();
              });
          });
        },
      });
    });
  }

  private getDeniedScopes(requiredScopes: GoogleDriveScope[]): string[] {
    const googleToken = this._googleTokenStorage.getToken();
    const grantedScopes = googleToken?.tokenResponse.scope;
    return !grantedScopes
      ? requiredScopes
      : requiredScopes.filter(
          (requiredScope) => !grantedScopes.includes(requiredScope),
        );
  }

  private hasDeniedScopes(requiredScopes: GoogleDriveScope[]) {
    return this.getDeniedScopes(requiredScopes).length > 0;
  }

  private async requestNewAccessToken(
    scopes: GoogleDriveScope[],
  ): Promise<GoogleToken> {
    return new Promise(async (resolve, reject) => {
      const tokenClient =
        this._tokenClient ?? (await this.initializeTokenClient());

      // @ts-ignore
      tokenClient.callback = (
        tokenResponse: google.accounts.oauth2.TokenResponse,
      ) => {
        resolve(buildGoogleToken(tokenResponse));
      };

      // @ts-ignore
      tokenClient.error_callback = (
        error: google.accounts.oauth2.ClientConfigError,
      ) => {
        reject(new Error(error.message));
      };

      tokenClient.requestAccessToken({
        scope: joinGoogleScopes(scopes),
        include_granted_scopes: true,
      });
    });
  }

  async configureToken(params: ConfigureTokenParams): Promise<GoogleToken> {
    const { requestedScopes, useNewToken = false } = params;
    const requiredScopes = [
      ...GOOGLE_DRIVE_CREDENTIALS.SCOPES_OAUTH,
      ...requestedScopes,
    ];

    let googleToken = this._googleTokenStorage.getToken();
    if (
      useNewToken ||
      !googleToken ||
      googleToken.tokenResponse.error != null ||
      isGoogleTokenExpired(googleToken) ||
      this.hasDeniedScopes(requiredScopes)
    ) {
      const newToken = await this.requestNewAccessToken(requiredScopes);
      const { error, error_description } = newToken.tokenResponse;
      if (error == null) {
        googleToken = newToken;
        this._googleTokenStorage.setToken(googleToken);
      } else if (error === 'access_denied') {
        throw new PermissionsNotGrantedError();
      } else {
        throw new Error(error_description);
      }
    }

    await this.ensureInitialized();
    window.gapi.client.setToken(googleToken.tokenResponse);

    return googleToken;
  }

  async getLinkedAccount(): Promise<UserInfo | null> {
    let googleToken = this._googleTokenStorage.getToken();
    if (!googleToken) {
      return Promise.resolve(null);
    }

    await this.ensureInitialized();
    try {
      return await this.getUserInfo(googleToken.tokenResponse);
    } catch (e: any) {
      await this.removeLinkedAccount();
      return null;
    }
  }

  async removeLinkedAccount(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const googleToken = this._googleTokenStorage.getToken();
      if (!googleToken) {
        resolve();
        return;
      }

      this._googleTokenStorage.clearToken();

      await this.ensureInitialized();
      window.google.accounts.oauth2.revoke(
        googleToken.tokenResponse.access_token,
        () => {
          resolve();
        },
      );
    });
  }

  private async getUserInfo(
    tokenResponse: google.accounts.oauth2.TokenResponse,
  ): Promise<UserInfo> {
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v3/userinfo`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      },
    );

    if (!response.ok) {
      throw new CommunicationError(await response.text(), response.status);
    }

    const { email } = await response.json();
    const googleToken = this._googleTokenStorage.getToken();
    const scope = googleToken?.tokenResponse.scope;
    return { email, scope };
  }

  private async getConnectionsPage(
    pageSize: number,
    pageToken?: string,
  ): Promise<gapi.client.Response<gapi.client.people.ListConnectionsResponse>> {
    const requestedScopes = [GoogleDriveScope.Contacts];

    return await this.retryWithNewToken({
      requestedScopes,
      functionToRetry: async () => {
        return await window.gapi.client.people.people.connections.list({
          resourceName: 'people/me',
          pageSize,
          pageToken,
          sources: ['READ_SOURCE_TYPE_PROFILE', 'READ_SOURCE_TYPE_CONTACT'],
          personFields:
            'addresses,biographies,birthdays,emailAddresses,names,nicknames,occupations,organizations,phoneNumbers', //photos,relations,skills,urls,calendarUrls,clientData,events,externalIds,genders,interests,locales,locations,memberships,metadata,miscKeywords,coverPhotos,sipAddresses,imClients,ageRanges
        });
      },
    });
  }

  async getContacts(number: number = 100): Promise<any[]> {
    const results: gapi.client.people.Person[] = [];

    let { connections, nextPageToken } = (await this.getConnectionsPage(number))
      .result;
    results.push(...(connections || []));

    while (nextPageToken) {
      ({ connections, nextPageToken } = (
        await this.getConnectionsPage(number, nextPageToken)
      ).result);
      results.push(...(connections || []));
    }

    return formatContacts(results);
  }

  private async exportSpreadsheetFiles(fileId) {
    const mimeType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const files = await window.gapi.client.drive.files.export({
      fileId,
      mimeType,
    });
    return btoa(files.body);
  }

  private async getMediaFiles(fileId) {
    const files = await window.gapi.client.drive.files.get({
      fileId,
      alt: 'media',
    });
    return btoa(removeByteOrderMark(files.body));
  }

  async getFileFromDrive(file): Promise<any> {
    const requestedScopes = [GoogleDriveScope.Drive];
    const getFiles =
      file.mimetype === 'application/vnd.google-apps.spreadsheet'
        ? this.exportSpreadsheetFiles
        : this.getMediaFiles;

    return await this.retryWithNewToken({
      requestedScopes,
      functionToRetry: async () => {
        return await getFiles(file.id);
      },
    });
  }

  async showPicker(tokenResponse: google.accounts.oauth2.TokenResponse) {
    const requestedScopes = [GoogleDriveScope.Drive];

    return new Promise(async (resolve, reject) => {
      try {
        await this.ensureInitialized();
        await this.getUserInfo(tokenResponse);
      } catch (e: any) {
        await this.configureToken({ requestedScopes, useNewToken: true });
      }

      if (this.hasDeniedScopes(requestedScopes)) {
        reject(new PermissionsNotGrantedError());
        return;
      }

      const view = new google.picker.DocsView();
      view.setMimeTypes(
        ALL_MIMETYPES.join(',') + ',application/vnd.google-apps.folder',
      );
      const dialog = new google.picker.PickerBuilder()
        .setSelectableMimeTypes(ALL_MIMETYPES.join(','))
        .setAppId(GOOGLE_DRIVE_CREDENTIALS.APP_ID)
        .setOAuthToken(tokenResponse.access_token)
        .addView(view)
        .setDeveloperKey(GOOGLE_DRIVE_CREDENTIALS.API_KEY)
        .setOrigin(window.location.protocol + '//' + window.location.host)
        .setCallback((data: any) => {
          if (data.action === google.picker.Action.PICKED) {
            resolve(data.docs);
          }

          if (
            [google.picker.Action.PICKED, google.picker.Action.CANCEL].includes(
              data.action,
            )
          ) {
            dialog.dispose();
          }
        })
        .build();

      dialog.setVisible(true);
    });
  }

  private async retryWithNewToken(params: RetryWithNewTokenParams) {
    const { functionToRetry, requestedScopes } = params;

    const googleToken = await this.configureToken({ requestedScopes });

    if (this.hasDeniedScopes(requestedScopes)) {
      throw new PermissionsNotGrantedError();
    }

    try {
      return await functionToRetry(googleToken);
    } catch (e: any) {
      const googleToken = await this.configureToken({
        requestedScopes,
        useNewToken: true,
      });
      if (this.hasDeniedScopes(requestedScopes)) {
        throw new PermissionsNotGrantedError();
      }
      return await functionToRetry(googleToken);
    }
  }
}

const googleApi = new GoogleApi();

export default googleApi;
