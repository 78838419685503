import React, { PropsWithChildren, ReactNode, useEffect } from 'react';

import styled from 'styled-components';

import { Box } from 'components';
import { device } from 'utils/mediaQueries';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getLDAVersions } from 'ducks/info/selectors';
import { actions as infoActions } from 'ducks/info/actions';

import { PageHeader, StickyFooter } from '../components/app';
import { getPathName } from 'ducks/router/selectors';

const HORIZONTAL_PADDING = 80;
const HORIZONTAL_PADDING_MOBILE = 60;

const Wrapper = styled(Box)`
  overflow-x: clip;
  background-color: ${({ theme }) => theme.primary.background};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HorizontalPadding = styled(Box).attrs(() => ({
  px: HORIZONTAL_PADDING,
}))`
  @media ${device.mobile} {
    padding-left: ${HORIZONTAL_PADDING_MOBILE}px;
    padding-right: ${HORIZONTAL_PADDING_MOBILE}px;
  }
`;

type PageProps = {
  footerContent?: ReactNode;
  showFooter?: boolean;
};

const Page = ({ footerContent, children }: PropsWithChildren<PageProps>) => {
  const requestLDAVersions = useReduxAction(infoActions.LDA_VERSIONS.request);

  const versions = useReduxSelector(getLDAVersions);
  const pathname = useReduxSelector(getPathName);

  useEffect(() => {
    if (!versions) {
      requestLDAVersions();
    }

    console.debug(
      '%c LDAVERSIONS INFO ',
      'background:black;color:white;',
      versions,
    );
  }, [versions, requestLDAVersions, pathname]);
  return (
    <>
      <Wrapper>
        <PageHeader />

        <HorizontalPadding>
          <Box pt={14} pb={43}>
            {children}
          </Box>
        </HorizontalPadding>
      </Wrapper>

      {footerContent && (
        <StickyFooter>
          <HorizontalPadding>{footerContent}</HorizontalPadding>
        </StickyFooter>
      )}
    </>
  );
};

export default Page;
