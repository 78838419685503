import styled from 'styled-components/macro';
import Flex from '../../common/flex/Flex';
import Text from '../../common/typography/Typography';

export type VariantType = 'big' | 'small' | 'bigger' | 'medium';
export interface AvatarProps {
  img?: string;
  firstName?: string | null;
  lastName?: string | null;
  variant?: VariantType;
}

const getBase = (width: string, height: string, borderWidth: string) => `
  width: ${width};
  height: ${height};
  border-width: ${borderWidth};
`;

const VariantMap: Map<VariantType, string> = new Map<VariantType, string>([
  ['big', getBase('40px', '40px', '1px')],
  ['small', getBase('24px', '24px', '0')],
  ['bigger', getBase('80px', '80px', '0')],
  ['medium', getBase('32px', '32px', '0')],
]);

export const StyledImage = styled.img<{ variant: VariantType }>`
  border-radius: ${({ variant }) => (variant === 'bigger' ? 'unset' : '50%')};
  border: ${({ theme }) => `solid ${theme.white}`};
  ${({ variant }) => VariantMap.get(variant)};
  object-fit: cover;
`;

export const ImageContainer = styled(Flex).attrs(({ theme }) => ({
  backgroundColor: theme.primary.lightest,
}))`
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.px(16)};
  height: ${({ theme }) => theme.px(16)};
  min-width: ${({ theme }) => theme.px(16)};
  border-radius: ${({ theme }) => theme.px(4)};
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  overflow: hidden;
`;

export const StyledText = styled(Text).attrs(({ theme }) => ({
  backgroundColor: theme.primary.lightest,
  fontSize: 12,
  textAlign: 'center',
  color: theme.primary.base,
}))``;
