import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import Box from 'components/common/box/Box';
import Flex from 'components/common/flex/Flex';
import Text from 'components/common/typography/Typography';
import Icon from '../icon/Icon';
import Card from '../card/Card';

export type ToastVariantType =
  | 'basic'
  | 'error'
  | 'success'
  | 'warning'
  | 'info';
export type ToastLinkType = { name: string; onClick: React.MouseEventHandler };

export type ToastProps = {
  open?: boolean;
  variant?: ToastVariantType;
  title: string;
  message?: string;
  onClose?: React.MouseEventHandler;
  link?: ToastLinkType;
};

export const StyledIcon = styled(Icon).attrs(
  ({ theme, variant }: { theme: DefaultTheme; variant: ToastVariantType }) => ({
    icon: IconMap.get(variant),
    color: theme.white,
    size: 20,
  }),
)`
  padding-top: ${({ theme }) => theme.px(1)};
  padding-left: ${({ theme }) => theme.px(1)};
  margin-right: 17px;
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.white,
  size: 14,
}))`
  margin-left: 21px;
`;

export const Title = styled(Text).attrs(({ theme }) => ({
  fontSize: theme.px(8),
  lineHeight: theme.px(12),
  letterSpacing: '0.4px',
  pr: 15,
}))`
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const Message = styled.pre`
  font-size: ${({ theme }) => theme.px(7)};
  padding-right: 15px;
  margin: 0;
  width: 100%;
  white-space: pre-line;
`;

export const Divider = styled(Box)`
  width: 1px;
  height: ${({ theme }) => theme.px(12)};
  background-color: ${({ theme }) => theme.withOpacity(theme.white, 0.2)};
  margin-left: auto;
`;

export const Divider2 = styled(Divider)`
  height: auto;
  min-height: ${({ theme }) => theme.px(24)};
`;

export const CloseButton = styled(Flex)`
  pointer-events: auto;
  cursor: pointer;
  border: 0 none;
  outline: none;
  background: transparent;
`;

export const ToastWrap = styled(Card).attrs({ justifyContent: 'unset' })<{
  variant: ToastVariantType;
}>`
  background-color: ${({ theme, variant }) => calcBackground(variant, theme)};
  color: ${({ theme }) => theme.white};
  padding: ${({ theme }) => theme.px(6, 18.5, 6, 16)};
  max-width: 800px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const MultilineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled(CloseButton)`
  font-size: ${({ theme }) => theme.px(8)};
  line-height: ${({ theme }) => theme.px(12)};
  letter-spacing: 0.4px;
  text-decoration: underline;
  padding-right: ${({ theme }) => theme.px(9)};
`;

const IconMap: Map<ToastVariantType, string> = new Map<
  ToastVariantType,
  string
>([
  ['error', 'error-filled'],
  ['success', 'check-filled'],
  ['warning', 'warning'],
  ['info', 'info-filled'],
]);

const calcBackground = (variant: ToastVariantType, theme: DefaultTheme) => {
  switch (variant) {
    case 'error':
      return theme.error.base;
    case 'success':
      return theme.success.base;
    case 'warning':
      return theme.warning.base;
    case 'info':
      return theme.info.base;
    case 'basic':
    default:
      return theme.neutral.darkest;
  }
};
