import React from 'react';
import { Text } from 'components';
import styled from 'styled-components/macro';
import IconButton from './IconButton';

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Title = styled(Text).attrs(() => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.4,
}))`
  padding-top: 4px;
  padding-left: 32px;
`;

const IconContainer = ({ name, icons, setClipArt, searchPhrase }) => {
  const IconType = icons?.map((icon) => {
    if (
      (searchPhrase !== '' &&
        icon.name.toLowerCase().includes(searchPhrase.toLowerCase())) ||
      name.toLowerCase().includes(searchPhrase.toLowerCase()) ||
      searchPhrase === ''
    ) {
      return (
        <IconButton
          setClipArt={setClipArt}
          name={icon.name}
          path={icon.path}
          key={`icon-${icon.name}`}
        />
      );
    } else {
      return null;
    }
  });

  return (
    <IconsContainer>
      <InnerContainer>
        <Title>{name}</Title>
      </InnerContainer>
      <InnerContainer>{IconType}</InnerContainer>
    </IconsContainer>
  );
};

export default IconContainer;
