type ScriptAttributes = {
  src: string;
  onload: ((this: GlobalEventHandlers, ev: Event) => any) | null;
};

export const appendScriptToBody = (attrs: ScriptAttributes) => {
  let script: HTMLScriptElement = document.createElement('script');
  script.src = attrs.src;
  script.onload = attrs.onload;
  script.async = true;
  document.body.appendChild(script);
};
