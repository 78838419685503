import React from 'react';
import { goBack } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { PageTitle } from 'v2/components/common';
import { useReduxAction } from 'hooks';

import { AvatarForm } from './AvatarForm';
import { DetailsForm } from './DetailsForm';
import { ActionButtonsFooter } from './ActionButtonsFooter';
import { PageWrapper, FormsContainer } from './Account.styles';
import Page from '../Page';

export const Account = () => {
  const { t } = useTranslation();
  const goBackAction = useReduxAction(goBack);

  return (
    <Page>
      <PageWrapper>
        <PageTitle
          onBackClick={() => goBackAction()}
          title={t('settings:account-settings')}
        />
        <FormsContainer>
          <AvatarForm />
          <DetailsForm />
        </FormsContainer>
        <ActionButtonsFooter />
      </PageWrapper>
    </Page>
  );
};
