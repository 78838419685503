import styled from 'styled-components/macro';
import { Text } from 'components';

const LabelText = styled(Text).attrs(({ theme, color }) => ({
  fontSize: theme.fontSizes.label,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: color ? color : theme.textColors.med,
}))``;

const Heading2Text = styled(Text).attrs(({ theme }) => ({
  fontSize: theme.fontSizes.h2,
  lineHeight: '32px',
  letterSpacing: 0.4,
}))``;

const Heading3Text = styled(Text).attrs(({ theme }) => ({
  fontSize: theme.fontSizes.h3,
  lineHeight: '28px',
  letterSpacing: 0.4,
}))``;

export { LabelText, Heading2Text, Heading3Text };
