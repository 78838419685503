import { ToastOptions } from '../state/Toast';
import i18n from 'i18next';

export class PermissionsNotGrantedError extends Error {}

// This needs to be a function due to need to retrieve localization strings
export const buildPermissionsNotGrantedToastOptions = (): ToastOptions => ({
  variant: 'error',
  title: i18n.t('login:link-file-failed'),
  message: i18n.t('dataSources:required-permissions-not-granted'),
  dismissTime: 10 * 1000,
});
