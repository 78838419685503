import styled from 'styled-components/macro';

import { device } from 'utils/mediaQueries';
import { Flex, TemplateCard, CategoryCard } from 'components';
import { StyledTemplatesCard } from 'components/app/template-card/TemplateCard.styles';

//Not ideal and needs to be refactored
export const StyledTemplateCard = styled(TemplateCard)`
  max-width: unset;

  & ${StyledTemplatesCard} {
    max-width: unset;
  }
`;
//
//Not ideal and needs to be refactored
export const StyledCategoryCard = styled(CategoryCard)`
  width: unset;
  margin: 0;
`;

export const CategoriesWrap = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.px(8)};

  @media ${device.desktopM} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TemplatesWrap = styled(Flex)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.px(8)};

  @media ${device.desktopM} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;
