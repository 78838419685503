import styled from 'styled-components';

import { Box, Flex, Icon } from 'components';
import { ModalContent } from 'components/lib/modal/Modal';
import { device } from 'utils/mediaQueries';

export const DialogContent = styled(Box)`
  flex: 1;
  padding: 1px 40px 28px 40px;
  overflow-y: auto;
`;

export const DialogFooter = styled(Flex)`
  align-items: 'center';
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 24,
}))``;

export const CloseButton = styled(Box)`
  position: absolute;
  top: 36px;
  right: 40px;
  cursor: pointer;
`;

export const StyledModal = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  width: 60%;
  min-height: 60vh;

  @media ${device.tablet} {
    width: 70%;
  }

  @media ${device.mobile} {
    width: 80%;
  }
`;
