import { select, take } from 'redux-saga/effects';
import { getCustomer } from '../ducks/auth/selectors';
import { types as authTypes } from '../ducks/auth/actions';

export default function* selectAwaitCustomer() {
  let customer = yield select(getCustomer);
  if (!customer) {
    yield take([authTypes.CUSTOMER.SUCCESS, authTypes.CUSTOMER.FAILURE]);
    customer = yield select(getCustomer);
  }
  return customer;
}
