import { getAssetPath } from 'utils/files';

const getWifiIconFileNameStem = (strength: number): string => {
  if (strength >= 75) {
    return 'wi_fi1';
  } else if (strength >= 50) {
    return 'wi_fi2';
  } else if (strength >= 25) {
    return 'wi_fi3';
  }
  return 'wi_fi4';
};

export const getWifiIcon = (strength: number): string => {
  return getAssetPath(`${getWifiIconFileNameStem(strength)}.svg`);
};
