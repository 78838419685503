import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from 'components';
import { useFetchedData, useReduxSelector, useUrlParam } from 'hooks';
import {
  filteredTemplates,
  getAllUserTemplates,
} from 'ducks/templates/selectors';
import { getQuery } from 'ducks/router/selectors';
import { actions as templateActions } from 'ducks/templates/actions';

import { useSelector } from 'react-redux';
import { isAddingFile } from 'ducks/dataSources/selectors';
import NoLabels from './NoLabels';
import { getUnit } from 'ducks/preferences/selectors';
import {
  PAGE_NUMBER_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SORT_DEFAULT,
} from 'utils/defaults';
import { TemplateCardType } from '../../../components/app/template-card/TemplateCard';
import { TemplateType } from 'zsbpsdk/src/templates/index';
import { StyledTemplateCard, TemplatesWrap } from './Workspace.styles';
import { useMultiSelect } from 'providers/MultipleSelect';

const Workspace = () => {
  const [{ selected: selectedTemplates }, { select, unselect }] =
    useMultiSelect();
  const labels = useReduxSelector(getAllUserTemplates);
  const unit = useReduxSelector(getUnit);
  const {
    pageSize = PAGE_SIZE_DEFAULT,
    size = '-1',
    sort = SORT_DEFAULT,
    search = '',
  } = useReduxSelector(getQuery); //TODO size here
  const { loading: filteredTemplatesloading } =
    useReduxSelector(filteredTemplates);

  const [pageNumber, setPageNumber] = useUrlParam(
    'pageNumber',
    PAGE_NUMBER_DEFAULT,
  );

  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    setTimestamp(Date.now());
  }, []);

  const params = useMemo(
    () => ({
      pageNumber,
      pageSize,
      size,
      sort,
      search,
      timestamp,
    }),
    [pageNumber, pageSize, search, size, sort, timestamp],
  );

  const [templatePage, loadingUser] = useFetchedData(
    filteredTemplates,
    templateActions.FILTERED.request,
    params,
  );

  const filteredTemplatesFileDataList: TemplateType[] =
    templatePage?.fileDataList;

  const dataSourcesLoading = useSelector(isAddingFile);

  let loading =
    (loadingUser || dataSourcesLoading) &&
    !filteredTemplatesFileDataList?.length;

  useEffect(() => {
    if (
      loading === false &&
      filteredTemplatesFileDataList?.length === 0 &&
      pageNumber > 1
    ) {
      setPageNumber(pageNumber - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredTemplatesFileDataList]);

  return (
    <>
      {loading ? (
        <Loader visible={loading} />
      ) : filteredTemplatesFileDataList?.length ? (
        <TemplatesWrap
          flexWrap="wrap"
          className="workspace-content"
          loaded={loading}
          justifyContent="start"
        >
          {filteredTemplatesFileDataList.map((t: TemplateType) => (
            <StyledTemplateCard
              template={t}
              key={t.id + t.tenant}
              elevation={'one'}
              unit={unit}
              type={TemplateCardType.USER}
              onCheckboxClick={() =>
                selectedTemplates.includes(t) ? unselect(t) : select(t)
              }
              isBulkSelectActive={selectedTemplates.length > 0}
              isSelectable={true}
              isSelected={selectedTemplates.includes(t)}
              disabled={filteredTemplatesloading}
            />
          ))}
        </TemplatesWrap>
      ) : (
        <NoLabels labels={labels} />
      )}
    </>
  );
};

export default Workspace;
