import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Flex } from 'components';
import { useReduxAction, useUrlParam } from 'hooks';
import { actions as categoriesActions } from 'ducks/categories/actions';
import { actions as labelSizesActions } from 'ducks/labelSizes/actions';

import CategoriesLayout from './CategoriesLayout';
import TemplatesLayout from './TemplatesLayout';
import Page from '../Page';

const ContentWrap = styled(Flex).attrs({
  flexDirection: 'column',
})`
  flex: 1;
  padding-top: 10px;
  max-width: 100%;
`;

const CommonDesignsPage = () => {
  const [selectedCategory] = useUrlParam('category', '');

  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);
  const getAllCategories = useReduxAction(categoriesActions.FILTERED.request);

  //Initially fetch all label sizes and categories
  useEffect(() => {
    getAllLabelSizes();
    getAllCategories('');
  }, [getAllLabelSizes, getAllCategories]);

  return (
    <Page>
      <ContentWrap>
        {selectedCategory ? <TemplatesLayout /> : <CategoriesLayout />}
      </ContentWrap>
    </Page>
  );
};

export default CommonDesignsPage;
