import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import IcomoonReact from 'icomoon-react';
import iconSet from 'components/lib/icon/selection.json';
import IconType from './IconType';
import { SpaceProps } from 'styled-system';

type IconProps = {
  color?: string;
  size?: string | number;
  icon: IconType;
} & SpaceProps;

const StyledIcon = styled(IcomoonReact).attrs(
  ({ theme, color }: { theme: DefaultTheme; color: string } & SpaceProps) => ({
    color: color ?? theme.primary.base,
    iconSet: iconSet,
  }),
)`
  margin-right: ${({ mr }) => mr && mr};
`;

const Icon = ({ color, size = 20, icon, ...props }: IconProps) => (
  <StyledIcon {...props} color={color} size={size} icon={icon} />
);

export default Icon;
