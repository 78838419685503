import React, { useEffect, useContext } from 'react';
import { Text, Flex, Button, Icon } from 'components';
import styled, { ThemeContext } from 'styled-components/macro';
import { push } from 'connected-react-router';
import { useReduxAction, useReduxSelector } from 'hooks';
import { TimelineMax } from 'gsap';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { useTranslation } from 'react-i18next';

const tl = new TimelineMax();

const StyledHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.lightest};

  svg {
    margin-left: 5px;
  }
`;

const EditWorkspaceTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 300,
  letterSpacing: 0.4,
  color: theme.neutral.darkest,
}))`
  cursor: pointer;
`;

const Arrow = styled(Icon).attrs(({ theme }) => ({
  icon: 'chevron-right',
  size: 24,
  color: theme.neutral.base,
}))`
  padding-left: 15px;
  padding-right: 15px;
`;

const PageHeader = ({ selectedWorkspaceId, onClick }: any) => {
  const { t } = useTranslation(['common', 'workplaces']);

  const theme = useContext(ThemeContext);
  const pushAction = useReduxAction(push);
  const path = useReduxSelector(
    (state: any) => state.router.location?.pathname,
  );
  const workspaces = useReduxSelector(getAllWorkspaces);
  const selectedWorkspace = workspaces.find(
    (w) => w?.id?.toString() === selectedWorkspaceId,
  );

  useEffect(() => {
    if (selectedWorkspace) return;
    tl.fromTo(
      '.header',
      { opacity: 0 },
      { opacity: 1, duration: 0.4, delay: 0.8 },
    ).fromTo(
      '.header-item',
      { opacity: 0, y: -15 },
      { opacity: 1, y: 0, duration: 0.4 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <Flex width="100%">
      <StyledHeader
        className="header"
        justifyContent="space-between"
        alignItems="center"
        pt={33}
        pr={45}
        pb={20}
        pl={40}
        width="100%"
      >
        <Flex justifyContent={'row'} alignItems={'center'}>
          <EditWorkspaceTitle
            onClick={() => pushAction('/workspaces')}
            className="header-item"
          >
            {t('components:workspace', { count: 2 })}
          </EditWorkspaceTitle>
          {path?.includes('/edit') && selectedWorkspace?.name && (
            <>
              <Arrow />
              <EditWorkspaceTitle>{selectedWorkspace?.name}</EditWorkspaceTitle>
            </>
          )}
        </Flex>
        <Button
          icon={'right'}
          iconName="close"
          iconSize="24"
          iconColor={theme.neutral.base}
          variant={'text-primary'}
          size={'lg'}
          onClick={onClick}
          className="header-item"
        >
          {path.includes('/edit/details') ||
          path.includes('/edit/edit') ||
          path.includes('/edit/theme') ||
          path.includes('/edit/members')
            ? t('common:back')
            : t('common:close')}
        </Button>
      </StyledHeader>
    </Flex>
  );
};

export default PageHeader;
