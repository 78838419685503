import i18n from 'i18next';

import { format, parse } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt';
import slLocale from 'date-fns/locale/sl';
import bgLocale from 'date-fns/locale/bg';

export const localeMap: Map<string, Locale> = new Map([
  ['en', enLocale],
  ['pt', ptLocale],
  ['sl', slLocale],
  ['bg', bgLocale],
]);

export const getCurrentLanguage = (): string => i18n.language.split('-')[0];

export const dateFormatter = (v: string): string => {
  const today = new Date();
  const date = new Date(Number(v + '000')); // UNIX epoch to miliseconds
  return today.toDateString() === date.toDateString()
    ? i18n.t('common:today')
    : format(date, 'MMM d, yyyy', {
        locale: localeMap.get(getCurrentLanguage()) || localeMap.get('en'),
      });
};

export const unixToDateFormatter = (unixTime: number): string => {
  const today = new Date();
  const date = new Date(unixTime * 1000);
  return today.toDateString() === date.toDateString()
    ? i18n.t('common:today')
    : format(date, 'MMM d, yyyy', {
        locale: localeMap.get(getCurrentLanguage()) || localeMap.get('en'),
      });
};

export const getUnixTime = (date) => {
  return (date.getTime() / 1000) | 0;
};

export const formatDate = (dateFormat: string, date = new Date()): string => {
  switch (dateFormat.toLowerCase()) {
    case 'mmm dd, yyyy':
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    case 'mm/dd/yyyy':
      return date.toLocaleDateString('en-US');
    case 'dd/mm/yyyy':
      return date.toLocaleDateString('en-GB');
    case 'mm/dd/yy':
      return date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'numeric',
        year: '2-digit',
      });
    case 'dd/mm/yy':
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    case 'dd.mm.yyyy':
      return date.toLocaleDateString('en-GB').replace(/\//g, '.');
    case 'yyyy/mm/dd':
      return date.toLocaleDateString('en-CA').replace(/-/g, '/');
    case 'yyyy-mm-dd':
    default:
      return date.toLocaleDateString('en-CA');
  }
};

export const parseDate = (dateFormat: string, dateString: string): Date => {
  const referenceDate = new Date(2051, 0, 0, 0, 0, 0, 0); // Year 2051 so that two-digit years 00 and 99 are parsed as 2000 and 2099
  return parse(dateString, dateFormat, referenceDate);
};

export const formatDateToIsoDate = (
  dateFormat: string,
  dateString: string,
): string => {
  if (!dateString || dateString.length === 0) return '';
  return format(parseDate(dateFormat, dateString), 'yyyy-MM-dd');
};

export const formatCurrentTime = (format): string => {
  let date = new Date();
  let is24 = format.startsWith('HH:mm');
  let isShowPeriod = format.includes('tt');
  let rawHours = date.getHours();

  let period = rawHours < 12 ? 'AM' : 'PM';
  let hours = is24
    ? rawHours
    : period === 'AM'
    ? rawHours === 0
      ? 12
      : rawHours
    : rawHours % 12 === 0
    ? 12
    : rawHours % 12;
  let mins = ('0' + date.getMinutes()).slice(-2);

  return `${hours}:${mins}${isShowPeriod ? ` ${period}` : ''}`;
};
