import React from 'react';
import styled from 'styled-components';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, ListItemProps } from '../List';
import MenuContext, { useMenu } from './MenuContext';
import { Box } from 'components';

export const usePaperStyles = makeStyles({
  root: {
    overflow: 'initial',
  },
});

export type MenuProps = React.PropsWithChildren<{
  /*
   * If true, the menu is visible.
   */
  open: boolean;
  /*
   * A HTML element, or a function that returns it.
   * It's used to set the position of the menu.
   */
  anchorEl: PopoverProps['anchorEl'];
  /*
   * Expand the with of the Popper to match the on of the acnhorEl.
   */
  expand?: boolean;
  /*
   * Optional minWidht of the Menu popover container.
   */
  minWidth?: number;
  /*
   * Optional maxHeight of the MenuList for menus with lots of MenuItems.
   */
  maxHeight?: string;
  /*
   * Callback fired before the Menu exits.
   */
  onClose?: () => void;
  /*
   * If true, the modal will not automatically shift focus to itself when it opens, and replace it to the last focused
   * element when it closes. This also works correctly with any modal children that have the disableAutoFocus prop.
   */
  disableAutoFocus?: boolean;
  /*
   * If true, the modal will not prevent focus from leaving the modal while open.
   */
  disableEnforceFocus?: boolean;
}>;

export const MenuList = styled(List)<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '50vh')};
  overflow-y: auto;
`;
export const MenuItem = ({ onClick, active, ...props }: ListItemProps) => {
  const { onClose } = useMenu();
  const handleClick = (e: React.MouseEvent) => {
    onClick && onClick(e);
    onClose && onClose();
  };
  return (
    <ListItem
      role={'menuitem'}
      noBorder={true}
      active={active}
      {...props}
      onClick={handleClick}
    />
  );
};

const Menu = ({
  open,
  anchorEl,
  onClose,
  expand = false,
  minWidth,
  maxHeight,
  disableAutoFocus = false,
  disableEnforceFocus = false,
  children,
}: MenuProps) => {
  const paperStyles = usePaperStyles();
  const anchor = anchorEl as HTMLElement;
  const handleClose = (event: React.MouseEvent<Document>) => {
    onClose && onClose();
  };

  const paperWidth = expand ? anchor?.offsetWidth : null;

  return (
    <MenuContext.Provider value={{ onClose }}>
      <Popover
        aria-label={'menu'}
        open={open}
        anchorEl={anchorEl}
        disableAutoFocus={disableAutoFocus}
        disableEnforceFocus={disableEnforceFocus}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          classes: paperStyles,
        }}
        style={{
          zIndex: 1300,
        }}
        onClose={handleClose}
      >
        <Box width={minWidth ?? paperWidth}>
          <Box mt={4} mb={4}>
            <MenuList maxHeight={maxHeight ? maxHeight : 'initial'}>
              {children}
            </MenuList>
          </Box>
        </Box>
      </Popover>
    </MenuContext.Provider>
  );
};

export default Menu;
