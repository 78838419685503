import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components/macro';

const paginationBorderRadius = '8px';
const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    border-radius: ${paginationBorderRadius};
  }

  .pagination > li {
    display: inline;

    > a,
    > span {
      transition: box-shadow 0.3s;
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: ${({ theme }) => theme.primary.base};
      text-decoration: none;
      background-color: ${({ theme }) => theme.white};
      border: 1px solid ${({ theme }) => theme.neutral.lightest};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.primary.dark};
        cursor: pointer;
        background-color: ${({ theme }) => theme.primary.lightest};
      }

      &:hover {
        z-index: 2;
      }

      &:focus {
        z-index: 4;
        outline: none;
        border: 1px solid ${({ theme }) => theme.primary.base};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.primary.base};
      }
    }

    &:first-child {
      > a,
      > span {
        margin-left: 0;
        border-top-left-radius: ${paginationBorderRadius};
        border-bottom-left-radius: ${paginationBorderRadius};
      }
    }

    &:last-child {
      > a,
      > span {
        border-top-right-radius: ${paginationBorderRadius};
        border-bottom-right-radius: ${paginationBorderRadius};
      }
    }
  }
  .pagination > .active {
    > a,
    > a:hover,
    > a:focus,
    > span,
    > span:hover,
    > span:focus {
      z-index: 3;
      color: ${({ theme }) => theme.white};
      cursor: default;
      background-color: ${({ theme }) => theme.primary.dark};
      border-color: ${({ theme }) => theme.primary.dark};
    }
  }
  .pagination > .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span,
    > span:hover,
    > span:focus {
      color: ${({ theme }) => theme.textColors.low};
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

const Pagination = ({
  children,
  pageCount,
  onPageChange,
  forcePage,
  ...props
}: any) => {
  return (
    <PaginationWrapper data-testid="pagination-wrapper">
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        forcePage={forcePage}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
