import React, { Suspense, useCallback, useEffect } from 'react';
import { Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { useReduxSelector, useReduxAction } from 'hooks';
import { getAllPrinters } from 'ducks/printers/selectors';
import { Flex, Box, PrintersList2 } from 'components';
import PrivateRoute from 'components/app/PrivateRoute/PrivateRoute';
import { MenuCard, Text } from 'v2/components/common';
import { device } from 'utils/mediaQueries';
import {
  COMMON_DESIGNS_PATH,
  MY_DATA_SOURCES_PATH,
  MY_APPS_PATH,
  APPLICATION_VERSION,
  PRINT_PAGE_PATH,
  MY_DESIGNS_PATH,
  CREATE_NEW_DESIGN,
} from 'utils/config';
import { actions as printersActions } from 'ducks/printers/actions';

import PrintPage from '../PrintPage';
import Page from '../Page';
import CertificationModalsContainer from '../../../pages/warningModals/CertificationModalsContainer';
import {
  getPrinterWithAboutToExpireCertification,
  getPrinterWithExpiredCertification,
} from '../../../pages/warningModals/utils/getCertificateExpirations';
import CreateNewTemplate from '../PrintPage/CreateNewTemplate';
import { getAssetPath } from 'utils/files';

const MenuCardList = styled(Box)`
  margin-bottom: ${({ theme }) => theme.px(9)};
  display: grid;
  grid-template-columns: 50% 50%;
  gap: ${({ theme }) => theme.px(8)};

  @media ${device.mobile} {
    grid-template-columns: none;
  }
`;

const PrinterListWrap = styled(Box)`
  position: relative;
  z-index: 1;
  margin-top: -104px;
`;

export default function HomePage() {
  const { t } = useTranslation('menu');
  const history = useHistory();
  const printers = useReduxSelector(getAllPrinters) ?? [];
  const printersAboutToExpireCertificationOffline =
    getPrinterWithAboutToExpireCertification(printers);
  const printersExpiredCertificationOffline =
    getPrinterWithExpiredCertification(printers);
  const fetchPrinters = useReduxAction(printersActions.ALL.request);

  const navigateTo = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    fetchPrinters({});
  }, [fetchPrinters]);

  return (
    <Page>
      {(printersAboutToExpireCertificationOffline.length > 0 ||
        printersExpiredCertificationOffline.length > 0) && (
        <CertificationModalsContainer
          printersAboutToExpireCertificationOffline={
            printersAboutToExpireCertificationOffline
          }
          printersExpiredCertificationOffline={
            printersExpiredCertificationOffline
          }
        />
      )}

      <Box position={'relative'} zIndex={1} px={'40px'}>
        <PrinterListWrap>
          <PrintersList2 />
        </PrinterListWrap>

        <Box mt={'40px'}>
          <Text variant={'h2'}>{t('menu:what-do-you-want-to-do')}</Text>

          <MenuCardList mt={8}>
            <MenuCard
              title={t('menu:print-a-label')}
              description={t('menu:print-a-label-desc')}
              onClick={() => navigateTo(PRINT_PAGE_PATH)}
            />

            <MenuCard
              title={t('menu:design-a-label')}
              description={t('menu:design-a-label-desc')}
              onClick={() => navigateTo(MY_DESIGNS_PATH)}
            />

            <MenuCard
              title={t('menu:explore-templates')}
              description={t('menu:explore-templates-desc')}
              onClick={() => navigateTo(COMMON_DESIGNS_PATH)}
            />

            <MenuCard
              title={t('menu:manage-files')}
              description={t('menu:manage-files-desc')}
              onClick={() => navigateTo(MY_DATA_SOURCES_PATH)}
            />

            <MenuCard
              title={t('menu:install-drivers')}
              description={t('menu:install-drivers-desc')}
              onClick={() => navigateTo(MY_APPS_PATH)}
            />

            <MenuCard
              title={t('menu:buy-more-labels')}
              description={t('menu:buy-more-labels-desc')}
              icon={'new-window'}
              onClick={() =>
                openInNewTab('https://www.zebra.com/smb/us/en/labels.html')
              }
            />
          </MenuCardList>
        </Box>

        <Flex mt={'20px'} alignItems={'center'} justifyContent={'center'}>
          <img
            src={getAssetPath('/logo-zebra.svg')}
            alt={t('menu:zebra-logo')}
          />
          <Text variant={'body'} data-testid="application-version">
            {t('menu:version', { version: APPLICATION_VERSION })}
          </Text>
        </Flex>
      </Box>

      <Suspense fallback="">
        <Switch>
          <PrivateRoute path={PRINT_PAGE_PATH} component={PrintPage} />
          <PrivateRoute
            path={CREATE_NEW_DESIGN}
            component={CreateNewTemplate}
          />
        </Switch>
      </Suspense>
    </Page>
  );
}
