import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { Flex, Button, Box } from 'components';
import { device } from 'utils/mediaQueries';
import DeleteAccountModal from 'pages/overview/DeleteAccountModal';

const ButtonsContainer = styled(Flex)`
  position: fixed;
  bottom: 0;
  width: calc(100vw - 60px);
  z-index: 1;
  background-color: ${({ theme }) => theme.primary.background};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.primary.background};
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  padding-right: 100px;

  @media ${device.tablet} {
    padding-right: 60px;
  }

  @media ${device.mobile} {
    padding-right: 60px;
  }
`;

const BorderBox = styled(Box).attrs(() => ({
  py: 11,
}))`
  width: 100vw;
  border-top: 1px solid ${({ theme }) => theme.neutral.lighter};
`;

export const ActionButtonsFooter = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <ButtonsContainer>
      <BorderBox>
        {/* TODO this needs to be changed when account deletion is implemented */}
        <Button
          data-testid="delete-account"
          variant="text-destructive"
          onClick={handleOpen}
        >
          Delete Account
        </Button>
      </BorderBox>

      <DeleteAccountModal open={open} handleModalClose={handleClose} />
    </ButtonsContainer>
  );
};
