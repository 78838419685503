import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { useReduxAction, useReduxSelector } from 'hooks';
import { Flex } from 'components';
import { getAllPrinters } from 'ducks/printers/selectors';

import SettingsCard from './SettingsCard';
import { SectionTitle, Title } from './EditWorkspace.styles';
import { getAssetPath } from '../../../utils/files';

const Printers = () => {
  const { t } = useTranslation(['settings', 'overview']);
  const pushAction = useReduxAction(push);
  const printers = useReduxSelector(getAllPrinters);
  const { url } = useRouteMatch();

  return (
    <Flex
      width={738}
      flexDirection={'column'}
      justifyContent={'space-between'}
      data-testid={'printer-settings-list'}
    >
      <Title variant={'h2'} mb={8}>
        {t('overview:printer.settings')}
      </Title>
      <SectionTitle pb={12}>
        {t('overview:printer.printing-preferences-title')}
      </SectionTitle>
      <SettingsCard
        onClick={() => pushAction(`${url}/general`)}
        icon={getAssetPath('/ZSB-DP14-ZSB-DP12.png')}
        title={t('other:general')}
        description={t('overview:printer.general-settings-description')}
        data-testid="general-settings-description"
      />
      {printers?.map((printer, index: number) => {
        return (
          <SettingsCard
            key={printer.uniqueId}
            onClick={() => pushAction(`${url}/${printer.uniqueId}`)}
            icon={printer?.avatar}
            title={printer?.name}
            description={t('overview:printer.printer-settings-description')}
            data-testid={'printer-settings-description'}
          />
        );
      })}
    </Flex>
  );
};

export default Printers;
