import { FileDataParams } from './FileProvider';
import { CloudFileProvider } from './CloudFileProvider';
import i18n from 'i18next';
import { getOneDriveFile } from '../../services/office365';

export class MicrosoftOneDriveFileProvider extends CloudFileProvider {
  async getFileData(file, params: FileDataParams) {
    file = (await super.getFileData(file, params)) ?? file;

    const { msalContext, isOneDriveAuthenticated } = params;
    if (msalContext === undefined || isOneDriveAuthenticated === undefined) {
      throw new Error(i18n.t('common:errors:generic-error'));
    }

    return getOneDriveFile(msalContext, isOneDriveAuthenticated, file);
  }
}
