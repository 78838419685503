import { all, fork } from 'redux-saga/effects';
import printers from './printers';
import templates from './templates';
import categories from './categories';
import sniffer from './routeSniffer';
import auth from './auth';
import workspaces from './workspaces';
import search from './search';
import labelSizes from './labelSizes';
import dataSources from './dataSources';
import notifications from './notifications';
import info from './info';
import preferences from './preferences';
import pageVisibility from './pageVisibility';
import bannerMessages from './bannerMessages';
import modalDialogs from './modalDialogs';
import uploadAndPrintTemplate from './uploadAndPrintTemplate';

export function* rootSaga() {
  yield all([
    fork(auth),
    fork(workspaces),
    fork(printers),
    fork(sniffer),
    fork(templates),
    fork(categories),
    fork(labelSizes),
    fork(search),
    fork(dataSources),
    fork(notifications),
    fork(info),
    fork(preferences),
    fork(pageVisibility),
    fork(bannerMessages),
    fork(modalDialogs),
    fork(uploadAndPrintTemplate),
  ]);
}
