import { useState, useMemo, useCallback, SyntheticEvent } from 'react';

const useValidation = (initialFields: any, validators: any) => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState({});

  const hasErrors = useMemo(
    () => Object.values(errors).some((val) => val),
    [errors],
  );

  const onChange = useCallback(
    (event: SyntheticEvent, checked) => {
      const { name, value } = event.target as HTMLInputElement;
      const newValue = checked !== undefined ? checked : value;
      const newFields = {
        ...fields,
        [name]: newValue,
      };
      setFields(newFields);
      setErrors({ ...errors, [name]: validators.single(name, newValue) });
    },
    [errors, fields, validators],
  );

  return [fields, errors, hasErrors, onChange];
};

export default useValidation;
