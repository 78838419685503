import { useRef, useEffect, useState, MutableRefObject } from 'react';

const useDebounceCallback = (callback: any, delay: number) => {
  const latestCallback: MutableRefObject<any> = useRef();
  const [callCount, setCallCount] = useState(0);

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (callCount > 0) {
      const fire = () => {
        setCallCount(0);
        latestCallback.current();
      };

      const id: NodeJS.Timeout = setTimeout(fire, delay);
      return () => clearTimeout(id);
    }
  }, [callCount, delay]);

  return () => setCallCount((callCount) => callCount + 1);
};

export default useDebounceCallback;
