import {
  generateDataTypes,
  generateDataActions,
  generateSelectedDataActions,
  generateSelectedDataTypes,
  generateDataActionsPrint,
  generateDataTypesPrint,
} from 'ducks/utils';
import { TemplatePage } from 'zsbpsdk/src/templates/index';

const BASE_DUCK_PREFIX = 'SMB_TEMPLATES_';

export type AllTemplatesRequest = {};

export type RecentTemplatesRequest = {
  pageNumber?: string;
  pageSize?: string;
  search?: string;
};

export type FilteredTemplatesRequest = {
  pageNumber: string;
  pageSize: string;
  size: string;
  sort: string;
  search: string;
  timestamp?: Date;
  searchLibrary?: string;
  selectedCategory?: string;
};

export type ZebraAllTemplatesRequest = {};

export type ZebraFilteredTemplatesRequest = {
  selectedCategory: string;
  search: string;
};

export type PrintPayload = {
  template;
  printer;
  copies: number;
  fields;
  selectedLabels: number[];
  labelRange;
  labelRangeMapping;
};

// Action creators and Actions;

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  CURRENT: generateDataTypes(BASE_DUCK_PREFIX + 'CURRENT'),
  RECENT: generateDataTypes(BASE_DUCK_PREFIX + 'RECENT'),
  FILTERED: generateDataTypes(BASE_DUCK_PREFIX + 'FILTERED'),
  FILTERED_HASH: generateDataTypes(BASE_DUCK_PREFIX + 'FILTERED_HASH'),
  SELECTED: generateSelectedDataTypes(BASE_DUCK_PREFIX + 'SELECTED'),
  SELECTED_DATA: generateDataTypes(BASE_DUCK_PREFIX + 'SELECTED_DATA'),
  ENTERED_DATA: generateSelectedDataTypes(BASE_DUCK_PREFIX + 'ENTERED_DATA'),
  UPDATE: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE'),
  REMOVE: generateDataTypes(BASE_DUCK_PREFIX + 'REMOVE'),
  REMOVE_MULTIPLE: generateDataTypes(BASE_DUCK_PREFIX + 'REMOVE_MULTIPLE'),
  PRINT: generateDataTypesPrint(BASE_DUCK_PREFIX + 'PRINT'),
  TEST_PRINT: generateDataTypes(BASE_DUCK_PREFIX + 'TEST_PRINT'),
  TEMPLATE_DIALOG: generateDataTypes(BASE_DUCK_PREFIX + 'TEMPLATE_DIALOG'),
  LABEL_PREVIEW: generateDataTypes(BASE_DUCK_PREFIX + 'LABEL_PREVIEW'),
  DUPLICATE: generateDataTypes(BASE_DUCK_PREFIX + 'DUPLICATE'),
  ZEBRA_ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ZEBRA_ALL'),
  ZEBRA_FILTERED: generateDataTypes(BASE_DUCK_PREFIX + 'ZEBRA_FILTERED'),
  ZEBRA_FILTERED_ALL_SIZES: generateDataTypes(
    BASE_DUCK_PREFIX + 'ZEBRA_FILTERED_ALL_SIZES',
  ),
  MY_DESIGN_ALL_SIZES: generateDataTypes(
    BASE_DUCK_PREFIX + 'MY_DESIGN_ALL_SIZES',
  ),
  THUMBNAIL: generateDataTypes(BASE_DUCK_PREFIX + 'THUMBNAIL'),
};

export const actions = {
  ALL: generateDataActions<AllTemplatesRequest, TemplatePage>(types.ALL),
  CURRENT: generateDataActions(types.CURRENT),
  RECENT: generateDataActions<RecentTemplatesRequest, TemplatePage>(
    types.RECENT,
  ),
  FILTERED: generateDataActions<FilteredTemplatesRequest, TemplatePage>(
    types.FILTERED,
  ),
  FILTERED_HASH: generateDataActions<FilteredTemplatesRequest, TemplatePage>(
    types.FILTERED_HASH,
  ),
  SELECTED: generateSelectedDataActions(types.SELECTED),
  SELECTED_DATA: generateDataActions(types.SELECTED_DATA),
  ENTERED_DATA: generateSelectedDataActions(types.ENTERED_DATA),
  UPDATE: generateDataActions(types.UPDATE),
  REMOVE: generateDataActions(types.REMOVE),
  REMOVE_MULTIPLE: generateDataActions(types.REMOVE_MULTIPLE),
  PRINT: generateDataActionsPrint<PrintPayload>(types.PRINT),
  TEST_PRINT: generateDataActions(types.TEST_PRINT),
  TEMPLATE_DIALOG: generateDataActions(types.TEMPLATE_DIALOG),
  LABEL_PREVIEW: generateDataActions(types.LABEL_PREVIEW),
  DUPLICATE: generateDataActions(types.DUPLICATE),
  ZEBRA_ALL: generateDataActions<ZebraAllTemplatesRequest, TemplatePage>(
    types.ZEBRA_ALL,
  ),
  ZEBRA_FILTERED: generateDataActions<
    ZebraFilteredTemplatesRequest,
    TemplatePage
  >(types.ZEBRA_FILTERED),
  ZEBRA_FILTERED_ALL_SIZES: generateDataActions(types.ZEBRA_FILTERED_ALL_SIZES),
  MY_DESIGN_ALL_SIZES: generateDataActions(types.MY_DESIGN_ALL_SIZES),
  THUMBNAIL: generateDataActions(types.THUMBNAIL),
};
