import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import Text from 'components/common/typography/Typography';

const Label = styled(Text).attrs(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: theme.textColors.med,
}))``;

export const StartLabel = styled(Label)`
  margin-right: auto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.4;
  line-height: 21px;
  color: ${({ theme }) => theme.textColors.med};
  padding-top: 6px;
`;

export const EndLabel = styled(Label)`
  margin-left: auto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.4;
  line-height: 21px;
  color: ${({ theme }) => theme.textColors.med};
  padding-top: 6px;
`;

export const StartLabelTop = styled(Label)`
  font-size: 14px;
  margin-right: auto;
`;

export const EndLabelTop = styled(Label)`
  font-size: 14px;
  margin-left: auto;
`;

const getGradient = (theme: DefaultTheme, value: number, max: number) => {
  let percentage = (value / max) * 100;
  return `linear-gradient(
    to right,
    ${theme.primary.base} 0%,
    ${theme.primary.base} ${percentage}%,
    ${theme.neutral.lighter} ${percentage}%,
    ${theme.neutral.lighter} 100%
  );`;
};

const getDisabledGradient = (theme: DefaultTheme, value: number, max: number) => {
  const percentage = (value / max) * 100;
  return `linear-gradient(
    to right,
    ${theme.primary.light} 0%,
    ${theme.primary.light} ${percentage}%,
    ${theme.neutral.lighter} ${percentage}%,
    ${theme.neutral.lighter} 100%
  );`;
};

const CommonStyle = (theme: DefaultTheme) => `
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: poiner;
    background: ${theme.primary.base};
`;

const DisabledStyle = (theme: DefaultTheme) => `
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    background: ${theme.primary.light};
`;

export const StyledInput = styled.input<{
  value: number | string;
  max: number;
  disabled?: boolean;
}>`
  -webkit-appearance: none;
  outline: none;
  height: 3px;
  width: 100%;
  background: ${({ theme, value, max }) =>
    getGradient(theme, Number(value), max)};
  border-radius: 4px;
  margin: unset;
  &::-webkit-slider-thumb {
    -webkite-appearance: none;
    appearance: none;
    ${({ theme, disabled }) => disabled ? DisabledStyle(theme) : CommonStyle(theme)};
  }

  :disabled {
    background: ${({ theme, value, max }) =>
      getDisabledGradient(theme, Number(value), max)};
  }

  &::-moz-range-thumb {
    appearance: none;
    border: none;
    ${({ theme }) => CommonStyle(theme)};
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.primary.lighter};
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const Underlay = styled.div`
  position: absolute;
  height: 10px;
  width: 100%;
  top: -3px;
  left: 0;
  z-index: -1;
  display: flex;
`;
export const UnderlayTop = styled(Underlay)`
  top: 22px;
`;

export const Breakpoint = styled.div<{ stepsNo: number }>`
  width: 1px;
  height: 10px;
  background-color: ${({ theme }) => theme.neutral.base};
  min-width: 1px;
`;

export const NoLine = styled.div<{ stepsNo: number }>`
  width: 6px;
  height: 10px;
  background-color: ${({ theme }) => theme.primary.background};
`;

export const Divider = styled.div<{ size: number }>`
  width: calc(
    (100% - ${(props) => props.size}px) / (${(props) => props.size} - 1)
  );

  height: 10px;
  background-color: ${({ theme }) => theme.primary.background};
`;

export const Group1 = ({ noOfBreakpoints }) => (
  <>
    <Divider size={noOfBreakpoints} />
    <NoLine stepsNo={noOfBreakpoints} />
  </>
);

export const Group2 = ({ noOfBreakpoints }) => (
  <>
    <NoLine stepsNo={noOfBreakpoints} />
    <Divider size={noOfBreakpoints} />
    <Breakpoint stepsNo={noOfBreakpoints} />
  </>
);

export const Group3 = ({ noOfBreakpoints }) => (
  <>
    <Divider size={noOfBreakpoints} />
    <Breakpoint stepsNo={noOfBreakpoints} />
  </>
);

export const Group1WithBorder = ({ noOfBreakpoints }) => (
  <>
    <Divider size={noOfBreakpoints} />
    <NoLine stepsNo={noOfBreakpoints} />
    <Breakpoint stepsNo={noOfBreakpoints} />
  </>
);

export const Group2WithBorder = ({ noOfBreakpoints }) => (
  <>
    <Breakpoint stepsNo={noOfBreakpoints} />
    <NoLine stepsNo={noOfBreakpoints} />
    <Divider size={noOfBreakpoints} />
    <Breakpoint stepsNo={noOfBreakpoints} />
  </>
);
