import React from 'react';

import NotificationList from './NotificationList';
import { NotificationWrap } from './NotificationWidget.styles';

const NotificationWidget = () => {
  return (
    <NotificationWrap data-testid={'toast-notifications'}>
      <NotificationList />
    </NotificationWrap>
  );
};

export default NotificationWidget;
