import { useReduxSelector } from 'hooks';
import { getAllFeatures } from 'ducks/auth/selectors';
import { hasFeature, Features } from 'utils/features';
export { Features } from 'utils/features';

export default function useFeature(feature: Features) {
  const features: Features[] = useReduxSelector(getAllFeatures);

  return hasFeature(features, feature);
}
