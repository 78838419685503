import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Box } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as templateActions } from 'ducks/templates/actions';
import { actions as labelSizesActions } from 'ducks/labelSizes/actions';
import { getQuery as selectQuery } from 'ducks/router/selectors';
import { filteredTemplates as selectFilteredTemplates } from 'ducks/templates/selectors';
import { PRINT_PAGE_PATH } from 'utils/config';
import { PAGE_SIZE_DEFAULT } from 'utils/defaults';
import { DialogHeader, Pagination } from 'v2/components/app';
import TemplatesLayoutSearch from 'v2/pages/MyDesignsPage/TemplatesLayoutSearch';
import TemplatesLayoutFilter from 'v2/pages/MyDesignsPage/TemplatesLayoutFilter';
import { MultiSelectProvider } from 'providers/MultipleSelect';

import { DialogFooter, DialogContent } from '../Dialog.styles';
import TemplatesList from './TemplatesList';

const StyledBox = styled(Box)`
  div {
    span {
      font-size: 20px;
      line-height: 28px;
    }

    &:first-child {
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

const MyLabels = () => {
  const { t } = useTranslation();

  const pushAction = useReduxAction(push);
  const getMyDesignSizesAction = useReduxAction(
    templateActions.MY_DESIGN_ALL_SIZES.request,
  );
  const getFilteredTemplatesHash = useReduxAction(
    templateActions.FILTERED_HASH.request,
  );

  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);

  const { pageSize = PAGE_SIZE_DEFAULT } = useReduxSelector(selectQuery);
  const { data: filteredTemplates } = useReduxSelector(selectFilteredTemplates);

  const totalPagesCount = filteredTemplates
    ? Math.ceil(filteredTemplates.totalCount / parseInt(pageSize))
    : 0;

  useEffect(() => {
    getMyDesignSizesAction();
    getAllLabelSizes();
    getFilteredTemplatesHash();
  }, [getMyDesignSizesAction, getAllLabelSizes, getFilteredTemplatesHash]);

  return (
    <MultiSelectProvider>
      <DialogHeader
        title={t('dialogs:print-label.my-label-designs')}
        onBackClick={() => pushAction(PRINT_PAGE_PATH)}
      />

      <StyledBox px={40} pb={2}>
        <TemplatesLayoutSearch />

        <TemplatesLayoutFilter />
      </StyledBox>

      <DialogContent>
        <TemplatesList />
      </DialogContent>

      <DialogFooter>
        {totalPagesCount > 1 && <Pagination pageCount={totalPagesCount} />}
      </DialogFooter>
    </MultiSelectProvider>
  );
};

export default MyLabels;
