import React from 'react';
import { Card, Flex, Box, Tooltip } from 'components';
import styled from 'styled-components/macro';

const Container = styled.div`
  cursor: pointer;
`;

const TooltipsView = (): any => {
  return (
    <Card p={10} m={10} pb={50} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Tooltips</h2>
        <Flex mt={50} mb={50} flexDirection="row">
          <Box mr={40}></Box>
          <Box mr={100}>
            <Tooltip enabled={true} content={'Top'} direction={'top'}>
              <Container>Top</Container>
            </Tooltip>
          </Box>
          <Box mr={100}>
            <Tooltip enabled={true} content={'Bottom'} direction={'bottom'}>
              <Container>Bottom</Container>
            </Tooltip>
          </Box>
          <Box mr={100}>
            <Tooltip enabled={true} content={'Left'} direction={'left'}>
              <Container>Left</Container>
            </Tooltip>
          </Box>
          <Box mr={100}>
            <Tooltip enabled={true} content={'Right'} direction={'right'}>
              <Container>Right</Container>
            </Tooltip>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default TooltipsView;
