import styled from 'styled-components';
import { Box, Flex, Text } from 'components';
import { device } from 'utils/mediaQueries';

export const HeaderWrap: any = styled(Flex).attrs({
  flexDirection: 'column',
})`
  top: -1px;
  z-index: 9; // 1 beneath SideNav too show shadow
  transition: box-shadow 0.3s;
  width: 100%;
  max-width: 100%;
  top: -300px;
  background-color: ${({ theme }) => theme.primary.background};

  @media (min-height: 750px) {
    position: sticky;

    ${({ showHeaderShadow }: any) =>
      showHeaderShadow &&
      `
        box-shadow: 0px 8px 16px rgba(73, 80, 94, 0.16);
    
        &::before,
        &::after {
          box-shadow: 0px 20px 16px -16px rgba(73, 80, 94, 0.16);
        }
      `}
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    height: 100%;
    background-color: ${({ theme }) => theme.primary.background};
    transition: box-shadow 0.3s;
  }

  ${({ screenWidth }: any) =>
    screenWidth > 1328 &&
    `
    &::before {
      left: calc((${screenWidth}px - 1340px)/-2);
      width: calc((${screenWidth}px - 1340px)/2);
    }
  
    &::after {
      right: calc((${screenWidth}px - 1340px)/-2);
      width: calc((${screenWidth}px - 1340px)/2);
    }
  `};
`;

export const FilterResulText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
  fontSize: theme.fontSizes.h2,
  lineHeight: '32px',
  letterSpacing: 0.4,
  fontWeight: 300,
}))``;

export const TemplateActionsWrap = styled(Flex)`
  > button {
    margin-left: 14px;
    &:first-of-type {
      margin-left: 0;
    }
  }

  @media ${device.mobile} {
    margin-left: 0;
    margin-top: 20px;
    justify-content: space-between;
    flex-grow: 1;
    > button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const InnerWrap: any = styled(Flex).attrs({ flexDirection: 'column' })`
  position: relative;
  padding: 30px;
  z-index: 10; // Dropdowns must overlap pagination
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
`;

export const BackToLibraryWrap = styled(Flex).attrs({
  flexDirection: 'row',
  alignItems: 'center',
})`
  cursor: pointer;
`;

export const SearchBox = styled(Box)`
  max-width: 512px;

  flex-grow: 1;
  margin-right: 16px;

  @media ${device.mobile} {
    width: 100%;
    max-width: unset;
    margin-right: 0;
  }

  @media ${device.desktopMiddle} {
    width: 400px;
  }
`;

export const DropdownContainer = styled(Flex)`
  @media ${device.mobile} {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }
`;

export const DropdownWrapper = styled(Box)`
  :not(:first-child) {
    margin-left: 8px;
    @media ${device.mobile} {
      margin-left: 0;
    }
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const StyledFlex = styled(Flex)`
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const FixingDiv = styled.div`
  display: none;
`;
