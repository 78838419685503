// TO-DO Collect types and interfaces in definitions file

export type BaseAction = {
  type: string;
  payload?: any;
};

export type PayloadAction<Payload> = {
  type: string;
  payload: Payload;
};

export type ToastInitialState = {
  open: boolean;
  message: string;
  variant: 'basic' | 'error' | 'success' | 'warning' | 'info' | any;
};

export type FileDialogInitialState = {
  open: boolean;
};

export type SidebarInitialState = {
  isCollapsed: boolean;
};

export type PageVisibilityInitialState = {
  isVisible: boolean;
};

export type SelectedReducerInitialState = {
  data: undefined | any;
  loading: boolean;
};

export interface BaseInitialState<Data> {
  loading: boolean;
  data: undefined | Data;
  error: undefined | any;
}

export interface FetchInitialState extends BaseInitialState<any> {
  params: undefined | any;
}

export interface PostInitialState extends BaseInitialState<any> {
  success: boolean;
}

export const SELECTED_DATA_INITIAL_STATE: SelectedReducerInitialState = {
  data: undefined,
  loading: false,
};

export const FETCH_DATA_INITIAL_STATE: FetchInitialState = {
  loading: false,
  params: undefined,
  data: undefined,
  error: undefined,
};

export const POST_DATA_INITIAL_STATE: PostInitialState = {
  success: false,
  loading: false,
  data: undefined,
  error: undefined,
};

export const generateDataTypes = (prefix: string) => ({
  REQUEST: `${prefix}_REQUEST`,
  SUCCESS: `${prefix}_SUCCESS`,
  FAILURE: `${prefix}_FAILURE`,
  CLEAR: `${prefix}_CLEAR`,
});

export const generateDataTypesPrint = (prefix: string) => ({
  REQUEST: `${prefix}_REQUEST`,
  SUCCESS: `${prefix}_SUCCESS`,
  FAILURE: `${prefix}_FAILURE`,
  CLEAR_ID: `${prefix}_CLEAR_ID`,
  CLEAR_TEMPLATE: `${prefix}_CLEAR_TEMPLATE`,
});
export const generateSelectedDataTypes = (prefix: string) => ({
  SET: `${prefix}_SET`,
  CLEAR: `${prefix}_CLEAR`,
});

export const generateSelectedDataActions = (types: any) => ({
  set: (payload: any) => ({
    type: types.SET,
    payload: payload,
  }),
  clear: () => ({
    type: types.CLEAR,
  }),
});

export const generateDataActions = <RequestPayload, SuccessPayload>(
  types: any,
) => ({
  request: (payload: RequestPayload) => ({
    type: types.REQUEST,
    payload: payload,
  }),
  success: (payload: SuccessPayload) => ({
    type: types.SUCCESS,
    payload: payload,
  }),
  failure: (payload: any) => ({
    type: types.FAILURE,
    payload: payload,
  }),
  clear: () => ({
    type: types.CLEAR,
  }),
});

export const generateDataActionsPrint = <RequestPayload>(types: any) => ({
  request: (payload: RequestPayload): PayloadAction<RequestPayload> => ({
    type: types.REQUEST,
    payload: payload,
  }),
  success: (payload: any) => ({
    type: types.SUCCESS,
    payload: payload,
  }),
  failure: (payload: any) => ({
    type: types.FAILURE,
    payload: payload,
  }),
  clear_id: () => ({
    type: types.CLEAR_ID,
  }),
  clear_template: () => ({
    type: types.CLEAR_TEMPLATE,
  }),
});

export const generateFetchDataReducer = (types: any) => ({
  [types.REQUEST]: (state: any, action: BaseAction) => ({
    ...state,
    loading: true,
    error: undefined,
    params: action.payload,
  }),
  [types.SUCCESS]: (state: any, action: BaseAction) => ({
    ...state,
    loading: false,
    error: undefined,
    data: action.payload,
  }),
  [types.FAILURE]: (state: any, action: BaseAction) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),
  [types.CLEAR]: (state: any) => ({
    ...state,
    error: undefined,
    data: undefined,
    loading: false,
  }),
  //[authTypes.LOGOUT]: () => FETCH_DATA_INITIAL_STATE // this is good
});

export const generatePostDataReducer = (types: any, ignoreData = false) => ({
  [types.REQUEST]: (state: any) => ({
    ...state,
    loading: true,
    success: false,
    error: undefined,
  }),
  [types.SUCCESS]: (state: any, action: BaseAction) => ({
    loading: false,
    success: true,
    error: undefined,
    data: ignoreData ? undefined : action.payload,
  }),
  [types.FAILURE]: (state: any, action: BaseAction) => ({
    loading: false,
    success: false,
    error: action.payload,
    data: undefined,
  }),
  [types.CLEAR]: () => POST_DATA_INITIAL_STATE,
  //[authTypes.LOGOUT]: () => POST_DATA_INITIAL_STATE
});

export const generatePostDataReducerPrint = (
  types: any,
  ignoreData = false,
) => ({
  [types.REQUEST]: (state: any) => ({
    ...state,
    loading: true,
    success: false,
    error: undefined,
  }),
  [types.SUCCESS]: (state: any, action: BaseAction) => ({
    loading: false,
    success: true,
    error: undefined,
    data: ignoreData ? undefined : action.payload,
  }),
  [types.FAILURE]: (state: any, action: BaseAction) => ({
    loading: false,
    success: false,
    error: action.payload,
    data: undefined,
  }),
  [types.CLEAR_ID]: (state: any) => ({
    loading: false,
    success: false,
    error: false,
    data: { ...state.data, jobId: null },
  }),
  [types.CLEAR_TEMPLATE]: (state: any) => ({
    loading: false,
    success: false,
    error: false,
    data: { ...state.data, templateName: null },
    //[authTypes.LOGOUT]: () => POST_DATA_INITIAL_STATE
  }),
});

export const generateSelectDataReducer = (types: any) => ({
  [types.SET]: (state: any, action: BaseAction) => ({
    ...state,
    data: action.payload,
  }),
  [types.CLEAR]: (state: any) => ({
    ...state,
    data: undefined,
  }),
});

export const createReducer =
  (
    initialState:
      | FetchInitialState
      | PostInitialState
      | SelectedReducerInitialState
      | ToastInitialState
      | FileDialogInitialState
      | SidebarInitialState
      | PageVisibilityInitialState,
    reducers: any,
  ) =>
  (state = initialState, action: BaseAction) =>
    reducers[action.type]?.(state, action) || state;
