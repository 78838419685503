const BASE_DUCK_PREFIX = 'SMB_SIDEBAR_';

export const types = {
  COLLAPSED: BASE_DUCK_PREFIX + 'COLLAPSED',
};

export const actions = {
  COLLAPSED: (isCollapsed: boolean) => ({
    type: types.COLLAPSED,
    payload: isCollapsed,
  }),
};
