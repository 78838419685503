import styled from 'styled-components/macro';
import { Box } from 'components';
import { hexToRgba } from 'utils/themes';

export const StyledInfoIcon = styled(Box)`
  cursor: pointer;
  &:before {
    content: url('data:image/svg+xml;utf8,<svg height="20px" width="20px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" viewBox="0 0 20 20"><path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8H9V5h2v2z" fill="${({
      theme,
    }) => hexToRgba(theme.primary.base, 1)}"/></svg>');
    display: inline-block;
  }
`;
