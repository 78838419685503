import styled from 'styled-components';

import { Box } from 'components';
import { elevations } from 'utils/themes/common';

export type PaperProps = {
  elevation?: keyof typeof elevations;
  borderRadius?: number;
};

/**
 * Primary UI component for wrapping elements
 */
const Paper = styled(Box)<PaperProps>`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme, elevation = 'one' }) => theme.elevations[elevation]};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
`;

export default Paper;
