import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { useReduxAction } from 'hooks';
import { cancelClicked, confirmClicked } from 'state/Dialog';
import { Flex, Button } from 'components';
import { Text } from 'v2/components/common';
import { Modal } from 'v2/components/lib';

export default function ConfirmationDialog({
  title,
  content,
  confirmText,
  cancelText,
  open,
  ...props
}) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation('common');
  const cancel = useReduxAction(cancelClicked);
  const confirm = useReduxAction(confirmClicked);

  return (
    <Modal
      data-testid="confirmation-dialog"
      aria-label={title}
      onClose={() => cancel()}
      open={open}
      header={
        <Text variant={'h2'} color={theme.error.base}>
          {title}
        </Text>
      }
      {...props}
    >
      <Text variant={'body'}>{content}</Text>
      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="confirmation-cancel-button"
          onClick={() => cancel()}
          variant={'basic'}
        >
          {cancelText ? cancelText : t('common:cancel')}
        </Button>
        <Button
          data-testid="confirmation-confirm-button"
          variant={'destructive'}
          onClick={() => confirm()}
        >
          {confirmText ? confirmText : t('common:ok')}
        </Button>
      </Flex>
    </Modal>
  );
}
