import { generateDataTypes, generateDataActions } from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_PREFERENCES_';

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'), //GET
  UPDATE: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE'), //SET
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  UPDATE: generateDataActions(types.UPDATE),
};
