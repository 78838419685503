import { PrinterType, PrinterStatusType } from 'zsbpsdk/src/printer';
import { CARTRIDGE_MINIMUM_LABELS } from 'utils/config';
import Unit, { convertDotUnit } from 'utils/unitFormatter';

export enum PrinterStatus {
  Offline = 'offline',
  CoverOpen = 'cover-open',
  PaperOut = 'paper-out',
  BufferFull = 'buffer-full',
  UnableToPrint = 'unable-to-print',
  MediaOut = 'media-out',
  MediaLow = 'media-low',
  Online = 'online',
}

export function convertPrinterStatus(
  status?: PrinterStatusType,
  labelsRemaining: number = 0,
): PrinterStatus {
  if (status) {
    if (!status.isOnline) return PrinterStatus.Offline;
    if (status.isHeadOpen) return PrinterStatus.CoverOpen;
    if (status.isPaperOut) return PrinterStatus.PaperOut;
    if (status.isBufferFull) return PrinterStatus.BufferFull;
    if (!status.isReadyToPrint) return PrinterStatus.UnableToPrint;
    if (status.isOnline && labelsRemaining <= CARTRIDGE_MINIMUM_LABELS)
      return PrinterStatus.MediaLow;

    if (
      !status.isBufferFull &&
      !status.isHeadOpen &&
      !status.isPaperOut &&
      status.isReadyToPrint
    )
      return PrinterStatus.Online;
  }

  return PrinterStatus.Offline;
}

export function isPrinterOperational(status: PrinterStatus) {
  return (
    status !== PrinterStatus.Offline &&
    ![PrinterStatus.CoverOpen, PrinterStatus.PaperOut].includes(status)
  );
}

export function getLabelsWidth(etch: any, status: PrinterStatus): string {
  if (isPrinterOperational(status)) {
    const abbr = etch.unit.abbreviation;
    const abbrComponent = abbr.length === 1 ? abbr : abbr;

    return `${etch.width}${abbrComponent} x ${etch.height}${abbrComponent}`;
  }

  return 'N/A';
}

export function getEtch(printer: PrinterType, unit: Unit) {
  if (!printer.cartridgeInfo) {
    return { width: '?', height: '?', length: '2', unit };
  }

  let localisedWidth = convertDotUnit(printer.cartridgeInfo?.width, unit); //TODO change to user preferences
  let localisedHeight = convertDotUnit(printer.cartridgeInfo?.length, unit); //TODO change to user preferences
  return {
    width: localisedWidth,
    height: localisedHeight,
    length:
      localisedWidth.length +
      localisedHeight.length +
      unit.abbreviation.length * 2,
    unit,
  };
}
