import { generateDataTypes, generateDataActions } from 'ducks/utils';
import { TemplatePage } from 'zsbpsdk/src/templates/index';

const BASE_DUCK_PREFIX = 'SMB_SEARCH_';

export type SearchZebraTemplatesRequest = {
  value: string;
  category: string;
}

export const types = {
  TEMPLATES: generateDataTypes(BASE_DUCK_PREFIX + 'TEMPLATES'),
  ZEBRA_TEMPLATES: generateDataTypes(BASE_DUCK_PREFIX + 'ZEBRA_TEMPLATES'),
  CATEGORIES: generateDataTypes(BASE_DUCK_PREFIX + 'CATEGORIES'),
  DATA_SOURCES: generateDataTypes(BASE_DUCK_PREFIX + 'DATA_SOURCES'),
};

export const actions = {
  TEMPLATES: generateDataActions<string, TemplatePage>(types.TEMPLATES),
  ZEBRA_TEMPLATES: generateDataActions<SearchZebraTemplatesRequest, TemplatePage>(types.ZEBRA_TEMPLATES),
  CATEGORIES: generateDataActions(types.CATEGORIES),
  DATA_SOURCES: generateDataActions(types.DATA_SOURCES),
};
