import Text from 'components/common/typography/Typography';
import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import Icon from '../icon/Icon';
import IconType from '../icon/IconType';

export interface MenuItemProps {
  href?: string;
  iconName?: IconType;
  onClick?: React.MouseEventHandler;
  selected?: boolean;
  badge?: number;
  collapsed?: boolean;
  tooltipText?: string;
}

export const StyledItem = styled.a<MenuItemProps>`
  display: flex;
  align-items: center;

  margin: ${({ collapsed, theme }) =>
    collapsed ? theme.px(4, -4) : theme.px(0, 0, 8)};
  padding: ${({ collapsed, theme }) =>
    collapsed ? theme.px(4, 4) : theme.px(6, 9)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary.lightest : theme.white};
  border-radius: ${({ theme }) => theme.px(4)};
  transition: background-color 0.3s;
  cursor: pointer;
  text-decoration: none;

  &&:last-child {
    margin-bottom: 0;
  }

  div {
    transition: color 0.3s;
  }

  && {
    svg {
      margin-right: ${({ theme }) => theme.px(5)};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary.lightest};

    div {
      color: ${({ theme }) => theme.primary.base};
    }

    &&& svg path {
      fill: ${({ theme }) => theme.primary.base};
    }
  }
  &&& {
    transition: padding 0.6s ease-out, margin 0.6s ease-out;
  }
`;

export const BadgeWrapper = styled.span`
  position: relative;
  display: inline-block;
  border-radius: ${({ theme }) => theme.px(1)};
`;

export const Badge = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: ${({ theme }) => theme.px(9)};
  height: ${({ theme }) => theme.px(9)};
  top: ${({ theme }) => theme.px(-5)};
  left: -7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.error.base};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.px(5)};
`;

export const StyledIcon = styled(Icon).attrs(
  ({ theme, selected }: { theme: DefaultTheme; selected?: boolean }) => ({
    color: selected ? theme.primary.base : theme.textColors.med,
    size: 24,
  }),
)``;

export const StyledText = styled(Text)<{ selected?: boolean }>`
  display: inline;
  font-size: ${({ theme }) => theme.px(8)};
  line-height: ${({ theme }) => theme.px(12)};
  font-weight: normal;
  letter-spacing: 0.4px;
  color: ${({ theme, selected }) =>
    selected ? theme.primary.base : theme.textColors.med};
`;
