import * as React from 'react';
import { Flex, Box, Card, Text } from 'components';
import { useTranslation } from 'react-i18next';

export const Tutorials = (): any => {
  const { t } = useTranslation('other');

  return (
    <Flex alignItems="center" justifyContent="center">
      <Box mt={200}>
        <Card elevation="three">
          <Text>{t('other:is-not-implemented', { what: 'Tutorials' })}</Text>
        </Card>
      </Box>
    </Flex>
  );
};
