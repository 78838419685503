import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Box, Button, Flex } from 'components';
import { HintText, NoContentImage } from 'v2/components/common';
import { useReduxAction } from 'hooks';
import { CHOOSE_A_TEMPLATE, CREATE_NEW_DESIGN } from 'utils/config';

const StyledBrowseDesignsButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))`
  margin-right: ${({ theme }) => theme.px(12)};
`;

const StyledCreateDesignButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))``;

const StyledHintText = styled(HintText)`
  display: block;
  max-width: 512px;
  text-align: center;
  margin: ${({ theme }) => theme.px(12, 0, 14)};
`;

const NoTemplatesLayout = () => {
  const { t } = useTranslation();

  const pushAction = useReduxAction(push);

  return (
    <Flex
      mt={16}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Box>
        <img src={NoContentImage} alt={t('templates:no-templates-alt')} />
      </Box>

      <Box mt={4}>
        <StyledHintText>
          {t('templates:choose-predesigned-labels')}
        </StyledHintText>
      </Box>

      <Flex mt={16}>
        <StyledBrowseDesignsButton
          data-testid="browse-common-design-button"
          icon="right"
          iconName="common-designs"
          onClick={() => pushAction(CHOOSE_A_TEMPLATE)}
        >
          {t('templates:browse-common-designs')}
        </StyledBrowseDesignsButton>

        <StyledCreateDesignButton
          data-testid="create-label-design-button"
          icon="right"
          iconName="add"
          variant="primary"
          onClick={() => pushAction(CREATE_NEW_DESIGN)}
          iconSize={16}
          className="workspace-button"
        >
          {t('templates:create-new-design')}
        </StyledCreateDesignButton>
      </Flex>
    </Flex>
  );
};

export default NoTemplatesLayout;
