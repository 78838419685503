import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'components';
import { NoContentImage } from 'v2/components/common';
import { Container, BodyText } from './index.styles';

export default function NoPrinters() {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex
        flex={1}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <img src={NoContentImage} alt={t('templates:no-templates-alt')} />
        <BodyText pt={'24px'}>
          {t('dialogs:print-test-label.no-printers-text')}
        </BodyText>
      </Flex>
    </Container>
  );
}
