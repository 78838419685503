import { Flex } from 'components';
import styled from 'styled-components';

import { device } from 'utils/mediaQueries';

export const ContentWrapWideScreen = styled.div<any>`
  position: relative;
  z-index: 10;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.px(600)};
  height: 100%;
  transition: height 0.4s ease-out;

  @media ${device.desktop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContentWrap = styled(Flex).attrs({
  ml: 40,
  flexDirection: 'column',
})`
  @media ${device.mobile} {
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  }

  @media ${device.tablet} {
    margin-left: 20px;
  }

  flex: 1;
`;

export const ContentOuterWrap = styled(Flex)`
  @media ${device.mobile} {
    min-height: unset;
  }

  background-color: ${({ theme }) => theme.primary.background};
  min-height: 100vh;
  position: relative;
  z-index: 10;
`;

export const LoaderWrapper = styled.div`
  height: 150px;
`;
