import React from 'react';
import {
  StyledImage,
  Container,
  StyledText,
  VariantType,
} from './Avatar.styles';
import { buildFullName, buildUserInitials } from 'utils/userSettings';

type AvatarProps = {
  img?: string;
  firstName?: string;
  lastName?: string;
  variant?: VariantType;
};

function hasImage(img: string) {
  return img && img !== 'null';
}

export default function Avatar({
  img = '',
  firstName = '',
  lastName = '',
  variant = 'small',
  ...props
}: AvatarProps) {
  return (
    <Container
      data-testid={'avatar'}
      {...props}
      variant={variant}
      $noBorder={Boolean(img)}
    >
      {hasImage(img) ? (
        <StyledImage
          {...props}
          variant={variant}
          src={`data:image/jpeg;base64,  ${img}`}
          title={buildFullName(firstName, lastName)}
          alt="user-pic"
        />
      ) : (
        <StyledText
          data-testid={'avatar-initials'}
        >
          {buildUserInitials(firstName, lastName)}
        </StyledText>
      )}
    </Container>
  );
}
