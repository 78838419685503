import React, { useCallback } from 'react';
import { Button } from 'components';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useAppDispatch, useReduxAction } from '../../../../hooks';
import { actions as dataActions } from 'ducks/dataSources/actions';
import { openErrorToast } from '../../../../state/Toast';
import { getLoggedInOneDriveAccount } from '../../../../services/office365';
import { getAssetPath } from 'utils/files';

const StyledButton = styled(Button)`
  background: url('${getAssetPath('logo_microsoft.svg')}') transparent 12px 50%
    no-repeat;
  border: 1px solid #8c8c8c;
  color: #5e5e5e;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  letter-spacing: 0;
  align-items: center;
  border-radius: 0;
  padding: 12px 12px 12px 48px;
  font-size: 17px;
  transition: background-color 0.2s ease-out;
  &:active {
    background-color: #eee;
  }
  &:hover {
    color: #5e5e5e;
  }
`;

const OneDriveLoginButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dataSources');

  const openPicker = useReduxAction(dataActions.OFFICE_365.request);

  const msalContext = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const openPickerAfterLogin = useCallback(async () => {
    try {
      const wasAlreadyAuthenticated = isAuthenticated;
      const account = await getLoggedInOneDriveAccount(
        msalContext,
        isAuthenticated,
      );

      if (wasAlreadyAuthenticated) {
        // Each click should either log user in or open picker, not both
        openPicker(account);
      }
    } catch (e: any) {
      dispatch(openErrorToast(e.errorMessage));
    }
  }, [dispatch, msalContext, isAuthenticated, openPicker]);

  return (
    <StyledButton variant="text-primary" onClick={openPickerAfterLogin}>
      <AuthenticatedTemplate>
        {t('dataSources:link-to-microsoft-onedrive-file')}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {t('dataSources:sign-in-with-microsoft')}
      </UnauthenticatedTemplate>
    </StyledButton>
  );
};

export default OneDriveLoginButton;
