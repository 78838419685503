import { useReduxSelector } from 'hooks';
import { selectDialog } from 'state/Dialog';

import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const DIALOG_COMPONENTS = {
  Confirmation: ConfirmationDialog,
};

export default function DialogManager() {
  const { type, props } = useReduxSelector(selectDialog);

  const SpecificDialog = DIALOG_COMPONENTS[type];
  if (!SpecificDialog) {
    return null;
  }

  return <SpecificDialog {...props} />;
}
