export const SYSTEM_CATEGORY = 'System'; // This is a hidden category

export enum TemplateCategory {
  Internal = 'ZEBRA_INTERNAL',
  UserCreated = 'USER_CREATED',
}

export enum LabelStock {
  Address = 'Address',
  Barcodes = 'Barcodes',
  Jewelry = 'Jewelry',
  MiniMultipurpose = 'Mini Multipurpose',
  MultipurposeNameTag = 'Multipurpose-Name Tag',
  PostageShipping = 'Postage-Shipping',
  ReturnAddressFileFolder = 'Return Address-File Folder',
  Round = 'Round',
  Shipping = 'Shipping',
  SmallMultipurpose = 'Small Multipurpose',
  XlShipping = 'XL Shipping',
}

type CategoryData = {
  id: string;
  desc: string;
};

export type CategoryList = {
  [key in LabelStock]: CategoryData;
};

export const templateCategories: CategoryList = {
  [LabelStock.Address]: {
    id: '10035148',
    desc: 'For use on envelopes and packages',
  },
  [LabelStock.Barcodes]: {
    id: '10035065',
    desc: 'Designs for UPC, EAN, Code 39, QR Code barcodes, box marking',
  },
  [LabelStock.Jewelry]: {
    id: '10034909',
    desc: 'For labeling and pricing small items',
  },
  [LabelStock.MiniMultipurpose]: {
    id: '10039381',
    desc: 'Pricing, crafting, logos, item organization, filing',
  },
  [LabelStock.MultipurposeNameTag]: {
    id: '10035152',
    desc: 'Visitor and name badges, with or without photos',
  },
  [LabelStock.PostageShipping]: {
    id: '10036128',
    desc: 'Mailing, box marking, safety notices',
  },
  [LabelStock.ReturnAddressFileFolder]: {
    id: '10035154',
    desc: 'File folder identification in multiple formats',
  },
  [LabelStock.Round]: {
    id: '10039374',
    desc: 'Product packaging, holiday labels, gift label stickers, mason jar labels, and customized decorative stickers',
  },
  [LabelStock.Shipping]: {
    id: '10035149',
    desc: 'Mailing and packages, large name badges, part marking',
  },
  [LabelStock.SmallMultipurpose]: {
    id: '10035150',
    desc: 'Barcodes and asset marking',
  },
  [LabelStock.XlShipping]: {
    id: '10035147',
    desc: 'Product/Brand labels, Bottle/Jar labels, Signage Labels (Caution, Hazard, etc.)',
  },
};
