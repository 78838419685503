import React from 'react';
import { Switch, Route } from 'react-router';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import {
  Components,
  Login,
  NotFoundWithMenu,
  Designer,
  Help,
  Workspaces,
  Cloud,
} from 'pages';

import {
  COMMON_DESIGNS_PATH,
  HOME_PAGE_PATH,
  MY_DESIGNS_PATH,
  MY_DATA_SOURCES_PATH,
} from 'utils/config';
import { Eula } from 'pages/eula';
import { MSAL_CONFIG } from 'services/office365';
import PrintDialog from 'components/app/print-dialog/PrintDialog';
import SMBToast from 'components/common/SMBToast';
import SMBFileDialog from 'components/common/SMBFileDialog';
import NotificationWidgetWrapper from './NotificationWidgetWrapper';
import LabelRangeProvider from 'components/app/print-dialog/LabelRangeContext';
import { TemplateDialogModal } from 'pages/templates/components/TemplateDialogModal';
import { OutageDialog } from 'components/app/outage-dialog';
import LiveChatProvider from 'providers/LiveChat';
import LoginRoute from 'components/app/LoginRoute/LoginRoute';
import PrivateRoute from 'components/app/PrivateRoute/PrivateRoute';
import RedirectWithHash from 'components/common/RedirectWithHash/RedirectWithHash';

import { TopNavigation, DialogManager } from './components/app';
import { Account } from './pages/SettingsPage/Account';
import { PrinterSettings } from './pages/SettingsPage/PrinterSettings';
import { Common } from './pages/SettingsPage/Common';
import {
  HomePage,
  DriversPage,
  CommonDesignsPage,
  MyDesignsPage,
  GeneralSettings,
  DatasourcesPage,
  NotificationsPage,
} from './pages';

export function Routes() {
  return (
    <Switch>
      <LoginRoute path="/login" component={Login} />
      <Route path="/eula" component={Eula} />
      <Route path="/cloud" component={Cloud} />
      <LiveChatProvider>
        <MsalProvider instance={new PublicClientApplication(MSAL_CONFIG)}>
          <LabelRangeProvider>
            <TopNavigation />
            <DialogManager />

            <SMBFileDialog />
            <PrintDialog />
            <TemplateDialogModal />
            <SMBToast />
            <NotificationWidgetWrapper />
            <OutageDialog />
            <Switch>
              <PrivateRoute path={HOME_PAGE_PATH} component={HomePage} />
              <PrivateRoute path={MY_DESIGNS_PATH} component={MyDesignsPage} />
              <PrivateRoute
                path={COMMON_DESIGNS_PATH}
                component={CommonDesignsPage}
              />
              <PrivateRoute path="/components" component={Components} />
              <PrivateRoute path="/workspaces/:id?" component={Workspaces} />
              <PrivateRoute path="/user-settings" component={Account} />
              <PrivateRoute
                path="/settings/printers/general"
                component={Common}
              />
              <PrivateRoute
                path="/settings/printers/:printerId"
                component={PrinterSettings}
              />
              <PrivateRoute path="/settings" component={GeneralSettings} />
              <PrivateRoute
                path={MY_DATA_SOURCES_PATH}
                component={DatasourcesPage}
              />
              <PrivateRoute path="/designer" component={Designer} />
              <PrivateRoute
                path="/notifications"
                component={NotificationsPage}
              />
              <PrivateRoute path="/help" component={Help} />
              <PrivateRoute path="/apps" component={DriversPage} />
              <RedirectWithHash to={HOME_PAGE_PATH} />
            </Switch>
          </LabelRangeProvider>
        </MsalProvider>
      </LiveChatProvider>
      <PrivateRoute component={NotFoundWithMenu} />
    </Switch>
  );
}
