import {
  createReducer,
  generateFetchDataReducer,
  generatePostDataReducer,
  generateSelectDataReducer,
} from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';
import {
  FETCH_DATA_INITIAL_STATE,
  POST_DATA_INITIAL_STATE,
  SELECTED_DATA_INITIAL_STATE,
} from 'ducks/utils';

const leaveWorkspace = (state: any, action: any) => ({
  ...state,
  data: state?.data?.filter((w: any) => w.id !== action.payload.id),
});

const update = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE),
);

// is there an issue here with updating the avatar
const updateWorkspace = (state: any, action: any) => {
  return {
    ...state,
    data: state?.data?.map((workspace: any) =>
      workspace.id === action.payload.id
        ? {
            ...action.payload,
          }
        : workspace,
    ),
  };
};

const all = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ALL),
  [types.LEAVE.SUCCESS]: leaveWorkspace,
  [types.UPDATE.SUCCESS]: updateWorkspace,
});

const current = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer(types.CURRENT),
);

const selected = createReducer(
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer(types.SELECTED),
);

const leave = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.LEAVE),
);

export default combineReducers({ all, current, selected, update, leave });
