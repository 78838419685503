import React from 'react';
import {
  ArrowWrapper,
  LeftArrow,
  RightArrow,
  RightLeftType,
} from './Arrow.styles';
import { useCarousel } from './CarouselContext';

const Arrow = ({ direction = 'right' }: { direction: RightLeftType }) => {
  const [{ current, total }, { next, prev }] = useCarousel();

  const disabled = React.useMemo<boolean>(() => {
    if (!total) return true;
    if (direction === 'left' && current === 0) return true;
    if (direction === 'right' && current === total - 1) return true;
    return false;
  }, [current, direction, total]);

  const onClick = React.useCallback(() => {
    if (disabled) return;
    direction === 'right' ? next() : prev();
  }, [direction, disabled, next, prev]);

  return (
    <ArrowWrapper
      direction={direction}
      disabled={disabled}
      onClick={onClick}
      data-testid={`carouselArrow-${direction}`}
    >
      {direction === 'right' ? <RightArrow /> : <LeftArrow />}
    </ArrowWrapper>
  );
};

export default Arrow;
