import styled from 'styled-components';
import { device } from 'utils/mediaQueries';
import { Flex, Box } from 'components';

export const NotificationWrap = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 5,
  pr: 5,
  right: 5,
})`
  z-index: 1003;
  display: flex;
  align-items: flex-end;

  @media ${device.mobileToTablet} {
    padding-left: 0;
  }

  @media ${device.mobile} {
    bottom: 25px;
    padding-left: 0;
  }
`;

export const NotificationBox = styled(Box)`
  @media ${device.mobileToTablet} {
    width: 95%;
  }

  @media ${device.mobile} {
    width: 95%;
  }
`;
