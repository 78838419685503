import { Box, Flex } from 'components';
import React from 'react';
import CloudFileUploadButton from './CloudFileUploadButton';
import UploadButton from './LocalFileUploadButton';
import styled from 'styled-components/macro';
import { device } from 'utils/mediaQueries';

const StyledFlex = styled(Flex)`
  flex-direction: row;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    button {
      justify-content: center;
      width: 100%;
    }
  }
`;

const StyledBox = styled(Box)`
  @media ${device.mobile} {
    margin: 16px 0 0 0;
  }
`;

export const FilesButtons = () => {
  return (
    <StyledFlex>
      <StyledBox mr={6}>
        <CloudFileUploadButton />
      </StyledBox>
      <StyledBox ml={6}>
        <UploadButton />
      </StyledBox>
    </StyledFlex>
  );
};
