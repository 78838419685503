import React, { useContext, useRef } from 'react';
import { Box, Flex, Modal, Text } from 'components';
import { CircleBox, StyledButton, StyledIcon, StyledText, VideoLinkContainer } from './FeedOnHeadCloseInfoModal.styles';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks';
import FeedOnHeadCloseVideoModal from './FeedOnHeadCloseVideoModal';
import { ThemeContext } from 'styled-components/macro';

const FeedOnHeadCloseInfoModal = ({ modalRef, showDisableButton, onDisableClick }) => {
  const { t } = useTranslation(['common', 'overview']);
  const theme = useContext(ThemeContext);

  const modalHook = useModal(modalRef);

  const videoModalRef = useRef(null);
  const videoModalHook = useModal(videoModalRef);

  const footer = (
    <Flex justifyContent={'flex-end'}>
      {showDisableButton ? (
        <>
          <StyledButton
            data-testid="head-close-feed-cancel-button"
            variant={'text-primary'}
            onClick={modalHook.close}
          >
            {t('common:cancel')}
          </StyledButton>
          <StyledButton
            data-testid="head-close-feed-disable-button"
            variant={'primary'}
            onClick={() => {
              onDisableClick();
              modalHook.close();
            }}
            ml={theme.spacing.buttonGap}
          >
            {t('common:disable')}
          </StyledButton>
        </>
      ) : (
        <StyledButton variant={'primary'} onClick={modalHook.close}>
          {t('common:ok')}
        </StyledButton>
      )}
    </Flex>
  );

  return (
    <>
      <Modal
        data-testid="feed-on-head-close-info-modal"
        ref={modalRef}
        title={t('overview:printer.align-label')}
        defaultPadding={false}
        clickOutside={true}
        headerPadding={'28px 40px 15px 40px'}
        footer={footer}
      >
        <Box paddingX={'40px'} style={{ maxWidth: '460px' }}>
          <Text mb={'30px'}>{t('overview:printer.feed-on-head-close-body-text-first')}</Text>
          <Text mb={'16px'}>{t('overview:printer.feed-on-head-close-body-text-second')}</Text>
          <VideoLinkContainer
            data-testid="video-modal-link"
            onClick={videoModalHook.open}
          >
            <StyledText>{t('overview:printer.label-alignment-demo')}</StyledText>
            <CircleBox>
              <StyledIcon />
            </CircleBox>
          </VideoLinkContainer>
        </Box>
      </Modal>
      <FeedOnHeadCloseVideoModal modalRef={videoModalRef} />
    </>
  );
};

export default FeedOnHeadCloseInfoModal;
