// Can't use imports in this file, for example when running Jest tests

export const ZPC_SUBDOMAIN_SANDBOX_SUFFIX = '.zpc-sandbox';

export enum FrontendEnvironment {
  Production = 'production',
  Stage = 'stage',
  Develop = 'develop',
  Sandbox = 'sandbox',
}

export enum BackendEnvironment {
  Sandbox,
  Develop,
  Stage,
  Production,
}

export enum NodeEnvironment {
  Development = 'development',
  Production = 'production',
  Test = 'test'
}

export enum BaseUrl {
  Sandbox = 'https://zsbportal-sandbox.zebra.com',
  Develop = 'https://zsbportal-dev.zebra.com',
  Stage = 'https://zsbportal-stage.zebra.com',
  Production = 'https://zsbportal.zebra.com',
}

if (process.env.NODE_ENV === NodeEnvironment.Development) {
  console.info(`REACT_APP_FRONTEND_ENV=${process.env.REACT_APP_FRONTEND_ENV}`); // i.e. sandbox, develop, stage, production
  console.info(
    `REACT_APP_ZPC_SUBDOMAIN=${process.env.REACT_APP_ZPC_SUBDOMAIN}`,
  ); // i.e. example-cluster-name.zpc-sandbox, zpc-dev, zpc-stage, zpc
  console.info(`REACT_APP_VERSION=${process.env.REACT_APP_VERSION}`);
  console.info(`REACT_APP_DEBUG_LDA=${process.env.REACT_APP_DEBUG_LDA}`);
  console.info(`PUBLIC_URL=${process.env.PUBLIC_URL}`);
}

export const getFrontendEnvironment = (): FrontendEnvironment => {
  const frontendEnvironment = process.env.REACT_APP_FRONTEND_ENV;
  switch (frontendEnvironment) {
    case 'sandbox':
      return FrontendEnvironment.Sandbox;
    case 'develop':
      return FrontendEnvironment.Develop;
    case 'stage':
      return FrontendEnvironment.Stage;
    case 'production':
      return FrontendEnvironment.Production;
    default:
      throw new Error(`Invalid frontend environment: ${frontendEnvironment}`);
  }
};

export const getBackendEnvironment = () => {
  const subdomain = process.env.REACT_APP_ZPC_SUBDOMAIN;
  switch (subdomain) {
    case 'zpc':
      return BackendEnvironment.Production;
    case 'zpc-stage':
      return BackendEnvironment.Stage;
    case 'zpc-dev':
      return BackendEnvironment.Develop;
    default:
      if (subdomain?.endsWith(ZPC_SUBDOMAIN_SANDBOX_SUFFIX)) {
        return BackendEnvironment.Sandbox;
      } else {
        throw new Error(`Invalid ZPC subdomain: ${subdomain}`);
      }
  }
};

export const isBackendProduction = (): boolean =>
  getBackendEnvironment() === BackendEnvironment.Production;

export const isBackendStageOrProduction = (): boolean =>
  getBackendEnvironment() === BackendEnvironment.Stage ||
  getBackendEnvironment() === BackendEnvironment.Production;

const getFrontendBaseUrl = () => {
  const environment: FrontendEnvironment = getFrontendEnvironment();
  switch (environment) {
    case FrontendEnvironment.Production:
      return BaseUrl.Production;
    case FrontendEnvironment.Stage:
      return BaseUrl.Stage;
    case FrontendEnvironment.Develop:
      return BaseUrl.Develop;
    case FrontendEnvironment.Sandbox:
      return BaseUrl.Sandbox;
    default:
      throw new Error(`Invalid frontend environment: ${environment}`);
  }
};

const getEnvironmentSpecificFrontendUrl = () => {
  const environment: FrontendEnvironment = getFrontendEnvironment();
  switch (environment) {
    case FrontendEnvironment.Sandbox:
      return `${BaseUrl.Sandbox}/${getSandboxId()}`;
    case FrontendEnvironment.Develop:
    case FrontendEnvironment.Stage:
    case FrontendEnvironment.Production:
    default:
      return getFrontendBaseUrl();
  }
};

export const getBackendBaseUrl = () => {
  const environment: BackendEnvironment = getBackendEnvironment();
  switch (environment) {
    case BackendEnvironment.Production:
      return BaseUrl.Production;
    case BackendEnvironment.Stage:
      return BaseUrl.Stage;
    case BackendEnvironment.Develop:
      return BaseUrl.Develop;
    case BackendEnvironment.Sandbox:
      return BaseUrl.Sandbox;
    default:
      throw new Error(`Invalid backend environment: ${environment}`);
  }
};

export const getSandboxId = () => {
  const publicUrl = process.env.PUBLIC_URL;
  if (publicUrl?.match(/^\/.*/g)) {
    const sandboxId = publicUrl.replace(/^\/|\/$/g, '');
    if (sandboxId) {
      return sandboxId;
    }
  }
  return null;
};

const getLdaUrl = () => {
  const ldaEnv = process.env.REACT_APP_DEBUG_LDA === 'true' ? 'lda-dev' : 'lda';
  const ldaBaseUrl =
    getBackendEnvironment() === BackendEnvironment.Develop &&
    getFrontendEnvironment() === FrontendEnvironment.Sandbox
      ? BaseUrl.Develop
      : getEnvironmentSpecificFrontendUrl();

  return `${ldaBaseUrl}/${ldaEnv}/index.html`;
};

export const APPLICATION_VERSION = `${
  process.env.REACT_APP_VERSION
}-${getFrontendEnvironment()}`;

export const ZEBRA_GRPC_SERVICE_URL = `https://zsbp.${process.env.REACT_APP_ZPC_SUBDOMAIN}.zebra.com`;
export const ZEBRA_NL_API_BASE_URL = `https://nl.${process.env.REACT_APP_ZPC_SUBDOMAIN}.zebra.com`;

export const LDA_URL = getLdaUrl();

export const SSO_BASE_URL = isBackendProduction()
  ? 'https://api.zebra.com'
  : 'https://test-api1.zebra.com';
export const SSO_EMAIL_TOKEN_URL = `${SSO_BASE_URL}/ZebIDM/tokenToUpdateuser/`;

export const getSsoSignupUrl = () => {
  switch (getBackendEnvironment()) {
    case BackendEnvironment.Production:
      return 'https://signup.zebra.com';
    case BackendEnvironment.Stage:
      return 'https://stagec-signup.zebra.com';
    case BackendEnvironment.Develop:
    default:
      return 'https://devc-signup.zebra.com';
  }
};

const LOGOUT_CHANGE_PASSWORD_BASE_URL = isBackendProduction()
  ? 'https://pi.zebra.com'
  : 'https://test-pi.zebra.com';
export const LOGOUT_URL = `${LOGOUT_CHANGE_PASSWORD_BASE_URL}/ext/signout`;

const SSO_REDIRECT_URI = `${ZEBRA_GRPC_SERVICE_URL}/auth/code`;

export const SSO_CHANGE_PASSWORD_URL = `${getSsoSignupUrl()}/content/userreg/change-password-landing.html?callbackurl=${encodeURIComponent(
  `${LOGOUT_CHANGE_PASSWORD_BASE_URL}/as/authorization.oauth2` +
    `?response_type=code` +
    `&client_id=${
      isBackendProduction() ? 'ApigeeWebAppMB' : 'deviceflow_apigee_mb'
    }` +
    `&redirect_uri=${SSO_REDIRECT_URI}` +
    `&scope=zuid openid`,
)}`;

export const ZEBRA_LOGIN_URL =
  `${SSO_BASE_URL}/v1/user/authorization` +
  `?response_type=code` +
  `&client_id=${
    isBackendProduction()
      ? '8vgQcJpw1Z1MABEnS5xh4g8duq5LunPE'
      : 'GzEJq3IsYZZHwSvvgBhTe3Wx246j1cod'
  }` +
  `&redirect_uri=${encodeURIComponent(SSO_REDIRECT_URI)}` +
  `&scope=zuid%20openid`;

export const OFFICE_365_CREDENTIALS = {
  SDK_URL: 'https://js.live.net/v7.2/OneDrive.js',
  CLIENT_ID: '23f3d001-15eb-48a8-bcd7-08650fb6dadb',
  SCOPES: ['files.Read.All', 'Contacts.Read', 'Contacts.Read.Shared'],
  REDIRECT_URI: `${getEnvironmentSpecificFrontendUrl()}/cloud`,
};

export const HOME_PAGE_PATH = '/home';
export const COMMON_DESIGNS_PATH = '/common-designs';
export const MY_DESIGNS_PATH = '/my-designs';
export const MY_DATA_SOURCES_PATH = '/datasources';
export const MY_NOTIFICATIONS_PATH = '/notifications';
export const MY_APPS_PATH = '/apps';
export const MY_PRINTER_SETTINGS_PATH = '/printer-settings';
export const MY_ACCOUNT_SETTINGS_PATH = '/user-settings';
export const PRINT_PAGE_PATH = '/home/print';
export const CHOOSE_A_TEMPLATE = '/home/print/common-designs';
export const RECENTLY_PRINTED = '/home/print/recent';
export const CREATE_NEW_DESIGN = '/home/new-design';
export const MY_LABELS = '/home/print/my-designs';

export const TIME_OUT_TOAST = 3000;
export const CARTRIDGE_MINIMUM_LABELS = 20;
export const PRINTER_NAME_LENGTH_MAX = 30;

export const GRPC_CODES = {
  UNKNOWN: 2,
};

export const SETTING_KEY_FEED_ON_HEAD_CLOSE = 'ezpl.head_close_action';

export const HEAD_CLOSE_MEMORIZED_VALUE =
  'HEAD_CLOSE_MEMORIZED_VALUE_FOR_PRINTER';
export const DRIVERS_MODAL_SHOWN_COUNT_PREFIX = 'driversModalShownCount';
export const DRIVERS_MODAL_SHOWN_MAX_COUNT = 2;

export const ZEBRA_EMAIL_EXTENSION = '@zebra.com';
export const MAX_UPLOAD_FILE_SIZE = 23068672; //22MB in bytes

export const OVERVIEW_MODAL_SHOWN_COUNT_PREFIX = 'overviewModalShownCount';
export const OVERVIEW_MODAL_SHOWN_MAX_COUNT = 1;

export const DEFAULT_USER_COUNTRY_CODE = 'USA';
export const HOW_TO_ADD_PRINTER_LINK = 'https://zsbseries.com/setup';
