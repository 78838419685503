import React, { useMemo } from 'react';
import { Flex, Box } from 'components';
import { FileInfoType } from 'utils/dataSourcesTypes';
import { unixToDateFormatter } from 'utils/dateFormatter';
import { getMimeTypeIcon } from 'utils/icons/getMimeTypeIcon';
import LocalFilesTable from './FilesTable/FilesTable';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
const StyledFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.primary.background};
`;
const formatProvider = (provider) => {
  if (provider === 'OneDrive') return 'Microsoft OneDrive';
  return provider;
};
const FilesList = ({ files, hover, onRowSelect }) => {
  const { t } = useTranslation('dataSources');

  const localFilesArray: FileInfoType[] = useMemo(
    () =>
      files
        .map(
          ({
            id,
            name,
            mimetype,
            lastModified,
            provider,
            linkedMicrosoftAccount,
          }) => ({
            id,
            name,
            mimetype: getMimeTypeIcon(mimetype),
            lastModified,
            provider: formatProvider(provider),
            linkedMicrosoftAccount,
          }),
        )
        .sort((a, b) => {
          if (!a.name) return -1;
          return a.name.localeCompare(b.name);
        })
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i),
    [files],
  );
  const columns = [
    { key: 'mimetype' },
    { key: 'name', title: t('dataSources:file-name') },
    { key: 'provider', title: t('dataSources:source') },
    {
      key: 'lastModified',
      title: t('dataSources:date-added'),
      formatter: unixToDateFormatter,
      align: 'left',
    },
    { key: 'action', align: 'right' },
  ];
  return (
    <>
      <StyledFlex
        px={12}
        flexDirection={'column'}
        marginTop={'5px'}
        alignItems={'left'}
        width={'100%'}
        pb={120}
      >
        <Box width={'100%'}>
          <LocalFilesTable
            hover={hover}
            onRowSelect={onRowSelect}
            columns={columns}
            data={localFilesArray}
          />
        </Box>
      </StyledFlex>
    </>
  );
};
export default FilesList;
