import { put, takeLatest } from 'redux-saga/effects';
import {
  types as categoriesTypes,
  actions as categoriesActions,
} from 'ducks/categories/actions';
import { getFilteredCategories } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { apiCall } from './utils';

function* handleGetFilteredCategories({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const { categories } = yield apiCall(
      getFilteredCategories,
      customer?.id,
      payload,
      token,
    );

    if (categories) {
      yield put(categoriesActions.FILTERED.success([...categories]));
    }
  } catch (err: any) {
    yield put(categoriesActions.FILTERED.failure({ error: err }));
  }
}

export default function* watcher() {
  yield takeLatest(
    categoriesTypes.FILTERED.REQUEST,
    handleGetFilteredCategories,
  );
}
