import { useEffect, useContext } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components/macro';

import { Flex, Button } from 'components';
import { useReduxAction } from 'hooks';
import { actions as templateActions } from 'ducks/templates/actions';

import { actions as labelSizesActions } from 'ducks/labelSizes/actions';
import { StickyHeader } from 'v2/components/app';
import { PageTitle } from 'v2/components/common';
import { MultiSelectProvider, useMultiSelect } from 'providers/MultipleSelect';

import Page from '../Page';
import TemplatesList from './TemplatesList';
import TemplatesLayoutSearch from './TemplatesLayoutSearch';
import TemplatesLayoutFilter from './TemplatesLayoutFilter';
import TemplatesLayoutLabelButtons from './TemplatesLayoutLabelButtons';

const MyDesignsPage = () => {
  const { t } = useTranslation();

  const getMyDesignSizesAction = useReduxAction(
    templateActions.MY_DESIGN_ALL_SIZES.request,
  );
  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);
  const getFilteredTemplatesHash = useReduxAction(
    templateActions.FILTERED_HASH.request,
  );

  const pushAction = useReduxAction(push);

  useEffect(() => {
    getMyDesignSizesAction();
    getAllLabelSizes();
    getFilteredTemplatesHash();
  }, [getMyDesignSizesAction, getAllLabelSizes, getFilteredTemplatesHash]);

  return (
    <MultiSelectProvider>
      <Page footerContent={<FooterBar />}>
        <StickyHeader>
          <PageTitle
            title={t('menu:design-a-label')}
            onBackClick={() => pushAction('/')}
          />

          <Flex alignItems={'end'} justifyContent={'space-between'}>
            <TemplatesLayoutSearch />

            <TemplatesLayoutLabelButtons />
          </Flex>

          <TemplatesLayoutFilter />
        </StickyHeader>

        <TemplatesList />
      </Page>
    </MultiSelectProvider>
  );
};

export function FooterBar() {
  const { t } = useTranslation('common');
  const theme = useContext(ThemeContext);
  const [{ selected }, { clear }] = useMultiSelect();
  const location = useLocation();
  const removeTemplates = useReduxAction(
    templateActions.REMOVE_MULTIPLE.request,
  );

  const handleDeleteMultipleTemplates = () => {
    removeTemplates({ templates: selected, location });
  };

  if (selected.length === 0) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="end"
      data-testid={'delete-multiple-templates-bar'}
    >
      <Button variant={'secondary'} onClick={() => clear()}>
        {t('common:cancel')}
      </Button>
      <Button
        ml={theme.spacing.buttonGap}
        variant={'destructive'}
        onClick={handleDeleteMultipleTemplates}
      >
        {t('common:delete')}
      </Button>
    </Flex>
  );
}

export default MyDesignsPage;
