import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReduxAction } from 'hooks';
import { Pagination, TemplatesGrid } from 'v2/components/app';
import { ErrorText } from 'v2/components/common';
import { zebraFilteredTemplates as selectZebraFilteredTemplates } from 'ducks/templates/selectors';
import { actions as templateActions } from 'ducks/templates/actions';
import { useFilteredTemplates } from 'v2/hooks';

export default function TemplatesList() {
  const { t } = useTranslation();

  const zebraFilteredTemplatesAction = useReduxAction(
    templateActions.ZEBRA_FILTERED.request,
  );

  const {
    filteredTemplatesData: zebraFilteredTemplatesData,
    filteredTemplatesDataLoading: zebraFilteredTemplatesDataLoading,
    filteredTemplatesError: zebraFilteredTemplatesError,
    totalPagesCount,
  } = useFilteredTemplates(
    selectZebraFilteredTemplates,
    zebraFilteredTemplatesAction,
  );

  if (zebraFilteredTemplatesError) {
    return (
      <ErrorText variant="h2" mb={8}>
        {t('templates:server-error')}
      </ErrorText>
    );
  }

  return (
    <>
      <TemplatesGrid
        isLoading={zebraFilteredTemplatesDataLoading}
        templates={zebraFilteredTemplatesData}
      />

      {totalPagesCount > 1 && <Pagination pageCount={totalPagesCount} />}
    </>
  );
}
