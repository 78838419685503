import React from 'react';
import PrinterWithoutText from './PrinterWithoutText';
import PrinterWithText from './PrinterWithText';

const Printers = ({
  name = '',
  src,
  alt = 'Printer',
  width = '38px',
  showText,
}) => {
  return showText ? (
    <PrinterWithText name={name} src={src} alt={alt} width={width} />
  ) : (
    <PrinterWithoutText src={src} alt={alt} width={width} />
  );
};

export default Printers;
