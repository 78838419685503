import React, { useEffect } from 'react';

import { Box } from 'components';
import { useReduxAction, useUrlParam } from 'hooks';
import { actions as templateActions } from 'ducks/templates/actions';
import { PageTitle } from 'v2/components/common';
import { push } from 'connected-react-router';
import { COMMON_DESIGNS_PATH } from 'utils/config';
import { StickyHeader } from 'v2/components/app/StickyHeader/StickyHeader';

import TemplatesList from './TemplatesList';
import FilterTemplatesLayout from './FilterTemplatesLayout';

const TemplatesLayout = () => {
  const [selectedCategory] = useUrlParam('category', '');
  const allLabelSizesActionRequest = useReduxAction(
    templateActions.ZEBRA_FILTERED_ALL_SIZES.request,
  );
  const pushAction = useReduxAction(push);

  const handleOnBackClick = (): void => {
    pushAction(COMMON_DESIGNS_PATH);
  };

  useEffect(() => {
    allLabelSizesActionRequest({ selectedCategory });
  }, [selectedCategory, allLabelSizesActionRequest]);

  return (
    <>
      <StickyHeader>
        <Box>
          <PageTitle title={selectedCategory} onBackClick={handleOnBackClick} />
          <FilterTemplatesLayout />
        </Box>
      </StickyHeader>

      <TemplatesList />
    </>
  );
};

export default TemplatesLayout;
