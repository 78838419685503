import { createSelector } from 'reselect';

const printerTools = (state: any) => state.info.printerTools;
const links = (state: any) => state.info.links;
const ldaVersions = (state: any) => state.info.ldaVersions;
const whoAmI = (state: any) => state.info.whoAmI;

export const getPrinterTools = createSelector(
  [printerTools],
  (printerToolsInfo) => printerToolsInfo.data,
);
export const getPrinterToolsError = createSelector(
  [printerTools],
  (printerToolsInfo) => printerToolsInfo.error,
);
export const getPrinterToolsLoading = createSelector(
  [printerTools],
  (printerToolsInfo) => printerToolsInfo.loading,
);
export const getLinks = createSelector([links], (links) => links.data);

export const getLDAVersions = createSelector(
  [ldaVersions],
  (versions) => versions.data,
);

export const selectPrinterTools = createSelector(
  [printerTools],
  (printerToolsInfo) => printerToolsInfo,
);

export const getWhoAmI = createSelector(
  [whoAmI],
  (whoAmI) => whoAmI.success,
)
