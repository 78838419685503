import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { useReduxAction } from 'hooks';
import { NavigationTabBar } from 'components/common/NavigationTabBar';
import { ContentOuterWrap, ContentWrap } from 'components/common/layout/Layout';

import { Log } from './Log';
import { Settings } from './Settings';
import { Messages } from './Messages';

export default function Notifications() {
  const { t } = useTranslation('notifications');

  const pushAction = useReduxAction(push);
  const location = useLocation();

  const applyPage = (val) => {
    if (val === 0) {
      pushAction('/notifications');
    } else if (val === 1) {
      pushAction('/notifications/settings');
    } else {
      pushAction('/notifications/messages');
    }
  };

  const getSelected = () => {
    if (location.pathname === '/notifications') {
      return 0;
    } else if (location.pathname === '/notifications/settings') {
      return 1;
    } else if (location.pathname === '/notifications/messages') {
      return 2;
    }
  };

  return (
    <ContentOuterWrap>
      <ContentWrap>
        <NavigationTabBar
          data-testid="notifications-nav-bar"
          selected={getSelected()}
          pageNames={[
            t('notifications:history'),
            t('notifications:settings'),
            t('notifications:messages'),
          ]}
          setPage={applyPage}
        />
        <Switch>
          <Route path="/notifications" component={Log} exact />
          <Route path="/notifications/settings" component={Settings} />
          <Route path="/notifications/messages" component={Messages} />
        </Switch>
      </ContentWrap>
    </ContentOuterWrap>
  );
}
