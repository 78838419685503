import React, { forwardRef } from 'react';
import { Modal, Flex, Text, Box, Button } from 'components';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const Light = styled.span`
  font-weight: 100;
`;

const PrinterDetailsModal = forwardRef(({ printer }: any, ref: any) => {
  const { t } = useTranslation(['overview', 'common']);

  return (
    <Modal
      clickOutside={() => ref.current.close()}
      ref={ref}
      data-testid="printer-details-modal"
    >
      <Box mb={8}>
        <Text fontSize={24} fontWeight={300}>
          {t('overview:printer-details')}
        </Text>
      </Box>
      {Object.keys(printer).map((key) => (
        <Box mb={4} key={key}>
          <Text fontWeight={300}>{key}</Text>
          {typeof printer[key] == 'object' ? (
            Object.keys(printer[key]).map((subkey) => (
              <div key={`${key},${subkey}`}>
                {subkey}: <Light>{String(printer[key][subkey])}</Light>
              </div>
            ))
          ) : (
            <Text>{printer[key]}</Text>
          )}
        </Box>
      ))}
      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="printer-details-cancel-button"
          onClick={() => ref.current.close()}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>
      </Flex>
    </Modal>
  );
});

export default PrinterDetailsModal;
