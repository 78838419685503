import styled from 'styled-components';
import { Text } from 'components';

export const StyledCheckBox = styled.input.attrs({
  type: 'checkbox',
})<{ isTriState?: boolean; indeterminate?: boolean }>`
  height: ${({ theme }) => theme.px(11)};
  min-width: ${({ theme }) => theme.px(11)};
  margin: 0 13px 0 0;
  accent-color: ${({ theme }) => theme.primary.base};

  ${({ isTriState, indeterminate, theme }) =>
    isTriState && !indeterminate && `
    &:indeterminate {
      appearance: none;
      height: 22px;
      min-width: 22px;
      padding: initial;
      margin: 0 13px 0 0;
      box-sizing: border-box;
      background-color:  ${theme.primary.base};
      outline: 2px ${theme.white}; 
      border-radius: 3px;
      
      &::after {
        content: '';
        border-bottom: 3px solid ${theme.white};
        position: relative;
        padding-left: 12px;
        margin: 5px;
        bottom: 5px;
      }
      
    }
  `}
`;

export const LabelText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
  fontSize: 14,
}))``;
