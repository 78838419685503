import { useEffect, useCallback } from 'react';
import { useReduxSelector, useReduxAction } from 'hooks';
import equal from 'deep-equal';

/**
 * Custom hook to fetch data from redux or dispatch an action to fetch the data
 *
 * Returns an array so multiple calls can destructure different variable names
 *
 * USAGE EXAMPLE:
 *   const [scores, pending, error, retry] = useFetchedData(
 *     getTemplates,
 *     templateActions.FILTERED.request,
 *     { size: 0, sort: 0 }
 *   );
 */

const useFetchedData = (
  selector: any,
  requestAction: any,
  requestPayload: any,
) => {
  const actionDispatcher = useReduxAction(requestAction);
  const result = useReduxSelector(selector) as any;
  const { data, loading, error, params } = result;

  // memoise function to fetch the data for the given action & payload
  const fetchData = useCallback(
    () => actionDispatcher(requestPayload),
    [actionDispatcher, requestPayload],
  );

  // re-fetch the data if it does not exist or the action/arguments changes
  useEffect(() => {
    if (loading) return;
    if (!equal(requestPayload, params)) fetchData();
    // if (!data || !equal(requestPayload, params)) fetchData(); //NOTE: This line causes infinite loop if something is stopping the saga from running
  }, [data, fetchData, params, requestPayload, loading]);

  // return data, pending and error with fetchData function for retrying
  return [
    !equal(requestPayload, params) ? null : data, //check if the params are not the same do not return from the store
    loading,
    error,
    fetchData,
  ];
};

export default useFetchedData;
