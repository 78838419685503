import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Search } from 'components';
import { actions as searchActions } from 'ducks/search/actions';
import { getTemplatesSuggestions as selectTemplateSuggestions } from 'ducks/search/selectors';
import { getAllUserTemplates as selectAllUserTemplates } from 'ducks/templates/selectors';
import { useReduxAction, useReduxSelector, useUrlParam } from 'hooks';
import { HintText } from 'v2/components/common';

import styled from 'styled-components';

const SearchWrap = styled(Box)`
  flex: 1;
  max-width: 512px;
  margin: ${({ theme }) => theme.px(16, 4, 0, 0)};
`;

const TemplatesLayoutSearch = () => {
  const { t } = useTranslation();

  const searchTemplates = useReduxAction(searchActions.TEMPLATES.request);

  const allUserTemplates = useReduxSelector(selectAllUserTemplates);
  const options = useReduxSelector(selectTemplateSuggestions);

  const [searchString, setSearchString] = useUrlParam('search', '');

  const onLibrarySearch = (value: string): void => {
    searchTemplates(value);
  };

  if (allUserTemplates.length === 0) return null;

  return (
    <Flex flexDirection={'column'} flex={1}>
      <HintText
        data-testid="access-designs"
        variant="label"
        lineHeight={'32px'}
      >
        {t('templates:access-saved-designs')}
      </HintText>

      <SearchWrap>
        <Search
          placeholder={t('templates:search-your-designs')}
          data-testid="input-search"
          onChange={onLibrarySearch}
          options={options}
          onSelect={setSearchString}
          initialSearch={searchString}
        />
      </SearchWrap>
    </Flex>
  );
};

export default TemplatesLayoutSearch;
