import * as React from 'react';
import { push } from 'connected-react-router';
import { Button, Box } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { isSidebarCollapsed } from 'ducks/sideBar/selectors';
import {
  MY_PRINTER_SETTINGS_PATH,
  MY_DESIGNS_PATH,
} from '../../../../utils/config';
import { useTranslation } from 'react-i18next';
import { getAssetPath } from 'utils/files';
import { Carousel } from 'components';
import {
  CardHolder,
  CarouselContainer,
  CloseButton,
  CloseIcon,
  FullScreenBox,
  Slide,
  CarouselTitle,
  SlideDescription,
  SlideContainer,
  SlideSubTitle,
  SlideContent,
} from './OverviewIntro.styles';

import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import { getCustomer } from '../../../../ducks/auth/selectors';
import { CustomerInfoType } from 'zsbpsdk/src/customer';

import {
  OVERVIEW_MODAL_SHOWN_COUNT_PREFIX,
  OVERVIEW_MODAL_SHOWN_MAX_COUNT,
} from 'utils/config';
import { useEffect, useState } from 'react';

const getOverviewModalShownCountKey = (customer: CustomerInfoType) =>
  `${OVERVIEW_MODAL_SHOWN_COUNT_PREFIX}-${customer.id}`;

export const OverviewIntro = (): any => {
  const [hideIntro, setHideIntro] = React.useState(false);
  const _push = useReduxAction(push);
  const { t } = useTranslation('overview');
  const currentCustomer = useReduxSelector(getCustomer);
  const [overviewVisible, setOverviewVisible] = useState<boolean>(false);

  const sideBarCollapsedState = useReduxSelector(isSidebarCollapsed);

  useEffect(() => {
    const overviewModalShownCount = getFromLocalStorage(
      getOverviewModalShownCountKey(currentCustomer),
    );

    if (overviewModalShownCount !== null) {
      const count = Number(overviewModalShownCount);
      if (count >= OVERVIEW_MODAL_SHOWN_MAX_COUNT) {
        setOverviewVisible(false);
      } else {
        saveToLocalStorage(
          getOverviewModalShownCountKey(currentCustomer),
          count + 1,
        );
        setOverviewVisible(true);
      }
    } else {
      saveToLocalStorage(getOverviewModalShownCountKey(currentCustomer), 1);
      setOverviewVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return overviewVisible && !hideIntro ? (
    <FullScreenBox collapsedState={sideBarCollapsedState}>
      <CardHolder flexDirection={'column'} p={48} elevation="one">
        <CloseButton
          data-testid={'close-button-overview'}
          as={'button'}
          onClick={() => setHideIntro(true)}
        >
          <CloseIcon />
        </CloseButton>
        <CarouselContainer>
          <Box>
            <CarouselTitle
              data-testid="welcome-slides-title"
            >
              {t('overview:welcome-slides-title')}
            </CarouselTitle>
          </Box>
          <Carousel>
            <Slide key={1}>
              <SlideContainer>
                <SlideSubTitle>
                  {t('overview:welcome-slide2-subtitle')}
                </SlideSubTitle>
                <Box pl={36} pr={36} pt={16}>
                  <SlideDescription>
                    {t('overview:welcome-slide2-text')}
                  </SlideDescription>
                </Box>
                <SlideContent>
                  <img
                    src={getAssetPath('welcome-image-slide2.png')}
                    style={{
                      paddingTop: '66px',
                    }}
                    alt=""
                  />
                  <Button
                    variant={'primary'}
                    size={'xl'}
                    mb={40}
                    mt={72}
                    onClick={() => {
                      setHideIntro(true);
                      _push(MY_PRINTER_SETTINGS_PATH);
                    }}
                  >
                    {t('overview:welcome-slide2-settings-button')}
                  </Button>
                </SlideContent>
              </SlideContainer>
            </Slide>
            <Slide key={2}>
              <SlideContainer>
                <SlideSubTitle>
                  {t('overview:welcome-slide3-subtitle')}
                </SlideSubTitle>
                <Box pl={65} pr={65} pt={16}>
                  <SlideDescription>
                    {t('overview:welcome-slide3-text')}
                  </SlideDescription>
                </Box>
                <SlideContent>
                  <img
                    src={getAssetPath('welcome-image-slide3.png')}
                    style={{
                      paddingTop: '7px',
                    }}
                    alt=""
                  />
                  <Button
                    variant={'primary'}
                    size={'xl'}
                    mb={40}
                    mt={60}
                    onClick={() => {
                      setHideIntro(true);
                      _push(MY_DESIGNS_PATH);
                    }}
                  >
                    {t('overview:welcome-slide3-design-button')}
                  </Button>
                </SlideContent>
              </SlideContainer>
            </Slide>
          </Carousel>
        </CarouselContainer>
      </CardHolder>
    </FullScreenBox>
  ) : (
    <></>
  );
};
