import { generateDataTypes, generateDataActions } from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_AUTH_';

export const types = {
  LOGIN: generateDataTypes(BASE_DUCK_PREFIX + 'LOGIN'),
  USER: generateDataTypes(BASE_DUCK_PREFIX + 'USER'),
  CUSTOMER: generateDataTypes(BASE_DUCK_PREFIX + 'CUSTOMER'),
  UPDATE_USER: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_USER'),
  UPDATE_FIRSTNAME: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_FIRSTNAME'),
  UPDATE_LASTNAME: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_LASTNAME'),
  UPDATE_EMAIL: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_EMAIL'),
  UPDATE_CUSTOMER: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE_CUSTOMER'),
  EMAIL_TOKEN: generateDataTypes(BASE_DUCK_PREFIX + 'EMAIL_TOKEN'),
  DELETE_ACCOUNT: generateDataTypes(BASE_DUCK_PREFIX + 'DELETE_ACCOUNT'),
  LOGOUT: BASE_DUCK_PREFIX + 'LOGOUT',
  INITIAL_STATUS: {
    IS_LOADING: BASE_DUCK_PREFIX + 'INITIAL_STATUS_IS_LOADING',
  },
  LOAD_FEATURES: generateDataTypes(BASE_DUCK_PREFIX + 'LOAD_FEATURES'),
};

export const actions = {
  LOGIN: generateDataActions(types.LOGIN),
  USER: generateDataActions(types.USER),
  CUSTOMER: generateDataActions(types.CUSTOMER),
  UPDATE_USER: generateDataActions(types.UPDATE_USER),
  UPDATE_FIRSTNAME: generateDataActions(types.UPDATE_FIRSTNAME),
  UPDATE_LASTNAME: generateDataActions(types.UPDATE_LASTNAME),
  UPDATE_EMAIL: generateDataActions(types.UPDATE_EMAIL),
  UPDATE_CUSTOMER: generateDataActions(types.UPDATE_CUSTOMER),
  EMAIL_TOKEN: generateDataActions(types.EMAIL_TOKEN),
  DELETE_ACCOUNT: generateDataActions(types.DELETE_ACCOUNT),
  LOGOUT: () => ({ type: types.LOGOUT }),
  INITIAL_STATUS: {
    IS_LOADING: (payload: boolean) => ({
      type: types.INITIAL_STATUS.IS_LOADING,
      payload,
    }),
  },
  LOAD_FEATURES: generateDataActions(types.LOAD_FEATURES),
};
