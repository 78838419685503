import React from 'react';
import RadioGroupContext from './RadioGroupContext';

export type RadioGroupProps = {
  /**
   * Name of RadioGroup
   */
  name: string;
  /**
   * Controlled Value
   */
  value: any;
  /**
   * Function Control
   */
  onChange: (value: any) => void;
  children?: React.ReactNode;
};

/**
 * RadioGroup
 * Groups multiple RadioButtons with a single control
 */
const RadioGroup = ({ name, value, onChange, children }: RadioGroupProps) => {
  return (
    <RadioGroupContext.Provider value={{ name, value, onChange }}>
      {children}
    </RadioGroupContext.Provider>
  );
};
export default RadioGroup;
