// eslint-disable-next-line
import { i18n } from 'i18next';
import { Preferences } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { DEFAULT_LANGUAGE_CODE } from '../i18n';

const LANGUAGE_CODE_PREFERENCE_LANGUAGE_MAP = {
  en: Preferences.Language.ENGLISH,
  pt: Preferences.Language.PORTUGUESE,
  es: Preferences.Language.SPANISH,
  de: Preferences.Language.GERMAN,
  it: Preferences.Language.ITALIAN,
  fr: Preferences.Language.FRENCH,
  nl: Preferences.Language.DUTCH,
  'fr-CA': Preferences.Language.FRENCH_CANADIAN,
  sv: Preferences.Language.SWEDISH,
  nb: Preferences.Language.NORWEGIAN,
  da: Preferences.Language.DANISH,
  pl: Preferences.Language.POLISH,
  cs: Preferences.Language.CZECH,
};

export const DEFAULT_PREFERENCES_LANGUAGE =
  LANGUAGE_CODE_PREFERENCE_LANGUAGE_MAP[DEFAULT_LANGUAGE_CODE];

export const getPreferencesLanguage = (i18n: i18n) => {
  const languageCodes = Object.keys(LANGUAGE_CODE_PREFERENCE_LANGUAGE_MAP);

  let language = i18n.language;
  if (!languageCodes.includes(language) && language.includes('-')) {
    language = language.substr(0, language.indexOf('-'));
  }

  return LANGUAGE_CODE_PREFERENCE_LANGUAGE_MAP[
    languageCodes.includes(language) ? language : DEFAULT_LANGUAGE_CODE
  ];
};
