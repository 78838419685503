import { PortalMessageType } from '../components/lda/PortalMessageType';
import { validate as uuidValidate } from 'uuid';
import { getBackendBaseUrl } from './config';

export type LdaPostMessage = {
  isSohoPortal: boolean;
  messageType: PortalMessageType;
  uuid: string;
  data?: any;
};

export const getLdaPostMessageTargetOrigin = () => getBackendBaseUrl();

export const isLdaPostMessageValid = (
  event: MessageEvent<LdaPostMessage>,
): boolean => {
  // Ignore messages from unsecure origins
  if (event.origin !== getLdaPostMessageTargetOrigin()) {
    return false;
  }

  // Check for all properties
  if (
    event.data === undefined ||
    event.data.isSohoPortal === undefined ||
    event.data.messageType === undefined ||
    event.data.uuid === undefined
  ) {
    return false;
  }

  const data = event.data;

  // Check for correct messageType
  if (!Object.values<string>(PortalMessageType).includes(data.messageType)) {
    return false;
  }

  // Check for valid UUID
  return uuidValidate(data.uuid);
};
