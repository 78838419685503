import React from 'react';
import { Route, Switch } from 'react-router';
import { Account } from './Account';
import { Printers } from './Printers';
import { ContentOuterWrap, ContentWrap } from 'components/common/layout/Layout';

const Settings = () => {
  return (
    <ContentOuterWrap>
      <ContentWrap>
        <Switch>
          <Route path="/user-settings" component={Account} />
          <Route path="/printer-settings/:printerId?" component={Printers} />
        </Switch>
      </ContentWrap>
    </ContentOuterWrap>
  );
};

export default Settings;
