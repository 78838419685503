import styled from 'styled-components';
import Box from 'components/common/box/Box';
import Text from 'components/common/typography/Typography';
import Icon from 'components/lib/icon/Icon';

import { device } from 'utils/mediaQueries';

export const NotificationWrap = styled(Box).attrs({
  pr: 11,
  pt: 10,
  pb: 10,
  pl: 11,
})`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.neutral.lightest};
  width: 642px;
  min-height: 125px;
  box-shadow: ${({ theme }) => theme.elevations.one};
  background-color: ${({ theme }) => theme.withOpacity(theme.white, 0.9)};

  @media ${device.mobileToTablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const Message = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.4px;
`;

export const SubTitle = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

export const FooterText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))`
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 16,
}))``;

export const CloseButton = styled(Box)`
  position: absolute;
  border-radius: 50%;
  right: 16px;
  top: 14%;
  padding: 0;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: none;

  @media ${device.mobile} {
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
    transform: none;

    svg {
      vertical-align: unset !important;
      width: 16px;
      height: 16px;
    }
  }
`;

export const TextHolder = styled(Box).attrs({ ml: 12 })`
  width: 80%;
  display: flex;
  justify-content: flex-start;
  text-align: left;

  @media ${device.mobile} {
    width: 80%;
  }
`;

export const StyledImage = styled.img`
  height: 36px;
`;
