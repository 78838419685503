import validate from 'validate.js';

const validatorsFor = (constraints: any, errorMessage: any) => ({
  //all: fields => validate(fields, constraints),
  single: (name: string, value: any) => {
    const fieldsSingle = { [name]: value.trim() };
    const constraintsSingle = { [name]: constraints[name] };
    const result = validate(fieldsSingle, constraintsSingle, errorMessage);
    return result ? result[name] : result;
  },
});

export default validatorsFor;
