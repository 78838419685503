import {
  BaseAction,
  createReducer,
  generateFetchDataReducer,
  generatePostDataReducer,
  generateSelectDataReducer,
} from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';
import {
  FETCH_DATA_INITIAL_STATE,
  POST_DATA_INITIAL_STATE,
  SELECTED_DATA_INITIAL_STATE,
} from 'ducks/utils';

const updatePrinter = (state: any, action: any) => ({
  ...state,
  data: state?.data?.map((printer: any) =>
    printer.uniqueId === action.payload.uniqueId
      ? {
          ...action.payload,
        }
      : printer,
  ),
});

const deletePrinter = (state: any, action: any) => {
  return {
    ...state,
    data: state?.data?.filter(
      (printer: any) => printer.uniqueId !== action.payload.uniqueId,
    ),
  };
};

// hadReturn indicates whether there was a request to fetch the printers
// and that the API had retured a response.
// This will help with some loading state issues
const FETCH_PRINTERS_INITIAL_STATE = {
  ...FETCH_DATA_INITIAL_STATE,
  hadReturn: false,
};

const all = createReducer(FETCH_PRINTERS_INITIAL_STATE, {
  ...{
    ...generateFetchDataReducer(types.ALL),
    [types.ALL.SUCCESS]: (state: any, action: BaseAction) => {
      const oldPrinters = new Map(
        (state.data || []).map((obj: any) => [obj.uniqueId, obj]),
      );
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.map((printer: any) => {
          //Keep previously added props like "allValues" and "wlan"
          return { ...(oldPrinters.get(printer.uniqueId) || {}), ...printer };
        }),
        hadReturn: true,
      };
    },
    [types.ALL.FAILURE]: (state: any, action: BaseAction) => ({
      ...state,
      loading: false,
      error: action.payload,
      hadReturn: true,
    }),
  },
  [types.UPDATE.SUCCESS]: updatePrinter,
  [types.UPDATE_STATUS.SUCCESS]: updatePrinter,
  [types.REMOVE.SUCCESS]: deletePrinter,
});

const current = createReducer(
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer(types.CURRENT),
);

const selected = createReducer(
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer(types.SELECTED),
);

const update = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE),
);

const remove = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.REMOVE),
);

const cancelAll = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.CANCEL_ALL),
);

const reprint = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.REPRINT),
);

const getUntilNew = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.GET_UNTIL_NEW),
);

const setSettings = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.SET_SETTINGS),
);

const feedOnHeadClose = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.FEED_ON_HEAD_CLOSE),
);

export default combineReducers({
  all,
  current,
  selected,
  update,
  remove,
  cancelAll,
  reprint,
  getUntilNew,
  setSettings,
  feedOnHeadClose,
});
