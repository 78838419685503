import styled from 'styled-components/macro';
import { Text } from 'components';

export const PrinterImageBackground = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.primary.lightest};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const PrinterImage = styled.img`
  width: 38px;
  display: inline-block;
  vertical-align: middle;
`;

export const PrinterName = styled(Text).attrs(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'normal',
  letterSpacing: '.4px',
  color: theme.textColors.high,
  width: '75%',
}))``;
