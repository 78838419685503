import { put, select, takeLatest } from 'redux-saga/effects';
import {
  actions as searchActions,
  types as searchTypes,
  SearchZebraTemplatesRequest,
} from 'ducks/search/actions';
import {
  getDataSourcesSuggestions,
  getTemplateSuggestions,
} from 'services/api';
import { getFilteredCategories } from 'ducks/categories/selectors';
import tokenStorage from 'services/tokenStorage';
import { getExternalFilesFiltered } from 'services/internalDb';
import { getTenantId } from './templates';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates/index';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { TemplateCategory } from '../utils/categories';
import { PayloadAction } from '../ducks/utils';
import { apiCall } from './utils';

function* handleTemplateSuggestionsRequest(action: PayloadAction<string>) {
  const value = action.payload;
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const suggestions: TemplatePage = yield apiCall(
      getTemplateSuggestions,
      customer?.id,
      workspaceId,
      value,
      token,
    );
    yield put(searchActions.TEMPLATES.success(suggestions));
  } catch (err: any) {
    yield put(searchActions.TEMPLATES.failure({ error: err }));
  }
}

function* handleZebraTemplateSuggestionsRequest({
  payload,
}: PayloadAction<SearchZebraTemplatesRequest>) {
  const { value, category } = payload;

  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = TemplateCategory.Internal;
    const { fileDataList }: TemplatePage = yield apiCall(
      getTemplateSuggestions,
      customer?.id,
      workspaceId,
      value,
      token,
      category,
    );

    const filteredFileDataList: TemplateType[] = fileDataList.filter(
      (t: TemplateType) => !t.name.includes('test'),
    );

    const templatePage: TemplatePage = {
      fileDataList: filteredFileDataList,
      totalCount: filteredFileDataList.length,
    };

    yield put(searchActions.ZEBRA_TEMPLATES.success(templatePage));
  } catch (err: any) {
    yield put(searchActions.ZEBRA_TEMPLATES.failure({ error: err }));
  }
}

function* handleCategoriesSuggestionsRequest(action: any) {
  const value = action.payload;
  try {
    const allCategories = yield select(getFilteredCategories);

    yield put(
      searchActions.CATEGORIES.success(
        allCategories.filter((c) =>
          c.name.toLowerCase().includes(value.toLowerCase()),
        ),
      ),
    );
  } catch (err: any) {
    yield put(searchActions.CATEGORIES.failure({ error: err }));
  }
}

function* handleDataSourcesSuggestionsRequest(action: any) {
  console.debug(
    '%c Template Saga ',
    'color:white;background:black',
    'handleDataSourcesSuggestionsRequest',
  );
  const value = action.payload;
  try {
    const { token } = tokenStorage.get();
    const customer = yield selectAwaitCustomer();
    const workspaceId = yield getTenantId();
    const suggestions = yield apiCall(
      getDataSourcesSuggestions,
      customer?.id,
      workspaceId,
      value,
      token,
    );
    const externalFiles = yield apiCall(
      getExternalFilesFiltered,
      value,
      customer?.id,
      workspaceId,
    );
    yield put(
      searchActions.DATA_SOURCES.success([...suggestions, ...externalFiles]),
    );
  } catch (err: any) {
    yield put(searchActions.DATA_SOURCES.failure({ error: err }));
  }
}

export default function* watcher() {
  yield takeLatest(
    searchTypes.TEMPLATES.REQUEST,
    handleTemplateSuggestionsRequest,
  );
  yield takeLatest(
    searchTypes.ZEBRA_TEMPLATES.REQUEST,
    handleZebraTemplateSuggestionsRequest,
  );
  yield takeLatest(
    searchTypes.CATEGORIES.REQUEST,
    handleCategoriesSuggestionsRequest,
  );
  yield takeLatest(
    searchTypes.DATA_SOURCES.REQUEST,
    handleDataSourcesSuggestionsRequest,
  );
}
