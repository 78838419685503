import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

// Define a type for the slice state
interface VisibilityState {
  visible: boolean;
}

const initialState: VisibilityState = {
  visible: false,
};

const NAMESPACE = 'SMB_PAGE_VISIBILITY';

export const PageVisibilitySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    visible: (state) => ({ ...state, visible: true }),
    hidden: (state) => ({ ...state, visible: false }),
  },
});

export const { visible, hidden } = PageVisibilitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVisibility = (state: RootState) => state.pageVisibility;

export default PageVisibilitySlice.reducer;
