import React, { useRef, useCallback, useEffect } from 'react';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getFileDialogStatus } from 'ducks/fileDialog/selectors';
import { actions as FileDialogActions } from 'ducks/fileDialog/actions';
import GlobalModalDialog from 'pages/dataSources/components/ModalDialog/GlobalModalDialog';
import { useModal } from 'hooks';

const SMBFileDialog = () => {
  const dialogStatus = useReduxSelector(getFileDialogStatus);
  const closeDialog = useReduxAction(FileDialogActions.CLOSE);

  const largeModalRef = useRef(null);
  const modal = useModal(largeModalRef);

  const handleClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  useEffect(() => {
    if (dialogStatus.open) modal.open();
  }, [dialogStatus.open, modal]);

  return dialogStatus ? (
    <GlobalModalDialog
      ref={largeModalRef}
      // network={dialogStatus.network}
      fileType={dialogStatus.fileType}
      onClose={handleClose}
      open={dialogStatus.open}
    />
  ) : null;
};

export default SMBFileDialog;
