import React, {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useContext,
  ChangeEvent,
} from 'react';
import { useAppDispatch, useReduxAction, useReduxSelector } from 'hooks';
import { Modal, Text, Input, Flex, Button, Box } from 'components';
import { actions as workspaceActions } from 'ducks/workspaces/actions';
import styled from 'styled-components/macro';
import { supportedImageTypes } from 'services/api';
import { ThemeContext } from 'styled-components/macro';

import {
  getCurrentWorkspace,
  isUpdateSuccess,
  isUpdating,
} from 'ducks/workspaces/selectors';
import { useTranslation } from 'react-i18next';
import { handleUploadedImage } from 'utils/imageCompress';
import getTextAbbreviation from 'utils/getTextAbbreviation';
import { openErrorToast } from 'state/Toast';

const Section = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[11]}px;
`;

const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14x',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.med,
  marginBottom: '5px',
}))``;

const PreviewWrapper = styled.div.attrs(({ theme }) => ({}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-radius: 8px;
  margin-bottom: 16px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.primary.lightest};
`;

const SideText = styled.p`
  color: ${({ theme }) => theme.textColors.med};
  margin-top: 30px;
`;

const UploadInput = styled.input.attrs({
  type: 'file',
  hidden: true,
})``;

const StyledImg = styled.img`
  border-radius: 8px;
  height: 80px;
  width: 80px;
  object-fit: cover;
`;

const EditWorkspaceModal = forwardRef(
  ({ isOpen, setIsOpen }: any, ref: any) => {
    const dispatch = useAppDispatch();

    const workspace: any = useReduxSelector(getCurrentWorkspace);
    const InputUploadRef = useRef<any>(null);
    const theme = useContext(ThemeContext);
    const { t } = useTranslation(['overview', 'common', 'settings']);
    const NOT_ALLOWED_CHARACTERS = '/[^%&*?+[\\]:/<>#]*';

    const updateSuccess = useReduxSelector(isUpdateSuccess);
    const updateLoading = useReduxSelector(isUpdating);
    const [workspaceName, setWorkspaceName] = useState(workspace.name);
    const [workspaceAvatar, setWorkspaceAvatar] = useState(workspace.avatar);
    const [url, setUrl] = useState<null | string>();
    const updatedWorkspace = workspace;

    const updatedWorkspaceFunc = useReduxAction(
      workspaceActions.UPDATE.request,
    );

    useEffect(() => {
      if (updateSuccess) {
        ref.current.close();
      }
    }, [updateSuccess, ref]);

    useEffect(() => {
      if (workspace?.name !== workspaceName) {
        setWorkspaceName(workspace.name);
      }
      if (workspace?.avatar !== workspaceAvatar) {
        setUrl(
          workspace?.avatar && workspace?.avatar !== 'null'
            ? `data:image/jpeg;base64, ` + workspace?.avatar
            : undefined,
        );
        setWorkspaceAvatar(workspace.avatar);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspace]);

    const handleChange = () => {
      updatedWorkspace.name = workspaceName;
      setWorkspaceAvatar(updatedWorkspace.avatar);
      updatedWorkspaceFunc(updatedWorkspace);
    };

    const removeImage = () => {
      setUrl(undefined);
      updatedWorkspace.avatar = 'null';
    };

    const onClose = () => {
      if (
        workspaceAvatar &&
        workspaceAvatar !== 'null' &&
        workspaceAvatar !== ''
      ) {
        setUrl(`data:image/jpeg;base64, ` + workspaceAvatar);
        updatedWorkspace.avatar = workspaceAvatar;
      } else if (workspaceAvatar === 'null') {
        setUrl(undefined);
        setWorkspaceAvatar('null');
        updatedWorkspace.avatar = 'null';
      } else {
        setUrl(undefined);
        setWorkspaceAvatar(workspace?.avatar);
      }
      setWorkspaceName(workspace.name);
    };

    const handleUploadInputChange = async (
      e: ChangeEvent<HTMLInputElement>,
    ) => {
      const fileObject = e.target.files && e.target.files[0];
      const options = {
        maxWidthOrHeight: 120,
        useWebWorker: true,
      };

      if (!fileObject) return;

      try {
        updatedWorkspace.avatar = await handleUploadedImage(
          fileObject,
          options,
        );

        setUrl(`data:image/jpeg;base64, ` + updatedWorkspace.avatar);
      } catch (error: any) {
        console.error(error);

        const message = error.message;

        if (!message) {
          dispatch(
            openErrorToast({
              title: t('common:errors.corrupted-file'),
              dismissTime: 0,
            }),
          );

        }
        else {
          dispatch(
            openErrorToast({
              title: message,
            }),
          );
        }
      } finally {
        InputUploadRef.current.value = null;
      }
    };

    const hasError = (): string | null => {
      if (workspaceName === null || workspaceName === undefined) {
        return null;
      } else if (workspaceName.length === 0) {
        return t('settings:workspace-name-length-at-least-one-character');
      } else if (workspaceName.length >= 256) {
        return t('settings:workspace-name-can-not-exceed-length');
      } else if (
        workspaceName.match(NOT_ALLOWED_CHARACTERS) ||
        workspaceName.match(/^\s+|\s+$/g)
      ) {
        return t('settings:workspace-name-characters-not-allowed');
      }

      return null;
    };

    const handleOnChangeWorkspaceName = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setWorkspaceName(value);
    };

    return (
      <Modal
        data-testid="edit-workspace-modal"
        clickOutside={true}
        title={t('overview:workspace.edit')}
        ref={ref}
        defaultPadding={false}
        onClose={onClose}
        footer={
          <Flex mt={10} justifyContent={'flex-end'}>
            <Button
              data-testid="edit-workspace-cancel-button"
              onClick={() => ref.current.close()}
              variant={'text-primary'}
            >
              {t('common:cancel')}
            </Button>
            <Button
              data-testid="edit-workspace-save-button"
              ml={theme.spacing.buttonGap}
              disabled={updateLoading || hasError() !== null}
              variant={'primary'}
              onClick={handleChange}
            >
              {updateLoading ? t('common:saving') : t('common:save')}
            </Button>
          </Flex>
        }
      >
        <Flex
          width={416}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          <Flex flexDirection={'column'} px="40px">
            <Section>
              <SectionTitle>{t('overview:workspace.avatar')}</SectionTitle>
              <Flex width={'90%'} flexDirection={'row'}>
                <PreviewWrapper>
                  {url ? (
                    <StyledImg
                      data-testid="edit-workspace-avatar-image"
                      alt={t('overview:workspace.avatar')}
                      title={t('overview:workspace.avatar')}
                      src={url}
                    />
                  ) : (
                    <Text
                      data-testid="edit-workspace-name-initials"
                      fontSize={28}
                      textAlign={'center'}
                      color={theme.primary.base}
                      fontWeight={300}
                      lineHeight={'36px'}
                      letterSpacing={0.4}
                      width={'80px'}
                      overflow={'hidden'}
                      padding={'4px'}
                    >
                      {getTextAbbreviation(workspaceName ?? '')}
                    </Text>
                  )}
                </PreviewWrapper>
                <SideText data-testid="edit-workspace-side-text">
                  {!url && t('overview:workspace.no-image-uploaded')}
                </SideText>
              </Flex>
              <Flex flexDirection="row" mr={8}>
                <UploadInput
                  data-testid="edit-workspace-upload-photo-input"
                  ref={InputUploadRef}
                  onChange={handleUploadInputChange}
                  accept={supportedImageTypes.toString()}
                />
                <Button
                  data-testid="edit-workspace-upload-photo-button"
                  variant={'outline'}
                  onClick={() => InputUploadRef?.current?.click()}
                >
                  {t('common:upload-photo')}
                </Button>
                {url && (
                  <>
                    <Box width={theme.spacing.buttonGap} />
                    <Button
                      data-testid="edit-workspace-remove-image-button"
                      variant={'text-destructive'}
                      onClick={removeImage}
                    >
                      {t('common:remove-image')}
                    </Button>
                  </>
                )}
              </Flex>
            </Section>
            <Box mb={34}>
              <SectionTitle>{t('overview:workspace.name')}</SectionTitle>

              <Input
                data-testid="input-workspace-name"
                value={workspaceName}
                hasError={hasError() !== null}
                message={hasError()}
                onChange={handleOnChangeWorkspaceName}
              />
            </Box>
          </Flex>
        </Flex>
      </Modal>
    );
  },
);

export default EditWorkspaceModal;
