import * as React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components/macro';
import { Flex } from 'components';
import { Support } from './Support';
import { Header } from './Header';
import { ContentWrapWideScreen } from 'components/common/layout/Layout';

const ContentWrap = styled(Flex).attrs({
  mt: 300,
  flexDirection: 'column',
})`
  flex: 1;
`;

const Help = () => {
  return (
    <ContentWrapWideScreen>
      <Flex>
        <ContentWrap>
          <Header />
          <Switch>
            <Route path="/help" component={Support} />
            {/* <Route path="/help" component={Contact} /> */}
          </Switch>
        </ContentWrap>
      </Flex>
    </ContentWrapWideScreen>
  );
};

export default Help;
