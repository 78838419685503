import { OFFICE_365_CREDENTIALS } from 'utils/config';
import {
  AccountInfo,
  BrowserAuthErrorMessage,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { supportedDataTypes } from './api';
import i18n from 'i18next';
import { IMsalContext } from '@azure/msal-react';
import { buildEmptyStringArray } from '../utils/dataManipulationMethods';

export const MS_CONTACTS_LABEL = 'Office365 Contacts';
export const MS_PROVIDER_LABEL = 'OneDrive';
export const MS_PROVIDER_LABEL_OLD = 'Office';

const DBG = ['%c OFFICE ', 'background:hotpink;font-family:"Comic Sans"'];

class ContactField {
  private readonly _header: string;
  private readonly _parse: (contact) => any;

  constructor(header: string, parse: (contact) => any) {
    this._header = header;
    this._parse = parse;
  }

  get header() {
    return this._header;
  }

  get parse() {
    return this._parse;
  }
}

export const CONTACT_FIELDS: ContactField[] = [
  new ContactField('Display Name', (contact) => contact.displayName),
  new ContactField('Title', (contact) => contact.title),
  new ContactField('Given Name', (contact) => contact.givenName),
  new ContactField('Initials', (contact) => contact.initials),
  new ContactField('Middle Name', (contact) => contact.middleName),
  new ContactField('Surname', (contact) => contact.surname),
  new ContactField('Nick Name', (contact) => contact.nickName),
  new ContactField(
    'Email Address',
    (contact) => contact.emailAddresses[0]?.address,
  ),
  new ContactField('Job Title', (contact) => contact.jobTitle),
  new ContactField('Company Name', (contact) => contact.companyName),
  new ContactField('Department', (contact) => contact.department),
  new ContactField('Office Location', (contact) => contact.officeLocation),
  new ContactField('Manager', (contact) => contact.manager),
  new ContactField('Home Phone', (contact) => contact.homePhones[0]),
  new ContactField('Mobile Phone', (contact) => contact.mobilePhone),
  new ContactField('Business Phone', (contact) => contact.businessPhones[0]),
  new ContactField('Personal Notes', (contact) => contact.personalNotes),
  new ContactField('Birthday', (contact) => contact.birthday),
  new ContactField(
    'Home Address - Street',
    (contact) => contact.homeAddress['street'],
  ),
  new ContactField(
    'Home Address - City',
    (contact) => contact.homeAddress['city'],
  ),
  new ContactField(
    'Home Address - Country/Region',
    (contact) => contact.homeAddress['countryOrRegion'],
  ),
  new ContactField(
    'Home Address - Postal Code',
    (contact) => contact.homeAddress['postalCode'],
  ),
  new ContactField(
    'Home Address - State',
    (contact) => contact.homeAddress['state'],
  ),
  new ContactField(
    'Business Address - Street',
    (contact) => contact.businessAddress['street'],
  ),
  new ContactField(
    'Business Address - City',
    (contact) => contact.businessAddress['city'],
  ),
  new ContactField(
    'Business Address - Country/Region',
    (contact) => contact.businessAddress['countryOrRegion'],
  ),
  new ContactField(
    'Business Address - Postal Code',
    (contact) => contact.businessAddress['postalCode'],
  ),
  new ContactField(
    'Business Address - State',
    (contact) => contact.businessAddress['state'],
  ),
  new ContactField(
    'Other Address - Street',
    (contact) => contact.otherAddress['street'],
  ),
  new ContactField(
    'Other Address - City',
    (contact) => contact.otherAddress['city'],
  ),
  new ContactField(
    'Other Address - Country/Region',
    (contact) => contact.otherAddress['countryOrRegion'],
  ),
  new ContactField(
    'Other Address - Postal Code',
    (contact) => contact.otherAddress['postalCode'],
  ),
  new ContactField(
    'Other Address - State',
    (contact) => contact.otherAddress['state'],
  ),
];

export const MSAL_CONFIG = {
  auth: {
    clientId: OFFICE_365_CREDENTIALS.CLIENT_ID,
    redirectUri: OFFICE_365_CREDENTIALS.REDIRECT_URI,
    postLogoutRedirectUri: `${OFFICE_365_CREDENTIALS.REDIRECT_URI}settings`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

declare global {
  interface Window {
    microsoftApp: any;
    OneDrive: {
      open(obj: any): void;
    };
  }
}

type IdTokenClaims = {
  preferred_username: string;
};

const getBase64File = async (url): Promise<any> => {
  return new Promise((resolve, reject) => {
    console.debug(...DBG, 'getBase64File');
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      console.debug(...DBG, 'getBase64File.onLoad');
      reader.onloadend = function () {
        console.debug(...DBG, 'getBase64File.onLoadEnd');
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
      console.debug(...DBG, 'getBase64File.onLoad-');
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

const getPreferredUsername = (account: AccountInfo): string => {
  return (account.idTokenClaims as IdTokenClaims)?.preferred_username;
};

const getClient = (
  msalInstance: PublicClientApplication,
  account: AccountInfo,
): Client => {
  return Client.initWithMiddleware({
    authProvider: new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
      account,
      interactionType: InteractionType.Popup, // msal-browser InteractionType
      scopes: OFFICE_365_CREDENTIALS.SCOPES, // example of the scopes to be passed
    }),
  });
};

export const showOneDriveFilePicker = async (account: AccountInfo) => {
  return new Promise((resolve, reject) => {
    const odOptions = {
      clientId: OFFICE_365_CREDENTIALS.CLIENT_ID,
      action: 'query',
      multiSelect: false,
      viewType: 'files',
      accountSwitchEnabled: false,
      advanced: {
        accessToken: account.username,
        scopes: OFFICE_365_CREDENTIALS.SCOPES,
        queryParameters:
          'select=id,name,size,file,folder,@microsoft.graph.downloadUrl,photo',
        filter: supportedDataTypes.join(',').toLowerCase(), //TODO
        // endpointHint: 'api.onedrive.com',
        redirectUri: OFFICE_365_CREDENTIALS.REDIRECT_URI,
        loginHint: getPreferredUsername(account),
      },
      success: (files) => {
        resolve(files);
      },
      cancel: () => {
        resolve(null);
      },
      error: (error) => {
        reject(error);
      },
    };

    window.OneDrive.open(odOptions);
  });
};

export const getLoggedInOneDriveAccount = async (
  context: IMsalContext,
  isAuthenticated: boolean,
): Promise<AccountInfo | null> => {
  const { instance, accounts, inProgress } = context;
  if (isAuthenticated && accounts.length > 0) {
    return accounts[0];
  } else if (inProgress !== 'login') {
    try {
      const { account } = await instance.loginPopup({
        scopes: OFFICE_365_CREDENTIALS.SCOPES,
        prompt: 'select_account',
      });
      if (account) {
        return account;
      }
    } catch (e: any) {
      if (e.errorCode !== BrowserAuthErrorMessage.userCancelledError.code) {
        console.log(e);
        throw new Error(e.errorMessage);
      }
    }
  }
  return null;
};

export const getOneDriveFile = async (
  context: IMsalContext,
  isAuthenticated: boolean,
  file: any,
): Promise<string> => {
  const account = await getLoggedInOneDriveAccount(context, isAuthenticated);
  if (!account) {
    throw new Error(i18n.t('common:errors:account-not-found'));
  }

  const fileMetaData = await getClient(
    context.instance as PublicClientApplication,
    account,
  )
    .api(`/me/drive/items/${file.id}`)
    .get(); //TODO try catch for when the file is deleted.
  const url = fileMetaData?.['@microsoft.graph.downloadUrl'];
  const base64File = await getBase64File(url);
  return base64File.split(',')[1];
};

type ContactPaginationOptions = {
  page: number;
  top: number;
  getAll: boolean;
  previousResult?: any[];
};

const fetchPaginatedContacts = async (
  client: Client,
  options: ContactPaginationOptions,
): Promise<any[]> => {
  let { page, top, getAll, previousResult = [] } = options;
  const contacts = await client
    .api(`/me/contacts?$skip=${page}&top=${getAll ? top : 2}`)
    .get();

  const result = [...previousResult, ...contacts.value];
  return getAll && contacts['@odata.nextLink'] != null
    ? fetchPaginatedContacts(client, {
        page: page + top,
        top,
        previousResult: result,
        getAll,
      })
    : result;
};

const buildContactValuesRow = (contact) =>
  CONTACT_FIELDS.map((field) => field.parse(contact) ?? '');

export const getOneDriveContacts = async (
  msalContext: IMsalContext,
  isOneDriveAuthenticated: boolean,
  getAll: boolean = true,
): Promise<any[]> => {
  console.debug(...DBG, 'getContacts');
  let content: any[] = [];

  const account = await getLoggedInOneDriveAccount(
    msalContext,
    isOneDriveAuthenticated,
  );
  if (account) {
    content = await fetchPaginatedContacts(
      getClient(msalContext.instance as PublicClientApplication, account),
      {
        page: 0,
        top: 500,
        getAll,
      },
    );
  }

  const rows =
    content.length > 0
      ? content.map(buildContactValuesRow)
      : [buildEmptyStringArray(CONTACT_FIELDS.length)];

  return [CONTACT_FIELDS.map((field) => field.header)].concat(rows);
};
