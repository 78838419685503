import { createReducer } from 'ducks/utils';
import { ModalDialogTypes, ModalDialogAction, types } from './actions';
import { combineReducers } from 'redux';

export interface ModalDialogState {
  open: boolean;
  confirm: boolean;
  data: any;
}

const DIALOG_INITIAL_STATE: ModalDialogState = {
  open: false,
  confirm: false,
  data: null,
};

export const generateModalDialogReducer = (types: ModalDialogTypes) => ({
  [types.OPEN]: (state: ModalDialogState, action: ModalDialogAction) => ({
    ...state,
    open: true,
    data: action.payload,
  }),
  [types.CONFIRM]: (state: ModalDialogState, action: ModalDialogAction) => ({
    ...state,
    confirm: true,
    //To have the ability to confirm without any data and use existing state data or directly with passing data.
    data: action.payload || state.data,
  }),
  [types.CLOSE]: (state: ModalDialogState, action: ModalDialogAction) => ({
    ...state,
    ...DIALOG_INITIAL_STATE,
  }),
});

const overwriteFile = createReducer(DIALOG_INITIAL_STATE, {
  ...generateModalDialogReducer(types.OVERWRITE_FILE),
});

const outage = createReducer(
  { ...DIALOG_INITIAL_STATE, open: false },
  {
    ...generateModalDialogReducer(types.OUTAGE),
  },
);
export default combineReducers({
  overwriteFile,
  outage,
});
