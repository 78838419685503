import styled, { DefaultTheme } from 'styled-components';
import {
  InnerTextInputFieldWrap,
  TextInputField,
  IconWrap,
} from 'components/lib/input/Input';
import { Box, Icon, Card } from 'components';
import IconType from '../icon/IconType';

export type DropdownOptionsType = {
  label: string;
  value: string;
  logo?: IconType;
};

export type DropdownProps = {
  disabled?: boolean;
  options?: DropdownOptionsType[];
  onChange?: (e: string) => void;
  value?: string;
  showTop?: boolean;
  placeholder?: string;
  name?: string;
  noOutline?: boolean;
  tableCellDesign?: boolean;
  noOptionsValue?: string;
  hasError?: boolean;
};

export const DropdownStatusIcon = styled(Icon).attrs(
  ({ theme, open }: { theme: DefaultTheme; open?: boolean }) => ({
    icon: `chevron-${open ? 'up' : 'down'}`,
    size: 10,
    color: open ? theme.primary.base : theme.neutral.base,
  }),
)<{ open?: boolean }>``;

export const HeaderWrap = styled(Box)<{
  open: boolean;
  noOutline?: boolean;
  tableCellDesign?: boolean;
  disabled?: boolean;
  hasError?:boolean;
}>`
  ${InnerTextInputFieldWrap} {
    padding-left: ${({ theme }) => theme.px(8)};
    cursor: pointer;
    box-shadow: none;
    background-color: ${({ theme, hasError }) => (hasError ? theme.error.lightest :'transparent')};
    //background-color: transparent;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.error.base :theme.neutral.lighter)};

    input {
      border-radius: ${({ theme }) => theme.px(4, 0, 0, 4)};
      border-right: 1px solid ${({ theme }) => theme.neutral.lighter};
      padding: 0px;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
    }
  }
  ${({ noOutline }) =>
    noOutline &&
    `${InnerTextInputFieldWrap} {
      border: none;
      input {
        border-right: none;
      }
    }`}

  ${({ tableCellDesign }) =>
    tableCellDesign &&
    `${InnerTextInputFieldWrap} {
      border: none;
    }`}
  ${TextInputField} {
    cursor: pointer;
    background-color: transparent;
    color: ${({ theme, tableCellDesign }) =>
      tableCellDesign ? 'inherit' : theme.textColors.med};
  }
  ${IconWrap} {
    padding: 0 19px;
  }
  ${({ tableCellDesign, theme }) =>
    !tableCellDesign &&
    `${InnerTextInputFieldWrap}:hover {
    background-color: ${theme.primary.lightest};
  }`}
  ${InnerTextInputFieldWrap}:focus-within {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary.base};
  }

  ${({ open }) =>
    open &&
    `
  ${InnerTextInputFieldWrap} {
    background-color: ${({ theme }) => theme.primary.lightest};
  }
  ${TextInputField} {
    color: ${({ theme }) => theme.primary.base};
  }
  `}
`;

export const MenuDropdown = styled(Card).attrs({ elevation: 'four' })<{
  open?: boolean;
  showTop?: boolean;
  isSearchChild?: boolean;
}>`
  flex-direction: column;
  border: none;
  padding: unset;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.px(4)};
  max-height: 286px;
  overflow-y: auto;
  display: flex;

  visibility: ${({ open }) => (open ? 'visible' : 'hidden')} !important;

  // TO-DO probably dynamic top will be needed, to use as a body for search top is different
  margin-top: ${({ isSearchChild }) => (isSearchChild ? 6 : 3)}px;
  z-index: ${({ open }) => (open ? 101 : 1)};
  left: 0;
  top: 100%;
  position: absolute;
  width: 100%;
  box-sizing: border-box;

  ${({ showTop }) =>
    showTop &&
    `
    top: auto;
    bottom: 51px;
  `}
`;
