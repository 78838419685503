import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Card, Dropdown, Flex, Box } from 'components';
import { useUrlParam } from 'hooks';

export const NoHiddenOverflowCard = styled(Card)`
  overflow: unset;
`;

const options = [
  { value: '1', label: 'Action List Item 1' },
  { value: '2', label: 'Action List Item 2' },
  { value: '3', label: 'Action List Item 3' },
  { value: '4', label: 'Action List Item 4' },
  { value: '5', label: 'Action List Item 5' },
  { value: '6', label: 'Action List Item 6' },
  { value: '7', label: 'Action List Item 7' },
  { value: '8', label: 'Action List Item 8' },
];

const ConnectedDropdown = () => {
  const [value, setValue] = useUrlParam('exampleDropdown', '1');
  return <Dropdown value={value} onChange={setValue} options={options} />;
};

const DropdownsView = (): any => {
  const [value, setValue] = useState('3');
  return (
    <NoHiddenOverflowCard p={10} pb={40} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Dropdowns</h2>
        <Flex flexDirection="row" flexWrap="wrap">
          <Box width={280} mr={10}>
            <Dropdown
              value={value}
              onChange={(e: any) => {
                setValue(e.target.value);
              }}
              options={options}
            />
          </Box>
          <Box width={280} mr={10}>
            <ConnectedDropdown />
          </Box>
        </Flex>
      </Flex>
    </NoHiddenOverflowCard>
  );
};

export default DropdownsView;
