import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Flex from 'components/common/flex/Flex';

import {
  SettingsCardWrap,
  StyledImg,
  ImgWrapper,
  TruncatedText,
} from './SettingsCard.styles';
import Text from '../../components/common/Text/Text';

type CustomSettingsCardProps = {
  title: string;
  description: string;
  icon?: string;
  onClick: MouseEventHandler;
};

const SettingsCard = ({
  title,
  description,
  icon,
  onClick,
}: CustomSettingsCardProps) => {
  const { t } = useTranslation();

  return (
    <SettingsCardWrap data-testid="settings-card" onClick={onClick}>
      <Flex>
        <ImgWrapper>
          {icon && (
            <StyledImg
              height="48"
              src={icon}
              alt={t('settings:printer.printer-avatar')}
            />
          )}
        </ImgWrapper>
        <Flex flexDirection={'column'}>
          <Flex alignItems={'center'}>
            <TruncatedText variant={'h3'}>{title}</TruncatedText>
          </Flex>
          <Text>{description}</Text>
        </Flex>
      </Flex>
    </SettingsCardWrap>
  );
};

export default SettingsCard;
