import * as React from 'react';
import { Card, Flex, Toast, Box, Button } from 'components';
import { useAppDispatch } from 'hooks';
import { open } from 'state/Toast';
const CardsView = (): any => {
  const dispatch = useAppDispatch();
  return (
    <Card p={10} pb={40} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Toasts</h2>
        <Flex flexDirection="column">
          <Box mb={2}>
            <Toast
              variant={'basic'}
              title={'Default toast notification component...'}
            />
          </Box>
          <Box mb={2}>
            <Toast
              variant={'error'}
              title={'Error toast notification component...'}
            />
          </Box>
          <Box mb={2}>
            <Toast
              variant={'success'}
              title={'Success toast notification component...'}
            />
          </Box>
          <Box mb={2}>
            <Toast
              variant={'warning'}
              title={'Warning toast notification component...'}
            />
          </Box>
          <Box mb={2}>
            <Toast
              variant={'info'}
              title={'Info toast notification component...'}
            />
          </Box>
        </Flex>
      </Flex>
      <Button
        onClick={() =>
          dispatch(open({ title: 'Hello this is a Toast', variant: 'success' }))
        }
      >
        {'Open Toast'}
      </Button>
    </Card>
  );
};

export default CardsView;
