import React from 'react';
import styled from 'styled-components';
import { Card } from 'components';
import './css/support.scss';

const CardHolder = styled(Card)`
  position: relative;
  top: 30px;
  left: 47%;
  width: 70%;
  max-width: 1040px;
  min-width: 1014px;
  background-color: ${({ theme }) => theme.white};
  transform: translateX(-50%);
`;

const CardHolderInner = styled(Card)``;

// const GetLocation = () => {
//   const timeZoneOffset = new Date().getTimezoneOffset();
//   if (timeZoneOffset >= -180 && timeZoneOffset <= 60) {
//     return 'Available 9am to 5pm UTC';
//   } else if (timeZoneOffset >= 180 && timeZoneOffset <= 600) {
//     return 'Available 7am to 7pm CT';
//   } else {
//     return 'Chat not available in your location';
//   }
// };

export const Support = (): any => {
  //https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_minimized_events.html
  // const user = useReduxSelector(getUser);
  // const customer: CustomerInfoType = useReduxSelector(getCustomer);
  // const onlineButton = useRef(null);
  // const offlineButton = useRef(null);
  // const [activatedChat, setActivatedChat] = useState(false);
  // const userFirstName = user ? user.firstName : customer.firstName;
  // const userLastName = user ? user.lastName : ''; //Todo when availble in user object
  // const userEmail = customer.email;
  // const userId = user ? user.zuid : customer.id;
  // let userPrintersList = '';
  // const printers = useReduxSelector(getAllPrinters);

  //Pass through printers list to snapin
  // const getPrintersList = () => {
  //   printers &&
  //     printers.forEach((printer, index) => {
  //       if (index == 0) {
  //         userPrintersList = printer.name;
  //       } else {
  //         userPrintersList += `, ${printer.name}`;
  //       }
  //     });
  // };

  // const handleChatEvent = event => {
  //   setActivatedChat(event.detail.isChatActive);
  // };

  // const getLocation = () => {
  //   const timeZoneOffset = new Date().getTimezoneOffset();
  //   if (timeZoneOffset >= -180 && timeZoneOffset <= 60) {
  //     return 'EMEA';
  //   } else if (timeZoneOffset >= 180 && timeZoneOffset <= 600) {
  //     return 'NALA';
  //   } else {
  //     return 'templates';
  //   }
  // };

  // const userCountry = getLocation();

  // useEffect(() => {
  //   SalesForceChat.setup(onlineButton.current, offlineButton.current); //TODO what to do if fail;
  //   getPrintersList();
  //   SalesForceChat.getUserInfo(
  //     userFirstName,
  //     userEmail,
  //     userId,
  //     userLastName,
  //     userCountry,
  //     userPrintersList,
  //   );
  //   window.addEventListener('chatEvent', handleChatEvent);

  //   return () => {
  //     SalesForceChat.teardown();
  //     window.removeEventListener('chatStarted', handleChatEvent);
  //   };
  // }, [SalesForceChat.activateChat, printers]);

  return (
    <>
      <CardHolder flexDirection={'column'} p={56} elevation="one">
        <CardHolderInner mt={20}>
          {/* <div ref={onlineButton} style={{ display: 'none' }}>
            <Text style={{ textAlign: 'center' }}>{GetLocation()}</Text>
            <Flex flexDirection="row" justifyContent="center" mt={32}>
              <Button
                variant={'primary'}
                size={'xl'}
                onClick={SalesForceChat.activateChat}
                style={{ minWidth: '208px' }}
                disabled={activatedChat}
              >
                Begin Chat
              </Button>
            </Flex>
          </div>
          <div ref={offlineButton} style={{ display: 'none' }}>
            <Text style={{ textAlign: 'center' }}>
              There are no agents online, submit contact us instead...
            </Text>
            <Flex flexDirection="row" justifyContent="center" mt={2}>
              <Link target="_blank" href="https://zsbsupport.zebra.com/s/"  rel="noopener">
                Salesforce Community Contact
              </Link>
            </Flex>

            <Flex flexDirection="row" justifyContent="center" mt={32}>
              <Button
                variant={'primary'}
                size={'xl'}
                onClick={SalesForceChat.activateChat}
                style={{ minWidth: '208px' }}
                disabled={true}
              >
                Begin Chat
              </Button>
            </Flex>
          </div> */}
        </CardHolderInner>
      </CardHolder>
    </>
  );
};
