import React, { forwardRef, useEffect } from 'react';
import { Flex, Button, Modal, Box, Text } from 'components';
import GoogleDrive from './GoogleDrive';
import OneDriveLoginButton from './OneDriveLoginButton';
import { useReduxSelector, useReduxAction } from 'hooks';
import { useTranslation } from 'react-i18next';

import {
  isAddingFileSuccess,
  isAddingFileError,
} from 'ducks/dataSources/selectors';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';

const CloudModal = forwardRef(({ ...props }: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);

  const errorAddingFile = useReduxSelector(isAddingFileError);
  const successAddingFile = useReduxSelector(isAddingFileSuccess);
  const clearState = useReduxAction(dataSourcesActions.ADD.clear);

  useEffect(() => {
    if (errorAddingFile || successAddingFile) {
      clearState();
      ref.current.close();
    }
  }, [clearState, errorAddingFile, ref, successAddingFile]);

  return (
    <Modal
      ref={ref}
      title={t('dataSources:cloud-service-sign-in')}
      defaultPadding={false}
      footer={
        <Flex justifyContent={'flex-end'}>
          <Button
            variant={'text-primary'}
            onClick={() => {
              ref.current.close();
            }}
          >
            {t('common:cancel')}
          </Button>
        </Flex>
      }
      clickOutside={true}
      // onClose={onClose}
    >
      <Flex width={'512px'} flexDirection={'row'}>
        <Flex
          width={'100%'}
          justifySelf={'start'}
          justifyItems={'start'}
          height={'100%'}
          flexDirection={'column'}
        >
          <Box pl={40} pr={40} mt={7}>
            <Text lineHeight={'24px'}>
              {t('dataSources:signing-into-cloud-services')}
            </Text>
          </Box>
          <Box height={'60px'} mt={8}>
            <Flex flexDirection={'row'} justifyContent={'center'} data-testid="google-drive-login-button">
              <GoogleDrive ref={ref.current} />
            </Flex>
          </Box>
          <Box height={'60px'}>
            <Flex flexDirection={'row'} justifyContent={'center'} data-testid="one-drive-login-button">
              <OneDriveLoginButton />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Modal>
  );
});

export default CloudModal;
