import * as React from 'react';
import { Card, Flex, Box, Banner } from 'components';

const BannersView = (): any => {
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Banners</h2>
        <Flex flexDirection="column">
          <Box width={640} m={1}>
            <Banner
              variant={'basic'}
              message={'Basic banner message'}
              subtitle={'Subtitle'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'error'}
              message={'Error banner message'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'error'}
              message={'Error banner message, no close, no subtitle'}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'success'}
              message={'Success banner message'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'warning'}
              message={'Warning banner message'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'info'}
              message={'Info banner message'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
          <Box width={640} m={1}>
            <Banner
              variant={'info'}
              message={'Lorem ipsum'}
              subtitle={'Lorem ipsum'}
              onClose={() => console.log('Close clicked')}
            />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default BannersView;
