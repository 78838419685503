import styled from 'styled-components/macro';

const LinkButton = styled.a`
  display: inline-block;
  height: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 0.4px;
  border: none;
  white-space: nowrap;
  outline: none;
  align-items: center;
  cursor: pointer;
  font-weight: 500;

  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary.base};
  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.primary.dark};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary.lighter};
  }
  &:focus,
  &:not:disabled {
    background-color: ${({ theme }) => theme.primary.dark};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary.base};
  }
`;

export default LinkButton;
