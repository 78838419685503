import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  ChangeEvent,
} from 'react';
import styled from 'styled-components/macro';
import { Button, Flex, Text, Box, Loader } from 'components';
import { useAppDispatch, useReduxAction, useReduxSelector } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import {
  getCustomer,
  getUpdateCustomerLoading,
  getUser,
} from 'ducks/auth/selectors';
import { supportedImageTypes } from 'services/api';
import { CustomerInfoType } from 'zsbpsdk/src/customer';
import { ThemeContext } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { handleUploadedImage } from 'utils/imageCompress';
import { openBasicToast, openErrorToast } from 'state/Toast';

import { buildUserInitials } from '../../../utils/userSettings';

const ImageContainer = styled(Flex).attrs(({ theme }) => ({
  mr: 8,
}))`
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 25%;
  overflow: hidden;
  border: 1px solid #c9cdd4;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  object-fit: cover;
`;

const UploadInput = styled.input.attrs({
  type: 'file',
  hidden: true,
})``;

const Subtitle = styled(Text).attrs(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: theme.textColors.med,
}))``;

export const AvatarForm = () => {
  const dispatch = useAppDispatch();

  const InputUploadRef = useRef<any>(null);
  const [url, setUrl] = useState<null | string>(null);
  const updateCustomer = useReduxAction(authActions.UPDATE_CUSTOMER.request);
  const updateCustomerLoading = useReduxSelector(getUpdateCustomerLoading);
  const user = useReduxSelector(getUser);
  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const theme = useContext(ThemeContext);

  const firstName = user?.firstName ?? customer?.firstName;

  const { t } = useTranslation(['settings', 'common']);

  const handleUploadInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileObject = e.target.files && e.target.files[0];
    const options = {
      maxWidthOrHeight: 120,
      useWebWorker: true,
    };

    if (!fileObject) return;

    try {
      const avatar = await handleUploadedImage(fileObject, options);
      const newCustomer = { ...customer, avatar };
      updateCustomer(newCustomer);
      InputUploadRef.current.value = null;
    } catch (error: any) {
      console.error(error);
      let message = error.message;
      if (!message) {
        message = t('common:errors.corrupted-file');
      }

      dispatch(openErrorToast({ title: message, dismissTime: 0 }));
    }
  };

  useEffect(() => {
    if (customer?.avatar?.length > 0 && customer?.avatar !== 'null') {
      setUrl('data:image/jpeg;base64, ' + customer.avatar);
    } else {
      setUrl('');
    }
  }, [user, updateCustomerLoading, customer]);

  const removeAvatar = () => {
    setUrl(null);

    const newCustomer = { ...customer, avatar: 'null' };
    updateCustomer(newCustomer);

    dispatch(openBasicToast(t('settings:avatar-remove-successful')));
  };

  return (
    <Flex pb={40} flexDirection={'column'}>
      <Subtitle mb={4}>{t('common:avatar')}</Subtitle>
      <Flex flexDirection={'row'} alignItems={'center'} pb={12}>
        {updateCustomerLoading ? (
          <Box height="80px">
            <Loader visible={true} />
          </Box>
        ) : url ? (
          <ImageContainer>
            <Image data-testid="avatar-form-image" src={url} />
          </ImageContainer>
        ) : (
          <>
            <ImageContainer backgroundColor={theme.primary.lightest}>
              <Text
                data-testid="avatar-form-initials"
                fontSize={36}
                textAlign={'center'}
                color={theme.primary.base}
              >
                {buildUserInitials(firstName, user?.lastName)}
              </Text>
            </ImageContainer>
            <Subtitle data-testid="avatar-form-subtitle">
              {t('common:no-image-uploaded')}
            </Subtitle>
          </>
        )}
      </Flex>
      <Flex>
        <Box mr={8}>
          <UploadInput
            data-testid="avatar-form-upload-photo-input"
            ref={InputUploadRef}
            onChange={handleUploadInputChange}
            accept={supportedImageTypes.toString()}
          />
          <Button
            data-testid="avatar-form-upload-photo-button"
            variant={'outline'}
            onClick={() => InputUploadRef?.current?.click()}
          >
            {t('common:upload-photo')}
          </Button>
        </Box>
        {url && (
          <Button
            data-testid="avatar-form-remove-image-button"
            onClick={() => removeAvatar()}
            variant={'text-primary'}
          >
            {t('common:remove-image')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
