import { useCallback, useEffect } from 'react';

import { TemplatePage } from 'zsbpsdk/src/templates';
import { RootState } from 'store';

import { useReduxSelector, useUrlParam } from 'hooks';
import { getQuery as selectQuery } from 'ducks/router/selectors';
import {
  PAGE_NUMBER_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SORT_DEFAULT,
} from 'utils/defaults';
import { BaseInitialState } from 'ducks/utils';

const useFilteredTemplates = (
  selector: (state: RootState) => BaseInitialState<TemplatePage>,
  reduxAction: (params: {
    searchLibrary: string;
    pageSize: string;
    pageNumber: number;
    sort: string;
    size: string;
    search: string;
    selectedCategory?: string;
  }) => void,
) => {
  const {
    data: filteredTemplatesData,
    loading: filteredTemplatesDataLoading,
    error: filteredTemplatesError,
  } = useReduxSelector(selector) as any;

  const {
    searchLibrary = '',
    pageSize = PAGE_SIZE_DEFAULT,
    sort = SORT_DEFAULT,
    size = '',
    search = '',
    category,
  } = useReduxSelector(selectQuery);

  const [pageNumber, setPageNumber] = useUrlParam(
    'pageNumber',
    PAGE_NUMBER_DEFAULT,
  );

  const totalPagesCount = Math.ceil(
    filteredTemplatesData?.totalCount / parseInt(pageSize),
  );

  const fetchData = useCallback(
    () =>
      reduxAction({
        searchLibrary,
        search: search || searchLibrary,
        pageNumber,
        pageSize,
        sort,
        size,
        selectedCategory: category && decodeURIComponent(category),
      }),
    [
      pageNumber,
      pageSize,
      reduxAction,
      search,
      searchLibrary,
      size,
      sort,
      category,
    ],
  );

  useEffect(() => {
    const page = parseInt(pageNumber);

    if (page > totalPagesCount) {
      setPageNumber(
        totalPagesCount > 0 ? totalPagesCount : PAGE_NUMBER_DEFAULT,
      );
    }
  }, [pageNumber, setPageNumber, totalPagesCount]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    filteredTemplatesData,
    filteredTemplatesDataLoading,
    filteredTemplatesError,
    totalPagesCount,
  };
};

export default useFilteredTemplates;
