import React from 'react';
import styled from 'styled-components/macro';
import { Flex, LabelText, Text } from 'components';
import { useTranslation } from 'react-i18next';

type SectionAttributes = {
  title: string;
  children?: any;
};

const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: 18,
  lineHeight: '28px',
  letterSpacing: 0.4,
  color: theme.textColors.med,
}))``;

const SectionWrap = styled(Flex).attrs({ flexDirection: 'column' })`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const Section = ({ title, children }: SectionAttributes) => {
  const { t } = useTranslation('templates');

  return (
    <SectionWrap>
      <SectionTitle>{`${title} (${children.length})`}</SectionTitle>
      {children.length === 0 && (
        <LabelText>
          {t('templates:not-found-filter', { term: title, count: 1 })}
        </LabelText>
      )}
      <Flex flexWrap={'wrap'}>{children}</Flex>
    </SectionWrap>
  );
};

export default Section;
