import { useState, useEffect } from 'react';
//   Custom hook to handle debounce correctly

export default function useDebounce(value, time) {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setState(value), time);
    return () => clearTimeout(timer);
  }, [value, time]);
  return state;
}
