import i18n from 'i18next';
import { ZsbpApp } from 'zsbpsdk';
import {
  APPLICATION_VERSION,
  SSO_BASE_URL,
  SSO_EMAIL_TOKEN_URL,
  ZEBRA_GRPC_SERVICE_URL,
  ZEBRA_NL_API_BASE_URL,
} from 'utils/config';
import { WorkspaceType } from 'zsbpsdk/src/workspace';
import { PrinterType } from 'zsbpsdk/src/printer';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates';
import {
  LabelStock,
  SYSTEM_CATEGORY,
  templateCategories,
  TemplateCategory,
} from 'utils/categories';
import { convertLabelSizeString, convertUnitToType } from 'utils/unitFormatter';
import {
  CustomerInfoType,
  CustomerPreferencesType,
  State,
} from 'zsbpsdk/src/customer';
import { LabelStockType } from 'zsbpsdk/src/labels';
import { FileInfoType } from 'utils/dataSourcesTypes';
import { getUnixTime } from 'utils/dateFormatter';
import { toBase64, toBase64fromPSD } from 'utils/toBase64';
import { getFileExtension } from 'utils/files';
import { CommunicationError, Severity } from 'utils/errorFormatter';
import {
  BannerMessage,
  PrinterSettings,
} from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { TEMPLATE_PARAMS_DEFAULT } from 'utils/defaults';
import { getSuccessfulTemplates } from './helper';

type DeleteManyDocuments = (
  customerId: string,
  workspaceId: any,
  token: string,
  list: TemplateType[],
) => Promise<{
  deletedCount: number;
  successfullyDeletedTemplates?: TemplateType[];
}>;

export const supportedDbTypes = ['.TXT', '.CSV', '.XLSX'];
export const supportedImageTypes = ['.BMP', '.JPG', '.JPEG', '.PNG', '.PSD'];
export const supportedWorkspacedAvatarTypes = [
  '.PNG',
  '.JPEG',
  '.JPG',
  '.BMP',
  '.GIF',
];
export const supportedTemplatetypes = ['.NLBL'];
export const supportedDataTypes = [...supportedDbTypes, ...supportedImageTypes];

export const DOCUMENT_MAX_PAGE_SIZE = 100;

const HEADER_KEY_CLIENT_ID = 'x-zebra-zsbp-client-id';
const HEADER_KEY_CUSTOMER_ID = 'x-zebra-customer-id';

const DBG = ['%c API ', 'color:white;background:green'];

const documentFilesUrl = `${ZEBRA_NL_API_BASE_URL}/document/api/v1/files`;
const documentMetaUrl = `${ZEBRA_NL_API_BASE_URL}/document/api/v1/metaData`;
const designerUrl = `${ZEBRA_NL_API_BASE_URL}/designer/api/v1`;
const renderingUrl = `${ZEBRA_NL_API_BASE_URL}/rendering/api/v1`;
export const DB_FILE_NAME = 'cloudDatabase.dat';

const DOCUMENT_REGEX = /[%&*?+\\:/<>#]/g;
export const DOCUMENT_NOT_ALLOWED_PATTERN = '[^%&*?+\\:/<>#“”]*';
export const NO_SPECIAL_CHARS_REGEX =
  /[`~!@#$%^&*()+\-—_=[\]{}:;'",.<>\\|/?￥\s*]/g;

export const buildHeaders = (
  customerId: string | null,
  token: string | null,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    [HEADER_KEY_CLIENT_ID]: getClientId(),
  };

  if (customerId !== null) {
    headers[HEADER_KEY_CUSTOMER_ID] = customerId;
  }

  return headers;
};

const getZsbpApp = (customerId: string | null, token: string | null) =>
  new ZsbpApp(ZEBRA_GRPC_SERVICE_URL, buildHeaders(customerId, token));

const timeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

// TODO Make this return a Promise<FileInfoPage> (with fileDataList of type FileInfoType[] and totalCount)
const formatDatasources = async (response) => {
  const x = await response.json();
  const z = x['fileDataList'];
  return z
    .map((obj) => parseDatasource(obj))
    .filter(({ name }) => {
      return name !== DB_FILE_NAME;
    });
};

const parseDatasource = (obj) => {
  let mimeType = '';
  if (obj.ErrorCode === 404) {
    return null as any;
  }
  obj.metadata.forEach((element) => {
    if (element['key'] === 'mimeType') {
      mimeType = element['value'];
    }
  });
  return {
    name: obj.name,
    id: obj.name,
    provider: 'Local File',
    mimetype: mimeType,
    lastModified: getUnixTime(new Date(obj.modifiedDateTime)),
  };
};

const formatTemplates = async (
  response,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  let { fileDataList, totalCount } = await response.json();
  const parsedFileDataList: TemplateType[] = [];
  // const a = z.filter(t => t['tenantExternalId'] === workspaceId);

  for (let template of fileDataList) {
    if (template.category !== SYSTEM_CATEGORY) {
      // Remove hidden templates
      template = await parseTemplate(template, customerId, workspaceId, token);
      parsedFileDataList.push(template);
    }
  }

  return {
    fileDataList: parsedFileDataList,
    totalCount,
  };
};

const buildParsedTemplate = (
  template: RawResponse,
  category,
  width,
  height,
  fieldOrder,
): TemplateType => ({
  id: template.id,
  tenant: template.tenantExternalId,
  filename: template.name,
  name: template.name ? template.name.replace(/(.*)\.nlbl$/, '$1') : '',
  smallThumbnail: template.thumbnail ?? '',
  category,
  createdDate: template.createDateTime ?? '',
  lastUpdateDate: template.modifiedDateTime ?? '',
  lastPrint:
    template.printedDateTime !== null && template.printedDateTime !== ''
      ? template.printedDateTime
      : template.metadata.find((x) => x.key === 'printedDateTime')?.value,
  labelSize: {
    width,
    height,
  },
  fieldOrder: fieldOrder.split(',') ?? [],
  orientation: template.metadata.find((x) => x.key === 'orientation')?.value,
});

const parseTemplate = async (
  template,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplateType> => {
  let category,
    fieldOrder = '';
  let width = 0,
    height = 0;

  for (const metadataKey in template.metadata) {
    const { key, value } = template.metadata[metadataKey];
    switch (key) {
      case 'height':
        height = Number(value);
        break;
      case 'width':
        width = Number(value);
        break;
      case 'category':
        category = value;
        break; //TODO: get dataSource and fields
      case 'fieldOrder':
        fieldOrder = value;
        break;
    }
  }

  if (width === 0 || height === 0) {
    try {
      const templateDetails = await getTemplateDataDetails(
        template.name,
        customerId,
        workspaceId,
        token,
      );
      if (width === 0) {
        width = templateDetails.mediaWidth;
      }
      if (height === 0) {
        height = templateDetails.mediaHeight;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return buildParsedTemplate(template, category, width, height, fieldOrder);
};

export const getTemplate = async (
  customerId: string,
  workspaceId: any,
  token: string,
  name: string,
): Promise<TemplateType> => {
  return parseTemplate(
    await getDocument(customerId, workspaceId, token, name),
    customerId,
    workspaceId,
    token,
  );
};

export const getDocument = async (
  customerId: string,
  workspaceId: any,
  token: string,
  name: string,
): Promise<any> => {
  const response = await fetch(
    `${documentFilesUrl}/${encodeURIComponent(name)}`,
    getBody(customerId, workspaceId, token),
  );
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return await response.json();
};
const getCloudDbFile = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<any> => {
  const checkCloudDBResponse = await fetch(
    `${documentFilesUrl}?Filter=cloudDatabase.dat`,
    getBody(customerId, workspaceId, token),
  );
  const cloudDbInfo = await checkCloudDBResponse.json();
  return await cloudDbInfo;
};

const getDocumentContent = async (
  customerId: string,
  workspaceId: any,
  token: string,
  name: string,
): Promise<any> => {
  if (name === 'cloudDatabase.dat') {
    const cloudData = await getCloudDbFile(customerId, workspaceId, token);
    const cloudDatabaseDat = cloudData.fileDataList.find(
      (p) => p.name === 'cloudDatabase.dat',
    );

    if (!cloudDatabaseDat) {
      return '';
    }
  }

  const response = await fetch(
    `${documentFilesUrl}/${encodeURIComponent(name)}/content`,
    getBody(customerId, workspaceId, token),
  );
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return await response.json();
};

const setDocumentContent = async (
  customerId: string,
  workspaceId: any,
  token: string,
  name: string,
  data: string,
): Promise<any> => {
  const response = await fetch(
    `${documentFilesUrl}/${encodeURIComponent(name)}/content`,
    {
      method: 'PUT',
      headers: {
        ...buildHeaders(customerId, token),
        TenantId: workspaceId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: data }),
    },
  );
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return true;
};

const parseTemplatesResponse = async (
  response,
  errorMessage,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  if (response.status === 200) {
    return await formatTemplates(response, customerId, workspaceId, token);
  }
  throw new CommunicationError(
    (await response.text()) ?? errorMessage,
    response.status,
  );
};

const parseMyDesignSizesResponse = async (
  response: Response,
): Promise<Array<{ width: number; height: number }>> => {
  if (response.status === 200) {
    return response.json().then((data: string[]) => {
      return data.reduce((acc: { width: number; height: number }[], cur) => {
        const [width, height] = cur.split('x');
        acc.push({ width: +width, height: +height });
        return acc;
      }, []);
    });
  }
  throw new CommunicationError(await response.text(), response.status);
};

const parseDataSourceResponse = async (
  response,
  errorMessage,
): Promise<FileInfoType[]> => {
  if (response.status === 200) {
    return await formatDatasources(response);
  }
  throw new CommunicationError(
    (await response.text()) ?? errorMessage,
    response.status,
  );
};

const getBody = (customerId: string, workspaceId: any, token: string): any => ({
  method: 'GET',
  headers: {
    ...buildHeaders(customerId, token),
    TenantId: workspaceId,
  },
});

export const getClientId = (): string => {
  let uuid = localStorage.getItem('uuid');
  if (!uuid) {
    uuid = (Number(1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(
      /[018]/g,
      (c) => {
        let a = parseInt(c);
        return (
          a ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (a / 4)))
        ).toString(16);
      },
    );
    localStorage.setItem('uuid', uuid);
  }
  return uuid;
};

export const checkTokenValidity = async (
  token: string,
  customerId: string | null = null,
) => {
  try {
    await getZsbpApp(customerId, token).portal.getVersion();
    return true;
  } catch (error: any) {
    console.debug(...DBG, 'Invalid token', error);
    return false;
  }
};

export const login = async () => {
  return new Promise((resolve, reject) => {
    fetch(`${ZEBRA_GRPC_SERVICE_URL}/zsbpportal.ZsbpPortalService/GetVersion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/grpc-web-text',
        accept: 'application/grpc-web-text',
        'x-grpc-web': '1',
      },
    })
      .then((res) => {
        if (!res.ok || res.headers.get('grpc-status') !== '0') {
          return reject(new Error(res.headers.get('grpc-message') || ''));
        }
        return resolve(true);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const logout = async (customerId: string, token: string) => {
  await fetch(`${ZEBRA_GRPC_SERVICE_URL}/auth/logout`, {
    method: 'POST',
    headers: buildHeaders(customerId, token),
  });
};

export const getPrinterTools = async (
  unit: string,
  dithering: number,
  os: string,
  customerId: string,
  token: string,
) => {
  const id = getClientId();
  const printerTools = await getZsbpApp(
    customerId,
    token,
  ).portal.info.getPrinterToolsInfo(unit, dithering, os, id);

  return {
    version: printerTools.getVersion(),
    url: printerTools.getSignedinstallerurl(),
    releaseNotesUrl: printerTools.getSignedreleasenotesurl(),
    size: printerTools.getSize(),
    created: printerTools.getCreated()?.toDate,
    sha: printerTools.getSha256(),
    releaseNotesContents: printerTools.getReleasenotescontent(),
  };
};

export const getLinks = async (customerId: string, token: string) =>
  await getZsbpApp(customerId, token).portal.info.getLinks();

export const getCustomer = async (token: string): Promise<CustomerInfoType> =>
  await getZsbpApp(null, token).portal.customer.getInfo();

export const setCustomer = async (
  customerInfo: CustomerInfoType,
  token: string,
): Promise<CustomerInfoType> =>
  await getZsbpApp(customerInfo.id, token).portal.customer.setInfo(
    customerInfo,
  );

export const sendWhoAmI = async (
  unit: string,
  dithering: number,
  customerId: string,
  token: string,
  clientId: string,
): Promise<boolean> =>
  await getZsbpApp(customerId, token).portal.info.whoAmI(
    unit,
    dithering,
    APPLICATION_VERSION,
    clientId,
  );

export const getPrefs = async (customerId: string, token: string) => {
  const preferences: CustomerPreferencesType = await getZsbpApp(
    customerId,
    token,
  ).portal.customer.getPreferences();
  return { ...preferences, units: convertUnitToType(preferences.units) };
};

export const setPrefs = async (
  customerId: string,
  token: string,
  inputPrefs: any,
) => {
  const outputPrefs: any = await getZsbpApp(
    customerId,
    token,
  ).portal.customer.setPreferences(inputPrefs);
  return { ...outputPrefs, units: convertUnitToType(outputPrefs.units) };
};

export const getUserDetails = async (customer: any): Promise<any> => {
  const response = await fetch(
    `${SSO_BASE_URL}/v2/customers/contacts?requesterId=zmbsrvacc&userId=${encodeURIComponent(
      customer.email,
    )}`,
    {
      method: 'GET',
      // SSO URLs don't need x-zebra-customer-id and x-zebra-zsbp-client-id headers
      headers: {
        Authorization: `Bearer ${customer.token}`,
      },
    },
  );
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return response.json();
};

export const updateUser = async (
  zuid,
  userId,
  body,
  customerId: string,
  token: string,
): Promise<any> => {
  const response = await fetch(
    `${SSO_BASE_URL}/v2/customers/contacts?zuid=${zuid}`,
    {
      method: 'PUT',
      // SSO URLs don't need x-zebra-customer-id and x-zebra-zsbp-client-id headers
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          userId,
          ...body,
          requesterId: 'zmbsrvacc',
          appId: 'ZEMB',
          zuid,
        },
      }),
    },
  );
  const json = await response.json();
  if (response.ok && json.status !== 'Error') {
    return true;
  }
  throw Error(json);
};

export const requestEmailToken = async (
  newEmail: string,
  zuid: string,
  customerId: string,
  token: string,
): Promise<any> => {
  const body = JSON.stringify({
    UserId: newEmail,
    EmailId: newEmail,
    zuid: zuid,
    RequesterId: 'zmbsrvacc',
    Version: 'B2CApi',
    retEventID: 'True',
  });

  const response = await fetch(SSO_EMAIL_TOKEN_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: body,
  });
  const json = await response.json();
  if (response.status !== 200 || json['ResponseCode'] !== 'ZBRGTS000')
    throw json;
  return json['RefCode'];
};

export const editPrinter = async (
  printer: any,
  customerId: string,
  token: string,
): Promise<PrinterType> =>
  await getZsbpApp(customerId, token).portal.printer.updatePrinter(printer);

export const getPrinters = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<PrinterType[]> => {
  const printers = await getZsbpApp(
    customerId,
    token,
  ).portal.printer.getPrinters(workspaceId);
  console.debug(...DBG, 'getPrinters', printers);
  return printers;
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getPrintersUntilNew = async (
  customerId: string,
  workspaceId: any,
  token: string,
  currentPrinters: PrinterType[],
): Promise<PrinterType[]> => {
  let newPrinters;
  let delayTime = 500;

  while (newPrinters == null) {
    newPrinters = await getNewList(
      customerId,
      workspaceId,
      token,
      currentPrinters,
    );
    if (newPrinters !== null) break;
    await delay((delayTime *= 2));
    if (delayTime > 30000) throw Error('No new printers found');
  }
  return newPrinters;
};

const getNewList = async (
  customerId: string,
  workspaceId: any,
  token: string,
  currentPrinters: PrinterType[],
): Promise<PrinterType[] | null> => {
  let _newList: PrinterType[];
  _newList = await getPrinters(customerId, workspaceId, token);
  const ids: string[] =
    currentPrinters.length > 0 ? currentPrinters.map((e) => e.uniqueId) : [];
  const newIds: string[] =
    _newList.length > 0 ? _newList.map((e) => e.uniqueId) : [];

  let hasNew: boolean = false;
  if (newIds.length > 0) {
    if (newIds.length > ids.length) {
      hasNew = true;
    } else {
      newIds.forEach((element) => {
        if (ids.includes(element)) hasNew = true;
      });
    }
  }
  if (hasNew) return [..._newList];

  return null;
};

export const getWorkspaces = async (
  customerId: string,
  token: string,
): Promise<WorkspaceType[]> =>
  await getZsbpApp(customerId, token).portal.workspace.getWorkspaces();

export const updateWorkspace = async (
  workspace: WorkspaceType,
  customerId: string,
  token: string,
): Promise<WorkspaceType> =>
  await getZsbpApp(customerId, token).portal.workspace.updateWorkspace(
    workspace,
  );

export const deletePrinter = async (
  printerId: any,
  customerId: string,
  token: string,
): Promise<boolean> =>
  await getZsbpApp(customerId, token).portal.printer.deletePrinter(printerId);

export const deleteAccount = async (
  request: State,
  customerId: string,
  token: string,
): Promise<Object> =>
  await getZsbpApp(customerId, token).portal.customer.deleteAccount(request);

export const sendZpl = async (
  zplCommand: string | Uint8Array,
  printerId: string,
  customerId: string,
  token: string,
): Promise<string> =>
  getZsbpApp(customerId, token).portal.printer.sendZpl(printerId, zplCommand);

export const print = async (
  templateFilename: string,
  fields,
  printer: any,
  copies: number,
  printerDarkness: number,
  cartridgeDarkness: number,
  dithering: number,
  customerId: string,
  token: string,
  templateCategory: TemplateCategory | LabelStock,
): Promise<string> => {
  let printerSettings = new Map<string, string>();

  printerSettings.set(
    'PrintDitheringType',
    dithering > 6 || dithering < 1 ? '6' : dithering.toString(),
  );

  if (cartridgeDarkness == null || cartridgeDarkness === 0) {
    cartridgeDarkness = 150;
  }
  printerSettings.set('cartridgeDarkness', cartridgeDarkness.toString());

  if (printerDarkness == null) {
    printerDarkness = 0;
  }
  printerSettings.set('printDarkness', printerDarkness.toString());

  const jobId = await getZsbpApp(customerId, token).portal.printer.print(
    printer.uniqueId,
    `Zebra ${printer.model}`,
    templateFilename,
    changeNewLine(fields),
    copies ?? 1,
    printerSettings,
    templateCategory,
  );
  return jobId.toObject().toString(); //TODO improve type
};

export const cancelAll = async (
  printer: any,
  customerId: string,
  token: string,
): Promise<String> => {
  const jobId = await getZsbpApp(customerId, token).portal.printer.cancelAll(
    printer,
  );
  return jobId.toObject().toString();
};

export const reprint = async (
  printer: any,
  customerId: string,
  token: string,
): Promise<String> => {
  const jobId = await getZsbpApp(customerId, token).portal.printer.reprint(
    printer,
  );
  return jobId.toObject().toString();
};

export const leaveWorkspace = async () => {
  await timeout(1000);
  //TODO
  return { success: true };
};

export const getTemplates = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  const response = await fetch(
    `${documentFilesUrl}?FileType=Label&PageSize=${DOCUMENT_MAX_PAGE_SIZE}`, // TODO pagination
    getBody(customerId, workspaceId, token),
  );

  return await parseTemplatesResponse(
    response,
    'Error sending get request',
    customerId,
    workspaceId,
    token,
  );
};

export const getTemplateDataDetails = async (
  templateFilename: string,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<any> => {
  const response = await fetch(
    `${designerUrl}/labels/${encodeURIComponent(templateFilename)}/information`,
    getBody(customerId, workspaceId, token),
  );
  if (response.status === 200) {
    const { databases, dataSources, mediaWidth, mediaHeight } =
      await response.json();
    return { databases, dataSources, mediaWidth, mediaHeight };
  }
  throw new CommunicationError(await response.text(), response.status);
};

export const getMyDesignAllSizes = async (
  customerId: string,
  workspaceId: string,
  token: string,
) => {
  const response = await fetch(
    `${documentMetaUrl}/size`,
    getBody(customerId, workspaceId, token),
  );
  return await parseMyDesignSizesResponse(response);
};

export const getFilteredTemplates = async (
  params: any = TEMPLATE_PARAMS_DEFAULT,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  const { pageNumber, pageSize, size, sort, search } = params;
  const urlSearchparams = new URLSearchParams();

  if (size && size !== '-1') {
    let { width, height } = convertLabelSizeString(decodeURIComponent(size));
    if (width === undefined || height === undefined)
      console.error('getFilteredTemplates gave a null size :/');
    urlSearchparams.append('Metadata', `{width:${width},height:${height}}`);
  }

  sort && sort !== '-1' && urlSearchparams.append('Sort', `name ${sort}`);
  search &&
    search !== '' &&
    urlSearchparams.append('Filter', decodeURIComponent(search));
  pageNumber && urlSearchparams.append('PageNumber', pageNumber);
  pageSize && urlSearchparams.append('PageSize', pageSize);
  urlSearchparams.append('FileType', 'Label');

  const response = await fetch(
    `${documentFilesUrl}?${urlSearchparams}`,
    getBody(customerId, workspaceId, token),
  );
  return await parseTemplatesResponse(
    response,
    'Error sending filter request',
    customerId,
    workspaceId,
    token,
  );
};

export const getFilteredCategoryTemplates = async (
  params: any = TEMPLATE_PARAMS_DEFAULT,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  const {
    pageNumber,
    pageSize,
    size,
    sort,
    search,
    selectedCategory: category = '',
  } = params;
  const selectedCategory = category.replaceAll('/', '-');
  const urlSearchparams = new URLSearchParams();

  if (selectedCategory && size && size !== '-1') {
    let { width, height } = convertLabelSizeString(decodeURIComponent(size));
    if (width === undefined || height === undefined)
      console.error('getFilteredTemplates gave a null size :/');
    urlSearchparams.append(
      'Metadata',
      `{width:${width},height:${height},Category:${selectedCategory}}`,
    );
  } else if (selectedCategory) {
    selectedCategory &&
      urlSearchparams.append('Metadata', `{Category:${selectedCategory}}`);
  } else if (size && size !== '-1') {
    let { width, height } = convertLabelSizeString(decodeURIComponent(size));
    if (width === undefined || height === undefined)
      console.error('getFilteredTemplates gave a null size :/');
    urlSearchparams.append('Metadata', `{width:${width},height:${height}`);
  }
  search &&
    search !== '' &&
    urlSearchparams.append('Filter', decodeURIComponent(search));
  sort && sort !== '-1' && urlSearchparams.append('Sort', `name ${sort}`);
  pageNumber && urlSearchparams.append('PageNumber', pageNumber);
  pageSize && urlSearchparams.append('PageSize', pageSize);
  urlSearchparams.append('FileType', 'Label');

  const response = await fetch(
    `${documentFilesUrl}?${urlSearchparams}`,
    getBody(customerId, TemplateCategory.Internal, token),
  );
  return await parseTemplatesResponse(
    response,
    'Error sending filter request',
    customerId,
    workspaceId,
    token,
  );
};

export const getTemplateSuggestions = async (
  customerId: string,
  workspaceId: any,
  search: string,
  token: string,
  category?: string,
): Promise<TemplatePage> => {
  const uri = new URLSearchParams();

  uri.append('FileType', 'Label');
  if (search) {
    uri.append('Filter', search);
  }
  if (category) {
    uri.append('Metadata', `{category:${category}}`);
  }

  const response = await fetch(
    `${documentFilesUrl}?${uri.toString()}`,
    getBody(customerId, workspaceId, token),
  );
  return await parseTemplatesResponse(
    response,
    'Error sending suggestions request',
    customerId,
    workspaceId,
    token,
  );
};

export const getDataSourcesSuggestions = async (
  customerId: string,
  workspaceId: string,
  search: string,
  token: string,
  extraParams: string = '',
): Promise<FileInfoType[]> => {
  const body = getBody(customerId, workspaceId, token);
  const url = `${documentFilesUrl}?${
    search && search.length > 0 ? `Filter=${encodeURIComponent(search)}&` : ''
  }${extraParams}`;

  const [imageResponse, dbResponse] = await Promise.all([
    fetch(`${url}FileType=Image`, body),
    fetch(`${url}FileType=Database`, body),
  ]);

  let images = await parseDataSourceResponse(
    imageResponse,
    'Error sending suggestions request',
  ); //Why Template Response??
  let dbs = await parseDataSourceResponse(
    dbResponse,
    'Error sending suggestions request',
  );

  console.debug(...DBG, 'getDataSourcesSuggestions', [...images, ...dbs]);
  return [...images, ...dbs];
};

export const getLocalDatasources = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<FileInfoType[]> => {
  //TODO: add image / database only filter in next api release
  //TODO: remove params
  return await getDataSourcesSuggestions(
    customerId,
    workspaceId,
    '',
    token,
    `Sort=name&PageSize=${DOCUMENT_MAX_PAGE_SIZE}&`,
  );
};

export const getLocalDatasourcesFiltered = async (
  customerId: string,
  workspaceId: string,
  params: any = TEMPLATE_PARAMS_DEFAULT,
  token: string,
): Promise<FileInfoType[]> => {
  return await getDataSourcesSuggestions(
    customerId,
    workspaceId,
    decodeURIComponent(params),
    token,
    `PageSize=${DOCUMENT_MAX_PAGE_SIZE}&`,
  );
};

export const getRecentTemplates = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  const response = await fetch(
    `${documentFilesUrl}/recentlyprinted?pageSize=6&pageNumber=1`,
    getBody(customerId, workspaceId, token),
  );
  return await parseTemplatesResponse(
    response,
    'Error sending recent request',
    customerId,
    workspaceId,
    token,
  );
};

export const getFilteredCategories = async (
  customerId: string,
  params: any,
  token: string,
) => {
  const response = await fetch(
    `${documentMetaUrl}/category?`,
    getBody(customerId, TemplateCategory.Internal, token),
  );

  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }

  const notFoundText = i18n.t('common:errors.not-found');
  const filteredBySearch = (await response.json())
    .map((obj) => ({
      name: obj.replaceAll(/([-])/g, '/'),
      logo: 'logo-zebra',
      id: templateCategories[obj]?.id || notFoundText,
      desc: templateCategories[obj]?.desc || notFoundText,
    }))
    .filter(({ name }) => name !== SYSTEM_CATEGORY)
    .filter((t: any) =>
      t.name.toLowerCase().includes(decodeURIComponent(params.toLowerCase())),
    );
  //TODO: Get this data in the same format as previously
  return {
    success: true,
    categories: filteredBySearch,
  };
};

export const getCategoriesSuggestions = async (value: string) => {
  //TODO categories suggestions on search
  return {};
};

export const getAllLabelSizes = async (
  customerId: string,
  token: string,
): Promise<LabelStockType[]> =>
  await getZsbpApp(customerId, token).portal.labels.getLabelStocks();

export const subscribeNotifications = (
  customerId: string,
  token: string,
  callback: any,
  errorCallback: any,
  endCallback: any,
) => {
  getZsbpApp(customerId, token).portal.customer.notifications(
    callback,
    errorCallback,
    endCallback,
  );
};

export const duplicateTemplate = async (
  template: any,
  newName: string,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<any> => {
  const response = await fetch(
    `${documentFilesUrl}/${template.name}.nlbl/clone`,
    {
      method: 'POST',
      headers: {
        ...buildHeaders(customerId, token),
        TenantId: workspaceId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: `${newName}.nlbl`,
      }),
    },
  );
  if (response.status === 201) {
    return await parseTemplate(
      await response.json(),
      customerId,
      workspaceId,
      token,
    );
  }
  throw new CommunicationError(await response.text(), response.status);
};

export const getUniqueFileName = async (
  newName: string,
  customerId: string,
  workspaceId: string,
  token: string,
) => {
  const fileName = encodeURIComponent(newName.replace(DOCUMENT_REGEX, ''));
  const uniqueName = await fetch(`${documentFilesUrl}/${fileName}/unique`, {
    method: 'GET',
    headers: {
      ...buildHeaders(customerId, token),
      TenantId: workspaceId,
      'Content-Type': 'application/json',
    },
  });

  if (!uniqueName.ok) {
    throw new CommunicationError(await uniqueName.text(), uniqueName.status);
  }

  const json = await uniqueName.json();

  if (json['name'].length > 0) {
    return json['name'];
  }
  throw Error('Name too short');
};

export const renameTemplate = async (
  oldTemplate: any,
  newName: string,
  customerId: string,
  workspaceId: any,
  token: string,
) => {
  const oldFilename = encodeURIComponent(oldTemplate.filename);
  const response = await fetch(`${documentFilesUrl}/${oldFilename}`, {
    method: 'PUT',
    headers: {
      ...buildHeaders(customerId, token),
      TenantId: workspaceId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: newName,
      fileType: 'Label',
      printedDateTime: oldTemplate.lastPrint,
      metadata: [
        {
          key: 'width',
          value: oldTemplate.labelSize?.width,
        },
        {
          key: 'height',
          value: oldTemplate.labelSize?.height,
        },
      ],
    }),
  });

  return response.ok ? newName : false;
};

export const updateTemplateMetadata = async (
  oldTemplate: any,
  metadata: any,
  customerId: string,
  workspaceId: any,
  token: string,
) => {
  const oldFilename = encodeURIComponent(oldTemplate.filename);
  const body = JSON.stringify({
    name: `${oldTemplate.name}.nlbl`,
    fileType: 'Label',
    printedDateTime: oldTemplate.lastPrint,
    metadata: [
      {
        key: 'width',
        value: oldTemplate.labelSize?.width,
      },
      {
        key: 'height',
        value: oldTemplate.labelSize?.height,
      },
      metadata,
    ],
  });
  const response = await fetch(`${documentFilesUrl}/${oldFilename}`, {
    method: 'PUT',
    headers: {
      ...buildHeaders(customerId, token),
      TenantId: workspaceId,
      'Content-Type': 'application/json',
    },
    body,
  });
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return await parseTemplate(
    await response.json(),
    customerId,
    workspaceId,
    token,
  );
};

export const deleteDocument = async (
  id: number,
  customerId: string,
  workspaceId: any,
  token: string,
) => {
  const response = await fetch(
    `${documentFilesUrl}/${encodeURIComponent(id)}`,
    {
      method: 'DELETE',
      headers: {
        ...buildHeaders(customerId, token),
        TenantId: workspaceId,
      },
    },
  );

  return response.status === 204;
};

export const deleteManyDocuments: DeleteManyDocuments = async (
  customerId: string,
  workspaceId: any,
  token: string,
  list: TemplateType[],
) => {
  const data: boolean[] = await Promise.allSettled(
    list.map((t: TemplateType) =>
      deleteDocument(t.id, customerId, workspaceId, token),
    ),
  ).then((results) =>
    results.map((result) =>
      result.status === 'fulfilled' ? result.value : false,
    ),
  );

  const successfulTemplates = getSuccessfulTemplates(list, data);

  return {
    successfulTemplates,
    deletedCount: successfulTemplates.length,
  };
};

const changeNewLine = (labels: any) =>
  labels.map((fields) =>
    JSON.parse(JSON.stringify(fields).replace(/(\\r)?\\n/g, '<CR><LF>')),
  );

export const getPrintPreview = async (
  templateFilename: string,
  dataSources: any,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<string[]> => {
  const response = await getZsbpApp(
    customerId,
    token,
  ).portal.document.printPreview(
    workspaceId,
    `Zebra ZSB-DP14`,
    templateFilename,
    changeNewLine(dataSources),
  );
  let responseJson = JSON.parse(response);
  if (!responseJson.frontSides || responseJson.frontSides.length === 0) {
    if (responseJson.printingError) {
      throw responseJson.printingError;
    }
    throw responseJson;
  }
  return responseJson.frontSides as string[];
};

export const addLocalFile = async (
  file: File,
  fileName: string,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<FileInfoType> => {
  const mimeType = file.type;

  const extension = getFileExtension(fileName.toUpperCase());
  if (!supportedDataTypes.includes(`.${extension}`)) {
    console.debug(...DBG, 'File is not allowed mimetype', extension);
    throw new Error('Invalid File Type');
  }

  const response = await fetch(`${documentFilesUrl}`, {
    method: 'POST',
    headers: {
      ...buildHeaders(customerId, token),
      TenantId: workspaceId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: fileName,
      content:
        mimeType.includes('photoshop') || extension.includes('PSD')
          ? await toBase64fromPSD(file)
          : await toBase64(file),
      fileType: mimeType.includes('image') ? 'Image' : 'Database',
      metadata: [
        {
          key: 'mimeType',
          value: mimeType,
        },
      ],
    }),
  });
  if (!response.ok) {
    throw new CommunicationError(await response.text(), response.status);
  }

  return parseDatasource(await response.json());
};

export const addTemplate = async (
  fileData: string,
  fileName: string,
  customerId: string,
  workspaceId: any,
  token: string,
  isInternalDatabase: boolean = false,
): Promise<any> => {
  const extension = getFileExtension(fileName.toUpperCase());
  if (
    !supportedTemplatetypes.includes(`.${extension}`) &&
    !isInternalDatabase
  ) {
    console.debug(...DBG, 'File is not allowed mimetype', extension);
    throw new Error('Invalid File Type');
  }
  const response = await fetch(`${documentFilesUrl}`, {
    method: 'POST',
    headers: {
      ...buildHeaders(customerId, token),
      TenantId: workspaceId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: fileName,
      content: fileData,
      fileType: isInternalDatabase ? 'Database' : 'label',
    }),
  });

  if (response.status !== 201) {
    throw new CommunicationError(await response.text(), response.status);
  }
  return await parseTemplate(
    await response.json(),
    customerId,
    workspaceId,
    token,
  );
};

export const addAvatar = async (
  file: File,
  workspaceId: any,
  token: string,
): Promise<Boolean> => {
  // TODO: This needs to be created...
  const fileName = file.name.toString().replace(/[|&;$%@"<>()+,]/g, '');
  const extension = getFileExtension(fileName.toUpperCase());
  if (!supportedImageTypes.includes(`.${extension}`)) {
    console.debug(...DBG, 'File is not allowed mimetype', extension);
    throw new Error('Invalid File Type');
  }
  // const response = await fetch(`${baseUrl}${documentUrl}`, {
  //   method: 'POST',
  //   headers: {
  //     ...buildHeaders(customerId, token),
  //     TenantId: workspaceId,
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     name: fileName,
  //     content: await toBase64(file),
  //     fileType: 'image',
  //   }),
  // });
  // if (response.status !== 201) {
  //   throw new Error('Error uploading datasource');
  // }
  return true;
};

export const getThumbnail = async (
  filename: string,
  tenant: string,
  customerId: string,
  token: string,
  width?: number,
  height?: number,
) => {
  const response = await fetch(`${renderingUrl}/documents/thumbnail`, {
    method: 'POST',
    headers: {
      ...buildHeaders(customerId, token),
      tenantId: tenant,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fileName: filename,
      graphicFormat: 'png',
      ...(height && { height: height }),
      ...(width && { width: width }),
    }),
  });

  if (response.status === 503) {
    throw new CommunicationError(
      i18n.t('api:thumbnail-unavailable'),
      response.status,
    );
  }

  if (response.status !== 201) {
    const responseText = await response.text();
    const errorCode = JSON.parse(responseText).errorCode;

    if (errorCode === 2002)
      throw new CommunicationError(
        "Sorry, this image is temporarily unavailable. We're working on it.",
        response.status,
      );

    throw new CommunicationError(responseText, response.status);
  }

  const json = await response.json();

  if (json.previewError) {
    throw new CommunicationError(json.previewError, response.status);
  }

  return json.image;
};

export const getLocalDataSource = async (
  id: string,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<FileInfoType> =>
  parseDatasource(await getDocument(customerId, workspaceId, token, id));

export const getLocalData = async (
  //TODO basically nothing which calls this is safe. Need to wrap all calls in try catch.
  name: string,
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<String[][]> =>
  await getDocumentContent(customerId, workspaceId, token, name);

export const getCloudDatabase = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<string> => {
  let content = await getDocumentContent(
    customerId,
    workspaceId,
    token,
    DB_FILE_NAME,
  );
  return atob(content['content']); //TODO error catching
};

export const setCloudDatabase = async (
  content: string,
  customerId: string,
  workspaceId: any,
  token: string,
) => {
  return setDocumentContent(
    customerId,
    workspaceId,
    token,
    DB_FILE_NAME,
    btoa(content),
  );
};

export const logMessage = async (
  severity: Severity,
  category: string,
  message: string,
  customerId: string,
  token: string,
) => {
  try {
    await getZsbpApp(customerId, token).portal.info.logMessage(
      severity,
      category,
      message,
      getClientId(),
    );
    return true;
  } catch (e: any) {
    return false;
  }
};

export const getAnnouncements = async (
  customerId: string,
  token: string,
): Promise<BannerMessage[]> =>
  (
    await getZsbpApp(customerId, token).portal.info.getBannerMessages()
  ).getBannermessagesList();

export type RawResponse = {
  id: number;
  contentLength: number;
  createDateTime: string;
  description: string;
  fileType: string;
  metadata: {
    key: string;
    value: string;
  }[];
  modifiedBy: string;
  modifiedDateTime: string;
  name: string;
  printedDateTime: string;
  tenantExternalId: string;
  thumbnail: string;
  widthInch: number;
  heightInch: number;
  orientation: string;
};
export const getSystemTemplates = async (
  customerId: string,
  workspaceId: any,
  token: string,
): Promise<TemplatePage> => {
  const urlSearchparams = new URLSearchParams();
  urlSearchparams.append('Metadata', `{Category:System}`);
  urlSearchparams.append('FileType', 'Label');

  const response = await fetch(
    `${documentFilesUrl}?${urlSearchparams}`,
    getBody(customerId, TemplateCategory.Internal, token),
  );
  return await formatTemplates(response, customerId, workspaceId, token);
};

export const setPrinterSettings = async (
  settings: PrinterSettings,
  customerId: string,
  token: string,
): Promise<boolean> =>
  await getZsbpApp(customerId, token).portal.printer.setSettings(settings);

export const getPortalVersion = async (
  customerId: string,
  token: string,
): Promise<string> => {
  const version = await getZsbpApp(customerId, token).portal.getVersion();
  return version.getPortalversionstring();
};

export const getNicelabelVersions = async (
  customerId: string,
  token: string,
): Promise<{}> => {
  const version = await getZsbpApp(
    customerId,
    token,
  ).portal.getNicelabelServiceVersions();
  return {
    renderingversion: version.getRenderingversion(),
    designerversion: version.getDesignerversion(),
    documentversion: version.getDocumentversion(),
    provisioningversion: version.getProvisioningversion(),
  };
};
