import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import {
  getAllPrinters,
  getAllPrintersLoading,
} from 'ducks/printers/selectors';
import { NavigationTabBar } from 'components/common/NavigationTabBar';
import { PageTitle } from 'v2/components/common';

import { Workspace } from './Workspace';
import Printers from './Printers';
import { Notifications } from './Notifications';
import { About } from './About';
import Page from '../Page';
import { HOME_PAGE_PATH } from 'utils/config';

export default function GeneralSettings() {
  const { t } = useTranslation('other');
  const printersLoading = useReduxSelector(getAllPrintersLoading);
  const printers = useReduxSelector(getAllPrinters);
  const replaceAction = useReduxAction(replace);
  const pushAction = useReduxAction(push);
  const location = useLocation();

  const generatePageNames = () => {
    return [
      t('other:workspace'),
      t('other:printers'),
      t('other:notifications'),
      t('other:about-and-legal'),
    ];
  };

  const applyPage = (val) => {
    if (val === 0) {
      replaceAction('/settings');
    } else if (val === 1) {
      replaceAction('/settings/printers');
    } else if (val === 2) {
      replaceAction('/settings/notifications');
    } else {
      replaceAction('/settings/about');
    }
  };

  const getSelected = () => {
    if (location.pathname === '/settings') {
      return 0;
    } else if (location.pathname === '/settings/printers') {
      return 1;
    } else if (location.pathname === '/settings/notifications') {
      return 2;
    } else if (location.pathname === '/settings/about') {
      return 3;
    }
    return null;
  };

  return (
    <Page>
      {!printers || printersLoading ? (
        <Loader visible={true} />
      ) : (
        <>
          <PageTitle
            onBackClick={() => pushAction(HOME_PAGE_PATH)}
            title={t('settings:settings-title')}
          />
          <NavigationTabBar
            data-testid="settings-nav-bar"
            selected={getSelected()}
            pageNames={generatePageNames()}
            setPage={applyPage}
          />
          <Switch>
            <Route path="/settings" component={Workspace} exact />
            <Route path="/settings/printers" component={Printers} exact />
            <Route
              path="/settings/notifications"
              component={Notifications}
              exact
            />
            <Route path="/settings/about" component={About} exact />
          </Switch>
        </>
      )}
    </Page>
  );
}
