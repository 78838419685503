import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Loader } from 'components';

export default function LoadingScreen() {
  const { t } = useTranslation('login');

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Box mb={10}>
        <Text fontSize={24}>{t('loading') + '...'}</Text>
      </Box>
      <Loader visible={true} />
    </Flex>
  );
}
