import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  forwardRef,
  cloneElement,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { Box, SMBThemeProvider } from 'components';
import { PositionProps, SizeProps } from 'styled-system';
import { usePortal, getEl, setRef } from 'hooks/usePortal';
import getScrollbarSize from 'utils/getScrollbarSize';
import gsap, { Power2 } from 'gsap';

export const CLASS_BACKDROP = 'backdrop';
export const CLASS_MODAL_BOX = 'modal-box';

type NobackDropProp = {
  noBackdrop: boolean;
  className?: String;
  $zIndex?: number;
};

type isAnimatedBackdropProp = {
  isAnimatedBackdrop?: boolean;
};

const ModalBox = styled(Box).attrs({
  tabIndex: -1,
  className: CLASS_MODAL_BOX,
})<NobackDropProp>`
  box-shadow: ${({ noBackdrop }) =>
    !noBackdrop ? '0 0 5px 0 rgba(0, 0, 0, 0.2)' : 'unset'};
  position: absolute;
  opacity: 0;
  background-color: ${({ noBackdrop, theme }) =>
    !noBackdrop ? theme.white : 'unset'}

  &:focus {
    outline: none;
  }
`;

export const ModalContainer = styled(Box).attrs({
  role: 'presentation',
})<NobackDropProp>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: ${({ noBackdrop }) => (noBackdrop ? 'unset' : 0)};
  right: ${({ noBackdrop }) => (noBackdrop ? 'unset' : 0)};
  ${({ noBackdrop }) =>
    noBackdrop &&
    `
  width: 100%;
  `}
  z-index: ${({ $zIndex = 1001 }) => $zIndex};
`;

const Backdrop = styled(Box).attrs({
  ariaHidden: true,
})<isAnimatedBackdropProp>`
  visibility: ${({ isAnimatedBackdrop }) =>
    isAnimatedBackdrop ? 'hidden' : 'visible'};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({ isAnimatedBackdrop }) => (isAnimatedBackdrop ? '0' : '1')};
  background-color: ${({ theme }) => theme.withOpacity(theme.black, 0.5)};
`;

type PortalProps = {
  container: any;
  disabled?: boolean;
  children: any;
  isAnimatedBackdrop: boolean;
  onRendered?: () => void;
};

const showModalAndBackdrop = (isAnimatedBackdrop) => {
  let backdropElements = document.getElementsByClassName(CLASS_BACKDROP);
  let backdropElementsCount = backdropElements.length;

  if (backdropElementsCount > 0) {
    let lastBackdropElement = backdropElements[backdropElementsCount - 1];

    gsap.to(lastBackdropElement, {
      visibility: 'visible',
      opacity: 1,
      duration: isAnimatedBackdrop ? 0.1 : 0,
    });
  }

  if (document.getElementsByClassName(CLASS_MODAL_BOX).length > 0) {
    gsap.fromTo(
      `.${CLASS_MODAL_BOX}`,
      { x: '-50%', y: '-50%' },
      {
        visibility: 'visible',
        x: '-50%',
        y: '-50%',
        opacity: 1,
        scale: 1,
        duration: 0.4,
        ease: Power2.easeInOut,
      },
    );
  }
};

const Portal = forwardRef(
  (
    {
      container,
      disabled = false,
      children,
      isAnimatedBackdrop,
      onRendered,
    }: PortalProps,
    ref,
  ) => {
    const handleRef = useMemo(
      () => (refValue: any) => {
        setRef(children.ref, refValue);
        setRef(ref, refValue);
      },
      [ref, children.ref],
    );

    const [mountNode, setMountNode] = useState<Element | null>(null);

    useLayoutEffect(() => {
      showModalAndBackdrop(isAnimatedBackdrop);
    });

    useEffect(() => {
      if (!disabled) {
        setMountNode(getEl(container) || document.body);
      }
    }, [disabled, container]);

    useEffect(() => {
      if (mountNode && !disabled) {
        setRef(ref, mountNode);
        return () => {
          setRef(ref, null);
        };
      }

      return undefined;
    }, [ref, mountNode, disabled]);

    useEffect(() => {
      if (onRendered && (mountNode || disabled)) {
        onRendered();
      }
    }, [onRendered, mountNode, disabled]);

    if (disabled) {
      React.Children.only(children);
      return cloneElement(children, {
        ref: handleRef,
      });
    }
    return mountNode ? createPortal(children, mountNode) : mountNode;
  },
);

type Props = PositionProps &
  SizeProps & {
    anchor: any;
    open: boolean;
    children?: React.ReactChildren;
    noBackdrop: boolean;
    onRendered?: () => void;
    onClose?: () => void;
    ariaLabel?: string;
  };

const ModalPopover = forwardRef<Props, any>(
  (
    {
      anchor,
      open,
      children,
      onRendered,
      onClose,
      noBackdrop = false,
      isAnimatedBackdrop = true,
      ariaLabel,
      ...props
    },
    ref,
  ) => {
    const container = usePortal(anchor);
    const mountNodeRef = useRef(null);

    const handleMounted = () => {
      if (document.body) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${getScrollbarSize()}px`;
      }
    };

    const handleClose = useCallback(() => {
      if (document.body) {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
      }
    }, []);

    const handleOpen = useCallback(() => {
      handleMounted();
    }, []);

    React.useEffect(() => {
      return () => {
        handleClose();
      };
    }, [handleClose]);

    React.useEffect(() => {
      if (open) {
        handleOpen();
      } else {
        handleClose();
      }
    }, [open, handleClose, handleOpen]);

    const handlePortalRef = useCallback(
      (node) => {
        mountNodeRef.current = node;

        if (!node) {
          return;
        }

        if (onRendered) {
          onRendered();
        }

        if (open) {
          handleMounted();
        }
      },
      [onRendered, open],
    );

    return (
      open && (
        <Portal
          ref={handlePortalRef}
          disabled={false}
          container={container}
          isAnimatedBackdrop={isAnimatedBackdrop}
        >
          <SMBThemeProvider>
            <ModalContainer
              noBackdrop={noBackdrop}
              aria-label={ariaLabel}
              $zIndex={props.zIndex}
            >
              {noBackdrop ? null : (
                <Backdrop
                  data-testid={'backdrop'}
                  onClick={onClose}
                  className={CLASS_BACKDROP}
                  isAnimatedBackdrop={isAnimatedBackdrop}
                />
              )}
              {/* <FocusTrap ref={ref} open={open}> */}
              <ModalBox noBackdrop={noBackdrop} {...props}>
                {children}
              </ModalBox>
              {/* </FocusTrap> */}
            </ModalContainer>
          </SMBThemeProvider>
        </Portal>
      )
    );
  },
);

export default ModalPopover;
