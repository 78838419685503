import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';

import styled from 'styled-components';

import { State } from 'zsbpsdk/src/customer';

import { Modal, Flex, Box, Button } from 'components';
import { BoldText, ErrorText, Text } from 'v2/components/common';
import { useReduxAction } from 'hooks';

import { actions as authActions } from '../../ducks/auth/actions';

type CustomDeleteAccountModalProps = {
  handleModalClose: () => void;
  open: boolean;
};

const StyledBoldText = styled(BoldText)`
  font-weight: 500;
`;

const DeleteAccountConfirmationModal = ({
  handleModalClose,
  open,
}: CustomDeleteAccountModalProps) => {
  const { instance } = useMsal();
  const { t } = useTranslation('settings');
  const ref = useRef(null);

  const confirmDeleteAccount = useReduxAction(
    authActions.DELETE_ACCOUNT.request,
  );

  const handleDeleteAccountModalClose = () => {
    handleModalClose();
  };

  const handleSubmit = () => {
    confirmDeleteAccount({ state: State.CONFIRM, msalInstance: instance });

    handleDeleteAccountModalClose();
  };

  return (
    <Modal
      data-testid="delete-account-confirmation-modal"
      ref={ref}
      clickOutside={false}
      onClose={handleDeleteAccountModalClose}
      small
      open={open}
      isAnimatedBackdrop={false}
    >
      <Box mb={8}>
        <ErrorText variant="h2" data-testid="delete-account-confirmation-text">{t('settings:delete-account')}</ErrorText>
      </Box>

      <Trans
        i18nKey="settings:delete-account-confirmation"
        components={{
          text: <Text variant="label" lineHeight="24px" data-testid="delete-account-confirmation"></Text>,
          strong: <StyledBoldText variant="label" as={'span'}></StyledBoldText>,
        }}
      />

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="delete-account-confirmation-cancel-button"
          onClick={handleDeleteAccountModalClose}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>

        <Button
          data-testid="confirm-deletion-button"
          variant={'destructive'}
          type={'submit'}
          onClick={handleSubmit}
        >
          {t('common:delete')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteAccountConfirmationModal;
