const BASE_DUCK_PREFIX = 'SMB_PRINT_DIALOG_';

export const types = {
  OPEN: BASE_DUCK_PREFIX + 'OPEN',
  CLOSE: BASE_DUCK_PREFIX + 'CLOSE',
};

export const actions = {
  OPEN: (templateFilename: string) => ({
    type: types.OPEN,
    payload: { templateFilename },
  }),
  CLOSE: () => ({ type: types.CLOSE }),
};
