import {
  createReducer,
  FETCH_DATA_INITIAL_STATE,
  generateFetchDataReducer,
  generatePostDataReducer,
  POST_DATA_INITIAL_STATE
} from 'ducks/utils';
import { combineReducers } from 'redux';
import { types } from './actions';

const printerTools = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.PRINTER_TOOLS),
});
const links = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.LINKS),
});
const ldaVersions = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.LDA_VERSIONS),
});
const whoAmI = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.WHO_AM_I, true)
})
export default combineReducers({
  printerTools,
  links,
  ldaVersions,
  whoAmI
});
