import { createSelector } from 'reselect';
import { UNKNOWN } from '../../utils/unitFormatter';

const allPreferences = (state: any) => state.preferences.all.data;
const unit = (state: any) => state.preferences.all.data?.units;
const dithering = (state: any) => state.preferences.all.data?.dithering;
const allPreferencesLoading = (state: any) => state.preferences.all.loading;

export const getAllPreferences = createSelector(
  [allPreferences],
  (preferences) => preferences,
);

export const getPrintjobs = createSelector(
  [allPreferences],
  (preferences) => preferences?.notificationpreference?.printjobs,
);

export const getCartridgelow = createSelector(
  [allPreferences],
  (preferences) => preferences?.notificationpreference?.cartridgelow,
);

export const getAllPreferencesLoading = createSelector(
  [allPreferencesLoading],
  (loading) => loading,
);

export const getUnit = createSelector([unit], (unit) =>
  unit ? unit : UNKNOWN,
);
export const getDithering = createSelector(
  [dithering],
  (dithering) => dithering,
);
