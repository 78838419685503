import React from 'react';
import { Flex } from 'components';
import { Line } from './Line';
import {
  PrinterImageBackground,
  PrinterImage,
  PrinterName,
} from './PrnterWithText.styles';

const PrinterWithText = ({ name, src, alt, width }) => {
  return (
    <>
      <Flex flexDirection="row" justifyContent={'left'} alignItems={'center'}>
        <PrinterImageBackground>
          <PrinterImage src={src} alt={alt} role={'img'} />
        </PrinterImageBackground>
        <PrinterName mx={20} style={{ overflowWrap: 'break-word' }}>
          {name}
        </PrinterName>
      </Flex>
      <Line />
    </>
  );
};

export default PrinterWithText;
