import React from 'react';
import {
  IconButton,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { Icon } from 'components';

const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 24,
}))``;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '32px 56px 16px 40px',
    },
    content: {
      padding: '4px 40px 32px 40px',
    },
    closeButton: {
      position: 'absolute',
      right: 4,
      top: 4,
    },
    paper: {
      borderRadius: 8,
    },
  }),
);

type DialogProps = MuiDialogProps & {
  header?: React.ReactElement;
  onClose?: () => void;
};

export default function Dialog({
  header,
  open,
  onClose,
  children,
  ...props
}: DialogProps) {
  const styles = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      {...props}
      classes={{
        paper: styles.paper,
      }}
    >
      <MuiDialogTitle disableTypography className={styles.title}>
        {header}
        {onClose && (
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </MuiDialogTitle>

      <MuiDialogContent className={styles.content}>{children}</MuiDialogContent>
    </MuiDialog>
  );
}
