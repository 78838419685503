import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  filterDisabledBannerMessages,
  getUnexpiredBannerMessages,
  sortBannerMessages,
  updateBannerMessageLocalStorage,
} from '../../utils/bannerMessages';
import { CustomerInfoType } from 'zsbpsdk/src/customer';

interface AnnouncementsType {
  bannerMessages?: BannerMessage[];
}

const initialState: AnnouncementsType = {};

const NAMESPACE = 'SMB_ANNOUNCEMENT';

type BannerMessagePayload = {
  bannerMessages: BannerMessage[];
  customer: CustomerInfoType;
};
export const AnnouncementSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setBannerMessages: (
      state: AnnouncementsType,
      action: PayloadAction<BannerMessagePayload | undefined>,
    ) => {
      const bannerMessages = action.payload?.bannerMessages;
      const customer = action.payload?.customer;
      if (bannerMessages != null && customer != null) {
        updateBannerMessageLocalStorage(bannerMessages, customer);
      }
      return { bannerMessages };
    },
    clearBannerMessages: () => initialState,
  },
});

const { actions, reducer } = AnnouncementSlice;
export const { setBannerMessages, clearBannerMessages } = actions;
export default reducer;

const selectBannerMessages = (state: RootState) =>
  state.announcement.bannerMessages;
const selectCustomer = (state: RootState) => state.auth.customer.data;
export const selectFilteredBannerMessages = createSelector(
  selectBannerMessages,
  selectCustomer,
  (initialBannerMessages, customer) => {
    if (initialBannerMessages != null && customer != null) {
      const unexpiredBannerMessages = getUnexpiredBannerMessages(
        initialBannerMessages,
        customer,
      );
      const enabledBannerMessages = filterDisabledBannerMessages(
        unexpiredBannerMessages,
        customer,
      );
      return sortBannerMessages(enabledBannerMessages);
    } else {
      return [];
    }
  },
);

export const fetchBannerMessages = createAction(
  `${NAMESPACE}/fetchBannerMessages`,
);
