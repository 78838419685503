import {
  createReducer,
  generateFetchDataReducer,
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer,
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer,
  generatePostDataReducerPrint,
} from 'ducks/utils';
import { types } from './actions';
import { combineReducers } from 'redux';
import { FETCH_DATA_INITIAL_STATE } from 'ducks/utils';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates/index';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

// issue here updating state as reducer?
const updateTemplate = (state: any, action: { payload: TemplateType }) => {
  const templatePage: TemplatePage = state?.data;
  const fileDataList: TemplateType[] = templatePage?.fileDataList;
  const newTemplate: TemplateType = { ...action.payload };
  const oldFilename: string | undefined = newTemplate.oldFilename;
  if (oldFilename) {
    delete newTemplate.oldFilename;
    return {
      ...state,
      data: {
        ...templatePage,
        fileDataList: fileDataList?.map((template: any) =>
          template.filename === oldFilename ? newTemplate : template,
        ),
      },
    };
  }
  return {
    ...state,
    data: {
      ...templatePage,
      fileDataList: fileDataList?.map((template: any) =>
        template.filename === newTemplate.filename ? newTemplate : template,
      ),
    },
  };
};

const updateFilteredTemplate = (
  state: any,
  action: { payload: TemplateType },
) => {
  const templatePage: TemplatePage = state?.data;
  const fileDataList: TemplateType[] = templatePage?.fileDataList;
  if (fileDataList) {
    const oldFilename: string | undefined = action?.payload?.oldFilename;
    if (oldFilename) {
      const newTemplates: TemplateType[] = fileDataList.filter(
        (t: TemplateType) => t?.filename !== oldFilename,
      );
      const newTemplate: TemplateType = { ...action.payload };
      delete newTemplate.oldFilename;
      return {
        ...state,
        data: {
          ...templatePage,
          fileDataList: addTemplateToSortedList(
            newTemplates,
            newTemplate,
            state?.params?.sort,
          ),
        },
      };
    } else {
      return {
        ...state,
        data: {
          ...templatePage,
          fileDataList: fileDataList.map((template: TemplateType) =>
            template.filename === action.payload.filename
              ? {
                  ...action.payload,
                }
              : template,
          ),
        },
      };
    }
  }
  return state;
};

const addTemplateToSortedList = (
  templates: TemplateType[],
  newTemplate: TemplateType,
  sort: string,
): TemplateType[] => {
  const oldLength = templates?.length;
  for (const [index, element] of templates.entries()) {
    if (sort === 'desc') {
      if (element.filename.toLowerCase() < newTemplate.filename.toLowerCase()) {
        templates.splice(index, 0, newTemplate);
        break;
      }
    } else {
      if (element.filename.toLowerCase() > newTemplate.filename.toLowerCase()) {
        templates.splice(index, 0, newTemplate);
        break;
      }
    }
  }
  if (oldLength === templates.length) templates.push(newTemplate);
  return templates;
};

const addTemplates = (state: any, action: { payload: TemplatePage }) => {
  const templatePage: TemplatePage = action?.payload;
  if (!state.data) {
    return { ...state, data: templatePage };
  }
  const fileDataList: TemplateType[] = state?.data?.fileDataList;
  let filteredFileDataList: TemplateType[] = templatePage?.fileDataList?.filter(
    (v: TemplateType, i: number) =>
      fileDataList?.findIndex((t: TemplateType) => t.id === v.id) === -1,
  );

  return {
    ...state,
    data: {
      ...templatePage,
      fileDataList: [...fileDataList, ...filteredFileDataList],
    },
  };
};

const removeTemplate = (
  state: any,
  { payload }: PayloadAction<TemplateType>,
) => {
  const { filename } = payload;

  const fileDataList: TemplateType[] = state?.data?.fileDataList?.filter(
    (template: TemplateType) => template.filename !== filename,
  );

  return {
    ...state,
    data: {
      fileDataList,
    },
  };
};

const removeMultipleTemplates = (
  state: any,
  { payload }: PayloadAction<TemplateType[]>,
) => {
  const successfulTemplates = payload;

  const fileDataList: TemplateType[] = state?.data?.fileDataList?.filter(
    (template: TemplateType) =>
      !successfulTemplates.find(
        (t: TemplateType) => t.filename === template.filename,
      ),
  );

  return {
    ...state,
    data: {
      fileDataList,
    },
  };
};

const getFilteredHash = (
  state: RootState,
  { payload }: PayloadAction<TemplatePage>,
) => {
  return {
    ...state,
    data: payload,
  };
};

const all = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ALL),
  [types.ALL.SUCCESS]: addTemplates,
  [types.UPDATE.SUCCESS]: updateTemplate,
  [types.RECENT.SUCCESS]: addTemplates,
  [types.FILTERED.SUCCESS]: addTemplates,
  [types.THUMBNAIL.SUCCESS]: updateTemplate,
  [types.REMOVE.SUCCESS]: removeTemplate,
  [types.REMOVE_MULTIPLE.SUCCESS]: removeMultipleTemplates,
});

const current = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.CURRENT),
});

const recent = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.RECENT),
  [types.UPDATE.SUCCESS]: updateTemplate,
});

const filtered = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.FILTERED),
  // TO-DO probably will use only ALL reducer when detach MOCK-UP
  [types.UPDATE.SUCCESS]: updateFilteredTemplate,
  [types.THUMBNAIL.SUCCESS]: updateFilteredTemplate,
});

const zebraFiltered = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ZEBRA_FILTERED),
});
const zebraFilteredAllSizes = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ZEBRA_FILTERED_ALL_SIZES),
});
const myDesignSizes = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.MY_DESIGN_ALL_SIZES),
});
const zebraAll = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ZEBRA_ALL),
  [types.ZEBRA_FILTERED.SUCCESS]: addTemplates,
});

const selected = createReducer(SELECTED_DATA_INITIAL_STATE, {
  ...generateSelectDataReducer(types.SELECTED),
  [types.UPDATE.SUCCESS]: (state, event) => {
    return state?.data?.id === event?.payload?.id
      ? { ...state, data: event?.payload }
      : state;
  },
});

const selectedData = createReducer(
  SELECTED_DATA_INITIAL_STATE,
  generateFetchDataReducer(types.SELECTED_DATA),
);

const enteredData = createReducer(
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer(types.ENTERED_DATA),
);

// reducer to recieve action type and object - update store
const update = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.UPDATE),
);

const remove = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.REMOVE),
});

const removeMultiple = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.REMOVE_MULTIPLE),
});

const print = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducerPrint(types.PRINT),
});

const testPrint = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.TEST_PRINT),
});

const templateDialog = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.TEMPLATE_DIALOG),
});

const labelPreview = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.LABEL_PREVIEW),
});
const duplicate = createReducer(
  POST_DATA_INITIAL_STATE,
  generatePostDataReducer(types.DUPLICATE),
);

const thumbnail = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.THUMBNAIL),
});

const filteredTemplatesHash = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.FILTERED_HASH),
  [types.REMOVE.SUCCESS]: getFilteredHash,
});

export default combineReducers({
  all,
  current,
  recent,
  filtered,
  filteredTemplatesHash,
  selected,
  update,
  remove,
  removeMultiple,
  print,
  testPrint,
  templateDialog,
  labelPreview,
  duplicate,
  zebraFiltered,
  zebraFilteredAllSizes,
  myDesignSizes,
  zebraAll,
  selectedData,
  enteredData,
  thumbnail,
});
