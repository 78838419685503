import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
  useParams,
  generatePath,
} from 'react-router-dom';
import { replace } from 'connected-react-router';

import { useReduxAction, useReduxSelector } from 'hooks';
import { getAllPrinters, getSelectedPrinter } from 'ducks/printers/selectors';
import { actions as printerActions } from 'ducks/printers/actions';
import { NavigationTabBar } from 'components/common/NavigationTabBar';

import { General } from './General';
import { WiFi } from './WiFi';

export const PrinterSettings = () => {
  const { t } = useTranslation('other');
  const replaceAction = useReduxAction(replace);
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const { printerId } = useParams<{ printerId: string }>();
  const setSelectedPrinter = useReduxAction(printerActions.SELECTED.set);
  const selectedPrinter = useReduxSelector(getSelectedPrinter);
  const printers = useReduxSelector(getAllPrinters);
  const selectedTab = location.pathname === url ? 0 : 1;

  //Redirect to the list of printers if we try to load a printer
  //that is not currently in the list of printers
  useEffect(() => {
    const requestedPrinter = printers.find(
      ({ uniqueId }) => uniqueId === printerId,
    );

    if (!requestedPrinter) {
      replaceAction(generatePath(path));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printers, printerId]);

  useEffect(() => {
    setSelectedPrinter(printerId);
  }, [setSelectedPrinter, printerId]);

  if (!selectedPrinter.uniqueId) {
    return null;
  }

  return (
    <>
      <NavigationTabBar
        data-testid="printer-settings-nav-bar"
        selected={selectedTab}
        pageNames={[t('other:customize'), t('other:network-connection')]}
        setPage={(index: number) =>
          replaceAction(`${url}${index > 0 ? '/wifi' : ''}`)
        }
      />
      <Switch>
        <Route path={path} component={General} exact />
        <Route path={`${path}/wifi`} component={WiFi} exact />
        <Redirect to={url} />
      </Switch>
    </>
  );
};
