import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { ThemeContext } from 'styled-components/macro';
import { CustomerInfoType } from 'zsbpsdk/src/customer';

import {
  MenuItem,
  Menu,
  PageWrap,
  Profile,
  Flex,
  Box,
  Text,
  Icon,
} from 'components';
import Workgroup2 from 'components/common/navigation/Workgroup2';
import ExpandableHelpList from 'components/common/navigation/ExpandableHelpList';
import { useReduxSelector, useReduxAction, useWindowDimensions } from 'hooks';
import { actions as SideBar } from 'ducks/sideBar/actions';
import { getCustomer } from 'ducks/auth/selectors';
import { isSidebarCollapsed } from 'ducks/sideBar/selectors';
import { device } from 'utils/mediaQueries';
import {
  APPLICATION_VERSION,
  COMMON_DESIGNS_PATH,
  HOME_PAGE_PATH,
  MY_APPS_PATH,
  MY_DATA_SOURCES_PATH,
  MY_DESIGNS_PATH,
  MY_NOTIFICATIONS_PATH,
  MY_PRINTER_SETTINGS_PATH,
} from 'utils/config';
import { getAssetPath } from 'utils/files';
import { getLDAVersions } from 'ducks/info/selectors';
import { actions as infoActions } from 'ducks/info/actions';

import PageHeader from './Header';
import {
  MenuScroller,
  MobileMenuBar,
  ProfileFlex,
  RotationBox,
  ScrollAssistance,
  StickyLogo,
  StyledSidenav,
  Wrapper,
} from './index.styles';
import EditWorkspaceModal from '../../../pages/overview/components/EditWorkspaceModal';
import ChangeThemeModal from '../../../pages/overview/components/ChangeThemeModal';

const Page = ({ children, noBackground = false, ...props }: any) => {
  const { t } = useTranslation('menu');
  const theme = useContext(ThemeContext);

  const user: CustomerInfoType = useReduxSelector(getCustomer);
  const locationPath = useReduxSelector(
    (state: any) => state.router.location?.pathname,
  );

  const { height } = useWindowDimensions();
  const SidebarScroll = useRef();
  const [open, setOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [showToolTip, setShowToolTip] = useState(true);
  const [collapsedNav, setCollapsedNav] = useState<boolean>(false); //new
  const [scrollOnSidebar, setScrollOnSidebar] = useState<boolean>(false);
  const isDesktop = useMediaQuery({ query: device.desktop });
  const isTablet = useMediaQuery({ query: device.tablet });

  const setReduxCollapsed = useReduxAction(SideBar.COLLAPSED);
  const requestLDAVersions = useReduxAction(infoActions.LDA_VERSIONS.request);

  const sideBarCollapsedState = useReduxSelector(isSidebarCollapsed);
  const versions = useReduxSelector(getLDAVersions);

  const hasSideBar = !(locationPath.indexOf('workspaces') > -1);

  useEffect(() => {
    setReduxCollapsed(collapsedNav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedNav]);

  useEffect(() => {
    if (sideBarCollapsedState !== collapsedNav) {
      setCollapsedNav(sideBarCollapsedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideBarCollapsedState]);

  useEffect(() => {
    if (height < 900) {
      setScrollOnSidebar(true);
    }
    if (height < 760) {
      setShowToolTip(false);
    } else {
      setShowToolTip(true);
    }
  }, [height]);

  useEffect(() => {
    if (locationPath.indexOf('designer') > -1) {
      setCollapsedNav(true);
    }
  }, [locationPath]);

  useEffect(() => {
    if (!versions) {
      requestLDAVersions();
    }

    console.debug(
      '%c LDAVERSIONS INFO ',
      'background:black;color:white;',
      versions,
    );
  }, [versions, requestLDAVersions, locationPath]);

  const editWorkspaceModal = useRef();
  const changeThemeModal = useRef(null);
  const driversModalRef = useRef(null);

  const hasHeader =
    !(locationPath.indexOf('workspaces') > -1) &&
    !(locationPath.indexOf('designer') > -1) &&
    !(locationPath.indexOf('notifications') > -1);

  let path = '';
  let variant = '';
  let isVideo = false;

  if (!user) {
    return <></>;
  }
  return (
    <Wrapper
      variant={variant}
      noBackground={noBackground}
      isVideo={isVideo}
      path={path}
    >
      {isTablet && (
        <>
          {hasHeader && (
            <PageHeader
              variant={variant}
              noBackground={noBackground}
              pathname={locationPath}
            />
          )}

          {hasSideBar && (
            <MobileMenuBar>
              <Box mr={20}>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                  }}
                >
                  <Icon icon="hamburger-menu" size="25" color={theme.white} />
                </a>
              </Box>

              <Text
                fontSize={16}
                lineHeight={'24px'}
                fontWeight={'normal'}
                letterSpacing={0.4}
                color={theme.white}
              >
                {t('menu:home')}
              </Text>
            </MobileMenuBar>
          )}
        </>
      )}
      {hasSideBar && (
        <>
          <StyledSidenav
            collapsedState={collapsedNav}
            scrollOnSidebar={scrollOnSidebar}
            open={isDesktop ? true : open}
            setOpen={setOpen}
          >
            <ProfileFlex alignItems="center" justifyContent="space-between">
              <Profile
                variant="medium"
                collapsedState={collapsedNav}
                showToolTip={showToolTip}
              ></Profile>
            </ProfileFlex>

            <Workgroup2
              collapsedState={collapsedNav}
              showToolTip={showToolTip}
              editWorkspaceModal={editWorkspaceModal}
              changeThemeModal={changeThemeModal}
            />
            <MenuScroller
              collapsedState={collapsedNav}
              scrollOnSidebar={scrollOnSidebar}
              ref={SidebarScroll}
            >
              {helpOpen && <ScrollAssistance />}
              <Menu collapsed={collapsedNav}>
                <MenuItem
                  data-testid="overview-nav"
                  iconName="overview"
                  href={HOME_PAGE_PATH}
                  selected={locationPath === HOME_PAGE_PATH}
                  collapsed={collapsedNav}
                  tooltipText={t('menu:home')}
                >
                  {t('menu:home')}
                </MenuItem>

                <MenuItem
                  data-testid="label-templates-common-nav"
                  iconName="common-designs"
                  href={COMMON_DESIGNS_PATH}
                  selected={locationPath === COMMON_DESIGNS_PATH}
                  collapsed={collapsedNav}
                  tooltipText={t('menu:common-designs')}
                >
                  {t('menu:common-designs')}
                </MenuItem>

                <MenuItem
                  data-testid="label-templates-nav"
                  iconName="my-designs"
                  href={MY_DESIGNS_PATH}
                  collapsed={collapsedNav}
                  selected={locationPath === MY_DESIGNS_PATH}
                  tooltipText={t('menu:my-designs')}
                >
                  {t('menu:my-designs')}
                </MenuItem>

                <MenuItem
                  data-testid="label-datasources-nav"
                  iconName="data-sources"
                  href={MY_DATA_SOURCES_PATH}
                  selected={locationPath === MY_DATA_SOURCES_PATH}
                  collapsed={collapsedNav}
                  tooltipText={t('menu:my-data')}
                >
                  {t('menu:my-data')}
                </MenuItem>

                {/* <Menu>
            <MenuItem
              data-testid="label-datasources-nav"
              iconName="data-sources"
              href="/datasources"
              collapsedState={collapsedNav}
              text={t('my-data')}
            >
              {t('my-data')}
            </MenuItem> */}

                {/* <MenuItem iconName="shop" href="/shop">
              Shop
            </MenuItem> */}
                {/* </Menu> */}
                <div ref={driversModalRef}>
                  <MenuItem
                    data-testid="label-driver-and-apps-nav"
                    collapsed={collapsedNav}
                    iconName="apps"
                    href={MY_APPS_PATH}
                    selected={locationPath === MY_APPS_PATH}
                    tooltipText={t('menu:driver-and-apps')}
                  >
                    {t('menu:driver-and-apps')}
                  </MenuItem>
                </div>
                <MenuItem
                  data-testid="label-notifications-nav"
                  collapsed={collapsedNav}
                  iconName="notifications-new"
                  href={MY_NOTIFICATIONS_PATH}
                  selected={locationPath === MY_NOTIFICATIONS_PATH}
                  tooltipText={t('menu:notifications')}
                >
                  {t('menu:notifications')}
                </MenuItem>
                <MenuItem
                  data-testid="label-settings-nav"
                  iconName="settings"
                  href={MY_PRINTER_SETTINGS_PATH}
                  selected={locationPath === MY_PRINTER_SETTINGS_PATH}
                  collapsed={collapsedNav}
                  tooltipText={t('menu:printer-settings')}
                >
                  {t('menu:printer-settings')}
                </MenuItem>
                {/* {collapsedNav && (
                  <MenuItem
                    collapsedState={collapsedNav}
                    iconName="support"
                    text={t('help')}
                    onClick={openHelpfromCollapsed}
                  >
                    {t('help')}
                  </MenuItem>
                )} */}
                {/* </Menu> */}
                {/* <Menu> */}
              </Menu>
              <Menu>
                <ExpandableHelpList
                  collapsedState={collapsedNav}
                  setHelpOpen={setHelpOpen}
                  showToolTip={showToolTip}
                />
              </Menu>

              {/* {process.env.NODE_ENV === 'development' && (
                <Menu collapsedState={collapsedNav}>
                  <MenuItem
                    data-testid="components-nav"
                    iconName="apps"
                    href="/components"
                    collapsedState={collapsedNav}
                    text={t('Components')}
                  >
                    {t('Components')}
                  </MenuItem>
                </Menu>
              )} */}

              {/* <Box mb={108} /> */}
              {helpOpen && <ScrollAssistance />}
            </MenuScroller>
            <StickyLogo collapsedState={collapsedNav}>
              <Flex
                flexDirection={collapsedNav ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mt={20} ml={collapsedNav ? 3 : 8}>
                  <img
                    src={getAssetPath(
                      !collapsedNav
                        ? '/logo-zebra.svg'
                        : '/zebra-collapsed-menu-logo.svg',
                    )}
                    alt={t('menu:zebra-logo')}
                  />
                </Box>
                {/* <Menu>
                  <ExpandableHelpList
                    collapsedState={collapsedNav}
                    setHelpOpen={setHelpOpen}
                    showToolTip={showToolTip}
                  />
                </Menu> */}
                {/* {process.env.NODE_ENV === 'development' && (
                  <Menu collapsedState={collapsedNav}>
                    <MenuItem
                      data-testid="components-nav"
                      iconName="apps"
                      href="/components"
                      collapsedState={collapsedNav}
                      text={t('Components')}
                    >
                      {t('Components')}
                    </MenuItem>
                  </Menu>
                )} */}

                <RotationBox
                  collapsedNav={collapsedNav}
                  onClick={() => setCollapsedNav((collapsed) => !collapsed)}
                >
                  {/* TODO: Why are these doubling? */}
                  <Icon
                    data-testid="sidebar-collapse-icon"
                    icon="sidebar-close"
                    size="40"
                  />
                </RotationBox>
              </Flex>
              {!collapsedNav && (
                <Flex>
                  <Box width={500} m={7} ml={11}>
                    <Text
                      data-testid="app-version"
                      fontSize={12}
                      lineHeight={'16px'}
                      fontWeight={'400'}
                      letterSpacing={0.4}
                      color={theme.neutral.darkest}
                    >
                      {t('menu:version', { version: APPLICATION_VERSION })}
                    </Text>
                  </Box>
                </Flex>
              )}
            </StickyLogo>
          </StyledSidenav>
        </>
      )}
      {isDesktop && (
        <>
          <PageWrap
            collapsedState={collapsedNav}
            className="page-content"
            hasSideBar={hasSideBar}
          >
            {hasHeader && (
              <PageHeader
                noBackground={noBackground}
                isVideo={isVideo}
                path={path}
                pathname={locationPath}
                collapsedState={collapsedNav}
              />
            )}
            {children}
          </PageWrap>
        </>
      )}
      {isTablet && (
        <>
          <Flex justifyContent="center">{children}</Flex>
        </>
      )}
      <EditWorkspaceModal ref={editWorkspaceModal} />
      <ChangeThemeModal ref={changeThemeModal} />
    </Wrapper>
  );
};

export default Page;
