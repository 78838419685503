import { generateDataTypes, generateDataActions } from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_INFO_';

export const types = {
  PRINTER_TOOLS: generateDataTypes(BASE_DUCK_PREFIX + 'PRINTER_TOOLS'),
  WHO_AM_I: generateDataTypes(BASE_DUCK_PREFIX + 'WHO_AM_I'),
  LINKS: generateDataTypes(BASE_DUCK_PREFIX + 'LINKS'),
  LDA_VERSIONS: generateDataTypes(BASE_DUCK_PREFIX + 'LDA_VERSIONS'),
};

export const actions = {
  PRINTER_TOOLS: generateDataActions(types.PRINTER_TOOLS),
  WHO_AM_I: generateDataActions(types.WHO_AM_I),
  LINKS: generateDataActions(types.LINKS),
  LDA_VERSIONS: generateDataActions(types.LDA_VERSIONS),
};
