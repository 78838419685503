import React from 'react';
import { Box, Input } from 'components';
import { useOnClickOutside } from 'hooks';
import {
  DropdownProps,
  HeaderWrap,
  MenuDropdown,
  DropdownStatusIcon,
} from './Dropdown.styles';
import DropdownOption from './DropdownOption';
import { useTranslation } from 'react-i18next';

const Dropdown = ({
  disabled = false,
  options = [],
  onChange,
  value,
  showTop = false,
  placeholder,
  noOutline = false,
  tableCellDesign = false,
  noOptionsValue,
  name,
  hasError,
  ...props
}: DropdownProps) => {
  const { t } = useTranslation('components');

  const [isOpen, setIsOpen] = React.useState(false);
  const wrapRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapRef, () => setIsOpen(false));

  const handleHeaderClick = (e: React.MouseEvent) => {
    !disabled && !isOpen ? setIsOpen(true) : setIsOpen(false);
  };

  const handleSelect = React.useCallback(
    (e: string) => {
      onChange && onChange(e);
      setIsOpen(false);
    },
    [onChange],
  );

  return (
    <Box
      ref={wrapRef}
      position={'relative'}
      width="100%"
      display="block"
      {...props}
    >
      <HeaderWrap
        data-testid="dropdown"
        open={isOpen}
        onClick={handleHeaderClick}
        disabled={disabled}
        noOutline={noOutline}
        tableCellDesign={tableCellDesign}
        title={options.find((o) => o.value === value)?.label ?? value}
        hasError={hasError}
      >
        <Input
          key={value}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          defaultValue={options.find((o) => o.value === value)?.label ?? value}
          iconEnd={<DropdownStatusIcon open={isOpen} />}
          readonly
          hasError={true}
        />
      </HeaderWrap>
      <MenuDropdown
        data-testid="template-filter-list"
        open={isOpen}
        showTop={showTop}
      >
        {!options || !options.length ? (
          <DropdownOption key={'no-options'} onClick={(e) => null}>
            {noOptionsValue ?? (placeholder || t('components:no-options'))}
          </DropdownOption>
        ) : (
          !disabled &&
          options.map(({ value: optionValue, label, logo }, i: number) => (
            <DropdownOption
              key={i}
              value={optionValue}
              onClick={(e) => handleSelect(optionValue)}
              selected={value === optionValue && value !== ''}
              data-testid="dropdown-option"
              logo={logo}
              title={label}
            >
              {label}
            </DropdownOption>
          ))
        )}
      </MenuDropdown>
    </Box>
  );
};

export default Dropdown;
