import { TemplateType } from 'zsbpsdk/src/templates';

export const getSuccessfulTemplates = (
  templateList: TemplateType[],
  responseList: boolean[],
): (TemplateType | null)[] => {
  const filteredTemplateList = templateList
    .map((t: TemplateType, idx: number) => {
      if (!responseList[idx]) return null;

      return t;
    })
    .filter((t: TemplateType | null) => t);

  return filteredTemplateList;
};
