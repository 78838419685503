import { getSandboxId } from './config';

export const getAssetPath = (filePath: string) => {
  if (!filePath.startsWith('/')) {
    filePath = `/${filePath}`;
  }

  const sandboxId = getSandboxId();
  return sandboxId ? `/${sandboxId}${filePath}` : filePath;
};

export const getFileExtension = (fileName: string): string => {
  const ext = fileName.split('.').pop();
  return ext ? ext : '-';
};

export function downloadFile(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = url;
  link.click();
}
