import styled, { DefaultTheme } from 'styled-components';
import { Icon, Flex } from 'components';

export type RightLeftType = 'left' | 'right';

export interface ArrowProps {
  direction: RightLeftType;
  disabled?: boolean;
}

export const RightArrow = styled(Icon).attrs(
  ({ theme }: { theme: DefaultTheme }) => ({
    icon: 'chevron-right',
    color: theme.neutral.base,
    size: 40,
  }),
)``;

export const LeftArrow = styled(Icon).attrs(
  ({ theme }: { theme: DefaultTheme }) => ({
    icon: 'chevron-left',
    color: theme.neutral.base,
    size: 40,
  }),
)``;

export const ArrowWrapper = styled(Flex)<ArrowProps>`
  position: absolute;
  top: 40%;
  justify-content: center;
  ${({ direction }) => (direction === 'right' ? `right: 0` : `left: 0`)};
  cursor: pointer;
  align-items: center;

  &[disabled] {
    pointer-events: none;

    svg path {
      fill: ${({ theme }) => theme.neutral.lighter} !important;
    }
  }
`;
