import * as React from 'react';
import { Flex } from 'components';
import {
  TypographyView,
  IconsView,
  ButtonsView,
  InputsView,
  CardsView,
  AvatarsView,
  ModalsView,
  CarouselView,
  SlidersView,
  ToastsView,
  BannersView,
  HeadersView,
  TableView,
  SearchesView,
  DropdownsView,
  NotificationsView,
  LabelRangePicker,
  TooltipsView,
} from './components';

export const Wrap = (): any => {
  return (
    <Flex flexDirection="column">
      <SearchesView />
      <TypographyView />
      <IconsView />
      <ButtonsView />
      <InputsView />
      <CardsView />
      <AvatarsView />
      <ModalsView />
      <CarouselView />
      <SlidersView />
      <BannersView />
      <HeadersView />
      <ToastsView />
      <TableView />
      <DropdownsView />
      <NotificationsView />
      <LabelRangePicker />
      <TooltipsView />
    </Flex>
  );
};
