import React from 'react';
import styled from 'styled-components';
import { Icon, Flex, Tooltip, Loader } from 'components';
import { ThemeContext } from 'styled-components/macro';
import { useReduxAction, useReduxSelector } from 'hooks';
import { push } from 'connected-react-router';
import { getCustomer, getUser, getAccountLoading } from 'ducks/auth/selectors';
import { VariantType } from 'components/lib/avatar/Avatar.styles';
import Avatar from '../../lib/avatar/Avatar';

const UsernameContainer = styled.span`
  margin-left: 16px;
  text-overflow: ellipsis;
  min-width: 141px;
  max-width: 141px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.4px;
  color: #fdfeff;
`;
const StyledSpan = styled.span`
  min-width: 157px;
  max-width: 157px;
`;

const StyledAvatar = styled(Avatar)`
  top: 0px;
  border-radius: 8px;
  border: none;
`;

const StyledDiv = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding-left: 5px;
`;

const StyledFlex = styled(Flex)<any>`
  cursor: ${({ collapsed }) => (collapsed ? 'pointer' : '')};
  overflow: hidden;
`;

const Profile = ({
  variant,
  collapsedState,
  showToolTip,
  noIcon,
}: {
  variant: VariantType;
  collapsedState?: any;
  showToolTip?: any;
  noIcon?: any;
}) => {
  const theme = React.useContext(ThemeContext);
  const pushAction = useReduxAction(push);
  const user = useReduxSelector(getUser);
  const customer = useReduxSelector(getCustomer);
  const accountLoading = useReduxSelector(getAccountLoading);

  const getUserName = () => {
    let str = '';

    if (user) {
      if (user.firstName) {
        str += user.firstName + ' ';
      }
      if (user.lastName) {
        str += user.lastName;
      }
    } else {
      str = customer.firstName;
    }

    return str;
  };

  return (
    <>
      <StyledFlex
        onClick={() => (collapsedState ? pushAction('/user-settings') : null)}
        flexDirection="row"
        alignItems="center"
        collapsed={collapsedState}
      >
        <Tooltip
          enabled={collapsedState && showToolTip}
          content={'User Settings'}
          direction="right"
          mt="-18px"
        >
          {!accountLoading && (
            <StyledAvatar
              variant={variant}
              firstName={user?.firstName ?? customer.firstName}
              lastName={user?.lastName}
              img={customer?.avatar !== 'null' && customer?.avatar}
            />
          )}
        </Tooltip>
        {!collapsedState &&
          (accountLoading ? (
            <StyledSpan>
              <Loader visible={true} height={'22px'} width={'22px'} />
            </StyledSpan>
          ) : (
            <UsernameContainer
              data-testid="sidebar-username"
            >
              {getUserName()}
            </UsernameContainer>
          ))}
        {!collapsedState && !noIcon && (
          <StyledDiv
            data-testid="user-settings"
            onClick={() => pushAction('/user-settings')}
          >
            <Icon icon="edit" size={'20px'} color={theme.white} />
          </StyledDiv>
        )}
      </StyledFlex>
    </>
  );
};

export default Profile;
