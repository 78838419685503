const KEY_PREFIX = 'Notification-log-for-';

const getSpecificCustomerKey = (customerId: string) => {
  return KEY_PREFIX + customerId;
};

export function getNotifications(customerId: string) {
  try {
    return JSON.parse(
      localStorage.getItem(getSpecificCustomerKey(customerId)) || '[]',
    );
  } catch (e) {
    return [];
  }
}

export function setNotifications(customerId: string, notifications: any[]) {
  localStorage.setItem(
    getSpecificCustomerKey(customerId),
    JSON.stringify(notifications),
  );
}

export function addNotification(notification: any, customerId: string) {
  const notifications = getNotifications(customerId);

  //Add the notification if it's not already added
  if (
    !notifications.find(({ uniqueId }) => uniqueId === notification.uniqueId)
  ) {
    setNotifications(customerId, [notification, ...notifications]);
  }
}

export function saveNotification(notification: any, customerId: string) {
  const notifications = getNotifications(customerId);
  const notificationToSave = notifications.find(
    ({ uniqueId }) => uniqueId === notification.uniqueId,
  );

  if (notificationToSave) {
    Object.assign(notificationToSave, notification);
    setNotifications(customerId, notifications);
  }
}

export function deleteNotification(notificationId: string, customerId: string) {
  const notifications = getNotifications(customerId);

  setNotifications(
    customerId,
    notifications.filter(({ uniqueId }) => uniqueId !== notificationId),
  );
}
