import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { Box, Button, Flex } from 'components';
import { Text } from 'v2/components/common';
import { useModal } from 'hooks';
import NewLabelModal from 'pages/templates/components/NewLabelModal';
import { COMMON_DESIGNS_PATH } from 'utils/config';

const StyledBrowseDesignsButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))`
  margin-right: ${({ theme }) => theme.px(12)};
`;

const StyledCreateDesignButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))``;

const NoTemplatesLayout = () => {
  const { t } = useTranslation();
  const newLabelModalRef = useRef(null);
  const { open: openNewLabelModal } = useModal(newLabelModalRef);
  const history = useHistory();

  const handleOnClick = () => {
    history.push( COMMON_DESIGNS_PATH, { from: window.location.pathname } as { from: string }); 
  };
  
  return (
    <Flex
      mt={16}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Box data-testid="no-data-designs">
        <Text variant="h2">{t('templates:no-data-designs')}</Text>
      </Box>

      <Box mt={4}>
        <Text>{t('templates:get-started')}</Text>
      </Box>

      <Flex mt={16}>
        <StyledBrowseDesignsButton
          data-testid="browse-common-design-button"
          icon="right"
          iconName="common-designs"
          onClick={() => handleOnClick()}
        >
          {t('templates:browse-common-designs')}
        </StyledBrowseDesignsButton>

        <StyledCreateDesignButton
          data-testid="create-label-design-button"
          icon="right"
          iconName="add"
          variant="primary"
          onClick={openNewLabelModal}
          iconSize={16}
          className="workspace-button"
        >
          {t('templates:create-new-design')}
        </StyledCreateDesignButton>
      </Flex>

      <NewLabelModal ref={newLabelModalRef} />
    </Flex>
  );
};

export default NoTemplatesLayout;
