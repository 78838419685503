import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';

const StickyFooterWrap = styled.div<{ isSticky: boolean }>`
  transition: box-shadow 0.3s;
  box-shadow: none;
  z-index: 5;
  position: sticky;
  bottom: 0;

  ${({ isSticky, theme }) =>
    isSticky &&
    `
        transition: box-shadow 0.3s;   
        &::before {
            position: absolute;
            content: '';
            height: 100%;
            transition: box-shadow 0.3s;
            box-shadow: 0px -20px 16px -16px rgba(73, 80, 94, 0.16);
            background: ${theme.white};
          }
    `}
`;

const FooterContent = styled.div`
  padding: 20px 0;
  background-color: ${({ theme }) => theme.white};
`;

export const StickyFooter = ({ children }: PropsWithChildren<ReactNode>) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(true);

  const handleScroll = (): void => {
    const hasReachedSticky = stickyRef.current
      ? document.documentElement.offsetHeight - stickyRef.current.offsetTop >
        stickyRef.current.offsetHeight
      : false;

    setIsSticky(hasReachedSticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StickyFooterWrap
      ref={stickyRef}
      isSticky={isSticky}
      data-testid="sticky-footer"
    >
      {children && <FooterContent>{children}</FooterContent>}
    </StickyFooterWrap>
  );
};

export default StickyFooter;
