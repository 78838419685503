import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Box, Flex, ToggleSwitch } from 'components';
import { useModal, useReduxSelector, useReduxAction } from 'hooks';
import { feedOnHeadCloseData, getSelectedPrinter } from 'ducks/printers/selectors';
import { useTranslation } from 'react-i18next';
import {
  actions as printersActions,
  FeedOnHeadClosePayload,
} from 'ducks/printers/actions';
import { StyledInfoIcon } from './FeedOnHeadCloseSetting.styles';
import FeedOnHeadCloseInfoModal from './FeedOnHeadCloseInfoModal';
import { PrinterSettings } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  convertHeadClosedActionToString,
  isFeedOnHeadCloseEnabled,
} from 'utils/feedOnHeadClose';

const FeedOnHeadCloseSetting = () => {
  const { t } = useTranslation(['common', 'overview']);

  const printer = useReduxSelector(getSelectedPrinter);
  const uniqueId = printer.uniqueId;
  const feedOnHeadClosePayload: FeedOnHeadClosePayload = useReduxSelector(feedOnHeadCloseData);
  const headClosedAction = feedOnHeadClosePayload?.[uniqueId]?.headClosedAction;
  const setPrinterSettings = useReduxAction(
    printersActions.SET_SETTINGS.request,
  );
  const refreshFeedOnHeadClose = useReduxAction(
    printersActions.FEED_ON_HEAD_CLOSE.request,
  );
  const infoModalRef = useRef(null);
  const infoModalHook = useModal(infoModalRef);

  const [allowDisableFeedOnHeadClose, setAllowDisableFeedOnHeadClose] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultCheckedUseFeedOnHeadClose = printer.feedOnHeadClose === 'feed';

  useEffect(() => {
    setIsLoading(feedOnHeadClosePayload?.[uniqueId]?.loading !== false);
  }, [feedOnHeadClosePayload, uniqueId]);

  useEffect(() => {
    if (uniqueId) {
      refreshFeedOnHeadClose({ uniqueId });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [uniqueId]);

  const updatePrinterFeedOnHeadClose = useCallback(
    (isEnabled: boolean) => {
      const printerSettings: PrinterSettings = new PrinterSettings();
      const headClosedAction = isEnabled
        ? PrinterSettings.HeadClosedAction.FEED
        : PrinterSettings.HeadClosedAction.NO_MOTION;
      printerSettings.setHeadclosedaction(headClosedAction);
      printerSettings.setPrinteruniqueid(uniqueId);
      setPrinterSettings({ printerSettings });
    },
    [setPrinterSettings, uniqueId],
  );

  const handleInfoButtonClick = useCallback(() => {
    setAllowDisableFeedOnHeadClose(false);
    infoModalHook.open?.();
  }, [infoModalHook]);

  const handleFeedOnHeadCloseSwitch = useCallback(
    (isEnabled: boolean) => {
      if (!isEnabled) {
        setAllowDisableFeedOnHeadClose(true);
        infoModalHook.open?.();
        return;
      }

      updatePrinterFeedOnHeadClose(isEnabled);
    },
    [updatePrinterFeedOnHeadClose, infoModalHook],
  );

  return (
    <>
      <Box mt={25}>
        <Flex width="100%" flexDirection={'column'}>
          <ToggleSwitch
            data-testid="feed-on-head-close-switch"
            disabled={!printer?.status?.isOnline || isLoading}
            value={convertHeadClosedActionToString(headClosedAction)}
            label={t('overview:printer.feed-on-head-close')}
            defaultChecked={
              headClosedAction === undefined
                ? defaultCheckedUseFeedOnHeadClose
                : isFeedOnHeadCloseEnabled(headClosedAction)
            }
            isLoading={isLoading}
            onChange={(e) => {
              handleFeedOnHeadCloseSwitch(e.target.checked);
            }}
            greyLabel={true}
            componentNextToText={
              <StyledInfoIcon
                data-testid="feed-on-head-close-setting-info-button"
                ml={'8px'}
                mb={'-6px'}
                onClick={handleInfoButtonClick}
              />
            }
          />
        </Flex>
      </Box>
      <FeedOnHeadCloseInfoModal
        modalRef={infoModalRef}
        showDisableButton={allowDisableFeedOnHeadClose}
        onDisableClick={() => {
          updatePrinterFeedOnHeadClose(false);
        }}
      />
    </>
  );
};

export default FeedOnHeadCloseSetting;
