import { BannerMessage, Preferences } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { CustomerInfoType } from 'zsbpsdk/src/customer/index';
import { DEFAULT_PREFERENCES_LANGUAGE } from './i18n';

const LOCAL_STORAGE_KEY_PREFIX = 'bannerMessageId';
const LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX = 'bannerMessageId-expirable';
const EXPIRING_BANNER_MESSAGE_DISPLAY_LIMIT = 3;
const EXPIRING_BANNER_MESSAGE_TYPES = [
  BannerMessage.BannerMessageType.NEW_FEATURES,
];

export const getBannerMessageKey = (
  bm: BannerMessage,
  customer: CustomerInfoType,
) => `${LOCAL_STORAGE_KEY_PREFIX}-${bm.getId()}-${customer.id}`;

export const getBannerMessageSettingKey = (
  type: BannerMessage.BannerMessageType,
  customer: CustomerInfoType,
) => `bannerMessageType-${type}-${customer.id}`;

export const getBannerMessageExpirableKey = (
  bannerID: number,
  customer: CustomerInfoType,
) => `${LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX}-${bannerID}-${customer.id}`;

const getExpiredBannerMessageKeys = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) =>  
  bannerMessages
    .filter((bm) => EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()))
    .map((bm) => getBannerMessageExpirableKey(bm.getId(), customer))
    .filter((key) => {
      const bannerMessage = localStorage.getItem(key);
      const parsedBannerInfo = JSON.parse(bannerMessage || '{}');
      const displayCount: number = parseInt(parsedBannerInfo.loginCounter);

      return displayCount >= EXPIRING_BANNER_MESSAGE_DISPLAY_LIMIT;
   });

export const getUnexpiredBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const expiredBannerMessageKeys = getExpiredBannerMessageKeys(
    bannerMessages,
    customer,
  );

  return bannerMessages.filter(
    (bm) =>
      !expiredBannerMessageKeys.includes(getBannerMessageExpirableKey(bm.getId(), customer)),
  );
};

const getEnabledBannerMessageKeys = (
  customer: CustomerInfoType,
): BannerMessage.BannerMessageType[] => {
  const localStorageKeys = Object.keys(localStorage);
  return Object.keys(BannerMessage.BannerMessageType)
    .filter((type) => !isNaN(Number(BannerMessage.BannerMessageType[type])))
    .map(
      (type) =>
        BannerMessage.BannerMessageType[
          type as keyof typeof BannerMessage.BannerMessageType
        ],
    )
    .filter((type) =>
      type != null &&
      localStorageKeys.includes(getBannerMessageSettingKey(type, customer))
        ? localStorage.getItem(getBannerMessageSettingKey(type, customer)) ===
          'true'
        : true,
    );
};

const getEnabledBannerMessageTypes = (customer: CustomerInfoType) => {
  return Object.entries(BannerMessage.BannerMessageType)
    .map(([_, value]) => value)
    .filter((value) =>
      getEnabledBannerMessageKeys(customer).includes(
        value as BannerMessage.BannerMessageType,
      ),
    );
};

export const filterDisabledBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const enabledTypes = getEnabledBannerMessageTypes(customer);
  return bannerMessages.filter((bm) => enabledTypes.includes(bm.getType()));
};

export const sortBannerMessages = (bannerMessages: BannerMessage[]) =>
  bannerMessages.sort((a, b) => {
    const createdA = a.getCreated();
    const createdB = b.getCreated();
    if (a.getType() === b.getType() && createdA != null && createdB != null) {
      return createdB.getSeconds() - createdA.getSeconds();
    } else {
      return a.getType() - b.getType();
    }
});

const initializeBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  bannerMessages
    .map((bm) => getBannerMessageKey(bm, customer))
    .filter((key) => localStorage.getItem(key) == null)
    .forEach((key) => localStorage.setItem(key, String(0)));

  const enabledExpiringBannerMessages = bannerMessages.filter((bm) =>
    EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()),
  );

  const bannerInfo = JSON.stringify({
    loginCounter: 0,
    displayedToUser: 'false',
  });
  enabledExpiringBannerMessages
    .map((bm) => getBannerMessageExpirableKey(bm.getId(), customer))
    .filter((key) => localStorage.getItem(key) == null)
    .forEach((key) => localStorage.setItem(key, bannerInfo));
};

export const incrementBannerMessageDisplayCount = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const enabledBannerMessages = filterDisabledBannerMessages(
    bannerMessages,
    customer,
  );
  const enabledBannerMessageLocalStorageKeys = enabledBannerMessages.map((bm) =>
    getBannerMessageKey(bm, customer),
  );
  Object.entries(localStorage)
    .filter(([key]) => enabledBannerMessageLocalStorageKeys.includes(key))
    .forEach(([key, value]) => {
      localStorage.setItem(key, String(parseInt(value) + 1));
    });

  const enabledExpiringBannerMessages = enabledBannerMessages.filter((bm) =>
    EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()),
  );

  const bannerMessagesWithDisplayLimitKeys = enabledExpiringBannerMessages.map(
    (bm) => getBannerMessageExpirableKey(bm.getId(), customer),
  );

  Object.entries(localStorage)
    .filter(([key]) => bannerMessagesWithDisplayLimitKeys.includes(key))
    .forEach(([key, value]) => {
      const bannerInfoLocalStorage = localStorage.getItem(key);
      const parsedBannerInfo = JSON.parse(bannerInfoLocalStorage || '{}');

      if (parsedBannerInfo.displayedToUser === 'true') {
        const counterValue = String(parseInt(parsedBannerInfo.loginCounter));
        const bannerInfo = JSON.stringify({
          loginCounter: String(parseInt(counterValue) + 1),
          displayedToUser: 'false',
        });
        localStorage.setItem(key, bannerInfo);
      }
    });
};

const removeUnusedBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const bannerMessageKeys = bannerMessages.map((bm) =>
    getBannerMessageKey(bm, customer),
  );

  const bannerExpirableMessageKeys = bannerMessages.map((bm) =>
  getBannerMessageExpirableKey(bm.getId(), customer),
);
  const allBannerKeys = bannerMessageKeys.concat(bannerExpirableMessageKeys);

  Object.keys(localStorage)
    .filter((key) => key.startsWith(LOCAL_STORAGE_KEY_PREFIX))
    .filter((key) => key.includes(customer.id))
    .filter((key) => !key.startsWith(LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX))
    .filter((key) => !allBannerKeys.includes(key))
    .forEach((key) => localStorage.removeItem(key));
};

export const updateBannerMessageLocalStorage = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  initializeBannerMessages(bannerMessages, customer);
  removeUnusedBannerMessages(bannerMessages, customer);
};

export const getBannerMessageContentForLanguage = (
  bannerMessage: BannerMessage,
  language: Preferences.Language,
): BannerMessage.Content | undefined => {
  const contentList = bannerMessage.getContentList();
  const content = contentList.find((bm) => bm.getLanguage() === language);
  return content != null
    ? content
    : contentList.find(
        (bm) => bm.getLanguage() === DEFAULT_PREFERENCES_LANGUAGE,
      );
};

export const incrementExpirableBannerMessageInfo = (
  customer: CustomerInfoType,
  bannerID: number
) => {

  // This key gives us information if the user has actually seen the baner during a specific login.
  const bannerMessageExpirableKey = getBannerMessageExpirableKey(
    bannerID,
    customer,
  );

  if (bannerMessageExpirableKey) {
    const expirableBannerInfo = localStorage.getItem(bannerMessageExpirableKey);
    const parsedExpirableBannerInfo = JSON.parse(expirableBannerInfo || '{}');

    if (
      parsedExpirableBannerInfo.displayedToUser &&
      parsedExpirableBannerInfo.displayedToUser === 'false'
    ) {
      parsedExpirableBannerInfo.displayedToUser = 'true';
      localStorage.setItem(
        bannerMessageExpirableKey,
        JSON.stringify(parsedExpirableBannerInfo),
      );
    }
  }
};
