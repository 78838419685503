import styled from 'styled-components';
import { device } from 'utils/mediaQueries';
import { Flex, Sidenav, Box } from 'components';

export const Wrapper: any = styled.div`
  @media ${device.mobile} {
    padding-bottom: 0;
  }
  background-color: ${({ theme }) => theme.primary.background};
`;

export const MobileMenuBar = styled(Flex)`
  padding: 23px 20px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: ${({ theme }) => theme.primary.darkest};
`;

export const StyledSidenav = styled(Sidenav)<any>`
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;

export const MenuScroller = styled.div<any>`
  overflow-y: auto;
  height: calc(100% - 238px);
  scroll-snap-type: y proximity; //scroll assistance

  &::-webkit-scrollbar {
    ${(props) =>
      props.scrollOnSidebar === true && props.collapsedState === false
        ? ''
        : '  display:none'};
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.neutral.lighter}};
  }

  scrollbar-color: ${({ theme }) => theme.neutral.lighter}};
  scrollbar-width: ${(props) =>
    props.scrollOnSidebar === true && props.collapsedState === false
      ? 'thin'
      : 'none'};
`;

// TODO: Would need a prop to conditionally render this when expandableList is open
// ${props => (props.openHelp === true ? 'scroll-snap-align: end' : '')};
export const ScrollAssistance = styled.div<any>`
  scroll-snap-align: end;
`;

interface StickyLogoProps {
  collapsedState: boolean;
}

export const StickyLogo = styled.div<StickyLogoProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: ${(props) => (props.collapsedState ? '54px' : '240px')};
  background-color: ${({ theme }) => theme.white};
  transition: transform 0.3s;
  transition: width 0.6s ease-out !important;
  overflow: hidden;
`;

export const RotationBox = styled(Box).attrs(
  ({ collapsedNav }: { collapsedNav: boolean }) => ({
    mt: collapsedNav ? 0 : 4,
    mb: collapsedNav ? 8 : 0,
    ml: collapsedNav ? 3 : 7,
    mr: collapsedNav ? 0 : 8,
    pb: collapsedNav ? 20 : 0,
  }),
)<{ collapsedNav: boolean }>`
  cursor: pointer;
  &&& {
    transition: transform 0.6s ease-out !important;
  }
  transform: rotate(${({ collapsedNav }) => (collapsedNav ? 180 : 0)}deg);
`;

export const ProfileFlex = styled(Flex)`
  background: ${({ theme }) => theme.primary.darkest};
  padding: 16px 11px;
  max-height: 64px;
`;
