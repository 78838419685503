import React from 'react';
import { Flex, Text } from 'components';
import { FilesButtons } from 'pages/dataSources/components/FilesButtons';
import styled from 'styled-components';
import { getAllFilesData } from 'ducks/dataSources/selectors';
import { useReduxSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

const StartAddingFilesSection = styled(Text)`
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.neutral.base};
  margin-bottom: 24px;
`;

const SearchInFilesSection = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.neutral.base};
  margin-bottom: 8px;
`;

const ContentWrap = styled(Flex)`
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export default function NoFiles() {
  const { t } = useTranslation('dataSources');
  const files = useReduxSelector(getAllFilesData);

  return (
    <ContentWrap data-testid="no-files">
      <SearchInFilesSection>
        {files && files.length < 1 ? t('templates:no-data-files') : t('templates:no-results-files')}
      </SearchInFilesSection>
      {files && files.length < 1 ? (
        <>
          <StartAddingFilesSection>{t('dataSources:start-by-adding-files')}</StartAddingFilesSection>
          <FilesButtons />
        </>
      ) : (
        ''
      )}
    </ContentWrap>
  );
}
