/**
 * Portal message type enumeration. To be shared with the host application.
 */
export enum PortalMessageType {
  IsAvailableRequest = 'IsAvailable_Request',
  IsAvailableResponse = 'IsAvailable_Response',

  DesignerReadyRequest = 'LdaReady_Request',

  CloseDesignerRequest = 'Close_Request',

  OpenUploadDataSourceDialogRequest = 'UploadDataSource_Request',
  OpenUploadDataSourceDialogResponse = 'UploadDataSource_Response',

  OpenLinkDataSourceDialogRequest = 'LinkDataSource_Request',

  OpenUploadImageDialogRequest = 'UploadImage_Request',
  OpenUploadImageDialogResponse = 'UploadImage_Response',

  OpenLinkImageDialogRequest = 'LinkImage_Request',

  OpenGalleryImageDialogRequest = 'GetGalleryImage_Request',
  OpenGalleryImageDialogResponse = 'GetGalleryImage_Response',

  OpenCameraDialogRequest = 'CaptureImage_Request',
  OpenCameraDialogResponse = 'CaptureImage_Response',

  OpenPrintOptionsDialogRequest = 'OpenPrintOptions_Request',

  GetVersionRequest = 'GetVersion_Request',
  GetVersionResponse = 'GetVersion_Response',

  GetAuthorizationRequest = 'GetAuthorization_Request',
  GetAuthorizationResponse = 'GetAuthorization_Response',

  GetClientIdRequest = 'GetClientId_Request',
  GetClientIdResponse = 'GetClientId_Response',

  GetCustomerIdRequest = 'GetCustomerId_Request',
  GetCustomerIdResponse = 'GetCustomerId_Response',

  GetPrinterInfosRequest = 'GetPrinters_Request',
  GetPrinterInfosResponse = 'GetPrinters_Response',

  GetCustomerInfoRequest = 'GetCustomerInfo_Request',
  GetCustomerInfoResponse = 'GetCustomerInfo_Response',

  GetStockInfosRequest = 'GetLabelStocks_Request',
  GetStockInfosResponse = 'GetLabelStocks_Response',

  GetDatabaseFileListRequest = 'ListDataSources_Request',
  GetDatabaseFileListResponse = 'ListDataSources_Response',

  GetContactsSourcesRequest = 'ListContactsSources_Request',
  GetContactsSourcesResponse = 'ListContactsSources_Response',

  GetImageFileListRequest = 'ListImages_Request',
  GetImageFileListResponse = 'ListImages_Response',

  RequestContactsSourceMetaDataRequest = 'GetContactsColumns_Request',
  RequestContactsSourceMetaDataResponse = 'GetContactsColumns_Response',

  RequestFileMetaDataRequest = 'GetMetaData_Request',
  RequestFileMetaDataResponse = 'GetMetaData_Response',

  ShowToastRequest = 'Toast_Request',

  FileSelectedResponse = 'FileSelected_Response',

  // The following enum values beginning with 'Debug' are
  // used in lda-dev/index.html only, not in the LDA
  DebugCompressImageRequest = 'CompressImage_Request',
  DebugCompressImageResponse = 'CompressImage_Response',

  DebugGetPortalVersionRequest = 'GetPortalVersion_Request',
  DebugGetPortalVersionResponse = 'GetPortalVersion_Response',

  DebugGetWorkspacesRequest = 'GetWorkspaces_Request',
  DebugGetWorkspacesResponse = 'GetWorkspaces_Response',
}
