import * as React from 'react';
import { ComponentType } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import theme from 'utils/themes';
import { useReduxSelector } from 'hooks';
import { getCurrentWorkspace } from 'ducks/workspaces/selectors';

type Props = {
  /** components to which the theme will be provided */
  children: React.ReactNode;
};

const SMBThemeProvider: ComponentType<Props> = ({ children }: Props) => {
  const currentWorkspace = useReduxSelector(getCurrentWorkspace);
  return (
    <ThemeProvider theme={theme(currentWorkspace?.theme)} children={children} />
  );
};

export default SMBThemeProvider;
