import React, { useEffect, useState } from 'react';
import { useAppSelector, useReduxAction } from 'hooks';
import { getLinks } from 'ducks/info/selectors';
import { actions as infoActions } from 'ducks/info/actions';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { announcementHeight } from '../lib/announcement/Announcement.styles';
import { selectFilteredBannerMessages } from 'state/Announcement';
import {
  DEFAULT_PREFERENCES_LANGUAGE,
  getPreferencesLanguage,
} from '../../utils/i18n';

type ButtonContainerType = {
  top: string;
};
const ButtonContainer = styled.div<ButtonContainerType>`
  width: 189px;
  background: rgba(255, 255, 255, 0.7);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  right: 40px;
  position: absolute;
  top: ${({ top }) => top};
`;

const StyledLink = styled.a`
  justify-content: center;
  text-decoration: none;
  height: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.4px;
  border: none;
  white-space: nowrap;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary.base};
  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.primary.dark};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary.lighter};
  }
  &:focus,
  &:not:disabled {
    background-color: ${({ theme }) => theme.primary.dark};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary.base};
  }
`;

const BuyMore = () => {
  const { i18n, t } = useTranslation('components');

  const links = useAppSelector(getLinks);
  const requestLinks = useReduxAction(infoActions.LINKS.request);
  const filteredBannerMessages = useAppSelector(selectFilteredBannerMessages);

  const language = getPreferencesLanguage(i18n);
  const [top, setTop] = useState<string>(String(0));

  useEffect(() => {
    const hasBannerMessages =
      filteredBannerMessages.filter((bm) =>
        bm.getContentList().some((c) => {
          const contentLanguage = c.getLanguage();
          return (
            contentLanguage === language ||
            contentLanguage === DEFAULT_PREFERENCES_LANGUAGE
          );
        }),
      ).length > 0;
    setTop(hasBannerMessages ? announcementHeight : String(0));
  }, [filteredBannerMessages, language]);

  useEffect(() => {
    if (!links) {
      requestLinks();
    }
  }, [links, requestLinks]);

  return (
    <ButtonContainer top={top}>
      <StyledLink
        data-testid="buy-more-button"
        href={links?.get('shop')}
        target="_blank"
        rel="noopener"
      >
        {t('components:label.buy-more-labels')}
      </StyledLink>
    </ButtonContainer>
  );
};

export default BuyMore;
