import { StyledBox } from './PrinterStatusChip.styles';

const OfflineIcon = () => {
  return (
    <StyledBox>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7143 7.44688C16.1809 7.83021 16.8643 7.79688 17.2893 7.37188C17.7809 6.88021 17.7476 6.06354 17.2059 5.62187C14.2143 3.17187 10.3726 2.26354 6.74759 2.90521L8.90592 5.06354C11.3143 5.03854 13.7393 5.83021 15.7143 7.44688ZM13.8226 8.97188C13.1726 8.49688 12.4643 8.13854 11.7226 7.88854L14.1809 10.3469C14.3809 9.86354 14.2643 9.28854 13.8226 8.97188ZM10.6226 12.5219C9.60592 11.9969 8.38926 11.9969 7.36426 12.5219C6.87259 12.7802 6.78092 13.4552 7.17259 13.8469L8.39759 15.0719C8.72259 15.3969 9.24759 15.3969 9.57259 15.0719L10.7976 13.8469C11.2059 13.4552 11.1226 12.7802 10.6226 12.5219ZM15.3976 13.9135L2.43092 0.946875C2.10592 0.621875 1.58092 0.621875 1.25592 0.946875C0.930922 1.27188 0.930922 1.79688 1.25592 2.12188L3.20592 4.08021C2.36426 4.49688 1.54759 5.00521 0.797589 5.62187C0.255922 6.06354 0.222589 6.88021 0.714255 7.37188C1.13926 7.79688 1.81426 7.83854 2.27259 7.45521C3.10592 6.77188 4.02259 6.23854 4.98092 5.84688L6.83926 7.70521C5.89759 7.95521 4.99759 8.37188 4.18092 8.96354C3.60592 9.38021 3.57259 10.2219 4.07259 10.7219L4.08092 10.7302C4.48926 11.1385 5.13092 11.1802 5.60592 10.8385C6.59759 10.1385 7.75592 9.78854 8.91426 9.76354L14.2226 15.0719C14.5476 15.3969 15.0726 15.3969 15.3976 15.0719C15.7226 14.7635 15.7226 14.2385 15.3976 13.9135Z"
          fill="#929AA9"
        />
      </svg>
    </StyledBox>
  );
};

export default OfflineIcon;
