import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { device } from 'utils/mediaQueries';

//TODO: media query doesnt work from 1328 - 1340ish
// try using  screenwith > 1340 with a new selector

const Overlay: any = styled.div`
  position: absolute;
  top: -300px;
  left: 0;
  content: '';
  width: 200%;
  height: 300px;
  background-color: ${({ theme }) => theme.primary.background};
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.3s linear;
  ${({ screenWidth }: any) =>
    screenWidth > 0 &&
    `
    @media ${device.tablet}{
      width: ${screenWidth}px ;
    }

    @media ${device.desktop}{
      width: calc(${screenWidth}px - 240px) ;
    }
    @media ${device.desktopMiddle}{
      margin-left: 0px;
    }
    @media ${device.desktopWide}{
      margin-left: calc(((${screenWidth}px - 1340px) / 2) * -1);
    }
    `}

  ${({ scrollPosition }: any) =>
    scrollPosition > 0 &&
    `
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      -webkit-perspective: 1000;
      transition: opacity .01s linear;
      opacity: ${scrollPosition / 300}
  `}
`;

export const HeaderOverlay = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (position <= 300) {
      setScrollPosition(position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay scrollPosition={scrollPosition} screenWidth={props.screenWidth} />
  );
};
