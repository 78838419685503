import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { getPreferencesLanguage } from '../../../utils/i18n';
import AnnouncementModal from '../../../pages/overview/components/AnnouncementModal';
import { useAppDispatch, useAppSelector, useModal } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import {
  fetchBannerMessages,
  selectFilteredBannerMessages,
} from '../../../state/Announcement';
import Announcement from './Announcement';

type AnnouncementType = {
  show: boolean;
};

const Announcements = ({ show }: AnnouncementType) => {
  const { i18n } = useTranslation('common');

  const [modalBannerMessageContent, setModalBannerMessageContent] = useState<
    BannerMessage.Content | undefined
  >(undefined);
  const filteredBannerMessages = useAppSelector(selectFilteredBannerMessages);

  const announcementModalRef = useRef(null);
  const announcementModal = useModal(announcementModalRef);

  const handleModalOpen = useCallback(
    (bannerMessageContent) => {
      setModalBannerMessageContent(bannerMessageContent);
      announcementModal.open();
    },
    [setModalBannerMessageContent, announcementModal],
  );

  const appDispatch = useAppDispatch();
  useEffect(() => {
    appDispatch(fetchBannerMessages());
  }, [appDispatch]);

  return (
    <>
      {show && filteredBannerMessages != null ? (
        <Announcement
          language={getPreferencesLanguage(i18n)}
          bannerMessages={filteredBannerMessages}
          onModalOpen={handleModalOpen}
        />
      ) : null}
      <AnnouncementModal
        ref={announcementModalRef}
        bannerMessageContent={modalBannerMessageContent}
      />
    </>
  );
};

export default Announcements;
