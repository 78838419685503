import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ToastLinkType,
  ToastVariantType,
} from 'components/lib/toast/Toast.styles';
import type { RootState } from '../../store';
import { TIME_OUT_TOAST } from '../../utils/config';

// Define a type for the slice state
interface ToastType {
  open: boolean;
  title?: string;
  message?: string;
  variant?: ToastVariantType;
  dismissTime?: number;
  link?: ToastLinkType;
}

export type ToastOptions = Omit<ToastType, 'open'>;

const initialState: ToastType = {
  open: false,
};

export const NAMESPACE = 'SMB_TOAST';

export const ToastSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ToastOptions>) =>
      (state = { ...action.payload, open: true }),
    close: (state) => (state = initialState),
  },
});

export const { open, close } = ToastSlice.actions;

const getOptions = (titleOrOptions: string | ToastOptions): ToastOptions =>
  typeof titleOrOptions === 'string'
    ? { title: titleOrOptions }
    : titleOrOptions;

export const openToast = (options: ToastOptions) => {
  const { dismissTime = TIME_OUT_TOAST, ...remainingOptions } = options;
  return ToastSlice.actions.open({
    dismissTime,
    ...remainingOptions,
  });
};

const openToastWithVariant = (
  variant: ToastVariantType,
  titleOrOptions: string | ToastOptions,
) => openToast({ variant, ...getOptions(titleOrOptions) });

export const openBasicToast = (
  titleOrOptions: string | Omit<ToastOptions, 'variant'>,
) => openToastWithVariant('basic', titleOrOptions);

export const openErrorToast = (
  titleOrOptions: string | Omit<ToastOptions, 'variant'>,
) => openToastWithVariant('error', titleOrOptions);

export const openWarningToast = (
  titleOrOptions: string | Omit<ToastOptions, 'variant'>,
) => openToastWithVariant('warning', titleOrOptions);

export const openSuccessToast = (
  titleOrOptions: string | Omit<ToastOptions, 'variant'>,
) => openToastWithVariant('success', titleOrOptions);

// Other code such as selectors can use the imported `RootState` type
export const selectToastState = (state: RootState) => state.toast;

export default ToastSlice.reducer;
