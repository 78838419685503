import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Text } from 'components';
import { NoContentImage } from 'v2/components/common';
import { useReduxAction } from 'hooks';
import { CHOOSE_A_TEMPLATE, CREATE_NEW_DESIGN } from 'utils/config';
import styled from 'styled-components';

const StyledBrowseDesignsButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))`
  margin-right: ${({ theme }) => theme.px(12)};
`;

const StyledCreateDesignButton = styled(Button).attrs(({ theme }) => ({
  iconColor: theme.white,
}))``;

const StyledHintText = styled(Text)`
  color: ${({ theme }) => theme.textColors.med};
  display: block;
  max-width: 512px;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin: ${({ theme }) => theme.px(12, 0, 14)};
`;

const NoRecentlyPrinted = () => {
  const { t } = useTranslation('templates');

  const pushAction = useReduxAction(push);

  return (
    <Flex
      mt={16}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      flexDirection={'column'}
      data-testid="no-recently-printed-labels"
    >
      <Box>
        <img src={NoContentImage} alt={t('templates:no-templates-alt')} />
      </Box>

      <Box mt={4}>
        <StyledHintText>
          {t('templates:no-recently-printed-labels')}
        </StyledHintText>
      </Box>

      <Flex mt={16}>
        <StyledBrowseDesignsButton
          data-testid="browse-common-design-button"
          icon="right"
          iconName="common-designs"
          onClick={() => pushAction(CHOOSE_A_TEMPLATE)}
        >
          {t('templates:browse-common-designs')}
        </StyledBrowseDesignsButton>

        <StyledCreateDesignButton
          data-testid="create-label-design-button"
          icon="right"
          iconName="add"
          variant="primary"
          onClick={() => pushAction(CREATE_NEW_DESIGN)}
          iconSize={16}
          className="workspace-button"
        >
          {t('templates:create-new-design')}
        </StyledCreateDesignButton>
      </Flex>
    </Flex>
  );
};

export default NoRecentlyPrinted;
