import React, { useEffect } from 'react';

import { Flex, Loader, Icon } from 'components';
import Text from 'v2/components/common/Text/Text';
import styled, { ThemeContext } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getAllPreferencesLoading } from 'ducks/preferences/selectors';
import { getLinks, getLDAVersions } from 'ducks/info/selectors';
import { actions as infoActions } from 'ducks/info/actions';

export const Title = styled(Text)`
  line-height: 36px;
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.textColors.med};
  line-height: 21px;
`;

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  width: max-content;
  color: ${({ theme }) => theme.primary.base};
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const About = () => {
  const { t } = useTranslation(['notifications', 'utils', 'other']);
  const theme = React.useContext(ThemeContext);
  const isLoadingPrefs = useReduxSelector(getAllPreferencesLoading);
  const links = useReduxSelector(getLinks);
  const requestLinks = useReduxAction(infoActions.LINKS.request);
  const versions = useReduxSelector(getLDAVersions);
  const requestLDAVersions = useReduxAction(infoActions.LDA_VERSIONS.request);

  useEffect(() => {
    if (!links) {
      requestLinks();
    }
    if (!versions) {
      requestLDAVersions();
    } else {
      console.debug(
        '%c LDAVERSIONS INFO ',
        'background:black;color:white;',
        versions,
      );
    }
  }, [links, requestLinks, versions, requestLDAVersions]);

  return (
    <>
      {isLoadingPrefs ? (
        <Loader visible={true} />
      ) : (
        <Flex flexDirection={'column'}>
          <Title variant={'h2'}>{t('other:about-and-legal')}</Title>
          <Flex flexDirection={'row'} mb={'32px'} mt={8}>
            <StyledText
              data-testid="about-and-legal-description"
              variant={'body'}
            >
              {t('other:about-and-legal-description')}
            </StyledText>
          </Flex>
          <Text
            data-testid="privacy-policy-title"
            fontSize={20}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
          >
            {t('settings:privacy-policy')}
          </Text>
          <StyledLink
            data-testid="privacy-policy-review-button"
            href={links?.get('privacy')}
            target="_blank"
            rel="noopener"
          >
            <StyledIcon icon="new-window" size={20} />
            {t('settings:privacy-policy-review')}
          </StyledLink>
          <Text
            data-testid="terms-title"
            fontSize={20}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
            mt={12}
          >
            {t('settings:terms-and-conditions')}
          </Text>
          <StyledLink
            data-testid="review-terms-button"
            href={
              'https://www.zebra.com/content/dam/zebra_new_ia/en-us/support-and-downloads/community/media/site-image-zsb/ZSB_Software_EULA.pdf'
            }
            target="_blank"
            rel="noopener"
          >
            <StyledIcon icon="new-window" size={20} />
            {t('settings:terms-and-conditions-review')}
          </StyledLink>
          <Text
            data-testid="version-info-title"
            fontSize={20}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
            mt={12}
          >
            {t('settings:application-version-information')}
          </Text>
          <StyledLink
            data-testid="review-version-button"
            href={
              'https://www.zebra.com/us/en/about-zebra/company-information/legal/open-source-usage.html#zsb-webui'
            }
            target="_blank"
            rel="noopener"
          >
            <StyledIcon icon="new-window" size={20} />
            {t('settings:version-information-review')}
          </StyledLink>
          <Text
            data-testid="version-info-title"
            fontSize={20}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.high}
            mb={5}
            mt={12}
          >
            {t('settings:lda-version-information')}
          </Text>
          <StyledText mt={2} data-testid="designer-version" variant={'body'}>
            {t('settings:designer-version', {
              version: versions?.['designerversion'],
            })}
          </StyledText>
        </Flex>
      )}
    </>
  );
};
