import React from 'react';
import { Flex } from 'components';
import {
  BannerVariant,
  BannerWrap,
  CloseButton,
  CloseIcon,
  Message,
  StyledIcon,
  SubTitle,
  TextHolder,
} from './Banner.styles';

type BannerAttributes = {
  variant?: BannerVariant;
  message?: string;
  subtitle?: string;
  onClose?: React.MouseEventHandler;
};

const Banner = ({
  variant = 'basic',
  subtitle = '',
  message = '',
  onClose,
}: BannerAttributes) => (
  <BannerWrap subtitle={subtitle} variant={variant}>
    <Flex flexDirection={'row'}>
      <StyledIcon variant={variant.toString()} />
      <TextHolder>
        <Message>{message}</Message>
        {subtitle && <SubTitle mt={'5px'}>{subtitle}</SubTitle>}
      </TextHolder>
    </Flex>
    {onClose && (
      <CloseButton onClick={onClose} as={'button'}>
        <CloseIcon variant={variant.toString()} />
      </CloseButton>
    )}
  </BannerWrap>
);

export default Banner;
