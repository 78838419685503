import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { device } from 'utils/mediaQueries';
import { Flex, Loader, Box, NotificationExpandable } from 'components';
import { Text } from 'v2/components/common';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getCustomer } from 'ducks/auth/selectors';
import { readNotificationRequest } from 'state/Notification';
import {
  selectNotificationsLogState,
  removeSingleRequest,
} from 'state/Notification';

const LoaderWrap = styled(Box)`
  position: relative;
  border-radius: 8px;
  width: 525px;
  margin-bottom: ${({ theme }) => `${theme.space[11]}px`};

  @media ${device.mobileToTablet}, @media ${device.mobile} {
    width: 100%;
  }
`;

const HintText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))``;

export const Log = () => {
  const { t } = useTranslation(['notifications', 'utils']);
  const customer = useReduxSelector(getCustomer);
  const notifications = useSelector(selectNotificationsLogState);
  const removeNotification = useReduxAction(removeSingleRequest);
  const readNotification = useReduxAction(readNotificationRequest);

  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'} mb={'32px'}>
        <HintText variant={'label'}>{t('notifications:received')}</HintText>
      </Flex>
      {notifications.length === 0 &&
        (!customer ? (
          <LoaderWrap>
            <Loader visible={true} />
          </LoaderWrap>
        ) : (
          <Text variant={'h2'}>{t('notifications:no-new')}</Text>
        ))}

      {notifications.map((notification) => (
        <NotificationExpandable
          key={notification.uniqueId}
          notification={notification}
          onDismiss={() => removeNotification(notification.uniqueId)}
          onOpen={() => !notification.isRead && readNotification(notification)}
        />
      ))}
    </Flex>
  );
};
