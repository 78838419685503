import 'ag-psd/initialize-canvas'; // only needed for reading image data and thumbnails
import { readPsd } from 'ag-psd';

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const str = reader.result != null ? reader.result.toString() : '';
      return resolve(str.split(',')[1]);
    };
    reader.onerror = (error) => reject(error);
  });

export const toBase64fromPSD = (file) =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = () => {
      const psd2: any = readPsd(reader.result, {
        skipThumbnail: true,
        skipLinkedFilesData: true,
        skipLayerImageData: true,
      });
      const dataURL = psd2.canvas.toDataURL();
      return resolve(dataURL.split(',')[1]);
    };
    reader.onerror = (error) => reject(error);
  });
