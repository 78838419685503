import React, { forwardRef, useCallback } from 'react';
import { Button, Flex, Modal } from 'components';
import styled from 'styled-components/macro';
import MarkdownIt from 'markdown-it';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';

const md = new MarkdownIt();

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const Message = styled.div`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.neutral.darkest};
`;

type AnnouncementModalProps = {
  bannerMessageContent: BannerMessage.Content | undefined;
};

const AnnouncementModal = forwardRef<AnnouncementModalProps, any>(
  ({ bannerMessageContent }: any, ref: any) => {
    const handleClick = useCallback(() => {
      ref.current.close();
    }, [ref]);

    return (
      <Modal
        data-testid="announcement-modal"
        clickOutside={true}
        hasCloseButton={true}
        small
        ref={ref}
      >
        <Message>
          {bannerMessageContent != null
            ? parse(
                DOMPurify.sanitize(
                  md.render(bannerMessageContent.getModalmarkdown()),
                ),
              )
            : ''}
        </Message>
        <Flex mt={20} justifyContent={'flex-end'}>
          <Button
            data-testid="announcement-close-button"
            variant="primary"
            type="submit"
            onClick={handleClick}
          >
            OK
          </Button>
        </Flex>
      </Modal>
    );
  },
);

export default AnnouncementModal;
