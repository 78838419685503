import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Flex, Button, Modal, Box, Search } from 'components';
import IconCategoryContainer from './iconContainer/IconCategoryContainer';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const SearchBox = styled(Box)`
  flex-grow: 1;
  margin-left: 32px;
  width: 550px;
  padding-top: 12px;
`;

const ModalBody = styled.div`
  width: 635px;
  padding-left: 4px;
`;

const InnerContainer = styled.div`
  width: 635px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.neutral.lighter};
  }

  scrollbar-color: ${({ theme }) => theme.neutral.lighter};
  scrollbar-width: thin;
`;

const GalleryModal = forwardRef(({ resolve }: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);

  const [selectedClipArt, setSelectedClipArt] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [data, setData] = useState<any>([]);
  const [iconsList, setIconsList] = useState<any>([]);
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const getData = () => {
    fetch('gallery-icons/icons.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((json) => setData(json));
  };
  useEffect(() => {
    getData();
  }, []);

  //generate options for search
  useEffect(() => {
    const iconsListTemp: any[] = [];
    const catListTemp: any[] = [];
    data.forEach((cat) => {
      catListTemp.push({ name: cat.category });
      cat.icons.forEach((icon) => {
        iconsListTemp.push({ name: icon.name });
      });
    });
    setIconsList(iconsListTemp);
    setCategoriesList(catListTemp);
  }, [data]);

  useEffect(() => {
    if (selectedClipArt !== null) {
      resolve(selectedClipArt, ref.current); //send back to printoption step
      ref.current.close();
    }
  }, [selectedClipArt, ref, resolve]);

  //options for search filter
  const handleChange = (phrase) => {
    const catFiltered = categoriesList.filter((i: any) =>
      i.name.toLowerCase().includes(phrase.toLowerCase()),
    );
    const iconsFiltered = iconsList.filter((i: any) =>
      i.name.toLowerCase().includes(phrase.toLowerCase()),
    );
    const optionsArr: any = [];
    if (catFiltered.length > 0) {
      optionsArr.push(
        { label: t('dataSources:categories'), type: 'section' },
        ...catFiltered,
      );
    }
    if (iconsFiltered.length > 0) {
      optionsArr.push(
        { label: t('dataSources:icons'), type: 'section' },
        ...iconsFiltered,
      );
    }
    setOptions(optionsArr);
  };

  const handleClose = useCallback(() => {
    if (selectedClipArt == null) {
      resolve('');
    }
    setSelectedClipArt(null);
  }, [resolve, selectedClipArt]);

  return (
    <Modal
      ref={ref}
      title={t('dataSources:zebra-gallery')}
      defaultPadding={false}
      onClose={handleClose}
      header={
        <Flex justifyContent={'flex-end'}>
          <Button
            variant={'text-primary'}
            onClick={() => {
              ref.current.close();
            }}
          >
            {t('common:cancel')}
          </Button>
        </Flex>
      }
      clickOutside={true}
    >
      <ModalBody>
        <InnerContainer>
          <SearchBox>
            <Search
              placeholder={t('dataSources:search-icons')}
              data-testid="input-search"
              onChange={handleChange}
              onSelect={setSearchPhrase}
              initialSearch={searchPhrase}
              options={options}
            />
          </SearchBox>

          <IconCategoryContainer
            searchPhrase={searchPhrase}
            setClipArt={setSelectedClipArt}
            data={data}
          />
        </InnerContainer>
      </ModalBody>
    </Modal>
  );
});

export default GalleryModal;
