import { TextArea } from '../../../index';
import React, { useMemo } from 'react';
import { PrintInput } from './PrintInput';
import { parseInitialValue } from 'utils/templates';

export const PrintTextInput = ({
  template,
  card,
  disabled,
  handleChange,
  fieldError,
}) => {
  const calculatedInitialValue = useMemo(() => {
    return parseInitialValue(card.initialValue);
  }, [card.initialValue]);
  return (
    <PrintInput template={template} card={card}>
      <TextArea
        contentEditable={true}
        rows={calculatedInitialValue.split(/\r\n|\r|\n/).length}
        placeholder={card.promptText}
        value={calculatedInitialValue}
        disabled={disabled}
        key={card.name}
        onChange={handleChange}
        hasError={fieldError?.includes(card.name) ?? false}
      />
    </PrintInput>
  );
};
