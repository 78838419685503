import styled from 'styled-components';
import { Box, Flex, Loader, Text } from 'components';
import { device } from 'utils/mediaQueries';
import React from 'react';

const toggleSwitchWidth = '48px';
const toggleSwitchHeight = '24px';

export const StyledText = styled(Text).attrs(() => ({
  fontSize: 14,
  letterSpacing: 0.4,
}))<{ greyText?: boolean }>`
  color: ${({ theme, greyText }) =>
    greyText ? `${theme.textColors.med}` : `${theme.textColors.high}`};
`;

export const ToggleSwitchLoader = () => (
  <Box width={toggleSwitchWidth} height={toggleSwitchHeight}>
    <Loader visible={true} width={toggleSwitchHeight} height={toggleSwitchHeight} />
  </Box>
);

export const ToggleSwitchInput = styled.input.attrs({
  type: 'checkbox',
  className: 'mozilla-specific',
})<{
  disabled?: boolean;
  checked?: boolean;
}>`
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: ${toggleSwitchWidth};
  height: ${toggleSwitchHeight};
  margin: 0;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: ${toggleSwitchWidth};
    height: ${toggleSwitchHeight};
    border-radius: 23px;
    background-color: ${({ theme, disabled, checked }) =>
      disabled
        ? `${theme.neutral.lightest}`
        : checked
        ? `${theme.primary.base}`
        : `${theme.neutral.lighter}`};
    transition: background-color 0.15s ease-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: ${({ checked }) =>
      checked ? `translate(130%, -50%)` : `translate(2px, -50%)`};
    background-color: ${({ theme, disabled }) =>
      disabled ? `${theme.neutral.lighter}` : `${theme.white}`};
    transition: transform 0.05s ease-out;
  }
`;

export const Container = styled(Flex)<any>`
  margin-bottom: 9px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.desktop} {
    width: 384px;
  }

  @media ${device.tablet} {
    width: calc(100% - 10px);
  }

  @media ${device.mobile} {
    width: auto;
  }
`;
