export type FileInfoType = {
  id: string;
  provider: string;
  name: string;
  mimetype: string;
  lastModified: number;
  dimensions?: DimensionsType;
  linkedMicrosoftAccount?: string;
};

type DimensionsType = {
  width: number;
  height: number;
};

export enum DataSourceType {
  Counter = 'Counter',
  ClientDate = 'ClientDate',
  ClientTime = 'ClientTime',
  Currency = 'Currency',
  Date = 'Date',
  Image = 'Image',
  Number = 'Number',
  Picture = 'Picture',
  Text = 'Text',
}
