import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { Notification } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { sortNotifications, ExtendedNotification } from '../../utils/notifications';

import { RootState } from 'store';
import { getPrintjobs, getCartridgelow } from 'ducks/preferences/selectors';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { getAllPrinters } from 'ducks/printers/selectors';
import transform, {
  NotificationMessageType,
} from 'components/app/notification-widget/transformer';

const initialState: {
  all: ExtendedNotification[];
  toast: ExtendedNotification[];
} = {
  all: [],
  toast: [],
};

const NAMESPACE = 'SMB_NOTIFICATION';

export const NotificationSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setNotificationsLog: (state, action) => {
      state.all = action.payload;
    },
    saveSingle: (state, action) => {
      state.all.unshift(action.payload);
      state.toast.unshift(action.payload);
    },
    removeSingle: (state, action) => {
      return {
        ...state,
        all: state.all.filter(({ uniqueId }) => uniqueId !== action.payload),
      };
    },
    removeFromToast: (state, action) => {
      return {
        ...state,
        toast: state.toast.filter(
          ({ uniqueId }) => uniqueId !== action.payload,
        ),
      };
    },
    readNotification: (state, action) => {
      const notification = state.all.find(
        ({ uniqueId }) => uniqueId === action.payload.uniqueId,
      );

      if (notification) {
        notification.isRead = true;
      }
    },
  },
});

export const updatePrinter = createAction<Notification.AsObject>(
  `${NAMESPACE}/updatePrinter`,
);
export const receivedNotification = createAction<Notification.AsObject>(
  `${NAMESPACE}/receivedNotification`,
);
export const removeSingleRequest = createAction<string>(
  `${NAMESPACE}/removeSingleRequest`,
);
export const readNotificationRequest = createAction<Notification.AsObject>(
  `${NAMESPACE}/readNotificationRequest`,
);

export const {
  setNotificationsLog,
  saveSingle,
  removeSingle,
  removeFromToast,
  readNotification,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

export const selectNotificationsLogState = createSelector(
  [
    (state: RootState) => sortNotifications(state.notifications.all),
    getAllWorkspaces,
    getAllPrinters,
    getPrintjobs,
    getCartridgelow,
  ],
  transformNotifications,
);

export const selectToastNotifications = createSelector(
  [
    (state: RootState) => sortNotifications(state.notifications.toast),
    getAllWorkspaces,
    getAllPrinters,
    getPrintjobs,
    getCartridgelow,
  ],
  transformNotifications,
);
export const selectHasUnreadNotifications = createSelector(
  [selectNotificationsLogState],
  (notifications) => notifications.filter(({ isRead }) => !isRead).length > 0,
);

function transformNotifications(
  notifications: ExtendedNotification[],
  workspaces: any,
  printers: any,
  printjobs: any,
  cartridgelow: any,
  
) {
  return notifications.reduce((acc, notification) => {
    if (!(workspaces || printers)) {
      return acc;
    }

    const parsedNotification = transform(
      notification,
      workspaces,
      printers,
      true,
      true
    );

    if (parsedNotification?.title || parsedNotification?.detailedMessage) {
      acc.push(parsedNotification);
    }

    return acc;
  }, [] as NotificationMessageType[]);
}
