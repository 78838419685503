import styled, { DefaultTheme } from 'styled-components';
import { Icon, Flex, Box } from 'components';

export const GAP = 20;
export type RightLeftType = 'left' | 'right';

export const RightArrow = styled(Icon).attrs(
  ({ theme }: { theme: DefaultTheme }) => ({
    icon: 'chevron-right',
    color: theme.neutral.base,
    size: 40,
  }),
)``;

export const LeftArrow = styled(Icon).attrs(
  ({ theme }: { theme: DefaultTheme }) => ({
    icon: 'chevron-left',
    color: theme.neutral.base,
    size: 40,
  }),
)``;

export const ArrowWrapper = styled(Flex)<{ $disabled?: boolean, isVisible?: boolean }>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  border-radius: 30%;
  box-shadow: ${({ theme }) => theme.elevations.three};
  visibility: ${({ isVisible = true }) => isVisible ? 'visible' : 'hidden' };

  @media screen and (max-width: 436px) {
    display: none;
  }

  ${({ theme, $disabled }) =>
    $disabled &&
    `
      pointer-events: none;

      svg path {
        fill: ${theme.neutral.lighter} !important;
      }
    `}
`;

export const HiddenMask = styled(Box)`
  max-width: calc(100% - 100px);
  flex: 1;
  overflow-x: clip;
`;

export const Slides = styled(Flex)<{
  $translate?: number;
  $offsetWidth: number;
}>`
  transform: translateX(-${({ $translate = 0 }) => $translate}px);
  transition: transform ease-out 0.45s 0.1s;

  & > div:nth-last-child(1):nth-child(odd) {
    transform: translateX(${({ $offsetWidth }) => $offsetWidth}px);
  }
`;

export const Slide = styled(Box)<{ $minWidth?: number }>`
  flex: 1;
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}px;`}
  margin: 0 ${GAP / 2}px;
`;

export const DotContainer = styled(Flex)<{ isVisible?: boolean }>`
  justify-content: center;
  visibility: ${({ isVisible = true }) => isVisible ? 'visible' : 'hidden' };
`;

export const Dot = styled.div<{ active?: boolean }>`
  width: ${({ theme }) => theme.px(4)};
  height: ${({ theme }) => theme.px(4)};
  margin-right: ${({ theme }) => theme.px(4)};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ active, theme }) =>
    active ? theme.primary.base : theme.neutral.lighter};
  &&:last-child {
    margin-right: 0;
  }
`;
