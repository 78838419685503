import React from 'react';
import styled from 'styled-components/macro';
import { Flex, PageHeader } from 'components';
import { push } from 'connected-react-router';
import { useReduxAction } from 'hooks';
import { WorkspacesList } from './components/WorkspacesList';
import { Switch, Route } from 'react-router';
import WorkspaceEdit from './components/WorkspaceEdit';
import { useReduxSelector } from 'hooks';
import { HOME_PAGE_PATH } from 'utils/config';

const Wrapper = styled(Flex)`
  min-height: 100vh;
`;

export const Workspaces = ({ match }): any => {
  const pushAction = useReduxAction(push);
  const path = useReduxSelector(
    (state: any) => state.router.location?.pathname,
  );
  return (
    <Wrapper flexDirection="column">
      <PageHeader
        selectedWorkspaceId={match.params.id}
        onClick={() => {
          if (path === '/workspaces') pushAction(HOME_PAGE_PATH);
          else pushAction('/workspaces');
        }}
      />
      <Switch>
        <Route path="/workspaces/:id/edit" component={WorkspaceEdit} />
        <Route path="/workspaces" component={WorkspacesList} />
      </Switch>
    </Wrapper>
  );
};
