import { NodeEnvironment } from "./config";

interface LoggerI {
    debug: (...args: any) => void,
    log: (...args: any) => void,
    info: (...args: any) => void,
    warn: (...args: any) => void,
    error: (...args: any) => void
}

export const Logger: LoggerI = {
    debug: (...args: any) => {
        if (process.env.NODE_ENV === NodeEnvironment.Development) {
            console.debug(...args);
        }
    },
    log: (...args: any) => {
        if (process.env.NODE_ENV === NodeEnvironment.Development) {
            console.log(...args);
        }
    },
    info: (...args: any) => {
        console.info(...args);
    },
    warn: (...args: any) => {
        console.warn(...args);
    },
    error: (...args: any) => {
        console.error(...args);
    }
}