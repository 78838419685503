import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actions as dataSourcesActions,
  types as dataSourcesTypes,
} from 'ducks/dataSources/actions';
import { actions as templateActions } from 'ducks/templates/actions';
import { actions as printDialogActions } from 'ducks/printDialog/actions';
import { openBasicToast, openErrorToast } from 'state/Toast';
import { addTemplate, getUniqueFileName } from 'services/api';
import tokenStorage from 'services/tokenStorage';
import { getTenantId } from './templates';
import { toBase64 } from 'utils/toBase64';
import { MAX_UPLOAD_FILE_SIZE } from 'utils/config';
import { formattedBytes } from 'utils/metrics';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall } from './utils';
import i18n from 'i18next';

const DBG = ['%c UploadAndPrintTemplate Saga ', 'color:white;background:black'];

function* handleAddAndPrintFile({
  payload,
}: PayloadAction<{
  file: File;
  source: string;
  from: string;
  fileType: string;
}>) {
  console.debug(...DBG, 'handleAddAndPrintFile');
  const { file, source } = payload;

  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const { token } = tokenStorage.get();

  try {
    const uniqueName = yield apiCall(
      getUniqueFileName,
      file.name,
      customer?.id,
      workspaceId,
      token,
    );
    if (source.includes('Label')) {
      if (!file) {
        yield put(dataSourcesActions.ADD_AND_PRINT.failure({}));
        yield put(openErrorToast(i18n.t('dataSources:upload-file-error')));

        return;
      }

      if (file.size > +MAX_UPLOAD_FILE_SIZE.toExponential()) {
        yield put(dataSourcesActions.ADD_AND_PRINT.failure({}));
        yield put(
          openErrorToast(
            i18n.t('dataSources:upload-file-size-error', {
              maxSize: formattedBytes(MAX_UPLOAD_FILE_SIZE),
            }),
          ),
        );

        return;
      }

      const fileData = yield call(toBase64, file);

      const fileState = yield apiCall(
        addTemplate,
        fileData,
        uniqueName,
        customer?.id,
        workspaceId,
        token,
      );
      yield put(
        dataSourcesActions.ADD_AND_PRINT.success({ fileType: 'Label' }),
      );

      yield put(
        openBasicToast(
          i18n.t('dataSources:upload-file-success', {
            fileName: fileState.name ?? file.name,
          }),
        ),
      );

      const { id } = fileState;
      yield put(templateActions.SELECTED_DATA.request({ template: { id } }));
      yield put(printDialogActions.OPEN(id));
    }
  } catch (err: any) {
    yield put(dataSourcesActions.ADD_AND_PRINT.failure({ error: err }));
    yield put(openErrorToast(`${err.message}`));
  }
}

export default function* watcher() {
  yield takeLatest(
    dataSourcesTypes.ADD_AND_PRINT.REQUEST,
    handleAddAndPrintFile,
  );
}
