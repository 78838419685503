import { createReducer, generateFetchDataReducer } from 'ducks/utils';
import { types } from './actions';
import { combineReducers } from 'redux';
import {
  FETCH_DATA_INITIAL_STATE,
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer,
} from 'ducks/utils';

const all = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ALL),
});

const selected = createReducer(
  SELECTED_DATA_INITIAL_STATE,
  generateSelectDataReducer(types.SELECTED),
);
export default combineReducers({
  all,
  selected,
});
