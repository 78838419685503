import {
  createReducer,
  generatePostDataReducer,
  generateFetchDataReducer,
} from 'ducks/utils';
import { types } from './actions';
import { FETCH_DATA_INITIAL_STATE, POST_DATA_INITIAL_STATE } from 'ducks/utils';
import { combineReducers } from 'redux';

const all = createReducer(
  { ...FETCH_DATA_INITIAL_STATE, loading: true },
  {
    ...generateFetchDataReducer(types.ALL),
  },
);
const update = createReducer(POST_DATA_INITIAL_STATE, {
  ...generatePostDataReducer(types.UPDATE),
});

export default combineReducers({
  all,
  update,
});
