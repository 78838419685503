import React from 'react';
import styled from 'styled-components/macro';
import NotificationWidget from 'components/app/notification-widget/NotificationWidget';
import { NotificationWrap } from 'components/app/notification-widget/NotificationWidget.styles';
import { Box } from 'components';

const StyledNotificationWrapper = styled(Box)`
  & ${NotificationWrap} {
    top: 69px;
  }
`;

const NotificationWidgetWrapper = () => {
  return (
    <StyledNotificationWrapper>
      <NotificationWidget />
    </StyledNotificationWrapper>
  );
};

export default NotificationWidgetWrapper;
