import { FileDataParams, FileProvider } from './FileProvider';
import { getFileById } from '../../services/internalDb';

export class CloudFileProvider implements FileProvider {
  async getFileData(file, params: FileDataParams) {
    if (params.useCache) {
      return getFileById(file.id, params.customerId, params.workspaceId);
    }
    return null;
  }
}
