import React from 'react';
import { Icon } from 'components';
import styled from 'styled-components/macro';
import IconType from 'components/lib/icon/IconType';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const BadgeWrapper = styled.span`
  position: relative;
  display: inline-block;
  border-radius: ${({ theme }) => theme.px(1)};
`;

const Badge = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: ${({ theme }) => theme.px(4)};
  height: ${({ theme }) => theme.px(4)};
  top: ${({ theme }) => theme.px(-2)};
  right: -2px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.error.base};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.px(5)};
`;

export type IconButtonProps = {
  icon: IconType;
  size?: string | number;
  color?: string;
  badge?: boolean;
  onClick?: () => void;
};

const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
  ({ icon, badge, size = 32, color = 'white', onClick, ...props }, ref) => {
    return (
      <Wrapper
        data-testid={'icon-button'}
        ref={ref}
        {...props}
        onClick={onClick}
      >
        <BadgeWrapper>
          {badge && <Badge />}
          <Icon icon={icon} size={size} color={color} />
        </BadgeWrapper>
      </Wrapper>
    );
  },
);

export default IconButton;
