import { Loader } from 'components';
import { getAllPreferencesLoading } from 'ducks/preferences/selectors';
import { useReduxSelector } from 'hooks';
import React from 'react';
import EditWorkspace from './EditWorkspace';

export const Workspace = () => {
  const preferencesLoading = useReduxSelector(getAllPreferencesLoading);

  return preferencesLoading ? <Loader visible={true} /> : <EditWorkspace />;
};
