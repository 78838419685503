import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectWithHash = ({ to }) => {
  return (
    <Redirect
      to={{
        pathname: to,
        hash: new URL(window.location.href).hash,
      }}
    />
  );
};

export default RedirectWithHash;
