import React, { useMemo, useState } from 'react';
import { Dropdown } from 'components';
import { RichDatasources } from 'utils/templates';
import { DropdownDatasourceBox } from './DropdownDatasource.styles';

export const CONTACT_ADDRESS_HEADERS = {
  home: [
    'Home Address - Street',
    'Home Address - City',
    'Home Address - Country/Region',
    'Home Address - Postal Code',
    'Home Address - State',
    'Home Address - Fully Formatted',
  ],
  business: [
    'Business Address - Street',
    'Business Address - City',
    'Business Address - Country/Region',
    'Business Address - Postal Code',
    'Business Address - State',
    'Business Address - Fully Formatted',
  ],
  other: [
    'Other Address - Street',
    'Other Address - City',
    'Other Address - Country/Region',
    'Other Address - Postal Code',
    'Other Address - State',
    'Other Address - Fully Formatted',
  ],
};

type Props = {
  data: RichDatasources;
  header: string;
  onChangeHandler: (group, newIndex) => void;
};
export const DropdownDatasource = ({
  data,
  header,
  onChangeHandler,
}: Props) => {
  const selectedDatasource = useMemo(
    () => data.find((o) => o.selected === true) || data[0],
    [data],
  );

  const [unitState, setUnitState] = useState(selectedDatasource.value);

  return (
    <DropdownDatasourceBox>
      <Dropdown
        value={unitState}
        tableCellDesign={true}
        onChange={(val) => {
          selectedDatasource.selected = false;
          const currentDatasource: any = data.find((o) => o.value === val);
          if (currentDatasource) {
            currentDatasource.selected = true;
          }
          setUnitState(currentDatasource.value);
          if (header.toLocaleLowerCase().indexOf('home') !== -1) {
            onChangeHandler(
              CONTACT_ADDRESS_HEADERS['home'].filter((o) => o !== header),
              data.indexOf(currentDatasource),
            );
          } else if (header.toLocaleLowerCase().indexOf('business') !== -1) {
            onChangeHandler(
              CONTACT_ADDRESS_HEADERS['business'].filter((o) => o !== header),
              data.indexOf(currentDatasource),
            );
          } else if (header.toLocaleLowerCase().indexOf('other') !== -1) {
            onChangeHandler(
              CONTACT_ADDRESS_HEADERS['other'].filter((o) => o !== header),
              data.indexOf(currentDatasource),
            );
          }
        }}
        options={data}
      />
    </DropdownDatasourceBox>
  );
};
