import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface GlobalUtils {
  printerSettingsTabChange?: boolean;
}

const initialState: GlobalUtils = {
  printerSettingsTabChange: false,
};

const NAMESPACE = 'SMB_GLOBAL_UTILS';

export const GlobalUtilsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setPrinterSettingsTabChange: (state, action) => {
      return { ...state, printerSettingsTabChange: action.payload };
    },
  },
});

export const { setPrinterSettingsTabChange } = GlobalUtilsSlice.actions;

export default GlobalUtilsSlice.reducer;

export const selectPrinterSettingsTabChange = (state: RootState) =>
  state.globalUtils.printerSettingsTabChange;
