import React from 'react';
import styled from 'styled-components/macro';
import { Flex, Text } from 'components';

const Title = styled(Text).attrs(({ theme }) => ({
  fontWeight: 300,
  fontSize: 24,
  lineHeight: '32px',
  letterSpacing: 0.4,
  color: theme.textColors.high,
}))``;

export const Subtitle = styled(Text).attrs(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: theme.textColors.med,
}))``;

const EditContentHeader = ({ title, subtitle, ...props }: any) => (
  <Flex pb={'40px'} flexDirection={'column'} {...props}>
    <Title mb={4}>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Flex>
);

export default EditContentHeader;
