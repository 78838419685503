import React from 'react';
import { Flex, Text, ToggleSwitch } from 'components';

import { ThemeContext } from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import { CustomerInfoType } from 'zsbpsdk/src/customer/index';
import { openBasicToast } from 'state/Toast';
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
} from '../../../hooks';
import { getCustomer } from '../../../ducks/auth/selectors';
import { BannerMessage } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { getBannerMessageSettingKey } from '../../../utils/bannerMessages';

export const Messages = () => {
  const { t } = useTranslation(['notifications']);
  const theme = React.useContext(ThemeContext);
  const customer: CustomerInfoType = useAppSelector(getCustomer);
  const dispatch = useAppDispatch();

  const [upcomingFeaturesEnabled, setUpcomingFeaturesEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.UPCOMING_FEATURES,
      customer,
    ),
    true,
  );
  const [newFeaturesEnabled, setNewFeaturesEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.NEW_FEATURES,
      customer,
    ),
    true,
  );
  const [promotionsEnabled, setPromotionsEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.PROMOTIONAL,
      customer,
    ),
    true,
  );
  const [maintenanceEnabled, setMaintenanceEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.MAINTENANCE,
      customer,
    ),
    true,
  );
  const [remindersEnabled, setRemindersEnabled] = useLocalStorage(
    getBannerMessageSettingKey(
      BannerMessage.BannerMessageType.REMINDERS,
      customer,
    ),
    true,
  );
  const openToast = () => {
    dispatch(openBasicToast(t('notifications:preferences-updated')));
  };

  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'row'} mb={'32px'}>
        <Text
          data-testid="notification-messages-description"
          fontSize={14}
          lineHeight={'32px'}
          fontWeight={'400'}
          letterSpacing={0.4}
          color={theme.textColors.med}
        >
          {t('notifications:messages-on-off')}
        </Text>
      </Flex>

      <ToggleSwitch
        data-testid="notifications-upcoming-features"
        value={upcomingFeaturesEnabled ? 'upcomingFeaturesEnabled' : ''}
        label={t('notifications:upcoming-features')}
        defaultChecked={upcomingFeaturesEnabled}
        onChange={(e) => {
          setUpcomingFeaturesEnabled(!upcomingFeaturesEnabled);
          openToast();
        }}
      />
      <ToggleSwitch
        data-testid="notifications-new-features"
        value={newFeaturesEnabled ? 'newFeaturesEnabled' : ''}
        label={t('notifications:new-features')}
        defaultChecked={newFeaturesEnabled}
        onChange={(e) => {
          setNewFeaturesEnabled(!newFeaturesEnabled);
          openToast();
        }}
      />
      <ToggleSwitch
        data-testid="notifications-promotions"
        value={promotionsEnabled ? 'promotionsEnabled' : ''}
        label={t('notifications:promotions')}
        defaultChecked={promotionsEnabled}
        onChange={(e) => {
          setPromotionsEnabled(!promotionsEnabled);
          openToast();
        }}
      />
      <ToggleSwitch
        data-testid="notifications-maintenance-enabled"
        value={maintenanceEnabled ? 'maintenanceEnabled' : ''}
        label={t('notifications:maintenance')}
        defaultChecked={maintenanceEnabled}
        onChange={(e) => {
          setMaintenanceEnabled(!maintenanceEnabled);
          openToast();
        }}
      />
      <ToggleSwitch
        data-testid="notifications-reminders-enabled"
        value={remindersEnabled ? 'remindersEnabled' : ''}
        label={t('notifications:reminders')}
        defaultChecked={remindersEnabled}
        onChange={(e) => {
          setRemindersEnabled(!remindersEnabled);
          openToast();
        }}
      />
    </Flex>
  );
};
