import React, { useState, ChangeEvent, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { PrinterType } from 'zsbpsdk/src/printer';

import { useReduxAction, useReduxSelector } from 'hooks';
import { getAllPrinters } from 'ducks/printers/selectors';
import { BoldText, ErrorText, Text } from 'v2/components/common';
import { Modal, Flex, Box, Button, Checkbox, Input } from 'components';
import { getCustomer } from 'ducks/auth/selectors';
import { CustomerInfoType, State } from 'zsbpsdk/src/customer';
import { actions as authActions } from 'ducks/auth/actions';

import {
  ModalCheckboxWrapper,
  ModalInputWrapper,
} from './DeleteAccountModal.styles';

type CustomDeleteAccountModalProps = {
  handleModalClose: () => void;
  open: boolean;
};

const StyledBoldText = styled(BoldText)`
  line-height: 24px;
`;

const StyledErrorText = styled(ErrorText)`
  font-weight: 700;
  line-height: 24px;
`;

const DeleteAccountModal = ({
  handleModalClose,
  open,
}: CustomDeleteAccountModalProps) => {
  const { t } = useTranslation(['settings', 'dialogs']);
  const ref = useRef(null);

  const deleteAccount = useReduxAction(authActions.DELETE_ACCOUNT.request);

  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const printers: PrinterType[] | null = useReduxSelector(getAllPrinters);

  const [{ removeData, deIdentify, printerOn }, setChecked] = useState<{
    removeData: boolean;
    deIdentify: boolean;
    printerOn: boolean;
  }>({
    removeData: false,
    deIdentify: false,
    printerOn: false,
  });

  const onChange: (e: ChangeEvent<HTMLInputElement>) => void = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = e.target;

    setChecked(
      (prev: {
        removeData: boolean;
        deIdentify: boolean;
        printerOn: boolean;
      }) => ({
        ...prev,
        [name]: checked,
      }),
    );
  };

  const handleDeleteAccountModalClose: () => void = () => {
    setChecked({ removeData: false, deIdentify: false, printerOn: false });

    handleModalClose();
  };

  const handleSubmit: () => void = () => {
    deleteAccount({ state: State.INITIATE });

    handleModalClose();
  };

  return (
    <Modal
      data-testid="delete-account-modal"
      ref={ref}
      clickOutside
      onClose={handleDeleteAccountModalClose}
      small
      open={open}
      isAnimatedBackdrop={false}
    >
      <Box mb={8}>
        <ErrorText
          variant="h2"
          lineHeight={'36px'}
          data-testid="delete-account-text"
        >
          {t('settings:delete-account')}
        </ErrorText>
      </Box>

      <Box mb={8}>
        <StyledBoldText variant="body" data-testid="acknowledge-to-continue">
          {t('dialogs:acknowledge-to-continue')}
        </StyledBoldText>
      </Box>

      <ModalCheckboxWrapper data-testid="delete-checkbox-wrapper">
        <Checkbox
          checked={removeData}
          onChange={onChange}
          name="removeData"
          label={
            <Text
              variant="body"
              lineHeight="24px"
              data-testid="all-data-will-be-removed"
            >
              {t('settings:all-data-will-be-removed')}
            </Text>
          }
        />
        <Checkbox
          checked={deIdentify}
          onChange={onChange}
          name="deIdentify"
          label={
            <Text
              variant="body"
              lineHeight="24px"
              data-testid="account-will-be-de-identified"
            >
              {t('settings:account-will-be-de-identified')}
            </Text>
          }
        />
        <Checkbox
          checked={printerOn}
          onChange={onChange}
          name="printerOn"
          label={
            <Trans
              i18nKey={
                printers && (printers.length === 0 || printers.length === 1)
                  ? 'settings:ensure-printer-is-on'
                  : 'settings:ensure-printer-is-on_plural'
              }
              components={{
                text: (
                  <Text
                    variant="body"
                    lineHeight="24px"
                    data-testid="ensure-printer-is-on"
                  ></Text>
                ),
                strong: (
                  <StyledBoldText variant="body" as={'span'}></StyledBoldText>
                ),
              }}
            ></Trans>
          }
        />
      </ModalCheckboxWrapper>

      <StyledErrorText variant="body" data-testid="must-sign-in-one-last-time">
        {t('settings:must-sign-in-one-last-time')}
      </StyledErrorText>

      <ModalInputWrapper>
        <Input
          label="Email"
          disabled
          defaultValue={customer?.email}
          data-testid="delete-account-email-input"
        />
      </ModalInputWrapper>

      <Flex mt={40} justifyContent={'flex-end'}>
        <Button
          data-testid="delete-account-cancel-button"
          onClick={handleDeleteAccountModalClose}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>

        <Button
          data-testid="continue-deletion-button"
          variant={'destructive'}
          type={'submit'}
          disabled={!deIdentify || !printerOn || !removeData}
          onClick={handleSubmit}
        >
          {t('common:continue')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteAccountModal;
