import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import EditSectionHeader from './EditSectionHeader';
import { Input, Button, Flex, Text, Box } from 'components';
import { Subtitle } from './EditSectionHeader';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getAllWorkspaces, isUpdating } from 'ducks/workspaces/selectors';

import { actions as workspaceActions } from 'ducks/workspaces/actions';
import { useTranslation } from 'react-i18next';

const LogoPlaceHolder = styled(Box)`
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-radius: 8px;
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.primary.lightest};
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border: solid 1px ${({ theme }) => theme.white};
  box-sizing: border-box;
`;

const UploadInput = styled.input.attrs({
  type: 'file',
  accept: 'image/*',
  hidden: true,
})``;

const FileNameString = styled(Text).attrs(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.4,
  color: theme.textColors.high,
}))``;

const AvatarDetails = ({ imageUrl, fileObject }: any) => {
  const [, month = 'Feb', date = '19', year = '2020'] = fileObject
    ? new Date().toDateString().split(' ')
    : [];
  const { t } = useTranslation('common');
  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection="column">
        <FileNameString pb={2}>{fileObject?.name || imageUrl}</FileNameString>
        <Subtitle>{t('common:uploaded-time', { month, date, year })}</Subtitle>
      </Flex>
    </Flex>
  );
};

const AvatarForm = ({ imageUrl }: any) => {
  const InputUploadRef = useRef<any>(null);
  const [url, setUrl] = useState<null | string>(null);
  const [fileObject, setFileObject] = useState<null | string>(null);
  const handleUploadInputChange = (e: any) => {
    const fileObject = e.target.files[0];
    setFileObject(fileObject);
    const fileURL = URL.createObjectURL(fileObject);
    setUrl(fileURL);
  };

  const { t } = useTranslation(['common', 'workspaces']);

  useEffect(() => {
    if (imageUrl) {
      setUrl(imageUrl);
    }
  }, [imageUrl]);
  return (
    <Flex pb={40} flexDirection={'column'}>
      <Subtitle mb={4}>{t('common:avatar')}</Subtitle>
      <Flex flexDirection={'row'} alignItems={'center'} pb={12}>
        <Box width={80} height={80} mr={8}>
          {url ? <Image src={url} /> : <LogoPlaceHolder />}
        </Box>
        {url ? (
          <AvatarDetails fileObject={fileObject} imageUrl={url} />
        ) : (
          <Subtitle>{t('common:no-image-uploaded')}</Subtitle>
        )}
      </Flex>
      <Flex>
        <Box mr={8}>
          <UploadInput
            ref={InputUploadRef}
            onChange={handleUploadInputChange}
          />
          <Button
            variant={'outline'}
            onClick={() => InputUploadRef?.current?.click()}
          >
            {t('common:upload-photo')}
          </Button>
        </Box>
        <Button onClick={() => setUrl(null)} variant={'text-destructive'}>
          {t('common:remove-image')}
        </Button>
      </Flex>
    </Flex>
  );
};

const Details = ({ match, ...props }: any) => {
  const workspaces = useReduxSelector(getAllWorkspaces);
  const isWorkspaceUpdating = useReduxSelector(isUpdating);
  const workspace = workspaces.find(
    (w) => w?.id?.toString() === match.params.id,
  );

  const { t } = useTranslation(['common', 'workspaces']);

  const [internalWorkspace, setInternalWorkspace] = useState<any>({});
  const updateWorkspace = useReduxAction(workspaceActions.UPDATE.request);

  useEffect(() => {
    if (workspaces) {
      setInternalWorkspace({ ...workspace });
    }
  }, [match.params.id, workspace, workspaces]);

  const handleEdit = useCallback(() => {
    updateWorkspace(internalWorkspace);
  }, [internalWorkspace, updateWorkspace]);

  return (
    <>
      <EditSectionHeader
        title={t('workspaces:details')}
        subtitle={t('workspaces:workspace-details', {
          name: workspace?.name,
        })}
      />
      <AvatarForm imageUrl={'/logo-athlon.svg'} />
      <Box width={336}>
        <Input
          label={t('workspaces:workspace-name')}
          value={internalWorkspace.name || ''}
          onChange={({ target: { value: v } }: any) => {
            setInternalWorkspace({ ...internalWorkspace, name: v });
          }}
        />
      </Box>
      <Subtitle mt={40} mb={6}>
        {t('common:created')}
      </Subtitle>
      <FileNameString>
        {t('common:was-created-on', {
          workspace: workspace?.name,
          date: workspace?.createdDate?.substring(
            0,
            workspace?.createdDate?.indexOf('T'),
          ),
          owner: workspace?.ownerId,
        })}
      </FileNameString>
      <Flex
        flexDirection={'row'}
        alignItems={'flex-end'}
        justifyContent={'flex-end'}
      >
        <Button disabled={isWorkspaceUpdating} onClick={handleEdit}>
          {isWorkspaceUpdating ? t('common:saving') : t('common:save')}
        </Button>
      </Flex>
    </>
  );
};

export default Details;
