import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { push, replace } from 'connected-react-router';

import {
  Route,
  Redirect,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { useReduxAction, useReduxSelector } from 'hooks';
import { NavigationTabBar } from 'components/common/NavigationTabBar';

import { General } from 'pages/settings/components/General';
import {
  getAllPrinters,
  getAllPrintersLoading,
  getSelectedPrinter,
} from 'ducks/printers/selectors';
import { actions as printerActions } from 'ducks/printers/actions';
import { PageTitle } from 'v2/components/common';
import { Loader } from 'components';

import { NetworkConnection } from './NetworkConnection';
import Page from '../Page';

export const PrinterSettings = () => {
  const { t } = useTranslation('other');
  const pushAction = useReduxAction(push);
  const replaceAction = useReduxAction(replace);
  const printersLoading = useReduxSelector(getAllPrintersLoading);
  const printers = useReduxSelector(getAllPrinters);
  const selectedPrinter = useReduxSelector(getSelectedPrinter);
  const setSelectedPrinter = useReduxAction(printerActions.SELECTED.set);
  const { printerId } = useParams<{ printerId: string }>();
  const location = useLocation();
  const { path, url } = useRouteMatch();

  const selectedTab = location.pathname === url ? 0 : 1;

  //Redirect to the list of printers if we try to load a printer
  //that is not currently in the list of printers
  useEffect(() => {
    if (!printers) {
      return;
    }

    const requestedPrinter = printers.find(
      ({ uniqueId }) => uniqueId === printerId,
    );

    if (!requestedPrinter) {
      pushAction('/settings/printers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printers, printerId]);

  //We keep the old logic for passing the printer
  //because we still use the old components: General and NetworkConnection
  //which rely on having the selectedPrinter in the Redux store
  useEffect(() => {
    setSelectedPrinter(printerId);
  }, [setSelectedPrinter, printerId]);

  if (printersLoading) {
    return <Loader visible={true} />;
  }

  //This will prevent rendering the child component with the previous
  //selectedPrinter value due to the useEffect delay
  if (!selectedPrinter.uniqueId || selectedPrinter.uniqueId !== printerId) {
    return null;
  }

  return (
    <Page>
      <PageTitle
        onBackClick={() => pushAction('/settings/printers')}
        title={selectedPrinter?.name}
      />
      <NavigationTabBar
        data-testid="printer-settings-nav-bar"
        selected={selectedTab}
        pageNames={[t('other:customize'), t('other:network-connection')]}
        setPage={(index: number) =>
          replaceAction(`${url}${index > 0 ? '/wifi' : ''}`)
        }
      />
      <Switch>
        <Route path={path} component={General} exact />
        <Route path={`${path}/wifi`} component={NetworkConnection} exact />
        <Redirect to={url} />
      </Switch>
    </Page>
  );
};
