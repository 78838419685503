import { createReducer, generateFetchDataReducer } from 'ducks/utils';
import { types } from './actions';
import { combineReducers } from 'redux';
import { FETCH_DATA_INITIAL_STATE } from 'ducks/utils';

const all = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.ALL),
});

const filtered = createReducer(FETCH_DATA_INITIAL_STATE, {
  ...generateFetchDataReducer(types.FILTERED),
});

export default combineReducers({
  all,
  filtered,
});
