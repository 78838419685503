import React, { useEffect, useRef } from 'react';
import { Flex } from 'components';
import { StyledCheckBox, LabelText } from './Checkbox.styles';

export type CheckInputProps = {
  checked?: boolean;
  indeterminate?: boolean;
  label?: string | any;
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
  isTriState?: boolean;
  onClick?: React.MouseEventHandler;
  onChange?: React.ChangeEventHandler;
};

const Checkbox = ({
  checked = false,
  indeterminate = false,
  label,
  name,
  multiple = false,
  onChange,
  onClick,
  disabled = false,
  isTriState = false,
}: CheckInputProps) => {
  const checkboxRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [checkboxRef, indeterminate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    onChange && onChange(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    onClick && onClick(event);
  };

  return (
    <Flex as={'label'} flexDirection={'row'} alignItems="center">
      <StyledCheckBox
        data-testid="checkbox-input"
        ref={checkboxRef}
        multiple={multiple}
        disabled={disabled}
        checked={checked}
        name={name}
        onChange={handleChange}
        onClick={handleClick}
        isTriState={isTriState}
      />
      {label && <LabelText>{label}</LabelText>}
    </Flex>
  );
};

export default Checkbox;
