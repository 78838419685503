import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions as printersActions,
  types as printersTypes,
  FeedOnHeadCloseRequest,
  FeedOnHeadClosePayload,
  SetSettingsRequest,
} from 'ducks/printers/actions';
import {
  cancelAll,
  deletePrinter,
  editPrinter,
  getPrinters,
  getPrintersUntilNew,
  reprint,
  setPrinterSettings,
} from 'services/api';
import { actions as preferencsActions } from 'ducks/preferences/actions';
import tokenStorage from 'services/tokenStorage';
import { PrinterType } from 'zsbpsdk/src/printer';
import { openBasicToast, openErrorToast } from 'state/Toast';
import { getTenantId } from './templates';
import { feedOnHeadCloseData, getAllPrinters } from 'ducks/printers/selectors';
import i18n from 'i18n';
import selectAwaitCustomer from '../utils/selectAwaitCustomer';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall } from './utils';
import {
  PrinterSettings,
  Notification,
} from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { receivedNotification } from 'state/Notification';
import {
  convertStringToUnit,
  convertStringToDithering,
} from 'utils/unitFormatter';
import {
  convertStringToHeadClosedAction,
  testFeedOnHeadCloseNotificationPayload,
  parseFeedOnHeadCloseNotificationPayload,
} from 'utils/feedOnHeadClose';
import { SETTING_KEY_FEED_ON_HEAD_CLOSE } from 'utils/config';

export function* handlePrintersRequest() {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const workspaceId = yield getTenantId();
    const printers = yield apiCall(
      getPrinters,
      customer?.id,
      workspaceId,
      token,
    );
    console.debug(
      '%c PRINTERS SAGA ',
      'background:orange;color:white;',
      printers,
    );
    yield put(printersActions.ALL.success(printers));
  } catch (err: any) {
    yield put(printersActions.ALL.failure({ error: err }));
  }
}

function* handleEditPrinterRequest({ payload }: any) {
  try {
    console.debug(
      '%c PRINTERS SAGA ',
      'background:orange;color:white;',
      'handleEditPrinterRequest',
      payload,
    );
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const updatedPrinter = yield apiCall(
      editPrinter,
      payload,
      customer?.id,
      token,
    );

    // Below is added because 'editPrinter' function does not return wlan and allValues properties, so we take them from payload
    const updatedPrinterWithAllValues = { ...payload, ...updatedPrinter };
    yield put(printersActions.UPDATE.success(updatedPrinterWithAllValues));
    yield put(
      openBasicToast(
        i18n.t(`settings:printer.settings-updated`, {
          name: updatedPrinter.name,
        }),
      ),
    );
  } catch (err: any) {
    yield put(printersActions.UPDATE.failure({ error: err }));

    // From the backend we receive this message.
    if (err.message === 'Printer name must be unique') {
      yield put(
        openErrorToast(i18n.t(`settings:printer.printer-name-duplicated`)),
      );
      return;
    }

    yield put(
      openErrorToast(
        i18n.t(`settings:printer.printer-settings-could-not-be-updated`),
      ),
    );
  }
}

function* handleCurrentCarouselPrinterStatus({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const workspaceId = yield getTenantId();
    const printerList: PrinterType[] = yield apiCall(
      getPrinters,
      customer?.id,
      workspaceId,
      token,
    );
    console.debug(
      '%c PRINTERS SAGA ',
      'background:orange;color:white;',
      printerList,
    );
    yield put(
      printersActions.UPDATE_STATUS.success(
        printerList.find((printer) => printer.uniqueId === payload.uniqueId),
      ),
    );
  } catch (err: any) {
    yield put(printersActions.UPDATE_STATUS.failure({ error: err }));
  }
}

function* handleDeletePrinterRequest({
  payload,
}: PayloadAction<{ printer: PrinterType }>) {
  const { printer } = payload;

  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const success = yield apiCall(deletePrinter, printer, customer?.id, token);

    if (!success) {
      throw new Error(
        i18n.t('printers:printer-not-removed', { name: printer.name }),
      );
    }

    yield put(printersActions.REMOVE.success({ ...payload.printer }));
    yield put(printersActions.REMOVE.clear());

    yield put(
      openBasicToast(
        i18n.t('printers:printer-removed', { name: printer.name }),
      ),
    );
  } catch (err: any) {
    yield put(
      openErrorToast(
        i18n.t('printers:printer-not-removed', { name: printer.name }),
      ),
    );
    yield put(printersActions.REMOVE.failure({ error: err }));
  }
}

function* handleCancelAllRequest({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const success = yield apiCall(
      cancelAll,
      payload.printer,
      customer?.id,
      token,
    );
    if (success) {
      yield put(printersActions.CANCEL_ALL.success({ ...payload.printer }));
      yield put(
        openBasicToast(
          `Print queue of "${payload.printer.name}" has been successfully cleared`,
        ),
      );
    } else {
      console.error(
        `Print queue of "${payload.printer.name}" was not cleared`,
        success,
      );
      yield put(
        openErrorToast(
          `Print queue of "${payload.printer.name}" was not cleared`,
        ),
      );
    }
  } catch (err: any) {
    console.error(
      `Print queue of "${payload.printer.name}" was not cleared`,
      err,
    );
    yield put(
      openErrorToast(
        `Print queue of "${payload.printer.name}" was not cleared`,
      ),
    );
    yield put(printersActions.CANCEL_ALL.failure({ error: err }));
  }
}

function* handleReprintRequest({ payload }: any) {
  try {
    const customer = yield selectAwaitCustomer();
    const { token } = tokenStorage.get();
    const success = yield apiCall(reprint, payload, customer?.id, token);
    if (success) {
      yield put(printersActions.REPRINT.success({ ...payload }));
      yield put(
        openBasicToast(
          `The reprint last label request for "${payload.name}" printer has been sent`,
        ),
      );
    } else {
      console.error(
        `The last label printed on "${payload.name}" failed to reprint`,
        success,
      );
      yield put(
        openErrorToast(
          `The last label printed on "${payload.name}" failed to reprint`,
        ),
      );
    }
  } catch (err: any) {
    console.error(
      `The last label printed on "${payload.name}" failed to reprint`,
      err,
    );
    yield put(
      openErrorToast(
        `The last label printed on "${payload.name}" failed to reprint`,
      ),
    );
    yield put(printersActions.REPRINT.failure({ error: err }));
  }
}

function* handleGetUntilNewRequest() {
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const { token } = tokenStorage.get();
  const existingPrinters = yield select(getAllPrinters);
  try {
    const newPrinters = yield apiCall(
      getPrintersUntilNew,
      customer?.id,
      workspaceId,
      token,
      existingPrinters,
    );
    yield put(printersActions.ALL.success(Object.values(newPrinters)));
  } catch (e: any) {
    yield put(printersActions.ALL.failure(e));
  }
}

function* handleSetSettingRequest({
  payload,
}: PayloadAction<SetSettingsRequest>) {
  console.debug(
    '%c PRINTERS SAGA ',
    'background:orange;color:white;',
    'handleSetSettingRequest',
    payload,
  );
  const { token } = tokenStorage.get();
  const customer = yield selectAwaitCustomer();

  try {
    if (
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
      !navigator.onLine
    ) {
      // Firefox is offline, throw direct error, fixing SMBUI-2076's long timeout
      throw new Error(
        i18n.t(`settings:printer.printer-settings-could-not-be-updated`),
      );
    }

    const { printerSettings } = payload;
    const uniqueId = printerSettings.getPrinteruniqueid();

    yield setFeedOnHeadCloseLoading(uniqueId, true);
    yield apiCall(setPrinterSettings, printerSettings, customer?.id, token);

    // Feed on head close setting is updated in onNotification handler
  } catch (e: any) {
    yield put(printersActions.ALL.failure(e));
    yield setFeedOnHeadCloseLoading(payload.printerSettings.getPrinteruniqueid(), false);
    yield put(
      openErrorToast(
        i18n.t(`settings:printer.printer-settings-could-not-be-updated`),
      ),
    );
  }
}

function* setFeedOnHeadCloseLoading(uniqueId: string, value: boolean) {
  const feedOnHeadClosePayload = yield select(feedOnHeadCloseData);
  yield put(
    printersActions.FEED_ON_HEAD_CLOSE.success({
      ...feedOnHeadClosePayload,
      [uniqueId]: { loading: value },
    }),
  );
}

type PrinterFeedOnHeadClose = {
  headClosedAction: PrinterSettings.HeadClosedAction;
  printerName: string;
};

function* refreshPrintersUntilFeedOnHeadCloseRetrieved(
  uniqueId: string,
  totalWaitTime: number = 0,
): Generator<any, PrinterFeedOnHeadClose | undefined, any> {
  const { token } = tokenStorage.get();
  const customer = yield selectAwaitCustomer();
  const workspaceId = yield getTenantId();
  const printers = yield apiCall(getPrinters, customer?.id, workspaceId, token);
  yield put(printersActions.ALL.success(printers));

  const feedOnHeadClosePayload = yield select(feedOnHeadCloseData);
  const loading = feedOnHeadClosePayload?.[uniqueId]?.loading;
  const headClosedAction: PrinterSettings.HeadClosedAction | undefined =
    feedOnHeadClosePayload?.[uniqueId]?.headClosedAction;

  if (totalWaitTime > 30000) {
    // Timed out after multiple retries
    throw new Error(
      i18n.t(`settings:printer.printer-settings-could-not-be-retrieved`),
    );
  }

  if (loading === false && headClosedAction) {
    // Successfully retrieved head closed action from printer
    const printer = printers?.find((printer) => printer.uniqueId === uniqueId);
    return {
      printerName: printer.name,
      headClosedAction,
    };
  }

  // Try to retrieve head closed action again due to allValues rate limit
  const waitTime = 3000;
  totalWaitTime += waitTime;
  yield delay(waitTime);
  yield refreshPrintersUntilFeedOnHeadCloseRetrieved(uniqueId, totalWaitTime);
}

function* handleFeedOnHeadCloseRequest({
  payload,
}: PayloadAction<FeedOnHeadCloseRequest>) {
  console.debug(
    '%c PRINTERS SAGA ',
    'background:orange;color:white;',
    'handleFeedOnHeadCloseRequest',
    payload,
  );

  try {
    const { uniqueId } = payload;
    yield setFeedOnHeadCloseLoading(uniqueId, true);
    yield call(refreshPrintersUntilFeedOnHeadCloseRetrieved, uniqueId);

    // Success is handled in onNotification, under 'get_all_values'
  } catch (e: any) {
    yield put(printersActions.FEED_ON_HEAD_CLOSE.failure(e));
    yield setFeedOnHeadCloseLoading(payload.uniqueId, false);
    yield put(
      openErrorToast(
        i18n.t(`settings:printer.printer-settings-could-not-be-retrieved`),
      ),
    );
  }
}

function* onNotification({
  type,
  payload: data,
}: {
  type: string;
  payload: Notification.AsObject;
}) {
  let payload: any = {};
  try {
    payload = JSON.parse(data.payload);
  } catch (e: any) {}
  let { cmd = '', result = '', method = '', params = {} } = payload;

  if (Notification.Subject.PRINTER === data.subject) {
    const printers: PrinterType[] = yield select(getAllPrinters);
    const printer = printers?.find(
      (printer) => printer.uniqueId === data.subjectId,
    );
    let updatedPrinter = {};

    if (printer) {
      const updatePrinterProperties = (newProperties: object) => {
        return {
          ...printer,
          ...newProperties,
        };
      };
      const updatePrinterStatus = (newStatus: object) => {
        return updatePrinterProperties({
          status: {
            ...printer.status,
            ...newStatus,
          },
        });
      };

      switch (data.type) {
        case Notification.Type.CARTRIDGE_EVENT: // 6
          if (method === 'cartridge_inserted') {
            updatedPrinter = updatePrinterProperties({
              cartridgeInfo: params
                ? makeCartridgeInfo(printer.cartridgeInfo, params)
                : printer.cartridgeInfo,
              status: {
                ...printer.status,
                isHeadOpen: false,
                isPaperOut: params?.labels_remaining <= 0,
                isOnline: true,
              },
            });

            yield put(printersActions.ALL.request({}));
          } else if (method === 'cartridge_removed') {
            updatedPrinter = updatePrinterProperties({
              cartridgeInfo: {},
            });
          }
          break;

        case Notification.Type.PORTAL_EVENT: // 4
          switch (data.action) {
            case Notification.Action.ONLINE:
            case Notification.Action.OFFLINE:
              updatedPrinter = updatePrinterStatus({
                isOnline: data.action === Notification.Action.ONLINE,
              });
              break;

            case Notification.Action.UPDATED:
              updatedPrinter = updatePrinterProperties({
                darkness: payload['darkness'],
                name: payload['name'],
              });
              break;

            case Notification.Action.DELETED:
              yield put(printersActions.REMOVE.success(printer));
              yield put(printersActions.REMOVE.clear());
              break;
          }

          yield put(printersActions.UPDATE_STATUS.success(updatedPrinter));
          break;

        case Notification.Type.RESULTS: // 5
          if (Notification.Action.UNKNOWN_ACTION === data.action) {
            switch (cmd) {
              case 'get_all_values':
                const cartridge = result.cartridge
                  ? makeCartridgeInfo(printer.cartridgeInfo, result.cartridge)
                  : printer.cartridgeInfo;
                delete result.cartridge;
                updatedPrinter = updatePrinterProperties({
                  cartridgeInfo: cartridge,
                  allValues: result,
                });

                if (!printer.name) {
                  yield put(printersActions.ALL.request({}));
                }

                yield put(
                  printersActions.UPDATE_STATUS.success(updatedPrinter),
                );

                const feedOnHeadClosePayload: FeedOnHeadClosePayload = yield select(
                  feedOnHeadCloseData,
                );
                yield put(
                  printersActions.FEED_ON_HEAD_CLOSE.success({
                    ...feedOnHeadClosePayload,
                    [printer.uniqueId]: {
                      headClosedAction: convertStringToHeadClosedAction(
                        result[SETTING_KEY_FEED_ON_HEAD_CLOSE],
                      ),
                      loading: false,
                    },
                  }),
                );
                break;

              case 'wlan.get_status':
                updatedPrinter = updatePrinterProperties({
                  wlan: result,
                });
                yield put(
                  printersActions.UPDATE_STATUS.success(updatedPrinter),
                );
                break;

              case 'set':
                // This is handling update of the printer settings in web when change is made in other platforms
                // in our case (changing the mobile printer settings feed on head close is now updating web as well)
                yield put(printersActions.UPDATE_STATUS.request(printer));
                break;
            }
          }
          break;

        case Notification.Type.PRINT_STATUS: // 3
          if (method === 'print_format_status') {
            // TODO: We should update the print queue here.
            // Maybe we also need to show print job progress.
            if (params?.cartridge_labels_remaining !== undefined) {
              updatedPrinter = updatePrinterProperties({
                cartridgeInfo: {
                  ...printer.cartridgeInfo,
                  remaininglabels: params.cartridge_labels_remaining,
                },
              });
            }

            yield put(printersActions.UPDATE_STATUS.success(updatedPrinter));

            //Do optimistic update of the remaining labels
            if (params.status === 'reprint') {
              if (printer.cartridgeInfo) {
                printer.cartridgeInfo.remaininglabels -= 1;
                yield put(printersActions.UPDATE.success(printer));
              }
            }
          }
          break;

        case Notification.Type.ALERT: // 1
          if (Notification.Action.UNKNOWN_ACTION === data.action) {
            switch (params['name']) {
              case 'head_latch':
                updatedPrinter = updatePrinterProperties({
                  status: {
                    ...printer.status,
                    isHeadOpen: params.state === 'open',
                    isReadyToPrint: params.ready,
                  },
                  allValues: {
                    ...printer.allValues,
                    "head.latch_state": params.state
                  }
                })
                break;

              case 'media_state':
                switch (params['state']) {
                  case 'out':
                    updatedPrinter = updatePrinterStatus({
                      isPaperOut: true,
                    });
                    break;

                  case 'present':
                  case 'low':
                    updatedPrinter = updatePrinterStatus({
                      isPaperOut: false,
                    });
                    break;
                }
                break;

              case 'head_state':
                updatedPrinter = updatePrinterStatus({
                  isReadyToPrint: params['state'] !== 'overtemp',
                });
                break;
            }
          }
          yield put(printersActions.UPDATE_STATUS.success(updatedPrinter));
          break;
      }
    } else {
      if (Notification.Type.PORTAL_EVENT === data.type) {
        // 4
        if (Notification.Action.ONLINE === data.action) {
          yield put(printersActions.GET_UNTIL_NEW.request({}));
        }
      }
    }
  } else if (Notification.Subject.PREFERENCES === data.subject) {
    if (Notification.Type.PORTAL_EVENT === data.type) {
      if (Notification.Action.UPDATED === data.action) {
        const prefsObj = JSON.parse(data.payload);
        yield put(
          preferencsActions.ALL.success({
            ...prefsObj,
            units: convertStringToUnit(prefsObj.units),
            dithering: convertStringToDithering(prefsObj.dithering),
          }),
        );
      }
    }
  } else if (Notification.Subject.PRINTER_SETTINGS === data.subject) {
    if (Notification.Type.PORTAL_EVENT === data.type) {
      if (Notification.Action.UPDATED === data.action) {
        if (testFeedOnHeadCloseNotificationPayload(data.payload)) {
          const feedOnHeadClosePayload: FeedOnHeadClosePayload = yield select(feedOnHeadCloseData);
          convertStringToHeadClosedAction(
            parseFeedOnHeadCloseNotificationPayload(payload),
          );

          yield put(
            printersActions.FEED_ON_HEAD_CLOSE.success({
              ...feedOnHeadClosePayload,
              [data.subjectId]: {
                headClosedAction: convertStringToHeadClosedAction(
                  parseFeedOnHeadCloseNotificationPayload(data.payload),
                ),
                loading: false,
              },
            }),
          );
        }
      }
    }
  }
}

function makeCartridgeInfo(currentCartridge: any, newInfo: any) {
  const {
    darkness,
    label_top,
    labels_remaining,
    length,
    part_number,
    print_speed,
    serial_number,
    total_labels,
    width,
  } = newInfo;
  return {
    sn: serial_number ?? currentCartridge.sn,
    model: part_number ?? currentCartridge.model,
    width: width ?? currentCartridge.width,
    length: length ?? currentCartridge.length,
    totallabels: total_labels ?? currentCartridge.totallabels,
    remaininglabels: labels_remaining ?? currentCartridge.remaininglabels,
    defaultdarkness: darkness ?? currentCartridge.defaultdarkness,
    printspeed: print_speed,
    labeltop: label_top,
  };
}

export default function* watcher() {
  yield takeLatest(printersTypes.ALL.REQUEST, handlePrintersRequest);
  yield takeLatest(printersTypes.UPDATE.REQUEST, handleEditPrinterRequest);
  yield takeLatest(
    printersTypes.UPDATE_STATUS.REQUEST,
    handleCurrentCarouselPrinterStatus,
  );
  yield takeLatest(printersTypes.REMOVE.REQUEST, handleDeletePrinterRequest);
  yield takeLatest(printersTypes.CANCEL_ALL.REQUEST, handleCancelAllRequest);
  yield takeLatest(printersTypes.REPRINT.REQUEST, handleReprintRequest);
  yield takeLatest(
    printersTypes.GET_UNTIL_NEW.REQUEST,
    handleGetUntilNewRequest,
  );
  yield takeLatest(printersTypes.SET_SETTINGS.REQUEST, handleSetSettingRequest);
  yield takeLatest(
    printersTypes.FEED_ON_HEAD_CLOSE.REQUEST,
    handleFeedOnHeadCloseRequest,
  );
  yield takeLatest(receivedNotification.type, onNotification);
}
