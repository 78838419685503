import React from 'react';
import useMethods, { StateAndCallbacksFor } from 'use-methods';
import { original } from 'immer';

type State = {
  selected: any[];
};

const INITIAL_STATE: State = {
  selected: [],
};

type ContextType = StateAndCallbacksFor<typeof methods>;

const MultiSelectContext = React.createContext<ContextType | null>(null);

export const useMultiSelect = () => {
  const context = React.useContext(MultiSelectContext);

  if (context === null) {
    throw new Error('useMultiSelect must be within MultiSelectProvider');
  }

  return context;
};

export const MultiSelectProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const state = useMethods(methods, INITIAL_STATE);

  return <MultiSelectContext.Provider value={[...state]} children={children} />;
};

const methods = (state: State) => ({
  setSelected: (items: any[]) => {
    state.selected = items;
  },

  select: (item: any) => {
    if (!state.selected.includes(item)) {
      state.selected.push(item);
    }
  },

  unselect: (item: any) => {
    state.selected = state.selected.filter((i) => original(i) !== item);
  },

  clear: () => {
    state.selected = [];
  },
});
