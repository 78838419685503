import styled from 'styled-components/macro';
import { device } from 'utils/mediaQueries';
import Flex from '../flex/Flex';

export const PageWrap: any = styled.div<any>`
  padding-left: ${({ hasSideBar, collapsedState }) =>
    hasSideBar && collapsedState
      ? '54px'
      : hasSideBar && !collapsedState
      ? '240px'
      : '0'};
  width: ${({ hasSideBar, collapsedState }) =>
    hasSideBar && collapsedState
      ? 'calc(100% - 54px)'
      : hasSideBar && !collapsedState
      ? 'calc(100% - 240px)'
      : '100%'};
  background: ${({ theme }) => theme.primary.background};
  transition: padding-left 0.6s ease-out, width 0.6s ease-out;
  min-height: 100vh;
`;

export const ContentWrapWideScreen = styled.div<any>`
  position: relative;
  z-index: 10;
  margin: 0 auto;
  max-width: 1100px;
  height: 100%;
  transition: height 0.4s ease-out;

  @media ${device.desktop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    max-width: 100%;
    top: ${(props) => (props.isOverview ? 71 : 0)}px;
  }
`;
export const ContentWrap = styled(Flex).attrs({
  mt: 40,
  ml: 80,
  flexDirection: 'column',
})`
  @media ${device.mobile} {
    margin: 16px;
    width: calc(100% - 32px);
  }

  @media ${device.tablet} {
    margin-left: 20px;
  }

  flex: 1;
`;

export const ContentOuterWrap = styled(Flex)`
  @media ${device.mobile} {
    min-height: unset;
  }
  @media${device.tablet} {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 71px);
    top: 71px;
  }
  background-color: ${({ theme }) => theme.primary.background};
  min-height: 100vh;

  position: relative;
  z-index: 10;
`;
