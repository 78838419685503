/**
 *
 * @param obj This param should be an valid object.
 */
export const isObjectEmpty = (obj: Object): boolean => {
  return Object.keys(obj).length === 0;
};

export const buildEmptyStringArray = (length: number): string[] =>
  new Array<string>(length).fill('');

interface PropertyToAddProps {
  [key: string]: any;
}

export type AddPropertyToArrayItemProps = {
  array: any[];
  index: number;
  propertiesToAdd: PropertyToAddProps;
};

export const addPropertyToArrayItemAtIndex = ({
  array,
  index,
  propertiesToAdd,
}: AddPropertyToArrayItemProps) =>
  array.map((item, i) => {
    if (i !== index) {
      return item;
    }

    const properties = {};
    for (const [key, value] of Object.entries(propertiesToAdd)) {
      properties[key] = typeof value === 'function' ? value(item) : value;
    }

    return {
      ...item,
      ...properties,
    };
  });
