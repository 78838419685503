import React from 'react';
import { LoaderProps, LoaderWrapper, AnimatedLoader } from './Loader.styles';

const Loader = ({ visible = false, width, height, borderWidth }: LoaderProps) =>
  visible ? (
    <LoaderWrapper
      aria-label={'loader'}
      data-testid="loader"
    >
      <AnimatedLoader
        data-testid="animated-loader"
        width={width}
        height={height}
        borderWidth={borderWidth}
      />
    </LoaderWrapper>
  ) : null;

export default Loader;
