import styled from 'styled-components';
import { Box } from 'components';

export const DropdownDatasourceBox = styled(Box).attrs(() => ({
  ml: '-17px',
  mr: '-20px',
  width: '243px',
}))`
  text-align: left;
`;
