import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReduxSelector } from 'hooks';
import { Flex, Box } from 'components';
import {
  convertPrinterStatus,
  isPrinterOperational,
  getLabelsWidth,
  getEtch,
} from './utils';
import { getUnit } from 'ducks/preferences/selectors';

import PrinterStatusChip from '../PrinterStatusChip/PrinterStatusChip';
import {
  Container,
  DefaultText,
  TruncatedText,
  PrinterName,
  MinWidthFlex,
  PrinterAvatar,
} from './PrinterCard.styles';
import PrintersMenu from './PrintersMenu';

export default function PrinterCard({ printer }) {
  const { t } = useTranslation('components');
  const unit = useReduxSelector(getUnit);
  const printerStatus = convertPrinterStatus(
    printer.status,
    printer.cartridgeInfo?.remaininglabels,
  );

  return (
    <Container>
      <Box>
        <PrinterAvatar
          data-testid="printer-avatar"
          src={printer.avatar}
          alt="printer model avatar"
        />
      </Box>
      <MinWidthFlex flex={1}>
        <MinWidthFlex flex={1} flexDirection={'column'}>
          <Flex alignItems={'center'} mb={10}>
            <TruncatedText>
              <PrinterName data-testid="current-printer-name">
                {printer.name}
              </PrinterName>
            </TruncatedText>
            <Box ml={10}>
              <PrinterStatusChip status={printerStatus} />
            </Box>
          </Flex>
          <Box>
            <Flex>
              <Box>
                <Flex data-testid="printers-list-label-dimensions">
                  <DefaultText data-testid="printers-list-label-width">
                    {getLabelsWidth(getEtch(printer, unit), printerStatus)}
                  </DefaultText>
                </Flex>
                <DefaultText
                  data-testid="printers-list-cartridge-model"
                  $fontSize={14}
                  $lineHeight={21}
                >
                  {isPrinterOperational(printerStatus)
                    ? printer.cartridgeInfo?.model
                    : 'N/A'}
                </DefaultText>
              </Box>
              <Box ml={13}>
                <Flex
                  flexDirection={'column'}
                  data-testid="printers-list-label-count"
                  flexWrap="wrap"
                >
                  <DefaultText data-testid="printers-list-total-labels">
                    {isPrinterOperational(printerStatus)
                      ? t('components:printer.prints-remaining', {
                          prints: printer.cartridgeInfo?.remaininglabels,
                          total: printer.cartridgeInfo?.totallabels,
                        })
                      : 'N/A'}
                  </DefaultText>
                  <DefaultText $fontSize={14} $lineHeight={21}>
                    {t('components:printer.prints-left-new')}
                  </DefaultText>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </MinWidthFlex>
        <Box mt={-6}>
          <PrintersMenu printer={printer} />
        </Box>
      </MinWidthFlex>
    </Container>
  );
}
