import React, { Suspense, useContext } from 'react';
import styled from 'styled-components';
import { Flex, Box, PrintersList } from 'components';
import RecentTemplates from './RecentTemplates';
import { OverviewIntro } from './OverviewIntro/OverviewIntro';
import { ContentWrapWideScreen } from 'components/common/layout/Layout';
import { device } from 'utils/mediaQueries';
import EditPrinterModal from './EditPrinterModal';
import DeletePrinterModal from './DeletePrinterModal';
import PrinterDetailsModal from './PrinterDetailsModal';
import CancelPrintJobsModal from './CancelPrintJobsModal';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'styled-components/macro';
import { useReduxSelector } from 'hooks';
import { getAllPrinters } from 'ducks/printers/selectors';
import BuyMore from 'components/common/BuyMore';
import CertificationModalsContainer from '../../warningModals/CertificationModalsContainer';
import {
  getPrinterWithAboutToExpireCertification,
  getPrinterWithExpiredCertification,
} from '../../warningModals/utils/getCertificateExpirations';

const PrintersListContainer = styled(Flex)`
  @media ${device.mobile} {
    justify-content: center;
    > div {
      margin-left: 0;
    }
  }
`;
const PrintersListWrap = styled(Box)`
  @media ${device.mobile} {
    margin-left: 16px;
    margin-top: 96px;
  }
  @media ${device.mobileWide} {
    margin-left: 20px;
    margin-top: 85px;
    max-width: 336px;
  }
`;

export const Overview = (): any => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({ query: device.mobile });
  const printers = useReduxSelector(getAllPrinters) ?? [];
  const printersAboutToExpireCertificationOffline =
    getPrinterWithAboutToExpireCertification(printers);
  const printersExpiredCertificationOffline =
    getPrinterWithExpiredCertification(printers);

  return (
    <>
      {(printersAboutToExpireCertificationOffline.length > 0 ||
        printersExpiredCertificationOffline.length > 0) && (
        <CertificationModalsContainer
          printersAboutToExpireCertificationOffline={
            printersAboutToExpireCertificationOffline
          }
          printersExpiredCertificationOffline={
            printersExpiredCertificationOffline
          }
        />
      )}

      <OverviewIntro />
      <ContentWrapWideScreen isOverview={true}>
        <PrintersListContainer>
          <BuyMore />
          <PrintersListWrap>
            <Suspense fallback="">
              <PrintersList
                EditPrinterModal={EditPrinterModal}
                DeletePrinterModal={DeletePrinterModal}
                PrinterDetailsModal={PrinterDetailsModal}
                CancelPrintJobsModal={CancelPrintJobsModal}
              />
            </Suspense>
          </PrintersListWrap>
        </PrintersListContainer>

        <Flex
          flexWrap="wrap"
          mt={isMobile ? 62 : 90}
          pt={7}
          backgroundColor={theme.primary.background}
        >
          <Suspense fallback="">
            <RecentTemplates />
          </Suspense>
        </Flex>
      </ContentWrapWideScreen>
    </>
  );
};
