import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as Grid } from 'react-window';
import { throttle } from 'throttle-debounce';

import { Flex, Text, Box, Form, Checkbox } from 'components';
import SearchFiles from 'pages/dataSources/components/SearchFiles';
import { useLockBodyScroll } from 'hooks';

import { NoResultsPanel } from './NoResultsPanel';
import { StyledBox, StyledText } from './LabelRangePicker.styles';
import { DropdownDatasource } from './DropdownDatasource';
import SelectAllCheckbox from './SelectAllCheckbox';
import { usePrintDialog } from '../../app/print-dialog/PrintDialogContext';
import { useLabelRange } from '../../app/print-dialog/LabelRangeContext';
import { Row } from './types';

type LabelRangePickerProps = {
  onSelectionChange?: (selectedLabels: number[]) => void;
};

const LabelRangePicker = ({ onSelectionChange }: LabelRangePickerProps) => {
  const { t } = useTranslation('components');
  const [
    { firstRowIsHeader, selectedLabels: initialSelectedLabels },
    { setFirstRowIsHeader },
  ] = usePrintDialog();
  const [{ labelRange }] = useLabelRange();

  const [search, setSearch] = useState<string>('');
  const [labelRangeRowSearchResults, setLabelRangeRowSearchResults] = useState<
    { [key: number]: string; key: number; checked: boolean }[]
  >([]);
  const [labelRangeRowSelection, setLabelRangeRowSelection] = useState<
    { [key: number]: string; key: number; checked: boolean }[]
  >([]);

  const searchHandler = (e: string) =>
    e.length > 1 ? setSearch(e) : setSearch('');

  const throttledOnChange = useMemo(() => throttle(1000, searchHandler), []);

  const originalLabels = useMemo(
    () =>
      labelRange.reduce((acc: Row[], range, index: number) => {
        if (!!range.join('')) {
          acc.push({
            key: index,
            ...range,
            checked: initialSelectedLabels?.includes(index),
          });
        }

        return acc;
      }, []),
    [labelRange, initialSelectedLabels],
  );

  useLockBodyScroll();

  useEffect(() => {
    if (onSelectionChange) {
      const getSelectedLabelIndecies = labelRangeRowSelection
        .reduce((acc: number[], x) => {
          if (x.checked) {
            acc.push(x.key);
          }
          return acc;
        }, [])
        .sort();

      onSelectionChange(getSelectedLabelIndecies);
    }
  }, [labelRangeRowSelection, onSelectionChange]);

  useEffect(() => {
    setLabelRangeRowSelection(originalLabels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!search) {
      setLabelRangeRowSearchResults(originalLabels);
    } else {
      let filteredResults: any[] = originalLabels.filter(
        (_, index: number) => !(firstRowIsHeader && index === 0),
      );

      if (search.includes('*')) {
        // Search with wildcard
        filteredResults = filteredResults.filter((range) =>
          testWithWildcard(search, Object.values(range)),
        );
        setLabelRangeRowSearchResults(
          firstRowIsHeader
            ? [originalLabels[0], ...filteredResults]
            : [...filteredResults],
        );
      } else {
        // Search without wildcard
        filteredResults = filteredResults.filter((range) =>
          JSON.stringify(range).toLowerCase().includes(search.toLowerCase()),
        );
        setLabelRangeRowSearchResults(
          firstRowIsHeader
            ? [originalLabels[0], ...filteredResults]
            : [...filteredResults],
        );
      }
    }
  }, [labelRange, search, firstRowIsHeader, originalLabels]);

  const handleCheckBoxClick = useCallback(
    (rowInfo: { [key: number]: string; key: number; checked: boolean }) => {
      setLabelRangeRowSelection((labels) =>
        labels.map((element) => {
          if (element.key === rowInfo.key && !element.checked) {
            return { ...element, checked: true };
          } else if (element.key === rowInfo.key && element.checked) {
            return { ...element, checked: false };
          }

          return element;
        }),
      );
    },

    [setLabelRangeRowSelection],
  );

  const handleFirstRowIsHeaderClick = () => {
    const updatedFirstRowIsHeader = !firstRowIsHeader;
    let updatedSelected = [...labelRangeRowSelection];

    if (updatedFirstRowIsHeader)
      updatedSelected = updatedSelected.filter((val) => val.key !== 0);

    setLabelRangeRowSelection(updatedSelected);
    setFirstRowIsHeader(updatedFirstRowIsHeader);
  };

  const testWithWildcard = (
    searchWildcard: string,
    elementsToTest: string[],
  ) => {
    const escaped = searchWildcard.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`^${escaped.replace(/\*/g, '.*')}$`, 'i');
    return elementsToTest.some(regex.test.bind(regex));
  };

  let gridHeight = useMemo(() => {
    return labelRangeRowSearchResults?.length &&
      !(labelRangeRowSearchResults?.length === 1 && firstRowIsHeader)
      ? 615
      : 100;
  }, [labelRangeRowSearchResults, firstRowIsHeader]);

  const ListRow = ({ index, style, data }) => {
    const cols = Object.keys(data[index]);
    const [count, setCount] = useState<number>(0);

    const markSelectedGroup = (headers: string[], newIndex: number) => {
      const getKeyByValue = (object: object, value: string) => {
        return Object.keys(object).find((key) => object[key] === value) || '';
      };

      headers.forEach((header) => {
        const headerIndex: string = getKeyByValue(data[0], header);
        const cellData = data[index][headerIndex];
        if (Array.isArray(cellData)) {
          markSelected(cellData, newIndex);
        }
      });
      setCount(count + 1);
    };

    const markSelected = (options, newIndex) => {
      const selectedDatasource = options.find((o) => o.selected === true);
      selectedDatasource.selected = false;
      const currentDatasource: any = options[newIndex];
      if (currentDatasource) {
        currentDatasource.selected = true;
      }
    };

    const row = labelRangeRowSelection.find(
      (x) => x.key === labelRangeRowSearchResults[index].key && x.checked,
    );

    return (
      <Flex
        key={count}
        style={{ ...style }}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box width={50}>
          <Checkbox
            name={'selectedLabels'}
            disabled={firstRowIsHeader && index === 0}
            onChange={() =>
              handleCheckBoxClick(labelRangeRowSearchResults[index])
            }
            checked={row?.checked}
          />
        </Box>
        {cols.map(
          (key, j) =>
            key !== 'key' && (
              <StyledBox
                key={`${key}-${j}`}
                height={style.height}
                index={index}
                colsLength={cols.length - 2}
                rowsLength={data.length - 1}
                colIndex={j}
              >
                {Array.isArray(data[index][key]) ? (
                  <DropdownDatasource
                    data={data[index][key]}
                    header={data[0][key]}
                    onChangeHandler={markSelectedGroup}
                  />
                ) : (
                  <StyledText>{data[index][key]}</StyledText>
                )}
              </StyledBox>
            ),
        )}
      </Flex>
    );
  };

  return (
    <Form onSubmit={() => {}}>
      {labelRange.length > 0 ? (
        <Flex flexDirection={'column'}>
          <Flex
            flexDirection={'row'}
            mb={35}
            height={60}
            justifyContent="flex-end"
          >
            <Box width={480} height={50} mt={1} mr={2} ml={2}>
              <SearchFiles
                placeholder={t('components:label.search-rows')}
                onChange={(e) => throttledOnChange(e)}
              />
            </Box>
          </Flex>

          <Flex flexDirection={'row'} mb={20}>
            <Flex mr={20}>
              <SelectAllCheckbox
                labelRangeRowSearchResults={labelRangeRowSearchResults}
                labelRangeRowSelection={labelRangeRowSelection}
                setLabelRangeRowSelection={setLabelRangeRowSelection}
              />
              <Text style={{ marginRight: '20px' }}>
                {t('components:label.select-all')}
              </Text>
            </Flex>
            <Flex>
              <Checkbox
                onChange={handleFirstRowIsHeaderClick}
                checked={firstRowIsHeader}
              />
              <Text>{t('components:label.first-row-is-header')}</Text>
            </Flex>
          </Flex>

          <Flex
            data-testid="label-range-picker-grid-container"
            height={615}
            flexDirection={'column'}
            width={1112}
          >
            {labelRangeRowSearchResults?.length ? (
              <>
                <Grid
                  height={gridHeight}
                  itemCount={labelRangeRowSearchResults?.length}
                  itemSize={48}
                  width={'100%'}
                  itemData={labelRangeRowSearchResults}
                >
                  {ListRow}
                </Grid>
                {labelRangeRowSearchResults?.length === 1 &&
                  firstRowIsHeader && (
                    <NoResultsPanel>
                      {t('components:label.no-match')}
                    </NoResultsPanel>
                  )}
              </>
            ) : (
              <NoResultsPanel>{t('components:label.no-match')}</NoResultsPanel>
            )}
          </Flex>
        </Flex>
      ) : (
        <NoResultsPanel>{t('components:label.file-no-rows')}</NoResultsPanel>
      )}
    </Form>
  );
};

export default LabelRangePicker;
