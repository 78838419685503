import xlsx from 'xlsx';

export const getRows = (base64: string) => {
  let base624 = base64.substring(base64.indexOf(';') + 1);
  base624 = base624.replace('base64,', '');
  const buf = Buffer.from(base624, 'base64');
  const workbook = xlsx.read(buf, { type: 'buffer' });
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  let data = xlsx.utils.sheet_to_csv(sheet);
  if (typeof data === 'string') {
    data = new CSVFile(data.trim()).getData();
  }
  return data;
};

export const getMetaData = (base64: string) => {
  let base624 = base64.substring(base64.indexOf(';') + 1);
  base624 = base624.replace('base64,', '');
  const buf = Buffer.from(base624, 'base64');
  const workbook = xlsx.read(buf, { type: 'buffer' });
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  const rows: any[] = xlsx.utils.sheet_to_json(sheet);
  if (!rows?.length) return {};
  const columns: any = Object.keys(rows[0]);
  return {
    rows: rows.length,
    columns,
  };
};

export class CSVFile {
  rowSeparator: string;
  valueSeparator: string;
  data: any;
  isValid: boolean;

  constructor(data) {
    this.rowSeparator = '\n';
    this.valueSeparator = ',';
    this.data = [];
    this.isValid = false;
    this.parse(data);
  }

  parse(raw) {
    try {
      this.data = csvToArray(raw);
      this.isValid = true;
    } catch (e: any) {
      this.isValid = false;
    }
  }

  getData() {
    return this.data;
  }
}

function csvToArray(text) {
  let p = '',
    row = [''],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (',' === l && s) l = row[++i] = '';
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = '')];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}
