import React, { Suspense } from 'react';
import { PageWrap, Flex } from 'components';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 520px;

  & > ${PageWrap} {
    padding-left: 0;
    width: 100%;
  }
`;

const CloudPage = () => {
  const { t } = useTranslation('other');

  return (
    <Wrapper>
      <PageWrap>
        <Flex
          width="100%"
          height="100vh"
          alignItems="center"
          alignContent="center"
          flexDirection="row"
          justifyContent="center"
        >
          {t('other:connecting-to-onedrive')}
        </Flex>
      </PageWrap>
    </Wrapper>
  );
};

export const Cloud = () => (
  <Suspense fallback="">
    <CloudPage />
  </Suspense>
);

export default Cloud;
