import React from 'react';

import Notification from 'components/lib/notification/Notification';
import { useAppSelector, useReduxSelector, useReduxAction } from 'hooks';
import { getAllWorkspaces } from 'ducks/workspaces/selectors';
import { selectToastNotifications, removeFromToast } from 'state/Notification';

const NotificationList = () => {
  const notifications = useReduxSelector(selectToastNotifications);
  const workspaces = useAppSelector(getAllWorkspaces);
  const dismissNotification = useReduxAction(removeFromToast);

  if (!workspaces.length) {
    return null;
  }

  return (
    <>
      {notifications.map((not) => (
        <Notification
          key={not.uniqueId}
          img={not.img}
          title={not.title}
          footerText={not.footerText}
          onClose={() => dismissNotification(not.uniqueId)}
          detailedMessage={not.detailedMessage}
          timeoutOnClose={5000}
        />
      ))}
    </>
  );
};
export default NotificationList;
