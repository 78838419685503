import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  MarginProps,
  PaddingProps,
  LayoutProps,
  margin,
  padding,
  layout,
} from 'styled-system';
import Icon from '../icon/Icon';
import IconType from '../icon/IconType';

type variantType =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'destructive'
  | 'text-primary'
  | 'text-destructive'
  | 'basic';

export type ButtonPropsBase = {
  /**
   * Size of the Button
   */
  size: 'xl' | 'lg' | 'md' | 'sm';
  /**
   * Color Variant of the Button
   */
  variant: variantType;
  /**
   * Slim Button
   */
  slim: boolean;
  /**
   * Icon Alignment
   */
  icon: 'left' | 'right';
  /**
   * Color of the Icon
   */
  iconColor: string;
  /**
   * Size of the Icon
   */
  iconSize: number | string;
  /**
   * Name of the icon to be rendered
   */
  iconName: IconType;
  /**
   * Weight of The text on the Button
   */
  fontWeight: string;
  /**
   * Show Spinner if Loading
   */
  isLoading: boolean;
  /**
   * Click Function
   */
  onClick: React.MouseEventHandler;
  multiLine?: boolean;
};

export type ButtonProps = React.PropsWithChildren<Partial<ButtonPropsBase>> &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  MarginProps &
  PaddingProps &
  LayoutProps;

const RootButton = styled.button`
  display: flex;
  border-radius: ${({ theme }) => theme.px(4)};
  font-size: ${({ theme }) => theme.px(8)};
  letter-spacing: 0.4px;
  border: none;
  white-space: nowrap;
  outline: none;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-weight: 500;
`;
export const StyledIcon = styled(Icon)``;

export const LabelContainer = styled.span`
  font-family: ${({ theme }) => theme.defaultFont};
`;

const getStyle = (theme: DefaultTheme, variant?: string) => {
  switch (variant) {
    case 'primary':
      return getBase(
        theme.white,
        theme.primary.base,
        theme.primary.dark,
        theme.primary.lighter,
        theme.primary.dark,
        theme.primary.base,
      );
    case 'secondary':
      return getTextBase(
        theme.primary.base,
        theme.primary.dark,
        theme.primary.lighter,
        theme.primary.base,
      );
    case 'outline':
      return `
      color: ${theme.neutral.darkest};
      background-color: ${theme.white};
      background: transparent;
      box-shadow: 0 0 0 1px ${theme.neutral.light};
      &:hover {
        color: ${theme.neutral.base};
        ${StyledIcon} {
          path {
            fill: ${theme.neutral.base};
          }  
        }
      }
      &:disabled {
        color: ${theme.neutral.lighter};
        box-shadow: 0 0 0 1px ${theme.neutral.lighter};
        ${StyledIcon} {
          path {
            fill: ${theme.neutral.lighter};
          }  
        }
      }
      &:focus, &:not:disabled {
        box-shadow: 0 0 0 2px ${theme.primary.base};
      }
      ${StyledIcon} {
        path {
          fill: ${theme.textColors.high};
        }  
      }
      `;
    case 'destructive':
      return getBase(
        theme.white,
        theme.error.base,
        theme.error.dark,
        theme.error.lighter,
        theme.error.dark,
        theme.error.base,
      );
    case 'text-primary':
      return getTextBase(
        theme.primary.base,
        theme.primary.dark,
        theme.primary.lighter,
        theme.primary.base,
      );
    case 'text-destructive':
      return getTextBase(
        theme.error.base,
        theme.error.dark,
        theme.error.lighter,
        theme.error.base,
      );
    case 'basic':
      return getTextBase(
        theme.primary.base,
        theme.primary.dark,
        theme.primary.lighter,
        theme.textColors.high,
      );

    default:
      return getBase(
        theme.white,
        theme.primary.base,
        theme.primary.dark,
        theme.primary.lighter,
        theme.primary.dark,
        theme.primary.base,
      );
  }
};

export const StyledButton = styled(RootButton)<ButtonProps>`
  ${margin}
  ${padding}
  ${layout}
  flex-direction: ${({ icon }) => (icon === 'right' ? 'row-reverse' : 'row')};
  height: ${({ slim, theme }) => (slim ? theme.px(20) : theme.px(24))};
  padding: ${({ slim, theme }) => (slim ? theme.px(4, 4) : theme.px(6, 8))};
  ${({ theme, variant }) => getStyle(theme, variant)};
  ${({ size }) => size === 'xl' && 'min-width: 240px;'}
  ${({ size }) => size === 'lg' && 'min-width: 148px;'}
  ${({ size }) => size === 'md' && 'min-width: 80px;'}

  ${LabelContainer} {
    ${({ multiLine }) =>
      multiLine &&
      `white-space: normal;
      flex: 1; 
      text-align: left;`}
    ${({ size }) =>
      size === 'xl' &&
      `position: absolute;
      left: 50%;
      transform: translateX(-50%);`}
    ${({ size }) =>
      size === 'lg' &&
      `position: absolute;
      left: 50%;
      transform: translateX(-50%);`}
    ${({ size }) => size === 'md' && `margin: 0 auto;`}
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  ${({ fontWeight }) =>
    fontWeight &&
    `
    font-weight: ${fontWeight};
  `}
  ${({ icon, theme, size }) =>
    icon &&
    size !== 'xl' &&
    size !== 'lg' &&
    `
    padding-right: ${icon === 'left' ? theme.px(12) : theme.px(9)};
    padding-left: ${icon === 'left' ? theme.px(9) : theme.px(12)};
    ${LabelContainer} {
      margin-right: ${icon === 'right' ? theme.px(7) : 'unset'};
      margin-left: ${icon === 'left' ? theme.px(7) : 'unset'};
    }
  `}
`;

const getBase = (
  color: string,
  background: string,
  hoverBackground: string,
  disabledBackground: string,
  focusBackGround: string,
  boxShadow: string,
) => `
      color: ${color};
      background-color: ${background};
      transition: color .3s, background-color .3s;
      &:hover {
        background-color: ${hoverBackground};
      }
      &:disabled {
        background-color: ${disabledBackground};
      }
      &:focus, &:not:disabled {
        background-color: ${focusBackGround};
        box-shadow: 0 0 0 2px ${boxShadow};
      }
`;

const getTextBase = (
  color: string,
  hoverColor: string,
  disabledColor: string,
  focusColor: string,
) => `
      color: ${color};
      background-color: transparent;
      &:hover {
        color: ${hoverColor};
        ${StyledIcon} {
          path {
            fill: ${hoverColor};
          }  
        }
      }
      &:disabled {
        color: ${disabledColor};
        ${StyledIcon} {
          path {
            fill: ${disabledColor};
          }  
        }
      }
      &:focus, &:not:disabled {
        box-shadow: 0 0 0 2px ${focusColor};
      }
      ${StyledIcon} {
        path {
          fill: ${focusColor};
        }  
      }
      `;
