import styled from 'styled-components';

import { Flex } from 'components';
import { Text } from 'v2/components/common';

export const Container = styled(Flex)`
  height: 100%;
  flex-direction: column;
  padding: ${({ theme }) => theme.px(0, 20, 16)};
`;

export const BodyText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.med,
  variant: 'body',
}))``;

export const Table = styled(Flex)`
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
`;

export const Row = styled(Flex)<{ border?: boolean }>`
  min-height: 72px;
  align-items: center;
  ${({ theme, border = false }) =>
    border && `border-bottom: 1px solid ${theme.neutral.lightest};`}
`;

export const HeadRow = styled(Row)`
  position: sticky;
  top: 0;
  min-height: 32px;
  z-index: 10;

  background-color: ${({ theme }) => theme.white};
`;

export const PrinterImage = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background: ${({ theme }) => theme.primary.lightest};
  border-radius: 20px;

  & img {
    height: 28px;
  }
`;
