import styled from 'styled-components';
import { Card, Text, Flex, Box } from 'components';

export const PrintersListHolder = styled(Card)`
  width: 336px;
  height: 367px;
  align-items: center;
  justify-content: center;
  && {
    button {
      margin: 0 8px;
      width: 100%;
      max-width: 272px;
    }
  }
`;
export const StyledText = styled.div`
  position: relative;
  top: 0px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -22px;
  margin-right: -25px;
`;

export const FirstName = styled(Text).attrs(({ theme }) => ({
  color: theme.neutral.darkest,
}))`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

export const PrinterName = styled(Text)`
  overflow: hidden;
  width: 245px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CartridgeModel = styled(Text)`
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CarouselContainer = styled(Box)`
  width: 272px;
  align-self: center;
`;

export const CenteredFlex = styled(Flex)`
  margin: 0 -12px;
`;

export const Small = styled(Text).attrs(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 300,
  letterSpacing: 0.4,
  color: theme.neutral.darkest,
}))``;

export const PrinterCardInfo = styled(Flex)`
  flex: 1 1 50%;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
`;

export const CartridgeInfo = styled(Text).attrs(({ theme }) => ({
  fontWeight: 300,
  letterSpacing: 0.4,
  color: theme.neutral.darkest,
}))``;

export const CartridgeInfoStyles = {
  display: 'inline-block',
  margin: '0 5px',
  fontWeight: 300,
  letterSpacing: 0.4,
};
