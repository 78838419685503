import { createSelector } from 'reselect';
import { TemplatePage } from 'zsbpsdk/src/templates/index';

const templatesSuggestions = (state: any): TemplatePage =>
  state.search.templates.data;
const zebraTemplatesSuggestions = (state: any): TemplatePage =>
  state.search.zebraTemplates.data;
const categoriesSuggestions = (state: any) => state.search.categories.data;
const dataSourcesSuggestions = (state: any) => state.search.dataSources.data;
const dataSourcesParams = (state: any) => state.search.dataSources.params;

export const getTemplatesSuggestions = createSelector(
  [templatesSuggestions],
  (templatesSuggestionsPage: TemplatePage) =>
    templatesSuggestionsPage?.fileDataList?.length
      ? templatesSuggestionsPage.fileDataList
      : [],
);

export const getZebraTemplatesSuggestions = createSelector(
  [zebraTemplatesSuggestions],
  (zebraTemplatesSuggestionsPage: TemplatePage) =>
    zebraTemplatesSuggestionsPage?.fileDataList?.length
      ? zebraTemplatesSuggestionsPage.fileDataList
      : [],
);

export const getCategoriesSuggestions = createSelector(
  [categoriesSuggestions],
  (categoriesSuggestions = []) =>
    categoriesSuggestions.length ? categoriesSuggestions : [],
);

export const getDatasourcesSuggestions = createSelector(
  [dataSourcesSuggestions],
  (dataSourcesSuggestions = []) =>
    dataSourcesSuggestions.length ? dataSourcesSuggestions : [],
);

export const getDatasourcesParams = createSelector(
  [dataSourcesParams],
  (dataSourcesParams = '') =>
    dataSourcesParams.length ? dataSourcesParams : '',
);

//conditionally display mixed option titles & arr
export const getZebraTemplatesMixedSuggestions = createSelector(
  [categoriesSuggestions, zebraTemplatesSuggestions],
  (categoriesSuggestions = [], zebraTemplatesSuggestionsPage: TemplatePage) => {
    const options: any = [];
    if (categoriesSuggestions.length > 0) {
      options.push(
        { label: 'Categories', type: 'section' },
        ...categoriesSuggestions,
      );
    }
    const zebraTemplatesSuggestions =
      zebraTemplatesSuggestionsPage?.fileDataList;
    if (zebraTemplatesSuggestions?.length) {
      options.push(
        { label: 'Designs', type: 'section' },
        ...zebraTemplatesSuggestions,
      );
    }
    return options;
  },
);
