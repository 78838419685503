import React, { forwardRef, useEffect, useState, useCallback } from 'react';
import { Flex, Button, Modal, Box, Loader } from 'components';
import { actions as DataSourcesAction } from 'ducks/dataSources/actions';
import { useReduxAction } from 'hooks';
import FilesList from '../FilesList';

import { filteredDataSources } from 'ducks/dataSources/selectors';
import { actions as datasourcesActions } from 'ducks/dataSources/actions';
import { useFetchedData } from 'hooks';
import NoFiles from '../NoFiles';
import { Header } from '../Header';
import { useTranslation } from 'react-i18next';

const GlobalModalDialog = forwardRef(
  //TODO This shouldnt be needed anymore, but might be useful in the future if we have loads of files.
  ({ onClose, open, fileType }: any, ref: any) => {
    const { t } = useTranslation(['dataSources', 'common']);

    const [search] = useState('');
    const clearSelected = useReduxAction(DataSourcesAction.SELECTED.clear);
    const setSelectRow = useReduxAction(DataSourcesAction.SELECTED.request);
    // const uploading = useReduxSelector(isAddingFile);

    const [tempFileSelected, setTempFileSelected] = useState<any>({});
    const [allFiles, allFilesLoading] = useFetchedData(
      filteredDataSources,
      datasourcesActions.ALL.request,
      search,
    );

    useEffect(() => {
      clearSelected();
    }, [clearSelected]);

    const submitSelectedFile = useCallback(() => {
      setSelectRow({
        id: tempFileSelected.id,
        /*'provider': provider, */ name: tempFileSelected.name,
      }); //TODO no name. Is this an issue?
      ref.current.close();
      onClose(true);
    }, [onClose, ref, setSelectRow, tempFileSelected]);

    return (
      <Modal
        open={open}
        ref={ref}
        title={t('dataSources:select-data-source')}
        defaultPadding={false}
        footer={
          <Flex justifyContent={'space-between'}>
            {/* <Flex justifyContent={'flex-start'}>
              <Box>
                <Button
                  variant={'text-primary'}
                  onClick={() => {
                    clearSelected();
                    onClose();
                    ref.current.close();
                  }}
                >
                  Manage all data source files
                </Button>
              </Box>
            </Flex> */}
            <Flex justifyContent={'flex-end'}>
              <Button
                variant={'text-primary'}
                onClick={() => {
                  onClose();
                  ref.current.close();
                }}
              >
                {t('common:cancel')}
              </Button>
              <Button
                disabled={!tempFileSelected ? true : false}
                onClick={submitSelectedFile}
              >
                {t('common:select')}
              </Button>
            </Flex>
          </Flex>
        }
        clickOutside={false}
        onClose={onClose}
      >
        <Flex width={'1112px'} height={'589px'} flexDirection={'row'}>
          <Header hasData={true} />
          <Box width={'100%'} mt={40}>
            {allFilesLoading /*|| uploading*/ ? (
              <Flex
                justifyContent="center"
                height="100%"
                width="100%"
                alignItems="center"
              >
                <Loader visible={true} />
              </Flex>
            ) : allFiles && allFiles.length > 0 ? (
              <FilesList
                files={allFiles.filter((file) => {
                  let isImage = file.mimeType?.startsWith('image');
                  return fileType?.toLowerCase() === 'image'
                    ? isImage
                    : !isImage;
                })}
                onRowSelect={setTempFileSelected}
                hover={null}
              />
            ) : (
              <NoFiles />
            )}
          </Box>
        </Flex>
      </Modal>
    );
  },
);

export default GlobalModalDialog;
