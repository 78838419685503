import { LocalFileProvider } from './LocalFileProvider';
import {
  GOOGLE_PROVIDER_LABEL,
  GOOGLE_PROVIDER_LABEL_OLD,
  GoogleDriveFileProvider,
} from './GoogleDriveFileProvider';
import {
  MS_PROVIDER_LABEL,
  MS_PROVIDER_LABEL_OLD,
} from '../../services/office365';
import { MicrosoftOneDriveFileProvider } from './MicrosoftOneDriveFileProvider';
import { FileProvider } from './FileProvider';

export class FileProviderFactory {
  private static local: LocalFileProvider | undefined;
  private static googleDrive: GoogleDriveFileProvider | undefined;
  private static microsoftOneDrive: MicrosoftOneDriveFileProvider | undefined;

  static get localFileProvider() {
    this.local = this.local ?? new LocalFileProvider();
    return this.local;
  }

  static get googleDriveFileProvider() {
    this.googleDrive = this.googleDrive ?? new GoogleDriveFileProvider();
    return this.googleDrive;
  }

  static get microsoftOneDriveFileProvider() {
    this.microsoftOneDrive =
      this.microsoftOneDrive ?? new MicrosoftOneDriveFileProvider();
    return this.microsoftOneDrive;
  }

  static getInstance(file): FileProvider | null {
    if (file?.provider.startsWith('Local')) {
      return this.localFileProvider;
    } else {
      switch (file?.provider) {
        case GOOGLE_PROVIDER_LABEL:
        case GOOGLE_PROVIDER_LABEL_OLD:
          return this.googleDriveFileProvider;
        case MS_PROVIDER_LABEL:
        case MS_PROVIDER_LABEL_OLD:
          return this.microsoftOneDriveFileProvider;
      }
    }
    return null;
  }
}
