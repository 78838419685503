import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { useUrlParam } from 'hooks';
import { Box, Flex, Search, Text } from 'components';
import { useReduxSelector, useReduxAction } from 'hooks';
import { isLoadingFiltered } from 'ducks/templates/selectors';
import { actions as searchActions } from 'ducks/search/actions';
import { device } from 'utils/mediaQueries';
import { HeaderOverlay } from './Overlay';
import { FilesButtons } from './FilesButtons';
import { getDatasourcesSuggestions } from 'ducks/search/selectors';
import { getAllFilesData } from 'ducks/dataSources/selectors';
import { useTranslation } from 'react-i18next';

const SearchBox: any = styled(Box)`
  @media ${device.tablet} {
    width: 50%;
    max-width: 480px;
  }
  @media ${device.desktop} {
    width: 50%;
    max-width: 480px;
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: unset;
  }
`;

const HeaderWrap: any = styled(Flex).attrs({
  flexDirection: 'column',
})`
  top: -1px;
  z-index: 9; // 1 beneath SideNav too show shadow
  transition: box-shadow 0.3s;
  width: 100%;
  max-width: 100%;
  ${({ isSticky, theme }: any) =>
    isSticky &&
    `
    position: sticky;
    top: -300px;
    background-color: ${theme.primary.background};
    box-shadow: 0px 8px 16px rgba(73, 80, 94, 0.16);

    &::before,
    &::after {
      box-shadow: 0px 20px 16px -16px rgba(73, 80, 94, 0.16);
    }
  `}

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    height: 100%;
    background-color: ${({ theme }) => theme.primary.background};
    transition: box-shadow 0.3s;
  }

  ${({ screenWidth }: any) =>
    screenWidth > 1328 &&
    `
    &::before {
      left: calc((${screenWidth}px - 1340px)/-2);
      width: calc((${screenWidth}px - 1340px)/2);
    }
  
    &::after {
      right: calc((${screenWidth}px - 1340px)/-2);
      width: calc((${screenWidth}px - 1340px)/2);
    }
  `};

  @media ${device.desktop} {
    min-width: 736px;
  }
`;

const InnerWrap: any = styled(Flex).attrs({ flexDirection: 'column' })`
  position: relative;
  padding: 30px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
`;

const StyledFlex = styled(Flex)<any>`
  flex-direction: row;
  @media ${device.tablet} {
    padding: 0px;
  }
  @media ${device.mobile} {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
`;

const ButtonContainer = styled(Flex)`
  padding-left: 12px;
  @media ${device.mobile} {
    padding: 0;
    align-items: start;
    width: 100%;
  }
`;

export const Header = (props) => {
  const { t } = useTranslation('dataSources');

  const [isSticky, setIsSticky] = useState(false);
  const InnerWrapRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
  const suggestions = useReduxSelector(getDatasourcesSuggestions);
  const [search, setSearch] = useUrlParam('search', '');
  const theme = useContext(ThemeContext);
  const files = useReduxSelector(getAllFilesData);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (position > 300) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, []);

  const handleScreenWidth = useCallback(() => {
    setScreenWidth(document.body.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScreenWidth);

    return function () {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScreenWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchDataSources = useReduxAction(searchActions.DATA_SOURCES.request);
  const isLoading = useReduxSelector(isLoadingFiltered);

  useEffect(() => {
    setScreenWidth(document.body.clientWidth);
  }, [isLoading]);

  const onChange = async (value: string) => {
    searchDataSources(value);
  };

  return (
    <HeaderWrap isSticky={isSticky} screenWidth={screenWidth}>
      <HeaderOverlay screenWidth={screenWidth} />
      <InnerWrap ref={InnerWrapRef}>
        <Flex flexDirection={'row'} mb={'32px'}>
          <Text
            fontSize={14}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {t('dataSources:connect-your-files')}
          </Text>
        </Flex>
        {files && files.length > 0 && (
          <StyledFlex alignItems={'center'}>
            <SearchBox>
              <Search
                data-testid="search-files"
                placeholder={t('dataSources:search-files')}
                onChange={(e) => onChange(e)}
                options={suggestions}
                onSelect={setSearch}
                initialSearch={search}
              />
            </SearchBox>

            <ButtonContainer justifyContent={'flex-end'} ml={'auto'} pl={12}>
              <FilesButtons />
            </ButtonContainer>
          </StyledFlex>
        )}
      </InnerWrap>
    </HeaderWrap>
  );
};
