import React from 'react';
import {
  Group1,
  Group2,
  Group3,
  Group1WithBorder,
  Group2WithBorder,
} from './Slider.styles';

type BreakpointGroupProps = {
  total: number;
  showBorders?: boolean;
};

const BreakpointGroup = ({ total, showBorders }: BreakpointGroupProps) => {
  const FIRST_STEP = 0;
  const LAST_STEP = total - 1;
  return (
    <>
      {showBorders === true
        ? [...Array(total)].map((e, step) =>
            step === LAST_STEP ? (
              <Group1WithBorder noOfBreakpoints={+total} key={`line-${step}`} />
            ) : step === FIRST_STEP ? (
              <Group2WithBorder noOfBreakpoints={+total} key={`line-${step}`} />
            ) : (
              <Group3 noOfBreakpoints={+total} key={`line-${step}`} />
            ),
          )
        : [...Array(total)].map((e, step) =>
            step === LAST_STEP ? (
              <Group1 noOfBreakpoints={+total} key={`line-${step}`} />
            ) : step === FIRST_STEP ? (
              <Group2 noOfBreakpoints={+total} key={`line-${step}`} />
            ) : (
              <Group3 noOfBreakpoints={+total} key={`line-${step}`} />
            ),
          )}
    </>
  );
};

export default BreakpointGroup;
