import React, { useContext, useRef, useMemo, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

import { Box, Flex } from 'components';
import {
  COMMON_DESIGNS_PATH,
  HOME_PAGE_PATH,
  MY_DATA_SOURCES_PATH,
  MY_DESIGNS_PATH,
  MY_APPS_PATH,
  MY_NOTIFICATIONS_PATH,
} from 'utils/config';
import Video from 'components/common/page/video';
import { useReduxSelector } from 'hooks';
import { getPathName } from 'ducks/router/selectors';

import Announcements from './Announcements';

const Header = styled(Box)`
  height: 217px;
  overflow: hidden;
  z-index: 1;
  transition: left 0.6s ease-out, width 0.6s ease-out, opacity 0.6s ease-out;

  video {
    width: 100%;
    height: 520px;
    object-fit: cover;
    transform: translateY(-272px);
  }
`;

const PageHeader = () => {
  const pathname = useReduxSelector(getPathName);
  const theme = useContext(ThemeContext);

  const videoRef = useRef<HTMLVideoElement>(null);

  const videoPath = useMemo(() => {
    if (pathname.startsWith(HOME_PAGE_PATH))
      return `themes/${theme.name}/1.mp4`;
    else if (
      pathname === MY_APPS_PATH ||
      pathname === COMMON_DESIGNS_PATH ||
      pathname === MY_DESIGNS_PATH ||
      pathname === MY_NOTIFICATIONS_PATH
    )
      return `themes/${theme.name}/2.mp4`;
    else if (pathname === MY_DATA_SOURCES_PATH)
      return `themes/${theme.name}/3.mp4`;

    return null;
  }, [pathname, theme]);

  useEffect(() => {
    if (videoRef.current && videoPath) {
      videoRef.current.src = videoPath;
    }
  }, [videoPath]);

  return (
    <Flex flexDirection={'column'}>
      <Box position={'relative'}>
        {pathname.startsWith(HOME_PAGE_PATH) && <Announcements />}
      </Box>

      {videoPath && (
        <Header>
          <Video path={videoPath} ref={videoRef} />
        </Header>
      )}
    </Flex>
  );
};

export default PageHeader;
