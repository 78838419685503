import * as React from 'react';
import { Box, Loader, Flex, Text } from 'components';
import styled, { ThemeContext } from 'styled-components/macro';
import { DriversCardComponent } from './DriversCardComponent';
import { useAppDispatch, useReduxSelector, useReduxAction } from 'hooks';
import { useEffect } from 'react';
import {
  getPrinterTools,
  getPrinterToolsError,
  getPrinterToolsLoading,
} from 'ducks/info/selectors';
import { actions as infoActions } from 'ducks/info/actions';
import { openErrorToast } from 'state/Toast';
import { useTranslation } from 'react-i18next';

const H2 = styled.h2`
  color: ${({ theme }) => theme.textColors.med};
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.4px;
  font-style: normal;
`;

const StyledText = styled(Text)`
  max-width: 960px;
`;

export const Drivers = (): any => {
  const { t } = useTranslation('apps');

  const dispatch = useAppDispatch();
  const getTools = useReduxAction(infoActions.PRINTER_TOOLS.request);
  // const getLinksAction = useReduxAction(infoActions.LINKS.request);
  const isLoading = useReduxSelector(getPrinterToolsLoading);
  const error = useReduxSelector(getPrinterToolsError);
  const driverInfo = useReduxSelector(getPrinterTools);
  // const links = useReduxSelector(getLinks);
  const theme = React.useContext(ThemeContext);

  useEffect(() => {
    if (!driverInfo) getTools();
    // if (!links) getLinksAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) dispatch(openErrorToast(t('apps:could-not-retrieve-apps')));
  }, [dispatch, error, t]);

  useEffect(() => {
    console.debug('%c Drivers ', 'background:brown;color:white', driverInfo);
  }, [driverInfo]);
  return (
    <>
      <Flex flexDirection={'column'} mb={'32px'}>
        <StyledText
          data-testid="printer-driver-and-apps-description"
          fontSize={14}
          lineHeight={'24px'}
          letterSpacing={0.4}
          color={theme.textColors.med}
          mb={'32px'}
        >
          {t('apps:printer-driver-and-apps')}
        </StyledText>
        <StyledText
          data-testid="printer-driver-and-apps-download-text"
          fontSize={14}
          lineHeight={'24px'}
          fontWeight={'400'}
          letterSpacing={0.4}
          color={theme.textColors.med}
        >
          {t('apps:click-to-download')}
        </StyledText>
      </Flex>
      {isLoading ? (
        <Loader visible={isLoading} />
      ) : (
        <Box>
          <H2>{t('apps:windows')}</H2>
          <DriversCardComponent
            data-driver-os="windows"
            os="Windows"
            info={driverInfo?.windows}
          />
          <Box height="49px" />
          <H2>{t('apps:apple')}</H2>
          <DriversCardComponent
            data-driver-os="mac"
            os="Mac"
            info={driverInfo?.mac}
          />
        </Box>
      )}
    </>
  );
};
