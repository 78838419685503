import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useUrlParam } from 'hooks';
import {
  Flex,
  Button,
  Search,
  Dropdown,
  Text,
  Icon,
  Pagination,
  Checkbox,
} from 'components';
import { useReduxSelector, useReduxAction, useModal } from 'hooks';
import {
  filteredTemplates,
  zebraFilteredTemplates,
  getAllUserTemplates,
  zebraFilteredTemplatesAllSizes,
  myDesignSizeSelector,
  isLoadingMyDesignSizeSelector,
  myDesignSizeError,
} from 'ducks/templates/selectors';
import {
  filteredCategories,
  selectCategoriesWithLabel,
} from 'ducks/categories/selectors';
import {
  getTemplatesSuggestions,
  getZebraTemplatesSuggestions,
  getZebraTemplatesMixedSuggestions,
} from 'ducks/search/selectors';
import { actions as searchActions } from 'ducks/search/actions';
import { actions as templateActions } from 'ducks/templates/actions';
import { getPathName } from 'ducks/router/selectors';
import { ThemeContext } from 'styled-components/macro';
import NewLabelModal from './NewLabelModal';
import { device } from 'utils/mediaQueries';
import { HeaderOverlay } from './Overlay';
import ImportButton from './ImportButton';
import { push } from 'connected-react-router';
import { getEtch } from 'utils/unitFormatter';
import { getUnit } from 'ducks/preferences/selectors';
import { TemplatePage, TemplateType } from 'zsbpsdk/src/templates/index';
import { BaseInitialState } from '../../../ducks/utils';
import {
  PAGE_NUMBER_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SORT_DEFAULT,
} from 'utils/defaults';
import { DOCUMENT_MAX_PAGE_SIZE } from '../../../services/api';
import { useMediaQuery } from 'react-responsive';
import { COMMON_DESIGNS_PATH, MY_DESIGNS_PATH } from '../../../utils/config';
import { useTranslation } from 'react-i18next';
import { actions as labelSizesActions } from '../../../ducks/labelSizes/actions';
import {
  HeaderWrap,
  FilterResulText,
  TemplateActionsWrap,
  InnerWrap,
  BackToLibraryWrap,
  SearchBox,
  DropdownContainer,
  DropdownWrapper,
  StyledFlex,
  FixingDiv,
} from './Header.styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useMultiSelect } from 'providers/MultipleSelect';

const optionsSort = [
  { value: 'asc', label: 'Name (A to Z)' },
  { value: 'desc', label: 'Name (Z to A)' },
];

const buildPageSizeOption = (pageSize: string) => {
  return {
    value: pageSize,
    label: `Show ${pageSize} item${pageSize === '1' ? '' : 's'}`,
  };
};

const optionsPageSize = [
  buildPageSizeOption('15'),
  buildPageSizeOption(PAGE_SIZE_DEFAULT),
  buildPageSizeOption('90'),
];

const BackToLibrary = ({ selectedCategory, theme, ...props }: any) => {
  return (
    <BackToLibraryWrap {...props}>
      <Icon icon={'back-arrow'} color={theme.neutral.base} />
      <Text
        data-testid="header-selected-category-text"
        ml={[9]}
        fontSize={24}
        lineHeight={'32px'}
        letterSpacing={0.4}
        color={theme.textColors.high}
      >
        {selectedCategory}
      </Text>
    </BackToLibraryWrap>
  );
};

export const Header = () => {
  const isMobile = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation(['common', 'templates']);
  const location = useLocation();
  const history = useHistory();
  const [{ selected: selectedTemplates }, { setSelected, clear }] =
    useMultiSelect();

  const [pageNumber, setPageNumber] = useUrlParam(
    'pageNumber',
    PAGE_NUMBER_DEFAULT,
  );

  const [pageSize, setPageSize] = useUrlParam('pageSize', PAGE_SIZE_DEFAULT);

  const [size, setSize] = useUrlParam('size', '-1');
  const [sort, setSort] = useUrlParam('sort', SORT_DEFAULT);

  const [search, setSearch] = useUrlParam('search', '');
  const [searchLibrary, setSearchLibrary] = useUrlParam('searchLibrary', '');

  const [showHeaderShadow, setShowHeaderShadow] = useState(false);
  const InnerWrapRef = useRef(null);
  const newLabelModalRef = useRef(null);
  const { open: openNewLabelModal } = useModal(newLabelModalRef);
  const theme = useContext(ThemeContext);
  const pathname = useReduxSelector(getPathName);
  const isLibrary = pathname?.includes('common');
  const unit = useReduxSelector(getUnit);
  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);

  const [selectedCategory, setSelectedCategory] = useUrlParam('category', '');

  const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (position > 300) {
      setShowHeaderShadow(true);
    } else {
      setShowHeaderShadow(false);
    }
  }, []);

  const handleScreenWidth = useCallback(() => {
    setScreenWidth(document.body.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScreenWidth);

    const pageNumberInt = parseInt(pageNumber);
    if (pageNumberInt < 1 || isNaN(pageNumberInt)) {
      setPageNumber(PAGE_NUMBER_DEFAULT);
    }

    const pageSizeInt = parseInt(pageSize);

    if (pageSizeInt > DOCUMENT_MAX_PAGE_SIZE) {
      setPageSize(String(DOCUMENT_MAX_PAGE_SIZE));
    } else if (pageSizeInt < 1 || isNaN(pageSizeInt)) {
      setPageSize(PAGE_SIZE_DEFAULT);
    }

    return function () {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScreenWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, setPageSize]);

  const searchTemplates = useReduxAction(searchActions.TEMPLATES.request);
  const searchLibraryTemplates = useReduxAction(
    searchActions.ZEBRA_TEMPLATES.request,
  );
  const searchCategories = useReduxAction(searchActions.CATEGORIES.request);
  const options = useReduxSelector(getTemplatesSuggestions);
  const libraryOptions = useReduxSelector(getZebraTemplatesSuggestions);
  const allUserTemplates = useReduxSelector(getAllUserTemplates);

  const libraryMixedOptions = useReduxSelector(
    getZebraTemplatesMixedSuggestions,
  );
  const allLabelSizesActionRequest = useReduxAction(
    templateActions.ZEBRA_FILTERED_ALL_SIZES.request,
  );
  const {
    data: filteredTemplatesData,
    params: filteredTemplatesParams,
    loading: tfLoading,
  } = useReduxSelector(filteredTemplates);
  const filteredMyDesignTemplates: TemplateType[] = useMemo(
    () =>
      filteredTemplatesData?.fileDataList
        ? filteredTemplatesData.fileDataList
        : [],
    [filteredTemplatesData?.fileDataList],
  );
  const filteredMyDesignTemplatesTotalCount = filteredTemplatesData?.totalCount
    ? filteredTemplatesData.totalCount
    : 0;

  const {
    data: zebraFilteredTemplatesData,
    loading: isLoading,
  }: BaseInitialState<TemplatePage> = useReduxSelector(zebraFilteredTemplates);
  const filteredCategoryTemplates: TemplateType[] =
    zebraFilteredTemplatesData?.fileDataList
      ? zebraFilteredTemplatesData.fileDataList
      : [];
  const filteredCategoryTemplatesTotalCount =
    zebraFilteredTemplatesData?.totalCount
      ? zebraFilteredTemplatesData.totalCount
      : 0;

  const { data: cFiltered = [], loading: cfLoading } =
    useReduxSelector(filteredCategories);

  const { data: allCategories } = useReduxSelector(selectCategoriesWithLabel);

  const filtering = tfLoading || cfLoading;
  const filteredResults = !isLibrary
    ? filteredMyDesignTemplates.length + cFiltered.length
    : filteredCategoryTemplates.length + cFiltered.length;

  //Is there a better way to do this in fewer calls:
  const myDesignSizes = useReduxSelector(myDesignSizeSelector);
  const myDesignSizesError = useReduxSelector(myDesignSizeError);
  const myDesignSizeIsLoading = useReduxSelector(isLoadingMyDesignSizeSelector);
  const categorySizes = useReduxSelector(zebraFilteredTemplatesAllSizes);
  const [optionsMyDesignSizes, setOptionsMyDesignSizes] = useState([]);
  const [optionsCategorySizes, setOptionsCategorySizes] = useState([]);
  const getMyDesignSizesAction = useReduxAction(
    templateActions.MY_DESIGN_ALL_SIZES.request,
  );
  const myDesignSizesClearAction = useReduxAction(
    templateActions.MY_DESIGN_ALL_SIZES.clear,
  );

  //I.e applying filter or requesting another page
  //We clear the selected tempaltes if there is a change in the query params.
  useEffect(() => {
     clear();
  }, [filteredMyDesignTemplates, location.pathname,clear]);

  useEffect(() => {
    getAllLabelSizes();
  }, [getAllLabelSizes]);

  useEffect(() => {
    if (
      location.pathname === MY_DESIGNS_PATH &&
      !myDesignSizeIsLoading &&
      !myDesignSizes &&
      !myDesignSizesError &&
      filteredMyDesignTemplatesTotalCount
    ) {
      getMyDesignSizesAction();
    }
  }, [
    myDesignSizeIsLoading,
    getMyDesignSizesAction,
    myDesignSizes,
    myDesignSizesClearAction,
    location.pathname,
    myDesignSizesError,
    filteredMyDesignTemplatesTotalCount,
  ]);

  useEffect(() => {
    return () => {
      if (
        myDesignSizes ||
        (history.location.pathname !== MY_DESIGNS_PATH && myDesignSizes)
      ) {
        myDesignSizesClearAction();
      }
    };
  }, [
    history,
    history.location.pathname,
    myDesignSizes,
    myDesignSizesClearAction,
  ]);

  // To avoid missing header tools when a certain size is selected but there is no templates by selected criteria
  // (ex. if there is 1 template and the user delete it)
  useEffect(() => {
    if (
      location.pathname === MY_DESIGNS_PATH &&
      !filteredMyDesignTemplates.length &&
      size !== '-1'
    ) {
      setSize('-1');
    }
    if (
      location.pathname === COMMON_DESIGNS_PATH &&
      !filteredCategoryTemplates.length &&
      size !== '-1'
    ) {
      setSize('-1');
    }
  }, [
    filteredCategoryTemplates.length,
    filteredMyDesignTemplates.length,
    location.pathname,
    setSize,
    size,
  ]);

  useEffect(() => {
    if (myDesignSizes) {
      setOptionsMyDesignSizes(getOptions(myDesignSizes, unit));
    }
  }, [myDesignSizes, unit]);

  useEffect(() => {
    const sizesValue = categorySizes ? Object.values(categorySizes.sizes) : [];
    setOptionsCategorySizes(getOptions(sizesValue, unit));
  }, [categorySizes, unit]);

  useEffect(() => {
    if (
      selectedCategory &&
      selectedCategory.replace('/', '-') !== categorySizes?.selectedCategory
    ) {
      allLabelSizesActionRequest({ selectedCategory: selectedCategory });
    }
  }, [selectedCategory, allLabelSizesActionRequest, categorySizes]);

  const getOptions = (sizes, unit) =>
    sizes
      .sort((a, b) =>
        a.width !== b.width ? a.width - b.width : a.height - b.height,
      )
      .map((s: any) => {
        return {
          value: `${s.width}x${s.height}`,
          label: getEtch(s, unit),
        };
      });

  const getSize = (size, options) => {
    return options.find((o) => o.value === size)?.label ?? '-1';
  };

  useEffect(() => {
    setScreenWidth(document.body.clientWidth);
  }, [isLoading]);

  const pushAction = useReduxAction(push);

  useEffect(() => {
    if (selectedCategory) {
      setSearchLibrary('');
    } else if (isLibrary) {
      pushAction(COMMON_DESIGNS_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    setPageNumber(PAGE_NUMBER_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLibrary, pageSize]);

  const handleBacktoLibrary = () => {
    setSelectedCategory('');
  };

  // The search request we make to NL needs the category name
  // to be delimited with '-' instead of '/'.
  // For example on our side we have 'Multipurpose/Name Tag'
  // and we change it to 'Multipurpose-Name Tag'.
  const categoryNameNormalize = (categoryName) => {
    if (categoryName && categoryName.includes('/')) {
      let category = categoryName.replace('/', '-');
      return category;
    }
    return categoryName;
  };

  const onLibrarySearch = useCallback(
    (value: string) => {
      searchLibraryTemplates({
        value: value,
        category: categoryNameNormalize(selectedCategory),
      });
      searchCategories(value);
    },
    [searchCategories, searchLibraryTemplates, selectedCategory],
  );

  const onSelectSearch = (value: string) => {
    searchCategories(value);
    setSearchLibrary(value);
  };

  //This is really ugly but it's needed in order to filter the categories
  //after a refresh when a searchString is present
  //Ideally should be execute just once after a browser refresh
  React.useEffect(() => {
    if (allCategories.length && searchLibrary) {
      searchCategories(searchLibrary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories.length, onLibrarySearch]);

  const onChange = useCallback(
    (value: string) => {
      searchTemplates(value);
    },
    [searchTemplates],
  );

  const openNewLabelModalHandler = useCallback(() => {
    openNewLabelModal();
  }, [openNewLabelModal]);

  const startRange =
    ((parseInt(filteredTemplatesParams?.pageNumber) || 1) - 1) *
    parseInt(filteredTemplatesParams?.pageSize);

  const selectedText =
    selectedTemplates.length === filteredMyDesignTemplates.length
      ? t('common:selected-count-of', {
          from: startRange + 1,
          to: startRange + filteredMyDesignTemplates.length,
          total: filteredMyDesignTemplatesTotalCount,
        })
      : t('common:selected-count', {
          count: selectedTemplates.length,
        });

  return (
    <HeaderWrap showHeaderShadow={showHeaderShadow} screenWidth={screenWidth}>
      <HeaderOverlay screenWidth={screenWidth} />
      <InnerWrap ref={InnerWrapRef}>
        <Flex flexDirection={'row'} mb={'32px'}>
          <Text
            fontSize={14}
            lineHeight={'32px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {isLibrary
              ? t('templates:access-pre-made-designs')
              : t('templates:access-saved-designs')}
          </Text>
        </Flex>
        <Flex
          flexDirection={'row'}
          flexWrap="wrap"
          alignItems={'center'}
          justifyContent={'space-between'}
          data-testid="asdf"
        >
          {isLibrary ? (
            <SearchBox>
              <Search
                key={searchLibrary}
                placeholder={t('templates:search-common-designs')}
                data-testid="input-search"
                onChange={onLibrarySearch}
                options={
                  selectedCategory ? libraryOptions : libraryMixedOptions
                }
                onSelect={onSelectSearch}
                initialSearch={searchLibrary}
              />
            </SearchBox>
          ) : (
            <SearchBox>
              <FixingDiv />
              <Search
                placeholder={t('templates:search-your-designs')}
                data-testid="input-search"
                onChange={onChange}
                options={options}
                onSelect={setSearch}
                initialSearch={search}
                disabled={allUserTemplates.length < 1}
              />
            </SearchBox>
          )}

          <TemplateActionsWrap>
            {!isLibrary && (
              <>
                <ImportButton
                  data-testid="import-label-design-button"
                  buttonText={
                    isMobile
                      ? t('common:import')
                      : t('templates:import-a-design')
                  }
                />
                {allUserTemplates.length > 0 && (
                  <Button
                    data-testid="create-label-design-button"
                    icon={'right'}
                    iconName={'add'}
                    iconColor={theme.white}
                    variant={'primary'}
                    onClick={openNewLabelModalHandler}
                    iconSize={16}
                    className="workspace-button"
                  >
                    {isMobile
                      ? t('common:create')
                      : t('templates:create-new-design')}
                  </Button>
                )}
              </>
            )}
          </TemplateActionsWrap>
        </Flex>

        {((!isLibrary && filteredMyDesignTemplates.length > 0) ||
          (isLibrary && selectedCategory)) && (
          <StyledFlex
            data-testid="current-number-templates"
            mt={[11]}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {isLibrary && selectedCategory && (
              <BackToLibrary
                onClick={handleBacktoLibrary}
                theme={theme}
                selectedCategory={selectedCategory}
              />
            )}
            {!isLibrary && selectedTemplates.length > 0 && (
              <Flex>
                <Flex mr={2}>
                  <Checkbox
                    checked={selectedTemplates.length > 0}
                    indeterminate={
                      selectedTemplates.length <
                      filteredMyDesignTemplates.length
                    }
                    isTriState={true}
                    onClick={() => {
                      setSelected(
                        selectedTemplates.length <
                          filteredMyDesignTemplates.length
                          ? filteredMyDesignTemplates
                          : [],
                      );
                    }}
                  />
                </Flex>
                <FilterResulText data-testid="filter-result">
                  {selectedTemplates.length > 0
                    ? selectedText
                    : `${t('common:showing-designs', {
                        length: filteredMyDesignTemplates.length,
                        count: filteredMyDesignTemplatesTotalCount,
                      })}`}
                </FilterResulText>
              </Flex>
            )}
            {!isLibrary &&
              filteredMyDesignTemplates.length > 0 &&
              selectedTemplates.length === 0 && (
                <FilterResulText data-testid="filter-result">
                  {!isLibrary
                    ? isLoading
                      ? ''
                      : `${t('common:showing-designs', {
                          length: filteredMyDesignTemplates.length,
                          count: filteredMyDesignTemplatesTotalCount,
                        })}`
                    : !searchLibrary
                    ? t('templates:browse-categories')
                    : `${t('templates:search-results')} ${
                        !filtering ? '(' + filteredResults + ')' : ''
                      }`}
                </FilterResulText>
              )}
            {(filteredMyDesignTemplates.length > 0 ||
              filteredCategoryTemplates.length > 0) && (
              <DropdownContainer>
                <DropdownWrapper width={204}>
                  <Dropdown
                    data-testid="template-size-filter"
                    value={getSize(
                      size,
                      isLibrary ? optionsCategorySizes : optionsMyDesignSizes,
                    )}
                    noOutline={true}
                    options={[
                      { value: '-1', label: 'All sizes' },
                      ...(isLibrary
                        ? optionsCategorySizes
                        : optionsMyDesignSizes),
                    ]}
                    onChange={setSize}
                  />
                </DropdownWrapper>
                <DropdownWrapper width={168}>
                  <Dropdown
                    data-testid="template-name-filter"
                    value={sort || SORT_DEFAULT}
                    options={optionsSort}
                    onChange={setSort}
                    noOutline={true}
                  />
                </DropdownWrapper>
                <DropdownWrapper width={176}>
                  <Dropdown
                    data-testid="page-size-dropdown"
                    value={pageSize || PAGE_SIZE_DEFAULT}
                    options={optionsPageSize}
                    onChange={setPageSize}
                    noOutline={true}
                  />
                </DropdownWrapper>
              </DropdownContainer>
            )}
          </StyledFlex>
        )}
      </InnerWrap>

      {(!isLibrary || selectedCategory) && // Only show if in My Designs or Common Designs templates (i.e. not Common Designs categories)
        (isLibrary
          ? filteredCategoryTemplatesTotalCount
          : filteredMyDesignTemplatesTotalCount) > parseInt(pageSize) && // Only show if single displayed page cannot fit everything
        !isLoading &&
        !tfLoading &&
        !cfLoading && ( // Only show once finished loading paginated items
          <Pagination
            pageCount={Math.ceil(
              (isLibrary
                ? filteredCategoryTemplatesTotalCount
                : filteredMyDesignTemplatesTotalCount) / parseInt(pageSize),
            )}
            onPageChange={({ selected: pageNumber }) => {
              setPageNumber(pageNumber + 1);
            }}
            forcePage={(() => {
              const page = parseInt(pageNumber);
              return isNaN(page) ? undefined : page - 1;
            })()}
          />
        )}
      <NewLabelModal ref={newLabelModalRef} />
    </HeaderWrap>
  );
};
