import React, {
  useRef,
  useMemo,
  useContext,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components/macro';
import { Box, Text, Flex, Modal, Button } from 'components';
import { useModal, useWindowDimensions, useReduxSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Arrow } from './DriversModal.styles';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import {
  DRIVERS_MODAL_SHOWN_COUNT_PREFIX,
  DRIVERS_MODAL_SHOWN_MAX_COUNT,
} from 'utils/config';
import { CustomerInfoType } from 'zsbpsdk/src/customer';
import { getCustomer } from '../../ducks/auth/selectors';

const POPUP_WIDTH = 277;

export const getModalsCountKey = (customer: CustomerInfoType) =>
  customer && `${DRIVERS_MODAL_SHOWN_COUNT_PREFIX}-${customer.id}`;

interface Props {
  menuItemRef?: MutableRefObject<HTMLDivElement | null>;
  textReady: boolean;
}

const DriversModal = ({ menuItemRef, textReady }: Props) => {
  const { t } = useTranslation(['settings']);

  const customer = useReduxSelector(getCustomer);
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const driversModalRef = useRef(null);
  const driversModalRefHook = useModal(driversModalRef);
  const driversElement: DOMRect | undefined =
    menuItemRef?.current?.getBoundingClientRect();

  const driversModalStyles = useMemo(() => {
    if (!driversElement || driversElement === null || !textReady) return;
    return {
      overflow: 'visible',
      maxWidth: `${POPUP_WIDTH}px`,
      left:
        width > 975
          ? `${driversElement.x + driversElement.width + POPUP_WIDTH / 2}px`
          : '50%',
      top:
        width > 975
          ? `${driversElement.y + driversElement.height / 2}px`
          : '50%',
    };
  }, [driversElement, width, textReady]);

  useEffect(() => {
    const driversModalShownCount = getFromLocalStorage(
      getModalsCountKey(customer),
    );

    if (driversModalShownCount !== null) {
      const count = Number(driversModalShownCount);
      if (count >= DRIVERS_MODAL_SHOWN_MAX_COUNT) {
        setIsOpen(false);
      } else {
        saveToLocalStorage(getModalsCountKey(customer), count + 1);
        setIsOpen(true);
      }
    } else {
      saveToLocalStorage(getModalsCountKey(customer), 1);
      setIsOpen(true);
    }
  }, [customer]);

  return (
    <Modal
      data-testid="drivers-modal"
      style={driversModalStyles}
      ref={driversModalRef}
      defaultPadding={false}
      headerPadding={'20px'}
      open={isOpen}
      hasCloseButton={false}
      clickOutside={true}
      title={
        <Box>
          <Text color={theme.error.base} lineHeight={1.25}>
            {t('settings:drivers-download-modal.heading')}
          </Text>
        </Box>
      }
      footer={
        <Flex justifyContent={'center'}>
          <Button
            data-testid="drivers-modal-close"
            variant={'primary'}
            onClick={() => {
              driversModalRefHook.close();
            }}
          >
            {t('settings:drivers-download-modal.ok-button-text')}
          </Button>
        </Flex>
      }
    >
      <Arrow />
      <Box paddingX={'20px'}>
        <Text mb={'16px'}>{t('settings:drivers-download-modal.content')}</Text>
      </Box>
    </Modal>
  );
};

export default DriversModal;
