export enum HelpChatCustomField {
  MoneybadgerChatCountry = 'MoneybadgerChatCountry__c',
  Country = 'Country__c',
  MoneybadgerPrinterNames = 'MoneyBadger_Printer_Names__c',
  FirstName = 'First_Name__c',
  LastName = 'Last_Name__c',
  Email = 'Email__c',
  PreChatFormSubject = 'Pre_Chat_Form_Subject__c',
  Subject = 'Subject__c',
  ModelNumber = 'Model_Number__c',
  MbPrinterFriendlyName = 'MB_Printer_Friendly_Name__c',
  ContactStatus = 'Contact_Status__c',
  UserZuid = 'UserZuid__c',
  ChatBelongsTo = 'Chat_belongs_to__c',
  ContactPingZuid = 'Contact_Ping_ZUID__c',
  MbProblemCategory = 'MBProblem_Category__c',
  ProblemDescription = 'Problem_Description__c',
  ProductModel = 'Product_Model__c',
}
