import React from 'react';

import styled from 'styled-components';

import { Flex, Box } from 'components';

import PrinterStatusGraphic from './PrinterStatusGraphic';
import { PrinterStatus } from '../PrintersList/utils';
import { useTranslation } from 'react-i18next';

const PrinterStatusText = styled(Box)`
  color: #1d1e23;
  font-size: 14px;
`;

const PrinterStatusIndication = styled(Flex)<{
  status: PrinterStatus;
}>`
  background-color: rgba(29, 30, 35, 0.08);
  width: fit-content;
  height: 24px;
  border: none;
  padding: 2px;
  text-align: center;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.neutral.base};
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  ${({ status }) =>
    (status === PrinterStatus.Online &&
      `
    background-color: #ECFFF7;
    border-color: #84DAB6;
  `) ||
    ((status === PrinterStatus.CoverOpen ||
      status === PrinterStatus.MediaLow ||
      status === PrinterStatus.BufferFull) &&
      `
    background-color: #FEF2E2;
    border-color: #F5A230;`) ||
    ((status === PrinterStatus.MediaOut ||
      status === PrinterStatus.UnableToPrint ||
      status === PrinterStatus.PaperOut) &&
      `
    background-color: #FFF0F1;
    border-color: #F36170;`)}
`;

export default function PrinterStatusChip({
  status,
}: {
  status: PrinterStatus;
}) {
  const { t } = useTranslation();

  return (
    <PrinterStatusIndication
      flexDirection="column"
      status={status}
      data-testid="printer-status-indicator"
    >
      <PrinterStatusGraphic status={status} />
      <PrinterStatusText data-testid="printer-status-text">
        {t(`components:printer.status.${status}`)}
      </PrinterStatusText>
    </PrinterStatusIndication>
  );
}
