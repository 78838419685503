import { useState, useEffect } from 'react';

export type ModalRef = {
  isOpen: boolean;
  isMounted: () => boolean;
  open: () => void;
  toggle: () => void;
  close: () => void;
};

let init = false;

export const useModal = (
  ref: { current: any },
  initOpen: boolean = false,
): ModalRef => {
  const [refCurrent, setRefCurrent] = useState(ref.current || {});
  useEffect(() => {
    setRefCurrent(ref.current);
  }, [ref]);

  useEffect(() => {
    if (refCurrent && refCurrent.open && !init && initOpen) {
      refCurrent.open();
      init = true;
    }
  }, [initOpen, refCurrent]);

  useEffect(() => {
    return () => {
      init = false;
    };
  }, []);

  return {
    toggle: refCurrent?.toggle,
    open: refCurrent?.open,
    close: refCurrent?.close,
    isOpen: refCurrent?.isOpen,
    isMounted: refCurrent?.isMounted,
  };
};
