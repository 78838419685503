const BASE_DUCK_PREFIX = 'SMB_MODAL_DIALOG';

export const generateModalDialogTypes = (prefix: string): ModalDialogTypes => ({
  OPEN: `${prefix}_OPEN`,
  CONFIRM: `${prefix}_CONFIRM`,
  CLOSE: `${prefix}_CLOSE`,
});

export const generateModalDialogActions = (types: ModalDialogTypes) => ({
  open: (payload: any) => ({
    type: types.OPEN,
    payload: payload,
  }),
  confirm: (payload) => ({
    type: types.CONFIRM,
    payload: payload,
  }),
  close: () => ({
    type: types.CLOSE,
  }),
});

export const types = {
  OVERWRITE_FILE: generateModalDialogTypes(BASE_DUCK_PREFIX + 'OVERWRITE_FILE'),
  OUTAGE: generateModalDialogTypes(BASE_DUCK_PREFIX + 'OUTAGE'),
};

export interface ModalDialogAction {
  payload?: any;
}

export interface ModalDialogTypes {
  OPEN: string;
  CONFIRM: string;
  CLOSE: string;
}

export const actions = {
  OVERWRITE_FILE: generateModalDialogActions(types.OVERWRITE_FILE),
  OUTAGE: generateModalDialogActions(types.OUTAGE),
};
