import { createSelector } from 'reselect';

export const isOverwriteFileModalOpened = (state: any) =>
  state.modalDialog.overwriteFile.open;
export const isOverwriteFileModalConfirmed = (state: any) =>
  state.modalDialog.overwriteFile.confirm;
export const overwriteModalDialogData = (state: any) =>
  state.modalDialog.overwriteFile.data;

const getOutageDialog = (state: any) => state.modalDialog.outage;

export const selectOutageDialog = createSelector(
  [getOutageDialog],
  (dialog) => dialog,
);
