import React from 'react';
import {
  AvatarProps,
  StyledImage,
  ImageContainer,
  StyledText,
} from './Avatar.styles';
import { buildFullName, buildUserInitials } from '../../../utils/userSettings';

const Avatar = ({
  img = '',
  firstName = '',
  lastName = '',
  variant = 'medium',
  ...props
}: AvatarProps) => (
  <React.Fragment>
    {img ? (
      <StyledImage
        {...props}
        variant={variant}
        src={`data:image/jpeg;base64,  ${img}`}
        title={buildFullName(firstName, lastName)}
        alt="user-pic"
      />
    ) : (
      <ImageContainer {...props}>
        <StyledText
          data-testid={'avatar-initials'}
        >
          {buildUserInitials(firstName, lastName)}
        </StyledText>
      </ImageContainer>
    )}
  </React.Fragment>
);

export default Avatar;
