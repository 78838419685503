import { createSelector } from 'reselect';
import { RootState } from 'store';

const getToken = (state: RootState) => state.auth.login.data?.token;
const loading = (state: RootState) => state.auth.login.loading;
const user = (state: RootState) => state.auth.user.data;
const customer = (state: RootState) => state.auth.customer.data;
const userLoading = (state: RootState) => state.auth.user.loading;
const customerLoading = (state: RootState) => state.auth.customer.loading;
const updateCustomerLoading = (state: RootState) =>
  state.auth.updateCustomer.loading;
const updateUserLoading = (state: RootState) => state.auth.updateUser.loading;
const updateUser = (state: RootState) => state.auth.updateUser;
const updateCustomerSucesss = (state: RootState) =>
  state.auth.updateCustomer.success;
const updateCustomerError = (state: RootState) =>
  state.auth.updateCustomer.failure;
const emailToken = (state: RootState) => state.auth.emailToken;
const updateFirstName = (state: RootState) => state.auth.updateFirstName;
const updateLastName = (state: RootState) => state.auth.updateLastName;
const updateEmail = (state: RootState) => state.auth.updateEmail;
const getInitialStatus = (state: RootState) => state.auth.initialStatus;
const deleteAccount = (state: RootState) => state.auth.deleteAccount;
const getFeatures = (state: RootState) => state.auth.features.data;

export const getIsLoggedIn = createSelector([getToken], (token) =>
  Boolean(token),
);
export const getIsLoading = createSelector([loading], (loading) => loading);
export const getUser = createSelector([user], (user) => user);
export const getCustomer = createSelector([customer], (customer) => customer);
export const getCustomerIsLoading = createSelector(
  [customerLoading],
  (loading) => loading,
);
export const getIsInitialStatusLoading = createSelector(
  [getInitialStatus],
  (status) => status.loading,
);

export const getAccountLoading = createSelector(
  [userLoading, customerLoading, updateCustomerLoading, updateUserLoading],
  (user, customer, updateUser, updateCustomer) => {
    return user || customer || updateUser || updateCustomer;
  },
);

export const getUpdateCustomerLoading = createSelector(
  [updateCustomerLoading],
  (loading) => loading,
);

export const getUserLoading = createSelector(
  [userLoading],
  (loading) => loading,
);

export const getUpdateCustomerSuccess = createSelector(
  [updateCustomerSucesss],
  (success) => success,
);

export const getUpdateCustomerError = createSelector(
  [updateCustomerError],
  (error) => error,
);

export const getUpdateUserLoading = createSelector(
  [updateUser],
  (user) => user.loading,
);
export const getUpdateUserSuccess = createSelector(
  [updateUser],
  (user) => user.success,
);
export const getUpdateUserError = createSelector(
  [updateUser],
  (user) => user.error,
);

export const getEmailToken = createSelector(
  [emailToken],
  (token) => token.data,
);
export const getEmailTokenLoading = createSelector(
  [emailToken],
  (token) => token.loading,
);
export const getEmailTokenError = createSelector(
  [emailToken],
  (token) => token.error,
);

export const getDeleteAccount = createSelector(
  [deleteAccount],
  (deleteAccount) => deleteAccount,
);

export const getUpdateFirstNameLoading = createSelector(
  [updateFirstName],
  (updateFirstName) => updateFirstName.loading,
);

export const getUpdateLastNameLoading = createSelector(
  [updateLastName],
  (updateLastName) => updateLastName.loading,
);
export const getUpdateEmailLoading = createSelector(
  [updateEmail],
  (updateEmail) => updateEmail.loading,
);

export const getUpdateFirstNameError = createSelector(
  [updateFirstName],
  (updateFirstName) => updateFirstName.error,
);

export const getUpdateLastNameError = createSelector(
  [updateLastName],
  (updateLastName) => updateLastName.error,
);

export const getUpdateEmailError = createSelector(
  [updateEmail],
  (updateEmail) => updateEmail.error,
);

export const getUpdateFirstNameSuccess = createSelector(
  [updateFirstName],
  (updateFirstName) => updateFirstName.success,
);
export const getUpdateLastNameSuccess = createSelector(
  [updateLastName],
  (updateLastName) => updateLastName.success,
);
export const getUpdateEmailSuccess = createSelector(
  [updateEmail],
  (updateEmail) => updateEmail.success,
);

export const getUpdateFirstName = createSelector(
  [updateFirstName],
  (updateFirstName) => updateFirstName,
);
export const getUpdateLastName = createSelector(
  [updateLastName],
  (updateLastName) => updateLastName,
);

export const getLinkedAccountString = createSelector([user], (user) => {
  if (user && user.linkedAccounts !== '' && user.linkedAccounts !== null) {
    const accs = Array.from({ length: 4 }).fill(false);
    if (user.userId.includes('@zebra.com')) accs[0] = true;
    if (user.linkedAccounts.includes('GOOGLE')) accs[1] = true;
    if (user.linkedAccounts.includes('APPLE')) accs[2] = true;
    if (user.linkedAccounts.includes('FACEBOOK')) accs[3] = true;
    let known = accs.filter((val) => val === true).length < 2;
    if (known) {
      if (accs[0]) {
        return ' Zebra ';
      }
      if (accs[1]) {
        return ' Google ';
      }
      if (accs[2]) {
        return ' Apple ';
      }
      if (accs[3]) {
        return ' Facebook ';
      }
    }
    return ' ';
  }
  return null;
});
export const getAllFeatures = createSelector(
  [getFeatures],
  (features) => features,
);

export const selectFirstName = createSelector(
  [user, customer],
  (user, customer) => user?.firstName || customer?.firstName,
);
