import {
  generateDataTypes,
  generateDataActions,
  generateSelectedDataTypes,
  generateSelectedDataActions,
} from 'ducks/utils';

const BASE_DUCK_PREFIX = 'SMB_WORKSPACES_';

export const types = {
  ALL: generateDataTypes(BASE_DUCK_PREFIX + 'ALL'),
  CURRENT: generateDataTypes(BASE_DUCK_PREFIX + 'CURRENT'),
  UPDATE: generateDataTypes(BASE_DUCK_PREFIX + 'UPDATE'),
  SELECTED: generateSelectedDataTypes(BASE_DUCK_PREFIX + 'SELECTED'),
  LEAVE: generateDataTypes(BASE_DUCK_PREFIX + 'LEAVE'),
};

export const actions = {
  ALL: generateDataActions(types.ALL),
  CURRENT: generateDataActions(types.CURRENT),
  UPDATE: generateDataActions(types.UPDATE),
  SELECTED: generateSelectedDataActions(types.SELECTED),
  LEAVE: generateDataActions(types.LEAVE),
};

// TODO:
//SMB_WORKSPACES_UPDATE_REQUEST
//SMB_WORKSPACES_UPDATE_SUCCESS

// payload on Request console.log():
// Christmas****,
// {id: 136, name: "Man Inc - 5001", avatar: "http://www.fillmurray.com/200/300", size: 0, category: "", …}
// avatar: "http://www.fillmurray.com/200/300"
// category: ""
// createdDate: "2020-09-16T14:10:17.578Z"
// id: 136
// isDefault: true
// lastPrint: "not implemented"
// lastUpdated: ""
// name: "Man Inc - 5001"
// ownerId: "85a43c47-f610-4b34-8046-eec42c7b1003"
// size: 0
// __proto__: Object
