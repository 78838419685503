import { useState, useEffect } from 'react';

const nav: Navigator = navigator;

const useHasInternet = () => {
  const [state, setState] = useState(() => nav.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setState(true);
    };
    const handleOffline = () => {
      setState(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return state;
};
export default useHasInternet;
