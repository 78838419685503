import {
  getFromLocalStorage,
  clearFromLocalStorage,
  saveToLocalStorage,
} from './localStorageItemConverter';

const DELETE_ACCOUNT_KEY = 'deleteAccount';

type DeleteAccountData = {
  customerId: string;
  deleteExpiration: number;
};

export const setDeleteAccountStarted = (data) => {
  saveToLocalStorage(DELETE_ACCOUNT_KEY, JSON.stringify(data));
};

const parseDeleteAccountFromLocalStorage = (): DeleteAccountData | null => {
  try {
    const deleteAccountData = getFromLocalStorage(DELETE_ACCOUNT_KEY);
    return deleteAccountData
      ? (JSON.parse(deleteAccountData) as DeleteAccountData)
      : null;
  } catch (e: any) {
    return null;
  }
};

export const getDeleteAccountStarted = (): boolean => {
  const deleteAccount = parseDeleteAccountFromLocalStorage();

  return (
    !!deleteAccount && deleteAccount.deleteExpiration > new Date().getTime()
  );
};

export const isInitialDeleteAccountCustomer = (customerId: string): boolean => {
  const deleteAccount = parseDeleteAccountFromLocalStorage();

  return !!deleteAccount && customerId != null && deleteAccount.customerId === customerId;
};

export const clearDeleteAccountStarted = () => {
  clearFromLocalStorage(DELETE_ACCOUNT_KEY);
};
