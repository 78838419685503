import styled from 'styled-components';
import { Flex } from 'components';
import { device } from 'utils/mediaQueries';
import SearchResult from 'v2/pages/CommonDesignsPage/SearchResult';
import {
  CategoriesWrap,
  TemplatesWrap,
} from 'v2/pages/CommonDesignsPage/CommonDesignsPage.styles';
import CategoriesList from 'v2/pages/CommonDesignsPage/CategoriesList';
import FilterTemplatesLayout, {
  SearchWrap,
  StyledFlexWrap,
} from 'v2/pages/CommonDesignsPage/FilterTemplatesLayout';

export const StyledFlex = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  className: 'library-content',
})`
  width: 100%;
`;

export const StyledSearchResult = styled(SearchResult)`
  & ${CategoriesWrap} {
    grid-template-columns: 1fr 1fr 1fr;

    @media ${device.desktopM} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${device.tablet} {
      grid-template-columns: 1fr;
    }
  }

  & ${TemplatesWrap} {
    grid-template-columns: 1fr 1fr;

    @media ${device.desktopM} {
      grid-template-columns: 1fr;
    }

    @media ${device.tablet} {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledCategoriesList = styled(CategoriesList)`
  grid-template-columns: 1fr 1fr 1fr;

  @media ${device.desktopM} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const StyledFilterTemplatesLayout = styled(FilterTemplatesLayout)`
  & ${StyledFlexWrap} {
    @media ${device.desktopM} {
      flex-direction: column;
    }
  }
  & ${SearchWrap} {
    @media ${device.desktopM} {
      width: calc(100% - 2px);
    }
  }
`;
