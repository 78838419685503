export const isValidName = (name) =>
  name && typeof name === 'string' && name.trim().length > 0;

const getInitial = (name: string) => {
  name = name.trim();
  return name.length > 0 ? name[0].toUpperCase() : '';
};

export const buildUserInitials = (firstName, lastName) => {
  const initials: string[] = [];
  if (isValidName(firstName)) {
    initials.push(getInitial(firstName));
  }
  if (isValidName(lastName)) {
    initials.push(getInitial(lastName));
  }
  return initials.join(' ');
};

export const buildFullName = (firstName, lastName) => {
  const fullName: string[] = [];
  if (isValidName(firstName)) {
    fullName.push(firstName);
  }
  if (isValidName(lastName)) {
    fullName.push(lastName);
  }
  return fullName.join(' ');
};
