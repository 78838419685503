import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReduxAction, useReduxSelector, useValidation } from 'hooks';
import { Box, Button, Flex, Form, Input, Modal, Text } from 'components';
import {
  getIsLoadingUpdate,
  getIsUpdateSuccess,
} from 'ducks/templates/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import validatorsFor from 'utils/validators';
import { DOCUMENT_NOT_ALLOWED_PATTERN } from 'services/api';
import { useTranslation } from 'react-i18next';

const RenameTemplateModal = forwardRef(
  ({ template, isOpen, handleModalClose }: any, ref: any) => {
    const editTemplateName = useReduxAction(templatesActions.UPDATE.request);
    const editTemplateClear = useReduxAction(templatesActions.UPDATE.clear);
    const isLoading = useReduxSelector(getIsLoadingUpdate);
    const isEditSuccess = useReduxSelector(getIsUpdateSuccess);
    const { t } = useTranslation(['overview', 'common', 'utils']);

    const [hasChecked, setHasChecked] = useState(false);
    const location = useLocation();
    // TO-DO Think for a modals reducer and main modal component who renders whichever modal needed, and command it through actions

    useEffect(() => {
      if (isOpen) {
        onChange({
          target: { value: fields.templateName, name: 'templateName' },
        });
        setHasChecked(true);
      } else {
        setHasChecked(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleClose = useCallback(() => {
      editTemplateClear();
      handleModalClose();
    }, [handleModalClose, editTemplateClear]);

    useEffect(() => {
      if (isEditSuccess && isOpen) {
        handleClose();
      }
    }, [handleClose, isEditSuccess, isOpen]);

    const constraints = {
      templateName: {
        length: {
          minimum: 1,
          message: t('common:character-length', {
            value: `%{value}`,
            count: 1,
          }),
        },
        exclusion: {
          within: hasChecked ? [template.name] : [],
          message: t('common:must-be-different', {
            value: `%{value}`,
          }),
        },
        format: {
          pattern: DOCUMENT_NOT_ALLOWED_PATTERN,
          message: t('utils:characters-not-allowed'),
        },
      },
    };

    const validators = validatorsFor(
      constraints,
      t('utils:characters-not-allowed'),
    );

    const initialFields = {
      templateName: template.name,
    };

    const [fields, errors, hasErrors, onChange] = useValidation(
      initialFields,
      validators,
    );

    const handleSubmit = useCallback(() => {
      if (!hasErrors) {
        editTemplateName({
          template,
          name: fields.templateName,
          location,
        });
      }
    }, [editTemplateName, fields.templateName, hasErrors, template, location]);

    return (
      <Modal
        data-testid="change-template-name-modal"
        clickOutside={!isLoading}
        onClose={handleClose}
        ref={ref}
        small
        isAnimatedBackdrop={false}
      >
        <Text mb={8} fontSize={24} fontWeight={300} lineHeight={'32px'}>
          {t('overview:theme.edit-name')}
        </Text>
        <Text fontSize={16} lineHeight={'24px'}>
          {t('overview:designs.organize-by-giving-name')}
        </Text>
        <Form onSubmit={handleSubmit}>
          <Box mt={[11]}>
            <Input
              data-testid="input-template-name"
              value={fields.templateName}
              name={'templateName'}
              onChange={onChange}
              hasError={Boolean(errors?.templateName)}
              message={errors?.templateName?.[0] ?? ''}
            />
          </Box>

          <Flex mt={40} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              data-testid="template-cancel-button"
              mr={2}
              type={'reset'}
              onClick={handleClose}
              variant={'text-primary'}
            >
              {t('common:cancel')}
            </Button>
            <Button
              data-testid="template-save-button"
              isLoading={isLoading}
              disabled={hasErrors}
              type={'submit'}
            >
              {isLoading ? t('common:saving') : t('common:save')}
            </Button>
          </Flex>
        </Form>
      </Modal>
    );
  },
);

export default RenameTemplateModal;
