import { LOGOUT_URL } from '../utils/config';

export const STORAGE_KEY = 'SMB_TOKEN';

export const get = () => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null');
  } catch {
    clear();
    window.location.assign(LOGOUT_URL);
    return null;
  }
};

export const set = (token: string) =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ token }));

export const clear = () => {
  localStorage.removeItem(STORAGE_KEY);
  window.dispatchEvent(new Event('storage'));
};

const tokenStorage = {
  get,
  set,
  clear,
};

export default tokenStorage;
