import React from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useReduxAction } from 'hooks';
import { PRINT_PAGE_PATH } from 'utils/config';
import { DialogHeader } from 'v2/components/app';
import TemplatesList  from './TemplatesList';

export default function RecentlyPrintedPage() {
  const { t } = useTranslation();
  const pushAction = useReduxAction(push);

  return (
    <>
      <DialogHeader data-testid="recently-printed-title"
        title={t('dialogs:print-label.recently-printed')}
        onBackClick={() => pushAction(PRINT_PAGE_PATH)}
      />
      <TemplatesList />
    </>
  );
}
