import React from 'react';
import styled from 'styled-components/macro';

import { Box, Flex, Icon } from 'components';
import { Text } from 'v2/components/common';

const BackArrow = styled(Icon).attrs(({ theme }) => ({
  color: theme.neutral.base,
  icon: 'back-arrow',
  size: 32,
}))`
  cursor: pointer;
`;

const Container = styled(Flex)`
  align-items: 'center';
  padding: ${({ theme }) => theme.px(16, 20, 12)};
`;

type DialogHeaderProps = {
  title: string;
  onBackClick?: React.MouseEventHandler;
};

export default function DialogHeader({
  title,
  onBackClick,
}: DialogHeaderProps) {
  return (
    <Container>
      {onBackClick && (
        <Box
          onClick={onBackClick}
          aria-label={'back button'}
          data-testid="back-button"
          mr={8}
        >
          <BackArrow />
        </Box>
      )}
      <Text variant={'h2'}>{title}</Text>
    </Container>
  );
}
