import React, { useCallback, useEffect } from 'react';
import { FileProviderFactory } from '../../../utils/file/FileProviderFactory';
import { getOneDriveContacts } from '../../../services/office365';
import { getRows } from '../../../utils/excelHelper';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import useMethods, { StateAndCallbacksFor } from 'use-methods';
import { useReduxAction, useReduxSelector } from '../../../hooks';
import { getCustomer } from '../../../ducks/auth/selectors';
import { getCurrentWorkspaceId } from '../../../ducks/workspaces/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import { GOOGLE_CONTACTS_LABEL } from '../../../services/google/googleContacts';
import googleApi from '../../../services/google/googleApi';

type LabelRangeState = {
  file?;
  labelRange?;
  labelRangeMapping;
  loading: boolean;
  success: boolean;
  error?: Error;
};

const INITIAL_STATE: LabelRangeState = {
  labelRangeMapping: {},
  loading: false,
  success: false,
};

type Context = StateAndCallbacksFor<typeof methods>;
const LabelRangeContext = React.createContext<Context | null>(null);

const methods = (state: LabelRangeState) => ({
  reset() {
    return INITIAL_STATE;
  },
  setFile(file) {
    state.file = file;
  },
  clearFile() {
    state.file = undefined;
  },
  setLoading(loading: boolean) {
    state.loading = loading;
  },
  setSuccess(success: boolean) {
    state.success = success;
  },
  setError(error: Error) {
    state.error = error;
  },
  clearError() {
    state.error = undefined;
  },
  setLabelRange(labelRange) {
    state.labelRange = labelRange.reduce((acc, range) => {
      // Checks if the array is full with empty elements and filters them
      if (!!range.join('').trim()) {
        acc.push(range);
      }
      return acc;
    }, []);
  },
  setLabelRangeMapping(labelRangeMapping) {
    state.labelRangeMapping = labelRangeMapping;
  },
});

export type LabelRangeProviderProps = {};

export const LabelRangeProvider = ({
  children,
}: React.PropsWithChildren<LabelRangeProviderProps>) => {
  const [reducerState, callbacks] = useMethods(methods, INITIAL_STATE);
  const { file } = reducerState;
  const { setLoading, setSuccess, setError, clearError, setLabelRange } =
    callbacks;

  const msalContext = useMsal();
  const isOneDriveAuthenticated = useIsAuthenticated();

  const customer = useReduxSelector(getCustomer);
  const workspaceId = useReduxSelector(getCurrentWorkspaceId);
  const setEnteredData = useReduxAction(templatesActions.ENTERED_DATA.set);

  const getLabelRanges = useCallback(
    async (selectedFile) => {
      try {
        setLoading(true);
        setSuccess(false);
        clearError();

        if (selectedFile?.id) {
          let jsonSheet;

          switch (selectedFile.provider) {
            case GOOGLE_CONTACTS_LABEL:
              jsonSheet = await googleApi.getContacts();
              break;

            case 'Office365': // Microsoft Contacts
              jsonSheet = await getOneDriveContacts(
                msalContext,
                isOneDriveAuthenticated,
                true,
              );
              break;

            default:
              const fileProvider =
                FileProviderFactory.getInstance(selectedFile);
              const fileData = await fileProvider?.getFileData(selectedFile, {
                customerId: customer?.id,
                workspaceId,
                useCache: true,
                msalContext,
                isOneDriveAuthenticated,
              });
              if (fileData != null) {
                jsonSheet = getRows(fileData);
              }
              break;
          }

          setEnteredData(jsonSheet.map(() => ({})));
          setLabelRange(jsonSheet);
          setSuccess(true);
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [
      clearError,
      customer,
      isOneDriveAuthenticated,
      msalContext,
      setError,
      setSuccess,
      setLabelRange,
      setLoading,
      workspaceId,
      setEnteredData,
    ],
  );

  useEffect(() => {
    if (file) {
      getLabelRanges(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <LabelRangeContext.Provider
      value={[reducerState, callbacks]}
      children={children}
    />
  );
};

export default LabelRangeProvider;

export const useLabelRange = (): Context => {
  const context = React.useContext(LabelRangeContext);

  if (context === null) {
    throw new Error('LabelRangeContext failed to initialize');
  }

  return context;
};
