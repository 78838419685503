import { createSelector } from 'reselect';

export const image = (state: any) => state.iframe.image;
export const dataSource = (state: any) => state.iframe.dataSource;

export const getImageLoading = createSelector([image], (image) =>
  image ? { loading: image.loading, type: image.params?.type } : {},
);

export const getDataSourceLoading = createSelector([dataSource], (dataSource) =>
  dataSource
    ? { loading: dataSource.loading, type: dataSource.params?.type }
    : {},
);
